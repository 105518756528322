import React from "react";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBIcon,
  MDBTooltip,
} from 'mdbreact';

import defaultImage from "../../assets/images/default.jpg";

const loadText = props => {
	if (props.textData){
	  return (
		  <div className="row"
			  style={{
		  		marginTop: props.textConfig.margintop?props.textConfig.margintop+"px":"0" ,
		      marginLeft:  props.textConfig.marginleft?props.textConfig.marginleft+"px":"0" ,
		      marginBottom: props.textConfig.marginbottom?props.textConfig.marginbottom+"px":"0" ,
		      marginRight: props.textConfig.marginright?props.textConfig.marginright+"px":"0" 
		       }}
		  >
        <div className={ " col-12 " +(props.textConfig.aligntext? props.textConfig.aligntext: 'text-center')}  >
	       		{props.textData.link? 
	        			<a href={props.textData.link} target={ (props.textConfig.targetlink?props.textConfig.targetlink:"_blank") } >
	        				<span
		              	style={{
			              	fontSize: props.textConfig.fontsize+"px" ,
		              		fontWeight: props.textConfig.fontweight ,
		              		color: props.textConfig.color=="gray" ? "#696158": props.textConfig.color ,
		              		fontFamily: '"'+props.textConfig.fontfamily+'"',
		              		textDecoration:props.textConfig.textdecoration,
		              		
			              }}>
		              	{props.textData.text}
	            		</span>
	      				</a>
	        		:<>
	              <span
	              style={{	              	
	              	fontSize: props.textConfig.fontsize+"px" ,
	              	fontWeight: props.textConfig.fontweight ,
	              	color: props.textConfig.color=="gray" ? "#696158": props.textConfig.color ,
	              	fontFamily: '"'+props.textConfig.fontfamily+'"',
	              	textDecoration:props.textConfig.textdecoration,
	               
	              	
	              }}>
	              	{props.textData.text}
	            	</span>
	            	{props.textConfig.addline?props.textConfig.addline=="YES"?
	            		<><span className="or-line mt-2 mb-2"></span> <div className="mt-1 mb-10"></div></>
	            	:"":""}
	            	</>
	            }
        </div>
    	</div>
	  );
	}else{
		return "NO TEXT DATA"
	}
};
export default loadText;
				 