import React from "react";
import { getDefValues,getCurrency} from '../../common/functions';
import defaultImage from "../../assets/images/default.jpg";
import {getImagePath, toFixed } from '../../common/functions';
const loadList = props => {
	if (props.orderDetails.length>0){
		return (
			<div className="row">
				<div className="mobile-col col-12">
					<div className="order-status">
						<div className="row" >
							{props.orderDetails.map(item=> (
								<div className="product-detail"> 
							 		<div key= {"k_img"+item.PRODUCTID}  className="image"> 	
								 		<a href={"/productDetails/"+item.PRODUCTID+"/"+item.PRODUCTNAME} tabindex="-1">
											<img 
												key={"img"+item.PRODUCTID} 
												name={"manageImage_"+item.PRODUCTNAME}  
												src={ item.DEFAULTIMAGE!=""?getImagePath(item.DEFAULTIMAGE):defaultImage}
											/>
										</a>
									</div>
									<div key={"info"+item.PRODUCTID} className= "detail"  >
										<p key={"p1"+item.PRODUCTID} >
											{item.PRODUCTNAME} 
										</p>
										<p key={"p2"+item.PRODUCTID} > 
											<span key={"spaninfo"+item.PRODUCTID} > { "SKU: "+ item.SKU}</span>
									 	</p>
									 	<p key={"p3"+item.PRODUCTID} > 
											<span key={"spaninfo"+item.PRODUCTID} > { getDefValues().shopCartQuantityLabel+": "+item.QUANTITY}</span>
									 	</p>
									 	<p key={"p4"+item.PRODUCTID} > 
											<span key={"spaninfo"+item.PRODUCTID} > { getCurrency()}{toFixed(item.PRICE)}</span>
									 	</p>
									</div>
							 	</div>
					 	  ))
							}
							
							<div className="product-detail ">  
								<div className="image"> 	 
								</div> 
							 <div className= "detail"  > 
							 		<div className="row" >
								 		<div className="col-12">
								 			<span className="h6-responsive">{ getDefValues().orderResumeLabel}</span>
								 		</div>
								 	</div>
								 	<div className="row mt-2" >
								 		<div className="col-6">
								 			<span>{getDefValues().shopCartSubtotalLabel}</span>
								 		</div>
								 		<div className="col-6 text-right">
								 			<span className="text-bold">{ getCurrency()}{ toFixed(props.order.SUBTOTAL)}</span>
								 		</div>
								 	</div> 
								 	
								 	<div className="row mt-2" >
								 		<div className="col-6">
								 			<span>{getDefValues().shopCartDiscountLabel}</span>
								 		</div>
								 		<div className="col-6 text-right">
								 			<span className="text-bold">{ getCurrency()}{ toFixed(props.order.DISCOUNT)}</span>
								 		</div>
								 	</div> 
								 	
								 	<div className="row" >
								 		<div className="col-6">
								 			<span>{getDefValues().shippingCostLabel}</span>
								 		</div>
								 		<div className="col-6 text-right">
								 			<span className="text-bold">{ getCurrency()}{ toFixed(props.order.SHIPPINGCOST)}</span>
								 		</div>
								 	</div> 	<span className="or-line mt-2 mr-2"></span>
								 	<div className="row" > 
								 		<div className="col-6">
								 			<span className="text-bold">{getDefValues().totalOrderLabel}</span>
								 		</div>
								 		<div className="col-6 text-right">
								 			<span className="text-bold">{getCurrency()}{toFixed(props.order.TOTAL)}</span>
								 		</div>
								 	</div> 
							 	</div>
							</div>
						</div>
					</div>
				</div>  
		  </div>   
		);
			  
	}else{
		return "NO PRODUCT VALUES"
	}
};
export default loadList;
				 