/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';

/*functions*/
import {getLanguages, validateComponent, changeFilter, filterHandler,validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';
/*import ReactExport from "react-export-excel-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;*/
class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			modal2: false,
			modalMdClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			processName:"", 
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			filterConfig: {
				STATUS : { label:"STATUS" ,  type:"SELECT" , value:"" , values:["OK", "NOK" ,""] },
				FIELD0 : { label:"FIELD0" ,  type:"TEXT"   , value:""} ,
				FIELD1 : { label:"FIELD1" ,  type:"TEXT"   , value:""} ,
				FIELD2 : { label:"FIELD2" ,  type:"TEXT"   , value:""} ,
				FIELD3 : { label:"FIELD3" ,  type:"TEXT"   , value:""} ,
				FIELD4 : { label:"FIELD4" ,  type:"TEXT"   , value:""} 
				
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"LOADID", title:"LOADID",
						sorter: (a, b) => a.SPECIFICNAME.length - b.SPECIFICNAME.length },
					{ dataIndex:"DEST", title:"PROCESS",
						sorter: (a, b) => a.DEST.length - b.DEST.length },
					{ dataIndex:"FIELD0", title:"FIELD0",
						sorter: (a, b) => a.FIELD0.length - b.FIELD0.length },
					{ dataIndex:"FIELD1", title:"FIELD1",
						sorter: (a, b) => a.FIELD1.length - b.FIELD1.length },
				  { dataIndex:"FIELD2", title:"FIELD2",
						sorter: (a, b) => a.FIELD2.length - b.FIELD2.length },
					{ dataIndex:"FIELD3", title:"FIELD3",
						sorter: (a, b) => a.FIELD3.length - b.FIELD3.length },
					{ dataIndex:"FIELD4", title:"FIELD1",
						sorter: (a, b) => a.FIELD4.length - b.FIELD4.length },
						
					{ dataIndex:"INSERTDATE", title:"DATE",
						sorter: (a, b) => a.INSERTDATE.length - b.INSERTDATE.length },
					{ dataIndex:"STATUS", title:"STATUS",
						sorter: (a, b) => a.STATUS.length - b.STATUS.length },
					{ dataIndex:"MESSAGE", title:"MESSAGE",
						sorter: (a, b) => a.MESSAGE.length - b.MESSAGE.length }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Attribute",
			/*form data*/
			procs:[],
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			loadid:"",
			 
						
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		
	 		var resp = await callApi ( "custom","getDetailDomainsByCodeFromDB", {domainid:"19"} )			 
			this.setState({"procs":resp.rows});
	 	 
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	 //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	 
	  
  /*get value fields*/
  changeHandler = async(event) => {
  	
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined  ;
		
		if (field_name =="inputNewFile" ){ 
			this.setState({filename:event.target.files[0]}); 
		}else{
			this.setState({[field_name]:field_value}); 
		}
		 
	} 
  
	loadIds = async(event) => {
	  this.setState({isLoading:true});
	  var resp = await callApi ( "manager","getLoadsById", {loadid: this.state.loadid} )
		const tableData = this.state.tableData
		tableData.rows = resp.rows
		this.setState({originalTableData:this.state.tableData});
		this.setState({...this.state,modal1:false });
		this.setState({isLoading:false});
			 			
  }
  
  
	/*ABM manager*/
	processFile=async (event) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (this.state.processName==""){
  				respValid= false;
  				showError(toast,"Please choose one process");
  			}
  			
  			if (!this.state.filename){
  				respValid= false;
  				showError(toast,"Please choose one file");
  			}
  			 
  			if(respValid){
			  	this.setState({isLoading:true});
			  	const formData = new FormData();
		      formData.append(
		        "filename",
		        this.state.filename,
		        this.state.filename.name
		      );
       		formData.append("objectType", this.state.processName);
       
			  	let resp = await callApi ( "manager","loadMassiveData", formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		let data = resp.message.split(":")
			  		var loadid = data[1]
			  		 
			  		this.setState({loadid:loadid});
			  		
			  		this.loadIds()
			  		this.setState({isLoading:false});
			  	}else{
			  		showError(toast,resp.message);
			  		this.setState({isLoading:false});
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	
	
  
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Massive Loads"}/>
        	
        	<div className="row mt-5 mb-3 ">
						<div className="col-12">
							<button  type="submit" name="uploadFile"   className="btn-step"> 1 </button> 
								<span className="h5-responsive">	Please choose Massive Load Procedure</span>
						</div>	
	    		</div> 
        	<div className="row mt-1 mb-3">
						<div className="col-md-12 col-lg-6">
							
						 <select  name="processName"  defaultValue={this.state.processName} onChange={this.changeHandler} className={"form-control form-control-sm" }>
      			 		<option value="">CHOOSE ONE</option>
      			 		{this.state.procs.map(item => (
      			 		 <option value={item.CODE}>{item.STRING_VALUE}</option>
      			 		))
			          }  
							</select>			
														
						</div>	
	    		</div> 
	    		
        	<div className="row mt-3">
						<div className="col-md-12 col-lg-6">
							<button  type="submit" name="uploadFile"   className="btn-step"> 2 </button> 
								<span className="h5-responsive">	Please upload file</span>
						</div>	
	    		</div> 
        	<div className="row ">
						<div className="col-md-12 col-lg-6">
							
							 <div className="input-group mb-3 mt-3">
								  <input  className="form-control" name="inputNewFile" type="file" onChange={this.changeHandler} />
									<button  type="submit" name="uploadFile"   className="btn-info btn btn-sm" onClick={this.processFile}> <i className="fas fa-upload" /> Upload And Process </button>
								</div>	
														
						</div>	
	    		</div> 
	    		
	    		
	    		
	    		<div className="row ">
						<div className="col-12">
						<button  type="submit" name="uploadFile"   className="btn-step"> 3 </button> 
								<span className="h5-responsive">		You can trace your upload by Load Id</span>
					
						</div>	
	    		</div> 
	    		<div className="row ">
						<div className="col-6">
							
							 <div className="input-group mb-3 mt-3">
								 <input  className="form-control" name="loadid" value={this.state.loadid} type="text" onChange={this.changeHandler} />
								 <button  type="buttom" name="button" className="btn-success btn btn-sm" onClick={this.loadIds}> Check</button>
							</div>	
														
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 