/*****components***/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {toast,ToastContainer} from 'mdbreact';



/****custom components****/
import PageLoading from '../../components/PageLoading';
import PageHeader from '../../components/PageHeader';
import Form from '../../components/Form';
import Slider from '../../components/Slider';
import Auth from '../../services/Auth';
import SidebarNav from "../sidebar";

/****custom functions****/
import {getDataDB, validateForm,showError} from '../../common/functions';
 
class genericReport extends Component {
	
	//define variables
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			isLoading: true,
			
			settings : {
      	dots: true,
      	infinite: true,
      	speed: 500,
      	slidesToShow: 1,
      	slidesToScroll: 1,
      	autoplay: true,
      	cssEase: "linear",
      	pauseOnHover: true
    	},
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-9",
				groupingRows: 2,
				formTitle: "Formulario"
			},
			formData	: {
				granularity  : { label: "Granularidad"  , type: "SELECT"	, value: "HORA", 
							script : 
							'formData.from["options"]={ enableTime:currentField.value==="HORA"?true:false};'+
							'formData.to["options"]  ={ enableTime:currentField.value==="HORA"?true:false};', 
							values : [{text: "Horario", value: "HORA"},{text: "Diario", value: "DIA"}] },
								
				busyHour     : { label: "Hora Pico"         , type: "SELECT"	   , value :"CS", values: [{text: "CS POR CELDA", value: "CS"},{text: "PS POR CELDA", value: "PS"} ] },
				from         : { label: "Desde"		          , type: "DATEPICKER" , value: new Date()          , options :{ minDate: "2020-01-01", enableTime: true} , validation:{ needValidation: true, required:false, type:"datetime"	},
												 script :"(async () => { var resp = await callFormApi ( 'custom','getEstacion', {  tecnologia: '4G', from: formData.from.value, to: formData.to.value,    "+
												 																																		" departamento: formData.departamento.value, controller: formData.controller.value,"+
												 																																		" vendor: formData.vendor.value, municipio: formData.municipio.value,"+
												 																																		" tecnologia: '4G', modelo: '', tipo_acceso: '',"+
												 																																		" transporte: formData.transporte.value, area: formData.to.area}); "+
												 																																		" formData.estacion['values'] = resp.rows; formData.estacion['value'] =''; formData.celda['value'] =''; formData.celda['values'] =[]; })(); " }, 
				to           : { label: "Hasta"				      , type: "DATEPICKER" , value: new Date()          , options :{ minDate: "2020-01-02", enableTime: true} , disabled :true,  },
				
				departamento : { label: "Departamento"      , type: "SELECT"     , value:"", values: [] , getDataDB: {apiMethod: "getDepartamentos"} ,
												 script :"(async () => { var resp = await callFormApi ( 'custom','getEstacion', {  tecnologia: '4G', from: formData.from.value, to: formData.to.value,    "+
												 																																		" departamento: formData.departamento.value, controller: formData.controller.value,"+
												 																																		" vendor: formData.vendor.value, municipio: formData.municipio.value,"+
												 																																		" tecnologia: '4G', modelo: '', tipo_acceso: '',"+
												 																																		" transporte: formData.transporte.value, area: formData.to.area}); "+
												 																																		" formData.estacion['values'] = resp.rows; })(); " }, 
				area         : { label: "Area"              , type: "SELECT"	   , value:"", values: [{text: "TODOS", value: ""},{text: "URBANA", value: "URBANA"},{text: "RURAL", value: "RURAL"} ],
												 script :"(async () => { var resp = await callFormApi ( 'custom','getEstacion', {  tecnologia: '4G', from: formData.from.value, to: formData.to.value,    "+
												 																																		" departamento: formData.departamento.value, controller: formData.controller.value,"+
												 																																		" vendor: formData.vendor.value, municipio: formData.municipio.value,"+
												 																																		" tecnologia: '4G', modelo: '', tipo_acceso: '',"+
												 																																		" transporte: formData.transporte.value, area: formData.to.area}); "+
												 																																		" formData.estacion['values'] = resp.rows; })(); " }, 
				transporte   : { label: "Tipo de Transporte", type: "SELECT"     , value:"",  values: [] , getDataDB: {apiMethod: "getParamxCodigo", aditionalValues:[{paramCod:10}] } ,
												 script :"(async () => { var resp = await callFormApi ( 'custom', 'getEstacion', {  tecnologia: '4G', from: formData.from.value, to: formData.to.value,    "+
												 																																		" departamento: formData.departamento.value, controller: formData.controller.value,"+
												 																																		" vendor: formData.vendor.value, municipio: formData.municipio.value,"+
												 																																		" tecnologia: '4G', modelo: '', tipo_acceso: '',"+
												 																																		" transporte: formData.transporte.value, area: formData.to.area}); "+
												 																																		" formData.estacion['values'] = resp.rows; })(); " }, 
				controller   : { label: "RNC"               , type: "SELECT"     , value:"",  values: [] , getDataDB: {apiMethod: "getController", aditionalValues:[{tecnologia:"4G"}] } ,
												 script :"(async () => { var resp = await callFormApi ( 'custom','getEstacion', {  tecnologia: '4G', from: formData.from.value, to: formData.to.value,    "+
												 																																		" departamento: formData.departamento.value, controller: formData.controller.value,"+
												 																																		" vendor: formData.vendor.value, municipio: formData.municipio.value,"+
												 																																		" tecnologia: '4G', modelo: '', tipo_acceso: '',"+
												 																																		" transporte: formData.transporte.value, area: formData.to.area}); "+
												 																																		" formData.estacion['values'] = resp.rows; })(); " }, 
				vendor       : { label: "Vendor"            , type: "SELECT"	   , value:"", values: [{text: "TODOS", value: ""},{text: "HUAWEI", value: "HUAWEI"},{text: "ZTE", value: "ZTE"},{text: "ERICSSON", value: "ERICSSON"} ],
												 script :"(async () => { var resp = await callFormApi ( 'custom','getEstacion', {  tecnologia: '4G', from: formData.from.value, to: formData.to.value,    "+
												 																																		" departamento: formData.departamento.value, controller: formData.controller.value,"+
												 																																		" vendor: formData.vendor.value, municipio: formData.municipio.value,"+
												 																																		" tecnologia: '4G', modelo: '', tipo_acceso: '',"+
												 																																		" transporte: formData.transporte.value, area: formData.to.area}); "+
												 																																		" formData.estacion['values'] = resp.rows; })(); " }, 
				filtro_est   : { label: "Buscador Estacion"          , type: "TEXT"       , value: "yvasquez@entel.bo" , disabled:true },
				municipio    : { label: "Municipio"         , type: "SELECT"     , value:"",  values: [] , getDataDB: {apiMethod: "getMunicipio" },
												 script :"(async () => { var resp = await callFormApi ( 'custom','getEstacion', {  tecnologia: '4G', from: formData.from.value, to: formData.to.value,    "+
												 																																		" departamento: formData.departamento.value, controller: formData.controller.value,"+
												 																																		" vendor: formData.vendor.value, municipio: formData.municipio.value,"+
												 																																		" tecnologia: '4G', modelo: '', tipo_acceso: '',"+
												 																																		" transporte: formData.transporte.value, area: formData.to.area}); "+
												 																																		" formData.estacion['values'] = resp.rows; })(); " }, 
				EMPTY2       : { label: ""                  , type: "EMPTY"},
				estacion     : { label: "Estaciones"          , type: "MULTIPLE"   , value:"",  values: [] , rows:10,
												 getDataDB: {apiMethod: "getEstacion", apiValues:[ "from", "to", "departamento","controller","transporte","area","vendor","municipio"] , 
												 						 aditionalValues:[{tecnologia:"4G"}, {modelo:"TODOS"},{tipo_acceso:"TODOS"}] },
												 script :"(async () => { var resp = await callFormApi ( 'custom','getCelda', { estacion: currentField.value, tecnologia: '4G', from: formData.from.value, to: formData.to.value }); formData.celda['values'] = resp.rows; })(); " },
				celda        : { label: "Celdas"          , type: "MULTIPLE"   , value:"",  values: [ {text: "TODOS", value: ""} ] , rows:10 } ,
			}
		};
	}
	
	//loading page
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	var formData = this.state.formData
	 	
	 	try{
			for (let key in Object.keys(formData) ){
				let field = Object.keys(formData)[key]
				if(formData[field].getDataDB){
					this.setState({formData:tmpformData});
					let tmpformData = await getDataDB ( formData, field )
					this.setState({formData:tmpformData});
		 		}
		 	}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	changeHandler = async(event) => {
		this.setState({isLoading:true});
		try{
			let resp = await validateForm(this.state.formData, toast,event);
			this.setState({formData:resp.formData});
			var tmpformData = this.state.formData
			for (let key in Object.keys(tmpformData) ){
				let field = Object.keys(tmpformData)[key]
				if(tmpformData[field].type==="DATEPICKER"){
					tmpformData[field]["isUpdatable"]= false
			 		this.setState({formData:tmpformData});
			 		tmpformData[field]["isUpdatable"]= true
			 		this.setState({formData:tmpformData});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}	
	} 
	render() {
		
		return (
			<>
			 	<SidebarNav callback={this.props.callback} pageType="Report" pageId={this.props.match.params.pageId} />
				<PageLoading isLoading={this.state.isLoading}/>

				<div className="page-wrapper">
					<div className="content container-fluid">
					
						 
						<PageHeader title={"Reporte 1"} subtitle={"Accesibilidad"}/>
						<Form 
								formData={this.state.formData} 
								changeHandler={this.changeHandler} 
								formConfig={this.state.formConfig}
						 />
					</div>		
				</div>
			</>
		);
	}
}

export default genericReport;
