/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';
/*message*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
/*functions*/
import {getUserInfo,listImages, findObjName, getLanguages, manageImage, validateComponent, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

const foldername= process.env.REACT_APP_FOLDER_LAYOUT
class page extends Component{	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			modal2: false,
			modalMdClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			messages:[],
			
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"STORE", title:"Store",
						sorter: (a, b) => a.STORE.length - b.STORE.length },
					{ dataIndex:"MESSAGENAME", title:"Name",
						sorter: (a, b) => a.MESSAGENAME.length - b.MESSAGENAME.length },
					{	dataIndex:"MESSAGEDESC", title:"Message",
						sorter: (a, b) => a.MESSAGEDESC.length - b.MESSAGEDESC.length },
					{	dataIndex:"FROMDATE", title:"From",
						sorter: (a, b) => a.FROMDATE.length - b.FROMDATE.length },
						{	dataIndex:"TODATE", title:"To",
						sorter: (a, b) => a.TODATE.length - b.TODATE.length },
					{	dataIndex:"SORTORDER", title:"Sort Order",
						sorter: (a, b) => a.SORTORDER.length - b.SORTORDER.length },
						
					{ dataIndex: "MESSAGEID", title: "Action",
		        render: (text, record) => (<div className="text-right">
		         <button className="btn btn-sm btn-info" id={record.MESSAGEID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-danger" id={record.MESSAGEID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></div>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Message",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
 				/*
				STOREID         : { label: "Store"            , type: "SELECT", value: "", values: [],  "addDefault":"CHOOSE ONE", getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"15"}] }  } ,
				MESSAGETYPE     : { label: "Store"            , type: "SELECT", value: "", values: [{text:"TOP", value:"TOP"}] } ,
				MESSAGENAME     : { label: "Name"             , validationType:"text"  , type: "TEXT", value:"" },
				MESSAGEDESCS    : { label: "Message"          , validationType:"text"  , type: "CompLanguage",   values: []  },
				SORTORDER       : { label: "Sort Order"       , validationType:"number", type: "TEXT",   value: "0"} ,
				FROMDATE        : { label: "From"             , validationType:"number", type: "DATEPICKER",   value: ""} ,
				TODATE          : { label: "To"               , validationType:"number", type: "DATEPICKER",   value: ""} ,
				
				LABEL1          : { label: "Style"            , type: "LABEL",  value: "Style" ,class:"h4-responsive"} , 
				BACKGROUND      : { label: "Background Color"  , 	notRequired:true   , type: "TEXT" , value:"" },
				COLOR           : { label: "Text Color"        , 	notRequired:true   , type: "TEXT" , value:"" },
				OPACITY         : { label: "Transparency"      , 	notRequired:true   , type: "TEXT" , value:"" }
				*/
			}, 
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			completeMessage:[]
		}
	}
	/*loading data*/
	
	//manage image
	manageImage = async(event) => {
		try{
			this.setState({isLoading:true});
			 
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
			
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages("",objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal2: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
						let field = objImage.objectName
						var formData = this.state.formData
						formData[field].value = objImage.selected[0]
						this.setState({modal2:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	setMessageObj = async(event) => {
 		let languages= await getLanguages()
 		var rows = []
	 	 
	 	var completeMessage=[]
 		for (let l in languages ){
 			let resp = await callApi ( "manager","getMessages", {languageid: languages[l].id, messagetype:null } )
 			var messages=[]
 			 
 			for (let k in resp.rows ){
 				var aditionalconfig = JSON.parse( resp.rows[k].ADITIONALCONFIG)
 				let conf ={
 					name    : resp.rows[k].MESSAGENAME,
 					message : resp.rows[k].MESSAGEDESC,
 					link    : aditionalconfig?.link,
 					style   : aditionalconfig?.style,
 					type    : resp.rows[k].MESSAGETYPE,
 					from    : resp.rows[k].FROMDATE,
 					to      : resp.rows[k].TODATE,
 					storeId : resp.rows[k].STOREID?resp.rows[k].STOREID:"",
 				} 
	 			messages.push(conf)
	 		}
 			completeMessage.push({language: languages[l].name,
 												 languageId: languages[l].id, 
 												messages: messages} )
 												
 			if (getUserInfo().languageid == languages[l].id ){
 				for (let k in resp.rows ){
		 				rows.push(resp.rows[k])
		 		}
 			}
 		}
	 	const tableData = this.state.tableData
	 	tableData.rows = rows  
	 	let formData	= { 
				STOREID         : { label: "Store"            , notRequired:true       ,  type: "SELECT", value: "", values: [],  "addDefault":"ALL STORES", getDataDB: {apiGroup:"manager", apiMethod: "getStores"  }  } ,				
				MESSAGETYPE     : { label: "Type"            , type: "SELECT", value: "", values: [{text:"TOP", value:"TOP"}] } ,
				MESSAGENAME     : { label: "Name"             , validationType:"text"  , type: "TEXT", value:"" },
				MESSAGEDESCS    : { label: "Message Description"   , validationType:"text"  , type: "CompLanguage",   values: languages  },
				LINK            : { label: "Redirect Url"     , notRequired:true       , type: "TEXT",   value:""} ,
				SORTORDER       : { label: "Sort Order"       , validationType:"number", type: "TEXT",   value: ""} ,
				FROMDATE        : { label: "From"             , type: "DATEPICKER",   value: new Date()} ,
				TODATE          : { label: "To"               , type: "DATEPICKER",   value: new Date()} ,
				LABEL1          : { label: "Style"            , type: "LABEL",  value: "Style" , class:"h4-responsive"} , 
				BACKGROUND      : { label: "Background Color" , 	notRequired:true   , type: "COLOR" ,value:"", defaultvalue:"#FFF", size:"col-12" , sizesm:"col-sm-12", sizelg:"col-md-6", sizelg:"col-lg-6" },
				COLOR           : { label: "Text Color"       , 	notRequired:true   , type: "COLOR" ,value:"", defaultvalue:"#000", size:"col-12" , sizesm:"col-sm-12", sizelg:"col-md-6", sizelg:"col-lg-6" },
				OPACITY         : { label: "Transparency"     , 	notRequired:true   , type: "SELECT" , value:"", values: [ { text: "NO",value:"1" },{ value: "0.25",text:"75%" },{ value: "0.5",text:"50%" },{ value: "0.75",text:"25%" } ]  },
				FONTWEIGHT      : { label: "Font Weight"      , 	notRequired:true   , type: "SELECT" , value:"", values: [ { text: "Normal",value:"normal" },{ value: "bold",text:"Bold" }]  },
				TEXTDECORATION  : { label: "Text Decoration"  , 	notRequired:true   , type: "SELECT" , value:"", values: [ { text: "none",value:"normal" },{ value: "underline",text:"Underline" } ,{ value: "line-through",text:"Line Through" } ]  },
				CLOSABLE        : { label: "Closable"         , 	notRequired:true   , type: "SELECT" , value:"", values: [ { text: "YES",value:"YES" },{ value: "NO",text:"NO" } ]  }
				
		};
			
			this.setState({  ...this.state, formData:formData, completeMessage:completeMessage })	
	} 
	
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		
	 		await this.setMessageObj()	 									
	 		await preloadForm ( this.state.formData )
	 		
	 		
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	 
	  
  /*get value fields*/
  changeHandler = async(event, eventColor) => {
  	 
  	if (eventColor || !event.target.name){
  		var objName=  findObjName (eventColor)
  		event["target"] = {name:objName } 
  	}
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
	publishMessage=async (event) => {
		this.setState({  ...this.state, isLoading:true})
		try{	 
			console.log( this.state.completeMessage)
			
	  	let data= {"layoutname"  : "messages",
					       "replace"     : true,
					       foldername    : foldername+"private/MESSAGES/",
						     configuration : this.state.completeMessage }
			let resp = await callApi ( "s3", "createUploadFile", data )
			this.setState({...this.state,isLoading:false });
			if (resp.success){
		  	showMessage(toast,resp.message);
		  }else{
		  	showError(toast,resp.message);
		  }
			
			
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
 
	 
	/*charge data from a selected Message*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Message"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				
				this.state.formData.MESSAGEDESCS.value = undefined
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Message"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		
	  		let resp = await callApi ( "manager","getMessageById",{messageid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData,resp.MESSAGE)
	  		
	  		var optionNames=[], options=[]
	  		resp.MESSAGE.MESSAGEDESCS.map(function (item) {
	  			optionNames.push( {  id: item.LANGUAGEID, name: item.LANGUAGE,  value: item.MESSAGE })
	  		}); 
	  		
	  		this.state.formData.MESSAGENAME.value = resp.MESSAGE.MESSAGEDESCS[0].NAME
	  		this.state.formData.MESSAGEDESCS.value = undefined
	  		this.state.formData.MESSAGEDESCS.values = optionNames
	  		
	  		var aditionalconfig = JSON.parse( resp.MESSAGE.ADITIONALCONFIG)
	
	  		this.state.formData.LINK.value       = aditionalconfig.link
	  		this.state.formData.BACKGROUND.value = aditionalconfig.style.background
	  		this.state.formData.COLOR.value      = aditionalconfig.style.color
	  		this.state.formData.OPACITY.value    = aditionalconfig.style.opacity
	  		 
	  		this.state.formData.FONTWEIGHT.value     = aditionalconfig.style["font-weight"]
	  		this.state.formData.TEXTDECORATION.value = aditionalconfig.style["text-decoration"]
	  		
	  		this.state.formData.CLOSABLE.value = aditionalconfig.style.closable
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Message"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
					 
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
					_formData["messageid"] = this.state.currentId;
					
					var aditionalconfig = 
					{
						link  : _formData["link"]==""? "#": _formData["link"] ,
						style :  {
							background        : _formData["background"],
							color             : _formData["color"],
							opacity           : _formData["opacity"],
							"font-weight"     : _formData["fontweight"],
							"text-decoration" : _formData["textdecoration"],
							"closable"        : _formData["closable"]
						} 
					}
					
					
					_formData["aditionalconfig"] = aditionalconfig
					let resp = await callApi ( "manager",this.state.formAction+"Messages", _formData)
					
			  	if (resp.success){
			  		showMessage(toast,resp.message);
						await this.setMessageObj()						
						this.setState({  ...this.state, modal1:false})								
				 		await preloadForm ( this.state.formData )
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}

		
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
       <Modal open={this.state.modal2}
	      	icon="fas fa-images"
	      	title="Image Manager"
	      	zindex="1062"
	      	message={this.state.imageManager}
	      	toggle={this.toggleModal (2)} 
	      	class={this.state.modalLgClass}
	      />
      
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
													:
													 <Form 
															formData={this.state.formData} 
															manageImage={this.manageImage}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />
			 
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Layout Manager"} subtitle={"Messages"}/>
        	
        	<div className="row mb-4 justify-content-center text-right">
						<div className="col-md-12 col-lg-12">
								<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
								<button type="button" className="btn btn-sm btn-warning" name="set" onClick={this.publishMessage}> Publish Messages</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 