import React, { Component } from 'react';
import { Link,Redirect } from 'react-router-dom';
import Logo from '../../assets/images/logo-white.png';
import Auth from '../../services/Auth';
import {validateForm,showError} from '../../common/functions';
import PageLoading from '../../components/PageLoading';
import {toast,ToastContainer} from 'mdbreact';

 
 
class Login extends Component{
	//define variables
	constructor(props) {
		super(props);
		this.state = {
			isLoading : false,
			isLogged  : false,
			validForm : false,
			formData  : { username:  {name:'username' , label:"Correo", value: "admin" , type:"text", subType: "email",  valid:true },
								 		password:  {name:'password' , label:""      , value: "" , type:"password",  subType:"alphanumeric", valid:true },
								}
		}
	}
	//loading page
	componentDidMount() {
	 	//var token = Auth.loggedIn()
	 	/*
	 	if (Auth.loggedIn()){
	 		this.setState({isLogged:true});
	 	}*/
	 	
	 	if(Auth.loggedIn()){
        this.props.history.replace('/main');
      //  window.location.reload(false);
      }
      
	}
	//leaving page	 
	componentWillUnmount=()=>{
   	
  }	 
	//manage change Form Data
	changeHandler = async(event) => {
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
	} 
	
	//custom functions
	login = (e) => {
		
    e.preventDefault();
		this.setState({isLoading:true});
		
    Auth.login(this.state.formData.username.value, this.state.formData.password.value)
      .then((res) => {
      	if (res.success){
      		//this.props.history.replace('/main');
	        window.location.reload(false);
      		//this.setState({isLogged:true});
      	}else{ 
      		showError(toast, res.message)
      	}
    }).catch(err => { showError(toast, err) } )
    .finally(() => this.setState({isLoading:false}) );
  }
  
	//rendering page
	render(){ 
		
		if (this.state.isLogged) {
	    return (
	      <Redirect to="/main" />
	    )
 		}
	  	return (
				<div>
					<PageLoading isLoading={this.state.isLoading}/>
					<div className="main-wrapper login-body">
						<div className="login-wrapper">
							<div className="container">					
								<div className="loginbox">
									<div className="login-left">
										<img className="img-fluid" src={Logo} alt="Logo" />
									</div>
									<div className="login-right">
										<div className="login-right-wrap">
										
											<h1>Login</h1>
											<p className="account-subtitle">Access to our Admin</p>
											<form onSubmit={this.login}>
												
												{  Object.keys(this.state.formData).map(field => (
			                  	<div key={"div_"+this.state.formData[field].name} className="form-group">
														<input key={"input_"+this.state.formData[field].name} className="form-control" 
														placeholder={this.state.formData[field].defaultValue?this.state.formData[field].defaultValue: this.state.formData[field].label}
														name ={this.state.formData[field].name}
														type={this.state.formData[field].type}
														value={this.state.formData[field].value}
		              					onChange={this.changeHandler}
		              				 	/>
													</div>
			                  ))
			                  } 
												
												<div className="form-group">
													<button className="btn btn-primary btn-block" type="submit">Login</button>
												</div>
											</form>
											<div className="text-center forgotpass"><Link to="/forgotPassword">Forgot Password?</Link></div>											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
	  
	}
}
export default Login;
	 	