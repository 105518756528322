import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import Folder from "../../assets/images/folder.png";
import {getImagePath } from '../../common/functions';
const windowHeight = window.innerHeight
const ImgagesExtension = process.env.REACT_APP_DEF_IMG_EXTENSION
const loadImagePath = props => {
	
	if (props.objImages){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12"  >
					{props.objImages.length>0?
		  			<div className="row">
							<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3 text-center"}><span className="h6-responsive" >Image Path</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}><span className="h6-responsive" > Total Images</span></div>
							<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1"}> </div>
						</div>	
					:""}
				  {props.objImages.map(itemM => (
				  	<>
							<div className="row mb-1 flex">
						 		<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3 text-center"}>
							 		<div>
							 			
								 		<img	
												key={"img"+itemM.rowId} 
												name={"manageImageFolder_"+itemM.rowId} 
												onClick={props.manageImageFolder} 
												style={{"cursor": "pointer"}}
												width="90px" height="90px" 
												src={ 
													itemM.path.indexOf(ImgagesExtension)>=0?getImagePath(itemM.path):Folder
													} />
										<p style={{marginBottom:0  }}>
											<input key={props.container+"_path_"+itemM.rowId}  
											  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_path?"is-invalid":"")}
												value={itemM.path}
												name={props.container+"_path_"+itemM.rowId}
												onChange={props.changeHandler} /> 
							 			</p>
							 			<p style={{marginBottom:0  }}>
								 			<button  className="btn btn-info btn-sm btn-block mt-1 text-left " 
								 				name={"manageImageFolder_"+itemM.rowId}  
								 				onClick={props.manageImageFolder}
								      	type="button"> <i className="m-l-15 fas fa-folder"  ></i> Choose Image Path 
								    	</button>
							      </p>
							      {props.manageImage?
							      <p style={{marginBottom:0  }}>
								 			<button  className="btn btn-secondary btn-sm btn-block mt-1 text-left" 
									 			name={"manageImage_"+itemM.rowId} 
												onClick={props.manageImage} 
									      type="button"> <i className="m-l-15 fas fa-file"  ></i> Choose Image 
									    </button>
							      </p>
							    	:""}
							  	</div>			

							 		</div>
						 			
						    <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
						    	<input key={props.container+"_sortorder_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_sortorder?"is-invalid":"")}
										value={itemM.sortorder}
										name={props.container+"_sortorder_"+itemM.rowId}
										onChange={props.changeHandler} />
						    </div>						  	 				  	
						  	<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={props.container+"_"+itemM.rowId} onClick={props.delRow}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>
					  </>
					  ))
						} 
						{props.addRow?
							<div className="row">
								<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}> 
								
								{props.objImages.length==0?
									<button  className="btn btn-info btn-sm"  name={props.container}
					           onClick={props.addRow} type="button"> <i className="fas fa-plus"></i> 
					        </button>
					      :""}  
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO Image DATA"
	}
};
export default loadImagePath;
				 