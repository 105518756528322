/*****components***/
import React, { Component } from "react";
import { Switch } from "antd";
import { toast, ToastContainer } from "mdbreact";
/*Discount*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from "../../components/PageLoading";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import Form from "../../components/Form";
import PageHeader from "../../components/PageHeader";
import ImageManager from "../../components/ImageManager";
/*functions*/
import {
  getUserInfo,
  listImages,
  toFixed,
  filterHandler,
  getLanguages,
  findObjName,
  find,
  validateComponent,
  validateRole,
  encryptText,
  mergeFormData,
  clearformData,
  callApi,
  validateForm,
  showError,
  showMessage,
  preloadForm,
  parseFormData,
} from "../../common/functions";

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date();
var fromDate = new Date();
fromDate.setDate(f.getDate() - 30);

var mes =
  f.getUTCMonth() + 1 < 10 ? "0" + (f.getUTCMonth() + 1) : f.getUTCMonth() + 1;
var dia = f.getUTCDate() < 10 ? "0" + f.getUTCDate() : f.getUTCDate();
var date_label = f.getUTCFullYear() + "." + mes + "." + dia;
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;
class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_label: date_label,
      modal1: false,
      modal2: false,
      modalMdClass: "modal-lg",
      isLoading: true,
      componentType: "",
      modalClass: "modal-full-height modal-lg",
      modalIamgeClass: "modal-lg",
      /*modal info*/
      buttonsModal2: [
        {
          buttonAction: this.validateComponent,
          buttonId: 1,
          buttonClass: "btn btn-outline-primary",
          buttonName: "Save",
        },
        {
          buttonAction: this.cancelComponent,
          buttonId: 2,
          buttonClass: "btn btn-danger",
          buttonName: "Cancel ",
        },
      ],
      filterConfig: {
        TYPE: {
          label: "STATUS",
          type: "SELECT",
          value: "",
          values: ["", "COMBO", "DISCOUNT", "PACK", "BEFORE-NOW"],
        },
        SKU: { label: "SKU", type: "TEXT", value: "" },
        NAME: { label: "Combo Name", type: "TEXT", value: "" },
      },
      logs: [],
      formConfig2: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
      },

      originalTableData: {},
      /*table data*/

      formData2: {
        JOBDATE: {
          label: "Job Date",
          size: "col-12",
          type: "DATEPICKER",
          value: new Date(),
        },
      },

      tableData: {
        columns: [
          {
            dataIndex: "SKU",
            title: "SKU",
            sorter: (a, b) => a.SKU.length - b.SKU.length,
          },
          {
            dataIndex: "PRICE",
            title: "Price",
            sorter: (a, b) => a.PRICE.length - b.PRICE.length,

            render: (text, record) => (
              <div className="text-right">{toFixed(record.PRICE)} </div>
            ),
          },
          {
            dataIndex: "TYPE",
            title: "Type",
            sorter: (a, b) => a.TYPE.length - b.TYPE.length,
          },
          {
            dataIndex: "DISCOUNT",
            title: "Discount",
            sorter: (a, b) => a.DISCOUNT.length - b.DISCOUNT.length,
            render: (text, record) => (
              <div className="text-right">{toFixed(record.DISCOUNT)} </div>
            ),
          },

          {
            dataIndex: "NAME",
            title: "Combo Name",
            sorter: (a, b) => a.NAME.length - b.NAME.length,
          },
          {
            dataIndex: "FROMDATE",
            title: "From",
            sorter: (a, b) => a.FROMDATE.length - b.FROMDATE.length,
          },
          {
            dataIndex: "TODATE",
            title: "To",
            sorter: (a, b) => a.TODATE.length - b.TODATE.length,
          },

          {
            dataIndex: "STORE",
            title: "Store",
            sorter: (a, b) => a.STORE.length - b.STORE.length,
          },

          {
            dataIndex: "SKUID",
            title: "Action",
            render: (text, record) => (
              <div className="text-right">
                <button
                  className="btn btn-sm btn-info"
                  id={record.SKU + "__" + record.STOREID}
                  name="update"
                  onClick={this.loadFormData}
                  type="submit"
                >
                  Edit
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  id={record.SKU + "__" + record.STOREID}
                  name="delete"
                  onClick={this.loadFormData}
                  type="submit"
                >
                  Delete
                </button>
              </div>
            ),
          },
        ],
        rows: [],
      },
      /*modal info*/
      buttonsModal1: [
        {
          buttonAction: this.set,
          buttonId: 1,
          buttonClass: "btn btn-outline-primary",
          buttonName: "Add",
        },
      ],

      buttonsModal2: [
        {
          buttonAction: this.setJob,
          buttonId: 1,
          buttonClass: "btn btn-outline-primary",
          buttonName: "Add",
        },
      ],

      modalIcon: "fas fa-edit",
      modalTitle: "New Discount",
      /*form data*/

      formConfig: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
        languages: [],
      },
      formData: {
        SKU: { label: "SKU", validationType: "text", type: "TEXT" },
        PRICE: {
          label: "Price",
          notRequired: true,
          validationType: "number",
          type: "TEXT",
          value: "",
        },
        TYPE: {
          label: "Type",
          notRequired: true,
          type: "SELECT",
          value: "",
          values: [
            { text: "COMBO", value: "COMBO" },
            { text: "DISCOUNT", value: "DISCOUNT" },
            { text: "BEFORE-NOW", value: "BEFORE-NOW" },
            { text: "PACK", value: "PACK" },
          ],
        },
        DISCOUNT: {
          label: "Discount",
          notRequired: true,
          validationType: "number",
          type: "TEXT",
          value: "",
        },
        NAME: { label: "Name", notRequired: true, type: "TEXT", value: "" },
        FROMDATE: { label: "From", type: "DATEPICKER", value: new Date() },
        TODATE: { label: "To", type: "DATEPICKER", value: new Date() },
        STOREID: {
          label: "Store",
          notRequired: true,
          type: "SELECT",
          value: "",
          values: [],
          addDefault: "ALL STORES",
          getDataDB: {
            apiMethod: "getDetailDomainsByCode",
            aditionalValues: [{ domainid: "15" }],
          },
        },
      },
      /*support variables*/
      currentId: null,
      formAction: "set",
      deleteMessage: "Are you sure to delete this item?",
      completeDiscount: [],
    };
  }
  /*loading data*/

  setJob = async (event) => {
    let _formData = this.state.formData2;
    _formData = parseFormData(_formData);
    _formData["components"] = "";
    _formData["filename"] = "JOB__DISCOUNTS";
    var resp = await callApi("s3", "programFile", _formData);

    if (resp.success) {
      showMessage(toast, resp.message);
      this.setState({ ...this.state, modal2: false });
    } else {
      showError(toast, resp.message);
    }
  };

  getDiscountObj = async (event) => {
    let languages = await getLanguages();
    var rows = [];
    var parents = [];

    var completeDiscount = [];

    let resp = await callApi("manager", "getDiscounts");
    let Discounts = [];
    for (let k in resp.rows) {
      let obj = resp.rows[k];
      Discounts.push(obj);
    }
    completeDiscount = Discounts;

    const tableData = this.state.tableData;
    tableData.rows = resp.rows;
    this.setState({ originalTableData: this.state.tableData });
    this.setState({ ...this.state, completeDiscount: completeDiscount });
  };

  componentDidMount = async () => {
    var pageId = this.props.match.params.pageId;
    try {
      await this.getDiscountObj();
      await preloadForm(this.state.formData);
    } catch (err) {
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ isLoading: false });
    }
  };
  /*Form Functions*/

  //filter table
  filterHandler = async (event) => {
    let resp = await filterHandler(
      event,
      this.state.filterConfig,
      this.state.originalTableData
    );
    if (resp.filterConfig) {
      this.setState({ filterConfig: resp.filterConfig });
    }
    if (resp.tableData) {
      this.setState({ tableData: resp.tableData });
    }
  };

  deleteJob = async (componentid, pos) => {
    this.setState({ isLoading2: true });
    let resp = await callApi("layaoutManager", "deleteComponents", {
      componentid: componentid,
    });
    if (resp.success) {
      showMessage(toast, resp.message);
      const logs = this.state.logs;
      let pos = find(logs, componentid, "COMPONENTID");
      console.log(logs, pos);
      logs.splice(pos, 1);
      this.setState({ isLoading2: false });
    } else {
      showError(toast, resp.message);
      this.setState({ isLoading2: false });
    }
  };

  changeHandler2 = async (event) => {
    let resp = await validateForm(this.state.formData2, toast, event);
    this.setState({ formData2: resp.formData });
  };
  /*get value fields*/
  changeHandler = async (event, eventColor) => {
    if (eventColor || !event.target.name) {
      var objName = findObjName(eventColor);
      event["target"] = { name: objName };
    }

    let resp = await validateForm(this.state.formData, toast, event);
    this.setState({ formData: resp.formData });
  };

  toggleModal = (nr) => () => {
    this.setState({ ["modal" + nr]: !this.state["modal" + nr] });
  };

  //add new
  set = async (event) => {
    this.execute(event, "SET");
  };
  //edit
  update = async (event) => {
    this.execute(event, "UPDATE");
  };
  //delete
  delete = async (event) => {
    this.execute(event, "DELETE");
  };

  publishDiscount = async (event) => {
    this.setState({ ...this.state, isLoading: true });

    try {
      let _resp = await callApi("manager", "getDiscounts");
      let Discounts = [];
      for (let k in _resp.rows2) {
        let obj = _resp.rows2[k];
		if (obj.name==="COMBO"){			
			Discounts.push(obj);
		}
      }
      let data = {
        layoutname: "discounts",
        replace: true,
        foldername: foldername + "private/MAINDISCOUNTS/",
        configuration: Discounts,
      };
      let resp = await callApi("s3", "createUploadFile", data);
      this.setState({ ...this.state, isLoading: false });
      if (resp.success) {
        showMessage(toast, resp.message);
      } else {
        showError(toast, resp.message);
      }
    } catch (err) {
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  showJobs = async (event) => {
    this.setState({ ...this.state, isLoading: true });

    try {
      var logs = [];
      let resp2 = await callApi("layaoutManager", "getLogComponentById", {
        layoutFile: "JOB__DISCOUNTS",
      });
      for (let l in resp2.logs) {
        logs.push(resp2.logs[l]);
      }

      this.setState({ ...this.state, isLoading: false, logs: logs });
    } catch (err) {
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ ...this.state, isLoading: false, modal2: true });
    }
  };

  /*charge data from a selected Discount*/
  loadFormData = async (event) => {
    this.setState({ ...this.state, isLoading: true });
    try {
      var buttonsModal1;
      var modalIcon;
      var modalTitle;
      var formAction;
      var itemId;
      var modalClass;
      if (event.target.name === "set") {
        buttonsModal1 = [
          {
            buttonAction: this.set,
            buttonId: 1,
            buttonClass: "btn btn-outline-primary",
            buttonName: "Add",
          },
        ];
        modalIcon = "fas fa-plus";
        modalTitle = "New Discount";
        formAction = "set";
        itemId = null;
        modalClass = "modal-full-height modal-lg";
        let loadData = await clearformData(this.state.formData);
      } else if (event.target.name === "update") {
        buttonsModal1 = [
          {
            buttonAction: this.update,
            buttonId: 1,
            buttonClass: "btn btn-outline-info",
            buttonName: "Edit",
          },
        ];
        modalIcon = "fas fa-edit";
        modalTitle = "Edit Discount";
        itemId = event.target.id;
        formAction = "update";
        modalClass = "modal-full-height modal-lg";

        var data = itemId.split("__");
        var sku = data[0];
        var storeid = data[1];
        let resp = await callApi("manager", "getDiscountById", {
          sku: sku,
          storeid: storeid,
        });

        let loadData = await mergeFormData(this.state.formData, resp.discount);

        this.state.formData["STOREID"]["disabled"] = true;

        this.state.formData["PRICE"]["value"] = toFixed(resp.discount.PRICE);
        this.state.formData["DISCOUNT"]["value"] = toFixed(
          resp.discount.DISCOUNT
        );

        /*
	  		
	  		if (validConfig){ 
	  			let ADITIONALCONFIG =  JSON.parse(resp.Discount.ADITIONALCONFIG);
	  			
	  			for (let i in Object.keys(ADITIONALCONFIG)){
	  				let item =Object.keys(ADITIONALCONFIG)[i]
		  			this.state.formData[item.toUpperCase() ].value = ADITIONALCONFIG[item]
		  		}
		  		
	  		}else{
	  			
	  			for (let i in Object.keys(this.state.formData)){
	  				let item =Object.keys(this.state.formData)[i]
	  				if (item.startsWith("CONFIG")){
	  					this.state.formData[item.toUpperCase() ].value = ""	
	  				}
		  			
		  		}
	  			
	  		}
	  		
	  		var optionNames=[], options=[]
	  		resp.Discount.DiscountNAMES.map(function (item) {
	  			optionNames.push( {  id: item.LANGUAGEID, name: item.LANGUAGE,  value: item.NAME })
	  		}); 
	  		 
	  		this.state.formData.DiscountNAMES.value = undefined
	  		this.state.formData.DiscountNAMES.values = optionNames
	  		*/
      } else if (event.target.name === "delete") {
        buttonsModal1 = [
          {
            buttonAction: this.delete,
            buttonId: 1,
            buttonClass: "btn btn-outline-danger",
            buttonName: "Delete",
          },
        ];
        modalIcon = "fas fa-trash";
        modalTitle = "Delete Discount";
        itemId = event.target.id;
        formAction = "delete";
        modalClass = "";
      }
    } catch (err) {
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({
        ...this.state,
        modal1: true,
        isLoading: false,
        currentId: itemId,
        modalClass,
        formAction: formAction,
        buttonsModal1: buttonsModal1,
        modalIcon: modalIcon,
        modalTitle: modalTitle,
      });
    }
  };

  /*ABM manager*/
  execute = async (event, actionType) => {
    try {
      var resp = validateRole(this.props.location.pathname);
      if (resp) {
        /*validation*/
        var respValid = true;
        if (actionType === "DELETE") {
          respValid = true;
        } else {
          resp = await validateForm(this.state.formData, toast);
          respValid = resp.isValid;
        }
        if (respValid) {
          this.setState({ ...this.state, isLoading: true });
          let _formData = this.state.formData;
          _formData = parseFormData(_formData);

          if (actionType === "DELETE") {
            var data = this.state.currentId.split("__");
            var sku = data[0];
            var storeid = data[1];
            _formData["sku"] = sku;
            _formData["storeid"] = storeid;
          }

          var aditionalconfig = {};
          for (let i in Object.keys(_formData)) {
            var field = Object.keys(_formData)[i];
            if (field.startsWith("config")) {
              aditionalconfig[field] = _formData[field];
            }
          }
          _formData["aditionalconfig"] = aditionalconfig;
          let resp = await callApi(
            "manager",
            this.state.formAction + "Discounts",
            _formData
          );

          if (resp.success) {
            showMessage(toast, resp.message);
            await this.getDiscountObj();
            this.filterHandler({ target: { name: "search" } });
            this.setState({ ...this.state, modal1: false });
            await preloadForm(this.state.formData);
          } else {
            showError(toast, resp.message);
          }
        }
      } else {
        showError(toast, "Role not allowed to make changes");
      }
    } catch (err) {
      showError(toast, "Internal Error");
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  render() {
    return (
      <>
        <SidebarNav pageId={this.props.match.params.pageId} />
        <PageLoading isLoading={this.state.isLoading} />

        <Modal
          open={this.state.modal1}
          toggle={this.toggleModal(1)}
          class={this.state.modalClass}
          icon={this.state.modalIcon}
          title={this.state.modalTitle}
          buttons={this.state.buttonsModal1}
          cancelButton={true}
          message={
            this.state.formAction === "delete" ? (
              <h2>{this.state.deleteMessage}</h2>
            ) : (
              <Form
                formData={this.state.formData}
                changeHandler={this.changeHandler}
                formConfig={this.state.formConfig}
              />
            )
          }
        />

        <Modal
          open={this.state.modal2}
          toggle={this.toggleModal(2)}
          class={""}
          title={"Program Discounts"}
          buttons={this.state.buttonsModal2}
          cancelButton={true}
          message={
            <>
              <Form
                formData={this.state.formData2}
                changeHandler={this.changeHandler2}
                formConfig={this.state.formConfig2}
              />

              <table className="table">
                <thead>
                  <tr className="main-heading">
                    <th scope="col"> JOB DATE </th>
                    <th scope="col"> JOB EXECUTION DATE</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.logs.map(
                    (item, index) =>
                      index < 5 && (
                        <tr>
                          <td className=" ">{item.JOB_DATE}</td>
                          <td className=" ">{item.EXE_DATE}</td>
                          <td className=" ">
                            {item.RESP == "-" ? "PND" : item.RESP}
                          </td>
                          <td className=" ">
                            {item.RESP == "-" ? (
                              <button
                                className="btn btn-danger btn-sm"
                                name={"DELROW_" + item.COMPONENTID}
                                onClick={() => {
                                  this.deleteJob(item.COMPONENTID);
                                }}
                                type="button"
                              >
                                {" "}
                                <i className="fas fa-trash"></i>{" "}
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger btn-sm"
                                disabled
                                type="button"
                              >
                                {" "}
                                <i className="fas fa-trash"></i>{" "}
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </>
          }
        />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <PageHeader title={"Sales"} subtitle={"Discount"} />

            <div className="row mb-4 justify-content-center text-right">
              <div className="col-md-12 col-lg-12">
                <ExcelFile
                  filename={"export-discounts." + this.state.date_label}
                  element={
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      name="set"
                    >
                      {" "}
                      Export
                    </button>
                  }
                >
                  <ExcelSheet data={this.state.tableData.rows} name="Discounts">
                    <ExcelColumn label="SKU" value="SKU" />
                    <ExcelColumn label="Price" value="PRICE" />
                    <ExcelColumn label="Type" value="TYPE" />

                    <ExcelColumn label="Discount" value="DISCOUNT" />
                    <ExcelColumn label="Combo Name" value="TYPE" />
                    <ExcelColumn label="From" value="FROMDATE" />
                    <ExcelColumn label="To" value="TODATE" />
                    <ExcelColumn label="Store" value="STORE" />
                    <ExcelColumn label="Type" value="TYPE" />
                  </ExcelSheet>
                </ExcelFile>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  name="set"
                  onClick={this.loadFormData}
                >
                  {" "}
                  Add New
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-warning"
                  name="set"
                  onClick={this.publishDiscount}
                >
                  {" "}
                  Publish Discounts
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  name="set"
                  onClick={this.showJobs}
                >
                  {" "}
                  Program Discounts
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="card card-table flex-fill">
                  <Table
                    tableData={this.state.tableData}
                    filterConfig={this.state.filterConfig}
                    filterHandler={this.filterHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
