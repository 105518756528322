/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import Tab from '../../components/Tab';
import Card from '../../components/Card';
import SidebarNav from "../sidebar";

/*functions*/
import {loadPage, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage,showWarning,  preloadForm,parseFormData} from '../../common/functions';

var windowHeight = window.innerHeight

class page extends Component{

	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			isLoading: true,
			mainClass:"page-wrapper-left",
			parData:[
				{	elementType: "Card",
					name : "Card1",
					content:  [
						{	header: "Protocolos"  , 
							cardWidth: "col-12 col-md-6 col-lg-4",
							body: 
								[ {type:"text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
								  {type: "link", target:"_blank", url:"www.google.com", text:"version 1"},
								  {type: "link", target:"_blank", url:"www.google.com", text:"version 2"}
								],
						},
						{	header: "Reglamentos Internos de los fondos de inversion"  , 
							cardWidth: "col-12 col-md-6 col-lg-4",
							body: 
								[ {type: "text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
									{type: "text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
									{type: "text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
								  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 1"},
								  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 2"},
								 
								]
						},
						{ children: "Card1Form1",
							//header: "Procedimientos"  , 
							cardWidth: "col-12 col-md-6 col-lg-4"
							}
					],
					config:{
						pageWidth: "col-md-12 col-lg-12 col-xl-12",
						groupingRows: 3,
					}
				},
				{ elementType: "Form",
					isChildren: true,
					name: "Card1Form1",
					parent: "Card1",
					formData	: {
						USERNAME     : { label: "User Name"       , type: "TEXT",   value: ""  },
						FIRSTNAME    : { label: "First Name"      , type: "TEXT",   value: ""  },
					},
					config:{
						pageWidth: "card col-md-12 col-lg-12 col-xl-12",
						groupingRows: 2,
						formTitle:"Registro"
					}
				},
				{ elementType: "Slider",
					name:"Slider1",
					config : {
		      	dots: true,
		      //	infinite: true,
		      //	speed: 500,
		      	slidesToShow: 1,
		      	slidesToScroll: 1,
		      //	autoplay: true,
		      	cssEase: "linear",
		      	pauseOnHover: true,
		      	divWidth: "card col-md-12 col-lg-12 col-xl-12"
		    	},
		    	content:[
		    		
		    		{	
		    			header: "Saludos" , 
							body: "hola"
		    		},
		    		{	
		    			header: "Organizacion y Metodos"  , 
		    			children: "Slider1Card1" 
		    		},
		    		{	
		    			header: "Integrantes equipo"  , 
		    			children: "Slider1List1" 
		    		}
		    	]
				},
				{	elementType: "Card",
					name : "Slider1Card1",
					parent: "Slider1",
					isChildren: true,
					content:  [
						{	header: "card hijo 1"  , 
							cardWidth: "col-12 col-md-12 col-lg-6",
							body: 
								[ {type:"text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
								  {type: "link", target:"_blank", url:"www.google.com", text:"version 1"},
								  {type: "link", target:"_blank", url:"www.google.com", text:"version 2"}
								],
						},
						{	header: "card hijo 2"  , 
							cardWidth: "col-12 col-md-12 col-lg-6",
							body: 
								[ {type: "text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
									{type: "text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
									{type: "text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
								  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 1"},
								  
								]
						}
					],
					config:{
						pageWidth: "col-md-12 col-lg-12 col-xl-12",
						groupingRows: 2,
					}
				},
				{ elementType: "List",
					isChildren: true,
					name: "Slider1List1",
					parent: "Slider1",
					content	: [
						 { type: "text", text: "LAS NORMAS INTENAS DE CONDUCTA (CODIGO DE ETICA)" ,   },
						 { type: "text", text: "LAS NORMAS INTENAS DE CONDUCTA " ,  },
						 { type: "link", url: "http://www.google.com" , target:"_self" ,   text: "visitar google"  },
						 { type: "list", values: [
						 		{ type: "text", text: "LAS NORMAS INTENAS DE CONDUCTA (CODIGO DE ETICA)" },
						 		{ type: "link", url: "http://10.17.20.251:8089/intranet safi/downloadFile/TN.SAFI.001.pdf" , target:"_top" },
						 		{ type: "link", url: "http://www.google.com" ,  target:"_self"  , text : "ir a google"  },
						   ] 
						 }
					],
				},
			]
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	
	 	try{ 
	 		console.log("init")
			let _pageData =  loadPage(this.state.parData);
			console.log("RESPUESA FINAL")
			console.log(_pageData)
			this.setState({pageData:_pageData});
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	
   
	render(){
    return(
    <>
    	<PageLoading isLoading={this.state.isLoading}/>
      <div className={this.state.mainClass}>
	    	<div className="content container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
							<>
								{this.state.pageData}
							</>
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 