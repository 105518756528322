//componets
import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

//defined pages
import Header from "./pages/header/index";
import SidebarNav from "./pages/sidebar";
import Dashboard from "./pages/dashboard";
import GenericReport from "./pages/genericReport";
import Login from "./pages/login";
import Forgotpassword from "./pages/forgotpassword";
import Logout from "./pages/logout";
import Register from "./pages/register";
import Profile from "./pages/profile";
import Settings from "./pages/settings";
import GenericPage from "./pages/lockscreen"
import Auth from './services/Auth';

import {getUserInfo} from './common/functions';

//manager pages
import ManageUsers from "./pages/manageUsers";
import ManageUserGroups from "./pages/manageUserGroups";
import ManageOptions from "./pages/manageOptions";
import ManageCategories from "./pages/manageCategories";
import ManageProducts from "./pages/manageProducts";
import ManageAttributes from "./pages/manageAttributes";
import manageInterfaces from "./pages/manageInterfaces";
import ManageComponents from "./pages/manageComponents";
import ManageLayouts from "./pages/manageLayouts";
import ManagePrivateLayouts from "./pages/managePrivateLayouts";
import ManageAttributeGroups from "./pages/manageAttributeGroups";
import ManageCustomerGroups from "./pages/manageCustomerGroups";
import ManageManufacturers from "./pages/manageManufacturers";
import ManageHeaderMenu from "./pages/manageHeaderMenu";
import ManageTopMessages from "./pages/manageTopMessages";
import ManageOrders from "./pages/manageOrders";
import ManageProductsQty from "./pages/manageProductsQty";
import ManageLoads from "./pages/manageLoads";
import ManageTags from "./pages/manageTags";
import ManageStores from "./pages/manageStores";
import ManageEvents from "./pages/manageEvents";

import ManageDiscounts from "./pages/manageDiscounts";
import ManageCustomers from "./pages/manageCustomers";
import ManageCoupons from "./pages/manageCoupons";
import ManageCouponsCustomers from "./pages/manageCouponsCustomers";
import ManageBlogs from "./pages/manageBlogs";
import ManageRelatedProducts from "./pages/manageRelatedProducts";
import ManagePolygons from "./pages/managePolygons";
import ManageCustomerEvents from "./pages/manageCustomerEvents";


import ReportExportOrders from "./pages/reportExportOrders";
import reportAbandonedCart from "./pages/reportAbandonedCart";


//customer pages
import customPage from "./pages/customPage";
import Users from "./pages/users";
import Urls from "./pages/urls";
import Procedures from "./pages/procedures";

//custom apis
import Api, { setApiToken } from './common/api';

class Routes extends React.Component {
	constructor(props){
    super(props);
    this.state={
      changeReport: false ,
      changeFormPage: false
    }
  }
  //onClickRadio = nr => () => {
  callback = (i) => {
  	var change = this.state["change"+i]
    this.setState({
        ["change"+i] : change?false:true
    })
  }
  
  componentDidMount = async() => {
		
		if (!localStorage.getItem('wfl_def_language') || localStorage.getItem('wfl_def_language') == 'undefined' || localStorage.getItem('wfl_def_language') == null ){
		 	 localStorage.setItem('wfl_def_language', getUserInfo().languageid);
		}
		
		require('./assets/css/bootstrap.css')
		require('./assets/css/feathericon.min.css')
		require('./assets/plugins/fontawesome/css/fontawesome.min.css')
		require('./assets/plugins/fontawesome/css/all.min.css')
		require('./assets/css/font-awesome.min.css')
		require('./assets/css/style.css')
		//require('./assets/css/uicons-regular-straight.css')
		
		 //carousel
    require("./assets/css/slick/slick.css"); 
    require("./assets/css/slick/slick-theme.css");
    
    //table
    require("antd/dist/antd.css");

	}	
 
  render() {
  	setApiToken(Auth.getToken() );
  	return (	
  		<Router>
        
        <div>
        	<Route render={(props) => <Header  firstTimeTrue={this.firstTimeTrue} firstTime={this.state.firstTime}  {...props} />} />
          <Switch>
          	<Route path="/" exact component={Login} />
          	<Route path="/forgotPassword" exact component={Forgotpassword} />
          	
          	<Route path="/login" exact component={Login} />
	        	<Route path="/logout" exact component={Logout} />
	        	<Route path="/main" exact component={Dashboard} />
	        	<Route path="/register" exact component={Register} />
	        	<Route path="/profile" exact component={Profile} />
	        	<Route path="/settings" exact component={Settings} />
	        	
	        	<Route path="/manageUsers/:pageId" exact component={ManageUsers} />
	        	<Route path="/manageUserGroups/:pageId" exact component={ManageUserGroups} />
	        	<Route path="/manageOptions/:pageId" exact component={ManageOptions} />
	        	<Route path="/manageCategories/:pageId" exact component={ManageCategories} />
	        	<Route path="/manageProducts/:pageId" exact component={ManageProducts} />
	        	<Route path="/manageAttributes/:pageId" exact component={ManageAttributes} />
				<Route path="/manageInterface/:pageId" exact component={manageInterfaces} />
				
	        	<Route path="/manageAttributeGroups/:pageId" exact component={ManageAttributeGroups} />
	        	<Route path="/manageCustomerGroups/:pageId" exact component={ManageCustomerGroups} />
	        	<Route path="/manageManufacturers/:pageId" exact component={ManageManufacturers} />
	        	
	        	<Route path="/manageComponents/:pageId" exact component={ManageComponents} />
	        	<Route path="/manageLayouts/:pageId" exact component={ManageLayouts} />
	        	<Route path="/managePrivateLayouts/:pageId" exact component={ManagePrivateLayouts} />
	        	
	        	<Route path="/manageHeaderMenu/:pageId" exact component={ManageHeaderMenu} />
	        	<Route path="/manageTopMessages/:pageId" exact component={ManageTopMessages} />
	        	<Route path="/manageTags/:pageId" exact component={ManageTags} />
	        	<Route path="/manageStores/:pageId" exact component={ManageStores} />
	        	<Route path="/manageEvents/:pageId" exact component={ManageEvents} />
	        	
	        	
	        	<Route path="/manageDiscounts/:pageId" exact component={ManageDiscounts} />
	        	<Route path="/manageBlogs/:pageId" exact component={ManageBlogs} />
	        	<Route path="/manageRelatedProducts/:pageId" exact component={ManageRelatedProducts} />
	        	<Route path="/managePolygons/:pageId" exact component={ManagePolygons} />
	        	<Route path="/manageCustomerEvents/:pageId" exact component={ManageCustomerEvents} />
	        	
	        	
	        	<Route path="/manageCustomers/:pageId" exact component={ManageCustomers} />
	        	<Route path="/manageCoupons/:pageId" exact component={ManageCoupons} />
	        	<Route path="/manageCouponsCustomers/:pageId" exact component={ManageCouponsCustomers} />
	        	 
	        	<Route path="/ManageOrders/:pageId" exact component={ManageOrders} />
	        	<Route path="/manageProductsQty/:pageId" exact component={ManageProductsQty} />	
				<Route path="/manageLoads/:pageId" exact component={ManageLoads} />	
				<Route path="/reportExportOrders/:pageId" exact component={ReportExportOrders} />			
				<Route path="/reportAbandonedCart/:pageId" exact component={reportAbandonedCart} />	
	        	
				
	        	
	        	
	        	
	        	<Route path="/users" exact component={Users} />
	        	<Route path="/urls" exact component={Urls} />
	        	<Route path="/procedures" exact component={Procedures} />
	        	<Route path="/pages/:pageId" exact component={customPage} />
	        	
	        		        	
						{this.state.changeReport? 
      	 		<Route exact path="/main/g_report/:pageId"  component={(props) => <GenericReport callback={this.callback} {...props} />} />:
      	  	<Route exact path="/main/g_report/:pageId" render={(props) => <GenericReport callback={this.callback} {...props} />} /> }
      	  	{this.state.changeFormPage? 
      	 		<Route path="/main/g_page/:pageId"  component={(props) => <GenericPage {...props} />} />:
      	  	<Route exact path="/main/g_page/:pageId" component={GenericPage} /> }
        	</Switch>
           
     		</div>
      </Router>
		);
  }
}

export default Routes;
