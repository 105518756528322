import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import {getImagePath } from '../../common/functions';
import defaultImage from "../../assets/images/default.jpg";
const windowHeight = window.innerHeight
const loadCompHeaderIconItem = props => {

	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" >
					{props.data.length>0?
		  			<div className="row">
		  				<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Image </span></div>
		  				<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Image Width </span></div>
		  				<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Image Opacy </span></div>
		  				<div className={"col-sm-12 col-sm-12 col-lg-1 col-xl-1"}><span className="h6-responsive" >Label </span></div>
		  				<div className={"col-sm-12 col-sm-12 col-lg-1 col-xl-1"}><span className="h6-responsive" >Link Url </span></div>
		  				<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Target</span></div>
		  				<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Font Weight </span></div>
							<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Session </span></div>
							<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Type</span></div>
							<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Width</span></div>
							<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Height</span></div>
							<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Align</span></div>
							
						</div>	
					:""}
				  {props.data.map(itemM => (
				  	 
							<div className="row mb-1 flex middle">
								<div className="col-md-6 col-sm-6 col-lg-1 col-xl-1">
							 			<img 
											key={"img"+itemM.rowId} 
											name={"manageImage_CompHeaderIconItem_"+props.container+"_image_"+itemM.rowId}
											onClick={props.manageImage} 
											style={{"cursor": "pointer"}}
											width="45px" height="45px" 
											src={ itemM.image==""?defaultImage:getImagePath(itemM.image)} />
							  </div>	
								<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 					<input key={props.container+"_imagewidth_"+itemM.rowId}  type="text" 
					 					className={"form-control form-control-sm "+ (itemM.isInvalid_imagewidth?"is-invalid":"")}
										value={itemM.imagewidth}
										name={"FIELDVALUE_"+props.container+"_imagewidth_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>	 
						  	<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 					<input key={props.container+"_imageopacy_"+itemM.rowId}  type="text" 
					 					className={"form-control form-control-sm "+ (itemM.isInvalid_imageopacy?"is-invalid":"")}
										value={itemM.imageopacy}
										name={"FIELDVALUE_"+props.container+"_imageopacy_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>	
								<div className={"col-sm-12 col-sm-12 col-lg-1 col-xl-1"}>
					 					<input key={props.container+"_label_"+itemM.rowId}  type="text" 
					 					className={"form-control form-control-sm "+ (itemM.isInvalid_label?"is-invalid":"")}
										value={itemM.label}
										name={"FIELDVALUE_"+props.container+"_label_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>	 
						  	
						  	<div className={"col-sm-12 col-sm-12 col-lg-1 col-xl-1"}>
					 					<input key={props.container+"_link_"+itemM.rowId}  type="text" 
					 					className={"form-control form-control-sm "+ (itemM.isInvalid_link?"is-invalid":"")}
										value={itemM.link}
										name={"FIELDVALUE_"+props.container+"_link_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>	 
						  	
						  	<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_target_"+itemM.rowId} defaultValue={itemM.type?itemM.type:"_self"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
					        	<option value="_self">_self</option>
					        	<option value="_blank">_blank</option>
					        	<option value="_parent">_parent</option>
					        	<option value="_top">_top</option>
									</select>
						  	</div>	 
						  	
						  	<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_fontweight_"+itemM.rowId} defaultValue={itemM.fontweight?itemM.fontweight:"normal"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
										<option value="normal">normal</option>
					        	<option value="bold">bold</option>
									</select>
						  	</div>	 
						  	
								<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_sessiontype_"+itemM.rowId} defaultValue={itemM.sessiontype?itemM.sessiontype:"NOTREQUIRED"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
										<option value="logged">logged</option>
					        	<option value="notlogged">not logged</option>
					        	<option value="NOTREQUIRED">Both</option>
									</select>
						  	</div>	 
						 		<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_type_"+itemM.rowId} defaultValue={itemM.type?itemM.type:"TEXT"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
					        	<option value="TEXT">TEXT</option>
					        	<option value="IMAGE">IMAGE</option>
					        	<option value="BUTTON">BUTTON</option>
									</select>
						  	</div>	
						  	 
						  	<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_width_"+itemM.rowId} defaultValue={itemM.type?itemM.type:"col-12"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
					        	<option value="col-12">100%</option>
					        	<option value="col-6">50%</option>
					        	<option value="col-4">33%</option>
									</select>
						  	</div>	 
						  	
						  	<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_height_"+itemM.rowId} defaultValue={itemM.type?itemM.type:"mt-4"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
					        	<option value="mt-4">1.5 rem</option>
					        	<option value="mt-3">1 rem</option>
					        	<option value="mt-2">0.5 rem</option>
					        	<option value="mt-1">0.25 rem</option>
									</select>
						  	</div>	 
						  	
						  	<div className={"col-sm-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_align_"+itemM.rowId} defaultValue={itemM.type?itemM.type:"left"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
					        	<option value="left">left</option>
					        	<option value="right">right</option>
					        	<option value="center">center</option>
									</select>
						  	</div>	 
						  	
							  <div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right middle"}>
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompHeaderIconItem_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
							</div> 	
					  )) 
					} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-sm-12 col-sm-12 col-lg-5 col-xl-5"}></div>
								<div className={"col-sm-12 col-sm-12 col-lg-5 col-xl-5"}></div>
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right middle"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompHeaderIconItem"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO Item DATA"
	}
};
export default loadCompHeaderIconItem;
				 