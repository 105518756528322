import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import Form from '../../components/Form';


const loadCompOption = props => {
	
	if (props.objOptions){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" >
					
				  {props.objOptions.map(itemM => (
				  	<>
				  		<div className="row">
								<div className={"col-10"}><span className="h6-responsive" >Option</span></div>
				  		 
			  				<div className={"col-2 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompOption_"+itemM.rowId} onClick={props.manageComponent}
						    	   type="button"> <i className="fas fa-trash"  ></i> </button>
				  			</div>
							</div>	
				  		
				  		<div className="row mb-1">
						 	
		  					<div className="col-sm-12 col-sm-12 col-lg-11 col-xl-11">
						  		<Form 
										formData={{
											
											name                : { label: "Option Value"      , size: "col-3" , sizesm: "col-sm-6", sizemd: "col-md-6", sizelg: "col-lg-3" , type: "TEXT",    value: itemM.name } ,
											sortorder           : { label: "Sort Order"        , size: "col-6" , sizesm: "col-sm-6", sizemd: "col-md-6", sizelg: "col-lg-3" , type: "TEXT",    value: itemM.sortorder } ,
											image               : { label: "Imagen Path"       , size: "col-6" , sizesm: "col-sm-12", sizemd: "col-md-12", sizelg: "col-lg-6" , type: "IMAGE",    value: itemM.image, width:"38px",  height:"38px", flex:true } ,
											
											
											optionmaster        : { label: "Option Filter"     , size: "col-6" , sizesm: "col-sm-6", sizemd: "col-md-6", sizelg: "col-lg-6"  , type: "CompLanguage",   values: itemM.optionmaster },
											optioncustomer      : { label: "Option Label"      , size: "col-6" , sizesm: "col-sm-6", sizemd: "col-md-6", sizelg: "col-lg-6"   , type: "CompLanguage",   values: itemM.optioncustomer }

										}}
										changeHandler={props.manageComponent} 
										manageImage={props.manageImage} 
										container = {"objOptions_"+itemM.rowId}
										formConfig={{
											pageWidth: "card col-12",
											groupingRows: 2, 
										
										}}
									/>
							  </div>
							  
							</div>
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-10"}><span className="h6-responsive" ></span></div>
								<div className={"col-2 text-right"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompOption"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO Option DATA"
	}
};
export default loadCompOption;
				 