import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { MDBIcon,MDBFormInline } from "mdbreact";

import { Map, GoogleApiWrapper } from 'google-maps-react';

import { Wrapper, Status } from "@googlemaps/react-wrapper";


import DateTimePicker from '../DateTimePicker';
import DatePicker from '../DatePicker';
import marker from "../../assets/images/marker.png";

import UploadFiles from '../UploadFiles';

import {validateNumber, findValueById,getLanguage,getImagePath, getDefValues,getCoordinatesStore } from '../../common/functions';
const windowHeight = window.innerHeight

const windowWidthDef = window.innerWidth
 

const AnyReactComponent = ({ top, left }) => <div 
style={{ 
	 	
	"zindex": "100",
	"position":"absolute",
	"top": top,
	"left": left,	
	 }}> 	<img style={{"width": "20px"}}	src={marker} alt=""	/> </div>;


/*"left": "calc(-18% + "+x+"px)",
	"top": "calc(-29% + "+y+"px)"	*/
	
const addRow = (props,  ini, numcol) => {
	  
	let row=[]
	let keys= Object.keys(props.formData)
	 
	for (let key=ini;key< ini+ numcol ; key++){
		
		
		
		if(key < Object.keys(props.formData).length ){
			 
			var field = props.formData[keys[key]];
			
			var label= field.label
			let input =""
		
			if ( Array.isArray(label)){
				var label = findValueById(label, getLanguage(), "languageid", "value")
			}
			
			if (!field.notRequired){
				label = label+ " *"
			}
			
			var position= ""
			if(field.position){
				position= "text-left"
				
				if (field.position=="center"){
					position= "text-center"
				}else if (field.position=="right"){
					position= "text-right"
					
				}
			}
				
			if (field.type ==="INPUT"){
				if(field.includeButton=="YES" ){
					input =  
						<div key={"div_cont_"+keys[key]} className={ (field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " " } >
		     												
		     			<div className="row">
			     			<div key={"div_cont2_"+keys[key]} className="col-form-left">
									<div key={"div_form_"+keys[key]} className="form-group card-label ">
										<label key={"label_form_"+keys[key]}>{label}  </label>									
										<input id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
														onKeyDown={props.changeHandler}
														placeholder={ field.defaultvalue?field.defaultvalue:"" }
														style ={ field.defaultvalue? {"height": "49px"}:{} }
									  				type="text" className={" left-border form-control" + (field.isInvalid?" is-invalid":"") }
														value={field.value}
														disabled={  props.lockform?true:field.disabled?field.disabled: false}
														name={(props.container?props.container+"__":"")+ keys[key]} 
														onChange={props.changeHandler} />
									</div>
									
								</div>
								<div className="col-form-right flex middle" >
		            	<button disabled={props.lockform} className={"btn right-border btn-block btn-"+(field.actionLabel?field.actionLabel:"primary") }  
		            	style={{ 
		            		"boxShadow": "0 1px 3px 0 rgb(171, 79, 101)"
		            		}}type="button" name={props.container+"__"+field.buttonaction} onClick={props.manageButton}>{field.actionLabel?field.actionLabel: getDefValues().sendLabel }</button>
		          	</div>	
								
							</div>
							
						</div> 	
				}else{
					input = 
				 		<input id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
				 							disabled={  props.lockform?true:field.disabled?field.disabled: false}
				 							placeholder={ field.defaultvalue?field.defaultvalue:"" }
				 							style ={ field.defaultvalue? {"height": "49px"}:{} }
				 							
				 							
				 							onKeyDown={props.changeHandler}
				 							
				 							type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={field.value }
											name={(props.container?props.container+"__":"")+ keys[key]} onChange={props.changeHandler} />
											
				}
			}else if (field.type ==="PASSWORD"){
					input = <><input id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
										disabled={  props.lockform?true:field.disabled?field.disabled: false} 
										type={ field.showPass? "text":"password"} className={"form-control "+ (field.isInvalid?"is-invalid":"")}
										value={field.value+""}
										onKeyDown={props.changeHandler}
										name={(props.container?props.container+"_":"")+ keys[key]}
										onChange={props.changeHandler} />
										{!field.isInvalid?
											<button  type="button" className="hidePass" name={"togglePass"+(props.container?props.container+"_":"")+ keys[key] } onClick={props.changeHandler} ></button>
										:""}
										
									</>
			}else if (field.type ==="TEXTAREA"){
				input = <textarea id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
											disabled={  props.lockform?true:field.disabled?field.disabled: false}
											type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={field.value+""}
											onKeyDown={props.changeHandler}
											name={(props.container?props.container+"__":"")+ keys[key]}
									onChange={props.changeHandler} />
			}else if (field.type ==="SELECT"){
				if (field.values){
					var textSelected=""
				
					textSelected=field.values[0].text
					field.values.map(function (item) {
						if (item.value==field.value){
							textSelected = item.text
						}
		    	}); 
					
					input =props.lockform?
								<input id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
				 							disabled={true}
				 							type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={textSelected}
											name={(props.container?props.container+"__":"")+ keys[key]} onChange={props.changeHandler} />
											
	      			:  <select id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] } key={keys[key]} 
								 			name={(props.container?props.container+"__":"")+ keys[key]} defaultValue={field.value} onChange={props.changeHandler} className={"form-control"+ (field.isInvalid?" is-invalid":"") }>
	               			disabled={field.disabled?field.disabled: false}
	              			{field.values.map(item => (
	              		 			<option key={keys[key] +"_i"+item.value} value={item.value}>{item.text}</option>
	              		 	))
	                  	} 
	      				</select>
	      }else{
	      	input=""
	      }		
	    }else if (field.type ==="MULTIPLE"){
				input = <select id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] } key={keys[key]} 
								 			name={(props.container?props.container+"__":"")+ keys[key]} multiple={true}  onChange={props.changeHandler} size={ field.rows?field.rows: 5 } className={"form-control"+ (field.isInvalid?" is-invalid":"") }>
	              			{field.values.map(item => (
	              		 		<option key={keys[key] +"_i"+item.value} value={item.value}>{item.text}</option>
	              		 	))
	                  	} 
	      				</select>
			}else if (field.type ==="DATEPICKER"){
				if (props.lockform){
					input =
					 
							 <input id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
				 							disabled={true}
				 							style ={ {"height": "49px"}}
				 							type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={field.value} 
											name={(props.container?props.container+"__":"")+ keys[key]}  />
						 
				}else{
					input = field.isUpdatable===false?"":
									<DatePicker 
										key={keys[key]}
										date={field.value} 
										name={(props.container?props.container+"__":"")+ keys[key]}
										label={label} 
										options={field.options} 
										isUpdatable= {field.isUpdatable===undefined?true: field.isUpdatable }
										updateDate={props.changeHandler} 
									/>
				}
			}else if (field.type ==="LINEBREAK"){
				input = <div key={keys[key]} className=""  />				
			
			}else if (field.type ==="LINK"){
				
				input =  <div key={"div_cont_"+keys[key]} className={ " mb-2 "+  (field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " "+
		     												(" "+position+" "  )
		     												 } >
									{  props.lockform?
										<span className="link-form"  >  {field.label}</span>
										:
										<a className="link-form" href={field.value} target="_blank"  >  {field.label}</a>	
									}									
								</div>
				 
										
			}else if (field.type ==="CHECKBOX"){
				if (field.values){
					input = <div key={"div_cont_"+keys[key]} className={" mt-1 mb-1 "+ (field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " "+
		     												(" "+position+" "  ) } >
										<div className={field.values.length>1?"checkbox-container":""}>
											{field.values.map(item => (
													<div className={field.values.length==1?"checkbox-label":""}>
														<label >
															<input
															disabled ={  props.lockform?true:field.disabled?field.disabled: false} 
															className={field.values.length==1? (field.isInvalid?"checkbox-is-invalid":""):""}
															name={(props.container?props.container+"__":"")+ keys[key]} 
															checked={ field.value.indexOf( (item.value) ) >= 0?true:false   } 
															value = { item.value} key={keys[key] +"_"+item.value} 
															id= {keys[key] +"_"+item.value} 
															onChange={props.changeHandler} type="checkbox"/> {(!field.notRequired? "* ":"" ) + item.text} </label>
													</div>
				              		))
				              }	
										</div>
										
										
										{field.isInvalid?
											<div className={"col-12 mt-3"+ ( props.customClass?" useForm-valitation-message": " valitation-message":"") }>
										<label key={"val_message_form_"+keys[key]}>{ field.validationmessage  }  </label>			
										</div>
										:""}
									
									</div>
				} 
	    }else if (field.type ==="HIDDEN"){
				input = <input id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} disabled={true} type="hidden"  className={"form-control"+ (field.isInvalid?" is-invalid":"") }
									value={field.value+""}
									name={(props.container?props.container+"__":"")+ keys[key]}
									onChange={props.changeHandler} />
			
			}else if (field.type ==="LABEL"){
				input = 	<div key={"div_cont_"+keys[key]} className={ "mb-2 "+ (field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " "+
		     												(" "+position+" "  ) } >
									 <span className={ field.class?field.class:"form-span" } >	{ label  }   </span>
									</div>
			
			}else if (field.type ==="RADIO"){
				 	input = 					
									<div className="form-group card-label">
										<MDBFormInline>
										 
											{field.values.map(item => (
		              		 		 		
	              		 		<label className="ml-2">
													<input 
														key={keys[key] +"_i"+item.value}
														value={item.value}
														type="radio" name={(props.container?props.container+"_":"")+ keys[key]}
														checked={item.value===field.value?true:false} onClick={props.changeHandler} />
													<span className="checkmark ml-1">{item.text}</span>
												</label>  
		              		 )) 
											}
										</MDBFormInline>	
									</div>
			}else if (field.type==="GMAP"){	
				var mapWidth =  600
				var left = "calc(-18% + "+field.value.x+"px)" 
				var top = "calc(-29% + "+field.value.y+"px)"	
				
				var windowWidth = props.windowWidth?props.windowWidth: windowWidthDef
				
				if (windowWidth <779){
					mapWidth = 376;
					var left = "calc(-11.4% + "+field.value.x+"px)" 
					var top = "calc(-28.8% + "+field.value.y+"px)"	
				}
				
				if (windowWidth <400){
					mapWidth = 290;
					var left = "calc(-41.2% + "+field.value.x+"px)" 
					var top = "calc(-34% + "+field.value.y+"px)"	
				}   
				input = 					
					<div className="col-12 flex items-center">
			    	<div style={{ height: '500px', width: mapWidth+'px' }}>
			    	 
			      </div>
			    </div>
			      
		      
			}else if (field.type ==="ITEMS"){
				
				
				if (field.values){
					
					var textSelected= field.values[0]? field.values[0].text:""
					field.values.map(function (item) {
						if (item.value==field.value){
							textSelected = item.text
						}
		    	}); 
	           	
					input =props.lockform?
								<input id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
				 							disabled={  true}
				 							type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={textSelected}
											name={(props.container?props.container+"__":"")+ keys[key]} onChange={props.changeHandler} />
											
	      			: 
								<div className="card itemsCard " style={field.isInvalid?{ "borderColor": "#dc3545"  }:{} } >
	      					<div className="row ml-0">
	      					
		      					{field.values.map(item => (
		      						<div   
		      							className={ "nomargin " + ((field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " ") } 
		      						>
		      							<button
		      								disabled={props.lockform}
		      								key={keys[key] +"_i"+item.value}
		      								className={ (props.formConfig.btncolor?props.formConfig.btncolor:"primary")+ "-formitem"+ (item.value==field.value?" "+(props.formConfig.btncolor?props.formConfig.btncolor:"primary")+ "-formitem-active":"") }
		      								type="button" value={item.value} 
		      								name={(props.container?props.container+"__":"")+ keys[key]}
		      							 	onClick={props.changeHandler}>
		      								<span className="h4-responsive"> {item.text}</span>
		      								
		      								{item.aditionalText? 
		      								<p className="text-left font-md"> {getDefValues().addressLabel +": "+item.aditionalText}</p>
		      								
		      								:""}
		      								
		      							</button>
		      							
		      						</div>
		      					))
		                } 	
		                
	                </div>
	                
	                {field.addvalues?field.addvalues=="YES"?
	                	
	                	<div className="row mt-1 mb-1 mr-2">
	                		
	                		<div className="col-12 text-right ">
			                	<button disabled={props.lockform}
			                		className={"btn btn-md btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary") } name= {(props.container?props.container+"__":"")+"ADD__" + keys[key]} 	type="button" onClick={props.changeHandler}>
													<span>	{ field.buttonlabel? field.buttonlabel: <> <i style={{"font-size": "15px"}} className="fas fa-plus" />  {getDefValues().shortbtnaddbutton}</>} </span>
												</button>
											</div>
										</div>
					        :"":""}     
	      				</div>
	      }			
	    }else if (field.type ==="BUTTONITEMS"){
				
				if (field.values){
					var textSelected= field.values[0]? field.values[0].text:""
					field.values.map(function (item) {
						if (item.value==field.value){
							textSelected = item.text
						}
	    		}); 
	           	
				input =props.lockform?
							<input id={props.formConfig.formid? "form_"+props.formConfig.formid+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
				 							disabled={  true}
				 							type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={textSelected}
											name={(props.container?props.container+"__":"")+ keys[key]} onChange={props.changeHandler} />
											
	      			: <div className={ "mt-2 mb-2 nomargin flex items-center " + ((field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " ") } 
		     				>
		     					
									<div className="btn-group buttons-group " role="group" aria-label="buttons-types">
		      					{field.values.map(item => (
		      							<button 
		      								disabled={props.lockform}
		      								key={keys[key] +"_i"+item.value}
		      								className={"h5-responsive btn btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary") +"-rigth-button "+ (item.value==field.value? "btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary")+"-active":"") }
		      								type="button" value={item.value} 
		      								name={(props.container?props.container+"__":"")+ keys[key]}
		      								style={{marginRight: 22}}
		      							 	onClick={props.changeHandler}>
		      									{item.text}
		      								
		      								{item.aditionalText? 
		      								<p className="text-left font-md"> {getDefValues().addressLabel +": "+item.aditionalText}</p>
		      								
		      								:""}
		      							</button>
		      					))}
									</div> 
	                {field.addvalues?field.addvalues=="YES"?
	                	
	                	<div className="row mt-1 mb-1 mr-2">
	                		
	                		<div className="col-12 text-right ">
			                	<button 
			                		disabled={props.lockform}
			                		className={"btn btn-md btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary")} name= {(props.container?props.container+"__":"")+"ADD__" + keys[key]} 	type="button" onClick={props.changeHandler}>
													<span>	<i style={{"font-size": "15px"}} className="fas fa-plus" /> {getDefValues().shortbtnaddbutton} </span>
												</button>
											</div>
										</div>
					        :"":""}     
				        </div>
	      }			
	    }else if (field.type ==="IFRAME"){
				input = <div key={"div_cont_"+keys[key]} className={ (field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " " } >
		     					<iframe src={field.value} style={{"height": (field.height?field.height:"500px") , "width": (field.width?field.width:"100%"), "border": "0px " }} ></iframe>
		     			 </div> 
			}else if (field.type ==="API"){
				input = ""
			}else if (field.type ==="IMAGE"){
				input = <div key={"div_cont_"+keys[key]} className={ (field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " "+
		     												(field.align?field.align: "text-left")
		     												 } >
			     				
			     				<img src={getImagePath(field.value)} style={
				     					{"height": (field.height?field.height:"500px") , 
				     						"width": (field.width?field.width:"100%"), "border": "0px " ,
				     						"max-width": "100%"
			     						}
			     					} />
		     			 </div> 
			}else if (field.type ==="UPLOADIMAGE"){
				input =  
				<>
					{/*field.value!=""?
						<img src={getImagePath(field.value)} style={{maxWidth: "100%"}}/>
					:""*/}
					<div key={"div_form_"+keys[key]} className={"form-group "+( field.type ==="TEXT"?"card-label":"card-label" ) } >
						<label key={"label_form_"+keys[key]}>{ label? ( label + (field.aditionallabel?" "+ field.aditionallabel:"") ) :"" }  </label>
						{input}
					</div>
					<UploadFiles
					lockform={props.lockform}
					btncolor={props.formConfig.btncolor?props.formConfig.btncolor:"primary"}
					 value={field.value} key={keys[key]} fieldName={(props.container?props.container+"__":"")+ keys[key]} changeHandler={props.changeHandler} /> 
				</>
			}
			
	  	row.push( field.type ==="UPLOADIMAGE" || field.type ==="CHECKBOX" || field.type ==="IMAGE" || field.type ==="LINK" || field.type ==="API" || field.type ==="IFRAME" || field.type ==="HIDDEN" || field.type ==="LABEL" || field.type ==="GMAP" || field.includeButton=="YES" ? input :
	  	
		     				<div key={"div_cont_"+keys[key]} className={ (field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:"col-sm-12") + " "+
		     												(field.sizemd?field.sizemd:"col-md-12") + " "+
		     												(field.sizelg?field.sizelg:"col-lg-12") + " "+
		     												(field.aditionallabel || field.isInvalid?" mb-3":"")
		     												 } >
									{field.type ==="LINEBREAK"? "":
										<>
											<div key={"div_form_"+keys[key]} className={"form-group "+( field.type ==="TEXT"?"card-label":"card-label" ) } >
												<label key={"label_form_"+keys[key]}>{ label? ( label + (field.aditionallabel?" *":"") ) :"" }  </label>
												{input}
											</div>
											
											{field.isInvalid?
												<div className={"col-12 mt-3"+ ( props.customClass?" useForm-valitation-message": " valitation-message":"") }>
													<label key={"val_message_form_"+keys[key]}>{ field.validationmessage  }  </label>			
												</div>
											:
												<>
													{field.aditionallabel?
														<div className="aditional-message">
															<label key={"msg_message_form_"+keys[key]}>{ field.aditionallabel  }  </label>			
														</div>
													:""}
												</>
											}
										</>
									}
									
									
								</div> 
							)
			
			if (props.formData[keys[key+1]] ){
				if (props.formData[keys[key+1]].type==="LINEBREAK" ){
				  
					var curfield = props.formData[keys[key]];
					var curCol   = parseInt ((curfield.size?curfield.size:"col-12").split("-")[1])
					var curColsm = parseInt ((curfield.size?curfield.size:"col-12").split("-")[1])
					var curColmd = parseInt ((curfield.size?curfield.size:"col-12").split("-")[1])
					var curCollg = parseInt ((curfield.size?curfield.size:"col-12").split("-")[1])
					
					var LBcol = 12-curCol
					/*console.log("----")
					console.log(LBcol)
					console.log("----")*/
					
				}
			}
			
						 
		}
  }
	return row;
}

const loadForm = props => {
	
	
	const defaultProps = {
    center: {
      lat: props.gmpasConfig,
      lng: props.gmpasConfig
    },
    zoom: 8
  };
 
	if (props.formData){
		var groupingrows = validateNumber(props.formConfig.groupingrows)?parseInt( props.formConfig.groupingrows):2
		const formStructure=[]
		var formData =[]
		
		
		
		if (Array.isArray(props.formData)){
			for (let k in props.formData){
				
				if (k == props.activeForm ){
					
					let tmpProps = {container: props.container,  formData: props.formData[k].formData, formConfig: props.formData[k].formConfig, changeHandler: props.changeHandler, lockform: props.lockform }
					formData = addRow(tmpProps, 0,Object.keys(props.formData[k].formData ).length )
				}
			}
			
		}else{
			 formData = addRow(props, 0,Object.keys(props.formData).length )
		}
		  
			formStructure.push(  
												<div key={"row_"+0} className={"row" + " flex items-top " + (props.formConfig.formalign?props.formConfig.formalign:" items-left") }> 
													{formData}
												</div>	
												)
		 
	  return ( 
	 		<div className= {"card "+(props.formConfig.formalign?props.formConfig.formalign:" items-left") } >
	 		 
	 			<div className= { props.formConfig.colsize+" "+props.formConfig.colsizelg+" "+props.formConfig.colsizemd+" "+props.formConfig.colsizesm } >
							{props.formConfig.formtitle?
								<>
									<div className="row">
										<div className={"mb-3 col-" +( props.lockform?"10":"12"  ) + " flex middle " + (props.formConfig.aligntitle?props.formConfig.aligntitle:" items-left") }>	
				 							{props.formConfig.formtittleicon?<img className={props.formConfig.formsubtitle?"formIcon":"formIcon2"} src={getImagePath(props.formConfig.formtittleicon)} alt={props.formConfig.formtittleicon} /> :""}
				 							<span className={"h4-responsive flex " + ( props.formConfig.formtittleicon?" ml-40 ":"" ) +(props.formConfig.formalign?props.formConfig.formalign:" items-left")} >{props.formConfig.formtitle}  </span>
				 							
				 						</div>
				 						{props.formConfig.formsubtitle && !Array.isArray(props.formData)?
					 						<div className={"col-" +( props.lockform?"10":"12"  ) + " flex middle " + (props.formConfig.formalign?props.formConfig.formalign:" items-left") }>	
					 							{props.formConfig.formtittleicon?<div className="ml-40"/> :""}
					 							
					 							<span className={"h5-responsive flex " +(props.formConfig.formalign?props.formConfig.formalign:" items-left")} >{props.formConfig.formsubtitle+ ( props.formConfig.formclonable?" 1":"" ) }  </span>
					 						</div>
				 						:""}
				 						{props.lockform?!props.readMode?
							   			<div className="col-2 flex items-right">	
												<button className={"btn btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary") +"-edit-orderdata"} type="button" onClick={props.editForm}>
													<i className="fas fa-edit" />
												</button>
											</div>
										:"":""}
				 					</div>
				 					{props.formConfig.formlinetitle? props.formConfig.formlinetitle.length>0 || props.formConfig.formlinetitle=="YES" ? 
				 						<span className="or-line mt-1 mb-30"></span> :<div className="mt-1 mb-10"></div>
				 					:<div className="mt-1 mb-10"></div>}
				 				</>
				 			:
				 				<>
									<div className="row">
										<div className={"col-" +( props.lockform?"10":"12"  ) + " flex middle " + (props.formConfig.aligntitle?props.formConfig.aligntitle:" items-left") }>	
				 							{props.formConfig.formtittleicon?<img className={props.formConfig.formsubtitle?"formIcon":"formIcon2"} src={getImagePath(props.formConfig.formtittleicon)} alt={props.formConfig.formtittleicon} /> :""}
				 							<span className={"h4-responsive flex " + ( props.formConfig.formtittleicon?" ml-40 ":"" ) +(props.formConfig.formalign?props.formConfig.formalign:" items-left")} ></span>
				 							
				 						</div>
				 						{props.formConfig.formsubtitle?
					 						<div className={"col-" +( props.lockform?"10":"12"  ) + " flex middle " + (props.formConfig.formalign?props.formConfig.formalign:" items-left") }>	
					 							{props.formConfig.formtittleicon?<div className="ml-40"/> :""}
					 							
					 							<span className={"flex " +(props.formConfig.formalign?props.formConfig.formalign:" items-left")} >{props.formConfig.formsubtitle}  </span>
					 						</div>
				 						:""}
				 						{props.lockform?!props.readMode?
							   			<div className="col-2 flex items-right">	
												<button className={"btn btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary") +"edit-orderdata"} type="button" onClick={props.editForm}>
													<i className="fas fa-edit" />
												</button>
											</div>
										:"":""}
				 					</div>
				 					{props.formConfig.formlinetitle? props.formConfig.formlinetitle.length>0 || props.formConfig.formlinetitle=="YES" ? 
				 						<span className="or-line mt-1 mb-30"></span> :<div className="mt-1 mb-10"></div>
				 					:<div className="mt-1 mb-10"></div>}
				 				</>
				 			}
				 			
				 			{Array.isArray(props.formData)?
				 				<>
				 					<div  className="mt-30 mb-3"> 
										 <div className="btn-group buttons-group" role="group" aria-label="buttons-types">
										 	{props.formData.map( (item, key) => (
										 		<>
										 		<button type="button" onClick={props.changeForm} 
										 						name={ (props.container?props.container+"__":"") + key}
											 					value={key}
											 					 
											 					className={"btn btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary") +"-rigth-button "+ (key==props.activeForm? "btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary")+"-active": 	("zIndex"+(110-key) ) ) }
											 					
											 				 
										 		>
										 			{item.formLabel } 
										 		</button>
										 		{props.formConfig.formclonable? props.formConfig.formclonable=="YES"? 
											 		<button type="button" onClick={props.changeHandler} 
											 						name={ (props.container?props.container+"__":"") + "DELCLONFORM__"+key }
												 					value={key}
												 					disabled= { props.lockform?true:false }
												 					className="close-button"
												 					style={{ color: key==props.activeForm?"#fff": "#696158"}} >
											 	  X
											 		</button>
										 		:"":""}
										 		</>
	            		 		))
	                		} 
										</div>
									</div>
								
				 				</>
				 			:""}
						
				   		<form className={"customform"+ (props.customClass?" userForm":"") }>  
				   			{formStructure}  
							</form>
						</div>
				{props.formConfig.formclonable? props.formConfig.formclonable=="YES"? 
					<div className= { (props.formConfig.colsize+" "+props.formConfig.colsizelg+" "+props.formConfig.colsizemd+" "+props.formConfig.colsizesm) + " text-right mb-2" } >
		   			{props.lockform?
		   				<button disabled= {true} className={"btn btn-sm btn-outline-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary")} name= {(props.container?props.container+"__":"")+"CLONFORM" } 	type="button" >
			   				<span>	<i style={{"font-size": "15px"}} className="fas fa-plus" /> {getDefValues().shortbtnaddbutton + " " +(props.formConfig.formsubtitle?props.formConfig.formsubtitle:"" ) } </span>
							</button>
		   			:
		   				<button className={"btn btn-sm btn-outline-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary")} name= {(props.container?props.container+"__":"")+"CLONFORM" } 	type="button" 
		   					onClick={props.changeHandler}>
			   				<span>	<i style={{"font-size": "15px"}} className="fas fa-plus" /> {getDefValues().shortbtnaddbutton + " " +(props.formConfig.formsubtitle?props.formConfig.formsubtitle:"" ) } </span>
							</button>
		   			}
					</div>
				:"":""}
				
				{props.formConfig.formaction? props.formConfig.formaction!=""? 
					<>
						{!props.lockform?
							<div className= { (props.formConfig.colsize+" "+props.formConfig.colsizelg+" "+props.formConfig.colsizemd+" "+props.formConfig.colsizesm) + " text-right mb-2" } >
				        <button className={"btn btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary")}  
				        	style={{ "boxShadow": "0 1px 3px 0 rgb(171, 79, 101)"}}
				        	type="button" name={(props.container?props.container:"")+"__"+props.formConfig.formaction} 
				        	onClick={props.manageButton}>{props.formConfig.formactionlabel?props.formConfig.formactionlabel: getDefValues().sendLabel }
				      	</button>
				      </div>
				    :
					    <div className= { (props.formConfig.colsize+" "+props.formConfig.colsizelg+" "+props.formConfig.colsizemd+" "+props.formConfig.colsizesm) + " text-right mb-2" } >
				        <button className={"btn btn-"+(props.formConfig.btncolor?props.formConfig.btncolor:"primary")}  
				        	disabled
				        	style={{ "boxShadow": "0 1px 3px 0 rgb(171, 79, 101)"}}
				        	type="button" name={(props.container?props.container:"")+"__"+props.formConfig.formaction} 
				        >{props.formConfig.formactionlabel?props.formConfig.formactionlabel: getDefValues().sendLabel }
				      	</button>
				      </div>
				    }
		      </>
				:"":""}
			</div> 			

	  );
	}else{
		console.log("NO FORM DATA>>>>>" , props)
		return "NO FORM DATA"
	}
};


export default GoogleApiWrapper({
  apiKey: 'YOUR_GOOGLE_MAPS_API_KEY_GOES_HERE'
})(loadForm);