import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SidebarNav from "../sidebar";

import IMG01 from "../../assets/images/doctors/doctor-thumb-01.jpg";
import IMG02 from "../../assets/images/doctors/doctor-thumb-02.jpg";
import IMG03 from "../../assets/images/doctors/doctor-thumb-03.jpg";
import IMG04 from "../../assets/images/doctors/doctor-thumb-04.jpg";
import IMG05 from "../../assets/images/doctors/doctor-thumb-01.jpg";
import IMG06 from "../../assets/images/doctors/doctor-thumb-02.jpg";
import IMG07 from "../../assets/images/doctors/doctor-thumb-03.jpg";
import IMG08 from "../../assets/images/doctors/doctor-thumb-04.jpg";

class Dashboard extends Component{
    render(){
        return(
          <>
            <SidebarNav pageId={this.props.match.params.pageId} />
            <div className="page-wrapper">
			  	 		<div className="content container-fluid">
					 	 		<table className="table table-hover table-center mb-0">
                    <thead>
                        <tr>
                            <th>Invoice No</th>
                            <th>Patient</th>
                            <th>Amount</th>
                            <th>Paid On</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Link to="/pages/invoice-view">#INV-0010</Link>
                            </td>
                            <td>
                                <h2 className="table-avatar">
                                    <Link to="/doctor/patient-profile" className="avatar avatar-sm mr-2">
                                      user
                                    </Link>
                                    <Link to="/doctor/patient-profile">Richard Wilson <span>#PT0016</span></Link>
                                </h2>
                            </td>
                            <td>$450</td>
                            <td>14 Nov 2019</td>
                            <td className="text-right">
                                <div className="table-action">
                                 <Link to="/pages/invoice-view" className="btn btn-sm bg-info-light">
                                        <i className="far fa-eye"></i> View
                                    </Link>
                                    <a href="#0" className="btn btn-sm bg-primary-light">
                                        <i className="fas fa-print"></i> Print
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/pages/invoice-view">#INV-0009</Link>
                            </td>
                            <td>
                                <h2 className="table-avatar">
                                <Link to="/doctor/patient-profile" className="avatar avatar-sm mr-2">
                                        <img className="avatar-img rounded-circle" src={IMG03} alt="User" />
                                    </Link>
                                    <Link to="/doctor/patient-profile" >Charlene Reed <span>#PT0001</span></Link>
                                </h2>
                            </td>
                            <td>$200</td>
                            <td>13 Nov 2019</td>
                            <td className="text-right">
                                <div className="table-action">
                                    <Link to="/pages/invoice-view" className="btn btn-sm bg-info-light">
                                        <i className="far fa-eye"></i> View
                                    </Link>
                                    <a href="#0" className="btn btn-sm bg-primary-light">
                                        <i className="fas fa-print"></i> Print
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/pages/invoice-view">#INV-0008</Link>
                            </td>
                            <td>
                                <h2 className="table-avatar">
                                    <Link to="/doctor/patient-profile"  className="avatar avatar-sm mr-2">
                                        <img className="avatar-img rounded-circle" src={IMG04} alt="User" />
                                    </Link>
                                    <Link to="/doctor/patient-profile" >Travis Trimble <span>#PT0002</span></Link>
                                </h2>
                            </td>
                            <td>$100</td>
                            <td>12 Nov 2019</td>
                            <td className="text-right">
                                <div className="table-action">
                                <Link to="/pages/invoice-view" className="btn btn-sm bg-info-light">
                                        <i className="far fa-eye"></i> View
                                    </Link>
                                    <a href="#0" className="btn btn-sm bg-primary-light">
                                        <i className="fas fa-print"></i> Print
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/pages/invoice-view">#INV-0007</Link>
                            </td>
                            <td>
                                <h2 className="table-avatar">
                                <Link to="/doctor/patient-profile" className="avatar avatar-sm mr-2">
                                        <img className="avatar-img rounded-circle" src={IMG05} alt="User" />
                                    </Link>
                                    <Link to="/doctor/patient-profile">Carl Kelly <span>#PT0003</span></Link>
                                </h2>
                            </td>
                            <td>$350</td>
                            <td>11 Nov 2019</td>
                            <td className="text-right">
                                <div className="table-action">
                                    <Link to="/pages/invoice-view" className="btn btn-sm bg-info-light">
                                        <i className="far fa-eye"></i> View
                                    </Link>
                                    <a href="#0" className="btn btn-sm bg-primary-light">
                                        <i className="fas fa-print"></i> Print
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/pages/invoice-view">#INV-0006</Link>
                            </td>
                            <td>
                                <h2 className="table-avatar">
                                    <Link to="/doctor/patient-profile" className="avatar avatar-sm mr-2">
                                        <img className="avatar-img rounded-circle" src={IMG06} alt="User" />
                                    </Link>
                                    <Link to="/doctor/patient-profile">Michelle Fairfax <span>#PT0004</span></Link>
                                </h2>
                            </td>
                            <td>$275</td>
                            <td>10 Nov 2019</td>
                            <td className="text-right">
                                <div className="table-action">
                                    <Link to="/pages/invoice-view" className="btn btn-sm bg-info-light">
                                        <i className="far fa-eye"></i> View
                                    </Link>
                                    <a href="#0" className="btn btn-sm bg-primary-light">
                                        <i className="fas fa-print"></i> Print
                                    </a>
                                </div>
                            </td>
                        </tr>
                      
                     
                    
                    </tbody>
                </table>
 							</div>
           	</div>
					</>         
        )
     }
  }
  
 export default Dashboard; 