/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import CompOption from '../../components/CompOption';
import ImageManager from '../../components/ImageManager';

/*functions*/
import {getLanguages, manageComponent,objOptions, listImages, manageImage, find,findValueById,validateComponent, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			modal2: false,
			objOptions:[],
			languages:[],
			modalMdClass:"modal-lg",
			modalIamgeClass:"modal-lg",
			isLoading: true,
			objImage:{
				items:[],
				selected:[]
			},
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"OPTIONNAME", title:"Option Name",
						sorter: (a, b) => a.OPTIONNAME.length - b.OPTIONNAME.length },
					{ dataIndex:"SORTORDER", title:"Sort Order",
						sorter: (a, b) => a.SORTORDER.length - b.SORTORDER.length },
					{ dataIndex: "OPTIONID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-info" id={record.OPTIONID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-danger" id={record.OPTIONID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Option",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				OPTIONNAMES    : { label: "Option Name"      , type: "CompLanguage",   values: [] },
				TYPEID         : { label: "Type"             , type: "SELECT", value: "", values: [] , getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"6"}] } } ,
				SORTORDER      : { label: "Sort Order"       , type: "TEXT",   value: "" } ,
			//	CONTENTOPTIONS : { "label": "Options"      ,"buttonLabel": "Set values", "type": "CompOption", "values": []},
			},
						
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getOptions" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		
	 	let languages= await getLanguages()
	 	this.setState({languages:languages});
	 	let formData	= {
	 			
	 			OPTIONNAMES    : { label: "Option Name"      , type: "CompLanguage",   values: languages },
				TYPEID         : { label: "Type"             , type: "SELECT", value: "", values: [] , getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"6"}] } } ,
				SORTORDER      : { label: "Sort Order"       , type: "TEXT",   value: "" } ,
				//CONTENTOPTIONS : { "label": "Options"      ,"buttonLabel": "Set values", "type": "CompOption", "values": []},
			};
			
			this.setState({  ...this.state, formData:formData})								
	 		await preloadForm ( this.state.formData )
	 		
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	//manage image
	manageImage= async(event) => {
		try{
			
			this.setState({isLoading:true});
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}

			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[2]
				let objImage = await listImages("",objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal3: true});	 		 	
			}else{				 
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
					let field = objImage.objectName
					var objImage = this.state.objImage
					var objOptions = this.state.objOptions
					for (let i in objOptions){
						if (objOptions[i].rowId == field){
							console.log("--", objImage.selected[0])
							objOptions[i].image = objImage.selected[0]
						}
					}
					this.setState({modal3:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
  
	/*manage Components*/
	manageComponent = async(event) => {
		try{
			this.setState({isLoading:true});
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
			let action =  data[0]
			var componentType = data[1]
			 
			var  objOptions= this.state.objOptions
			
			
			 
				
			if (action==="objOptions"){
				
				data = event.target.name.split("__") ;				
				let fields = data[0].split("_")				
				let pos = find (objOptions, fields[1], "rowId")
				let obj = objOptions[pos] 
				if (Array.isArray(	objOptions[pos][data[1]])){
					for (let l in objOptions[pos][data[1]] ){
						if (  objOptions[pos][data[1]][l].id == data[2]  ){
							
							objOptions[pos][data[1]][l]["value"] =  event.target.value	
						}
					}
				}else{
					objOptions[pos][data[1]] = event.target.value	
				} 

	 		}else if (action=="DELROW") {
				let pos = find (objOptions, data[3], "rowId")
				objOptions.splice(pos, 1)				
				this.setState({ objOptions:objOptions });
				
			}else if (action=="ADDROW") {
				
				
				let rowId = 0
		 		for (let key in objOptions){
		 			if (rowId < objOptions[key].rowId){
		 				rowId =objOptions[key].rowId
		 			}
				} 	
				rowId = rowId+1
				
				var languages = []
				var languages2 = []
				this.state.languages.map(function (item) {
	  			languages.push( {  id: item.id, name: item.name, value:"" })
	  			languages2.push( {  id: item.id, name: item.name, value:"" })
	  		}); 
				
				let objRow= {
					rowId : rowId ,
					name:"",
					optionmaster: languages,
					optioncustomer:languages2,
					image:"",
					sortorder:objOptions.length+1
				}		
				objOptions.push(objRow)
				this.setState({objOptions:objOptions}); 
				 
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	  
  /*get value fields*/
  changeHandler = async(event) => {
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
		if (field_name=="TYPEID"){
			var formData 
			if (field_value==6 || field_value==7 ||field_value==8){
				formData = {
					OPTIONNAMES    : { label: "Option Name"      , type: "CompLanguage",   values: this.state.formData.OPTIONNAMES.values },
					TYPEID         : { label: "Type"             , type: "SELECT", value: this.state.formData.TYPEID.value, values: this.state.formData.TYPEID.values , getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"6"}] } } ,
					SORTORDER      : { label: "Sort Order"       , type: "TEXT",   value: this.state.formData.SORTORDER.value } ,
					//CONTENTOPTIONS : { label: "Options"      ,"buttonLabel": "Set values", "type": "CompOption", "values": []},
				}
			}else{
				formData = {
					OPTIONNAMES    : { label: "Option Name"      , type: "CompLanguage",   values: this.state.formData.OPTIONNAMES.values },
					TYPEID         : { label: "Type"             , type: "SELECT", value: this.state.formData.TYPEID.value, values: this.state.formData.TYPEID.values , getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"6"}] } } ,
					SORTORDER      : { label: "Sort Order"       , type: "TEXT",   value: this.state.formData.SORTORDER.value } ,
				}
			}
			this.setState({formData:formData});
		}
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
	validateComponent= async(event) => {

		try{
			this.setState({isLoading:true});
			var resp = await validateComponent( this.state.componentType, this.state.formData)

			 
			this.setState({formData:resp.formData});
			 
		
			let compManager = <CompOption data={this.state.formData[this.state.componentType].values} container= {this.state.componentType} manageComponent={this.manageComponent} />
	 		this.setState({ compManager:compManager  });
	 		 	
			if (resp.isValid){
				this.setState({modal2:false});
			}else{
				showError(toast,"CHECK COMPONENT VALUES");	
			}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	cancelComponent= async(event) => {
		 
		try{
			this.setState({isLoading:true});
			const formData = this.state.formData
			formData[this.state.componentType].values=[]
			this.setState({modal2:false});
			
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	/*charge data from a selected Option*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Option"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
			//	this.state.formData.ATTRIBUTENAMES.value = undefined
			//	this.state.formData.CONTENTOPTIONS.value = undefined
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Option"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		
	  		let resp = await callApi ( "manager","getOptionById",{optionid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData,resp.OPTION)
	  		
	  		var optionNames=[], options=[]
	  		resp.OPTION.OPTIONNAMES.map(function (item) {
	  			optionNames.push( {  id: item.LANGUAGEID, value: item.NAME })
	  		}); 
	  		
	  		var rowId=1
	  		var objOptions=[]
	  		var clanguages = this.state.languages
	  		resp.OPTION.CONTENTOPTIONS.map(function (item) {
	  			let pos = find (objOptions, item.OPTIONVALUE, "name")
	  			if (pos<0){
	  				
	  				var languages = []
						var languages2 = []
						for (let i in clanguages){
							let iteml = clanguages[i]
							let val1 = ""
							let val2 = ""
							 
							resp.OPTION.CONTENTOPTIONS.map(function (item2) {
								if ( iteml.id ==item2.LANGUAGEID &&  item.OPTIONVALUE == item2.OPTIONVALUE ){
									val1 = item.OPTIONMASTER
									val2 = item.OPTIONCUSTOMER
								}
							});
							 
			  			languages.push( {  id: iteml.id, name: iteml.name, value:val1 })
			  			languages2.push( {  id: iteml.id, name: iteml.name, value:val2 })
			  		}
						
						let objRow= {
							rowId : rowId ,
							name:item.OPTIONVALUE,
							optionmaster: languages,
							optioncustomer:languages2,
							image:item.IAMGEPATH, 
							sortorder:item.SORTORDER
						}		
					
		  			objOptions.push( objRow)
		  			rowId++ 
	  			} 
	  		});
	  		
	  		if ( objOptions.length>0){
						if ( objOptions[0].sortorder){
							objOptions= objOptions.sort(function (a, b){
							return (a.sortorder - b.sortorder)
							})
							
							for (let c in objOptions){
								objOptions["rowId"] = parseInt(c)
							}
							
						}				
					}
	  		
	  		console.log(objOptions)
	  		this.state.formData.OPTIONNAMES.value = undefined
	  		//this.state.formData.CONTENTOPTIONS.value = undefined
	  	 
	  		this.state.formData.OPTIONNAMES.values = optionNames
	  		//this.state.formData.CONTENTOPTIONS.values = options
	  		
	  		this.setState({ objOptions:objOptions });
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Option"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
					 
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
					_formData["optionid"] = this.state.currentId;
					
					let objOptions =[] 
					for (let key in this.state.languages ){
						let langId = this.state.languages[key].id
						
						this.state.objOptions.map(function (op) {
							let  optionmaster   = findValueById (op.optionmaster, langId, "id", "value")
							let  optioncustomer = findValueById (op.optioncustomer, langId, "id", "value")
							objOptions.push(
								{ languageid				: langId,
									name 							: op.name,
									image  						: op.image ,
									sortorder				  : op.sortorder ,
									optionmaster      : optionmaster ,
									optioncustomer    : optioncustomer								
								}
							)
						}); 
					} 
					 
					_formData ["contentoptions"] = objOptions
					
			  	let resp = await callApi ( "manager",this.state.formAction+"Options", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getOptions" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	
	
  
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
												 :
													<>
													 <Form 
															formData={this.state.formData} 
															manageComponent={this.manageComponent}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
													 
													 <CompOption 
															objOptions={this.state.objOptions} 
															optionYesNo={this.state.optionYesNo}
															manageComponent = {this.manageComponent}
															manageImage = {this.manageImage}
															container = "objOptions"
															addRow = {this.addRow}
															delRow = {this.delRow}/>
													</>							
												}
							  />
			<Modal open={this.state.modal2}
      	icon="fas fa-images"
      	title={this.state.titlemodal2}
      	zindex="1060"
      	message={this.state.compManager}
      	buttons={this.state.buttonsModal2}
      	class={this.state.modalMdClass}
      />
      
       <Modal open={this.state.modal3} 
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1060"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (3)} 
      	class={this.state.modalIamgeClass}
      />
      
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Options"}/>
        	
        	<div className="row mb-4 justify-content-center text-right">
						<div className="col-md-12 col-lg-12">
								<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 