import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";

const windowHeight = window.innerHeight

const formtypes =[
{"value": "INPUT"    , "text": "Input"},
{"value": "SELECT"   , "text": "Select"},
{"value": "CHECKBOX" , "text": "CheckBox"},
{"value": "RADIO"    , "text": "Radio"},
{"value": "ITEMS"	   , "text": "Items"},
{"value": "BUTTONITEMS"	   , "text": "Items (mini)"},
{"value": "PASSWORD" , "text": "Paswword"},
{"value": "TEXTAREA" , "text": "Input Area"},
{"value": "DATEPICKER" , "text": "Date picker"},
{"value": "DATETIMEPICKER" , "text": "Date Time picker"},
{"value": "HIDDEN"	 , "text": "Hidden"},
{"value": "BUTTON"	 , "text": "Button"},
{"value": "LABEL"	   , "text": "Label"},
{"value": "LINK"	   , "text": "Link"},
{"value": "UPLOADIMAGE"	 , "text": "Upload Image"},
{"value": "GMAP" 	   , "text": "Google Map"}
] 

const formValids = [
{"text": "NOTREQUIRED", "value": "NOT REQUIRED"},
{"text": "text", "value": "text"},
{"text": "number", "value": "number"},
{"text": "email", "value": "email"},
{"text": "password", "value": "password"}
]
const cols =[1,2,3,4,5,6,7,8,9,10,11,12]
const loadFormComp = props => {
	 
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" style={{ "maxHeight": (windowHeight-240)+"px" , "overflow":"auto"  } }>
								
				  {props.data.map(itemM => (
				  	<>
							<div style={{ border : (itemM.showMore?"2px solid #007bff": "1px solid #dbdbdb")  }} className="row mb-1 mt-2 form-component-container">
								<div className="col-md-6 col-sm-6 col-lg-2 col-xl-2">
							 		<div className="form-group card-label">
							 			<label > Type  </label>
						 				<select  name={"FIELDVALUE_"+props.container	+"_type_"+itemM.rowId} defaultValue={itemM.type} onChange={props.manageComponent} className={"form-control form-control-sm" }>
				        			{formtypes.map(item => (
				        		 		<option key={props.container+"attr_i"+item.value} value={item.value}>{item.text}</option>
				        		 	))
				            	} 
										</select>
							  	</div>	
							  </div>			
							  
							  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
						  		<div className="form-group card-label">
						  			<label >Sort Order </label>	
						 				<input key={props.container+"_sortorder_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_sortorder?"is-invalid":"")}
											value={itemM.sortorder}
											name={"FIELDVALUE_"+props.container+"_sortorder_"+itemM.rowId}
											onChange={props.manageComponent} />
							  	</div>		 	
						  	</div>	
						  					  
						  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
						  		<div className="form-group card-label">
							 			<label>Field Name   </label>
						 				<input key={props.container+"_name_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_name?"is-invalid":"")}
											value={itemM.name}
											name={"FIELDVALUE_"+props.container+"_name_"+itemM.rowId}
											onChange={props.manageComponent} />
									</div>
						  	</div>		
						  	
							  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
						  		<div className="form-group card-label">
						  			<label >Label  </label>
						 				<input key={props.container+"_label_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_label?"is-invalid":"")}
											value={itemM.label}
											name={"FIELDVALUE_"+props.container+"_label_"+itemM.rowId}
											onChange={props.manageComponent} />
							  	</div>
							  </div>				
							  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
						  		<div className="form-group card-label">
						  			<label >Default Value </label>	
						 				<input key={props.container+"_value_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_value?"is-invalid":"")}
											value={itemM.value}
											name={"FIELDVALUE_"+props.container+"_value_"+itemM.rowId}
											onChange={props.manageComponent} />
							  	</div>		 	
							  </div>
								{itemM.showMore? 
						  		<>			  	
						  		
						  		<div className="col-md-6 col-sm-6 col-lg-2 col-xl-2">
							  		<div className="mt-3">
							  			<button  className="btn btn-secondary btn-sm btn-rounded"  name={"TOGGLEROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
								       type="button"> <i className={ (itemM.showMore?"fas fa-compress-arrows-alt":"fas fa-expand-arrows-alt") } ></i> </button>
											<button  className="btn btn-danger btn-sm btn-rounded"  name={"DELROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
								       type="button"> <i className="fas fa-trash"  ></i> </button>
									 	</div>
									</div>
								
								  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >PlaceHolder   </label>
							 				<input key={props.container+"_defaultvalue_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_defaultvalue?"is-invalid":"")}
												value={itemM.defaultvalue}
												name={"FIELDVALUE_"+props.container+"_defaultvalue_"+itemM.rowId}
												onChange={props.manageComponent} />
								  	</div>		
								  </div>	
								  
								  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Button in Line   </label>
							 				<select  name={"FIELDVALUE_"+props.container	+"_includeButton_"+itemM.rowId} defaultValue={itemM.includeButton} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			 		<option   value="NO">NO</option>
					        		 	<option   value="YES">YES</option>
											</select>
								  	</div>	
								  </div>	
								  		
									<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Action </label>
							 				<select  name={"FIELDVALUE_"+props.container	+"_buttonaction_"+itemM.rowId} defaultValue={itemM.buttonaction} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			 		<option value="">Select Option</option>
			        			 		<option value="suscribe"> suscribe </option>
					        		 	<option value="initchat"> init chat</option>
					        		 	<option value="searchproduct"> search product</option>
											</select>
								  	</div>		 	
									</div>		 	 	
								 
								  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Size (Mobile) </label>
							 				<select  name={"FIELDVALUE_"+props.container	+"_size_"+itemM.rowId} defaultValue={itemM.size?itemM.size:"col-12"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			 		{cols.map(col => (
			        			 		 	<option value={"col-"+col}>{"col-"+col}</option>
			        			 		))}
											</select>
								  	</div>		 	
								  </div>		 	
								  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Size (Small) </label>	
							 				<select  name={"FIELDVALUE_"+props.container	+"_sizesm_"+itemM.rowId} defaultValue={itemM.sizesm?itemM.sizesm:"col-sm-12"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			 		{cols.map(col => (
			        			 		 	<option value={"col-sm-"+col}>{"col-"+col}</option>
			        			 		))}
											</select>
								  	</div>		 	
								  </div>		
								  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Size (Medium) </label>	
							 				<select  name={"FIELDVALUE_"+props.container	+"_sizemd_"+itemM.rowId} defaultValue={itemM.sizemd?itemM.sizemd:"col-md-6"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			 		{cols.map(col => (
			        			 		 	<option value={"col-md-"+col}>{"col-"+col}</option>
			        			 		))}
											</select>
								  	</div>		 	
								 	</div>	 	
								  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Size (Large) </label>	
							 				<select  name={"FIELDVALUE_"+props.container	+"_sizelg_"+itemM.rowId} defaultValue={itemM.sizelg?itemM.sizelg:"col-lg-6"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			 			{cols.map(col => (
			        			 		 	<option value={"col-lg-"+col}>{"col-"+col}</option>
			        			 		))}
											</select>
								  	</div>		 	
							  	</div>	
							  	
							  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Validation </label>	
							 				<select  name={"FIELDVALUE_"+props.container	+"_validationtype_"+itemM.rowId} defaultValue={itemM.validationtype?itemM.validationtype:"NOTREQUIRED"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			 				{formValids.map(item => (
						        		 		<option key={props.container+"attr_i"+item.value} value={item.text}>{item.text}</option>
						        		 	))
						            	}  
											</select>
								  	</div>		 	
							  	</div>	  
							  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Validation Message </label>	
							 				<input key={props.container+"_validationlabel_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_validationlabel?"is-invalid":"")}
												value={itemM.validationlabel}
												name={"FIELDVALUE_"+props.container+"_validationlabel_"+itemM.rowId}
												onChange={props.manageComponent} />
								  	</div>		 	
							  	</div>	
							  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Position </label>	
							 				<select  name={"FIELDVALUE_"+props.container	+"_position_"+itemM.rowId} defaultValue={itemM.position?itemM.position:"left"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			 		<option  value="left">Left</option>
						        		<option  value="center">Center</option>
						        		<option  value="right">Right</option>
											</select>
								  	</div>		 	
							  	</div>	
							  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Aditional Label </label>	
							 				<input key={props.container+"_aditionallabel_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_aditionallabel?"is-invalid":"")}
												value={itemM.aditionallabel}
												name={"FIELDVALUE_"+props.container+"_aditionallabel_"+itemM.rowId}
												onChange={props.manageComponent} />
								  	</div>		 	
							  	</div>	
							  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Class </label>	
							 				<input key={props.container+"_class_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_class?"is-invalid":"")}
												value={itemM.class}
												name={"FIELDVALUE_"+props.container+"_class_"+itemM.rowId}
												onChange={props.manageComponent} />
								  	</div>		 	
							  	</div>	
							  	
							  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Min Value </label>	
							 				<input key={props.container+"_minvalue_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_minvalue?"is-invalid":"")}
												value={itemM.minvalue}
												name={"FIELDVALUE_"+props.container+"_minvalue_"+itemM.rowId}
												onChange={props.manageComponent} />
								  	</div>		 	
							  	</div>	
							  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Max Value</label>	
							 				<input key={props.container+"_maxvalue_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_maxvalue?"is-invalid":"")}
												value={itemM.maxvalue}
												name={"FIELDVALUE_"+props.container+"_maxvalue_"+itemM.rowId}
												onChange={props.manageComponent} />
								  	</div>		 	
							  	</div>	
							  	
							  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Max Length</label>	
							 				<input key={props.container+"_maxlength_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_maxlength?"is-invalid":"")}
												value={itemM.maxlength}
												name={"FIELDVALUE_"+props.container+"_maxlength_"+itemM.rowId}
												onChange={props.manageComponent} />
								  	</div>		 	
							  	</div>	
							  	
							  	{itemM.type=="ITEMS" || itemM.type=="BUTTONITEMS"  ?
							  		<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >Add new values? </label>	
								 				<select  name={"FIELDVALUE_"+props.container	+"_addvalues_"+itemM.rowId} defaultValue={itemM.addvalues?itemM.addvalues:"NO"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
				        			 		<option value="NO">NO</option>
							        		<option value="YES">YES</option>
												</select>
									  	</div>		 	
								  	</div>	
									:""
									}
									
							  	{itemM.type=="ITEMS" || itemM.type=="SELECT" || itemM.type=="RADIO" || itemM.type== "BUTTONITEMS"?
							  		<div className="col-md-12 col-sm-12 col-lg-4 col-xl-4">
							  			<div className="card">
										  	 
									  			<div className="row mt-1">	
									  				<div className={"col-6"}>
									  					 <span className="ml-2">Text </span>
									  				</div>
									  				<div className={"col-6"}>
									  					<span className="ml-1">	Value </span>
									  				</div>
									  			</div>	
									  			{Array.isArray(itemM.items)?
									  				itemM.items.map(i => (
									  					<div className="row">	
								        		 		<div className="col-6">
							        		 				<b className="ml-2">	{i.text} </b>
								        		 		</div>		
							        		 			<div className="col-6">
						        		 					<b className="ml-1">	{i.value} </b>
						        		 				</div>
						        		 			</div>
							        		 	))
							        		:""
							            }  
						            	<div className="row mt-2 mb-1">	
									  				<div className={"col-12 text-right"}>
															<button  className="btn mr-2 btn-danger btn-sm"  name={"CompItem_"+props.container+"_items_"+itemM.rowId} onClick={props.manageComponent}
															type="button"> Set Items </button>
														</div>
						        			</div>	
											</div>	
										</div>
									:""
									}
							  </>	
						  	:
						  	""
						  	}
						  	
						  	
						  	
						  	{!itemM.showMore?
						  	<div className="col-md-6 col-sm-6 col-lg-2 col-xl-2">
						  		<div className="mt-3">
						  			<button  className="btn btn-secondary btn-sm btn-rounded"  name={"TOGGLEROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className={ (itemM.showMore?"fas fa-compress-arrows-alt":"fas fa-expand-arrows-alt") } ></i> </button>
										<button  className="btn btn-danger btn-sm btn-rounded"  name={"DELROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
								 	</div>
								</div>
								:""}
				    	</div>
				    	
				    	
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row mt-2 mb-2">
								<div className={"col-12 middle text-right "}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompForm"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO FormComp DATA"
	}
};
export default loadFormComp;
				 