/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Filters from '../../components/Filters'; 
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';

import ProductList from '../../components/ProductList';
/*functions*/
import {manageImage, searchHandler, listImages, getDetailDomainsByCode, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			//show : null,
			modal1: false,
			modal2: false,
			isLoading: true,
			date_label:date_label,
			modalClass: "modal-full-height modal-lg",
			modalIamgeClass:"modal-lg",
			/*table filter*/
			filterConfig: {
			
				ORDERID        :{ label:"Order Id"      ,  type:"NUMBER"  , value:""},
				FROMDATE       :{ label:"From"          ,  type:"DATE"    , value:fromDate} ,
				TODATE         :{ label:"To"            ,  type:"DATE"    , value:new Date()} ,
				CITY           :{ label:"City"          ,  type:"SELECT"  , values:[{text:"ALL", value:""}], value:""} ,
				STOREID        :{ label:"Store"         ,  type:"SELECT"  , values:[{text:"ALL", value:""}], value:""} ,
				PAYMENTTYPEID  :{ label:"Payment Type"  ,  type:"SELECT"  , values:[{text:"ALL", value:""}], value:""} ,
				SHIPPINGTYPEID :{ label:"Shipping Type" ,  type:"SELECT"  , values:[{text:"ALL", value:""}], value:""} ,
				MIN_PRICE      :{ label:"Min Amount"    ,  type:"NUMBER"  , value:""} ,
				MAX_PRICE      :{ label:"Max Amount"    ,  type:"NUMBER"  , value:""} ,
				HAVE_COUPON    :{ label:"Coupon"        ,  type:"SELECT"  ,  value:"", values:[ {text:"NO", value:"NO"}, {text:"YES",value:"YES"} ]} ,
				CUSTOMER_NAME  :{ label:"Customer Name" ,  type:"TEXT"    , value:"" } ,
				STATUSID       :{ label:"Status"        ,  type:"SELECT"  , value:"" , values:[{text:"ALL", value:""}] },
				
			},
			showMore:false,
			/*table data*/
			tableData: {
				columns : [
				 
					{ dataIndex:"ORDERID", title:"Order Id" },
					{ dataIndex:"STORENAME", title:"Store Name"},
					{ dataIndex:"STORE_PICKUP", title:"Pickup Store"},					
					{ dataIndex:"SUBTOTAL_ORDER", title:"Subtotal Order"},
					{ dataIndex:"SHIPPINGCOST", title:"Shipping Cost"},
					{  dataIndex:"DISCOUNT", title:"Discount"},
					{  dataIndex:"TOTAL_ORDER", title:"Total Order"},
					{  dataIndex:"STATUSNAME", title:"Status"},
					{  dataIndex:"FIRSTNAME", title:"Firstname"},
					{  dataIndex:"LASTNAME", title:"Lastname"},
					{  dataIndex:"EMAIL", title:"Email"},
					{  dataIndex:"PHONE", title:"Phone"},
					{  dataIndex:"PAYMENT_CITY", title:"Payment City"},
					{  dataIndex:"PAYMENTMETHOD", title:"Payment Method"},
					{  dataIndex:"SHIPPINGADDRESS", title:"Shipping Address"},
					{  dataIndex:"ADDITIONALSHIPPINGINSTRUCTIONS", title:"Shipping Instructions"},
					{  dataIndex:"PICKUPDATE", title:"Pickup Date"},
					{  dataIndex:"SHIPPING_CITY", title:"Shipping City"},
					{  dataIndex:"SHIPPINGMETHOD", title:"Shipping Method"},
					{  dataIndex:"SHIPPINGTYPE", title:"Shipping Type"},
					{  dataIndex:"HAVECOUPON", title:"Coupon"},
					{  dataIndex:"DATE_ADDED", title:"Date Added"},
					{  dataIndex:"DATE_MODIFIED", title:"Date Modified"},

			 
				],
		    rows: [], 
			},
			
			tableData2: {
				columns : [
					
					{ dataIndex:"ORDERID", title: "Order Id" },
					{ dataIndex:"STORENAME", title: "Store Name" },
					{ dataIndex:"STORE_PICKUP", title:"Pickup Store"},					
					{ dataIndex:"SKU", title: "SKU" },
					{ dataIndex:"SKUNAME", title: "Sku Name" },
					{ dataIndex:"PRICE", title: "Price" },
					{ dataIndex:"TOTAL_SKU", title: "Total SKU" },
					{ dataIndex:"QUANTITY", title: "Quantity" },
					{ dataIndex:"SUBTOTAL_ORDER", title: "Subtotal Order" },
					{ dataIndex:"SHIPPINGCOST", title: "Shipping Cost" },
					{ dataIndex:"DISCOUNT", title: "Discount" },
					{ dataIndex:"TOTAL_ORDER", title: "Total Order" },
			 
					{ dataIndex:"STATUSNAME", title: "Status" },
					{ dataIndex:"FIRSTNAME", title: "Firstname" },
					{ dataIndex:"LASTNAME", title: "Lastname" },
					{ dataIndex:"EMAIL", title: "Email" },
					{ dataIndex:"PHONE", title: "Phone" },
					{ dataIndex:"PAYMENT_CITY", title: "Payment City" },
					{ dataIndex:"PAYMENTMETHOD", title: "Payment Method" },
					{ dataIndex:"SHIPPINGADDRESS", title: "Shipping Address" },
					{ dataIndex:"ADDITIONALSHIPPINGINSTRUCTIONS", title: "Shipping Instructions" },
					{ dataIndex:"PICKUPDATE", title: "Pickup Date" },
					{ dataIndex:"SHIPPING_CITY", title: "Shipping City" },
					{ dataIndex:"SHIPPINGMETHOD", title: "Shipping Method" },
					{ dataIndex:"SHIPPINGTYPE", title: "Shipping Type" },
					{ dataIndex:"HAVECOUPON", title:"Coupon"},
					{ dataIndex:"DATE_ADDED", title: "Date Added" },
					{ dataIndex:"DATE_MODIFIED", title: "Date Modified" },
 
				],
		    rows: [], 
			},
	 
		 
		}
	}
	/*loading data*/
	componentDidMount = async() => {
		
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp1 = await callApi ( "custom","getPaymentTypes" )
	 		let resp2 = await callApi ( "custom","getShippingTypes" )
	 		let resp3 = await callApi ( "custom","getDetailDomainsByCode", {domainid:"15"} )	
	 		let resp4 = await callApi ( "manager","getStatusOrders"  )	
	 		let resp5 = await callApi ( "manager","getPolygons"  )	
	 		
	 		
	 		const filterConfig = this.state.filterConfig;
	 		resp1.rows.map(function (item) {
				 filterConfig.PAYMENTTYPEID.values.push ({text:item.PAYMENTNAME, value: item.PAYMENTTYPEID} )
			}); 
			resp2.rows.map(function (item) {
				 filterConfig.SHIPPINGTYPEID.values.push ({text:item.SHIPPINGNAME, value: item.SHIPPINGTYPEID} )
			}); 
			resp3.rows.map(function (item) {
				 filterConfig.STOREID.values.push ({text:item.STRING_VALUE, value: item.DETAILDOMAINID} )
			}); 
			resp4.rows.map(function (item) {
				 filterConfig.STATUSID.values.push ({text:item.STATUSNAME, value: item.STATUSID} )
			}); 
			
			var cities=[]
			resp5.rows.map(function (item) {
				
				if (cities.indexOf (item.CITY)<0){
					cities.push(item.CITY)
				}
			});
			
			cities.map(function (item) {
				 filterConfig.CITY.values.push ({text:item, value: item} )
			});  
			 
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	 
	 
  ///Search Manager
  searchHandler = async(event) => {
  	this.setState({isLoading:true});
		let resp = await searchHandler(event , this.state.filterConfig, this.state.showMore );
		
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig, showMore: resp.showMore });
		}
		
		if (resp.tableData){
			
			const tableData = this.state.tableData
	 		tableData.rows = resp.tableData.orders
	 		
	 		const tableData2 = this.state.tableData2
	 		tableData2.rows = resp.tableData.orderskus
	 		
		}
		
		this.setState({isLoading:false});
	} 
  
  
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
      
      
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Sales"} subtitle={"Export Orders"}/>
         
	    		<div className="row">
	    			<div className="col-12 col-md-6 col-lg-3">
			    		<Filters filterConfig={this.state.filterConfig} showMore={this.state.showMore} searchHandler={this.searchHandler}/>
	    			</div>	
	    			<div className="col-12 col-md-12 col-lg-9">
							 
							 
							 
							<div className="col-12 col-lg-12 text-right mb-2" style={{padding:0}}>
								<ExcelFile filename={"export-orders."+this.state.date_label} element={         
						         	<button type="button" className="btn btn-success" name="set" > Export Data</button>
						      }>
				          <ExcelSheet data={this.state.tableData.rows} name="Orders">
				              
				              <ExcelColumn label="Order Id" value="ORDERID"/> 
											<ExcelColumn label="Store Name" value="STORENAME"/> 
											<ExcelColumn label="Subtotal Order" value="SUBTOTAL_ORDER"/> 
											<ExcelColumn label="Shipping Cost" value="SHIPPINGCOST"/> 
											<ExcelColumn label="Discount" value="DISCOUNT"/> 
											<ExcelColumn label="Total Order" value="TOTAL_ORDER"/> 
										 
											<ExcelColumn label="Statusname" value="STATUSNAME"/> 
											<ExcelColumn label="First Name" value="FIRSTNAME"/> 
											<ExcelColumn label="Last Name" value="LASTNAME"/> 
											<ExcelColumn label="Email" value="EMAIL"/> 
											<ExcelColumn label="Phone" value="PHONE"/> 
											<ExcelColumn label="Payment City" value="PAYMENT_CITY"/> 
											<ExcelColumn label="Payment Method" value="PAYMENTMETHOD"/> 
											<ExcelColumn label="Shipping Address" value="SHIPPINGADDRESS"/> 
											<ExcelColumn label="Shipping Instructions" value="ADDITIONALSHIPPINGINSTRUCTIONS"/> 
											<ExcelColumn label="Pickup Date" value="PICKUPDATE"/> 
											<ExcelColumn label="Shipping City" value="SHIPPING_CITY"/> 
											<ExcelColumn label="Shipping Method" value="SHIPPINGMETHOD"/> 
											<ExcelColumn label="Shipping Type" value="SHIPPINGTYPE"/> 
											<ExcelColumn label="Coupon" value="HAVECOUPON"/> 
											<ExcelColumn label="Date Added" value="DATE_ADDED"/> 
											<ExcelColumn label="Date Modified" value="DATE_MODIFIED"/> 
				          </ExcelSheet>
				          
				          
				          <ExcelSheet data={this.state.tableData2.rows} name="Orders SKUs">
				            <ExcelColumn label="Order Id" value="ORDERID"/> 
										<ExcelColumn label="Store Name" value="STORENAME"/> 
										<ExcelColumn label="SKU" value="SKU"/> 
										<ExcelColumn label="SKU Name" value="SKUNAME"/> 
										<ExcelColumn label="Price" value="PRICE"/> 
										<ExcelColumn label="Total SKU" value="TOTAL_SKU"/> 
										<ExcelColumn label="Quantity" value="QUANTITY"/> 
										<ExcelColumn label="Subtotal Order" value="SUBTOTAL_ORDER"/> 
										<ExcelColumn label="Shipping Cost" value="SHIPPINGCOST"/> 
										<ExcelColumn label="Discount" value="DISCOUNT"/> 
										<ExcelColumn label="Total Order" value="TOTAL_ORDER"/> 
										 
										<ExcelColumn label="Status" value="STATUSNAME"/> 
										<ExcelColumn label="First Name" value="FIRSTNAME"/> 
										<ExcelColumn label="Last Name" value="LASTNAME"/> 
										<ExcelColumn label="Email" value="EMAIL"/> 
										<ExcelColumn label="Phone" value="PHONE"/> 
										<ExcelColumn label="Payment City" value="PAYMENT_CITY"/> 
										<ExcelColumn label="Payment Method" value="PAYMENTMETHOD"/> 
										<ExcelColumn label="Shipping Address" value="SHIPPINGADDRESS"/> 
										<ExcelColumn label="Shipping Instructions" value="ADDITIONALSHIPPINGINSTRUCTIONS"/> 
										<ExcelColumn label="Pickup Date" value="PICKUPDATE"/> 
										<ExcelColumn label="Shipping City" value="SHIPPING_CITY"/> 
										<ExcelColumn label="Shipping Method" value="SHIPPINGMETHOD"/> 
										<ExcelColumn label="Shipping Type" value="SHIPPING TYPE"/> 
										<ExcelColumn label="Coupon" value="HAVECOUPON"/> 
										<ExcelColumn label="Date Added" value="DATE_ADDED"/> 
										<ExcelColumn label="Date Modified" value="DATE_MODIFIED"/> 
				          </ExcelSheet>

						    </ExcelFile> 
							</div>	
					
						 
							<div className="col-12 col-lg-12" style={{padding:0}}>
								<div className="card card-table flex-fill">
									<h4 className="p-1"> Orders </h4>
									<Table tableData={this.state.tableData} scroll={{ x: 1650, y: 300 }}   />
								</div>
							 
							 
									<div className="card card-table flex-fill">
										<h4  className="p-1"> Orders Details </h4>
										<Table tableData={this.state.tableData2}  scroll={{ x: 1650, y: 300 }} />
									</div> 
							</div>
						</div>	
					</div>		
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 