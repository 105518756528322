/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
/*functions*/
import {manageImage, getLanguages, findObjName, findValueById, listImages,  find,validateComponent, getLanguage, validateSyncForm, changeFilter, filterHandler,validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			modal2: false,
			modalMdClass:"modal-lg",
			modalIamgeClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			filterConfig: {
				EVENT        : { label:"Event Type"        ,  type:"SELECT" , value:"" , values:[] },
			 
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"EVENTID", title:"Event ID",
						sorter: (a, b) => a.EVENTID.length - b.EVENTID.length },
					{ dataIndex:"EVENT", title:"Event",
						sorter: (a, b) => a.EVENT.length - b.EVENT.length },
					{ dataIndex:"LANGUAGE", title:"Language ID",
						sorter: (a, b) => a.LANGUAGE.length - b.LANGUAGE.length },
					 
					 
					{	dataIndex:"SORTORDER", title:"Sort Order",
						sorter: (a, b) => a.SORTORDER.length - b.SORTORDER.length },		
						
					{	dataIndex:"CODE", title:"Code",
						sorter: (a, b) => a.CODE.length - b.CODE.length },
					{ dataIndex: "EVENTID", title: "Action",
		        render: (text, record) => (<div className="text-right">
		         <button className="btn btn-sm btn-info" id={record.EVENTID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Update</button>
		           
		         <button className="btn btn-sm btn-danger" id={record.EVENTID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></div>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Attribute",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				
				EVENT           : { label: "Event Name"       ,"sizelg":"col-lg-6", type: "TEXT",   value: "" } ,
				CODE            : { label: "Event Code"       ,"sizelg":"col-lg-6", type: "TEXT",   value: "" } ,
				LANGUAGE        : { label: "Language"         ,"sizelg":"col-lg-6"  , type: "SELECT", values:[], value: "" , getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"2"}] } } ,
				SORTORDER       : { label: "SORTORDER"        ,"sizelg":"col-lg-6"  , type: "TEXT", validationType:"number",   value: "" } ,
				IMAGEPATH       : { label: "Ref Image"        , validationType:"text",   type: "IMAGE",  value: ""  },
				IMAGEBUTTON     : { label: "Button Image"     , validationType:"text",   type: "IMAGE",  value: ""  },
				PATHLAYOUT     : { label: "Path Layout"       , validationType:"text",   type: "TEXT",  value: ""  },
				
				
			},
			 
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			refreshMessage:"Are you sure?",
			initialFolder:"",
			imageManager:null,
			objImage:{
				items:[],
				selected:[]
			}
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getEvents" )
	 		const tableData = this.state.tableData
			let rows=[]
			let languages= await getLanguages();
			resp.rows.map(function (item) {
				
				let languagelabel =	 findValueById (languages, item.SUBDOMAINID, "id", "name")
				 
				let info = JSON.parse(item.CLOB_VALUE)

				 
				let row={
					EVENTID     : item.DETAILDOMAINID,
					EVENT       : item.STRING_VALUE, 
					SORTORDER   : item.SORTORDER,
					PATHLAYOUT  : info.pathlayout,
					CODE        : item.CODE,
					LANGUAGE    : item.SUBDOMAINID,
					LANGUAGELABEL:languagelabel,
					IMAGEPATH   : info.imagepath,
					IMAGEBUTTON : info.imagebutton,
				} 
				rows.push(row)
			}); 
				 console.log(rows)
	 		tableData.rows = rows
	 		this.setState({originalTableData:this.state.tableData});
	 	 	
	 		await preloadForm ( this.state.formData )	 		
	 		const filterConfig = this.state.filterConfig
	 		 
		 	filterConfig.EVENT.values.push("")
			rows.map(function (item) {
				filterConfig.EVENT.values.push (item.EVENT)
			}); 
			
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	 //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	
	//manage image
	manageImage= async(event) => {
		try{
			this.setState({isLoading:true});			
			var data = findObjName(event).split("_")
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages("",objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal2: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
						let field = objImage.objectName
						var formData = this.state.formData
						formData[field].value = objImage.selected[0]
						this.setState({modal2:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	  
  /*get value fields*/
  changeHandler = async(event) => {
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp 
		let isAsync=false
  	if (  this.state.formData[event.target.name]){
  		if ( this.state.formData[event.target.name].script ){
  			isAsync=true
  		}  		
  	}
		if (isAsync){
	  		resp = await validateForm(this.state.formData, toast,event);
	  	}else{
	  		resp = validateSyncForm(this.state.formData, toast,event);	
	  	}
		this.setState({formData:resp.formData});
		 
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
 
	 
	/*charge data from a selected Attribute*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Event Type"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				 
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Event Type"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		 
	  	  let pos = find ( this.state.originalTableData.rows,itemId, "EVENTID" )
	  		
	  		let data = this.state.originalTableData.rows[pos]
	  		 
	  	//	console.log(data, itemId)
	   
	  		let loadData = await mergeFormData (this.state.formData,data)
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Event Type"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	} 
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
	  			 
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
 					
 					var clobData = { imagepath: _formData.imagepath, imagebutton:_formData.imagebutton, pathlayout : _formData.pathlayout }
 					
 					console.log (this.state.tableData.rows)
 					 
 					let fixedFormData ={
 						domainid         : 21, //default 21 for events
						detaildomainid   : this.state.currentId,
						subdomainid      : _formData.language, 
						subdomain        : "EVENT",
						codedetaildomain : _formData.code,
						stringvalue      : _formData.event,
						numbervalue      : 1,
						datevalue        : null,
						clobvalue        : JSON.stringify(clobData),
						sortorder        : _formData.sortorder

 					}
			  	let resp = await callApi ( "custom",this.state.formAction+"DetailDomains", fixedFormData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getEvents" )
			  		
			  		let rows=[]
						resp.rows.map(function (item) {
							
							var catalogname= item.STRING_VALUE
							
							if (item.NUMBER_VALUE!= item.DETAILDOMAINID){
								catalogname =	 findValueById (resp.rows, item.NUMBER_VALUE, "DETAILDOMAINID", "STRING_VALUE")
							}
							let info = JSON.parse(item.CLOB_VALUE)
							let row={
								EVENTID     : item.DETAILDOMAINID,
								EVENT       : item.STRING_VALUE,
								CATALOGID   : item.NUMBER_VALUE,
								SORTORDER   : item.SORTORDER,
								PATHLAYOUT  : info.pathlayout,
								CATALOGNAME : catalogname,
								CODE        : item.CODE,
								LATITUDE    : info.lat,
								LONGITUDE   : info.lng,
								ADDRESS     : info.address
							} 
							rows.push(row)
						});
			 			const tableData = this.state.tableData
			 			tableData.rows = rows			 			
			 			this.setState({originalTableData:this.state.tableData});
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	} 
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
													:this.state.formAction==="refresh"?
														<h2>{this.state.refreshMessage}</h2>:
													 <Form 
															formData={this.state.formData} 
															manageImage={this.manageImage}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />
			<Modal open={this.state.modal2} 
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1060"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (2)} 
      	class={this.state.modalIamgeClass}
      />
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Event Types"}/>
        	<div className="row mb-4 justify-content-center text-right">
						<div className="col-md-12 col-lg-12">
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 