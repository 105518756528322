/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBIcon } from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import CompCoords from '../../components/CompCoords';
import ImageManager from '../../components/ImageManager';
import Map from '../../components/Map';
import { withScriptjs } from 'react-google-maps';
/*functions*/
import {getLanguages,getSubcomponents,toFixed, filterHandler, manageComponent,objPolygons, listImages, manageImage, find,findValueById,validateComponent, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';
import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const GoogleMapsAPI =   process.env.REACT_APP_API_KEY_GMAP

var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 
var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			date_label :date_label,
			modal1: false,
			modal2: false,
			modal3: false,
			coords:[],
			objPolygons:[],
			languages:[],
			modalMdClass:"modal-lg",
			modalIamgeClass:"modal-lg",
			isLoading: true,
			objImage:{
				items:[],
				selected:[]
			},
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			buttonsModal4 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
			],
			
			buttonsModal3 : [
				 
				{	buttonAction: this.closeMap,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Close"}	
			],
			
		 
			exportData:[],
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"CODE", title:"Polygon Code",
						sorter: (a, b) => a.CODE.length - b.CODE.length },
					{ dataIndex:"TYPE", title:"Type",
						sorter: (a, b) => a.TYPE?a.TYPE.length:0 - b.TYPE?b.TYPE.length:0 },
					{ dataIndex:"CITY", title:"City",
						sorter: (a, b) => a.CITY.length - b.CITY.length },
					 
					{ dataIndex:"PRICE", title:"Shipping Cost",
						sorter: (a, b) => a.PRICE - b.PRICE },
					
					{ dataIndex: "POLYGONID", title: "Action",
		        render: (text, record) => (
		       		<>
			         <button className="btn btn-info" id={record.CODE+"__"+record.TYPE} name="update"
			         onClick={this.loadFormData}
			          type="submit">Edit</button>
			         <button className="btn btn-success" id={record.CODE+"__"+record.TYPE} name="view"
			         onClick={this.loadFormData}
			          type="submit">View</button>
			         <button className="btn btn-danger" id={record.CODE+"__"+record.TYPE} name="delete"
			         onClick={this.loadFormData}
			          type="submit">Delete</button>
		         </>
		        )
		      }
				],
		    rows: [], 
			},
			filterConfig: {
				CODE  : { label:"Code" , type:"TEXT"   , value:""} ,
				CITY  : { label:"City"  , type:"SELECT" , value:"" , values:[] },
				TYPE  : { label:"Type"  , type:"SELECT" , value:"" , values:["","LIMIT", "ZONE"] },
				
			},
			originalTableData:{},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Polygon",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				CODE    : { label: "Code"            , type: "TEXT",   value:"" },
				TYPE    : { label: "Type"            , type: "SELECT", value: "", values: [ {text: "LIMIT", value:"LIMIT"},{text:"ZONE", value:"ZONE"}]   } ,
				CITY    : { label: "City"            , type: "TEXT",   value:"" },
				PRICE   : { label: "Shipping Cost"   , notRequired:true, type: "TEXT",   value:"" },
				COORDS  : { "label": "Coordinates"   , "buttonLabel": "Set Lat/Lng", "type": "CompCoords", "values": []},
			},
						
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getAllPolygons" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		this.setState({originalTableData:this.state.tableData});
	 		
	 		resp = await callApi ( "manager","getAllPolygonsAndCoords" )
	 		this.setState({exportData:resp.rows});
	 		console.log(resp.rows)
	 		var cities=[""]
	 		resp.rows.map(function (item) {
	  		if (cities.indexOf(item.CITY)<0 ){
	  			cities.push(item.CITY)
	  		}
  		}); 
  		const filterConfig = this.state.filterConfig;
  		filterConfig.CITY.values = cities
  		
	 									
	 		await preloadForm ( this.state.formData )
	 		
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	 
	/*manage Components*/
	manageComponent = async(event) => {
		try{ 
			this.setState({isLoading:true});
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
	 		var compManager 
			let action =  data[0]
			var componentName = data[1]
			var _components= getSubcomponents() 
			 
			if (_components.indexOf(action)>=0 ){
 
				if (action==="CompCoords"){
					compManager = <CompCoords    data={this.state.formData.COORDS.values} container= {componentName} manageComponent={this.manageComponent}  />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Set Polygon", componentName:componentName });
				} 
				
			}else{
				if ( data.length>=5){
				 
				}else{
					var formData = manageComponent( event, this.state.formData)
					this.setState({formData:formData});
					compManager = this.setSubComponent(event)		
					this.setState({compManager:compManager});
				}
				
				
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{ 
			this.setState({isLoading:false});
		}
	} 
	
	/*Form Functions*/
	 //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	
  /*get value fields*/
  changeHandler = async(event) => {
  	
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
		 
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
	validateComponent= async(event) => {

		try{
			
			this.setState({isLoading:true});
			var resp = await validateComponent( this.state.componentName, this.state.formData, toast)
			console.log(resp)
			
			this.setState({formData:resp.formData});
	 		let compManager = this.setSubComponent(event)	
	 		if (compManager!= null){
	 			
	 			this.setState({ compManager:compManager  });
	 		}
	 		 
			if (resp.isValid){
				this.setState({modal4:false});
			}else{
				showError(toast,"CHECK COORDINATES, INVALID NUMBER OR OUT OF RANGE");	
			}
			
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	setSubComponent= (event) => {
		//console.log("set")
		var compManager
		if(this.state.subcomponentType==="CompCoords"){
			compManager = <CompCoords  data={this.state.formData.COORDS.values} container= {this.state.componentName} manageComponent={this.manageComponent}  />
		
		} 
		
		return compManager
	}
	cancelComponent= async(event) => {
		try{
			this.setState({isLoading:true});
			const formData = this.state.formData
			formData.COORDS.values=[]
			this.setState({modal4:false});
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	
	/*charge data from a selected Polygon*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Polygon"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				
				var formData= this.state.formData
				formData.COORDS.values = [];
				formData.CODE.disabled = false;
	  		formData.TYPE.disabled = false;
	  		
			//	this.state.formData.ATTRIBUTENAMES.value = undefined
			//	this.state.formData.CONTENTPOLYGONS.value = undefined
			}else if (event.target.name==="view"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Polygon"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		var data = itemId.split("__")
	  		var code= data[0]
	  		var type= data[1] 	  		
	  		let resp = await callApi ( "manager","getPolygonById",{code: code, type:type} )
	  		let loadData = await mergeFormData (this.state.formData,resp.POLYGON[0])
	  		let coords =[]
	  		resp.POLYGON.map(function (item, index) {
	  			coords.push( {  lat: item.LATITUDE, lng: item.LONGITUDE })
	  		}); 
	  		console.log("coords",coords)
	  		this.setState({...this.state,coords:coords})
	  		
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Polygon"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		var data = itemId.split("__")
	  		var code= data[0]
	  		var type= data[1] 	  		
	  		let resp = await callApi ( "manager","getPolygonById",{code: code, type:type} )
	  		let loadData = await mergeFormData (this.state.formData,resp.POLYGON[0])
	  		let coords =[]
	  		resp.POLYGON.map(function (item, index) {
	  			coords.push( { rowId: index+1, latitude: item.LATITUDE.toFixed(6), longitude: item.LONGITUDE.toFixed(6) })
	  		}); 
	  		var formData= this.state.formData
	  		formData.COORDS.values = coords;
	  		formData.CODE.disabled = true;
	  		formData.TYPE.disabled = true;
	  		 
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Polygon"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			if (event.target.name==="view"){
				this.setState({...this.state,modal3:true, isLoading:false});
			}else{
				this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
			}
			
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
	  			
	  			if (this.state.formData.COORDS.values.length<1 ){
	  				respValid= false;
	  				showError(toast,"NO COORDINATES SELECTED");
	  			}
	  			
					 
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
					
					if (actionType==="DELETE"){
						let data = this.state.currentId.split("__")	  			  
	  				_formData["code"] = data[0]
						_formData["type"] = data[1]
					}
			  	let resp = await callApi ( "manager",this.state.formAction+"Polygons", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getAllPolygons" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({originalTableData:this.state.tableData});
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	
	closeMap =(event) => {
	  this.setState({...this.state,  modal3: false});
	}
  
	
	render(){
		const MapLoader = withScriptjs(Map);
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
												 :
													<>
													 <Form 
															formData={this.state.formData} 
															manageComponent={this.manageComponent}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
													 
													 
													</>							
												}
							  />
			<Modal open={this.state.modal2}
      	icon="fas fa-images"
      	title={this.state.titlemodal2}
      	zindex="1060"
      	message={this.state.compManager}
      	buttons={this.state.buttonsModal2}
      	class={this.state.modalMdClass}
      />
      
      <Modal open={this.state.modal4}
      	icon="fas fa-images"
      	title={this.state.titlemodal4}
      	zindex="1060"
      	message={this.state.compManager}
      	buttons={this.state.buttonsModal4}
      	class={this.state.modalLgClass}
      />
      
      
      <Modal open={this.state.modal3}
      	icon="fas fa-images"
      	title="Polygon"
      	zindex="1060"
      	toggle={this.toggleModal(3)} 
      	message=  <MapLoader
					 	height='480px'
					 	coords={this.state.coords}
					 	googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+GoogleMapsAPI+"&libraries=places"}
					 	loadingElement={<div style={{ height: `380px` }}></div>}
  					/> 
  					
      	buttons={this.state.buttonsModal3}
      	class={this.state.modalLgClass}
      />
      
      
       
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Polygons"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
						
							<ExcelFile filename={"export-polygons."+this.state.date_label} element={         
					          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
					      }>
					          <ExcelSheet data={this.state.exportData} name="Polygons">
				              
				              
				              <ExcelColumn label="CITY" value="CITY"/> 
				              <ExcelColumn label="TYPE" value="TYPE"/>
				              <ExcelColumn label="Code" value="CODE"/>
				              <ExcelColumn label="Cost" value="PRICE"/>
				              <ExcelColumn label="Latitude" value="LATITUDE"/> 
				              <ExcelColumn label="Longitude" value="LONGITUDE"/> 
					          </ExcelSheet>
 
					    </ExcelFile>
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 