/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';

/*functions*/
import {getLanguages, validateComponent,find, findObjName,changeFilter,validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			modal2: false,
			modalMdClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.setCustomersGroup,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.toggleModal(2),
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"CUSTOMERGROUPNAME", title:"Customer Group Name",
						sorter: (a, b) => a.CUSTOMERGROUPNAME.length - b.CUSTOMERGROUPNAME.length },
					{ dataIndex:"SORTORDER", title:"Sort Order",
						sorter: (a, b) => a.SORTORDER.length - b.SORTORDER.length },
					{ dataIndex: "CUSTOMERID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-sm btn-info" id={record.CUSTOMERGROUPID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-success" id={record.CUSTOMERGROUPID} name="loadcustomers"
		         onClick={this.loadCustomers}
		          type="submit">Set Customers</button>  
		          
		         <button className="btn btn-sm btn-danger" id={record.CUSTOMERGROUPID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Customer",
			modalTitle2:"Manage Customers",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			
			formConfig2:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				CUSTOMERGROUPNAMES    : { label: "Customer Group Name"     , type: "CompLanguage",   values: [] },
				CUSTOMERGROUPDESCS    : { label: "Description"     , type: "CompLanguage", subType: "TEXTAREA",  values: [] }, 
				REQUIRESSHIPPING     : { label: "Approve New Customers"   , notRequired:true , type: "RADIO",  value: [], values: [], getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"8"}] }  } ,
				SORTORDER        : { label: "Sort Order"              , type: "TEXT"        ,   value: "" } 
			},
			
			formData2	: {
				CUSTOMERS          : { label: "Customers" , showAll:true , filterValue:"", validationType:"array"  , type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getCustomers", apiGroup:"manager"} } ,
			},
						
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getCustomerGroups" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		
	 	let languages= await getLanguages()
	 	let languages2= await getLanguages()
	 	let formData	= {
	 			CUSTOMERGROUPNAMES    : { label: "Customer Group Name"     , type: "CompLanguage",   values: languages},
				CUSTOMERGROUPDESCS    : { label: "Description"             , type: "CompLanguage",   subType: "TEXTAREA", values: languages2}, 
				APPROVENEWCUSTOMERID     : { label: "Approve New Customers"    , notRequired:true , type: "RADIO",  value: [], values: [], getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"8"}] }  } ,
				SORTORDER        : { label: "Sort Order"                   , type: "TEXT"        ,   value: "" } 
			};
			
			this.setState({  ...this.state, formData:formData})								
	 		await preloadForm ( this.state.formData )
	 		
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	 /*get value fields*/
  changeHandler = async(event) => {
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
		 
	} 
	  
  /*get value fields*/
  changeHandler2 = async(event) => {
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp = await validateForm(this.state.formData2, toast,event);
		this.setState({formData2:resp.formData});
		 
	} 
	changeFilter2 = async(event) => {

		let _data = findObjName(event)
		let data =  _data.split("__")
		var originalValues=[]
		if (data.length==1){
			originalValues = this.state.formData2[data].originalValues
		}
     
  	var resp = await changeFilter(event, originalValues , this.state.formData2)	
    this.setState({formData2:resp});
	}
	
	loadCustomers =  async(event) => {
		this.setState({  ...this.state, isLoading:true})
		await preloadForm ( this.state.formData2 )
		var itemId = event.target.id
		let resp = await callApi ( "manager","getCustomerGroupById",{customergroupid: itemId} )
	  
	 	var customers = this.state.formData2.CUSTOMERS.originalValues
	 	var customersInGroup = resp.CUSTOMERGROUP.CUSTOMERS
	 	this.state.formData2.CUSTOMERS.value =[]
	 	for (let c in customers){
	 		let pos = find (customersInGroup, customers[c].value, "CUSTOMERID" )
	 		if (pos>=0){
	 			this.state.formData2.CUSTOMERS.value.push( customers[c].value )
	 		}
	 	}
	 	console.log(this.state.formData2.CUSTOMERS.value.length , this.state.formData2.CUSTOMERS.values.length)
	 	if (this.state.formData2.CUSTOMERS.value.length == this.state.formData2.CUSTOMERS.values.length){
	 		this.state.formData2.CUSTOMERS.checkAll = true;
	 	}else{
	 		this.state.formData2.CUSTOMERS.checkAll = false;
	 	}
	 	
	 	
	  this.state.formData2.CUSTOMERS.filterValue=""
	  this.setState({  ...this.state, isLoading:false, currentId:itemId, modal2: true})
  }
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
	setCustomersGroup=async (event) => {
  	try{
  		this.setState({  ...this.state, isLoading:true })
			let _formData =  this.state.formData2;
			_formData = parseFormData(_formData)
			_formData["customergroupid"] = this.state.currentId;
			
	  	let resp = await callApi ( "manager","setCustomerGroupsToCustomers", _formData )
	  	if (resp.success){
	  		showMessage(toast,resp.message);	
	  	}else{
	  		showError(toast,resp.message);
	  	}
		 
		}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false,modal2:false });
		} 	
	}
	 
	/*charge data from a selected Customer*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Customer Group"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				this.state.formData.CUSTOMERGROUPNAMES.value = undefined
				this.state.formData.CUSTOMERGROUPDESCS.value = undefined
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Customer Group"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		
	  		let resp = await callApi ( "manager","getCustomerGroupById",{customergroupid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData,resp.CUSTOMERGROUP)
	  		
	  		var names=[], descs=[]
	  		resp.CUSTOMERGROUP.CUSTOMERGROUPDESCS.map(function (item) {
	  			names.push( {  id: item.LANGUAGEID, name: item.LANGUAGE, value: item.NAME })
	  			descs.push( {  id: item.LANGUAGEID, name: item.LANGUAGE, value: item.DESCRIPTION })
	  		}); 
	  	 
	  		this.state.formData.CUSTOMERGROUPNAMES.value = undefined
				this.state.formData.CUSTOMERGROUPDESCS.value = undefined
				
	  		this.state.formData.CUSTOMERGROUPNAMES.values = names
	  		this.state.formData.CUSTOMERGROUPDESCS.values = descs
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Customer Group"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
  
  

	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		console.log(actionType)
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
	  			
	  			this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
					_formData["customergroupid"] = this.state.currentId;
					
			  	let resp = await callApi ( "manager",this.state.formAction+"CustomerGroups", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getCustomerGroups" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
					 
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
					_formData["customergroupid"] = this.state.currentId;
					
			  	let resp = await callApi ( "manager",this.state.formAction+"CustomerGroups", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getCustomerGroups" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
													:
													 <Form 
															formData={this.state.formData} 
															 
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />
			<Modal open={this.state.modal2}
      	icon="fas fa-users"
      	title={this.state.modalTitle2}
      	zindex="1060"
      	message=
      					 <Form 
      					 		formData={this.state.formData2} 
										changeHandler={this.changeHandler2} 
										changeFilter={this.changeFilter2}
										formConfig={this.state.formConfig2}
								 />
								 
      	buttons={this.state.buttonsModal2}
      	class={this.state.modalMdClass}
      />
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Customer Groups"}/>
        	
        	<div className="row mb-4 justify-content-center text-right">
						<div className="col-md-12 col-lg-12">
								<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 