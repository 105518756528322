/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';

/*functions*/
import {getLanguages, find,validateComponent, getLanguage, validateSyncForm, findValueById,changeFilter, filterHandler,validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

 

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			stores:[],
			hours:[],
			hours2:[],
			days: [
				"LUNES",
				"MARTES",
				"MIERCOLES",
				"JUEVES",
				"VIERNES",
				"SABADO",
				"DOMINGO"
			],
			modal2: false,
			modalMdClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			filterConfig: {
				SHIPPINGNAME        : { label:"Shipping Name"        ,  type:"SELECT" , value:"" , values:[] },
			 
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"SHIPPINGTYPEID", title:"Shipping ID",
						sorter: (a, b) => a.SHIPPINGTYPEID.length - b.SHIPPINGTYPEID.length },
					{ dataIndex:"SHIPPINGNAME", title:"SHIPPINGNAME",
						sorter: (a, b) => a.SHIPPINGNAME.length - b.SHIPPINGNAME.length },
				
						
					{ dataIndex: "SHIPPINGTYPEID", title: "Action",
		        render: (text, record) => (<div className="text-right">
		         <button className="btn btn-sm btn-info" id={record.SHIPPINGTYPEID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Update</button>
		           
		        </div>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Attribute",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				
				SHIPPINGNAME           : { label: "SHIPPINGNAME"       ,"sizelg":"col-lg-6", type: "TEXT",   value: "" } ,
				CATALOGNAME     : { label: "Catalog Name","sizelg":"col-lg-6" , notRequired:true , type: "SELECT", value: "", values: [] ,"addDefault":"Same as SHIPPINGNAME" , getDataDB: {apiGroup: "custom", apiMethod: "getDetailDomainsByCodeFromDB" ,  aditionalValues:[{domainid:"15"} , {subdomainId:"1"}] }} ,
				CODE            : { label: "CODE"     ,"sizelg":"col-lg-6"  , type: "TEXT",   value: "" } ,
				SORTORDER       : { label: "SORTORDER"  ,"sizelg":"col-lg-6"  , type: "TEXT", validationType:"number",   value: "" } ,
				ADDRESS         : { label: "SHIPPINGNAME Info"       , "sizelg":"col-lg-12", notRequired:true, type: "TEXT",   value: "" } ,
				LATITUDE        : { label: "Latitude"            , "sizelg":"col-lg-6"    , validationType:"number" , type: "TEXT",   value: "" } ,
				LONGITUDE       : { label: "Longitude"           , "sizelg":"col-lg-6"    , validationType:"number" , type: "TEXT",   value: "" } ,
			},
			 
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			refreshMessage:"Are you sure?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getFormShippingTypes" )
			let respS = await callApi ( "manager","getStores" )

			let respSS = await callApi ( "custom","getDetailDomainsByCodeFromDB" , {domainid:28} )


			let hours=[]
			let hours2=[]
			for (let i= 0; i<10;i++){
				hours.push ("0"+i+":00"  )
				hours.push ("0"+i+":30"  )

				hours2.push ("0"+i+":30"  )
				hours2.push ("0"+i+":59"  )
			}
			for (let i= 10; i<24;i++){
				hours.push (i+":00"  )
				hours.push (i+":30"  )

				hours2.push (i+":30"  )
				hours2.push (i+":59"  )
			}


			let rowsS=[]
			let days = this.state.days
			respS.rows.map(function (item) {				
				let _days=[]
				for (let d in days){
					let day ={
						day : days[d],
						active: true,
						from :  "08:00", 
						to   :  "16:00"
					}
					_days.push(day)
				}
				let _stores =[]

				respSS.rows.map(function (item2) {
					if (item2.SUBDOMAINID === item.DETAILDOMAINID ){
						_stores.push({code: item.CODE, storeid: item2.DETAILDOMAINID, store: item2.STRING_VALUE, hours:48} )
					}
				}); 
				let row={
					
					STOREID     : item.DETAILDOMAINID,
					STORE       : item.STRING_VALUE,
					cost        : 5,
					active      : true,
					days        : _days,
					stores      : _stores
				} 
				rowsS.push(row)
			}); 
			
	 		const tableData = this.state.tableData
			let rows=[]
			resp.rows.map(function (item) {
				
				let coord = JSON.parse(item.CLOB_VALUE)
				let row={
					SHIPPINGTYPEID  : item.SHIPPINGTYPEID,
					SHIPPINGNAME    : item.STRING_VALUE,				 
					STORES    		: coord,					
					domainid        : 27  ,
					detaildomainid  : item.DETAILDOMAINID,
					subdomainid     : item.SUBDOMAINID,
					subdomain       : item.SUBDOMAIN,
					codedetaildomain: item.CODE,
					stringvalue     : item.STRING_VALUE,
					numbervalue     : item.NUMBER_VALUE,
					datevalue       : item.DATE_VALUE,
					sortorder       : item.SORTORDER
				} 
				rows.push(row)
			}); 
			console.log(rows);

	 		tableData.rows = rows
	 		this.setState({originalTableData:this.state.tableData, /*stores:rowsS,*/ hours:hours, hours2:hours2});
	 	 	
	 		await preloadForm ( this.state.formData )	 		
	 		const filterConfig = this.state.filterConfig
	 		 
		 	filterConfig.SHIPPINGNAME.values.push("")
			rows.map(function (item) {
				filterConfig.SHIPPINGNAME.values.push (item.SHIPPINGNAME)
			}); 
			
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	 //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	 
	  
  /*get value fields*/
  changeHandler = async(event) => {
	var field_value = event? (event.target.value) :undefined
	var field_name  = event?event.target.name:undefined 
	const stores= this.state.stores
//	console.log("field_value",field_value)
//	console.log("field_name", field_name)
	if (field_name.indexOf("STORE_active_") ===0){
	
		stores[field_value]["active"] = !stores[field_value]["active"]
	}else if (field_name.indexOf("cost_") ===0){
		
		let _pos = field_name.split("_")
		let pos = _pos[1]
		if (isNaN(field_value)){
			stores[pos]["isInvalid_cost"] = true
			stores[pos]["cost"] =  field_value
		}else{
			stores[pos]["isInvalid_cost"] = false
			stores[pos]["cost"] =  field_value
		}
	}else if (field_name.indexOf("DAY_active_") ===0){		
		stores[field_value[0]].days[field_value[1]]["active"] = !stores[field_value[0]].days[field_value[1]]["active"] 

	}else if (field_name.indexOf("SUBSTORE_active_") ===0){		
		stores[field_value[0]].stores[field_value[1]]["active"] = !stores[field_value[0]].stores[field_value[1]]["active"] 

	}else if (field_name.indexOf("FIELDVALUE_") ===0){	

		let _pos = field_name.split("_")
		let posS = _pos[1]
		let posD = _pos[3]
		let value = _pos[2]
		stores[posS].days[posD][value] =  field_value
		 
	}else if (field_name.indexOf("2FIELDVALUE_") ===0){	

		let _pos = field_name.split("_")
		let posS = _pos[1]
		let posD = _pos[3]
		let value = _pos[2]
		stores[posS].stores[posD][value] =  field_value
		 
	} 

//		stores[field_value]["active"] = !stores[field_value]["active"]

	this.setState({ stores:stores});

		 
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
 //delete
	refresh=async (event) => {
  	try{	  	
			this.setState({  ...this.state, isLoading:true })
			let resp = await callApi ( "manager","updateProductsQtyBySHIPPINGNAMEs", {} )
			/*	
			let languages= await getLanguages()
								  
			for (let l in languages){
				let data= { languageid: languages[l].id,
								  	menutype:"CUSTOMERHEADER"} 
				let resp1 = await callApi ( "manager", "updateCategoriesByStock", data )	
			}
			*/
			let data= { languageid: getLanguage(),
								  	menutype:"CUSTOMERHEADER"} 
			let resp1 = await callApi ( "manager", "updateCategoriesByStock", data )	
			
			if (resp.success){
	  		showMessage(toast,resp.message);  
	 			this.setState({...this.state,modal1:false });
	  	}else{
	  		showError(toast,resp.message);
	  		this.setState({...this.state,modal1:false });
	  	}
			  	
	 	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:false,isLoading:false });
		}
	}
	 
	/*charge data from a selected Attribute*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true, })
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Product Qty"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				 
	  	}else if (event.target.name==="update"){
			buttonsModal1 = [
				{	buttonAction: this.update,
					buttonId    : 1,
					buttonClass : "btn btn-outline-info",
					buttonName  : "Edit"}
			]				
			modalIcon="fas fa-edit"
			modalTitle="Edit Shipping Type"
			itemId = event.target.id
			formAction="update"
			modalClass =  "modal-full-height modal-lg"
	  		 
	  	  	let pos = find ( this.state.originalTableData.rows,itemId, "SHIPPINGTYPEID" )
	  		
	  		let stores = this.state.originalTableData.rows[pos].STORES
			console.log("stores", stores)

			this.setState({...this.state,stores:stores,currentId :itemId })

			setTimeout(  function() {  
			this.setState({...this.state,modal1:true,isLoading:false, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });


			}.bind(this),10)
	  		
	  	} 
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{

			
			
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			let respValid = true
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					 
					let pos = find ( this.state.originalTableData.rows,this.state.currentId, "SHIPPINGTYPEID" )
					let _formData = this.state.originalTableData.rows[pos]
					 
 					var clobData =this.state.stores
 					
 					 
 					_formData["clobvalue"] =  JSON.stringify(clobData)

					console.log(_formData)

			  	let resp = await callApi ( "custom",this.state.formAction+"DetailDomains", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getFormShippingTypes" )
			  		
			  		let rows=[]
						resp.rows.map(function (item) {
							let coord = JSON.parse(item.CLOB_VALUE)
							let row={
								SHIPPINGTYPEID  : item.SHIPPINGTYPEID,
								SHIPPINGNAME    : item.STRING_VALUE,				 
								STORES    		: coord,					
								domainid        : 27  ,
								detaildomainid  : item.DETAILDOMAINID,
								subdomainid     : item.SUBDOMAINID,
								subdomain       : item.SUBDOMAIN,
								codedetaildomain: item.CODE,
								stringvalue     : item.STRING_VALUE,
								numbervalue     : item.NUMBER_VALUE,
								datevalue       : item.DATE_VALUE,
								sortorder       : item.SORTORDER
							} 
							rows.push(row)
						});
			 			const tableData = this.state.tableData
			 			tableData.rows = rows
			 			
			 			this.setState({originalTableData:this.state.tableData});
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	} 
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.currentId !=="1"?
													<>  
													 											 
														{this.state.stores.map((item, index) => (
															<>
																	<div className="row mb-1">
																		<div className={"col-3"} style={{display: "flex",alignItems: "center", marginTop:7}}>
																			<div key={"div_form1_"+item} className="form-group card-label ">
																				<h5>{item.STORE} </h5>
																			</div> 
																		</div>

																		<div className={"col-1"} style={{display: "flex",alignItems: "center"}}>
																			<div key={"div_form1_"+item} className="form-group card-label ">																			
																				<Switch checked={item.active} onChange={()=>{this.changeHandler({target: {name:"STORE_active_"+index, value: index }} )}} id={"active_"+item.STOREID} name={"active_"+item.STOREID}/>
																			</div> 
																		</div>
																	 
																		<div className={"col-5"} style={{display: "flex",alignItems: "center", justifyContent:"right"}}>

																			<div key={"div_form_"+item} className="form-group card-label ">
																				<label key={"label_form_"+item}>{ "Costo"  }  </label>									
																				<input id={"cost_"+item.STOREID}  key={"cost_"+item.STOREID} 
																								name={"cost_"+index}
																								placeholder={"Costo" }
																								className={"form-control form-control-sm "+ (item.isInvalid_cost?"is-invalid":"")}
																								type="text" 
																								
																								value={item.cost}
																								onChange={this.changeHandler} />
																			</div> 
																		</div>
																	</div>
																	{item.active &&
																		<>
																			<div className="row mb-1">
																				<div className={"col-3 day-cont"} >
																					<h6>Day</h6>
																				</div>
																				<div className={"col-3 day-cont"} >
																					<h6>From</h6>
																				</div>
																				<div className={"col-3 day-cont"} >
																					<h6>To</h6>
																				</div>
																			</div>
																			{item.days.map((itemD, index2) => (
																				<div className="row mb-1">
																					<div className={"col-3 day-cont"} >
																						<Switch checked={itemD.active} onChange={()=>{this.changeHandler({target: {name:"DAY_active_"+index+"_day_"+index2, value: [index,index2] }} )}}  name={"active_"+item.STOREID+"_day_"+itemD.day}/>
																						<h6>{itemD.day}</h6>
																					</div>		

																					<div className={"col-3"}>

																						<select  key={item.STOREID+"_from_"+itemD.day} 
																								name={"FIELDVALUE_"+index+"_from_"+index2}
																								defaultValue={itemD.from} 
																								onChange={this.changeHandler} 
																								className={"form-control form-control-sm" }>
																								{this.state.hours.map(itemH => (
																									<option value={itemH}>{itemH}</option>
																								))} 																								
																						</select>		
																					</div>		

																					<div className={"col-3"}>
																						<select  key={item.STOREID+"_to_"+itemD.day} 
																								name={"FIELDVALUE_"+index+"_to_"+index2}
																								defaultValue={itemD.to} 
																								onChange={this.changeHandler} 
																								className={"form-control form-control-sm" }>
																								{this.state.hours2.map(itemH => (
																									<option value={itemH}>{itemH}</option>
																								))} 																								
																						</select>		
																						
																					</div>		

																				</div>																			
																			))} 
																		</>
																	}						  	
																	
															</>
															))
														} 
													</>
												:
													<> 
													{this.state.stores.map((item, index) => (
															<>
																	<div className="row mb-1">
																		<div className={"col-3"} style={{display: "flex",alignItems: "center", marginTop:7}}>
																			<div key={"div_form1_"+item} className="form-group card-label ">
																				<h5>{item.STORE} </h5>
																			</div> 
																		</div>

																		<div className={"col-1"} style={{display: "flex",alignItems: "center"}}>
																			<div key={"div_form1_"+item} className="form-group card-label ">																			
																				<Switch checked={item.active} onChange={()=>{this.changeHandler({target: {name:"STORE_active_"+index, value: index }} )}} id={"active_"+item.STOREID} name={"active_"+item.STOREID}/>
																			</div> 
																		</div>																		
																	</div>
																	{item.active &&
																		<>
																			<div className="row mb-1">
																				<div className={"col-3 day-cont"} >
																					<h6>Store</h6>
																				</div>
																				<div className={"col-3 day-cont"} >
																					<h6>Hours</h6>
																				</div>		
																				<div className={"col-6 day-cont"} >
																					<h6>Adrress</h6>
																				</div>																				
																			</div>
																			{item.stores.map((itemD, index2) => (
																				<div className="row mb-1">
																					<div className={"col-3 day-cont"} >
																						<Switch checked={itemD.active} onChange={()=>{this.changeHandler({target: {name:"SUBSTORE_active_"+index+"_day_"+index2, value: [index,index2] }} )}}  />
																						<h6>{itemD.code + " "+itemD.store}</h6>
																					</div>		

																					<div className={"col-3"}>

																						<input id={"cost_"+item.storeid}  key={item.storeid+"_hours_"+itemD.day} 
																								name={"2FIELDVALUE_"+index+"_hours_"+index2}																								
																								className={"form-control form-control-sm "+ (itemD.isInvalid_hour?"is-invalid":"")}
																								type="text" 
																								value={itemD.hours}
																								onChange={this.changeHandler} />
																					</div>	

																					<div className={"col-6"}>

																						<input id={"add_"+item.storeid}  key={item.storeid+"_add_"+itemD.day} 
																								name={"2FIELDVALUE_"+index+"_add_"+index2}																								
																								className={"form-control form-control-sm "+ (itemD.isInvalid_hour?"is-invalid":"")}
																								type="text" 
																								value={itemD.add}
																								onChange={this.changeHandler} />
																					</div>			


																					

																				</div>																			
																			))} 
																		</>
																	}							  	
																	
															</>
															))
														} 

													 
													</>
												
												 }
							  />
			 
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Shipping Options"}/>
        	 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 