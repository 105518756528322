import React, { useState, useEffect } from "react";
import {toFixed, find,getImgagesExtension, searchArray,validateNumber, getDefValues,getImagePath,getCurrency } from '../../common/functions';
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBIcon,
  MDBTooltip,
} from 'mdbreact';

import defaultImage from "../../assets/images/default.jpg";
const windowWidth = window.innerWidth
const LoadProduct = (props) => {
 
	
	var qRequested =""
	var skucomment =""
	if (props.productData.quantities[0].quantityrequested){
		qRequested = props.productData.quantities[0].quantityrequested
	}
	
	if (props.productData.quantities[0].skucomment){
		skucomment = props.productData.quantities[0].skucomment
	}
	
	

	var target="_self"
	var action= ""
	var linkInfo=( props.productData.category? "/"+props.productData.category+"/"  :"/productDetails/") +
							 ( props.productData.productpath? props.productData.productpath :props.productData.label)+
							 ( props.productData.skuCloned?"?suggestedcolor_"+props.productData.skuCloned:"")
	var elementId= ""
	
	//href={( props.productData.category? "/"+props.productData.category+"/"  :"/productDetails/")+ (props.productData.productpath?  props.productData.productpath :props.productData.label) + ( props.productData.skuCloned?"?suggestedcolor_"+props.productData.skuCloned:"" ) }
	
	if (props.productConfig.fatherConfig){
		if (props.productConfig.fatherConfig.action && props.productConfig.fatherConfig.id){
			
			action = props.productConfig.fatherConfig.action
			elementId = props.productConfig.fatherConfig.id
			target= "_blank"
			linkInfo =  linkInfo+ ( props.productData.skuCloned?"&":"?" ) +"action="+action+"&id="+elementId+ ( qRequested==""?"": "&q="+qRequested )
		}
	}
	
	const [path,setPath] = React.useState(null);
	
	const [showComment,setShowComment] = React.useState(false);
	
	React.useEffect(()=>{
		/*
		document.addEventListener("scroll", () => {
	 	 
	 		setShowComment(false)
		});*/
		
		setPath(props.productData.path)
	 },[props])
	const toggleShowComment = (sku)=>{
		setShowComment(!showComment )
	}
	const selectImage = (imagePath) => { 
		try{
			let _data= imagePath.split("/")
			let _sku = (_data[ _data.length-1]).split("_")
			let sku =""
			for (let s in _sku){
				if (s< _sku.length-1){
					sku= sku+ "_"+ _sku[s]
				}
			}
			
			sku = sku.substr(1, sku.length)
			
			let pos = find (props.productData.quantities, sku, "sku")
			let option = props.productData.quantities[pos] 

			if ( option.imagesquantity >1){
				if (_sku[ _sku.length-1 ] =="1.jpg"  ){
					setPath(option.imagespath+sku+"_2"+getImgagesExtension() )
				}else{
					setPath(option.imagespath+sku+"_1"+getImgagesExtension() )
				}
			}
		}catch(Exc){
		}
		
	};
	
	const manageProdCart = (_event, banned, optionvalue) => { 
		
		try{
			if(!banned){ 
				var event = {target: {name: _event }}
				var data = (_event).split("__")
				let pos=0
				var arrPaths2= []
				var arrPaths= []
				var arrSKUs= [] 
				var arrPaths  = searchArray ( props.productData.quantities,"optionvalue", "imagepath",optionvalue , arrPaths )
				var arrPaths2 = searchArray ( props.productData.quantities,"optionvalue", "imagespath",optionvalue , arrPaths2 )
				var arrSKUs   = searchArray ( props.productData.quantities,"optionvalue", "sku", optionvalue      , arrSKUs )
				var path  = arrPaths[0]				
				if (path==null){
					path = arrPaths2[0]+arrSKUs[0]+"_1"+getImgagesExtension()
				}				
				props.productConfig.manageCart(event)
				setPath(path)
			}
			 
		}catch(Exc){
		}
		
	};
	
	
	
	const style={}
	
	if (props.productData.tagbgcolor){
		style["backgroundColor"] = props.productData.tagbgcolor
	}
	if (props.productData.tagcolor){
		style["color"] = props.productData.tagcolor
	}
	
	var tag = props.productData.tag
	var tagAdd=""
	
	var imageSkuSelected = props.productData.productsku?props.productData.productsku: props.productData.genericsku
	if (props.productData.cloned || props.productData.skuCloned){
		//console.log("clon no igual", imageSkuSelected)
		
		props.productData.quantities.map(function (o) {
			if (imageSkuSelected == o.sku){
				if (o.newpriced2!=null){
					tag = "-"+  parseInt (o.newpriced2 *100) +"% "
					 
					
					if (props.productData.quantities.length!=props.productData.allSKusD && props.productData.isLockedCat ){
						tagAdd=getDefValues().selectCodesLabel
					}
					
				} 
				if (o.newpricec1!=null){
					tag = 	o.newpricec1 +"x"+getCurrency()+  toFixed( o.newpricec2*o.newpricec1)
					
					if (props.productData.quantities.length!=props.productData.allSKusC && props.productData.isLockedCat ){
						tagAdd= getDefValues().selectCodesLabel
					}
				}  
			}
		})
		
	}else{
		props.productData.quantities.map(function (o) {
			if (o.newpriced2!=null){
				tag = "-"+  parseInt (o.newpriced2 *100) +"% "
				if (props.productData.quantities.length!=props.productData.allSKusD ){
					tagAdd=getDefValues().selectCodesLabel
				}
			} 
			if (o.newpricec1!=null){
				tag = 	o.newpricec1 +"x"+getCurrency()+  toFixed( o.newpricec2*o.newpricec1)
				if (props.productData.quantities.length!=props.productData.allSKusC ){
					tagAdd= getDefValues().selectCodesLabel
				}
			}  
		})
	}
	
	/*tag="-"
	tagAdd=getDefValues().selectCodesLabel*/
	var ctag
	var cstyle={}
	if (props.productData.customTag){
		//console.log("tacg", props.productData.customTag)
		//tag=null
		ctag= props.productData.customTag;
	//	console.log("tacg", ctag.style["font-size"])
	//	console.log("tacg", ctag.style["font-weight"])
		cstyle = {
			background: ctag.style.background? ctag.style.background: "#FFF",
			color: ctag.style.color? ctag.style.color:"#000",
			"font-size": (ctag.style["font-size"]? ctag.style["font-size"]+"px":"16px"),
			"font-weight": (ctag.style["font-weight"]? ctag.style["font-weight"]:"normal"),
			"text-decoration": ctag.style["text-decoration"]? ctag.style["text-decoration"]:"none",
			"top" : "4px",
			"opacity":"1"
			} 
		if (ctag.style.position){
			cstyle["top"] = ctag.style.position
		} 
		if (ctag.style.align){
			if (ctag.style.align== "left" ){
				cstyle["left"] = "1px"
			}else{
				cstyle["right"] = "1px"	
			} 
		}else{
			cstyle["right"] = "1px" 
		}  
		if (ctag.style.opacity){
			if (ctag.style.opacity== "0.75" ){
				cstyle["opacity"] = "0.25"
			}else if (ctag.style.opacity== "0.50" ){
				cstyle["opacity"] = "0.50"	
			}else if (ctag.style.opacity== "0.25" ){
				cstyle["opacity"] = "0.75"	
			}else{
				cstyle["opacity"] = "1"	
			} 
		}  
		if (ctag.style.imagepath){
			cstyle["paddingLeft"] = "0"
			cstyle["paddingRight"] = "0"
			cstyle["imagepath"] = true 
		}  
	}
	if (props.productData){
	  return (
	 	<> 
 		  {skucomment!=""?
		  	<div  onClick={() => { setShowComment(false); }}
		  	 className={"sku-comment-container"+ ( showComment?"":"-hide")} >
		 			<div  className={"sku-comment-active"} tabIndex="-1">
		 				<div>
							
							<div className={"sku-comment mb-1 "+ ( showComment?"":"hide-div")} >
								{skucomment}
							</div>
							<div className="sku-btn-container mb-1">
								<button   onClick={toggleShowComment}	className="btn btn-sm btn-outline-secondary">
									{getDefValues().closeLabel}
								</button> 
								<button  name={"add__"+props.productData.productid+"__"+elementId} 
								 
								 onClick={() => {
	              props.productConfig.manageCart( { target: { name:"add__"+props.productData.productid+"__"+elementId } } );
	              setShowComment(false);
	            }}
								  	className="ml-2  btn btn-sm btn-secondary">
									<span className="hide-mobile">	{getDefValues().btnaddbutton}</span>
									<span className="hide-web">	{getDefValues().shortbtnaddbutton}</span>
								</button>
							</div>
						</div> 
					</div>
				</div>
			:""}
		  <div  className="profile-widget">
			  
			 
				<div className="doc-img" >
					{tag?
		  			<div style={style} className= { tag=="new"?"product-new-tag":"product-tag"}>
							{ tag=="new"?getDefValues().newLabel: tag + (tagAdd!=""?"*":"" ) }
						
						</div>	
					:""
		  		}
		  		{tagAdd!=""?
							<div className="product-tag-info"> {tagAdd}</div>
					:""}
		  								
		  		
		  		{ctag? cstyle.imagepath?
		  				<div style={cstyle} className= { "custom-product-tag"}>
								<img src={getImagePath(ctag.style.imagepath)}  />
							</div>
		  		  :	
		  				<div style={cstyle} className= { "custom-product-tag"}>
								{ ctag.label}
							</div>
					:""
		  		}
		  		
					<a 
					  target = {target}
					  key   ={"text"+props.productData.productid} 
    				href={linkInfo} tabIndex="-1"
						onMouseEnter={() =>selectImage(path)}
    			  onMouseLeave={() =>selectImage(path)}
    			>
							<img  
										key   ={"img"+props.productData.productid} 
										name={"manageImage__"+props.productData.productid+"__"+props.productData.path} 
										className ="product-image"
										/*width ={props.productData.width?  props.productData.width :"300px" }
										height={props.productData.height? props.productData.height:"300px" }*/
										src   = {path==null?defaultImage:path!=""?getImagePath(path):defaultImage}
									/> 
					</a> 
				</div>
				
				<div className="pro-content" >
					
					{/*onClick={!img.banned?props.productConfig.manageCart:console.log("")}*/}
					
					{ props.productData.options?  props.productConfig.showVariants ?
							<div  className="option" tabIndex="-1">
								<div oldstyle={{display:"block", width:"90%", background:"#fff", "min-height":"92px", "cursor":"auto" }}>
								 	{props.productData.options.map(	
								 		(feat,i ) => ( ( i!= 0 && windowWidth<766 ) ?"":
								 			<>
											{feat.optionvalues.length>1?
									 			<div key={"feat_"+i}  id={"feat_"+i} className="attr-detail attr-color">
													<div className="row"> 
					     						<div className="col-12 list-filter color-filter-mini">
					     						<>
					                  {feat.optionvalues.map(
					                    (img, i) => ( i <  (img.image?6:5 ) ?
					                      <li key={"value_"+i} >
					                        <div style={{ "cursor":"pointer" }} name={feat.optionid+"__"+img.value} id={feat.optionid+"__"+img.value} >
					                       		{img.image && img.image.length>7?
					                       			<>
						                       			<button 
						                       				className="no-button"
						                       				onClick={() =>manageProdCart("selectOption__"+props.productData.productid+"__"+feat.optionid+"__"+img.value , img.banned, img.value ) }
						                       				
						                       				id ={props.name}  
							                       			name={"selectOption__"+props.productData.productid+"__"+feat.optionid+"__"+img.value} 
							                       			key={"b"+props.productData.productid+"_"+feat.optionid }>
							                       			<img src={getImagePath(img.image)} key={props.productData.productid+"_"+feat.optionid }/>
							                       		</button>
							                       		{props.productConfig.optionsselected?
							                       			props.productConfig.optionsselected.map( 
																					(selec, i) => (selec.optionid == feat.optionid && selec.optionvalue == img.value?
																							<div className= "active-mini-div"></div> :""
																						
							                       			))
							                       		:""}
							                       		{img.banned? <div className= "ban-div-mini"> </div> :""}
						                       		</>
					                       		: 
					                       			<button 
					                       				className= {props.productConfig.optionsselected?
							                       			props.productConfig.optionsselected.map( 
																					(selec, i) => (selec.optionid == feat.optionid && selec.optionvalue == img.value?
																							 " active-option ":" "
							                       			))
							                       		:""} 
							                       		
							                       		
							                       		
					                       				key={props.productData.productid+"_"+feat.optionid }
					                       				onClick={!img.banned?props.productConfig.manageCart:console.log("")} src={img.image} 
					                       				id ={props.name} 
					                       			 	name={"selectOption__"+props.productData.productid+"__"+feat.optionid+"__"+img.value} 
																	 		 >
																	 		 	{img.banned? <div className= "ban-option-mini"> <span>	 {img.label} </span> </div> : <span> {img.label} </span> }	
																			</button>
																		}
					                        </div>
					                      </li>
					                  : <>
					                  {i == (img.image?6:5) ?
					                  	<li>
						                	  <button 
		                       				className= "plus-option"
				                       		key={props.productData.productid+"_"+feat.optionid }
		                       				id ={props.name} 
		                       			 	name={"selectOption__"+props.productData.productid+"__"+feat.optionid+"__"+img.value} 
														 		 > <span> <a target={target}
													 		 							key   ={"span"+props.productData.productid} 
													 		 							href={linkInfo} tabIndex="-1"> 
														 		 							{ "+"+ (feat.optionvalues.length-(img.image?4:3))  }
														 		 					</a> </span> </button>
													 		 </li>
					                  :""}
					                  </>
					                    )
					                  )}
					                  
					                  </>
					                </div>
					                </div>
					             	</div>
					            :""
											}
											</>
										)
			            )}	
		            </div>
							</div>
						:<> {windowWidth<766? <div  className="option" tabIndex="-1"></div> :""}</>:<> {windowWidth<766? <div  className="option" tabIndex="-1"></div>:""}</>
					}
					
					{skucomment!=""?
						<>
							<div  className="comment-btn-container" tabIndex="-1">
								<button id ={props.name} name={"showComment__"+elementId} onClick={toggleShowComment}	className="btn btn-xs btn-secondary">
									<span>	{getDefValues().showCommentLabel.toUpperCase()}</span>
								</button>
							</div>
							
						</>
					:""}
					
				 	<div className="row align-items-start product-info-first-div">
						<p> <a  target = {target}
										key   ={"img"+props.productData.productid} 
										name={"manageHref__"+props.productData.productid+"__"+props.productData.path}
										href={linkInfo} tabIndex="-1"> { props.productData.label}
								</a>
						</p>
						
						
					</div>
					<div className="row align-items-end">
						<div className="col-12"> 
							{validateNumber(props.productData.discount) ?
        				<>
        					<span className="product-price-new"> 
        						{props.productData.currency} { toFixed( props.productData.pricenew)  }
        					</span>
        					<span className="product-price-old"> 
        						{" "+ props.productData.currency+" "+toFixed(props.productData.priceold) }
        					</span>
      					</>
        			:
        				<span className="product-price"> 
        				 {props.productData.currency+ " "+ toFixed(props.productData.priceold ) }
        				</span>
	            }
						</div>
						 
					</div>
					
					<div className="row align-items-end">
						<div className="col-12 special-col ">
							 
							<div className="product-actions flex">
								<div className="product-extra-link2-mini product-extra-quantity">
								 
									<button 
										id ={props.name} 
										name={"decrease__"+props.productData.productid} 
										onClick={props.productConfig.manageCart}	className="product-qty-updwown"	>
										<i className="fas fa-minus"></i>
									</button>
								  
									<input id ={props.name}  type="text" className="product-qty" name={"quantity__"+props.productData.productid}
										 value={props.productConfig.quantitySelected}
										  onChange={props.productConfig.manageCart}
									/>
									
									<button 
										id ={props.name} 
										name={"increase__"+props.productData.productid} 
										onClick={props.productConfig.manageCart} className="product-qty-updwown">
										<i className="fas fa-plus"></i>
									</button>
								</div>
								<div className="product-extra-link2-mini">
								{action==""?
									<button id ={props.name} name={"add__"+props.productData.productid} onClick={props.productConfig.manageCart}	className="btn detail-add-button-mini">
										<span className="hide-mobile">	{getDefValues().btnaddbutton}</span>
										<span className="hide-web">	{getDefValues().shortbtnaddbutton}</span>
									</button>
								:
									<>
									{action=="addEventList"?
										<button id ={props.name} name={"addEventList__"+props.productData.productid+"__"+elementId} onClick={props.productConfig.manageCart}	className="btn detail-add-button-mini-secondary">
											<span>	{getDefValues().addEventListButton}</span>
										</button>
									:""}
									
									{action=="addEventCart"?
										<button id ={props.name} name={"add__"+props.productData.productid+"__"+elementId} onClick={props.productConfig.manageCart}	className="btn detail-add-button-mini-secondary">
											<span className="hide-mobile">	{getDefValues().btnaddbutton}</span>
											<span className="hide-web">	{getDefValues().shortbtnaddbutton}</span>
										</button>
									:""}
									
									</>
								}
									<button id ={props.name} name={"favorite__"+props.productData.productid}  onClick={props.productConfig.manageCart} aria-label="Add To Wishlist" 
									className={props.productConfig.favorite? "towishlist-active":"towishlist"}	>
									</button>
								</div>
								
							</div>
							
							
						</div>
					</div>
					
				</div>
			</div>
		   
	 	</>	
	  );
	}else{
		return "NO Product DATA"
	}
};
LoadProduct.propTypes = {};


export default LoadProduct;
				 