import React, { Component } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Polygon,
  InfoWindow
} from 'react-google-maps';

import { callApi, showError} from '../../common/functions';
import {toast,ToastContainer } from 'mdbreact';
import PageLoading from '../../components/PageLoading';
const GoogleMapsAPI =   process.env.REACT_APP_API_KEY_GMAP

 
 	var mapRef;
class Map extends Component {
	

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      coords: {},
      markerPosition: {},
      address: '',
      coordValidation:[],
      width:"100%"
    };
  }
  
	componentDidMount() {
		
		console.log("Props",this.props.coords)
		var coords = {lat:this.props.coords[0].lat,
									lng:this.props.coords[0].lng
								 }
		this.setState({ coordValidation:  this.props.coords, coords: coords })
	} 
  render() {
    const GoogleMapContainer = withGoogleMap(props => (
      <GoogleMap
      
      	 ref={(ref) => (mapRef = ref)}
      	
      	 defaultCenter={this.state.coords} 
      	 defaultZoom={11}
      	 language={"es-es"}
      >
        <Marker
          key={this.props.index}
         
					draggable={true}
        >
        
        {this.state.coordValidation.length>0?
					<Polygon 
	            path={this.state.coordValidation}
	            
	            options = {{
								  fillColor: "#ff3939",
								  fillOpacity: 0.4,
								  strokeColor: "#ff3939",
								  strokeOpacity: 0.8,
								  strokeWeight: 1,
								  zIndex: 1
								}}
	          />
			  :""}
					  
					   
        </Marker>
      </GoogleMap>
    ));

    return (
   	<>
   		<PageLoading isLoading={this.state.isLoading}/>
				<div>
        	<GoogleMapContainer
	          containerElement={	<div style={{ height: this.props.height, "marginTop":"3px" , "width": this.state.width }} />}
          	mapElement={<div style={{ height: this.props.height , "width": this.state.width }} />} 
        	/>
       	</div>
		</>
    );
  }
}

export default Map;
