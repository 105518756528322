import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SidebarNav from "../sidebar";
import Card from '../../components/Card';

class Dashboard extends Component{
	
	//define variables
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			isLoading: true,
			
			settings : {
      	dots: true,
      	infinite: true,
      	speed: 500,
      	slidesToShow: 1,
      	slidesToScroll: 1,
      	autoplay: true,
     // 	speed: 3000,
     // 	autoplaySpeed: 3000,
      	cssEase: "linear",
      	pauseOnHover: true
    	},
			
			cardConfig:{
				pageWidth: "col-md-12 col-lg-12 col-xl-12",
				groupingRows: 3
			},
			cardData	: {
					card1  : { header: "Sistemas de apoyo"  , 
										 cardWidth: "col-12 col-md-6 col-lg-6",
										 cardClass:"ml-1 mt-4",
										 list: 
											[ 	{type: "link", target:"_blank", url:"http://www.google.com", text:"CRM"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"Centro de requerimientos"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"Asistencia personal"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"Consulta de puntos"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"OYM"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"Pago al Instante"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"SICO"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"Reclamos"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"Espia"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"SEGIP"},
													{type: "link", target:"_blank", url:"http://www.google.com", text:"ASFI"},
											],
					},
					
			}
		};
	}
  render(){
      return(
        <>
          <SidebarNav pageId={this.props.match.params.pageId} />
          <div className="page-wrapper">
						<div className="content container-fluid">
							<Card 
								cardData={this.state.cardData} 
								cardConfig={this.state.cardConfig}
							/>
						</div>		
					</div>
				</>         
      )
   }
  }
  
 export default Dashboard; 