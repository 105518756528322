import React from "react";
import { MDBIcon } from "mdbreact";

const activetab= []


const activateTab = (event) => {
	activetab[event] = 1
	 
	return true;
}
const addRow = (props,  ini, numcol) => {
	let row=[]
	let keys= Object.keys(props.cardData)
	for (let key=ini;key< ini+ numcol ; key++){
		if(key < Object.keys(props.cardData).length ){
			var field = props.cardData[keys[key]];
			
	  	row.push(   
     				
     				<div className={(field.cardWidth?field.cardWidth:"col-12 col-md-6 col-lg-4")+ " d-flex" }>
              <div className={ (field.cardClass?field.cardClass:"")+ " card flex-fill mt-2"} >
              	{field.image?
              		<img alt={field.image.alt} src={field.image.source} className="card-img-top" />
               	:""}
              	{field.header?
                	<div className="card-header">
                 	 <h5 className="card-title mb-0">{field.header} </h5>
                	</div>
              	:""}
              	
              	{!field.body?"":field.body.length>0?
	                <div className="card-body">
	                  {field.body.map(item => (
	                  	<>
	                  		{item.type==="text"?    <p className="card-text"> {item.text}</p> :""}
		              		 	{item.type==="link"?    <a className="card-link" target={item.target?item.target:"_self" } href={item.url}>{item.text}</a> :""}
		              		 	{item.type==="button"?  <a className={"btn "+(item.buttonclass?item.buttonclass:"btn-primary")} target={item.target?item.target:"_self"} href={item.url}>{item.text}</a> :""}
		              		</>
		              		))
		                  } 
	                </div>
	              :<>{field.body}</>}
	              {field.list?
                	<ul className="list-group list-group-flush">
	                  {field.list.map(item => (
	                  	<li className="list-group-item">
	                  		{item.type==="text"?    <p className="card-text"> {item.text}</p> :""}
		              		 	{item.type==="link"?    <a className="card-link" target={item.target?item.target:"_self" } href={item.url}>{item.text}</a> :""}
		              		 	{item.type==="button"?  <a className={"btn "+(item.buttonclass?item.buttonclass:"btn-primary")} target={item.target?item.target:"_self"} href={item.url}>{item.text}</a> :""}
		              		</li>
		              		))
	                  } 
	              	</ul>
	              :""}
              </div>
            </div>
                  
			)
		}
  }
	return row;
}

const loadCard = props => {
	if (props.cardData){
		var groupingRows = props.cardConfig.groupingRows?props.cardConfig.groupingRows:2
		const cardStructure=[]
	
		for (let key=0; key< Object.keys(props.cardData).length ; key = key+ groupingRows){
			var row = addRow(props, key,groupingRows )
			cardStructure.push(  
												<div key={"row_"+key} className="row"> 
													{row}
												</div>	
												)
		}
	  return ( 
	 		<div className= {props.cardConfig.pageWidth?props.cardConfig.pageWidth:"col-md-12 col-lg-12 col-xl-12" } >
	 			 
				 
				
					<div className="row">
						<div className="col-md-12 col-lg-12">	
				   		<div>
				   			{cardStructure}  
			          
							</div>
						</div>
					</div> 	
				 
			</div> 			

	  );
	}else{
		return "NO CARD DATA"
	}
};
export default loadCard;
				 