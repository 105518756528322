/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from 'react';


/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Filters from '../../components/Filters'; 
import PageHeader from '../../components/PageHeader';

/*functions*/
import { searchHandler,  callApi, findValueById} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			//show : null,
			modal1: false,
			modal2: false,
			isLoading: true,
			date_label:date_label,
			modalClass: "modal-full-height modal-lg",
			modalIamgeClass:"modal-lg",
			/*table filter*/
			filterConfig: {
			
				FROMDATE       :{ label:"From"          ,  type:"DATE"    , value:fromDate} ,
				TODATE         :{ label:"To"            ,  type:"DATE"    , value:new Date()} ,
			
				STOREID        :{ label:"Store"         ,  type:"SELECT"  , values:[], value:""} ,
				
				//MIN_PRICE      :{ label:"Min Amount"    ,  type:"NUMBER"  , value:""} ,
				//MAX_PRICE      :{ label:"Max Amount"    ,  type:"NUMBER"  , value:""} ,				
				CUSTOMER_NAME  :{ label:"Customer Name" ,  type:"TEXT"    , value:"" } ,
				STATUS         :{ label:"Status"        ,  type:"SELECT"  , value:"" , values:[{text:"ALL", value:""}] },
				
			},
			showMore:false,
			/*table data*/
			tableData: {
				columns : [
				 
					{ dataIndex:"id", title:"Cart Id" },
					{ dataIndex:"orderid", title:"Order Id" },
					{ dataIndex:"STORENAME", title:"Store Name"},
					{ dataIndex:"status", title:"Status"},
					{ dataIndex:"bstatus", title:"Notif Status"},
					{ dataIndex:"subtotal", title:"Subtotal"},					
					{ dataIndex:"discount", title:"Discount"},
					{ dataIndex:"total", title:"Total"},
					{ dataIndex:"cartquantity", title:"Total Products"},				
					{ dataIndex:"firstname", title:"Firstname"},
					{ dataIndex:"lastname", title:"Lastname"},
					{ dataIndex:"email", title:"Email"},
					{ dataIndex:"phone", title:"Phone"},

					{ dataIndex:"createdate", title:"Date Added"},
					{ dataIndex:"updatedate", title:"Date Modified"},
					{ dataIndex:"notifdate", title:"Date Notified"},

			 
				],
		    rows: [], 
			},
			
			tableData2: {
				columns : [
					
					{ dataIndex:"id", title:"Cart Id" },
					{ dataIndex:"orderid", title:"Order Id" },
					{ dataIndex:"STORENAME", title:"Store Name"},
					{ dataIndex:"status", title:"Status"},
					{ dataIndex:"bstatus", title:"Notif Status"},
					{ dataIndex:"subtotal", title:"Subtotal"},					
					{ dataIndex:"discount", title:"Discount"},
					{ dataIndex:"total", title:"Total"},
					{ dataIndex:"cartquantity", title:"Total Products"},				 
					{ dataIndex:"firstname", title:"Firstname"},
					{ dataIndex:"lastname", title:"Lastname"},
					{ dataIndex:"email", title:"Email"},
					{ dataIndex:"phone", title:"Phone"},					

					{ dataIndex:"sku", title:"sku"},
					{ dataIndex:"productname", title:"Product"},
					{ dataIndex:"category", title:"Category"},
					{ dataIndex:"quantity", title:"Quantity"},
					{ dataIndex:"price", title:"Price"},
					{ dataIndex:"NEWPRICED", title:"Discount Price"},					
					{ dataIndex:"NEWPRICEC2", title:"Combo Price"}, 
					{ dataIndex:"NEWPRICEC1", title:"Combo min Qty"},		

					{ dataIndex:"createdate", title:"Date Added"},
					{ dataIndex:"updatedate", title:"Date Modified"},
					{ dataIndex:"notifdate", title:"Date Notified"},

				],
		    rows: [], 
			}, 
		}
	}
	/*loading data*/
	componentDidMount = async() => {
		
	 	
	 	try{
	 		let resp1 = await callApi ( "custom","getPaymentTypes" )
	 		let resp2 = await callApi ( "custom","getShippingTypes" )
	 		let resp3 = await callApi ( "custom","getDetailDomainsByCode", {domainid:"15"} )	
	 		
	 		
			console.log(resp3)
	 		
	 		const filterConfig = this.state.filterConfig;
	 		/*
			resp1.rows.map(function (item) {
				 filterConfig.PAYMENTTYPEID.values.push ({text:item.PAYMENTNAME, value: item.PAYMENTTYPEID} )
			}); 
			resp2.rows.map(function (item) {
				 filterConfig.SHIPPINGTYPEID.values.push ({text:item.SHIPPINGNAME, value: item.SHIPPINGTYPEID} )
			}); 
			*/
				 
			
			resp3.rows.map(function (item, index) {
				filterConfig.STOREID.values.push ({text:item.STRING_VALUE, value: item.DETAILDOMAINID} )
				if(index==0){
				   filterConfig.STOREID.value =item.DETAILDOMAINID
				}
			}); 
			
			 
			 
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	 
	 
  ///Search Manager
  searchHandler = async(event) => {
  	this.setState({isLoading:true});
		let resp = await searchHandler(event , this.state.filterConfig, this.state.showMore, "repCarts" );
		
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig, showMore: resp.showMore });
		}
		 
		if (resp.tableData){
			 
			let carts = resp.tableData.carts
			let products = resp.tableData.products
			
			 
			if (carts.length>0){

				for(let i in carts){
					let storeName = findValueById (this.state.filterConfig.STOREID.values , carts[i].storeid , "value", "text")
					carts[i]["STORENAME"] = storeName
				}

				for(let i in products){
					let storeName = findValueById (this.state.filterConfig.STOREID.values , products[i].storeid , "value", "text")
					products[i]["STORENAME"] = storeName
				}

				const tableData = this.state.tableData
				tableData.rows = carts
				
				const tableData2 = this.state.tableData2
				tableData2.rows = products
			}
		}
		
		this.setState({isLoading:false});
	} 
  
  
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
      
      
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Sales"} subtitle={"Export user Carts"}/>
         
	    		<div className="row">
	    			<div className="col-12 col-md-6 col-lg-3">
			    		<Filters filterConfig={this.state.filterConfig} showMore={this.state.showMore} searchHandler={this.searchHandler}/>
	    			</div>	
	    			<div className="col-12 col-md-12 col-lg-9">
							 
							 
							 
							<div className="col-12 col-lg-12 text-right mb-2" style={{padding:0}}>
								<ExcelFile filename={"export-userCarts."+this.state.date_label} element={         
						         	<button type="button" className="btn btn-success" name="set" > Export Data</button>
						      }>
				          <ExcelSheet data={this.state.tableData.rows} name="userCarts">
				             					
				              				<ExcelColumn label="userCart Id" value="id"/> 										 
											<ExcelColumn label="Order Id" value="orderid"/> 
											<ExcelColumn label="Store Name" value="STORENAME"/> 
											<ExcelColumn label="Status" value="status"/> 
											<ExcelColumn label="Notification Status" value="bstatus"/> 

											<ExcelColumn label="Subtotal" value="subtotal"/> 

											<ExcelColumn label="Discount" value="discount"/> 
											<ExcelColumn label="Total" value="total"/> 
											<ExcelColumn label="Total Products" value="cartquantity"/> 
											
											<ExcelColumn label="First Name" value="firstname"/> 
											<ExcelColumn label="Last Name" value="lastname"/> 
											<ExcelColumn label="Email" value="email"/> 
											<ExcelColumn label="Phone" value="phone"/> 
										
										
											<ExcelColumn label="Date Added" value="createdate"/> 
											<ExcelColumn label="Date Modified" value="updatedate"/> 
											<ExcelColumn label="Date Notified" value="notifdate"/> 
				          </ExcelSheet>
				          
				          
				          <ExcelSheet data={this.state.tableData2.rows} name="userCarts SKUs">
				            				<ExcelColumn label="userCart Id" value="id"/> 						 
											<ExcelColumn label="Order Id" value="orderid"/> 
											<ExcelColumn label="Store Name" value="STORENAME"/> 
											<ExcelColumn label="Status" value="status"/> 
											<ExcelColumn label="Notification Status" value="bstatus"/> 

											<ExcelColumn label="Subtotal" value="subtotal"/> 

											<ExcelColumn label="Discount" value="discount"/> 
											<ExcelColumn label="Total" value="total"/> 
											<ExcelColumn label="Total Products" value="cartquantity"/> 
											
											<ExcelColumn label="First Name" value="firstname"/> 
											<ExcelColumn label="Last Name" value="lastname"/> 
											<ExcelColumn label="Email" value="email"/> 
											<ExcelColumn label="Phone" value="phone"/> 
										

											<ExcelColumn label="SKU" value="sku"/> 
											<ExcelColumn label="productname" value="Product"/> 
											<ExcelColumn label="Category" value="category"/> 
											<ExcelColumn label="Quantity" value="quantity"/> 
											<ExcelColumn label="Price" value="price"/> 
											<ExcelColumn label="Discount Price" value="NEWPRICED"/> 
											<ExcelColumn label="Combo Price" value="NEWPRICEC2"/> 
											<ExcelColumn label="Combo min Qty" value="NEWPRICEC1"/>  

											<ExcelColumn label="Date Added" value="createdate"/> 
											<ExcelColumn label="Date Modified" value="updatedate"/> 
											<ExcelColumn label="Date Notified" value="notifdate"/> 
										 
										
				          </ExcelSheet>

						    </ExcelFile> 
							</div>	
					
						 
							<div className="col-12 col-lg-12" style={{padding:0}}>
								<div className="card card-table flex-fill">
									<h4 className="p-1"> User Carts </h4>
									<Table tableData={this.state.tableData} scroll={{ x: 1650, y: 300 }}   />
								</div>
							 
							 
									<div className="card card-table flex-fill">
										<h4  className="p-1">Products Carts </h4>
										<Table tableData={this.state.tableData2}  scroll={{ x: 1650, y: 300 }} />
									</div> 
							</div>
						</div>	
					</div>		
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 