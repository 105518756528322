import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";
const windowHeight = window.innerHeight
 
const loadCardChildComp = props => {
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" style={{ "maxHeight": (windowHeight-240)+"px" , "overflow":"auto"  } }>
					{props.data.length>0?
		  			<div className="row">
						{/*	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2 ml-1"}><span className="h6-responsive" >Header</span></div>
							 */}
							<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}><span className="h6-responsive" >Mobile / Web</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}><span className="h6-responsive" >Children</span></div>
						 	<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}><span className="h6-responsive" >Box Position</span></div>
							<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}><span className="h6-responsive" >Width</span></div>
							<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-2"}><span className="h6-responsive" >Label</span></div>
							<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}><span className="h6-responsive" >Label Button</span></div>
							<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}><span className="h6-responsive" >Margin Top</span></div>
							<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}><span className="h6-responsive" >Color</span></div>
						 	
							<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1"}> </div>
						</div>	
					:""}
				  {props.data.map(itemM => (
				  	<>
							<div className="row mb-1">
							
							
								<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}>
						 				<select  name={"FIELDVALUE_"+props.container	+"_version_"+itemM.rowId} defaultValue={itemM.version?itemM.version:"web"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
		        			 		 <option value="web">web</option>
		        			 		 <option value="mobile">mobile</option>
		        			 		 <option value="both">both</option>
										</select>		
										 			 	
						  	</div>		
						  	{!props.hideManager?
							  	<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}>
								  	<div style={{display:"flex"}}>
							 				<select  name={"FIELDVALUE_"+props.container	+"_children_"+itemM.rowId} defaultValue={itemM.children} onChange={props.manageComponent} className={"form-control form-control-sm" }>
					        			<option key={props.container+"attr_i"+0} value={""}>CHOOSE ONE OPTION</option>
					        			{props.components.map(item => (
					        				<>{item.ISCHILDREN===1? props.filter?
					        						<>
					        							{	props.filter == item.COMPONENTCODE?
							        		 					<option key={props.container+"attr_i"+item.COMPONENTID} value={item.COMPONENTNAME}>{item.COMPONENTTYPE +" "+ item.COMPONENTNAME}</option>
						        		 				:""
						        		 				}
					        		 				</>
					        		 				:
					        		 				<option key={props.container+"attr_i"+item.COMPONENTID} value={item.COMPONENTNAME}>{item.COMPONENTTYPE +" - "+ item.COMPONENTNAME}</option>
					        		 			:""}
					        		 		</>
					        		 	))
					            	} 
											</select>
												<button  
													 className={"btn btn-"+(  itemM.children==""? "success":"info" ) +" btn-sm"  }
													 name={"ADDNEWCOMP_"+props.container+"_"+itemM.rowId}
									         onClick={props.manageComponent} type="button"> 
									         { itemM.children==""?
									         	<i className="fas fa-plus"  ></i>
									         	: 
									         	<i className="fas fa-edit"  ></i>
									         }
									      </button>
							      </div>	
							  	</div>			
						    :
						 	   <div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}>
								  	<div style={{display:"flex"}}>
							 				<select  name={"FIELDVALUE_"+props.container	+"_children_"+itemM.rowId} defaultValue={itemM.children} onChange={props.manageComponent} className={"form-control form-control-sm" }>
					        			<option key={props.container+"attr_i"+0} value={""}>CHOOSE ONE OPTION</option>
					        			{props.components.map(item => (
					        				<option key={props.container+"attr_i"+item.COMPONENTID} value={item.COMPONENTNAME}>{item.COMPONENTTYPE +" - "+ item.COMPONENTNAME}</option>
					        		 	))
					            	} 
											</select> 
							      </div>	
							  	</div>	
						    }
						   	<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}>
						 				<select  name={"FIELDVALUE_"+props.container	+"_boxposition_"+itemM.rowId} defaultValue={itemM.boxposition?itemM.boxposition:"col-12"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
		        			 	 		<option value="bottom">Bottom</option>
		        			 		 	<option value="top">Top</option>
		        			 	 
										</select>							  	 	 	
							  </div>		 	
								<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}>						  	  	 
						 				<select  name={"FIELDVALUE_"+props.container	+"_boxwidth_"+itemM.rowId} defaultValue={itemM.boxwidth?itemM.boxwidth:"col-sm-12"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
		        			 		<option value="full">Complete</option>
		        			 		<option value="1_3">1/3</option>
		        			 		<option value="2_2">1/2</option>
		        			 		<option value="2_3">2/3</option>
		        			 		
										</select>							   	
							  </div>		
							  <div className={"col-md-4 col-sm-4 col-lg-2 col-xl-2"}>		  		 
					 				 <input key={props.container+"_label_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_name?"is-invalid":"")}
										value={itemM.label}
										name={"FIELDVALUE_"+props.container+"_label_"+itemM.rowId}
										onChange={props.manageComponent} />			   
							 	</div>	 	
							  <div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}>
						 			<input key={props.container+"_linkbutton_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_name?"is-invalid":"")}
										value={itemM.linkbutton}
										name={"FIELDVALUE_"+props.container+"_linkbutton_"+itemM.rowId}
										onChange={props.manageComponent} />			   	 	
						  	</div>
						  	
						  	<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}>
						 			<input key={props.container+"_margintop_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_name?"is-invalid":"")}
										value={itemM.margintop}
										name={"FIELDVALUE_"+props.container+"_margintop_"+itemM.rowId}
										onChange={props.manageComponent} />			   	 	
						  	</div>
						  	
						  	<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}>
						 				<select  name={"FIELDVALUE_"+props.container	+"_color_"+itemM.rowId} defaultValue={itemM.color?itemM.color:"web"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
		        			 		 <option value="red">red</option>
		        			 		 <option value="blue">blue</option>
		        			 		 <option value="yellow">yellow</option>
		        			 		 <option value="gray">gray</option>
		        			 		 <option value="green">green</option>
										</select>							 	
						  	</div>	 
						  	<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompCardChild_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-5"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-6"}></div>
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompCardChild"}
					         onClick={props.manageComponent} type="button"> <i name={"ADDROW_"+props.container+"_CompCardChild"} className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO CardChildComp DATA"
	}
};
export default loadCardChildComp;
				 