/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
/*functions*/
import {manageImage, listImages, validateRole,changeFilter,findObjName, filterHandler ,getLanguages,encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 
const foldername= process.env.REACT_APP_FOLDER_LAYOUT

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			date_label:date_label,
			modal1: false,
			modal2: false,
			isLoading: true,
			modalClass: "modal-full-height modal-lg",
			modalIamgeClass:"modal-lg",
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"COUPONID", title:"Coupon ID",
						sorter: (a, b) => a.COUPONID - b.COUPONID },
					{ dataIndex:"COUPONNAME", title:"Coupon",
						sorter: (a, b) => a.COUPONNAME.length - b.COUPONNAME.length },
					{ dataIndex:"STATUS", title:"Status",
						sorter: (a, b) => a.STATUS.length - b.STATUS.length },
					{ dataIndex:"CUSTOMERCODE", title:"Customer Code",
						sorter: (a, b) => a.CUSTOMERCODE.length - b.CUSTOMERCODE.length },
					{ dataIndex:"ADMINCODE", title:"Admin Code",
						sorter: (a, b) => a.ADMINCODE.length - b.ADMINCODE.length },	
					{ dataIndex:"TYPE", title:"Type",
						sorter: (a, b) => a.TYPE.length - b.TYPE.length },		
					
					
					{ dataIndex:"STARTDATE", title:"Start Date",
						sorter: (a, b) => a.STARTDATE.length - b.STARTDATE.length },
					{ dataIndex:"ENDDATE", title:"End Date",
						sorter: (a, b) => a.ENDDATE.length - b.ENDDATE.length },
						
						
					{ dataIndex: "COUPONID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-info" id={record.COUPONID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-danger" id={record.COUPONID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
			originalTableData:{},
			filterConfig: {
				COUPONID     :{ label:"Coupon ID" ,  type:"NUMBER"  , value:""},
				COUPONNAME   :{ label:"Coupon" ,  type:"TEXT"   , value:""} ,
				STATUS       :{ label:"Status"        ,  type:"SELECT" , value:"" , values:[] },
				TYPE         :{ label:"Type"        ,  type:"SELECT" , value:"" , values:[] },
				ADMINCODE    :{ label:"Internal Code" ,  type:"TEXT"  , value:""},
				CUSTOMERCODE :{ label:"Customer Code" ,  type:"TEXT"  , value:""},
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Coupon",
			/*form data*/			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-6 col-xl-6",
				groupingRows: 2,
				
			},
			formData	: {
				     
			},
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			/*images or files*/
			initialFolder:"",
			imageManager:null,
			objImage:{
				items:[],
				selected:[]
			}
			 
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		
	 		await this.setCouponObj()
	 		await preloadForm ( this.state.formData )
	 		
	 		const formData = this.state.formData
	 		
	 		formData.EXC_POLYGONS.originalValues = formData.EXC_POLYGONS.values
	 		formData.EXC_CATEGORYIDS.originalValues = formData.EXC_CATEGORYIDS.values
	 		formData.INC_POLYGONS.originalValues = formData.INC_POLYGONS.values
	 		formData.INC_CATEGORYIDS.originalValues = formData.INC_CATEGORYIDS.values
	 		
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	
	changeFilter = async(event) => {

		let _data = findObjName(event)
		let data =  _data.split("__")
		var originalValues=[]
		if (data.length==1){
			originalValues = this.state.formData[data].originalValues
		}
    
		 
  	var resp = await changeFilter(event, originalValues , this.state.formData)	
    this.setState({formData:resp});
 
	} 
	
	setCouponObj = async(event) => {
 		let languages= await getLanguages()
 		let languages2= await getLanguages()
 		var rows = []
	 	var parents=[]
  
		let resp = await callApi ( "manager","getCoupons" )
 		const tableData = this.state.tableData
 		tableData.rows = resp.rows 
 		this.setState({originalTableData:this.state.tableData});
 		const filterConfig = this.state.filterConfig
 		
 		filterConfig.STATUS.values.push ("")
		tableData.rows.map(function (item) {
			if (filterConfig.STATUS.values.indexOf(item.STATUS)< 0){
				filterConfig.STATUS.values.push (item.STATUS)	
			}
		}); 
		
		filterConfig.TYPE.values.push ("")
		tableData.rows.map(function (item) {
			if (filterConfig.TYPE.values.indexOf(item.TYPE)< 0){
				filterConfig.TYPE.values.push (item.TYPE)	
			}
		}); 
			
 		let formData	= {
			
			COUPONNAME                :{ label: "Coupon Name"   ,     validationType:"text",     type: "TEXT",   value: ""  },
			COUPONLABELS              :{ label: "Coupon Label"  ,   size:"col-6"  , validationType:"text"  , type: "CompLanguage",   values: languages },
			COUPONMESSAGES            :{ label: "Coupon Message",   size:"col-6" , validationType:"text"     , type: "CompLanguage",   values: languages2  },
			CUSTOMERCODE              :{ label: "Customer Code" ,   size:"col-6"  ,notRequired:true  , type: "TEXT",   value: ""  },
			ADMINCODE                 :{ label: "Admin Code"    ,   size:"col-6"  ,notRequired:true  , type: "TEXT",   value: ""  },
			STATUSID                  :{ label: "Status"        ,   size:"col-6"  , type: "SELECT", value: "", values: [], getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"5"}] } } ,
			TYPE                      :{ label: "Discount Type" ,   size:"col-6"       , type: "SELECT",   value: "" , values:[ {text: "STATIC", value: "STATIC"}, {text: "AMOUNT", value: "AMOUNT"}, {text: "PERCENTAGE", value: "PERCENTAGE"}] },
			
			DISCOUNTAPPLYTO           :{ label: "Apply to"               , type: "SELECT",   value: "" , values:[ {text: "SUBTOTAL", value: "SUBTOTAL"}, {text: "SHIPPING", value: "SHIPPING"}] },
			
			DISCOUNTAMOUNT            :{ label: "Amount/Percentage(0%-100%)" ,   size:"col-6" , validationType:"number" , type: "TEXT",   value: "" } ,
			ISPOSITIVEBALANCETOSHIPP  :{ label: "Positive Balance to Shipping" ,   size:"col-6"      , type: "SELECT",   value: "" , values:[ {text: "YES", value: "YES"}, {text: "NO", value: "NO"}] }, 
			
			MINAMOUNT                 :{ label: "Min Sale Amount"       ,   size:"col-3" , validationType:"number" , type: "TEXT",   value: "1", defvalue: "1" } ,
			MAXAMOUNT                 :{ label: "Max Sale Amount"       ,   size:"col-3" , validationType:"number" , type: "TEXT",   value: "99999", defvalue: "99999" } ,
			MINQUANTITY               :{ label: "Min Products Quantity" ,   size:"col-3" , validationType:"number" , type: "TEXT",   value: "1", defvalue: "1" } ,
			MAXQUANTITY               :{ label: "Max Products Quantity" ,   size:"col-3" , validationType:"number" , type: "TEXT",   value: "99999", defvalue: "99999" } ,
			
			STARTDATE                 :{ label: "Start Date"      ,   size:"col-6"        , type: "DATEPICKER",   value: new Date()} ,
			ENDDATE                   :{ label: "End Date"        ,   size:"col-6"        , type: "DATEPICKER",   value: new Date()} ,
      
      
			
			TOTALCOUPONSUPPORTED      :{ label: "Total Coupon Supported"  ,   size:"col-4", validationType:"number" , type: "TEXT",   value: "99999", defvalue: "99999" } ,
			TOTALCOUPONUSED           :{ label: "Total Coupon Used"       ,   disabled:true, size:"col-4", validationType:"number" , type: "TEXT",   value: "0", defvalue: "0"  } ,
			TOTALCUSTOMERSUPPORTED    :{ label: "Total Customer Supported",   size:"col-4" , validationType:"number" , type: "TEXT",   value: "1", defvalue: "1"  } ,
			
			ISAUTOCALC                :{ label: "Automatic calculate" ,   size:"col-4", type: "SELECT",   value: "" , values:[ {text: "YES", value: "YES"}, {text: "NO", value: "NO"}] }, 
			ISLOGINREQUIRED           :{ label: "Required Login"      ,   size:"col-4", type: "SELECT",   value: "" , values:[ {text: "YES", value: "YES"}, {text: "NO", value: "NO"}] }, 
			ISAPPEND                  :{ label: "Allow more Coupons"  ,   size:"col-4", type: "SELECT",   value: "" , values:[ {text: "YES", value: "YES"}, {text: "NO", value: "NO"}] },
			
			CUSTOMERGROUPID           :{ label: "Customer Groups"     ,   size:"col-6" , notRequired:true  , type: "SELECT", "addDefault":"ALL CUSTOMER GROUPS", value: [], values: [] , getDataDB: {apiMethod: "getCustomerGroups", apiGroup:"manager"} } ,
			EMPTY1                    :{ label: ""                    ,   size:"col-6" , notRequired:true  , type: "EMPTY"} ,
			
			PRICES                    :{ label: "Prices Supported (Apply to Percentage"    ,   size:"col-6"  ,  notRequired:true  ,validationType:"array"  , type: "CHECKBOX", value: ["REGULAR"], values: [  {text: "REGULAR", value: "REGULAR"}, {text: "DISCOUNT", value: "DISCOUNT"} , {text: "COMBO", value: "COMBO"} , {text: "BEFORE-NOW", value: "BEFORE-NOW"}, , {text: "PACK", value: "PACK"} ]  } ,
			
			PAYMENTTYPEIDS            :{ label: "Payment Types"        ,   size:"col-6"  , notRequired:true  , showAll:true, type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getPaymentTypes", apiGroup:"custom"} } ,
			SHIPPINGTYPEIDS           :{ label: "Shipping Types"       ,   size:"col-6"  , notRequired:true  , showAll:true,type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getShippingTypes", apiGroup:"custom"} } ,
			
			
			STOREIDS                  :{ label: "Store"               ,   size:"col-6"  , notRequired:true   , showAll:true,type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"15"}] } } ,
			
			INC_POLYGONS              :{ label: "Included Polygons" ,   filterValue:"", size:"col-6"  ,  notRequired:true  ,validationType:"array"  , showAll:true, type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getPolygons", apiGroup:"manager"} } ,
			INC_CATEGORYIDS           :{ label: "Included Categories" , filterValue:"",   size:"col-6"  ,  notRequired:true  ,validationType:"array"  , showAll:true, type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getCategories", apiGroup:"manager"} } ,
	//		INC_PRODUCTIDS            :{   },
			EXC_POLYGONS              :{ label: "Excluded Polygons" ,  filterValue:"",   size:"col-6"  ,  notRequired:true  ,validationType:"array"  , showAll:true,type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getPolygons", apiGroup:"manager"} } ,
			EXC_CATEGORYIDS           :{ label: "Excluded Categories" ,filterValue:"",    size:"col-6"  ,  notRequired:true  ,validationType:"array"  , showAll:true, type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getCategories", apiGroup:"manager"} } ,
		//	EXC_PRODUCTIDS            :{   },
		
		//	ISMULTIPLE                :{ label: "Allow more Coupons"               , type: "SELECT",   value: "" , values:[ {text: "YES", value: "YES"}, {text: "NO", value: "NO"}] },
			INC_PRODUCTIDS             :{ label: "Included SKUs"   ,     validationType:"text",  notRequired:true  ,   type: "TEXT",   value: ""  },
			EXC_PRODUCTIDS             :{ label: "Excluded SKUs"   ,     validationType:"text",  notRequired:true  ,   type: "TEXT",   value: ""  },
		};
		this.setState({  ...this.state, formData:formData  })	
		
	} 
	
	/*Form Functions*/
	///filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	/*charge data from a selected user*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Coupon"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
			  this.state.formData.COUPONLABELS.value = undefined
				this.state.formData.COUPONMESSAGES.value = undefined
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Coupon"
				itemId = event.target.id
	  		let resp = await callApi ( "manager","getCouponById",{couponid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData,resp.COUPON)
	  		formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		
	  		var descriptions=[], labels=[]
	  		resp.COUPON.CUSTOMERMESSAGE.map(function (item) {
	  			descriptions.push( {  id: item.LANGUAGEID, name: item.LANGUAGE,  value: item.DESCRIPTION })
	  		}); 
	  
	  		resp.COUPON.CUSTOMERMESSAGE.map(function (item) {
	  			labels.push( {  id: item.LANGUAGEID, name: item.LANGUAGE,  value: item.ATRIB_NAME })
	  		}); 
	  		console.log(this.state.formData, labels)
	  		console.log("TOTALCOUPONUSED",resp.COUPON.TOTALCOUPONUSED)
	  		
	  		this.state.formData.COUPONLABELS.value = undefined
	  		this.state.formData.COUPONLABELS.values = labels
	  		this.state.formData.COUPONMESSAGES.value = undefined
	  		this.state.formData.COUPONMESSAGES.values = descriptions
	  		console.log("this.state.formData",this.state.formData)
	  		
	  		if ( resp.COUPON.ISDISCOUNTSHIPPING =="YES"){
	  			this.state.formData.DISCOUNTAPPLYTO.value = "SHIPPING"
	  		}else if ( resp.COUPON.ISDISCOUNTSUBTOTAL =="YES"){
	  			this.state.formData.DISCOUNTAPPLYTO.value = "SUBTOTAL"
	  		}
	  		
	  		
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Coupon"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  ///get value fields
  changeHandler = async(event) => {
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
	} 
  //show/hide modal
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	//manage image
	manageImage= async(event) => {
		try{
			this.setState({isLoading:true});
			var data = (event.target.name).split("_")
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages("",objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal2: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
						let field = objImage.objectName
						var formData = this.state.formData
						formData[field].value = objImage.selected[0]
						this.setState({modal2:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	} 

	/*ABM manager*/
	execute=async (event,actionType) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			if (actionType==="DELETE"){
  				resp= {isValid:true}
  			}else{
  				resp = await validateForm(this.state.formData, toast);
  			}
  			if(resp.isValid){
			  	this.setState({  ...this.state, isLoading:true })
			  	var _formData =  this.state.formData;
			  	_formData = parseFormData(_formData)
					_formData["couponid"] = this.state.currentId;
				   
					let resp = await callApi ( "manager",this.state.formAction+"Coupons", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getCoupons" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({originalTableData:this.state.tableData});
				 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
					 
		  	}
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
      
      <Modal open={this.state.modal2} 
      
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1060"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (2)} 
      	class={this.state.modalIamgeClass}
      />
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													:
													 <Form 
															formData={this.state.formData} 
															manageImage={this.manageImage}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
															changeFilter={this.changeFilter}
													 />
												 }
							  />

      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"System"} subtitle={"Coupons"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
							<ExcelFile filename={"export-coupons."+this.state.date_label} element={         
					          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
					      }>
			          <ExcelSheet data={this.state.tableData.rows} name="SKUS">
			            	<ExcelColumn label="Coupon ID" value="COUPONID"/> 
			              <ExcelColumn label="Coupon" value="COUPONNAME"/> 
			             
			              <ExcelColumn label="Status" value="STATUS"/>
			              
			              <ExcelColumn label="Customer Code" value="CUSTOMERCODE"/>
			              <ExcelColumn label="Admin Code" value="ADMINCODE"/>
			              <ExcelColumn label="Type" value="TYPE"/>
			              <ExcelColumn label="Start Date" value="STARTDATE"/>
			              <ExcelColumn label="End Date" value="ENDDATE"/>
			              
			          </ExcelSheet>
	 
					    </ExcelFile>
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 