/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';
/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
import ReactExport from "react-export-excel-xlsx-fix";

/*functions*/
import {getUserInfo,listImages,find, filterHandler,getLanguages,getLanguage, findObjName,manageImage, validateComponent, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const foldername= process.env.REACT_APP_FOLDER_LAYOUT

var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

class page extends Component{	
	constructor(props) {
		super(props);
		this.state = {
			date_label: date_label,
			modal1: false,
			modal2: false,
			modalMdClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			modalIamgeClass:"modal-lg",
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.setJob,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			filterConfig: {
				MENUID :{ label:"Menu Id" ,  type:"NUMBER"   , value:""} ,
				MENULEVEL :{ label:"Menu Level" ,  type:"NUMBER"   , value:""} ,
				MENUDESC :{ label:"Route" ,  type:"TEXT"   , value:""} ,
				
			},

			formConfig2:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1
			},
			formData2	: { JOBDATE   :{ label: "Job Date"      ,   size:"col-12"        , type: "DATEPICKER",   value: new Date()} ,
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"MENUID", title:"Menu ID",
						sorter: (a, b) => a.MENUID - b.MENUID },
					{ dataIndex:"MENULABEL", title:"Menu Label",
						sorter: (a, b) => a.MENULABEL.length - b.MENULABEL.length },
					{	dataIndex:"MENUDESC", title:"Route",
						sorter: (a, b) => a.MENUDESC.length - b.MENUDESC.length },
					{	dataIndex:"MENULEVEL", title:"Menu Level",
						sorter: (a, b) => a.MENULEVEL.length - b.MENULEVEL.length },
					{	dataIndex:"SORTORDER", title:"Sort Order",
						sorter: (a, b) => a.SORTORDER.length - b.SORTORDER.length },
						
					{ dataIndex: "MENUID", title: "Action",
		        render: (text, record) => (<div className="text-right">
		         <button className="btn btn-sm btn-info" id={record.MENUID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-danger" id={record.MENUID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></div>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Menu",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			logs:[],
			formData	: {
				
				MENUNAMES             : { label: "Menu Label"         ,validationType:"text"  , type: "CompLanguage",   values: []  },
				PARENTMENUID          : { label: "Parent Menu"        ,validationType:"text"  , type: "SELECT", value: "",  values: [] } ,
				MENUURL               : { label: "Path Url"           ,validationType:"text"  , type: "TEXT",   value: ""  },
				SORTORDER             : { label: "Sort Order"         ,validationType:"number", type: "TEXT",   value: "0"} ,
				CONFIGMENU2           : { label: "Special Menu"       ,size: "col-sm-12 col-md-6 col-lg-6 col-xl-6", notRequired:true   , type: "SELECT",   value: "" , values:[{text: "NO", value: ""},{text: "YES", value: "YES"}] },
				CONFIGMAXROWSCOL      : { label: "Max Rows per Column(Special Menu)",size: "col-sm-12 col-md-6 col-lg-6 col-xl-6", notRequired:true   , validationType:"number", type: "TEXT",   value: "10", defaultvalue:"10" } ,
				CONFIGBACKGROUND      : { label: "Background Color" , 	notRequired:true   , type: "COLOR" ,value:"", defaultvalue:"#FFF", size:"col-12" , sizesm:"col-sm-12", sizelg:"col-md-6", sizelg:"col-lg-6" },
				CONFIGCOLOR           : { label: "Text Color"       , 	notRequired:true   , type: "COLOR" ,value:"", defaultvalue:"#696158", size:"col-12" , sizesm:"col-sm-12", sizelg:"col-md-6", sizelg:"col-lg-6" },
				CONFIGIMAGE           : { label: "Image"            ,notRequired:true   , type: "IMAGE",   value: "", width:"120px",  height:"120px"} ,

			}, 
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			completeMenu:[]
		}
	}
	/*loading data*/
	
	//manage image
	manageImage = async(event) => {
		try{
			this.setState({isLoading:true});
			 
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
			
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages("",objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal2: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
						let field = objImage.objectName
						var formData = this.state.formData
						formData[field].value = objImage.selected[0]
						this.setState({modal2:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	

	setJob = async(event) => {
		let _formData =  this.state.formData2;
		_formData = parseFormData(_formData)
		_formData["components"] = ""		 
		_formData["filename"] = "JOB__HEADERMENU"		 
		var resp = await callApi ( "s3", "programFile", _formData )


		if (resp.success){
			showMessage(toast,resp.message);
			this.setState({...this.state,modal3:false });
		}else{
			showError(toast,resp.message);
		}

	} 

	showJobs=async (event) => {
		this.setState({  ...this.state, isLoading:true})
		
		try{	 
			
			var logs=[]
			let resp2 = await callApi ( "layaoutManager","getLogComponentById",{layoutFile:"JOB__HEADERMENU"} )
				for (let l in resp2.logs){
					logs.push(resp2.logs[l])
				}

			this.setState({...this.state,isLoading:false, logs:logs });
		 
			
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false, modal3:true });
		}
	}

	setMenuObj = async(event) => {
 		let languages= await getLanguages()
 		var rows = []
	 	var parents=[]
	 		
	 	var completeMenu=[]
 		for (let l in languages ){
 			
 			let resp = await callApi ( "manager","getMenus", {languageid: languages[l].id , menutype:"CUSTOMERHEADER" } )
 			let menus=[]
 			for (let k in resp.rows ){
 				let obj =resp.rows[k]
 				obj["OPEN"] ="false" 
	 			menus.push(obj)
	 		}
 			completeMenu.push({language: languages[l].name,
 												 languageId: languages[l].id, 
 												menu: menus} )
 				
 			if (getUserInfo().languageid == languages[l].id ){
 				for (let k in resp.rows ){
		 			parents.push ({ value:resp.rows[k].MENUID , text: resp.rows[k].MENUDESC })	
		 			if (resp.rows[k].MENULEVEL>0 ){
		 				let obj =resp.rows[k] 
		 				obj["OPEN"] ="false" 
		 				rows.push(obj) 
		 				
		 			}
		 		}
 			}
 		}
	 		const tableData = this.state.tableData
	 		tableData.rows = rows 
	 		this.setState({originalTableData:this.state.tableData});
	 		let formData	= {
				
				MENUNAMES		    : { label: "Menu Label"         ,validationType:"text"  , type: "CompLanguage",   values: languages  },
				PARENTMENUID    : { label: "Parent Menu"        ,validationType:"text"  , type: "SELECT", value: "",  values: parents } ,
				MENUURL         : { label: "Path Url"           ,validationType:"text"  , type: "TEXT",   value: ""  },
				SORTORDER       : { label: "Sort Order"         ,validationType:"number", type: "TEXT",   value: "0"} ,
				CONFIGMENU2           : { label: "Special Menu"       ,size: "col-sm-12 col-md-6 col-lg-6 col-xl-6", notRequired:true   , type: "SELECT",   value: "" , values:[{text: "NO", value: ""},{text: "YES", value: "YES"}] },
				CONFIGMAXROWSCOL      : { label: "Max Rows per Column(Special Menu)",size: "col-sm-12 col-md-6 col-lg-6 col-xl-6", notRequired:true   , validationType:"number", type: "TEXT",   value: "10", defaultvalue:"10" } ,
				CONFIGBACKGROUND      : { label: "Background Color" , 	notRequired:true   , type: "COLOR" ,value:"", defaultvalue:"#FFF", size:"col-12" , sizesm:"col-sm-12", sizelg:"col-md-6", sizelg:"col-lg-6" },
				CONFIGCOLOR           : { label: "Text Color"       , 	notRequired:true   , type: "COLOR" ,value:"", defaultvalue:"#696158", size:"col-12" , sizesm:"col-sm-12", sizelg:"col-md-6", sizelg:"col-lg-6" },
				CONFIGIMAGE           : { label: "Image"              ,notRequired:true   , type: "IMAGE",   value: "", width:"120px",  height:"120px" } ,
			};
			
			this.setState({  ...this.state, formData:formData, completeMenu:completeMenu })	
	} 
	
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		
	 		await this.setMenuObj()	 									
	 		await preloadForm ( this.state.formData )
	 		
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	//filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	}  

	changeHandler2 = async(event) => {
		let resp = await validateForm(this.state.formData2, toast,event);
		this.setState({formData2:resp.formData});
  } 
  
	deleteJob=async (componentid, pos) => {
		this.setState({isLoading2:true});
		let resp = await callApi ( "layaoutManager", "deleteComponents", {componentid:componentid } )
		if (resp.success){
			showMessage(toast,resp.message);		
			const logs= this.state.logs
			let pos = find(logs,componentid, "COMPONENTID")
			console.log(logs, pos)
			logs.splice(pos, 1)
			this.setState({isLoading2:false});
		}else{
			showError(toast,resp.message);
			this.setState({isLoading2:false});
		}
	}  

  /*get value fields*/
  changeHandler = async(event, eventColor) => {
  
  	if (eventColor || !event.target.name){
  		var objName=  findObjName (eventColor)
  		event["target"] = {name:objName } 
  	}
  
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
		 
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
	publishMenu=async (event) => {
		this.setState({  ...this.state, isLoading:true})
		try{	 
			/*
	  	let data= {"layoutname"  : "menu",
					       "replace"     : true,
					       foldername    : foldername+"private/CUSTOMERHEADER/",
						     configuration : this.state.completeMenu }
			let resp = await callApi ( "s3", "createUploadFile", data )
			*/
			let data= { languageid: getLanguage(),
								  menutype:"CUSTOMERHEADER"} 
			let resp = await callApi ( "manager", "updateCategoriesByStock", data )
			 
			this.setState({...this.state,isLoading:false });
			if (resp.success){
		  	showMessage(toast,"UPDATED SUCCESSFULLY");
		  }else{
		  	showError(toast,resp.result);
		  }
			 
			
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
 
	 
	/*charge data from a selected Menu*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Menu"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				this.state.formData.MENUNAMES.value = undefined
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Menu"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		
	  		let resp = await callApi ( "manager","getMenuById",{menuid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData,resp.MENU)
	  		
	  		var validConfig= true
	  		try{
	  			let ADITIONALCONFIG =  JSON.parse(resp.MENU.ADITIONALCONFIG);
	  			if (!ADITIONALCONFIG){
	  					validConfig=false;
	  			}
	  		}catch(ex){
	  			console.log("no")
	  			validConfig=false;
	  		}
	  		
	  		
	  		
	  		
	  		if (validConfig){ 
	  			let ADITIONALCONFIG =  JSON.parse(resp.MENU.ADITIONALCONFIG);
	  			
	  			for (let i in Object.keys(ADITIONALCONFIG)){
	  				let item =Object.keys(ADITIONALCONFIG)[i]
		  			this.state.formData[item.toUpperCase() ].value = ADITIONALCONFIG[item]
		  		}
		  		
	  		}else{
	  			
	  			for (let i in Object.keys(this.state.formData)){
	  				let item =Object.keys(this.state.formData)[i]
	  				if (item.startsWith("CONFIG")){
	  					this.state.formData[item.toUpperCase() ].value = ""	
	  				}
		  			
		  		}
	  			
	  		}
	  		
	  		var optionNames=[], options=[]
	  		resp.MENU.MENUNAMES.map(function (item) {
	  			optionNames.push( {  id: item.LANGUAGEID, name: item.LANGUAGE,  value: item.NAME })
	  		}); 
	  		 
	  		this.state.formData.MENUNAMES.value = undefined
	  		this.state.formData.MENUNAMES.values = optionNames
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Menu"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
					 
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
					_formData["menuid"] = this.state.currentId;					
					_formData["menutype"] = "CUSTOMERHEADER"					
					var aditionalconfig={}					
					for (let i in Object.keys(_formData)){
		  		var field = Object.keys(_formData)[i] 
		  			if (field.startsWith("config")){
		  				aditionalconfig[field] = _formData[field]
		  			}
		  		} 
					_formData["aditionalconfig"] = aditionalconfig;
					let resp = await callApi ( "manager",this.state.formAction+"Menus", _formData)
					
			  	if (resp.success){
			  		showMessage(toast,resp.message);
						await this.setMenuObj()
						this.filterHandler({target:{name:"search"}})
						this.setState({  ...this.state, modal1:false})								
				 		await preloadForm ( this.state.formData )
	 		 
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	
	
  
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
       <Modal open={this.state.modal2}
	      	icon="fas fa-images"
	      	title="Image Manager"
	      	zindex="1062"
	      	message={this.state.imageManager}
	      	toggle={this.toggleModal (2)} 
	      	class={this.state.modalIamgeClass}
	      />
      
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
													:
													 <Form 
															formData={this.state.formData} 
															manageImage={this.manageImage}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />

	<Modal open={this.state.modal3} 
								toggle={this.toggleModal (3)} 
								class={""}
								
								title={"Program Header"}
								buttons={this.state.buttonsModal2}
								cancelButton={true}
								message={ <>
									<Form 
											formData={this.state.formData2} 
											
											changeHandler={this.changeHandler2} 
											formConfig={this.state.formConfig2}
									 />

										<table className="table" >
											<thead>
													<tr className="main-heading">
															<th scope="col"> JOB DATE </th>
															<th scope="col"> JOB EXECUTION DATE</th> 
															<th scope="col">STATUS</th>
															<th scope="col">ACTION</th>
													</tr>
											</thead>
											<tbody> 
											{this.state.logs.map( (item,index) => ( index<5 &&
												 <tr>
													<td className=" ">
														{item.JOB_DATE}
													</td>
													<td className=" ">
														{item.EXE_DATE}
													</td>
													<td className=" ">
														{item.RESP=="-"?"PND":item.RESP}
													</td> 
													<td className=" ">
														{item.RESP=="-" ?
															<button  className="btn btn-danger btn-sm"  name={"DELROW_"+item.COMPONENTID} onClick={()=>{ this.deleteJob(item.COMPONENTID)}}
															type="button"> <i className="fas fa-trash"  ></i> </button>
														:
															<button  className="btn btn-danger btn-sm" disabled type="button"> <i className="fas fa-trash"  ></i> </button>
															
														}
													</td> 
											</tr>
											 ))
											} 	 
											</tbody>
										</table>
									</>
												 }
							  />	 
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Layout Manager"} subtitle={"Header Menu"}/>
        	
        	<div className="row mb-4 justify-content-center text-right">
						<div className="col-md-12 col-lg-12">
								<ExcelFile filename={"export-menus."+this.state.date_label} element={         
						          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
						      }>
						          <ExcelSheet data={this.state.tableData.rows} name="Menus">
						              <ExcelColumn label="Menu Label" value="MENULABEL"/>
						              <ExcelColumn label="Route" value="MENUDESC"/> 
						              <ExcelColumn label="Menu Level" value="MENULEVEL"/> 
						              <ExcelColumn label="Sort Order" value="SORTORDER"/>
						          </ExcelSheet>

						    </ExcelFile>
								<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
								<button type="button" className="btn btn-sm btn-warning" name="set" onClick={this.publishMenu}> Publish Menu</button>
								<button type="button" className="btn btn-sm btn-secondary" name="set" onClick={ this.showJobs }> Program Menu</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 