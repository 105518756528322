import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";

const windowHeight = window.innerHeight

const loadCompCoords = props => {
	
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-12" style={{ "maxHeight": (windowHeight-240)+"px" , "overflow":"auto"  } }>
					{props.data.length>0?
		  			<div className="row">
							<div className={"col-4"}><span className="h6-responsive" >LATITUDE</span></div>							 
							<div className={"col-4"}><span className="h6-responsive" >LONGITUDE</span></div> 						 
							<div className={"col-4"}> </div>
						</div>	
					:""}
				  {props.data.map(itemM => (
				  	<>
							<div className="row mb-1">
						 		<div className={"col-4"}>
					 				<input key={props.container+"_latitude_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_latitude?"is-invalid":"")}
										value={itemM.latitude}
										name={"FIELDVALUE_"+props.container+"_latitude_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>						  	
						  	 
						  	
						   
						  	<div className={"col-4"}>
					 				<input key={props.container+"_latitude_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_longitude?"is-invalid":"")}
										value={itemM.longitude}
										name={"FIELDVALUE_"+props.container+"_longitude_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>		
						  	
						  	<div className={"col-4 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompCoords_"+itemM.rowId} onClick={props.manageComponent}
							       type="Coord"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-4"}></div>
								<div className={"col-4"}></div>
								<div className={"col-4 text-right"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompCoords"}
					         onClick={props.manageComponent} type="Coord"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO CompCoords DATA"
	}
};
export default loadCompCoords;
				 