/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';
import { EditorState, ContentState,convertToRaw, convertFromHTML } from 'draft-js'
/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
import FolderManager from '../../components/FolderManager';
import CompCard from '../../components/CompCard';
import CompCardChild from '../../components/CompCardChild';
import CompCategoryBlog from '../../components/CompCategoryBlog';
import CompItem from '../../components/CompItem';
import CompHeaderIconItem from '../../components/CompHeaderIconItem';
import CompHeaderItems from '../../components/CompHeaderItems';

import CompSliderImg from '../../components/CompSliderImg';
import CompList from '../../components/CompList';
import CompForm from '../../components/CompForm';
import CompButton from '../../components/CompButton';

/*functions*/
import {getSubcomponents,filterHandler,manageFolder,validateSyncForm, listFolders,executeScript, findObjName,find,onDragEnd,changeFilter,validateComponent,manageComponent,manageSubComponent,  getUserInfo,getComponentConfiguration,manageImage, listImages, validateRole, showWarning,encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			date_label:date_label,
			modal1: false,
			modal1B: false,
			modal2: false,
			modal3: false,
			selectedRow:"",
			modal3B: false,
			currFolderImagePath:"",
			modal4: false,
			itemsImages:[],
			modal6: false,
			modal7: false,
			modalCat: false,
			editorState: EditorState.createEmpty(),
			isLoading: true,
			modalClass: "modal-full-height modal-lg",
			modalClassB: "modal-full-height modal-lg",
			modalLgClass:"modal-lg",
			modalSmClass:"modal-sm",
			modalClass6:"modal-sm",
			filterConfig: {
				COMPONENTID    : { label:"Component ID"    , type:"NUMBER" , value:""} ,
				COMPONENTNAME  : { label:"Component Name"      ,  type:"TEXT"   , value:""} ,
				COMPONENTTYPE  : { label:"Component Type"      ,  type:"SELECT" , value:"" , values: []  },
				CATEGORY       : { label:"Category"      ,  type:"SELECT" , value:"" , values: []  },
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"COMPONENTID", title:"Component ID",
						sorter: (a, b) => a.COMPONENTID - b.COMPONENTID },
					{ dataIndex:"COMPONENTTYPE", title:"Component Type",
						sorter: (a, b) => a.COMPONENTTYPE.length - b.COMPONENTTYPE.length },
					{ dataIndex:"CATEGORY", title:"Category",
						sorter: (a, b) => a.CATEGORY?a.CATEGORY.length:0 - b.CATEGORY?b.CATEGORY.length:0 },
					{ dataIndex:"COMPONENTNAME", title:"Component Name",
						sorter: (a, b) => a.COMPONENTNAME?a.COMPONENTNAME.length:0 - b.COMPONENTNAME?b.COMPONENTNAME.length:0 },
					{ dataIndex: "COMPONENTID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-sm btn-info" id={record.COMPONENTID+"_"+record.COMPONENTTYPEID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-success" id={record.COMPONENTID+"_"+record.COMPONENTTYPEID+"_"+(record.CATEGORY?record.CATEGORY:"") } name="updatecat"
		         onClick={this.loadFormData}
		          type="submit"> 	<i className="fas fa-sync-alt ml-1" />  Category</button>
		         <button className="btn btn-sm btn-danger" id={record.COMPONENTID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
			componenttypeid:"",
			subcomponentType:"",
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			buttonsModal1B : [
				{	buttonAction: this.setB,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			buttonsModal3 : [
				{	buttonAction: this.setComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Select Component"}
			],
			
			buttonsModal3B : [
				{	buttonAction: this.setComponentB,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Create Child"}
			],
			
			buttonsModal2 : [
				{	buttonAction: this.updateCategoryComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Update Category"}
			],
			
			/*modal info*/
			buttonsModal4 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
			],
			
			buttonsModal6 : [
				{	buttonAction: this.validateComponent2,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent2,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
			],
			
			buttonsModal4B : [
				{	buttonAction: this.validateComponentB,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponentB,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
			],
			
			buttonsModal6B : [
				{	buttonAction: this.validateComponent2B,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent2B,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
			],
			
			modalIcon:"fas fa-edit",
			modalIconB:"fas fa-edit",
			modalTitle:"New Component",
			modalTitleB:"New Child Component",
			/*form data*/			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 2,
				
			},
			formConfig3:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				
			},			
			formData2	: { 
										COMPONENTCATEGORIES     : { label: "Category"            , type: "SELECT" , value:"" , values: []  } ,
										COMPONENTCATEGORY       : { label: "New Category"        , type: "TEXT" , value:""  }
									},
									
			formConfig2:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				
			},
			
			formData	: {},
			formDataB	: {},
			formData3	: { 
										COMPONENT      : { label: "Component Name"      , type : "SELECT", "value": "", "values": [] , "addDefault":"CHOOSE ONE"  },
										COMPONENTCATEGORIES     : { label: "Category"            , type: "SELECT" , value:"" , values: [], "addDefault":"NO DEFINED", getDataDB: {apiMethod: "getCategoryComponents", apiGroup:"layaoutManager"  }  } ,
										COMPONENTCATEGORY       : { label: "New Category"        , type: "TEXT" , value:""  }
									},
			formData3B	: { 
										COMPONENT   : { label: "Component Name" , "type": "SELECT", "value": "", "values": [] , "addDefault":"CHOOSE ONE"  }
									},
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			/*images or files*/
			initialFolder:"",
			imageManager:null,
			folderManager:null,
			imageManagerB:null,
			compManager:null,
			compManagerB:null,
			categories:[],
			category:"",
			objImage:{
				items:[],
				selected:[]
			},
			objFolder:{
				items:[],
				selected:[]
			},
			objImageB:{
				items:[],
				selected:[]
			}
			 
		}
	}
	
	
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		
	 		let resp = await callApi ( "layaoutManager","getComponents" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		//let respCat = await callApi ( "layaoutManager","getCategoryComponents" )
	 		this.setState({originalTableData:this.state.tableData, objComponents:resp.rows  });
	 		
	 		await preloadForm ( this.state.formData3)
	 		resp = await callApi ( "custom","getDetailDomainsByCode",  {domainid: 14  , subdomainId :getUserInfo().languageid})
	 		const formData3B = this.state.formData3B
	 		const formData3 = this.state.formData3
	 		const formData2 = this.state.formData2
	 		formData3.COMPONENT.values.push  ({value:"", text:"CHOOSE ONE" })
	 		formData3B.COMPONENT.values.push ({value:"", text:"CHOOSE ONE" })
	 		
	 		resp.rows.map(function (item) {
	 			if(item.NUMBER_VALUE==1){
	 				formData3B.COMPONENT.values.push ({value:item.DETAILDOMAINID, text:item.STRING_VALUE })
	 			}
	 			formData3.COMPONENT.values.push ({value:item.DETAILDOMAINID, text:item.STRING_VALUE })
			}); 
	 		formData3.COMPONENTCATEGORIES.values.map(function (item) {
	 			formData2.COMPONENTCATEGORIES.values.push ({value:item.value, text:item.text })
			}); 
	 		const filterConfig = this.state.filterConfig	 		
	 		this.state.formData3.COMPONENT.values.map(function (item,i) {
				 filterConfig.COMPONENTTYPE.values.push (i==0 ? "": item.text )
			}); 
			
			filterConfig.CATEGORY.values.push ( "")
			this.state.formData3.COMPONENTCATEGORIES.values.map(function (item,i) {
				 filterConfig.CATEGORY.values.push ( item.text )
			}); 
			
	 		
		}catch(err){
			console.log(">>>>>>>>>>.",err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	/*show component selector*/
	setComponent =async (event) => {
		
		var formData
		try{
			if(this.state.formData3.COMPONENT.value===""){
				showError(toast,"CHOOSE ONE OPTION");
			}else{
				this.setState({  ...this.state, isLoading:true})
				var _resp = await getComponentConfiguration(this.state.formData3.COMPONENT.value)
				formData = await preloadForm ( _resp.configuration )
				let loadData = await clearformData (formData)
				
				var category = this.state.formData3.COMPONENTCATEGORIES.value				
				if (this.state.formData3.COMPONENTCATEGORY.value.length>0 ){
					var category = this.state.formData3.COMPONENTCATEGORY.value
				}
	
			  
			 	this.setState({  ...this.state, category: category, isLoading:false, modal1:true, modal3:false, formData: formData, componenttypeid: _resp.code})
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}
	}
	
	setComponentB =async (event) => {		
		var formData
		try{
			if(this.state.formData3B.COMPONENT.value===""){
				showError(toast,"CHOOSE ONE OPTION");
			}else{
				this.setState({  ...this.state, isLoading:true})
				var _resp = await getComponentConfiguration(this.state.formData3B.COMPONENT.value)
				formData = await preloadForm ( _resp.configuration )
				let loadData = await clearformData (formData)
				
				let buttonsModal1 = [
					{	buttonAction: this.setB,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				let modalIcon="fas fa-plus"
				let modalTitle="New Component"
				let formAction="set"
				let itemId = null
				let modalClass =  "modal-full-height modal-lg"
				
			 	this.setState({  ...this.state,  isLoading:false, modal1B:true, modal3B:false, 
			 		formDataB: formData, componenttypeidB: _resp.code,
			 		modalClassB: modalClass, formActionB:formAction, 
			 		buttonsModal1B: buttonsModal1, 
			 		modalIconB:modalIcon, modalTitleB:modalTitle
			 		})
			}
		}catch(err){
			console.log(">>>>>>>>>>.",err)
		}
	}
	
	updateCategoryComponent =async (event) => {
		
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			
		  	this.setState({  ...this.state, isLoading:true })
		  	var _formData =  this.state.formData2;
		  	_formData = parseFormData(_formData)

				var category = _formData["componentcategories"].toUpperCase()
				if ( _formData["componentcategory"].length >0 ){
					category= _formData["componentcategory"].toUpperCase()
				}
				_formData["category"] = category
				_formData["componentid"] = this.state.currentId;				
				let resp = await callApi ( "layaoutManager","updateCategoryComponent", _formData )
				
		  	if (resp.success){
		  		
		  		const filterConfig = this.state.filterConfig
		  		const formData2 = this.state.formData2
		  		const formData3 = this.state.formData3
		  		
		  		if ( filterConfig.CATEGORY.values.indexOf( category ) <0){
		  			filterConfig.CATEGORY.values.push(category)
		  			filterConfig.CATEGORY.values.sort(function (a, b){
							return ((a < b) ? -1 : ((a > b) ? 1 : 0));
						})
		  		}		 
		  		let pos = find(formData2.COMPONENTCATEGORIES.values,category,"value")
		  		if ( pos <0){
		  			formData2.COMPONENTCATEGORIES.values.push({text:category, value:category})
		  		}		  		
		  		pos = find(formData3.COMPONENTCATEGORIES.values,category,"value")
		  		if ( pos < 0){
		  			formData3.COMPONENTCATEGORIES.values.push({text:category, value:category})
		  		}		
		  		showMessage(toast,resp.message);
		  		resp = await callApi ( "layaoutManager","getComponents" )
		 			const tableData = this.state.tableData
		 			tableData.rows = resp.rows
		 			this.setState({originalTableData:this.state.tableData});
		 			this.setState({objComponents:resp.rows} )
		 			this.filterHandler({target:{name:"search"}})
		 			this.setState({...this.state,modalCat:false });
		 			
		  	}else{
		  		showError(toast,resp.message);
		  	}
		  	
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>.",err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	/*charge data from a selected user*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Component"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData3)
				this.setState({...this.state,modal3:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
	  
			}else if (event.target.name==="updatecat"){
	  		
	  		let info = event.target.id.split("_")				
				itemId = info[0]
				var compTypeId = info[1]
				var currentCategory = info[2]
				const formData2 = this.state.formData2
				let loadData = await clearformData (this.state.formData2)
				
				if(currentCategory!=""){
	 				formData2.COMPONENTCATEGORIES.value = currentCategory;
				}else{
					if (formData2.COMPONENTCATEGORIES.values.length>0 ){
						currentCategory = formData2.COMPONENTCATEGORIES.values[0].value
						formData2.COMPONENTCATEGORIES.value = currentCategory
					}
				}
				
				
				this.setState({...this.state,modalCat:true,isLoading:false, currentId :itemId, currentCategory:currentCategory   });
	  
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Component"
				
				let info = event.target.id.split("_")				
				itemId = info[0]
				var compTypeId = info[1]
			
	  		let resp = await callApi ( "layaoutManager","getComponentById",{componentid: itemId} )
	  		
	  		let data2 = JSON.parse(resp.COMPONENT.DATA);
	  		
	  		const formData3 = this.state.formData3
	  		formData3["COMPONENT"].value=compTypeId	  		
	  		var _resp = await getComponentConfiguration(this.state.formData3.COMPONENT.value)				
	  		var formData = _resp.configuration
	  		compTypeId = _resp.code
	  		
				formData = await preloadForm ( formData )	  	
	  		this.setState({  ...this.state, formData: formData, componenttypeid: compTypeId})
	  		
	  		var COMPONENT =resp.COMPONENT
	  		let data = JSON.parse(resp.COMPONENT.DATA);
	  		let config = JSON.parse(resp.COMPONENT.CONFIG);
 
				
	  		for (let key in Object.keys(data.data) ){
	  			var item = Object.keys(data.data) [key]
	  			if(this.state.formData["data"+item]){
	  				COMPONENT["data"+item] = data.data[item]
	  			}else{
	  				showWarning(toast, "VALUE NOT FOUND ("+item+")" )
	  			}
	  		}
	  		for (let key in Object.keys(config.config) ){
	  			var item = Object.keys(config.config) [key]
	  			if(this.state.formData["config"+item]){
	  				COMPONENT["config"+item] = config.config[item]
	  			}else{
	  				showWarning(toast, "CONF NOT FINDED ("+item+")" )
	  			}
	  		}
	  		delete COMPONENT["DATA"] 
				delete COMPONENT["CONFIG"]
				
				 //AQUI
					var content =   data.content;
					if ( data.content.length>0){
						if ( data.content[0].sortorder){
							content= data.content.sort(function (a, b){
							return (a.sortorder - b.sortorder)
							})
							
							for (let c in content){
								delete content[c]["rowId"] 
							}
							
							for (let c in content){
								content[c]["rowId"] = parseInt(c)
							}
							
						}				
					}
	  		let loadData = await mergeFormData (this.state.formData,COMPONENT)	  	
	  		formData = this.state.formData
	  		if (data.content){
	  			
	  			if (data.content.length>0){
	  				if ( resp.COMPONENT.COMPONENTTYPE=="BLOGCONTAINER" ){
	  					
	  					let availComp=[]
	  					formData["datacontent"].values.map(function (item) { 				
				 				let isUsed=false;
				 				let pos = find (content, item.value, "value" )
			 					if (pos>=0){
			 						isUsed= true;
			 					}
				 				if (!isUsed){
				 					availComp.push(item)	
				 				}
				 			})
				 			formData["datacontent"].value  = content
	  					formData["datacontent"].values = availComp	
	  				}else{
	  					formData["datacontent"].values = content	
	  				}
		  			
		  		}  	
		  		
	  		} 
	  		formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
	  	
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Component"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
				this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>.",err)
		}
  }
  //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
  ///get value fields
  changeHandler = async(event, eventColor) => { 
  	
    if (eventColor || !event.target.name){
  		var objName=  findObjName (eventColor)
  		event["target"] = {name:objName } 
  	}
  	//COMPONENT
  	if (event.target.name.indexOf("COMPONENT") >=0 ){
  		let resp = await validateForm(this.state.formData3, toast,event);
			this.setState({formData3:resp.formData});
			
  	}else{
  		let resp
  		var isAsync=false
	  	if (  this.state.formData[event.target.name]){
	  		if ( this.state.formData[event.target.name].script ){
	  			isAsync=true
	  		}  		
	  	}
	  	if (isAsync){
	  		resp = await validateForm(this.state.formData, toast,event);
	  	}else{
	  		resp = validateSyncForm(this.state.formData, toast,event);	
	  	}
  		
  	//	let resp = await validateForm(this.state.formData, toast,event);
			this.setState({formData:resp.formData});
  	}  
	} 
		
	changeHandler2 = async(event, eventColor) => { 
		
		let resp = await validateForm(this.state.formData2, toast,event);
		this.setState({formData2:resp.formData});
		
	} 
	onDragEnd = result => {
    var resp = onDragEnd(result, this.state.formData)	
  }
	
	///get filter to components
  changeFilter = async(event) => {
  	 
  	let _data = findObjName(event)
 
  	let data =_data.split("__")
  	var compName = data[0]
  	
  	var ObjComponentsOrig=this.state.formData[compName].originalValues
  	var resp = await changeFilter(event, this.state.formData[compName].originalValues , this.state.formData)	
    this.setState({formData:resp});
    
	} 
	
	changeHandlerB = async(event, eventColor) => {
    if (eventColor || !event.target.name){
  		var objName=  findObjName (eventColor)
  		event["target"] = {name:objName } 
  	}
  	//COMPONENT
  	if (event.target.name==="COMPONENT"){
  		let resp = await validateForm(this.state.formData3B, toast,event);
			this.setState({formData3B:resp.formData});
			
  	}else{
  		let resp = await validateForm(this.state.formDataB, toast,event);
			this.setState({formDataB:resp.formData});
  	}  
	} 
	
	//add new
  setB=async (event) => {
  	this.executeB(event)
	}
	
	updateB=async (event) => {
  	this.executeB(event)
	}
	
	
  //show/hide modal
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	//manage image
	manageImage = async(event) => {
		try{
			this.setState({isLoading:true});
			 
			var data = findObjName(event).split("_")
			
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages(this.state.currFolderImagePath,objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal2: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage , currFolderImagePath: objImage.route });
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
						let field = objImage.objectName
						var formData = this.state.formData
						formData[field].value = objImage.selected[0]
						this.setState({modal2:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	//manage Folder
	manageFolder= async(event) => {
		try{
			this.setState({isLoading:true});			
			var _data =   findObjName(event)
			var data = (_data).split("_")
			let action =  data[0]		
			
			 
				 
			if (action==="manageFolder"){
				let objectName = data[1]			 
				let _currFolder = this.state.formData[objectName].value.split("/")
				let size = _currFolder.length
				_currFolder.splice ( size-2, 2 )
				 
				let currFolder = ""
				if ( _currFolder.length>0){
					for (let c in _currFolder){
						currFolder = currFolder +	_currFolder[c] +"/"
					}	
				}
				
				let objFolder = await listFolders(currFolder,objectName,[]);
				this.setState({objFolder:objFolder});
				let folderManager = <FolderManager objFolder={this.state.objFolder} manageFolder={this.manageFolder} />
	 		 	this.setState({ folderManager:folderManager, modal7: true});
			}else{
				var objFolder = await manageFolder( event, this.state.objFolder)
				this.setState({objFolder:objFolder});
				let folderManager = <FolderManager objFolder={this.state.objFolder} manageFolder={this.manageFolder} />
				this.setState({folderManager:folderManager});
				
				if (objFolder.value){
						let field = objFolder.objectName
						var formData = this.state.formData
						formData[field].value = objFolder.value
						this.setState({modal7:false});

						if (formData[field].script){
							this.setState({isLoading:true});
							var _formData = await executeScript( formData, field, toast)
							this.setState({formData:_formData});
						}
				}
			}
			
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	//manage image
	manageImageB = async(event) => {
		try{
			this.setState({isLoading:true});
			 
			var data = findObjName(event).split("_")
			
			let action =  data[0]
			if (action==="manageImage"){
				let objectNameB = data[1]
				let objImageB = await listImages(this.state.currFolderImagePath,objectNameB);
				this.setState({objImageB:objImageB});
				let imageManagerB = <ImageManager objImage={this.state.objImageB} manageImage={this.manageImageB} />
	 		 	this.setState({ imageManagerB:imageManagerB, modal2B: true});
			}else{
				var objImageB = await manageImage( event, this.state.objImageB)
				this.setState({objImageB:objImageB , currFolderImagePath: objImageB.route });
				let imageManagerB = <ImageManager objImage={this.state.objImageB} manageImage={this.manageImageB} />
				this.setState({imageManagerB:imageManagerB});
				if (objImageB.selected.length===1){
						let field = objImageB.objectName
						var formDataB = this.state.formDataB
						formDataB[field].value = objImageB.selected[0]
						this.setState({modal2B:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	//manage image
	manageImageOption= async(event) => {
		try{
			this.setState({isLoading:true});
			
			var data = findObjName(event).split("_")
			
			if(data[0]=="manageImage" ){
				if (data.length===3 ){
					this.setState({imageField:data[2]});
				}
				if (data[3] =="items" ){
					this.setState({ itemsImages:data});
				}else{
					this.setState({itemsImages:[]});
				}
			}
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages(this.state.currFolderImagePath,objectName);
				this.setState({objImage:objImage});
				let imageManager2 = <ImageManager objImage={this.state.objImage} manageImage={this.manageImageOption} />
	 		 	this.setState({ imageManager2:imageManager2, modal5: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage , currFolderImagePath: objImage.route });
				let imageManager2 = <ImageManager objImage={this.state.objImage} manageImage={this.manageImageOption} />
				this.setState({imageManager2:imageManager2});
				if (objImage.selected.length===1){
					
					let field = objImage.objectName
					var objOptions 
					if (this.state.itemsImages.length>0){
						let _data = this.state.itemsImages
						let pos = find (this.state.formData[this.state.componentName].values, parseInt(_data[4]), "rowId" )
						objOptions = this.state.formData[this.state.componentName].values[pos][_data[3]]
						let posChild = find (objOptions, parseInt(_data[6]), "rowId"  )
						//this.state.formData[this.state.componentName].values[pos][_data[2]] 
						objOptions[posChild][_data[5]] = objImage.selected[0]
						let compManager2={}
						if ( _data[1] =="CompHeaderIconItem" ){
							let items= objOptions
							let subcomponentName= _data[2] +"_"+_data[3]+"_"+_data[4]
							compManager2 = <CompHeaderIconItem  components={this.state.objComponents} 
																	data={items} 
																	container= {subcomponentName} 
																	manageComponent={this.manageSubComponent}  
																	manageImage={this.manageImageOption} />	
						}
						this.setState({  compManager2:compManager2 });
						
					}else{
						objOptions = this.state.formData[this.state.componentName].values
						for (let i in objOptions){						 
						if (objOptions[i].rowId == field){			
							if (!this.state.imageField){
								objOptions[i].image = objImage.selected[0]
							}else{
								objOptions[i][this.state.imageField] = objImage.selected[0]
							}		 
							
						}
					}
					}
					
					this.setState({modal5:false});
					let compManager = this.setSubComponent(event)	
					this.setState({compManager:compManager});
				}
			}/*
			if (data.length===2){
				this.setState({imageField:null});
			}*/
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	}
	
	//manage image
	manageImageOptionB= async(event) => {
		try{
			this.setState({isLoading:true});
			
			var data = findObjName(event).split("_")
			
			if (data.length===3 && data[0]=="manageImage" ){
				this.setState({imageField:data[2]});
			}
			 
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImageB = await listImages(this.state.currFolderImagePath,objectName);
				this.setState({objImageB:objImageB});
				let imageManager2B = <ImageManager objImage={this.state.objImageB} manageImage={this.manageImageOptionB} />
	 		 	this.setState({ imageManager2B:imageManager2B, modal5: true});
			}else{
				var objImageB = await manageImage( event, this.state.objImageB)
				this.setState({objImageB:objImageB , currFolderImagePath: objImageB.route });
				let imageManager2B = <ImageManager objImage={this.state.objImageB} manageImage={this.manageImageOptionB} />
				this.setState({imageManager2B:imageManager2B});
				if (objImageB.selected.length===1){				 
					let field = objImageB.objectName
					var objOptions = this.state.formDataB[this.state.componentNameB].values
					for (let i in objOptions){						 
						if (objOptions[i].rowId == field){			
							if (!this.state.imageField){
								objOptions[i].image = objImageB.selected[0]
							}else{
								objOptions[i][this.state.imageField] = objImageB.selected[0]
							}		 
							
						}
					}
					this.setState({modal5B:false});
					let compManagerB = this.setSubComponentB(event)	
					this.setState({compManagerB:compManagerB});
				}
			}/*
			if (data.length===2){
				this.setState({imageField:null});
			}*/
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	}
	
	
	/*manage Components*/
	manageComponent = async (event,eventColor ) => {
		try{
			if (eventColor && !event.target){
	  		var objName=  findObjName (eventColor)
	  		event["target"] = {name:objName }  
	  	}	  	 
			this.setState({isLoading:true});
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
	 		var compManager 
			let action =  data[0]
			var componentName = data[1]
			var _components= getSubcomponents() 
			
			//console.log("data comp",data)
			
			if (_components.indexOf(action)>=0 ){
 
				if (action==="CompCard"){
					compManager = <CompCard      		components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config Childs", componentName:componentName });
				}else if (action==="CompSliderImg"){
					compManager = <CompSliderImg 		components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config Images", componentName:componentName });
				}else if (action==="CompForm"){
					compManager = <CompForm      		components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config Form Element", componentName:componentName });
				}else if (action==="CompHeaderItems"){
					compManager = <CompHeaderItems   type="header" 	components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config Header Element", componentName:componentName });
				}else if (action==="CompFooterItems"){
					compManager = <CompHeaderItems   type="footer"  components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config Footer Element", componentName:componentName });
				}else if (action==="CompCardChild"){
					compManager = <CompCardChild 	 	components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} filter={this.state.formData[componentName].filter	} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config Childs", componentName:componentName });
				}else if (action==="CompButton"){
					compManager = <CompButton       components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent}  manageImage={this.manageImageOption} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config Button", componentName:componentName });
				}else if (action==="CompCategoryBlog"){
					compManager = <CompCategoryBlog components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent}  manageImage={this.manageImageOption} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config Blog", componentName:componentName });
				}else if (action==="CompList"){
					compManager = <CompList components={this.state.objComponents} data={this.state.formData[componentName].values} container= {componentName} manageComponent={this.manageComponent}  manageImage={this.manageImageOption} />
					this.setState({ subcomponentType:action, compManager:compManager, modal4: true, titlemodal4: "Config List", componentName:componentName });
				}else if (action==="CompItem"){
					
					let rowId = data[2]+"_"+data[3]
					let subcomponentName = componentName+"_"+rowId				
					//console.log("entro por manager:"+subcomponentName, this.state.formData[componentName].values )
					let pos = find ( this.state.formData[componentName].values,  parseInt(data[3]) , "rowId" )
					var items = this.state.formData[componentName].values[pos].items?this.state.formData[componentName].values[pos].items:[]
					let compManager2 = <CompItem  components={this.state.objComponents} data={items} container= {subcomponentName} manageComponent={this.manageSubComponent}  manageImage={this.manageImageOption} />
				
					this.setState({ subcomponentType2:action, compManager2:compManager2, modal6: true, modalClass6:"modal-sm", titlemodal6: "Config Items", subcomponentName:subcomponentName });
				}else if (action==="CompHeaderIconItem"){
					let rowId = data[2]+"_"+data[3]
					let subcomponentName = componentName+"_"+rowId				
					let pos = find ( this.state.formData[componentName].values,  parseInt(data[3]) , "rowId" )
					var items = this.state.formData[componentName].values[pos].items?this.state.formData[componentName].values[pos].items:[]
					let compManager2 = <CompHeaderIconItem  components={this.state.objComponents} data={items} container= {subcomponentName} manageComponent={this.manageSubComponent}  manageImage={this.manageImageOption} />
					this.setState({ subcomponentType2:action, compManager2:compManager2, modal6: true, modalClass6:"modal-lg-2", titlemodal6: "Config Header Items", subcomponentName:subcomponentName });
				}
			}else{
				if ( action =="ADDNEWCOMP"){
				
					var selectedRow = data[2]+"" 
					let pos = find(this.state.formData[data[1]].values, parseInt(selectedRow), "rowId" )
					
					let field = this.state.formData[data[1]].values[pos]
					//console.log(field, this.state.objComponents)
					
					if(field.children!=""){
						pos = find(this.state.objComponents, field.children, "COMPONENTNAME" )
						let itemId = this.state.objComponents[pos].COMPONENTID
						let compTypeId = this.state.objComponents[pos].COMPONENTTYPEID
					
			  		let resp = await callApi ( "layaoutManager","getComponentById",{componentid: itemId} )
			  		let data2 = JSON.parse(resp.COMPONENT.DATA);
			  		
			  		const formData3B = this.state.formData3B
			  		formData3B["COMPONENT"].value=compTypeId	  		
			  		
			  		var _resp = await getComponentConfiguration(this.state.formData3B.COMPONENT.value)
			  		
			  		var formData = _resp.configuration
			  		compTypeId = _resp.code
			  		
						formData = await preloadForm ( formData )	  	
			  		this.setState({  ...this.state, formDataB: formData, componenttypeidB: compTypeId})
			  		
			  		//console.log("start");
			  		var COMPONENT =resp.COMPONENT
			  		let data = JSON.parse(resp.COMPONENT.DATA);
			  		let config = JSON.parse(resp.COMPONENT.CONFIG);
		 
						
			  		for (let key in Object.keys(data.data) ){
			  			var item = Object.keys(data.data) [key]
			  			if(this.state.formDataB["data"+item]){
			  				COMPONENT["data"+item] = data.data[item]
			  			}else{
			  				showWarning(toast, "VALUE NOT FOUND ("+item+")" )
			  			}
			  		}
			  		for (let key in Object.keys(config.config) ){
			  			var item = Object.keys(config.config) [key]
			  			if(this.state.formDataB["config"+item]){
			  				COMPONENT["config"+item] = config.config[item]
			  			}else{
			  				showWarning(toast, "CONF NOT FINDED ("+item+")" )
			  			}
			  		}
			  		delete COMPONENT["DATA"] 
						delete COMPONENT["CONFIG"]
						
						 //AQUI
							var content =   data.content;
							if ( data.content.length>0){
								if ( data.content[0].sortorder){
									content= data.content.sort(function (a, b){
									return (a.sortorder - b.sortorder)
									})
									
									for (let c in content){
										delete content[c]["rowId"] 
									}
									
									for (let c in content){
										content[c]["rowId"] = parseInt(c)
									}
									
								}				
							}
				 
			  		let loadData = await mergeFormData (this.state.formDataB,COMPONENT)
			  		formData = this.state.formDataB
			  		if (data.content){
			  			
			  			if (data.content.length>0){
			  				if ( resp.COMPONENT.COMPONENTTYPE=="BLOGCONTAINER" ){
			  					
			  					let availComp=[]
			  					formData["datacontent"].values.map(function (item) { 				
						 				let isUsed=false;
						 				let pos = find (content, item.value, "value" )
					 					if (pos>=0){
					 						isUsed= true;
					 					}
						 				if (!isUsed){
						 					availComp.push(item)	
						 				}
						 			})
						 			formData["datacontent"].value  = content
			  					formData["datacontent"].values = availComp	
			  				}else{
			  					formData["datacontent"].values = content	
			  				}
				  			
				  		}  	
				  		
			  		} 
			  		let formAction="update"
			  		let modalClass =  "modal-full-height modal-lg"
			  		let buttonsModal1 = [
							{	buttonAction: this.updateB,
								buttonId    : 1,
								buttonClass : "btn btn-outline-primary",
								buttonName  : "Update"}
						]
						let modalIcon="fas fa-edit"
						let modalTitle="Edit Child Component"
			  		this.setState({...this.state,modal1B:true,isLoading:false, currentIdB: itemId, componenttypeidB: _resp.code,  modalClassB: modalClass, formActionB:formAction, buttonsModal1B: buttonsModal1, modalIconB:modalIcon, modalTitleB:modalTitle  });
			  	
					}else{
						this.setState({...this.state, modal3B:true, selectedRow:selectedRow, currentIdB:null, formActionB: 'set'});
					}
					
				}else{
					var formData = manageComponent( event, this.state.formData)
					this.setState({formData:formData});
				
					compManager = this.setSubComponent(event)		
					this.setState({compManager:compManager});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	manageSubComponent = async(event) => {
		try{
			
			this.setState({isLoading:true});
			let resp = await manageSubComponent (event, this.state.formData)
			this.setState({formData:resp.formData}); 
			
			let compManager2 
			if (this.state.subcomponentType2 == "CompItem"){
			compManager2 = <CompItem  components={this.state.objComponents} data={ resp.data } container= {resp.subcomponentName} manageComponent={this.manageSubComponent}  manageImage={this.manageImageOption} />	
			}else if (this.state.subcomponentType2 == "CompHeaderIconItem"){
			compManager2 = <CompHeaderIconItem  components={this.state.objComponents} data={ resp.data } container= {resp.subcomponentName} manageComponent={this.manageSubComponent}  manageImage={this.manageImageOption} />	
			}
			this.setState({ compManager2:compManager2 });
			
		 
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	manageComponentB = (event,eventColor ) => {
		try{
			if (eventColor && !event.target){
			
	  		var objName=  findObjName (eventColor)
	  		event["target"] = {name:objName }  
	  	}
	  	 
			this.setState({isLoading:true});
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
	 		var compManagerB
			let action =  data[0]
			var componentNameB = data[1]
			var _components= getSubcomponents() 
			  
			if (_components.indexOf(action)>=0 ){
 
				if (action==="CompCard"){
					compManagerB = <CompCard      		components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Childs", componentNameB:componentNameB });
				}else if (action==="CompSliderImg"){
					compManagerB = <CompSliderImg 		components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Images", componentNameB:componentNameB });
				}else if (action==="CompForm"){
					compManagerB = <CompForm      		components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Form Element", componentNameB:componentNameB });
				}else if (action==="CompHeaderItems"){
					compManagerB = <CompHeaderItems  type="header" components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Header Element", componentNameB:componentNameB });
				}else if (action==="CompFooterItems"){
					compManagerB = <CompHeaderItems  type="footer" components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Footer Element", componentNameB:componentNameB });
					
				}else if (action==="CompCardChild"){
					compManagerB = <CompCardChild  	components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} filter={this.state.formDataB[componentNameB].filter	} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Childs", componentNameB:componentNameB });
				}else if (action==="CompButton"){
					compManagerB = <CompButton       components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Button", componentNameB:componentNameB });
				}else if (action==="CompCategoryBlog"){
					compManagerB = <CompCategoryBlog components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Blog", componentNameB:componentNameB });
				}else if (action==="CompList"){
					compManagerB = <CompList components={this.state.objComponents} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config List", componentNameB:componentNameB });
				}else if (action==="CompItem"){
					
					let rowId = data[2]+"_"+data[3]
					let subcomponentNameB = componentNameB+"_"+rowId				
					//console.log("entro por manager"+subcomponentNameB)
					let pos = find ( this.state.formDataB[componentNameB].values,  parseInt(data[3]) , "rowId" )
					var itemsB = this.state.formDataB[componentNameB].values[pos].items?this.state.formDataB[componentNameB].values[pos].items:[]
					
					let compManager2B = <CompItem  components={this.state.objComponents} data={itemsB} container= {subcomponentNameB} manageComponent={this.manageSubComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentType2B:action, compManager2B:compManager2B, modal6B: true, modalClass6:"modal-sm",titlemodal6B: "Config Items", subcomponentNameB:subcomponentNameB });
				}else if (action==="CompHeaderIconItem"){
					
					let rowId = data[2]+"_"+data[3]
					let subcomponentNameB = componentNameB+"_"+rowId				
					//console.log("entro por manager"+subcomponentNameB)
					let pos = find ( this.state.formDataB[componentNameB].values,  parseInt(data[3]) , "rowId" )
					var itemsB = this.state.formDataB[componentNameB].values[pos].items?this.state.formDataB[componentNameB].values[pos].items:[]
					
					let compManager2B = <CompHeaderIconItem  components={this.state.objComponents} data={itemsB} container= {subcomponentNameB} manageComponent={this.manageSubComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentType2B:action, compManager2B:compManager2B, modal6B: true,modalClass6:"modal-lg", titlemodal6B: "Config header Items", subcomponentNameB:subcomponentNameB });
				}
				
			}else{
				if ( action ==""){
					
				}else{
					var formDataB = manageComponent( event, this.state.formDataB)
					this.setState({formDataB:formDataB});
				
				compManagerB = this.setSubComponentB(event)		
				this.setState({compManagerB:compManagerB});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	 
	
	
	manageSubComponentB = async(event) => {
		try{
			
			this.setState({isLoading:true});
	
			let resp = await manageSubComponent (event, this.state.formDataB)
		 
			this.setState({formDataB:resp.formData}); 
			
			let compManager2B 
			if (this.state.subcomponentType2B == "CompItem"){
			compManager2B = <CompItem  components={this.state.objComponents} data={ resp.data } container= {resp.subcomponentName} manageComponent={this.manageSubComponentB}  manageImage={this.manageImageOptionB} />	
			}else if (this.state.subcomponentType2B == "CompHeaderIconItem"){
				compManager2B = <CompHeaderIconItem  components={this.state.objComponents} data={ resp.data } container= {resp.subcomponentName} manageComponent={this.manageSubComponentB}  manageImage={this.manageImageOptionB} />	
			}
			this.setState({ compManager2B:compManager2B});
			 
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	setSubComponent= (event) => {
		var compManager
		if(this.state.subcomponentType==="CompCard"){
			compManager = <CompCard 					components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
		}else if (this.state.subcomponentType==="CompSliderImg"){
			compManager = <CompSliderImg 			components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
		}else if (this.state.subcomponentType==="CompForm"){
			compManager = <CompForm 					components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
		}else if (this.state.subcomponentType==="CompHeaderItems"){
			compManager = <CompHeaderItems 	type="header"  components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
		}else if (this.state.subcomponentType==="CompFooterItems"){
			compManager = <CompHeaderItems 	type="footer"  components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
		}else if (this.state.subcomponentType==="CompCardChild"){
			compManager = <CompCardChild      components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption}  filter={this.state.formData[this.state.componentName].filter	} /> 
		}else if(this.state.subcomponentType==="CompButton"){
			compManager = <CompButton 			 	components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption}/>
		}else if (this.state.subcomponentType==="CompCategoryBlog"){
			compManager = <CompCategoryBlog 	components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
		}else if (this.state.subcomponentType==="CompList"){
			compManager = <CompList 	components={this.state.objComponents} data={this.state.formData[this.state.componentName].values} container= {this.state.componentName} manageComponent={this.manageComponent} manageImage={this.manageImageOption} />
		}
		return compManager
	}
	
	setSubComponentB= (event) => {
		var compManager
		if(this.state.subcomponentTypeB==="CompCard"){
			compManager = <CompCard 					components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompSliderImg"){
			compManager = <CompSliderImg 			components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompForm"){
			compManager = <CompForm 					components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompHeaderItems"){
			compManager = <CompHeaderItems  type="header"  components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompFooterItems"){
			compManager = <CompHeaderItems  type="footer"  components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
			
		}else if (this.state.subcomponentTypeB==="CompCardChild"){
			compManager = <CompCardChild      components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB}  filter={this.state.formDataB[this.state.componentNameB].filter	} /> 
		}else if(this.state.subcomponentTypeB==="CompButton"){
			compManager = <CompButton 			 	components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB}/>
		}else if (this.state.subcomponentTypeB==="CompCategoryBlog"){
			compManager = <CompCategoryBlog 	components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompList"){
			compManager = <CompList 	components={this.state.objComponents} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}
		return compManager
	}
	
	validateComponent= async(event) => {		 
		try{
			this.setState({isLoading:true});
			var resp = await validateComponent( this.state.componentName, this.state.formData, toast)
			this.setState({formData:resp.formData});
	 		let compManager = this.setSubComponent(event)	
	 		if (compManager!= null){
	 			this.setState({ compManager:compManager  });
	 		}
			if (resp.isValid){
				this.setState({modal4:false});
			}else{
				showError(toast,"CHECK COMPONENT VALUES");	
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	validateComponent2= async(event) => {		 
		try{
			this.setState({isLoading:true});
	 		let compManager = this.setSubComponent(event)	

	 		if (compManager!= null){
	 			this.setState({ compManager:compManager, modal6:false  });
	 		}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	cancelComponent= async(event) => {
		try{
			this.setState({isLoading:true});
			const formData = this.state.formData
			formData[this.state.componentName].values=[]
			this.setState({modal4:false});
			let compManager = this.setSubComponent(event)		
			this.setState({compManager:compManager});				
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	cancelComponent2= async(event) => {
		try{
			 
			this.setState({isLoading:true});
			this.setState({modal6:false});			
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 

	
	validateComponentB= async(event) => {		 
		try{
			this.setState({isLoading:true});
			var resp = await validateComponent( this.state.componentNameB, this.state.formDataB, toast)
			this.setState({formDataB:resp.formData});
	 		let compManagerB = this.setSubComponentB(event)	
	 		if (compManagerB!= null){
	 			this.setState({ compManagerB:compManagerB  });
	 		}
			if (resp.isValid){
				this.setState({modal4B:false});
			}else{
				showError(toast,"CHECK COMPONENT VALUES");	
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	validateComponent2B= async(event) => {		 
		try{
			this.setState({isLoading:true});
	 		let compManagerB = this.setSubComponentB(event)	
	 		if (compManagerB!= null){
	 			this.setState({ compManagerB:compManagerB, modal6B:false  });
	 		}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	cancelComponentB= async(event) => {
		try{
			this.setState({isLoading:true});
			const formDataB = this.state.formDataB
			formDataB[this.state.componentNameB].values=[]
			this.setState({modal4B:false});
			let compManagerB = this.setSubComponentB(event)		
			this.setState({compManagerB:compManagerB});				
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	cancelComponent2B= async(event) => {
		try{
			this.setState({isLoading:true});
			this.setState({modal6B:false});			
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	/*ABM manager*/
	execute=async (event,actionType) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			if (actionType==="DELETE"){
  				resp= {isValid:true}
  			}else{
  				resp = await validateForm(this.state.formData, toast);
  			}
  			if(resp.isValid){
			  	this.setState({  ...this.state, isLoading:true })
			  	var _formData =  this.state.formData;
			  	_formData = parseFormData(_formData)
					_formData["componentid"] = this.state.currentId;
					_formData["componenttypeid"] = this.state.componenttypeid;
					_formData["category"] = this.state.category;
				 
				 
				
					let resp = await callApi ( "layaoutManager",this.state.formAction+"Components", _formData )
					
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "layaoutManager","getComponents" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({originalTableData:this.state.tableData});
			 			this.setState({objComponents:resp.rows} )
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
		  	}
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>.",err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	/*ABM manager*/
	executeB=async (event) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			resp = await validateForm(this.state.formDataB, toast);
  			if(resp.isValid){
			  	this.setState({  ...this.state, isLoading:true })
			  	var _formData =  this.state.formDataB;
			  	_formData = parseFormData(_formData)
					_formData["componenttypeid"] = this.state.componenttypeidB;
					_formData["category"] = this.state.category;
					_formData["componentid"] = this.state.currentIdB;
					
					let resp = await callApi ( "layaoutManager",this.state.formActionB+"Components", _formData )
					
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "layaoutManager","getComponents" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({originalTableData:this.state.tableData});
			 			this.setState({objComponents:resp.rows} )
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1B:false });
			 			
			 			let _event2= {target:{name : "CompCardChild_datacontent" }}
			 			this.manageComponent(_event2)
			 			  
			 			let _event= {target:{name : "FIELDVALUE_datacontent_children_"+this.state.selectedRow, value:_formData.componentname } }
			 			this.manageComponent(_event)
			 			
			 			 
			 			this.setState({reloadComponent:true});  
						setTimeout( async function() {
							
						 this.setState({reloadComponent:false});  
						}.bind(this),1	)		
			 			
			  	}else{
			  		showError(toast,resp.message);
			  	}
		  	}
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>.",err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
			<Modal open={this.state.modal3} 
								toggle={this.toggleModal (3)}  
								icon=""
								title={"Choose Component Type"}
								buttons={this.state.buttonsModal3}
								cancelButton={true}
								class={this.state.modalSmClass}
								message= <Form 
															formData={this.state.formData3} 
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig3}
													 />
							  />
		
		<Modal open={this.state.modalCat} 
							toggle={this.toggleModal ("Cat")}  
							icon=""
							title={"Update Category"}
							buttons={this.state.buttonsModal2}
							cancelButton={true}
							class={this.state.modalSmClass}
							message= <Form 
														formData={this.state.formData2} 
														changeHandler={this.changeHandler2} 
														formConfig={this.state.formConfig2}
												 />
						  />
							  
		<Modal open={this.state.modal5} 
			icon="fas fa-images"
      	title="Image Manager"
      	zindex="1063"
      	message={this.state.imageManager2}
      	toggle={this.toggleModal (5)} 
      	class={this.state.modalLgClass}
							  />
		
		<Modal open={this.state.modal5B} 
			icon="fas fa-images"
      	title="Image Manager"
      	zindex="1063"
      	message={this.state.imageManager2B}
      	toggle={this.toggleModal ("5B")} 
      	class={this.state.modalLgClass}
							  />
							  
      <Modal open={this.state.modal2}
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1062"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (2)} 
      	class={this.state.modalLgClass}
      />
      
      <Modal open={this.state.modal2B}
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1067"
      	message={this.state.imageManagerB}
      	toggle={this.toggleModal ("2B")} 
      	class={this.state.modalLgClass}
      />
      
      {!this.state.reloadComponent?
	       <Modal open={this.state.modal4}
	      	icon="fas fa-images"
	      	title={this.state.titlemodal4}
	      	zindex="1060"
	      	message={this.state.compManager}
	      	buttons={this.state.buttonsModal4}
	      	class={this.state.modalLgClass}
	      />
	    :""}
    
       <Modal open={this.state.modal4B}
      	icon="fas fa-images"
      	title={this.state.titlemodal4B}
      	zindex="1068"
      	message={this.state.compManagerB}
      	buttons={this.state.buttonsModal4B}
      	class={this.state.modalLgClass}
      />
      
       <Modal open={this.state.modal6}
      	icon="fas fa-images"
      	title={this.state.titlemodal6}
      	zindex="1061"
      	message={this.state.compManager2}
      	buttons={this.state.buttonsModal6}
      	class={this.state.modalClass6}
      />
      
      <Modal open={this.state.modal6B}
      	icon="fas fa-images"
      	title={this.state.titlemodal6B}
      	zindex="1069"
      	message={this.state.compManager2B}
      	buttons={this.state.buttonsModal6B}
      	class={this.state.modalClass6}
      />
      
      <Modal open={this.state.modal7}
      	icon="fas fa-folder"
      	title="Category Folder"
      	zindex="1067"
      	message={this.state.folderManager}
      	toggle={this.toggleModal (7)} 
      	class={this.state.modalLgClass}
      />
      
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													: <Form 
															formData={this.state.formData} 
															manageImage={this.manageImage}
															manageComponent={this.manageComponent}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
															editorState={this.state.editorState}
															onEditorStateChange={this.onEditorStateChange}
															onDragEnd={this.onDragEnd}
															changeFilter={this.changeFilter}
															manageFolder={this.manageFolder}
													 />
												 }
							  />
			
			<Modal open={this.state.modal3B} 
								toggle={this.toggleModal ("3B")}  
								icon=""
								title={"Choose Component Child Type"}
								buttons={this.state.buttonsModal3B}
								cancelButton={true}
								zindex="1065"
								class={this.state.modalSmClass}
								message= <Form 
															formData={this.state.formData3B} 
															changeHandler={this.changeHandlerB} 
															formConfig={this.state.formConfig3}
													 />
							  />
							  
			<Modal open={this.state.modal1B} 
								toggle={this.toggleModal ("1B")} 
								class={this.state.modalClassB}
								icon={this.state.modalIconB}
								title={this.state.modalTitleB}
								zindex="1066"
								buttons={this.state.buttonsModal1B}
								cancelButton={true}
								message={this.state.formActionB==="delete"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													: <Form 
															formData={this.state.formDataB} 
															manageImage={this.manageImageB}
															manageComponent={this.manageComponentB}
															changeHandler={this.changeHandlerB} 
															formConfig={this.state.formConfig}
															editorState={this.state.editorState}
															onEditorStateChange={this.onEditorStateChange}
													 />
												 }
							  />				  
							
						
					
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Layout Manager"} subtitle={"Components"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
							<ExcelFile filename={"export-components."+this.state.date_label} element={         
						          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
						      }>
						       
						          <ExcelSheet data={this.state.tableData.rows} name="Components">
						          		<ExcelColumn label="Component ID"    value="COMPONENTID"/>
						          		<ExcelColumn label="Component Type"    value="COMPONENTTYPE"/>
						          		<ExcelColumn label="Category"    value="CATEGORY"/>
						          		<ExcelColumn label="Component Name"     value="COMPONENTNAME"/> 
						               
						          </ExcelSheet>

						    </ExcelFile>
								<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 