import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Auth from '../../services/Auth';
import ReactDOM from "react-dom";

class SidebarNav extends Component {
	
	//define variables
  constructor(props){
    super(props);
    this.state={
      show: null,
      menu:[],
      submenuShow:""
    }
  }
	//loading page
	componentDidMount() {
	 	var token = Auth.loggedIn()
	 	
	 	var menuShow
	 	if(this.props.pageId){
	 		var pageDomain
		 	for (let i=0; i< 	token.menu.length; i++ ){
		 		if ( token.menu[i].PAGE_ID ===  parseInt(this.props.pageId) ){
		 			pageDomain =  token.menu[i].PAGE_DOMAIN
		 			i = token.menu.length
		 		}
		 	}
		 	for (let i=0; i< 	token.menu.length; i++ ){
		 		if ( token.menu[i].PAGE_URL ==="#" && token.menu[i].PAGE_DOMAIN === pageDomain ){
		 			menuShow = "menu_"+token.menu[i].PAGE_ID
		 			i = token.menu.length
		 		}
		 	}
	 	}else{
	 		for (let i=0; i< 	token.menu.length; i++ ){
		 		if ( token.menu[i].PAGE_URL ===this.props.location.pathname && !token.menu[i].PAGE_DOMAIN ){
		 			menuShow = "menu_"+token.menu[i].PAGE_ID
		 			i = token.menu.length
		 		}
		 	}
	 		
	 	}
	 	
	 	this.setState({ ...this.state, menu:token.menu,submenuShow:this.props.pageId, show: menuShow});
	}
	//leaving page	 
	componentWillUnmount=()=>{
   	
  }	 
	
  handleShow(id){
    this.setState({
        show: id === this.state.show?null:id
    })
  }

	onhandleMenu = (e) => {	
		if(this.props.callback){
			this.props.callback(this.props.pageType)	
		}
	};
  
  render() {
   
   const {  location } = this.props
   let pathname = location.pathname

   return (
    <div className="sidebar" id="sidebar">
      <div className="primary-nav">
        <nav role="navigation" className="menu">
          <Scrollbars
            style={{
              width: 250,
              height: "100%",
              backgroundColor: "rgb(121 145 165)",
            }}
            className="menu"
          >
            <Link to="/admin" className="logotype">
              LOGO<span>TYPE</span>
            </Link>
            <div className="overflow-container">
              <ul className="menu-dropdown">
                <li className="menu-title">Menu</li>
                 
	              {this.state.menu.map(menu => ( !menu.PAGE_DOMAIN?
	              	<li key={'li_menu_'+menu.PAGE_ID}  className={`${menu.PAGE_URL === pathname ? 'active' : '' }`}>
                  	<Link key={'link_menu_'+menu.PAGE_ID} to={menu.PAGE_URL} exact="true"><i className="fe fe-home"></i>{menu.PAGE_NAME}</Link>
                	</li>
                	:menu.PAGE_URL==="#"?
	              		<li key={'li_menu_'+menu.PAGE_ID} className={`menu-hasdropdown ${ this.state.show == 'menu_'+menu.PAGE_ID  ? 'active' : '' }`}>
	              			<span key={'span_menu_'+menu.PAGE_ID} className="icon"></span>
	              			<div className="level0Menu"   key={'a_menu_'+menu.PAGE_ID}   onClick={()=>this.handleShow('menu_'+menu.PAGE_ID )}>  
	              				<i key={'i_menu_'+menu.PAGE_ID} className="fe fe-document"></i>{menu.PAGE_NAME}
	              			</div>
	              			<label key={'label_menu_'+menu.PAGE_ID} title="toggle menu" htmlFor={'menu_'+menu.PAGE_ID}>
		                    <span key={'span2_menu_'+menu.PAGE_ID} className="downarrow">
		                    	<i key={'i2_menu_'+menu.PAGE_ID} className="fa fa-angle-down rotate-icon" aria-hidden="true"></i>
		                    </span>
		                  </label>
		                  <input key={'input_menu_'+menu.PAGE_ID} id={'input_menu_'+menu.PAGE_ID} type="checkbox" className="sub-menu-checkbox" />
		                  <ul key={'ul_menu_'+menu.PAGE_ID} className={`sub-menu-dropdown submenu ${ this.state.show === 'menu_'+menu.PAGE_ID ? 'active' : '' } `} >
		              			{this.state.menu.map(submenu => (submenu.PAGE_URL!=="#" &&  menu.PAGE_DOMAIN === submenu.PAGE_DOMAIN?
		              				
		              				<li key={'li_submenu_'+submenu.PAGE_ID} id={'li_submenu_'+submenu.PAGE_ID} className={this.state.submenuShow ==submenu.PAGE_ID?"active":"" }  onClick={(e) => this.onhandleMenu(e)}  >
			                      <Link key={'link_submenu_'+submenu.PAGE_ID}  id={'idsubmenu_'+submenu.PAGE_ID}  to={submenu.PAGE_URL+"/"+submenu.PAGE_ID} >{submenu.PAGE_NAME}</Link>
			                    </li>
	              					:""
	              				))
				        				}
			        				</ul>
	              		</li>	
		            	:"" 
		            ))
				        }
              </ul>
            </div>
          </Scrollbars>
        </nav>
      </div>
    </div>
  );
}
}

export default withRouter(SidebarNav);
