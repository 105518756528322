import decode from 'jwt-decode';
import md5 from "react-native-md5";

import Api from "../common/api";

class AuthService {
  login(username, password) {
    return Api.auth.login(username, md5.hex_md5(password+""))
      .then(data => {
        if (data.success) {
          this.setToken(data.token)
        }
        return data
      })
  }

  loggedIn() {
    const token = this.getToken();
    return !!token && this.getUserInfo(token);
  }

  getUserInfo(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp > Date.now() / 1000) { // Checking if token is expired. N
        return decoded;
      } else
        return null;
    } catch (err) {
      return null;
    }
  }

  setToken(token) {
    // Saves user token to localStorage
    localStorage.setItem('wfl_token', token);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('wfl_token')
  }

  logout() {
    // Clear user token and profile data from localStorage
     
    localStorage.removeItem('wfl_token');
    localStorage.removeItem('DetailDomains');
		localStorage.removeItem('wfl_def_language');
		localStorage.removeItem('wfl_languages');
		localStorage.removeItem('defaulfFolderFilesName');
		
		for (let i=1; i<100; i++){
		localStorage.removeItem('DetailDomains_'+i);	
		}
		
  }
}

const Auth = new AuthService();
export default Auth;