/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
import CompCard from '../../components/CompCard';


/*functions*/
import { filterHandler, getFilterProductsElastic, find,findObjName,formatPathName,getImagePath, validateComponent,manageComponent, getUserInfo,getComponentConfiguration,manageImage, listImages, validateRole, showWarning,encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';


import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const foldername= process.env.REACT_APP_FOLDER_BLOG
const url_front= process.env.REACT_APP_URL_FRONT_CONFIG 
const grid = 8;


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
	 
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};


const getItemStyle = (isDragging, draggableStyle) => ({
  //  // some basic styles to make the items look a bit nicer
  //  userSelect: 'none',
  //  padding: 6,
  //  margin: "0px 0px 8px 0px",
  //  fontSize: "14px",
	//	
  //  // change background colour if dragging
  //  background: isDragging ? '#07d55a' : '#b1f7a6',
	//	border: "1px solid #5ce147",
	//	borderRadius: "0.4rem",
	//	minHeight:"0.75rem",
  //  // styles we need to apply on draggables
  //  ...draggableStyle
    
		userSelect: "none",
		padding: 1,
		margin: `0 ${grid}px 0 0`,
		// change background colour if dragging
		background: isDragging ? "lightgreen" : "#fff",
		// styles we need to apply on draggables
		...draggableStyle
  
});

const getItemStyle2 = (isDragging, draggableStyle) => ({
    userSelect: "none",
		padding: 6,
		margin: `0 ${grid}px 0 0`,
		// change background colour if dragging
		background: isDragging ? "lightgreen" : "#fff",
		// styles we need to apply on draggables
		...draggableStyle
});


const getListStyle = (isDraggingOver, itemsLength) => ({
  //  background: isDraggingOver ? '#d6efff' : '#FFF',
  //  padding: grid,
  //  width: "100%",
  //  boxShadow : "0 1px 3px 0 rgba(0, 0, 0, .05)",
  //  border: "1px solid #dbdbdb",
  //  borderRadius: "4px",
  //  minHeight:"8rem",
  //  height: "100%",
  //  display:flex
    background: isDraggingOver ? "lightblue" : "lightgrey",
	  display: "flex",
	  padding: grid,
	  width: "100%",
	  minHeight: "203px"
	 // overflow: "scroll"
});

const getListStyle2	= (isDraggingOver, itemsLength) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
	  display: "flex",
	  padding: grid,
	  width: "100%",
	  minHeight: "233px"
	 // overflow: "scroll"
});


const getItemStyleP = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 6,
    margin: "0px 0px 8px 0px",
    fontSize: "14px",
		
    // change background colour if dragging
    background: isDragging ? '#07d55a' : '#b1f7a6',
		border: "1px solid #5ce147",
		borderRadius: "0.4rem",
		minHeight:"0.75rem",
    // styles we need to apply on draggables
    ...draggableStyle
});

const getItemStyleP2 = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 15,
    margin: 12,

    // change background colour if dragging
    background: isDragging ? '#07d55a' : '#d2dde3',
    border: "1px solid #0668a2",    
		borderRadius: "0.4rem",
		minHeight:"1rem",
    // styles we need to apply on draggables
    ...draggableStyle
});


const getListStyleP = isDraggingOver => ({
    background: isDraggingOver ? '#d6efff' : '#FFF',
    padding: grid,
    width: "35%",
    boxShadow : "0 1px 3px 0 rgba(0, 0, 0, .05)",
    border: "1px solid #dbdbdb",
    borderRadius: "4px",
    minHeight:"30rem", 
    height: "30rem",
		"overflow-x": "scroll"
});
const getListStyleP2	 = isDraggingOver => ({
    background: isDraggingOver ? '#d6efff' : '#FFF',    
    boxShadow : "0 1px 3px 0 rgba(0, 0, 0, .05)",
		width: "63%",
		marginLeft: "2%",
		border: "1px solid #dbdbdb",
		borderRadius: "4px",
		minHeight:"30rem",
		height: "30rem",
		"overflow-x": "scroll"
    
});

var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 
class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			date_label: date_label,
			modal1: false,
			isLoading: true,
			modalClass: "modal-full-height modal-lg",
			modalLgClass:"modal-lg",
			modalClass3:"modal-sm",
			modalIamgeClass:"modal-lg",
			filterConfig: {
				id    : { label:"Blog Id" ,  type:"TEXT"   , value:""} ,
				title : { label:"Title" ,  type:"TEXT"   , value:""} 
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"title", title:"Title",
						sorter: (a, b) => a.title.length - b.title.length },
					{ dataIndex:"type", title:"Privacy",
						sorter: (a, b) => a.type.length - b.type.length
		      },
		      { dataIndex:"objtype", title:"Type",
						sorter: (a, b) => a.objtype.length - b.objtype.length
		      },
					{ dataIndex:"status", title:"Status",
						sorter: (a, b) => a.status.length - b.status.length },
					{ dataIndex:"insertdate", title:"Date Added",
						sorter: (a, b) => a.insertdate.length - b.insertdate.length }, 
					{ dataIndex: "id", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-sm btn-info" id={record.id} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-danger" id={record.id} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
	
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			modal2:false,
			modalIcon:"fas fa-edit",
			modalTitle:"New Component",
			/*form data*/			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1
			},
			formData	: { title     : { label: "Title",   type: "TEXT",   value: ""  },
										type      : { label: "Privacy"     , type: "SELECT",   value: "PUBLIC" , values:[{text: "PUBLIC", value: "PUBLIC"},{text: "PRIVATE", value: "PRIVATE"}] },
										content   : { label: "Content",   type: "TEXTAREA",   value: ""  },
										status    : { label: "Type"     , type: "SELECT",   value: "ENABLED" , values:[{text: "ENABLED", value: "ENABLED"},{text: "DISABLED", value: "DISABLED"}] },
										products  : { label: "Catalog Products" , notRequired:true        , type: "NODEF", value: [], values: [ ] , filterValue:"" } ,
										objtype   : { label: "Blog Content Type"     , type: "SELECT",   value: "IMAGE" , values:[{text: "IMAGE", value: "IMAGE"},{text: "VIDEO", value: "VIDEO"}] },
										image     : { label: "Images" , type: "NODEF", values: [], value:[] , filterValue:""} ,
										video     : { label: "Video" , type: "NODEF",  values: [],value:[] , filterValue:""} ,
										
									},
			formData2	: { title     : { label: "Title",   type: "TEXT",   value: ""  },
										type      : { label: "Privacy"     , type: "SELECT",   value: "PUBLIC" , values:[{text: "PUBLIC", value: "PUBLIC"},{text: "PRIVATE", value: "PRIVATE"}] },
										content   : { label: "Content",   type: "TEXTAREA",   value: ""  },
										status    : { label: "Type"     , type: "SELECT",   value: "ENABLED" , values:[{text: "ENABLED", value: "ENABLED"},{text: "DISABLED", value: "DISABLED"}] },
										products     : { label: "Catalog Products" , notRequired:true        , type: "NODEF", value: [], values: [ ] , filterValue:"" } ,
										objtype      : { label: "Blog Content Type"     , type: "SELECT",   value: "IMAGE" , values:[{text: "IMAGE", value: "IMAGE"},{text: "VIDEO", value: "VIDEO"}] },
										image    : { label: "Images" , type: "NODEF", values: [], value:[] , filterValue:""} ,
										video    : { label: "Video" , type: "NODEF",  values: [],value:[] , filterValue:""} ,
										
									},
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			/*images or files*/
			initialFolder:"",
			imageManager:null,
			compManager:null,
			objectName:"",
			objImage:{
				items:[],
				selected:[]
			},
			 ObjComponentsOrig:[],
			 ObjComponents:[]
		}
	}
	
	
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	
	 	try{
	 		let resp = await callApi ( "layaoutManager","getBlogs" )
	 		//console.log(resp)
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		this.setState({originalTableData:this.state.tableData});
	 		await preloadForm ( this.state.formData )

		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
  
	/*charge data from a selected user*/
	loadFormData=async (event) => {
		
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Blog"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				
			  let loadData = await clearformData (this.state.formData)
			  //console.log("loadData",loadData)
			 	this.state.formData.products.value= []
	  		this.state.formData.image.values= []
	  		this.state.formData.video.values= []
	  		this.state.formData.objtype.value= "IMAGE"
	  		this.state.formData.products.filterValue= ""
	  		this.state.formData.products.values= []
			 	
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Blog"
				formAction="update"
				modalClass =  "modal-full-height modal-lg"
				itemId = event.target.id
			  let resp = await callApi ( "layaoutManager","getBlog",{postid: itemId} )

	  		let loadData = await mergeFormData (this.state.formData,resp.postData)
	  		 
	  		var products=[]
	  		var image=[]
	  		var video=[]
	  		resp.postData.products.map(function (item) {
	  			let obj = JSON.parse(item)
	  			products.push(obj)
	  		}); 
	  		
	  		resp.postData.images.map(function (item) {
	  			image.push({ text:formatPathName(item), value:item })
	  		}); 
	  		resp.postData.videos.map(function (item) {
	  			video.push({ text:formatPathName(item), value:item })
	  		}); 
	  		 
	  		this.state.formData.products.value= products
	  		this.state.formData.image.values= image
	  		this.state.formData.video.values= video
	  		this.state.formData.products.filterValue= ""
	  		this.state.formData.products.values= []
	  		 
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Blog"
				itemId = event.target.id
				formAction="del"
				modalClass =  ""
	  	}
	  	this.setState({...this.state,modal1:true, formData2: this.state.formData, isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}
  }
  
  //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	
  ///get value fields
  changeHandler = async(event) => {
  		let resp = await validateForm(this.state.formData, toast,event);
			this.setState({formData:resp.formData});
	} 
	
	///get filter to components
  changeFilter = async(event) => {
  	  
  	try{
			let fieldValue =  event.target.value
			var formData = 	this.state.formData
			formData["products"]["filterValue"] = fieldValue
			this.setState({formData:formData});  
			 
			if (event.key === 'Enter') {
				this.searchProducts(event)
			}
		}catch(Excep){
			console.log(">>>>>>>>",Excep)
		} 
	} 
	
  searchProducts = async(event) => {
  	 
		this.setState({  formData2: this.state.formData}); 
		var formData  = this.state.formData
		try{
			let fieldValue =  formData["products"]["filterValue"] 
			if (fieldValue.length >= 3){
				this.setState({isLoading:true});	
				var resp = await  getFilterProductsElastic ({ searchkey:fieldValue, storeid: this.state.storeId?this.state.storeId:59  })
			 
				if (resp.success){
					var products = []
					resp.rows.map(function (key) {
						products.push ( { text: formatPathName(key.productname), label:key.productname  , value: key.productid, image: key.image } )
					}); 
					formData["products"]["values"] = products
					this.setState({formData:formData, isLoading:false});	
				}else{
					formData["products"]["values"] = []
					this.setState({formData:formData, isLoading:false});	
				} 
			} 
		}catch(Excep){
			console.log(">>>>>>>>",Excep) 
		}  
	} 
	manageImage= async(event) => {
		try{
//			console.log(event.target)
			this.setState({isLoading:true});
			var data = (event.target.name).split("_")
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]				
				var selected= []
				this.state.formData[objectName].values.map(function (key) {
					selected.push (key.value)
				}); 				
				let objImage = await listImages("",objectName, selected);
				objImage["selected"]= selected			 
			 
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} 
														multipleSelect={ objImage.objectName=="image"? true :false} selectedValues={this.state.formData[objectName].values}/>
	 		 	this.setState({ imageManager:imageManager, modal2: true, objectName: objectName });
			}else if (action==="selectMultiple") {
				var objImage = await manageImage( event, this.state.objImage)
				let field = objImage.objectName
				//console.log(objImage.selected.length)
				if (objImage.selected.length >0 && objImage.selected.length<=4 ){
					var values=[]
					objImage.selected.map(function (key) {
						let _name = key.split("/")
						values.push({text:_name[_name.length-1 ], value:key, type:field }) 
					}); 
					var formData = this.state.formData
					formData[field].values = values
					this.setState({modal2:false});
				}else{
					showError(toast,"PLEASE SELECT 1 TO 4 IMAGES, (CURRENT SELETED "+(objImage.selected.length)+" ITEMS");
				}				
			}else if (action==="selectItem") {				
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} 
																				multipleSelect={ objImage.objectName=="image"? true :false} selectedValues={this.state.formData[objImage.objectName].values} />
				this.setState({imageManager:imageManager});
				
				if (objImage.selected.length===1){
					let field = objImage.objectName
					var formData = this.state.formData
					var values=[]
					let _name = objImage.selected[0].split("/")
					values.push({text:_name[_name.length-1 ], value: objImage.selected[0], type:field  })					
					formData[field].values = values
					this.setState({modal2:false});
				}				
			}else{				
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});				
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} 
																				multipleSelect={ objImage.objectName=="image"? true :false} selectedValues={this.state.formData[objImage.objectName].values} />
				this.setState({imageManager:imageManager});		
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 	
	//get drag movement
	onDragEnd = result => {
		 
    var formData = this.state.formData
    var resp=false
		var listFrom=[]
		var listTo=[]
		var fieldName=""
		this.setState({  formData2: this.state.formData});  
		try{
			fieldName = result.draggableId.split("__")[0]
			listFrom = formData[fieldName].values
			listTo   =  formData[fieldName].value
			const { source, destination } = result;
			//console.log("INPUTS", source, destination)
		  if (!destination) {
		  	//console.log("no tiene destino")
		  	formData[fieldName].values=listFrom
				formData[fieldName].value=listTo
		      resp=false;
		  }
	  	if (source.droppableId === destination.droppableId) {  
	  			//console.log("esta dentro")   
	      if (source.droppableId === 'droppable1' ||
	      		source.droppableId  === 'droppable2' ||
	      		source.droppableId  === 'droppable3' 
	      		 ) {
	      	const items = reorder(
	          listFrom,
	          source.index,
	          destination.index
	      ); 
	      	formData[fieldName].values= items
	    	}else{
	    		const items = reorder(
	          listTo,
	          source.index,
	          destination.index
	        
	     		);
	    		formData[fieldName].value= items
	    	} 
	  	} else { 
	  		//console.log("esta fuera", fieldName, source.droppableId, destination.droppableId)
	  	 	if (source.droppableId==="droppable3" && destination.droppableId =="droppable4" ){
	    	 	const result = move(
	    	 			listFrom,
	    	 			listTo,
	            source,
	            destination
	        );	       
					formData[fieldName].values=result.droppable3
					formData[fieldName].value=result.droppable4
	       
	  		}else if (source.droppableId==="droppable4" && destination.droppableId =="droppable3" ){
	        const result = move(	            
		 					listTo,listFrom,
	            source,
	            destination
	        );
					formData[fieldName].values=result.droppable3
					formData[fieldName].value=result.droppable4
	         
	  		}else{
	  			//console.log("No se mueve")
	  		}	
	  	}
		this.setState({formData:null});  
		setTimeout( async function() {
			
		 this.setState({formData:formData});  
		}.bind(this),1	)		
	  	//console.log("formData", formData)	  	
		}catch(Excep){
			console.log("Excep",Excep)
		} 
  }
  
  //get drag movement
	removeItem =(event) => {		 
		let objName =findObjName (event)
		//console.log(objName)
		let data= objName.split("__")
		let pos = find ( this.state.formData[data[0]].values, data[1], "value" );
		 
		//console.log(pos)
		if (pos>=0){
			var formData = this.state.formData
			formData[data[0]].values.splice(pos, 1)	
			this.setState({ formData:formData});
		}
  }
  
  //show/hide modal
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	    
	/*ABM manager*/
	execute=async (event,actionType) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		var _formData =  this.state.formData;
			  	//console.log(this.state.formData)
  		if(resp){
  			/*validation*/
  			if (actionType==="DELETE"){
  				
  				resp= {isValid:true}
  			}else{
  				resp = await validateForm(this.state.formData, toast);  				
  				_formData = parseFormData(_formData)  				
  			 if(resp.isValid){	
	  				_formData["images"] = []
	  				_formData["videos"] = []
	  				resp["isValid"]=false
	  				if (_formData.objtype=="IMAGE" ){
			  			if( Array.isArray(this.state.formData.image.values)){
			  				this.state.formData.image.values.map(function (key) {
									_formData["images"].push (key.value)
									resp["isValid"]=true
								}); 
			  			}else{
			  				if ( this.state.formData.image.values!=""){
			  					_formData["images"].push (this.state.formData.image.values)
			  				} 
			  			}
							
							if (_formData["images"].length<=0){
								showError(toast,"PLEASE CHOOSE AT LEAST 1 IMAGE");
							}
	  				}else{
	  					if( Array.isArray(this.state.formData.video.values)){
		  					this.state.formData.video.values.map(function (key) {
									_formData["videos"].push (key.value)
									resp["isValid"]=true
								}); 
							}else{
			  				if ( this.state.formData.video.values!=""){
			  					_formData["images"].push (this.state.formData.video.values)
			  				} 
			  			}
			  			
							if (_formData["videos"].length<=0){
								showError(toast,"PLEASE CHOOSE 1 VIDEO");
							}
	  				}
	  				/*
	  				_formData["products"]=[]
	  				this.state.formData.products.value.map(function (key) {
							_formData["products"].push (key.value)						
						});
						*/
	  				//console.log("_formData",_formData)
  				}
  			}
  			if(resp.isValid){
  				_formData["postid"] = this.state.currentId
  				 
			  	this.setState({  ...this.state, isLoading:true })
					let resp = await callApi ( "layaoutManager",this.state.formAction+"Blog", _formData )
 		  
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			 			resp = await callApi ( "layaoutManager","getBlogs" )
				 		const tableData = this.state.tableData
				 		tableData.rows = resp.rows
	 					this.setState({originalTableData:this.state.tableData});
	 					this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
		  	}
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
       <Modal open={this.state.modal2} 
      
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1060"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (2)} 
      	class={this.state.modalIamgeClass}
      />
       
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="del"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													: <>
														
															<div className=""> 
																<div className="col-12" > 
																	{!this.state.formData || this.state.isLoading ?
																		 <Form 
																				formData={this.state.formData2} 
																				manageImage={this.manageImage}
																				changeHandler={this.changeHandler} 
																				formConfig={this.state.formConfig}
																		 />
																	:
																		<Form 
																				formData={this.state.formData} 
																				manageImage={this.manageImage}
																				changeHandler={this.changeHandler} 
																				formConfig={this.state.formConfig}
																		 /> 
																	}
																</div>
															<div style={{marginLeft: 15, marginRight:15}} >
																{!this.state.formData || this.state.isLoading ?
																	
																	<>
																	{this.state.formData2.objtype.value=="IMAGE"?
																		<div> 	 
																			<div>
																				<span className="h5-responsive">Images </span>
																			</div> 
														          <div style={getListStyle(false, this.state.formData2.image.values.length)} 	> 
													                {this.state.formData2.image.values.map((item, index) => (
							                                <div  key={item.value}   draggableId={"image"+"__"+item.text+"__"+item.value}   index={index}> 
				                                        <div style={getItemStyle(  false )}> 
				                                       		<div style={{display:"flex"}}>
				                                          	<div 
					                                           	 style={{
					                                           	 		position: "relative",
																													left: 94,
																													top: 3,
																													fontSize: 17,
																													color: "#fff",
																													border: "1px solid #1890ff",
																													width: 24,
																													background:"#1890ff",
																													padding: "0px 3px 0 3px",
																													marginBottom: 6,
																													borderRadius: 3,
																													cursor: "grab"
																													
					                                           	 	}}
					                                           	 name={"video__"+item.value } >
					                                           		<i className="fas fa-arrows-alt"></i>
					                                           </div>
				                                          	
					                                           <div 
					                                           	 style={{
					                                           	 		position: "relative",
																													left: 98,
																													top: 3,
																													fontSize: 17,
																													color: "#fff",
																													background:"#e84646",
																													border: "1px solid #e84646",
																													width: 24,
																													padding: "0px 3px 0 3px",
																													marginBottom: 6,
																													borderRadius: 3,
																													cursor: "pointer"
																													
					                                           	 	}}
					                                           	 name={"image__"+item.value } onClick={this.removeItem} >
					                                           		<i className="fas fa-trash"></i>
					                                           </div>
				                                           </div>
					                                           
				                                           <img  width="150px" src={ getImagePath(item.value)} />
				                                        </div>
							                                  
							                                </div>
							                            ))} 
														      	 	</div>
														      	 	<div className="row mt-1 mb-3">
											                	<div className="col-12 text-right ">
											                		<button type="button" className="btn btn-sm btn-info" name="manageImage_image" onClick={this.manageImage}> Add New Image</button>
											                	</div>
														          </div>
														      	 	
																		</div>	
																	:""}
																	{this.state.formData2.objtype.value=="VIDEO"?
																		<div>	
																			<div>
																				<span className="h5-responsive">Videos </span>
																			</div>
																			<div  style={getListStyle2(false , this.state.formData2.video.values.length)}>
					                           		{this.state.formData2.video.values.map((item, index) => (
					                                <div  key={item.value}  draggableId={"video"+"__"+item.text+"__"+item.value} index={index}>  	
					                                	<div  style={getItemStyle2(false)}>  
					                                  	<div style={{display:"flex"}}>
						                                  	<div 
			                                           	 style={{
			                                           	 		position: "relative",
																											left: 249,
																											top: 0,
																											fontSize: 20,
																											color: "#fff",
																											background:"#1890ff",
																											border: "1px solid #1890ff",
																											width: 30,
																											padding: "0px 5px 0 5px",
																											marginBottom: 5,
																											borderRadius: 3,
																											cursor: "grab"
																											
			                                           	 	}}
			                                           	 name={"video__"+item.value } >
			                                           		<i className="fas fa-arrows-alt"></i>
			                                           </div> 
			                                           <div 
			                                           	 style={{
			                                           	 		position: "relative",
																											left: 255,
																											top: 0,
																											fontSize: 20,
																											background:"#e84646",
																											color: "#fff",
																											border: "1px solid #e84646",
																											width: 30,
																											padding: "0px 5px 0 5px",
																											marginBottom: 5,
																											borderRadius: 3,
																											cursor: "pointer"
																											
			                                           	 	}}
			                                           	 name={"video__"+item.value } onClick={this.removeItem} >
			                                           		<i className="fas fa-trash"></i>
			                                           </div>
		                                           </div>
		                                           <video style={{"width":"320px", "max-height":"240p" }}
																					    		autoplay={false}  controls="true" preload="auto">
																					    		<source src={getImagePath(item.value)}
																					    	 	type="video/mp4"/>
																				    		</video> 
			                                        </div>
					                                </div>      	 
					                              ))}
					                            </div>  
					                            <div className="row mt-1 mb-3">
											                	<div className="col-12 text-right ">
											                		<button type="button" className="btn btn-sm btn-info" name="manageImage_image" onClick={this.manageImage}> Add New Video</button>
											                	</div>
														          </div>
														      	</div>
														      	
														      :""}
														      	<div className="dndContainer" style={ {"margin-left": "0px", "margin-right": "0px"}}>
																		<>
																			<div   style={ {width: "35%"}} >
																				<span className="h5-responsive" style={ {"paddingLeft": "0px"}}>Product List </span>
																				
																				<div   className={"form-group card-label" } style={{'paddingLeft': '0px', 'width': '100', "marginTop": "-6px"}} >
																						<i onClick={ this.searchProducts}className="fas fa-search"
																						style={{
																							"position" : "relative",
																							"top"      : "19px",										 
																							"left"     : "93%",
																							"opacity"  : "0.7",
																							"cursor"    : "pointer"
																						}}
																						></i>
																						<input id={"filter_component" }
																							type="text" 
																							style={{ height: "40px", paddingTop: "0px" }}
																							value={this.state.formData2.products.filterValue+""}
																							name="product_search"
																							placeHolder={"Search products..."}
																							onChange={this.changeFilter}
																							onKeyDown={this.changeFilter}
																							 />
																				</div>
																			</div>
																			<div    style={ {width: "65%" , "paddingLeft": "2%" }}>
																				<span className="h5-responsive">Selected Products </span>
																			</div>
																		</>
																		<div style={{"width": "100%" , "padding-right": "0px", "padding-left": "0px" }} className={"form-inline"} >
																		 
											                
											                        <div 
											                            style={getListStyleP(false)}> 
											                            {this.state.formData2.products.values.map((item, index) => ( 
						                                        <div style={getItemStyleP(  false  )}>
						                                            <img  width="80px" src={ getImagePath(item.image)} />
						                                           <span >  {item.label} </span>
						                                        </div> 
											                            ))} 
											                        </div>
											                    
											                
											                        <div
											                            
											                            style={getListStyleP2(false)}>
											                            {this.state.formData2.products.value.map((item, index) => ( 
						                                        <div style={getItemStyleP2(false)}> 
					                                            <img  width="50px" src={ getImagePath(item.image)} />
					                                            <span className="h5-responsive">  {item.label} </span>
						                                        </div> 
											                            ))}    
											                        </div>
											                    
																	
																		 </div>
																		</div>
														      	
														      	
														      	
																	</>
																	
																	:
																<DragDropContext onDragEnd={this.onDragEnd}>
																	{this.state.formData.objtype.value=="IMAGE"?
																	<div> 	
																		<div>
																			<span className="h5-responsive">Images </span>
																		</div> 
													          <Droppable droppableId={"droppable1"} direction="horizontal">
													                    {(provided, snapshot) => (
													                        <div
													                            ref={provided.innerRef}
													                             style={getListStyle(snapshot.isDraggingOver, this.state.formData.image.values.length)}
              																				{...provided.droppableProps}	
              																				>
													                            {this.state.formData.image.values.map((item, index) => (
													                                <Draggable
													                                    key={item.value}
													                                    draggableId={"image"+"__"+item.text+"__"+item.value}
													                                    index={index}>
													                                    {(provided, snapshot) => (
													                                        <div
													                                            ref={provided.innerRef}
													                                            {...provided.draggableProps}
													                                            {...provided.dragHandleProps}
													                                            style={getItemStyle(
													                                                snapshot.isDragging,
													                                                provided.draggableProps.style
													                                            )}>
													                                       			
													                                       		<div style={{display:"flex"}}>
														                                          	<div 
															                                           	 style={{
															                                           	 		position: "relative",
																																							left: 94,
																																							top: 3,
																																							fontSize: 17,
																																							color: "#fff",
																																							border: "1px solid #1890ff",
																																							width: 24,
																																							background:"#1890ff",
																																							padding: "0px 3px 0 3px",
																																							marginBottom: 6,
																																							borderRadius: 3,
																																							cursor: "grab"
																																							
															                                           	 	}}
															                                           	 name={"video__"+item.value } >
															                                           		<i className="fas fa-arrows-alt"></i>
															                                           </div>
														                                          	
															                                           <div 
															                                           	 style={{
															                                           	 		position: "relative",
																																							left: 98,
																																							top: 3,
																																							fontSize: 17,
																																							color: "#fff",
																																							background:"#e84646",
																																							border: "1px solid #e84646",
																																							width: 24,
																																							padding: "0px 3px 0 3px",
																																							marginBottom: 6,
																																							borderRadius: 3,
																																							cursor: "pointer"
																																							
															                                           	 	}}
															                                           	 name={"image__"+item.value } onClick={this.removeItem} >
															                                           		<i className="fas fa-trash"></i>
															                                           </div>
														                                           </div>
														                                           
													                                           <img  width="150px" src={ getImagePath(item.value)} />
													                                        </div>
													                                    )}
													                                </Draggable>
													                            ))}
													                            {provided.placeholder}
													                        </div>
													                    )}
																		</Droppable> 
										                <div className="row mt-1 mb-3">
										                	<div className="col-12 text-right ">
										                		<button type="button" className="btn btn-sm btn-info" name="manageImage_image" onClick={this.manageImage}> Add New Image</button>
										                	</div>
													          </div>
													        </div>
													      	:""}
													      	{this.state.formData.objtype.value=="VIDEO"?
													        <div> 
										                <div>
																			<span className="h5-responsive">Videos </span>
																		</div>
										                <Droppable droppableId="droppable2" direction="horizontal">
										                    {(provided, snapshot) => (
										                        <div
										                            ref={provided.innerRef}
										                            style={getListStyle2(snapshot.isDraggingOver , this.state.formData.video.values.length)}>
										                            {this.state.formData.video.values.map((item, index) => (
										                                <Draggable
										                                    key={item.value}
										                                    draggableId={"video"+"__"+item.text+"__"+item.value}
										                                    index={index}>
										                                    {(provided, snapshot) => (
										                                    	
										                                        <div
										                                            ref={provided.innerRef}
										                                            {...provided.draggableProps}
										                                            {...provided.dragHandleProps}
										                                            style={getItemStyle2(
										                                                snapshot.isDragging,
										                                                provided.draggableProps.style
										                                            )}> 
										                                            
										                                          	<div style={{display:"flex"}}>
											                                          	<div 
												                                           	 style={{
												                                           	 		position: "relative",
																																				left: 249,
																																				top: 0,
																																				fontSize: 20,
																																				color: "#fff",
																																				background:"#1890ff",
																																				border: "1px solid #1890ff",
																																				width: 30,
																																				padding: "0px 5px 0 5px",
																																				marginBottom: 5,
																																				borderRadius: 3,
																																				cursor: "grab"
																																				
												                                           	 	}}
												                                           	 name={"video__"+item.value } >
												                                           		<i className="fas fa-arrows-alt"></i>
												                                           </div>
											                                          	
												                                           <div 
												                                           	 style={{
												                                           	 		position: "relative",
																																				left: 255,
																																				top: 0,
																																				fontSize: 20,
																																				background:"#e84646",
																																				color: "#fff",
																																				border: "1px solid #e84646",
																																				width: 30,
																																				padding: "0px 5px 0 5px",
																																				marginBottom: 5,
																																				borderRadius: 3,
																																				cursor: "pointer"
																																				
												                                           	 	}}
												                                           	 name={"video__"+item.value } onClick={this.removeItem} >
												                                           		<i className="fas fa-trash"></i>
												                                           </div>
											                                           </div>
											                                            <video style={{"width":"320px", "max-height":"240p" }}
																														    		autoplay={false}  controls="true" preload="auto">
																														    		<source src={getImagePath(item.value)}
																														    	 	type="video/mp4"/>
																													    		</video> 
												                                        </div>
										                                        	 
										                                    )}
										                                </Draggable>
										                            ))}
										                            {provided.placeholder}
										                        </div>
										                    )}
										                </Droppable>
										                <div className="row mt-1 mb-3">
										                	<div className="col-12 text-right ">
										                		<button type="button" className="btn btn-sm btn-info" name="manageImage_video" onClick={this.manageImage}> Add New Video</button>
										                	</div>
										                </div>
										              </div>
										              :""}
										              
										              <div className="dndContainer" style={ {"margin-left": "0px", "margin-right": "0px" }}>
																		<>
																			<div   style={ {width: "35%"}} >
																				<span className="h5-responsive" style={ {"paddingLeft": "0px"}}>Product List </span>
																				
																				<div   className={"form-group card-label" } style={{'paddingLeft': '0px', 'width': '100%', "marginTop": "-6px"}} >
																						<i onClick={ this.searchProducts}className="fas fa-search"
																						style={{
																							"position" : "relative",
																							"top"      : "19px",										 
																							"left"     : "93%",
																							"opacity"  : "0.7",
																							"cursor"    : "pointer"
																						}}
																						></i>
																						<input id={"filter_component" }
																							type="text" 
																							style={{ height: "40px", paddingTop: "0px" }}
																							value={this.state.formData.products.filterValue+""}
																							name="product_search"
																							placeHolder={"Search products..."}
																							onChange={this.changeFilter}
																							onKeyDown={this.changeFilter}
																							 />
																				</div>
																			</div>
																			<div    style={ {width: "65%" , "paddingLeft": "2%" }}>
																				<span className="h5-responsive">Selected Products </span>
																			</div>
																		</>
																		<div style={{"width": "100%" , "padding-right": "0px", "padding-left": "0px" }} className={"form-inline"} >
																		 
											                <Droppable droppableId={"droppable3"}>
											                    {(provided, snapshot) => (
											                        <div
											                            ref={provided.innerRef}
											                            style={getListStyleP(snapshot.isDraggingOver)}> 
											                            {this.state.formData.products.values.map((item, index) => (
											                                <Draggable 
											                                    draggableId={"products__"+item.value}
											                                    index={index}>
											                                    {(provided, snapshot) => (
											                                        <div
											                                            ref={provided.innerRef}
											                                            {...provided.draggableProps}
											                                            {...provided.dragHandleProps}
											                                            style={getItemStyleP(
											                                                snapshot.isDragging,
											                                                provided.draggableProps.style
											                                            )}>
											                                            <img  width="80px" src={ getImagePath(item.image)} />
											                                           <span >  {item.label} </span>
											                                        </div>
											                                    )}
											                                </Draggable>
											                            ))}
											                            {provided.placeholder}
											                        </div>
											                    )}
											                </Droppable> 
											                <Droppable droppableId="droppable4">
											                    {(provided, snapshot) => (
											                        <div
											                            ref={provided.innerRef}
											                            style={getListStyleP2(snapshot.isDraggingOver)}>
											                            {this.state.formData.products.value.map((item, index) => (
											                                <Draggable
											                                   
											                                    draggableId={"products__"+item.value}
											                                    index={index}>
											                                    {(provided, snapshot) => (
											                                        <div
											                                            ref={provided.innerRef}
											                                            {...provided.draggableProps}
											                                            {...provided.dragHandleProps}
											                                            style={getItemStyleP2(
											                                                snapshot.isDragging,
											                                                provided.draggableProps.style
											                                            )}>
											                                            
											                                            <img  width="50px" src={ getImagePath(item.image)} />
											                                            <span className="h5-responsive">  {item.label} </span>
											                                        </div>
											                                    )}
											                                </Draggable>
											                            ))}
											                            {provided.placeholder}
											                        </div>
											                    )}
											                </Droppable>
																	
																 </div>
																</div>
																</DragDropContext>
																}
															</div>
														</div>
													</>
												 }
							  />
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Blog Manager"} subtitle={"Blogs"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
						
							<ExcelFile filename={"export-blogs."+this.state.date_label} element={         
						          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
						      }>
						          <ExcelSheet data={this.state.tableData.rows} name="Blogs">
						              <ExcelColumn label="Title" value="title"/>
						              <ExcelColumn label="Privacy" value="type"/> 
						              <ExcelColumn label="Type" value="objtype"/> 
						              <ExcelColumn label="Status" value="status"/>
						              <ExcelColumn label="Date Added" value="insertdate"/>
						          </ExcelSheet>
					    </ExcelFile>
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    	
    	
    </>         
    )
  }
}
export default page; 