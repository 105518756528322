/*****components***/
import React, { Component } from "react";
import { Switch } from "antd";
import { toast, ToastContainer } from "mdbreact";
import Auth from "../../services/Auth";
/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from "../../components/PageLoading";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import Form from "../../components/Form";
import PageHeader from "../../components/PageHeader";
import ImageManager from "../../components/ImageManager";

import ProductList from "../../components/ProductList";
/*functions*/
import {
  manageImage,
  filterHandler,
  listImages,
  validateRole,
  encryptText,
  mergeFormData,
  clearformData,
  callApi,
  validateForm,
  showError,
  showMessage,
  preloadForm,
  parseFormData,
} from "../../common/functions";

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //show : null,
      modal1: false,
      modal2: false,
      isLoading: true,
      modalClass: "modal-full-height modal-lg",
      modalIamgeClass: "modal-lg",
      /*table filter*/
      filterConfig: {
        CUSTOMER: { label: "Customer Name", type: "TEXT", value: "" },
        ORDERID: { label: "Order Id", type: "NUMBER", value: "" },
        STATUS: { label: "Status", type: "SELECT", value: "", values: [] },
        STORE_ONLINE: { label: "Store Name", type: "SELECT", value: "", values: [] },
		SHIPPINGTYPE: { label: "Shipping Type", type: "SELECT", value: "", values: [] },
        STOREPICKUP: {
          label: "Pickup Store",
          type: "SELECT",
          value: "",
          values: [],
        },
        TOTAL: { label: "Total", type: "RANGE", value: "" },
      },
      /*table data*/
      tableData: {
        columns: [
          {
            dataIndex: "STORE",
            title: "Store",
            sorter: (a, b) => a.STORE.length - b.STORE.length,
          },

          {
            dataIndex: "STORE_ONLINE",
            title: "Store Name",
            sorter: (a, b) => a.STORE_ONLINE.length - b.STORE_ONLINE.length,
          },

          {
            dataIndex: "STOREPICKUP",
            title: "Pickup Store",
            sorter: (a, b) => a.STOREPICKUP.length - b.STOREPICKUP.length,
          },
          {
            dataIndex: "SHIPPINGTYPE",
            title: "Shipping Type",
            sorter: (a, b) => a.SHIPPINGTYPE.length - b.SHIPPINGTYPE.length,
          },

          {
            dataIndex: "CUSTOMER",
            title: "Customer",
            sorter: (a, b) =>
              b.CUSTOMER.toUpperCase() < a.CUSTOMER.toUpperCase()
                ? -1
                : b.CUSTOMER.toUpperCase() > a.CUSTOMER.toUpperCase()
                ? 1
                : 0,
          },
          {
            dataIndex: "ORDERID",
            title: "Order ID",
            sorter: (a, b) => a.ORDERID - b.ORDERID,
          },
          {
            dataIndex: "STATUS",
            title: "Status",
            sorter: (a, b) => a.STATUS.length - b.STATUS.length,
          },
          {
            dataIndex: "TOTAL",
            title: "Total",
            sorter: (a, b) => a.TOTAL.length - b.TOTAL.length,
          },

          {
            dataIndex: "TYPE",
            title: "Type",
            sorter: (a, b) => a.TYPE.length - b.TYPE.length,
          },

          {
            dataIndex: "DATEADDED",
            title: "Date Added",
            sorter: (a, b) => a.DATEADDED.length - b.DATEADDED.length,
          },
          {
            dataIndex: "ORDERID",
            title: "Action",
            render: (text, record) => (
              <>
                <button
                  className="btn btn-sm btn-info"
                  id={record.ORDERID}
                  name="view"
                  onClick={this.loadFormData}
                  type="submit"
                >
                  View
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  id={record.ORDERID}
                  name="changeStatus"
                  onClick={this.loadFormData}
                  type="submit"
                >
                  Change Status
                </button>
              </>
            ),
          },
        ],
        rows: [],
      },
      /*modal info*/
      buttonsModal1: [
        {
          buttonAction: this.set,
          buttonId: 1,
          buttonClass: "btn btn-outline-primary",
          buttonName: "Add",
        },
      ],

      modalIcon: "fas fa-edit",
      modalTitle: "New Order",
      /*form data*/
      formConfig: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 2,
      },

      formData: {
        ORDERID: {
          label: "Order Id",
          size: "col-6",
          validationType: "text",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        EMPTY1: { label: "", size: "col-6", notRequired: true, type: "EMPTY" },

        EVENTTYPE: {
          label: "Event Type",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        EVENTNAME: {
          label: "Event Name",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },

        FIRSTNAME: {
          label: "Firstname",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        LASTNAME: {
          label: "Lastname",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },

        EMAIL: {
          label: "Email",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        PHONECODE: {
          label: "Phonecode",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        PHONE: {
          label: "Phone",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },

        STATUSNAME: {
          label: "Status",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        SHIPPINGMETHOD: {
          label: "Shipping Type",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        SUBTOTAL: {
          label: "Sub Total",
          size: "col-6",
          validationType: "text",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        SHIPPINGCOST: {
          label: "Shipping Cost",
          size: "col-6",
          validationType: "text",
          type: "TEXT",
          defvalue: "0",
          value: "",
          disabled: true,
        },
        DISCOUNT: {
          label: "Discount",
          size: "col-6",
          validationType: "text",
          type: "TEXT",
          defvalue: "0",
          value: "",
          disabled: true,
        },

        TOTAL: {
          label: "Total",
          size: "col-6",
          validationType: "text",
          type: "TEXT",
          defvalue: "0",
          value: "",
          disabled: true,
        },

        DATEADDED: {
          label: "Date Added",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        DATEMODIFIED: {
          label: "Date Modified",
          size: "col-6",
          type: "TEXT",
          value: "",
          disabled: true,
        },

        HAVECOUPON: {
          label: "Coupon",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        COUPONS: {
          label: "Coupons Names",
          type: "TEXT",
          value: "",
          disabled: true,
        },
        COUPONSADMIN: {
          label: "Coupons Internal Codes",
          type: "TEXT",
          value: "",
          disabled: true,
        },
      },

      formData2: {
        STATUSID: {
          label: "Status",
          type: "SELECT",
          value: "",
          values: [],
          getDataDB: { apiMethod: "getStatusOrders", apiGroup: "manager" },
        },
      },

      formConfig2: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
      },

      /*support variables*/
      currentId: null,
      orderDetails: [],
      order: {},
      formAction: "set",
      deleteMessage: "Are you sure to delete this item?",
      /*images or files*/
      initialFolder: "",
      imageManager: null,
      objImage: {
        items: [],
        selected: [],
      },
      originalTableData: {},
      orderHistory: {
        columns: [
          {
            dataIndex: "STATUSNAME",
            title: "Status",
            sorter: (a, b) => a.STATUSNAME.length - b.STATUSNAME.length,
          },
          {
            dataIndex: "OBSERVATION",
            title: "Observation",
            sorter: (a, b) => a.OBSERVATION.length - b.OBSERVATION.length,
          },
          {
            dataIndex: "ISPUBLIC",
            title: "Is Public",
            sorter: (a, b) => a.ISPUBLIC.length - b.ISPUBLIC.length,
          },
          {
            dataIndex: "USERNAME",
            title: "Username",
            sorter: (a, b) => a.USERNAME.length - b.USERNAME.length,
          },
          {
            dataIndex: "DATE_ADDED",
            title: "Date Added",
            sorter: (a, b) => a.DATE_ADDED.length - b.DATE_ADDED.length,
          },
        ],
        rows: [],
      },
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    var pageId = this.props.match.params.pageId;
    try {
      let resp = await callApi("manager", "getOrders");
      const tableData = this.state.tableData;
      tableData.rows = resp.rows;

      this.setState({ originalTableData: this.state.tableData });
      await preloadForm(this.state.formData);
      await preloadForm(this.state.formData2);

      const filterConfig = this.state.filterConfig;
      filterConfig.STATUS.values.push("");
      this.state.formData2.STATUSID.values.map(function (item) {
        filterConfig.STATUS.values.push(item.text);
      });

      filterConfig.STORE_ONLINE.values.push("");
      filterConfig.STOREPICKUP.values.push("");
      filterConfig.SHIPPINGTYPE.values.push("");

      resp.rows.map(function (item) {
        let exists = filterConfig.STORE_ONLINE.values.some((value) =>
          value.includes(item.STORE_ONLINE)
        );
        if (!exists) {
          filterConfig.STORE_ONLINE.values.push(item.STORE_ONLINE);
        }

        let exists2 = filterConfig.STOREPICKUP.values.some((value) =>
          value.includes(item.STOREPICKUP || '')
        );
        if (!exists2) {
          filterConfig.STOREPICKUP.values.push(item.STOREPICKUP);
        }

		let exists3 = filterConfig.SHIPPINGTYPE.values.some((value) =>
			value.includes(item.SHIPPINGTYPE || '')
		  );
		  if (!exists3) {
			filterConfig.SHIPPINGTYPE.values.push(item.SHIPPINGTYPE);
		  }


      });
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ isLoading: false });
    }
  };
  /*Form Functions*/

  /*charge data from a selected user*/
  loadFormData = async (event) => {
    this.setState({ ...this.state, isLoading: true });
    try {
      var buttonsModal1;
      var modalIcon;
      var modalTitle;
      var formAction;
      var itemId;
      var modalClass;
      if (event.target.name === "set") {
        buttonsModal1 = [
          {
            buttonAction: this.set,
            buttonId: 1,
            buttonClass: "btn btn-outline-primary",
            buttonName: "Add",
          },
        ];
        modalIcon = "fas fa-plus";
        modalTitle = "New Order";
        formAction = "set";
        itemId = null;
        modalClass = "modal-full-height modal-lg";
        let loadData = await clearformData(this.state.formData);
      } else if (event.target.name === "update") {
        buttonsModal1 = [
          {
            buttonAction: this.update,
            buttonId: 1,
            buttonClass: "btn btn-outline-info",
            buttonName: "Edit",
          },
        ];
        modalIcon = "fas fa-edit";
        modalTitle = "Edit Order";
        itemId = event.target.id;
        let resp = await callApi("manager", "getOrderById", { userid: itemId });

        //  EVENTTYPE

        let loadData = await mergeFormData(this.state.formData, resp.order);
        const orderHistory = this.state.orderHistory;
        orderHistory.rows = resp.orderDetails;
        formAction = "update";
        modalClass = "modal-full-height modal-lg";
      } else if (event.target.name === "delete") {
        buttonsModal1 = [
          {
            buttonAction: this.delete,
            buttonId: 1,
            buttonClass: "btn btn-outline-danger",
            buttonName: "Delete",
          },
        ];
        modalIcon = "fas fa-trash";
        modalTitle = "Delete Order";
        itemId = event.target.id;
        formAction = "delete";
        modalClass = "";
      } else if (event.target.name === "view") {
        buttonsModal1 = [];
        modalIcon = "fas fa-search";
        modalTitle = "View Order";
        itemId = event.target.id;
        let resp = await callApi("manager", "getOrderById", {
          orderid: itemId,
        });

        let loadDataC = await clearformData(this.state.formData);

        if (resp.order.ADDRESSINFO) {
          let addInfo = JSON.parse(resp.order.ADDRESSINFO);
          if (addInfo.eventname) {
            resp.order["EVENTNAME"] = addInfo.eventname;
            resp.order["EVENTTYPE"] = addInfo.eventtype;
          } else {
            resp.order["EVENTNAME"] = "";
            resp.order["EVENTTYPE"] = "";
          }
        } else {
          resp.order["EVENTNAME"] = "";
          resp.order["EVENTTYPE"] = "";
        }

        if (resp.order.ORDERINFO) {
          let addInfo = JSON.parse(resp.order.ORDERINFO);
          if (addInfo.eventname) {
            resp.order["EVENTNAME"] = addInfo.eventname;
            resp.order["EVENTTYPE"] = addInfo.EVENTTYPE;
          }
          resp.order["EMAIL"] = "NA";
          resp.order["PHONECODE"] = "NA";
          resp.order["PHONE"] = "NA";
          resp.order["SHIPPINGMETHOD"] = "NA";
          resp.order["HAVECOUPON"] = "NO";
        }

        //console.log(resp.order)

        let loadData = await mergeFormData(this.state.formData, resp.order);
        const orderHistory = this.state.orderHistory;
        orderHistory.rows = resp.orderHistory;
        formAction = "view";
        modalClass = "modal-full-height modal-lg";
        this.setState({
          ...this.state,
          orderDetails: resp.orderDetails,
          order: resp.order,
        });
      } else if (event.target.name === "changeStatus") {
        buttonsModal1 = [
          {
            buttonAction: this.changeStatus,
            buttonId: 1,
            buttonClass: "btn btn-outline-danger",
            buttonName: "Change Status",
          },
        ];
        modalIcon = "fas fa-trash";
        modalTitle = "Change Status";
        itemId = event.target.id;
        formAction = "changeStatus";
        modalClass = "";
        itemId = event.target.id;
      }
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({
        ...this.state,
        modal1: true,
        isLoading: false,
        currentId: itemId,
        modalClass,
        formAction: formAction,
        buttonsModal1: buttonsModal1,
        modalIcon: modalIcon,
        modalTitle: modalTitle,
      });
    }
  };

  ///filter table
  filterHandler = async (event) => {
    let resp = await filterHandler(
      event,
      this.state.filterConfig,
      this.state.originalTableData
    );
    if (resp.filterConfig) {
      this.setState({ filterConfig: resp.filterConfig });
    }
    if (resp.tableData) {
      this.setState({ tableData: resp.tableData });
    }
  };

  ///get value fields
  changeHandler = async (event) => {
    let resp = await validateForm(this.state.formData, toast, event);
    this.setState({ formData: resp.formData });
  };

  changeHandler2 = async (event) => {
    let resp = await validateForm(this.state.formData2, toast, event);
    this.setState({ formData: resp.formData });
  };

  //show/hide modal
  toggleModal = (nr) => () => {
    this.setState({ ["modal" + nr]: !this.state["modal" + nr] });
  };
  //add new
  set = async (event) => {
    this.execute(event, "SET");
  };
  //edit
  update = async (event) => {
    this.execute(event, "UPDATE");
  };
  //delete
  delete = async (event) => {
    this.execute(event, "DELETE");
  };

  changeStatus = async (event) => {
    this.execute(event, "CHANGE");
  };

  //manage image
  manageImage = async (event) => {
    try {
      this.setState({ isLoading: true });
      var data = event.target.name.split("_");
      let action = data[0];
      if (action === "manageImage") {
        let objectName = data[1];
        let objImage = await listImages("", objectName);
        this.setState({ objImage: objImage });
        let imageManager = (
          <ImageManager
            objImage={this.state.objImage}
            manageImage={this.manageImage}
          />
        );
        this.setState({ imageManager: imageManager, modal2: true });
      } else {
        var objImage = await manageImage(event, this.state.objImage);
        this.setState({ objImage: objImage });
        let imageManager = (
          <ImageManager
            objImage={this.state.objImage}
            manageImage={this.manageImage}
          />
        );
        this.setState({ imageManager: imageManager });
        if (objImage.selected.length === 1) {
          let field = objImage.objectName;
          var formData = this.state.formData;
          formData[field].value = objImage.selected[0];
          this.setState({ modal2: false });
        }
      }
    } catch (err) {
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  /*ABM manager*/
  execute = async (event, actionType) => {
    try {
      var resp = validateRole(this.props.location.pathname);
      if (resp) {
        /*validation*/
        if (actionType === "DELETE") {
          resp = { isValid: true };
        } else if (actionType === "CHANGE") {
          resp = await validateForm(this.state.formData2, toast);
        }
        if (resp.isValid) {
          this.setState({ ...this.state, isLoading: true });
          var _formData;
          if (actionType === "CHANGE") {
            _formData = this.state.formData2;
            _formData = parseFormData(_formData);
            _formData["id"] = _formData.statusid;

            var token = Auth.loggedIn();
            //console.log("token",token)
            _formData["username"] = token.username;
          } else {
            _formData = this.state.formData;
            _formData = parseFormData(_formData);
          }
          _formData["orderid"] = this.state.currentId;

          /*aditional validation*/
          //console.log("_formData",_formData)
          let resp = await callApi(
            "manager",
            this.state.formAction + "Orders",
            _formData
          );
          if (resp.success) {
            showMessage(toast, resp.message ? resp.message : resp.resp);
            resp = await callApi("manager", "getOrders");
            const tableData = this.state.tableData;
            tableData.rows = resp.rows;
            this.setState({ originalTableData: this.state.tableData });
            this.filterHandler({ target: { name: "search" } });
            this.setState({ ...this.state, modal1: false });
          } else {
            showError(toast, resp.message);
          }
        }
      } else {
        showError(toast, "Role not allowed to make changes");
      }
    } catch (err) {
      showError(toast, "Internal Error");
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  render() {
    return (
      <>
        <SidebarNav pageId={this.props.match.params.pageId} />
        <PageLoading isLoading={this.state.isLoading} />

        <Modal
          open={this.state.modal2}
          icon="fas fa-images"
          title="Image Manager"
          zindex="1060"
          message={this.state.imageManager}
          toggle={this.toggleModal(2)}
          class={this.state.modalIamgeClass}
        />
        <Modal
          open={this.state.modal1}
          toggle={this.toggleModal(1)}
          class={this.state.modalClass}
          icon={this.state.modalIcon}
          title={this.state.modalTitle}
          buttons={this.state.buttonsModal1}
          cancelButton={true}
          message={
            this.state.formAction === "changeStatus" ? (
              <Form
                formData={this.state.formData2}
                formConfig={this.state.formConfig2}
                changeHandler={this.changeHandler2}
              />
            ) : (
              <>
                <Form
                  formData={this.state.formData}
                  manageImage={this.manageImage}
                  changeHandler={this.changeHandler}
                  formConfig={this.state.formConfig}
                />
                <div className="card">
                  <div className="col-12">
                    <Table
                      tableData={this.state.orderHistory}
                      noPagination={true}
                    />
                  </div>
                </div>
                <ProductList
                  orderDetails={this.state.orderDetails}
                  order={this.state.order}
                />
              </>
            )
          }
        />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <PageHeader title={"Sales"} subtitle={"Orders"} />

            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="card card-table flex-fill">
                  <Table
                    tableData={this.state.tableData}
                    filterConfig={this.state.filterConfig}
                    filterHandler={this.filterHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
