import React, { Component } from "react";
import { MDBPopover, MDBPopoverBody, MDBBtn, MDBContainer } from "mdbreact";
import Folder from "../../assets/images/folder.png";
import File from "../../assets/images/file.png";
import {validateNumber, findValueById,getLanguage, getImagePath,getDefValues,getCoordinatesStore } from '../../common/functions';

const foldername= process.env.REACT_APP_FOLDER_LAYOUT

const windowHeight = window.innerHeight
		
const addRow = (props,  ini, numcol) => {
	let row=[]
	
	for (let key=ini; key< ini+ numcol ; key++){
		if(props.objFolder.items[key]){
			row.push( 
					<div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 flex items-center">
						<div style={{width: "100%"}}>
							<p className="folder-title"
							style={{
							
							}}>
								<div 
										key={"label-title-"+props.objFolder.items[key].item} 
										name={"selectItem_"+props.objFolder.items[key].item} 
										onClick={props.manageFolder} >
											{props.objFolder.items[key].label }
								</div>
							</p>
							<div>
								<p style={{textAlign: 'center' , marginBottom: 0}}>
									<img 
										key={"img"+props.objFolder.items[key].item} 
										name={"selectItem_"+props.objFolder.items[key].item} 
										onClick={props.manageFolder} 
										style={{"cursor": "pointer", maxWidth: 150}}
										/*className="mr-1 mt-2" */
										width="100%" 
										src={  props.objFolder.items[key].type==="folder"?Folder: File } alt={props.objFolder.items[key].name} />
								</p>
							</div>
							<p>
								{props.objFolder.items[key].type==="folder"?
									<div className="flex items-center">
										<button
	          	 	 			name={"selectFolder_"+props.objFolder.items[key].item} 
	          	 	 			value = {props.objFolder.items[key].name} 
	          	 	 			key={"check_"+props.objFolder.items[key].item} 
	          	 	 			className="btn-outline-primary px-1 btn btn-block small-text"
	          	 	 			style={{maxWidth: 150}}
	          		 			type="button" 
	          		 			onClick={props.manageFolder}>
	          		 			<i className="far fa-check-square mr-2" />
	          		 			<b >CHOOSE</b>
	          		 		 </button>
									</div>
								:
									<span> {props.objFolder.items[key].label} </span>
								}
							 </p>
						</div>
				</div>
			)
		}
			
	}
	
	return row
}

const loadFolderManager = props => {
	
	console.log(foldername)
	if (props.objFolder.items){	
		const formStructure=[]
		for (let key=0; key < props.objFolder.items.length; key = key+6){
			var row = addRow(props, key, 6 )
			formStructure.push(  
								<div key={"row_"+key} className="row ml-3"> 
									{row}
								</div>)
		}
		
		

	  return (
	  <>
	  	<section className="comp-section">
        <div className="comp-header">
          <span className="h3-responsive ">  </span>
          
        <div className="card">
          <div className="card-body">
           <div className="row">
	          	<div className="col-sm-12">
           			{props.objFolder.route}
	          	</div>
						</div>
	          <div className="row">
	          	<div className="col-sm-12">
	          		{foldername==props.objFolder.route?
	          			<button type="submit" name="getParent" disabled className="btn-secondary btn btn-sm"> <i className="fas fa-level-up-alt" /></button>
	          		:	<button type="submit" name="getParent"    className="btn-secondary btn btn-sm" onClick={props.manageFolder}> <i className="fas fa-level-up-alt" /></button>
	          		}
	          		<button type="submit" name="refresh"      className="btn-info  btn btn-sm"  onClick={props.manageFolder}> <i className="fas fa-sync-alt" /></button>
	          	 
	        			<button type="submit" name="showFolderName" className="btn-primary btn btn-sm" onClick={props.manageFolder}> <i className="fas fa-folder-plus" /></button>  		
	          		{props.objFolder.showFolderName?
		          		 <div className="popover fade show bs-popover-bottom" placement="top" style={{ "top": "26px", "left": "47px" }}>
					          <div>
					            <div className="popover-body">
					            	<div key={"divfolderName"} className={"form-group card-label"} >
													
													<div className="form-group">
                          	<label>New Folder</label>
                          	<div className="input-group mb-3">
                            	<input  key={"inputfolderName"} type="text" className={"form-control"}
																value={props.objFolder.newFolderValue+""}
																name="newFolderValue"
																onChange={props.manageFolder} />
															<button disabled={ props.objFolder.newFolderValue==""?true:false } type="submit" name="createFolder"   className="btn-secondary btn btn-sm"      onClick={props.manageFolder}> <i className="fas fa-folder-plus" /></button>
														</div>
                          </div>
													
												</div>
												
						           </div>
						         </div>
						       </div>
	          		:""}
	          		
	          		
	          		{props.multipleSelect?
	          			<button type="submit" name="selectMultiple" className="btn-info btn btn-sm" onClick={props.manageFolder}> <i className="far fa-check-circle" /> Select Files </button>
	          		:""}
	          	</div>
	         		<div className="line mt-2 ml-3" />
	        		</div>
						</div>
	         
								{formStructure}
				
	          
	          
            <div className="row text-right mt-2 mb-2">
            	<div className="col-12 col-sm-12 flex items-right">
	              <ul className="pagination pagination-sm mb-0 mr-2">
	                <li className="page-item disabled">
	                  <a className="page-link" href="#" tabIndex={-1}>Previous</a>
	                </li>
	               {/* <li className="page-item"><a className="page-link" href="#">1</a></li> */}
	                <li className="page-item active"><a className="page-link" href="#">1 <span className="sr-only">(current)</span></a></li>
	               {/* <li className="page-item"><a className="page-link" href="#">3</a></li> */}
	                <li className="page-item disabled">
	                  <a className="page-link" href="#" >Next</a>
	                </li>
	              </ul>
              </div>
            </div>
            
          </div>
        </div>
      </section>
			
		</>
	  );
	}else{
		return "NO ITEMS DATA"
	}
};

export default loadFolderManager;
				 