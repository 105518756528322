import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SidebarNav from "../sidebar";
import Card from '../../components/Card';

class Dashboard extends Component{
	
	//define variables
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			isLoading: true,
			
			settings : {
      	dots: true,
      	infinite: true,
      	speed: 500,
      	slidesToShow: 1,
      	slidesToScroll: 1,
      	autoplay: true,
     // 	speed: 3000,
     // 	autoplaySpeed: 3000,
      	cssEase: "linear",
      	pauseOnHover: true
    	},
			
			cardConfig:{
				pageWidth: "col-md-12 col-lg-12 col-xl-12",
				groupingRows: 3
			},
			cardData	: {
					card1  : { header: "Protocolos"  , 
										 cardWidth: "col-12 col-md-6 col-lg-4",
										 body: 
											[ {type:"text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
											  {type: "link", target:"_blank", url:"www.google.com", text:"version 1"},
											  {type: "link", target:"_blank", url:"www.google.com", text:"version 2"}
											],
					},
					card2  : { header: "Reglamentos Internos de los fondos de inversion"  , 
										 cardWidth: "col-12 col-md-6 col-lg-4",
										 body: 
													[ {type: "text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
													  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 1"},
													  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 2"},
													  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 3"}
													]
					},
					card3  : { 
										 image:{ source:"https://www.fortalezasafi.com/wp-content/uploads/2020/04/FB-LKDN-TWITTER-MURO-380x215.jpg", alt:"imagen"},
										 cardWidth: "col-12 col-md-6 col-lg-4",
										 body: 
													[ {type: "text", content: "Nuevas agencias"}, 
													  {type: "button", target:"_blank", url:"www.google.com", text:"Revisar", buttonclass:"btn-outline-primary"}
													]
					},
					card4  : { header: "Protocolos 2"  , 
										 cardWidth: "col-12 col-md-6 col-lg-4",
										 body: 
											[ {type:"text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
											  {type: "link", target:"_blank", url:"http://www.google.com", text:"version 1"},
											  {type: "link", target:"_blank", url:"http://www.google.com", text:"version 2"}
											],
					},
					card5  : { header: "Reglamentos Internos 2"  , 
										 cardWidth: "col-12 col-md-6 col-lg-4",
										 body: 
													[ {type: "text", text: "Some quick example text to build on the card title and make up the bulk of the card's content."}, 
													  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 1"},
													  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 2"},
													  {type: "link", target:"_blank", url:"http://www.google.com", text:"Reglamentos 3"}
													]
					},
			}
		};
	}
  render(){
      return(
        <>
          <SidebarNav pageId={this.props.match.params.pageId} />
          <div className="page-wrapper">
						<div className="content container-fluid">
							<Card 
								cardData={this.state.cardData} 
								cardConfig={this.state.cardConfig}
							/>
						</div>		
					</div>
				</>         
      )
  }
}
  
 export default Dashboard; 