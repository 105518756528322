import React, { Component } from "react";
import defaultImage from "../../assets/images/default.jpg";
import Form from '../../components/Form';
import Select from 'react-select';
import { components } from 'react-select';
const { SingleValue, Option } = components;


const windowHeight = window.innerHeight

const values = [
    {
        label: 'Option 1',
        value: 1,

        image: 'https://cidev-media.s3.amazonaws.com/ci_colors_variants/Multicolor_214.png',
    },
    {
        label: 'Option 2',
        value: 2,
        image: 'https://cidev-media.s3.amazonaws.com/ci_colors_variants/Multicolor_218.png',
    }
];

const IconSingleValue = (props) => (
    <SingleValue {...props}>
        <img src={props.data.image} style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }}/>
        {props.data.label}
    </SingleValue>
);

const IconOption = (props) => (
    <Option {...props}>
        <img src={props.data.image} style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }}/>
        {props.data.label}
    </Option>
);

const customStyles = {
    option: (provided) => ({
        ...provided,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }),
    singleValue: (provided) => ({
        ...provided,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }),
}

const loadOption = props => {
	if (props.options && props.objOptions){
	  return (
	  	 
			<div className="row">
				<div className="col-md-12 col-lg-12">
				{props.objOptions.length>1 && props.defaultData?
					<div className="row">
						<Form 
								formData={{
									optionnames         : { label: "Name"         , size: "col-12 "           , sizesm: "col-sm-12", sizemd: "col-md-12", sizelg: "col-lg-12"  , type: "CompLanguage", values: props.defaultData.optionnames },
									optionprice         : { label: "Price"        , size: "col-6 flex middle" , sizesm: "col-sm-6" , sizemd: "col-md-6" , sizelg: "col-lg-3"   , type: "TEXT",         value: props.defaultData.optionprice } ,
									optionimages        : { label: "Images Path"  , size: "col-6 flex middle" , sizesm: "col-sm-6" , sizemd: "col-md-6" , sizelg: "col-lg-3"   , type: "TEXT",         value: props.defaultData.optionimages ,  notRequired:true },
									imagesquantity      : { label: "Total Images"  , size: "col-6 flex middle" , sizesm: "col-sm-6" , sizemd: "col-md-6" , sizelg: "col-lg-3"   , type: "TEXT",         value: props.defaultData.imagesquantity } ,
									optionquantity      : { label: "Clonable"     , size: "col-6 flex middle" , sizesm: "col-sm-6" , sizemd: "col-md-6" , sizelg: "col-lg-3"   , type: "RADIO",       value: props.defaultData.optionquantity ,  values: [{text: "NO", value: "0"},{text: "YES", value: "1"} ]  } ,
								}}
								changeHandler={props.changeHandler} 
								container = {"objOptions_master"}
								formConfig={{
									formalign: "flex items-left",
									groupingRows: 2, 
									colsize: "col-12 item-master-container",
									colsizelg: "col-sm-9",
									formtitle:"Default Values"
								}}
						/>
					</div>
			  :""}
			  	
			  	{props.objOptions.map(itemM => (
			  	<div className="row item-option-container"  >
						<div key={"div_obj_"} className="col-sm-12 col-12 col-lg-3 grid">
							<div 
							style={{
								"color": "#959595",
								"font-weight": "bold",
								"marginTop": "5px",
								"marginBottom": "-6px"
							}}> Variants </div>
			  			{ Object.keys(itemM).map( (opt, index) => (opt.indexOf("optionvalues") >=0 ?
								
									<div key={"div_obj_l"+index} 
										name={props.container	+"_optionoptionid_"+itemM.rowId+"_"+ opt}  >
										<label
											style={{ 
													color: '#959595',
													fontSize: 14,
													fontWeight: 500,
													margin: '6px auto auto 8px'
											}}
											key={"div_obj_"+index}  >{itemM[opt][0].optionLabel}  </label>
										
										{itemM[opt][0].haveImage? 
											<Select
												
												defaultValue={
													itemM[opt].filter(option => 
          								option.value.toUpperCase() === itemM["optionvalue_"+itemM[opt][0].optionId].toUpperCase()  )
													}
												styles={customStyles} 
												className={ itemM["isInvalid_optionoptionid_"+ itemM[opt][0].optionId ] ? "invalid-react-select": " valid " } 
								        onChange={props.changeHandler} 
								        components={{SingleValue: IconSingleValue, Option: IconOption }}
								        options={itemM[opt]}
								   	 />
										:
									 		<select name={props.container	+"_optionoptionid_"+itemM.rowId+"_"+itemM[opt][0].optionId}
										 		onChange={props.changeHandler} 
										 		defaultValue={itemM["optionvalue_"+itemM[opt][0].optionId] }
										 		className={"form-control "+ (itemM["isInvalid_optionoptionid_"+ itemM[opt][0].optionId ] ?"is-invalid":"")}
										 	>
				               			<option key={"div_obj_l_"+index+"_"}  value={""}>{"NONE"}</option> 
				              			{itemM[opt].map( (item, index2) => ( 
				              		 			<option key={"div_obj_l_"+index+"_"+index2}  value={item.value}>{item.value }</option>
				              		 	))
				                  	} 
				      				</select>
										}
										
										
									</div>
							
								:""	
							))
							} 
						</div> 	
				
							<div className={"flex middle col-sm-12 col-12 col-lg-9"} >
								<Form 
										formData={{
											optionnames         : { label: "Specific Name"       , size: "col-12 " , sizesm: "col-sm-12", sizemd: "col-md-12", sizelg: "col-lg-12"  , type: "CompLanguage",   values: itemM.optionnames },
											optionsku           : { label: "SKU"                 , size: "col-8 flex middle" , sizesm: "col-sm-8", sizemd: "col-md-4", sizelg: "col-lg-3" , type: "TEXT",    value: itemM.optionsku } ,
											optionprice         : { label: "Price"               , size: "col-4 flex middle" , sizesm: "col-sm-4", sizemd: "col-md-4", sizelg: "col-lg-2" , type: "TEXT",    value: itemM.optionprice } ,
											optionimages        : { label: "Images Path"         , size: "col-8 flex middle" , sizesm: "col-sm-8", sizemd: "col-md-6", sizelg: "col-lg-3" , type: "TEXT",    value: itemM.optionimages ,  notRequired:true },
											imagesquantity      : { label: "Total Images"        , size: "col-4 flex middle" , sizesm: "col-sm-4", sizemd: "col-md-4", sizelg: "col-lg-2" , type: "TEXT",    value: itemM.imagesquantity } ,
											optionquantity      : { label: "Clonable"            , size: "col-4 flex middle" , sizesm: "col-sm-4", sizemd: "col-md-4", sizelg: "col-lg-2" , type: "RADIO", value: itemM.optionquantity ,  values: [{text: "NO", value: "0"},{text: "YES", value: "1"} ]  } ,
										}}
										changeHandler={props.changeHandler} 
										container = {"objOptions_"+itemM.rowId}
										formConfig={{
											pageWidth: "card col-12",
											groupingRows: 2, 
										}}
									/>
							</div>
							<div className={"col-12 flex items-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={props.container+"_"+itemM.rowId} onClick={props.delRow}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							</div>
							<div className="or-line"></div>
						</div>
					))
					}
							
					{props.addRow?
						<div className="row mt-3">
					 
							
							<div className={"col-12 flex items-right"}> <button  className="btn btn-info btn-sm"  name={props.container}
				         onClick={props.addRow} type="button"> <i className="fas fa-plus"></i> </button>
				    	</div>
				    	
						</div>	
		    	:""}
			    	
				</div>
			</div>   
	  );
	}else{
		return "NO Option DATA"
	}
};
export default loadOption;
				 