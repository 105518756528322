import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";
import {getImagePath } from '../../common/functions';
import { SketchPicker, SwatchesPicker  } from 'react-color';

const windowHeight = window.innerHeight

const formtypes =[
{"value": "INPUT"    , "text": "Input"},
{"value": "SELECT"   , "text": "Select"},
{"value": "CHECKBOX" , "text": "CheckBox"},
{"value": "RADIO"    , "text": "Radio"},
{"value": "ITEMS"	   , "text": "Items"},
{"value": "BUTTONITEMS"	   , "text": "Items (mini)"},
{"value": "PASSWORD" , "text": "Paswword"},
{"value": "TEXTAREA" , "text": "Input Area"},
{"value": "DATEPICKER" , "text": "Date picker"},
{"value": "DATETIMEPICKER" , "text": "Date Time picker"},
{"value": "HIDDEN"	 , "text": "Hidden"},
{"value": "BUTTON"	 , "text": "Button"},
{"value": "LABEL"	   , "text": "Label"},
{"value": "LINK"	   , "text": "Link"},
{"value": "UPLOADIMAGE"	 , "text": "Upload Image"},
{"value": "GMAP" 	   , "text": "Google Map"}
] 

const formValids = [
{"text": "NOTREQUIRED", "value": "NOT REQUIRED"},
{"text": "text", "value": "text"},
{"text": "number", "value": "number"},
{"text": "email", "value": "email"},
{"text": "password", "value": "password"}
]
const cols =[1,2,3,4,5,6,7,8,9,10,11,12]


const loadCompHeaderItems = props => {
	console.log(props.type)
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" style={{ "maxHeight": (windowHeight-240)+"px" , "overflow":"auto"  } }>
								
				  {props.data.map(itemM => (
				  	<>
							<div style={{ border : (itemM.showMore?"2px solid #007bff": "2px solid #dbdbdb")  }} className="row mb-1 mt-2 form-component-container">
								<div className="col-md-6 col-sm-6 col-lg-1 col-xl-1">
							 		<div className="form-group card-label">
							 			<img 
											key={"img"+itemM.rowId} 
											name={"manageImage_"+itemM.rowId} 
											onClick={props.manageImage} 
											style={{"cursor": "pointer"}}
											width="45px" height="45px" 
											src={ itemM.image==""?defaultImage:getImagePath(itemM.image)} />
							  	</div>	
							  </div>			
							  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
						  		<div className="form-group card-label">
						  			<label >Image Alt </label>	
						 				<input key={props.container+"_imagealt_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_imagealt?"is-invalid":"")}
											value={itemM.imagealt}
											name={"FIELDVALUE_"+props.container+"_imagealt_"+itemM.rowId}
											onChange={props.manageComponent} />
							  	</div>		 	
							  </div>
							  <div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
						  		<div className="form-group card-label">
						  			<label >Sort Order </label>	
						 				<input key={props.container+"_sortorder_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_sortorder?"is-invalid":"")}
											value={itemM.sortorder}
											name={"FIELDVALUE_"+props.container+"_sortorder_"+itemM.rowId}
											onChange={props.manageComponent} />
							  	</div>		 	
						  	</div>	
						  	{props.type=="footer"?
						  		
						  		<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
							  		<div className="form-group card-label">
							  			<label >Title </label>	
							 				<input key={props.container+"_title_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_title?"is-invalid":"")}
												value={itemM.title}
												name={"FIELDVALUE_"+props.container+"_title_"+itemM.rowId}
												onChange={props.manageComponent} />
								  	</div>		 	
							  	</div>	
							  		
						  	:""}
						  	
						  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
						  		<div className="form-group card-label">
						  			<label >Link  </label>
						 				<input key={props.container+"_link_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_link?"is-invalid":"")}
											value={itemM.link}
											name={"FIELDVALUE_"+props.container+"_link_"+itemM.rowId}
											onChange={props.manageComponent} />
							  	</div>
							  </div>
							  {props.type=="header"?
							  	<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}>
							  		<div className="form-group card-label">
								 			<label>Container Width   </label>
							 				<input key={props.container+"_width_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_width?"is-invalid":"")}
												value={itemM.width}
												name={"FIELDVALUE_"+props.container+"_width_"+itemM.rowId}
												onChange={props.manageComponent} />
										</div>
							  	</div>
							 	:""}
								{itemM.showMore? 
						  		<>			  	
						  		
						  		<div className="text-right col-md-6 col-sm-6 col-lg-2 col-xl-2">
							  		<div className="mt-3">
							  			<button  className="btn btn-secondary btn-sm btn-rounded"  name={"TOGGLEROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
								       type="button"> <i className={ (itemM.showMore?"fas fa-compress-arrows-alt":"fas fa-expand-arrows-alt") } ></i> </button>
											<button  className="btn btn-danger btn-sm btn-rounded"  name={"DELROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
								       type="button"> <i className="fas fa-trash"  ></i> </button>
									 	</div>
									</div>
									{props.type=="footer"?
										<>
										<div className="col-md-6 col-sm-6 col-lg-1 col-xl-1">
									 		<div className="form-group card-label">
									  	</div>	
									  </div>			
							  		<div className="col-md-6 col-sm-6 col-lg-1 col-xl-1">
									 		<div className="form-group card-label">
									  	</div>	
									  </div>
							  		<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >Image Width </label>	
								 				<input key={props.container+"_imagewidth_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_imagewidth?"is-invalid":"")}
													value={itemM.imagewidth}
													name={"FIELDVALUE_"+props.container+"_imagewidth_"+itemM.rowId}
													onChange={props.manageComponent} />
									  	</div>		 	
								  	</div>	
								  	
							  		<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >Subtitle </label>	
								 				<input key={props.container+"_subtitle_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_subtitle?"is-invalid":"")}
													value={itemM.subtitle}
													name={"FIELDVALUE_"+props.container+"_subtitle_"+itemM.rowId}
													onChange={props.manageComponent} />
									  	</div>		 	
								  	</div>	
								  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >Font Size(px) </label>	
								 				<input key={props.container+"_fontsize_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_fontsize?"is-invalid":"")}
													value={itemM.fontsize}
													name={"FIELDVALUE_"+props.container+"_fontsize_"+itemM.rowId}
													onChange={props.manageComponent} />
									  	</div>		 	
								  	</div>	
								  	
								  	<div className={"col-md-4 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >View Type </label>	
								 				<select  name={"FIELDVALUE_"+props.container	+"_view_"+itemM.rowId} 
								 				defaultValue={itemM.view?itemM.view:""} 
								 				onChange={props.manageComponent} className={"form-control form-control-sm" }>
													<option value="">Web/Mobile</option>
													<option value="hidemobile">Only Web</option>
								        	<option value="hideweb">Only Mobile</option>
												</select>
									  	</div>		 	
								  	</div>	
								  	<div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
									 		<div className="form-group card-label">
									  	</div>	
									  </div>
									  <div className="col-md-6 col-sm-6 col-lg-1 col-xl-1">
									 		<div className="form-group card-label">
									  	</div>	
									  </div>
								  	<div className={"col-md-4 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >Width(Mobile) </label>	
								 				<select  name={"FIELDVALUE_"+props.container	+"_size_"+itemM.rowId} defaultValue={itemM.size?itemM.size:"col-12"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
													<option value="col-2">16%</option>
								        	<option value="col-2_5">20%</option>
								        	<option value="col-4">33%</option>
								        	<option value="col-5">40%</option>
								        	<option value="col-6">50%</option>
								        	<option value="col-12">100%</option>
												</select>
									  	</div>		 	
								  	</div>	
								  	
								  	<div className={"col-md-4 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >Width(sm) </label>	
								 				<select  name={"FIELDVALUE_"+props.container	+"_sizesm_"+itemM.rowId} defaultValue={itemM.sizesm?itemM.sizesm:"col-sm-12"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
													<option value="col-sm-2">16%</option>
								        	<option value="col-sm-2_5">20%</option>
								        	<option value="col-sm-4">33%</option>
								        	<option value="col-sm-5">40%</option>
								        	<option value="col-sm-6">50%</option>
								        	<option value="col-sm-12">100%</option>
												</select>
									  	</div>		 	
								  	</div>	
								  	<div className={"col-md-4 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >Width(md) </label>	
								 				<select  name={"FIELDVALUE_"+props.container	+"_sizemd_"+itemM.rowId} defaultValue={itemM.sizemd?itemM.sizemd:"col-md-6"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
													<option value="col-md-2">16%</option>
								        	<option value="col-md-2_5">20%</option>
								        	<option value="col-md-4">33%</option>
								        	<option value="col-md-5">40%</option>
								        	<option value="col-md-6">50%</option>
								        	<option value="col-md-12">100%</option>
												</select>
									  	</div>		 	
								  	</div>	
								  	<div className={"col-md-4 col-sm-6 col-lg-2 col-xl-2"}>
								  		<div className="form-group card-label">
								  			<label >Width(lg) </label>	
								 				<select  name={"FIELDVALUE_"+props.container	+"_sizelg_"+itemM.rowId} defaultValue={itemM.sizelg?itemM.sizelg:"col-lg-4"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
													<option value="col-lg-2">16%</option>
								        	<option value="col-lg-2_5">20%</option>
								        	<option value="col-lg-4">33%</option>
								        	<option value="col-lg-5">40%</option>
								        	<option value="col-lg-6">50%</option>
								        	<option value="col-lg-12">100%</option>
												</select>
									  	</div>		 	
								  	</div>	
								  	<div className="col-md-6 col-sm-6 col-lg-3 col-xl-3">
									 		<div className="form-group card-label">
									  	</div>	
									  </div>
								  	<div className="col-md-6 col-sm-6 col-lg-1 col-xl-1">
									 		<div className="form-group card-label">
									  	</div>	
									  </div>
								  	</>
						  		:""}
									{props.type=="header"?
										<>
										  <div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}>
									  		<div name={"FIELDVALUE_"+props.container+"_background_"+itemM.rowId} 
									  			className="form-group card-label">
									  			<label>Background Container</label>
									  			<SketchPicker  
				 										className={"FIELDVALUE_"+props.container+"_background_"+itemM.rowId}
				 										name={"FIELDVALUE_"+props.container+"_background_"+itemM.rowId}
				 										id={"FIELDVALUE_"+props.container+"_background_"+itemM.rowId}
										        color={ itemM.background?itemM.background:"#FFFFF" }
										        onChange={ props.manageComponent}
										      />
										  	</div>		
										  </div>
										  <div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}>
									  		<div name={"FIELDVALUE_"+props.container+"_bordercolor_"+itemM.rowId} 
									  			className="form-group card-label">
									  			<label>Border Container</label>
									  			<SketchPicker  
				 										className={"FIELDVALUE_"+props.container+"_bordercolor_"+itemM.rowId}
				 										name={"FIELDVALUE_"+props.container+"_bordercolor_"+itemM.rowId}
				 										id={"FIELDVALUE_"+props.container+"_bordercolor_"+itemM.rowId}
										        color={ itemM.bordercolor?itemM.bordercolor:"#FFFFF" }
										        onChange={ props.manageComponent}
										      />
										  	</div>	
										  	<div className="form-group card-label">
									  			<label > Container Shadow  </label>
									 				<select  name={"FIELDVALUE_"+props.container	+"_shadow_"+itemM.rowId} defaultValue={itemM.shadow} onChange={props.manageComponent} className={"form-control form-control-sm" }>
					        			 		<option value="NO">NO</option>
							        		 	<option value="YES">YES</option>
													</select>
									  		</div>	
										  </div>	
									  </>
									:""}
							  	<div className={"mt-2 col-md-12 col-sm-12 " + (props.type=="header"? " col-lg-10 col-xl-10":" col-lg-8 col-xl-8" ) }>
							  			<div className="card">
										  	 
									  			<div className="row mt-1">	
									  				<div className={"col-6"}>
									  					 <span className="ml-2">Label </span>
									  				</div>
									  				<div className={"col-6"}>
									  					<span className="ml-1">	Link </span>
									  				</div>
									  			</div>	
									  			{Array.isArray(itemM.items)?
									  				itemM.items.map(i => (
									  					<div className="row">	
								        		 		<div className="col-6">
							        		 				<b className="ml-2">	{i.label} </b>
								        		 		</div>		
							        		 			<div className="col-6">
						        		 					<b className="ml-1">	{i.link} </b>
						        		 				</div>
						        		 			</div>
							        		 	))
							        		:""
							            }  
						            	<div className="row mt-2 mb-1">	
									  				<div className={"col-12 text-right"}>
															<button  className="btn mr-2 btn-danger btn-sm"  name={"CompHeaderIconItem_"+props.container+"_items_"+itemM.rowId} onClick={props.manageComponent}
															type="button"> Set Icons </button>
														</div>
						        			</div>	
											</div>	
										</div>
									
							  </>	
						  	:
						  	""
						  	}
						  	
						  	
						  	
						  	{!itemM.showMore?
						  	<div className="text-right col-md-6 col-sm-6 col-lg-2 col-xl-2">
						  		<div className="mt-3">
						  			<button  className="btn btn-secondary btn-sm btn-rounded"  name={"TOGGLEROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className={ (itemM.showMore?"fas fa-compress-arrows-alt":"fas fa-expand-arrows-alt") } ></i> </button>
										<button  className="btn btn-danger btn-sm btn-rounded"  name={"DELROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
								 	</div>
								</div>
								:""}
				    	</div>
				    	
				    	
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row mt-2 mb-2">
								<div className={"col-12 middle text-right "}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompHeaderItems"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO CompHeaderItems DATA"
	}
};
export default loadCompHeaderItems;
				 