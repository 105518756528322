
import React from "react";

import { getImagePath  } from '../../common/functions';

class AccordionItem extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
        this.state = {
            visibility: false
        }
    }
    
    handleToggleVisibility() {
        this.setState((prevState) => {
            return {
                visibility: !prevState.visibility,
            }
        })
    }
    render() {
    	//console.log("start",this.props.content)
        const activeStatus = this.state.visibility ? 'active' : ''

        return (this.props.content.image?
            <div>  
              <button className="accordion__button" 
              	onClick={this.handleToggleVisibility}> 
              	<img alt={this.props.content.image}  
			    			src={getImagePath(this.props.content.image)}  /> 
              {/*		<span className={this.state.visibility? 'fas fa-minus': 'fas fa-plus'}></span>
              	*/}
              </button>
              	<p className={`accordion__content ${activeStatus}`}> 
              	<img alt={this.props.content.imageC}  
			    			src={getImagePath(this.props.content.imageC)}  /> 
              </p> 
            </div>
        	:
        		<div> 
              <button className="accordion__button mb-1" 
              	onClick={this.handleToggleVisibility}> 
              	<div className="title"> { this.props.content.title+"-"}</div>
               	<span className={ "icon " +(this.state.visibility? 'fas fa-minus': 'fas fa-plus')}></span>
               
              </button>
              {this.state.visibility?
              	<div className={`accordion__content_text ${activeStatus} `}> 
              	{ this.props.content.text}
              </div	> 
            :""}
            </div>
        );
    }
}

export default AccordionItem;