/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import FolderManager from '../../components/FolderManager';
import ImageManager from '../../components/ImageManager';
import { EditorState, ContentState,convertToRaw, convertFromHTML } from 'draft-js'
/*functions*/
import {getSubcomponents, changeFilter, filterHandler,reorder,find, manageSubComponent, validateSyncForm, findObjName,getJSONDefinition,listFolders,move, onDragEnd, manageFolder,validateComponent,manageComponent, getUserInfo,getComponentConfiguration,manageImage, listImages, validateRole, showWarning,encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import CompCard from '../../components/CompCard';
import CompCardChild from '../../components/CompCardChild';
import CompCategoryBlog from '../../components/CompCategoryBlog';
import CompItem from '../../components/CompItem';
 
import CompSliderImg from '../../components/CompSliderImg';
import CompList from '../../components/CompList';
import CompForm from '../../components/CompForm';
import CompButton from '../../components/CompButton';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 


const foldernameOrigin= process.env.REACT_APP_FOLDER_LAYOUT
const url_front= process.env.REACT_APP_URL_FRONT_CONFIG 
const url_aws= process.env.REACT_APP_PATH_FILES
class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			date_label:date_label,
			modal1: false,
			modal2: false,
			modal2B: false,
			modal1B: false,
			modal22B:false,
			modal3:false,
			isLoading: true,
			compManagerB:null,
			buttonsModal4B : [
				{	buttonAction: this.validateComponentB,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponentB,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
			],
			editorState: EditorState.createEmpty(),
			modalClass: "modal-full-height modal-lg",
			modalClassB: "modal-full-height modal-lg",
			modalLgClass:"modal-lg",
			modalClass3:"modal-sm",
			foldername:foldernameOrigin,
			filterConfig: {
				LAYOUTNAME :{ label:"Layout Name" ,  type:"TEXT"   , value:""} 
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"LAYOUTNAME", title:"Layout Name",
						sorter: (a, b) => a.LAYOUTNAME.length - b.LAYOUTNAME.length },
					{ dataIndex:"LAYOUTURL", title:"URL",
						sorter: (a, b) => a.LAYOUTURL.length - b.LAYOUTURL.length,
						render: (text, record) => (<a href={record.LAYOUTURL} target="_blank"> {record.LAYOUTURL}</a>)
		      },
					{ dataIndex:"LAYOUTFILE", title:"Layout Path",
						sorter: (a, b) => a.LAYOUTFILE.length - b.LAYOUTFILE.length },
					{ dataIndex: "LAYOUTID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-sm btn-info" id={record.LAYOUTFILE} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-success" id={record.LAYOUTFILE} name="refresh"
		         onClick={this.loadFormData}
		          type="submit">Refresh</button> 
		          
				  <button className="btn btn-sm btn-secondary" id={record.LAYOUTFILE} name="program"
		         onClick={this.loadFormData}
		          type="submit">Program</button> 
		          

		         <button className="btn btn-sm btn-danger" id={record.LAYOUTNAME} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
	
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"},
				{	buttonAction: this.loadFormData,
					buttonId    : 2,
					buttonClass : "btn btn-success",
					buttonName  : "Program"}	
			],
			buttonsModal1B : [
				{	buttonAction: this.updateB,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Update"}
			],

			formData2	: { JOBDATE   :{ label: "Job Date"      ,   size:"col-12"        , type: "DATEPICKER",   value: new Date()} ,
								},
			formConfig2:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1
			},
			modalIconB:"fas fa-edit",
			modalIcon:"fas fa-edit",
			modalTitleB:"Update Component",
			modalTitle:"New Layut",
			modalIconB:"fas fa-edit",
			/*form data*/			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1
			},
			formData	: { LAYOUTNAME   : { label: "Layout Teplate Name"                    , type: "TEXT",   value: ""  },
										
										LAYOUTTITLE  : { label: "Template Title(H1)"        , notRequired:true, type: "TEXT",   value: "" , size: "col-sm-12 col-md-6 col-lg-6 col-xl-6"  },
										LAYOUTTAGS   : { label: "Template Tag"         , notRequired:true, type: "TEXT",    value: "" , size: "col-sm-12 col-md-6 col-lg-6 col-xl-6"  },
										
										
										LAYOUTIMAGE   : { label: "Preview Image", notRequired:true, type: "IMAGE",   value: "", size:"col-sm-12 col-md-6 col-lg-2 col-xl-2"  },
										LAYOUTABOUT  : { label: "About Template (Description)"        , notRequired:true, type: "TEXTAREA", height: "14rem",   value: "" , size: "col-sm-12 col-md-6 col-lg-10 col-xl-10"  },
										
										METATITLE  : { label: "Meta Title"        , notRequired:true, type: "TEXT",   value: "" , size: "col-sm-12 col-md-6 col-lg-6 col-xl-6"  },
										METADESC   : { label: "Meta Description"         , notRequired:true, type: "TEXTAREA",    value: "" , size: "col-sm-12 col-md-12 col-lg-12 col-xl-12"  },
										
										
										COMPONENTS   : { label: "Component List" , "type": "CompDND", values: [] , value:[] , 
																		 showAll:true, filterValue:"", filterValues:[], filterItems: ["category", "type"] , actionButton:true, actionLabel:"EDIT", action: this.updateComponet } 
									},
			formData3B	: { 
										COMPONENT   : { label: "Component Name" , "type": "SELECT", "value": "", "values": [] , "addDefault":"CHOOSE ONE"  }
									},
			formDataB	: {},
			/*support variables*/
			currentName:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			/*images or files*/
			initialFolder:"",
			folderManager:null,
			masterFolderManager:null,
			compManager:null,
			objFolder:{
				items:[],
				selected:[]
			},
			objFolderMaster:{
				items:[],
				selected:[]
			},
			ObjComponentsOrig:[],
			ObjComponents:[]
		}
	}
	
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	
	 	try{	 		
	 		let defaulfFolderFilesName = localStorage.getItem('defaulfFolderFilesName')
	 		
	 		let foldername = this.state.foldername
	 		if (defaulfFolderFilesName){
	 			this.setState({foldername:defaulfFolderFilesName});
	 			foldername= defaulfFolderFilesName
	 		}
	 		
	 		
	 		let rows = []	 		
	 		let resp = await callApi ( "s3","listFiles", {foldername:foldername} )
	 		for (let r in resp.files){
	 			if ( resp.files[r].filename!=this.state.foldername ){
	 				let data = resp.files[r].filepath.split(foldernameOrigin)
	 				let url =  url_front+"/pages/"+data[1]
	 				rows.push( { LAYOUTNAME: resp.files[r].filename.replace(foldernameOrigin, "") , LAYOUTURL: url, LAYOUTFILE: resp.files[r].filepath  });		
	 			}
	 		}
	 		
	 		const tableData = this.state.tableData
	 		tableData.rows = rows
	 		this.setState({originalTableData:this.state.tableData});
	 		await preloadForm ( this.state.formData )
			
			resp = await callApi ( "custom","getDetailDomainsByCode",  {domainid: 14  , subdomainId :getUserInfo().languageid})
	 		const formData3B = this.state.formData3B
	 		formData3B.COMPONENT.values.push ({value:"", text:"CHOOSE ONE" })
	 		
	 		resp.rows.map(function (item) {
	 			formData3B.COMPONENT.values.push ({value:item.DETAILDOMAINID, text:item.STRING_VALUE })
			}); 
	 		
			
	 		await this.getComponents()
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	
	
	updateComponet=async (event) => {
		try{	
			this.setState({isLoading:true});
			let pos = find(this.state.ObjComponentsOrig, event, "value" )
			let itemId = event
			let compTypeId = this.state.ObjComponentsOrig[pos].componenttypeid
			 
			let resp = await callApi ( "layaoutManager","getComponentById",{componentid: itemId} )
			
			let data2 = this.state.ObjComponentsOrig[pos].data
			
			const formData3B = this.state.formData3B
			formData3B["COMPONENT"].value=compTypeId	  		
			
			var _resp = await getComponentConfiguration(this.state.formData3B.COMPONENT.value)
			
			var formData = _resp.configuration
			formData["componentname"]["disabled"]= true
			compTypeId = _resp.code
			formData = await preloadForm ( formData )	  	
			this.setState({  ...this.state, formDataB: formData, componenttypeidB: compTypeId})
			
			var COMPONENT =this.state.ObjComponentsOrig[pos]
			
			let data = COMPONENT.data
			let config =COMPONENT.config
			
			COMPONENT["componentname"] = COMPONENT.text
			
			for (let key in Object.keys(data.data) ){
				var item = Object.keys(data.data) [key]
				if(this.state.formDataB["data"+item]){
					COMPONENT["data"+item] = data.data[item]
				}else{
					showWarning(toast, "VALUE NOT FOUND ("+item+")" )
				}
			}
			for (let key in Object.keys(config.config) ){
				var item = Object.keys(config.config) [key]
				if(this.state.formDataB["config"+item]){
					COMPONENT["config"+item] = config.config[item]
				}else{
					showWarning(toast, "CONF NOT FINDED ("+item+")" )
				}
			}
			delete COMPONENT["DATA"] 
			delete COMPONENT["CONFIG"]
			
			 //AQUI
				var content =   data.content;
				if ( data.content.length>0){
					if ( data.content[0].sortorder){
						content= data.content.sort(function (a, b){
						return (a.sortorder - b.sortorder)
						})
						
						for (let c in content){
							delete content[c]["rowId"] 
						}
						
						for (let c in content){
							content[c]["rowId"] = parseInt(c)
						}
						
					}				
				}
	 
			let loadData = await mergeFormData (this.state.formDataB,COMPONENT)
			formData = this.state.formDataB
			if (data.content){
				
				if (data.content.length>0){
					if ( resp.COMPONENT.COMPONENTTYPE=="BLOGCONTAINER" ){
						
						let availComp=[]
						formData["datacontent"].values.map(function (item) { 				
			 				let isUsed=false;
			 				let pos = find (content, item.value, "value" )
		 					if (pos>=0){
		 						isUsed= true;
		 					}
			 				if (!isUsed){
			 					availComp.push(item)	
			 				}
			 			})
			 			formData["datacontent"].value  = content
						formData["datacontent"].values = availComp	
					}else{
						formData["datacontent"].values = content	
					}
	  			
	  		}  	
	  		
			} 
			let formAction="update"
			let modalClass =  "modal-full-height modal-lg"
			let buttonsModal1 = [
				{	buttonAction: this.updateB,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Update"}
			]
			let modalIcon="fas fa-edit"
			let modalTitle="Edit Child Component"
			this.setState({...this.state,modal1B:true,isLoading:false, currentIdB: itemId, componenttypeidB: _resp.code,  modalClassB: modalClass, formActionB:formAction, buttonsModal1B: buttonsModal1, modalIconB:modalIcon, modalTitleB:modalTitle  });

		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
		
	}
	
	//manage Folder
	setDefault=async (event) => {
		localStorage.setItem('defaulfFolderFilesName', this.state.foldername);
	}
	manageFolderMaster= async(event) => {
		try{
			this.setState({isLoading:true});			
			var _data =   findObjName(event)
			var data = (_data).split("_")
			let action =  data[0]			 
			if (action==="manageFolder"){
				 
				let objFolderMaster= await listFolders(this.state.foldername,"MASTER",[]);
				this.setState({objFolderMaster:objFolderMaster});

				let masterFolderManager = <FolderManager objFolder={this.state.objFolderMaster} manageFolder={this.manageFolderMaster} />
	 		 	this.setState({ masterFolderManager:masterFolderManager, modal2B: true});
			}else{
				var objFolderMaster = await manageFolder( event, this.state.objFolderMaster)
				this.setState({objFolderMaster:objFolderMaster});
				let masterFolderManager = <FolderManager objFolder={this.state.objFolderMaster} manageFolder={this.manageFolderMaster} />
				this.setState({masterFolderManager:masterFolderManager});
				
				if (objFolderMaster.value){
					this.setState({modal2B:false, foldername: objFolderMaster.value });
					let rows = []
					let resp = await callApi ( "s3","listFiles", {foldername:objFolderMaster.value} )
			 		for (let r in resp.files){
			 			if ( resp.files[r].filename!=this.state.foldername ){
			 				let data = resp.files[r].filepath.split(foldernameOrigin)
	 						let url =  url_front+"/pages/"+data[1]
			 				rows.push( { LAYOUTNAME: resp.files[r].filename.replace(foldernameOrigin, "") , LAYOUTURL: url, LAYOUTFILE: resp.files[r].filepath  });		
			 			}
			 		}
			 		
			 		const tableData = this.state.tableData
			 		tableData.rows = rows
			 		this.setState({originalTableData:this.state.tableData});
				}
				
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	//manage Folder
	manageFolder= async(event) => {
		try{
			this.setState({isLoading:true});			
			var _data =   findObjName(event)
			var data = (_data).split("_")
			let action =  data[0]			 
			if (action==="manageFolder"){
				let objectName = data[1]			 
				let _currFolder = this.state.formData[objectName].value.split("/")
				let size = _currFolder.length
				_currFolder.splice ( size-2, 2 )
				 
				let currFolder = ""
				if ( _currFolder.length>0){
					for (let c in _currFolder){
						currFolder = currFolder +	_currFolder[c] +"/"
					}	
				}
				
				let objFolder = await listFolders(currFolder,objectName,[]);
				this.setState({objFolder:objFolder});
				let folderManager = <FolderManager objFolder={this.state.objFolder} manageFolder={this.manageFolder} />
	 		 	this.setState({ folderManager:folderManager, modal2: true});
			}else{
				var objFolder = await manageFolder( event, this.state.objFolder)
				this.setState({objFolder:objFolder});
				let folderManager = <FolderManager objFolder={this.state.objFolder} manageFolder={this.manageFolder} />
				this.setState({folderManager:folderManager});
				
				if (objFolder.value){
						let field = objFolder.objectName
						var formData = this.state.formData
						formData[field].value = objFolder.value
						this.setState({modal2:false});
				}
				
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	//manage image
	manageImage = async(event) => {
		try{
			this.setState({isLoading:true});
			 
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
			
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages("",objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal3: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
						let field = objImage.objectName
						var formData = this.state.formData
						formData[field].value = objImage.selected[0]
						this.setState({modal3:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
  getComponents=async (event) => {
		 
		try{	
	  	var resp = await callApi ( "layaoutManager","getComponents" )
	 		var components = []
	 		for (let c in resp.rows){
	 				components.push ( {
	 					value           : resp.rows[c].COMPONENTID,
	 					text            : resp.rows[c].COMPONENTNAME,
	 					type            : resp.rows[c].COMPONENTCODE,
	 					data            : JSON.parse( resp.rows[c].DATA),
	 					config          : JSON.parse( resp.rows[c].CONFIG),
	 					category        : resp.rows[c].CATEGORY?resp.rows[c].CATEGORY: "",
	 					componenttypeid : resp.rows[c].COMPONENTTYPEID,
	 					COMPONENTID     : resp.rows[c].COMPONENTID,
	 					COMPONENTNAME   : resp.rows[c].COMPONENTNAME,
	 					COMPONENTTYPE   : resp.rows[c].COMPONENTTYPE,
	 				})
	 		}
	 		this.setState({ObjComponentsOrig:components});
	 		
	 		this.setState({ObjComponents:components});
	 		const formData = this.state.formData
	 		formData.COMPONENTS.values=components
	 		
	 		var _filterValues =formData.COMPONENTS.filterItems
	 		
	 		var filterValues =[]	 		
	 		 
			for (let f in _filterValues){
				var label = _filterValues[f]
				var values =["ALL"]
				for (let v in formData.COMPONENTS.values){
					try{
						if (values.indexOf( formData.COMPONENTS.values[v][label] ) <0 )	{
							values.push( formData.COMPONENTS.values[v][label])
						}
					}catch(Exc){
						console.log(">>>>>>> ERROR GETTING FILTER VALUE:",)
					}
				}
				var defValue= "ALL"
				if (formData.COMPONENTS.filterValues[f]){
			 
					if (formData.COMPONENTS.filterValues[f].value){
					 
						 defValue = formData.COMPONENTS.filterValues[f].value
					}
				}
			 
				filterValues.push ( {label:label,values:values, value: defValue} )
			}
			formData.COMPONENTS.filterValues= filterValues
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
			this.setState({ObjComponentsOrig:[]});
			this.setState({ObjComponents:[]});
	 		const formData = this.state.formData
	 		formData.COMPONENTS.values=[]
		}
	}
	/*charge data from a selected user*/
	loadFormData=async (event) => {
		
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add",
					},
					{	buttonAction: this.loadFormData,
						buttonId    : 2,
						buttonClass : "btn btn-success",
						buttonName  : "Program"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Layout"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				 
				let loadData = await clearformData (this.state.formData)
				await this.getComponents();
				
				const formData = this.state.formData
	 		 	
	 		
				formData.LAYOUTNAME.value=""
				formData.COMPONENTS.values= this.state.ObjComponentsOrig
				formData.COMPONENTS.filterValue= ""
				
				formData.COMPONENTS.value= []
				this.setState({...this.state,modal1:true,isLoading:false, ObjComponents: this.state.ObjComponentsOrig, currentName :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"},
					{	buttonAction: this.loadFormData,
						buttonId    : 2,
						buttonClass : "btn btn-success",
						buttonName  : "Program"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Layout"
				formAction="update"
				modalClass =  "modal-full-height modal-lg"
				let itemId = event.target.id 
				await this.getComponents();
	  		let resp = await callApi ( "layaoutManager","getLayoutTemplateByName",{layoutFile: itemId} )
	  		
	  		let configuration = resp.template;   
	  		const formData = this.state.formData
	 		  formData.COMPONENTS.filterValue= ""
	 		 	let url = itemId.split("/")
	 			let layoutname = url[url.length-1]
	 			
	 			let availComp=[]
	 			let usedComp=[]	 			
	 			this.state.ObjComponentsOrig.map(function (item) { 				
	 				let isUsed=false;
	 				for (let key in configuration){
	 					if (item.value == configuration[key].componentid && !configuration[key].isChildren ){
	 						isUsed= true;
	 					}
	 				}
	 				if (!isUsed){
	 					availComp.push(item)	
	 				}
	 			})
	 			var templateInfo= { image: "", about: "", title:"", tags:""} 
				
	 			for (let c in configuration){
	 				if (configuration[c].componentType =="ABOUT"){
	 					templateInfo= { image: configuration[c].data.image, about: configuration[c].data.about,
	 													title: configuration[c].data.title, tags:  configuration[c].data.tags,
	 													metatitle: configuration[c].data.metatitle, metadesc:  configuration[c].data.metadesc } 
	 				}else{
	 					for (let o in  this.state.ObjComponentsOrig){
						if (this.state.ObjComponentsOrig[o].value == configuration[c].componentid && !configuration[c].isChildren ){
						 	usedComp.push(this.state.ObjComponentsOrig[o])
						}
					}	
	 				}
	 			}
	 	 
				formData.LAYOUTNAME.value=layoutname
			  formData.COMPONENTS.values= availComp
			  formData.COMPONENTS.value= usedComp
			  
			  formData.LAYOUTIMAGE.value= templateInfo.image
			  formData.LAYOUTABOUT.value= templateInfo.about
			  
			  formData.LAYOUTTITLE.value= templateInfo.title
			  formData.LAYOUTTAGS.value = templateInfo.tags
			  
			  formData.METATITLE.value= templateInfo.metatitle
			  formData.METADESC.value = templateInfo.metadesc
			   
				 
	  		this.setState({...this.state,modal1:true, ObjComponents:availComp, isLoading:false, currentName :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
	  	}else if (event.target.name==="refresh"){
	  		let itemId = event.target.id 
	  		
	  		await this.getComponents();
		 		
	  		let resp = await callApi ( "layaoutManager","getLayoutTemplateByName",{layoutFile: itemId} )
	  		
	  		let configuration = resp.template;   
	  		const formData = this.state.formData
	 		 
	 		 	let url = itemId.split("/")
	 			let layoutname = url[url.length-1]
	 			
	 			let availComp=[]
	 			let usedComp=[]	 			
	 			this.state.ObjComponentsOrig.map(function (item) {
	 				let isUsed=false;
	 				for (let key in configuration){
	 					if (item.value == configuration[key].componentid && !configuration[key].isChildren ){
	 						isUsed= true;
	 					}
	 				}
	 				if (!isUsed){
	 					availComp.push(item)	
	 				}
	 			})
	 			var templateInfo= { image: "", about: "", title:"", tags:""} 
	 					
	 			for (let c in configuration){
	 				if (configuration[c].componentType =="ABOUT"){
	 					templateInfo= { image: configuration[c].data.image, about: configuration[c].data.about,
	 													title: configuration[c].data.title, tags:  configuration[c].data.tags } 
	 				}else{
	 					for (let o in  this.state.ObjComponentsOrig){
						if (this.state.ObjComponentsOrig[o].value == configuration[c].componentid && !configuration[c].isChildren ){
						 	usedComp.push(this.state.ObjComponentsOrig[o])
						}
					}	
	 				}
	 			}
	 	 
				formData.LAYOUTNAME.value=layoutname
			  formData.COMPONENTS.values= availComp
			  formData.COMPONENTS.value= usedComp
			  
			  formData.LAYOUTIMAGE.value= templateInfo.image
			  formData.LAYOUTABOUT.value= templateInfo.about
			  
			  formData.LAYOUTTITLE.value= templateInfo.title
			  formData.LAYOUTTAGS.value = templateInfo.tags
			  
			  formData.METATITLE.value= templateInfo.metatitle
			  formData.METADESC.value = templateInfo.metadesc
			  
			  this.setState({...this.state, currentName :itemId });
			  var _formData =  this.state.formData;
			  
			  resp = await validateForm(this.state.formData, toast);  		
			    	
  			_formData = parseFormData(_formData) 
			   
			  configuration=[]				 
			  for (let c in _formData.components ){
			  	configuration.push({
			  		componentid   : _formData.components[c].value,
				  	componentType : _formData.components[c].type,
				  	name          : _formData.components[c].text,
				  	content       : _formData.components[c].data.content,
				  	data          : _formData.components[c].data.data,
				  	config        : _formData.components[c].config.config
			  	})
			  	const _parentData = _formData.components[c]
			  	if (_parentData.data.data.children){
			  		for (let c in this.state.ObjComponentsOrig ){
				  		if (this.state.ObjComponentsOrig[c].value == _parentData.data.data.children){					  			
				  			_parentData.data.data.children = this.state.ObjComponentsOrig[c].text					  			
					  		configuration.push({
						  		componentid   : this.state.ObjComponentsOrig[c].value,
							  	componentType : this.state.ObjComponentsOrig[c].type,
							  	name          : this.state.ObjComponentsOrig[c].text,
							  	isChildren		: true,
									parent				: _parentData.text,					
							  	content       : this.state.ObjComponentsOrig[c].data.content,
							  	data          : this.state.ObjComponentsOrig[c].data.data,
							  	config        : this.state.ObjComponentsOrig[c].config.config
						  	})
						  }
			  		}
			  	} 
			  	if (_parentData.data.content){	  		
			  		if(_parentData.type =="BLOGCONTAINER"){
			  			for (let p in _parentData.data.content){
			  				var _parentChild = _parentData.data.content[p]
								if (_parentChild.filepath){
				  				var respB = await callApi  ( "layaoutManager", "getLayoutTemplateByName", { layoutFile : _parentChild.filepath} )
	 								var image="",about="", title="",tags=""
	 								if (respB.success){
	 									let pos = find( respB.template, "ABOUT", "componentType" )
	 									if (pos>=0){	 										
	 										image = respB.template[pos].data.image
	 										about = respB.template[pos].data.about
	 										title = respB.template[pos].data.title
	 										tags  = respB.template[pos].data.tags
	 									}
	 								}										
	 								_parentChild["image"] = image
	 								_parentChild["about"] = about
	 								
	 								_parentChild["title"] = title
	 								_parentChild["tags"] = tags
								}		  				 
			  			}
			  		}else{
			  			for (let p in _parentData.data.content){
			  				var _parentChild = _parentData.data.content[p]
			  				if(_parentChild.children){
						  		for (let c in this.state.ObjComponentsOrig ){
							  		if (this.state.ObjComponentsOrig[c].text == _parentChild.children){ 
								  		configuration.push({
									  		componentid   : this.state.ObjComponentsOrig[c].value,
										  	componentType : this.state.ObjComponentsOrig[c].type,
										  	name          : this.state.ObjComponentsOrig[c].text,
										  	isChildren		: true,
												parent				: _parentData.text,					
										  	content       : this.state.ObjComponentsOrig[c].data.content,
										  	data          : this.state.ObjComponentsOrig[c].data.data,
										  	config        : this.state.ObjComponentsOrig[c].config.config
									  	})
									  }
						  		}
						  	}
			  			}
			  		}
			  	}
				}	
				
				var templateInfo= {
			  	image: _formData.layoutimage,
			  	about: _formData.layoutabout,
			  	title: _formData.layouttitle,
			  	tags:  _formData.layouttags,
			  	metatitle :  _formData.metatitle,
			  	metadesc  :  _formData.metadesc,			  	
			  } 
			  configuration.push({
									  		componentid   : 0,
										  	componentType : 'ABOUT',
										  	config        : {},
										  	data          : templateInfo
									  	})
										  	
			  let data= {"layoutname"   : _formData.layoutname,
					       "replace"     : true,
					       foldername    : this.state.foldername,
						     configuration }
				resp = await callApi ( "s3", "createUploadFile", data )
				
				if (resp.success){
		  		showMessage(toast,resp.message);
		  	}else{
		  		showError(toast,resp.message);
		  	}
			  this.setState({...this.state,isLoading:false });			
	  	
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Layout"
				itemId = event.target.id
				formAction="del"
				modalClass =  ""
				this.setState({...this.state,modal1:true,isLoading:false, currentName :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
	  	}else if (event.target.name==="program"){
			buttonsModal1 = [
				  {	buttonAction: this.program,
					  buttonId    : 1,
					  buttonClass : "btn btn-outline-danger",
					  buttonName  : "Program"}
			  ]				
			  modalIcon = "fas fa-timer"
			  modalTitle = "Program Layout"
			  itemId = event.target.id
			  formAction="job"
			  modalClass =  ""

			  var _formData ={} 
			  var components=[]
			  var isValid= true
			  var fileName= ""
			  var logs=[]
			  if (event.target.id ==="1" || event.target.id ==="2" ){
				_formData = this.state.formData;
				let resp = await validateForm(this.state.formData, toast);  				
				_formData = parseFormData(_formData)  				
				
				for (let i in _formData.components){
					components.push (_formData.components[i].value )					
				}
				fileName= this.state.currentName	
				isValid= resp.isValid

  				if (this.state.formAction==="set"){
  					this.state.tableData.rows.map(function (item) {
		 					if (item.LAYOUTNAME == _formData["layoutname"] ){
								isValid=false
  							showError(toast,"NAME ALREADY EXISTS");
		 					}
			 			})
  				}
  				if(_formData.components.length<1){
					isValid=false
  					showError(toast,"CHOOSE AT LEAST ONE ITEM");
  				}  
				let resp2 = await callApi ( "layaoutManager","getLogComponentById",{layoutFile: this.state.currentName} )
				for (let l in resp2.logs){
					logs.push(resp2.logs[l])
				}
			  }else{
				let resp = await callApi ( "layaoutManager","getLayoutTemplateByName",{layoutFile: itemId} )
				
				for (let i in resp.template){
					components.push (resp.template[i].componentid )					
				}
 
				let resp2 = await callApi ( "layaoutManager","getLogComponentById",{layoutFile: itemId} )
				for (let l in resp2.logs){
					logs.push(resp2.logs[l])
				}
			  }
			 

				console.log("_", formAction, _formData, components, fileName)
			  if (isValid){
				this.setState({...this.state,modal1:true, logs:logs, fileName:fileName,components:components, isLoading:false, currentName :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
			  }else{
				this.setState({...this.state, isLoading:false})
			  }
		}
  	}catch(err){
			console.log(">>>>>>>>>>",err)
		}
  }
  
  changeHandler2 = async(event) => {
	let resp = await validateForm(this.state.formData2, toast,event);
	this.setState({formData2:resp.formData});
  } 
  //filter table
  filterHandler = async(event) => {
  	
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	
  ///get value fields
  changeHandler = async(event) => {
  	
  	let resp 
  	var isAsync=false
  	if (  this.state.formData[event.target.name]){
  		if ( this.state.formData[event.target.name].script ){
  			isAsync=true
  		}  		
  	}
  	if (isAsync){
  		resp = await validateForm(this.state.formData, toast,event);
  	}else{
  		resp = validateSyncForm(this.state.formData, toast,event);	
  	}
  	
		this.setState({formData:resp.formData});
	} 
	
	///get filter to components
  changeFilter = async(event) => {
  	var resp = await changeFilter(event, this.state.ObjComponentsOrig , this.state.formData)	
    this.setState({formData:resp});
	} 
	//get drag movement
	onDragEnd = result => {
    var resp = onDragEnd(result, this.state.formData)	
  }
  
  //show/hide modal
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}

	program=async (event) => {
		this.execute(event, "PROGRAM")
	} 

	/*ABM manager*/
	execute=async (event,actionType) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		var _formData =  this.state.formData;
			  	
  		if(resp){
  			/*validation*/
  			if (actionType==="DELETE" || actionType==="PROGRAM" ){ 
  				
  				resp= {isValid:true}
  			}else{
  				resp = await validateForm(this.state.formData, toast);  				
  				_formData = parseFormData(_formData)  				
  				if (actionType==="SET"){
  					this.state.tableData.rows.map(function (item) {
		 					if (item.LAYOUTNAME == _formData["layoutname"] ){
		 					 	resp["isValid"]=false
  							showError(toast,"NAME ALREADY EXISTS");
		 					}
			 			})
  				}
  				if(_formData.components.length<1){
  					resp["isValid"]=false
  					showError(toast,"CHOOSE AT LEAST ONE ITEM");
  				}
  			}
  			if(resp.isValid){
			  	this.setState({  ...this.state, isLoading:true })
				  var configuration=[]				 
				  for (let c in _formData.components ){
				  	configuration.push({
				  		componentid   : _formData.components[c].value,
					  	componentType : _formData.components[c].type,
					  	name          : _formData.components[c].text,
					  	content       : _formData.components[c].data.content,
					  	data          : _formData.components[c].data.data,
					  	config        : _formData.components[c].config.config
				  	})
				  	const _parentData = _formData.components[c]
				  	if (_parentData.data.data.children){
				  		for (let c in this.state.ObjComponentsOrig ){
					  		if (this.state.ObjComponentsOrig[c].value == _parentData.data.data.children){					  			
					  			_parentData.data.data.children = this.state.ObjComponentsOrig[c].text					  			
						  		configuration.push({
							  		componentid   : this.state.ObjComponentsOrig[c].value,
								  	componentType : this.state.ObjComponentsOrig[c].type,
								  	name          : this.state.ObjComponentsOrig[c].text,
								  	isChildren		: true,
										parent				: _parentData.text,					
								  	content       : this.state.ObjComponentsOrig[c].data.content,
								  	data          : this.state.ObjComponentsOrig[c].data.data,
								  	config        : this.state.ObjComponentsOrig[c].config.config
							  	})
							  }
				  		}
				  	} 
				  	if (_parentData.data.content){	
				  		
				  		if(_parentData.type =="BLOGCONTAINER"){				  			
				  			for (let p in _parentData.data.content){
				  				var _parentChild = _parentData.data.content[p]
									if (_parentChild.filepath){
					  				var respB = await callApi  ( "layaoutManager", "getLayoutTemplateByName", { layoutFile : _parentChild.filepath} )
		 								var image="",about="", title="",tags=""
		 								if (respB.success){
		 									let pos = find( respB.template, "ABOUT", "componentType" )
		 									if (pos>=0){	 										
		 										image = respB.template[pos].data.image
		 										about = respB.template[pos].data.about
		 										title = respB.template[pos].data.title
		 										tags  = respB.template[pos].data.tags
		 									}
		 								}										
		 								_parentChild["image"] = image
		 								_parentChild["about"] = about
		 								
		 								_parentChild["title"] = title
		 								_parentChild["tags"] = tags
									}		  				 
				  			}
				  			
				  		}else{
				  			for (let p in _parentData.data.content){
				  				var _parentChild = _parentData.data.content[p]
				  				if(_parentChild.children){
							  		for (let c in this.state.ObjComponentsOrig ){
								  		if (this.state.ObjComponentsOrig[c].text == _parentChild.children){ 
									  		configuration.push({
										  		componentid   : this.state.ObjComponentsOrig[c].value,
											  	componentType : this.state.ObjComponentsOrig[c].type,
											  	name          : this.state.ObjComponentsOrig[c].text,
											  	isChildren		: true,
													parent				: _parentData.text,					
											  	content       : this.state.ObjComponentsOrig[c].data.content,
											  	data          : this.state.ObjComponentsOrig[c].data.data,
											  	config        : this.state.ObjComponentsOrig[c].config.config
										  	})
										  }
							  		}
							  	}
				  			}
				  		}
				  	}
				  }  
			 	  
				  var templateInfo= {
				  	image: _formData.layoutimage,
				  	about: _formData.layoutabout,
				  	title: _formData.layouttitle,
			  		tags:  _formData.layouttags,
			  		metatitle :  _formData.metatitle,
			  		metadesc  :  _formData.metadesc,
				  } 
				  configuration.push({
										  		componentid   : 0,
											  	componentType : 'ABOUT',
											  	config        : {},
											  	data          : templateInfo
										  	})
				  let data, resp
				  if (actionType==="DELETE"){
				  	data= { "filename"   :  this.state.foldername+this.state.currentName }				  	
				  	resp = await callApi ( "s3", "deleteFile", data )
				  
			      }else if (actionType==="PROGRAM"){

					let _formData =  this.state.formData2;
					_formData = parseFormData(_formData)
					_formData["components"] = this.state.components
					console.log(this.state.components)
					if (this.state.fileName===""){
						_formData["filename"] = this.state.currentName		
					}else{
						_formData["filename"] = this.state.fileName
					}
					resp = await callApi ( "s3", "programFile", _formData )
				  
				 
				  }else{
				  	
				  	if (actionType==="UPDATE"){
				  		let dataUpd= { "filename"   :  this.state.currentName.replace(url_aws+"/","") }	
				  		if (this.state.currentName.replace(url_aws+"/","") != this.state.foldername+_formData.layoutname ){
				  			let respUpd = await callApi ( "s3", "deleteFile", dataUpd )
				  		}
				  	}
				  	
				  	data= {"layoutname"   : _formData.layoutname,
					       "replace"     : actionType==="UPDATE"?true: false,
					       foldername    : this.state.foldername,
						     configuration }
						resp = await callApi ( "s3", "createUploadFile", data )
				  }				  
			  	if (resp.success){
			  		showMessage(toast,resp.message);
		 			
			 			resp = await callApi ( "s3","listFiles", {foldername:this.state.foldername} )
	 					let rows = []
	 					for (let r in resp.files){
				 			let data = resp.files[r].filepath.split(foldernameOrigin)
	 				let url =  url_front+"/pages/"+data[1]
			 				rows.push( { LAYOUTNAME: resp.files[r].filename.replace(foldernameOrigin, "") , LAYOUTURL: url, LAYOUTFILE: resp.files[r].filepath  });		
				 		}
				 		const tableData = this.state.tableData
			 			tableData.rows = rows
	 					this.setState({originalTableData:this.state.tableData});
	 					this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
		  	}
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	
	//COMPONENTS
	//manage image
	manageImageB = async(event) => {
		try{
			this.setState({isLoading:true});
			 
			var data = findObjName(event).split("_")
			
			let action =  data[0]
			if (action==="manageImage"){
				let objectNameB = data[1]
				let objImageB = await listImages(this.state.currFolderImagePath,objectNameB);
				this.setState({objImageB:objImageB});
				let imageManagerB = <ImageManager objImage={this.state.objImageB} manageImage={this.manageImageB} />
	 		 	this.setState({ imageManagerB:imageManagerB, modal22B: true});
			}else{
				var objImageB = await manageImage( event, this.state.objImageB)
				this.setState({objImageB:objImageB , currFolderImagePath: objImageB.route });
				let imageManagerB = <ImageManager objImage={this.state.objImageB} manageImage={this.manageImageB} />
				this.setState({imageManagerB:imageManagerB});
				if (objImageB.selected.length===1){
						let field = objImageB.objectName
						var formDataB = this.state.formDataB
						formDataB[field].value = objImageB.selected[0]
						this.setState({modal22B:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	manageComponentB = (event,eventColor ) => {
		try{
			if (eventColor && !event.target){
			
	  		var objName=  findObjName (eventColor)
	  		event["target"] = {name:objName }  
	  	}
	  	 
			this.setState({isLoading:true});
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
	 		var compManagerB
			let action =  data[0]
			var componentNameB = data[1]
			var _components= getSubcomponents() 
			
			if (_components.indexOf(action)>=0 ){
 
				if (action==="CompCard"){
					compManagerB = <CompCard      		components={this.state.ObjComponentsOrig} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Childs", componentNameB:componentNameB });
				}else if (action==="CompSliderImg"){
					compManagerB = <CompSliderImg 		components={this.state.ObjComponentsOrig} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Images", componentNameB:componentNameB });
				}else if (action==="CompForm"){
					compManagerB = <CompForm      		components={this.state.ObjComponentsOrig} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Form Element", componentNameB:componentNameB });
				}else if (action==="CompCardChild"){
					compManagerB = <CompCardChild  hideManager={true}	components={this.state.ObjComponentsOrig} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} filter={this.state.formDataB[componentNameB].filter	} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Childs", componentNameB:componentNameB });
				}else if (action==="CompButton"){
					compManagerB = <CompButton       components={this.state.ObjComponentsOrig} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Button", componentNameB:componentNameB });
				}else if (action==="CompCategoryBlog"){
					compManagerB = <CompCategoryBlog components={this.state.ObjComponentsOrig} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config Blog", componentNameB:componentNameB });
				}else if (action==="CompList"){
					compManagerB = <CompList components={this.state.ObjComponentsOrig} data={this.state.formDataB[componentNameB].values} container= {componentNameB} manageComponent={this.manageComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentTypeB:action, compManagerB:compManagerB, modal4B: true, titlemodal4B: "Config List", componentNameB:componentNameB });
				}else if (action==="CompItem"){
					
					let rowId = data[2]+"_"+data[3]
					let subcomponentNameB = componentNameB+"_"+rowId				
					
					let pos = find ( this.state.formDataB[componentNameB].values,  parseInt(data[3]) , "rowId" )
					var itemsB = this.state.formDataB[componentNameB].values[pos].items?this.state.formDataB[componentNameB].values[pos].items:[]
					
					let compManager2B = <CompItem  components={this.state.ObjComponentsOrig} data={itemsB} container= {subcomponentNameB} manageComponent={this.manageSubComponentB}  manageImage={this.manageImageOptionB} />
					this.setState({ subcomponentType2B:action, compManager2B:compManager2B, modal6B: true, titlemodal6B: "Config Items", subcomponentNameB:subcomponentNameB });
				}
			}else{
				if ( action ==""){
					
				}else{
					var formDataB = manageComponent( event, this.state.formDataB)
					this.setState({formDataB:formDataB});
				
					compManagerB = this.setSubComponentB(event)		
					this.setState({compManagerB:compManagerB});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	manageSubComponent = async(event) => {
		try{
			
			this.setState({isLoading:true});
	
			let resp = await manageSubComponent (event, this.state.formData)
		 
			this.setState({formData:resp.formData}); 
			let compManager2 
			if (this.state.subcomponentType2 == "CompItem"){
			compManager2 = <CompItem  components={this.state.objComponents} data={ resp.data } container= {resp.subcomponentName} manageComponent={this.manageSubComponent}  manageImage={this.manageImageOption} />	
			}
			this.setState({ compManager2:compManager2 });
			 
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	manageSubComponentB = async(event) => {
		try{
			
			this.setState({isLoading:true});
	
			let resp = await manageSubComponent (event, this.state.formDataB)
		 
			this.setState({formDataB:resp.formData}); 
			
			let compManager2B 
			if (this.state.subcomponentType2B == "CompItem"){
			compManager2B = <CompItem  components={this.state.ObjComponentsOrig} data={ resp.data } container= {resp.subcomponentName} manageComponent={this.manageSubComponentB}  manageImage={this.manageImageOptionB} />	
			}
			this.setState({ compManager2B:compManager2B});
			 
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	validateComponentB= async(event) => {		 
		try{
			this.setState({isLoading:true});
			var resp = await validateComponent( this.state.componentNameB, this.state.formDataB, toast)
			this.setState({formDataB:resp.formData});
	 		let compManagerB = this.setSubComponentB(event)	
	 		if (compManagerB!= null){
	 			this.setState({ compManagerB:compManagerB  });
	 		}
			if (resp.isValid){
				this.setState({modal4B:false});
			}else{
				showError(toast,"CHECK COMPONENT VALUES");	
			}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	validateComponent2B= async(event) => {		 
		try{
			this.setState({isLoading:true});
	 		let compManagerB = this.setSubComponentB(event)	
	 		if (compManagerB!= null){
	 			this.setState({ compManagerB:compManagerB, modal6B:false  });
	 		}
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	cancelComponentB= async(event) => {
		try{
			this.setState({isLoading:true});
			const formDataB = this.state.formDataB
			formDataB[this.state.componentNameB].values=[]
			this.setState({modal4B:false});
			let compManagerB = this.setSubComponentB(event)		
			this.setState({compManagerB:compManagerB});				
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	cancelComponent2B= async(event) => {
		try{
			this.setState({isLoading:true});
			this.setState({modal6B:false});			
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	
	setSubComponentB= (event) => {
		var compManager
		if(this.state.subcomponentTypeB==="CompCard"){
			compManager = <CompCard 					components={this.state.ObjComponentsOrig} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompSliderImg"){
			compManager = <CompSliderImg 			components={this.state.ObjComponentsOrig} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompForm"){
			compManager = <CompForm 					components={this.state.ObjComponentsOrig} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompCardChild"){
			compManager = <CompCardChild  hideManager={true}	 components={this.state.ObjComponentsOrig} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB}  filter={this.state.formDataB[this.state.componentNameB].filter	} /> 
		}else if(this.state.subcomponentTypeB==="CompButton"){
			compManager = <CompButton 			 	components={this.state.ObjComponentsOrig} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB}/>
		}else if (this.state.subcomponentTypeB==="CompCategoryBlog"){
			compManager = <CompCategoryBlog 	components={this.state.ObjComponentsOrig} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}else if (this.state.subcomponentTypeB==="CompList"){
			compManager = <CompList 	components={this.state.ObjComponentsOrig} data={this.state.formDataB[this.state.componentNameB].values} container= {this.state.componentNameB} manageComponent={this.manageComponentB} manageImage={this.manageImageOptionB} />
		}
		return compManager
	}
	
	changeHandlerB = async(event, eventColor) => {
    if (eventColor || !event.target){
  		var objName=  findObjName (eventColor)
  		event["target"] = {name:objName } 
  	}
  	//COMPONENT
  	if (event.target.name==="COMPONENT"){
  		let resp = await validateForm(this.state.formData3B, toast,event);
			this.setState({formData3B:resp.formData});
			
  	}else{
  		let resp = await validateForm(this.state.formDataB, toast,event);
			this.setState({formDataB:resp.formData});
  	}  
	} 
	onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };
  
  updateB=async (event) => {
  	this.executeB(event)
	}
	
	/*ABM manager*/
	executeB=async (event) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			resp = await validateForm(this.state.formDataB, toast);
  			 
  			if(resp.isValid){
			  	this.setState({  ...this.state, isLoading:true })
			  	var _formData =  this.state.formDataB;
			  	_formData = parseFormData(_formData)
					_formData["componenttypeid"] = this.state.componenttypeidB;
					_formData["category"] = this.state.category;
					_formData["componentid"] = this.state.currentIdB;
				
					let resp = await callApi ( "layaoutManager",this.state.formActionB+"Components", _formData )
					
			  	if (resp.success){
			  		await this.getComponents()
			  		let ref = await changeFilter({target:{name:"COMPONENTS__refresh"}}, this.state.ObjComponentsOrig , this.state.formData)	
			  		this.setState({formData:ref});
			  		showMessage(toast,resp.message);
			  		this.setState({...this.state,modal1B:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
		  	}
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>.",err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
    	
    	<Modal open={this.state.modal2B}
      	icon="fas fa-folder"
      	title="Master Category Folder"
      	zindex="1063"
      	message={this.state.masterFolderManager}
      	toggle={this.toggleModal ("2B")} 
      	class={this.state.modalLgClass}
      />
      
      <Modal open={this.state.modal22B}
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1067"
      	message={this.state.imageManagerB}
      	toggle={this.toggleModal ("22B")} 
      	class={this.state.modalLgClass}
      />
      
      <Modal open={this.state.modal2}
      	icon="fas fa-folder"
      	title="Category Folder"
      	zindex="1062"
      	message={this.state.folderManager}
      	toggle={this.toggleModal (2)} 
      	class={this.state.modalLgClass}
      />
      <Modal open={this.state.modal3}
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1062"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (3)} 
      	class={this.state.modalLgClass}
      />
      
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="del"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>													
													:this.state.formAction==="job"?
													<>
													<Form 
															formData={this.state.formData2} 
															
															changeHandler={this.changeHandler2} 
															formConfig={this.state.formConfig2}
													 />

														<table className="table" >
															<thead>
																	<tr className="main-heading">
																			<th scope="col"> JOB DATE </th>
																			<th scope="col"> JOB EXECUTION DATE</th> 
																			<th scope="col">STATUS</th>
																	</tr>
															</thead>
															<tbody> 
															{this.state.logs.map( (item,index) => ( index<5 &&
														 		<tr>
																	<td className=" ">
																		{item.JOB_DATE}
																	</td>
																	<td className=" ">
																		{item.EXE_DATE}
																	</td>
																	<td className=" ">
																		{item.RESP==""?"PND":item.RESP}
																	</td> 
															</tr>
															 ))
															} 	 
															</tbody>
														</table>
													</>
													: <Form 
															formData={this.state.formData} 
															onDragEnd={this.onDragEnd}
															manageComponent={this.manageComponent}
															manageImage={this.manageImage}
															changeFilter={this.changeFilter}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
															manageFolder={this.manageFolder}
													 />
												 }
							  />
			<Modal open={this.state.modal1B} 
								toggle={this.toggleModal ("1B")} 
								class={this.state.modalClassB}
								icon={this.state.modalIconB}
								title={this.state.modalTitleB}
								zindex="1066"
								buttons={this.state.buttonsModal1B}
								cancelButton={true}
								message={this.state.formActionB==="delete"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													: <Form 
															formData={this.state.formDataB} 
															manageImage={this.manageImageB}
															manageComponent={this.manageComponentB}
															changeHandler={this.changeHandlerB} 
															formConfig={this.state.formConfig}
															editorState={this.state.editorState}
															onEditorStateChange={this.onEditorStateChange}
													 />
												 }
							  />	
					<Modal open={this.state.modal6B}
		      	icon="fas fa-images"
		      	title={this.state.titlemodal6B}
		      	zindex="1069"
		      	message={this.state.compManager2B}
		      	buttons={this.state.buttonsModal6B}
		      	class={this.state.modalClass3}
		      />			 
		      <Modal open={this.state.modal4B}
		      	icon="fas fa-images"
		      	title={this.state.titlemodal4B}
		      	zindex="1068"
		      	message={this.state.compManagerB}
		      	buttons={this.state.buttonsModal4B}
		      	class={this.state.modalLgClass}
		      /> 
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Layout Manager"} subtitle={"Public Layout Templates"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
							<ExcelFile filename={"export-public-layouts."+this.state.date_label} element={         
						          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
						      }>
						          <ExcelSheet data={this.state.tableData.rows} name="Layouts">
						          		<ExcelColumn label="Layout Name"    value="LAYOUTNAME"/>
						          		<ExcelColumn label="URL"    value="LAYOUTURL"/>
						          		<ExcelColumn label="Layout Path"    value="LAYOUTFILE"/> 
						          </ExcelSheet>
							</ExcelFile>
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
	    		<div className="row mb-4 text-left">
						<div className="col-md-12 col-lg-12">
							<h5 className="flex middle"> Current Folder: <b className="mx-1"> {this.state.foldername}</b> 
								<button type="button" className="btn btn-sm btn-outline-info" 
									name="manageFolder" onClick={this.manageFolderMaster}>
									<i className="fas fa-folder-open" /> Change
								</button>
								<button type="button" className="btn btn-sm btn-outline-secondary" 
									name="setDefault" onClick={this.setDefault}>
									<i className="fas fa-check" /> Default
								</button>
							</h5>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 