	/*****components***/
	import React, { Component } from 'react';
	import { Switch } from "antd";
	import {toast,ToastContainer} from 'mdbreact';
	/*message*/
	import SidebarNav from "../sidebar";

	/*custom components*/
	import PageLoading from '../../components/PageLoading';
	import Table from '../../components/Table';
	import Modal from '../../components/Modal';
	import Form from '../../components/Form';
	import PageHeader from '../../components/PageHeader';
	import ImageManager from '../../components/ImageManager';
	/*functions*/
	import {getUserInfo,listImages, findObjName, getLanguages, manageImage, validateComponent, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

	const foldername= process.env.REACT_APP_FOLDER_LAYOUT
	class page extends Component{	
		constructor(props) {
			super(props);
			this.state = {
				show : null,
				modal1: false,
				modal2: false,
				modalMdClass:"modal-lg",
				modalLgClass:"modal-lg",
				isLoading: true,
				componentType:"",
				modalClass: "modal-full-height modal-lg",
				tags:[],

				/*modal info*/
				buttonsModal2 : [
					{	buttonAction: this.validateComponent,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Save"},
					{	buttonAction: this.cancelComponent,
						buttonId    : 2,
						buttonClass : "btn btn-danger",
						buttonName  : "Cancel "}	

				],

				/*table data*/
				tableData: {
					columns : [
						{ dataIndex:"STORE", title:"Store",
							sorter: (a, b) => a.STORE.length - b.STORE.length },
						{ dataIndex:"TAGNAME", title:"Name",
							sorter: (a, b) => a.TAGNAME.length - b.TAGNAME.length },
						{	dataIndex:"TAGDESC", title:"Tag",
							sorter: (a, b) => a.TAGDESC.length - b.TAGDESC.length },
						{	dataIndex:"FROMDATE", title:"From",
							sorter: (a, b) => a.FROMDATE.length - b.FROMDATE.length },
							{	dataIndex:"TODATE", title:"To",
							sorter: (a, b) => a.TODATE.length - b.TODATE.length },

						{ dataIndex: "TAGID", title: "Action",
				render: (text, record) => (<div className="text-right">
				 <button className="btn btn-sm btn-info" id={record.TAGID} name="update"
				 onClick={this.loadFormData}
				  type="submit">Edit</button>
				 <button className="btn btn-sm btn-danger" id={record.TAGID} name="delete"
				 onClick={this.loadFormData}
				  type="submit">Delete</button></div>
				)
			      }
					],
			    rows: [], 
				},
				/*modal info*/
				buttonsModal1 : [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				],

				modalIcon:"fas fa-edit",
				modalTitle:"New Tag",
				/*form data*/

				formConfig:{
					pageWidth: "card col-md-12 col-lg-12 col-xl-12",
					groupingRows: 1,
					languages:[]

				},
				formData	: {
					/*
					STOREID         : { label: "Store"            , type: "SELECT", value: "", values: [],  "addDefault":"CHOOSE ONE", getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"15"}] }  } ,
					TAGTYPE     : { label: "Store"            , type: "SELECT", value: "", values: [{text:"TOP", value:"TOP"}] } ,
					TAGNAME     : { label: "Name"             , validationType:"text"  , type: "TEXT", value:"" },
					TAGDESCS    : { label: "Tag"          , validationType:"text"  , type: "CompLanguage",   values: []  },
					SORTORDER       : { label: "Sort Order"       , validationType:"number", type: "TEXT",   value: "0"} ,
					FROMDATE        : { label: "From"             , validationType:"number", type: "DATEPICKER",   value: ""} ,
					TODATE          : { label: "To"               , validationType:"number", type: "DATEPICKER",   value: ""} ,

					LABEL1          : { label: "Style"            , type: "LABEL",  value: "Style" ,class:"h4-responsive"} , 
					BACKGROUND      : { label: "Background Color"  , 	notRequired:true   , type: "TEXT" , value:"" },
					COLOR           : { label: "Text Color"        , 	notRequired:true   , type: "TEXT" , value:"" },
					OPACITY         : { label: "Transparency"      , 	notRequired:true   , type: "TEXT" , value:"" }
					*/
				}, 
				/*support variables*/
				currentId:null,
				formAction: "set",
				deleteTag:"Are you sure to delete this item?",
				completeTag:[]
			}
		}
		/*loading data*/

		//manage image
		manageImage = async(event) => {
			try{
				this.setState({isLoading:true});

				var data
			if(event.target.name){
					data = (event.target.name).split("_")
				}else{
					data = (event.target.parentNode.name).split("_")
				}

				let action =  data[0]
				if (action==="manageImage"){
					let objectName = data[1]
					let objImage = await listImages("",objectName);
					this.setState({objImage:objImage});
					let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
					this.setState({ imageManager:imageManager, modal2: true});
				}else{
					var objImage = await manageImage( event, this.state.objImage)
					this.setState({objImage:objImage});
					let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
					this.setState({imageManager:imageManager});
					if (objImage.selected.length===1){
							let field = objImage.objectName
							var formData = this.state.formData
							formData[field].value = objImage.selected[0]
							this.setState({modal2:false});
					}
				}
			}catch(err){
				console.log(">>>>>>>>>>"+err)
			}finally{
				this.setState({isLoading:false});
			}
		} 

		getTagObj = async(event) => {
			let languages= await getLanguages()
			var rows = []

			var completeTag=[]
			for (let l in languages ){
				let resp = await callApi ( "manager","getTags", {languageid: languages[l].id, messagetype:null } )
				var tags=[]

				for (let k in resp.rows ){
					var aditionalconfig = JSON.parse( resp.rows[k].ADITIONALCONFIG)
					let conf ={
						name    : resp.rows[k].TAGNAME,
						label : resp.rows[k].TAGDESC,
						id    : resp.rows[k].TAGID, 
						style   : aditionalconfig.style, 
						from    : resp.rows[k].FROMDATE,
						to      : resp.rows[k].TODATE,
						storeId : resp.rows[k].STOREID?resp.rows[k].STOREID:"",
					} 
					tags.push(conf)
				}

				completeTag.push({language: languages[l].name,
													 languageId: languages[l].id, 
													tags: tags} )

				if (getUserInfo().languageid == languages[l].id ){
					for (let k in resp.rows ){
							rows.push(resp.rows[k])
					}
				}
			}
			const tableData = this.state.tableData
			tableData.rows = rows  
			let formData	= { 
					STOREID         : { label: "Store"            , notRequired:true       ,  type: "SELECT", value: "", values: [],  "addDefault":"ALL STORES", getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"15"}] }  } ,				

					TAGNAME     : { label: "Name"             , validationType:"text"  , type: "TEXT", value:"" },
					TAGDESCS    : { label: "Tag Label"   , validationType:"text"  , type: "CompLanguage",   values: languages  },


					FROMDATE        : { label: "From"             , type: "DATEPICKER",   value: new Date()} ,
					TODATE          : { label: "To"               , type: "DATEPICKER",   value: new Date()} ,
					LABEL1          : { label: "Style Tag"            , type: "LABEL",  value: "Style" , class:"h4-responsive"} , 
					IMAGEPATH       : { label: "Image"            , validationType:"text",  notRequired:true , type: "IMAGE",  value: ""  },
					POSITION        : { label: "Position Top"         , validationType:"text",  notRequired:true , type: "TEXT",  value: ""  },
					POSITIONB       : { label: "Position Bottom"         , validationType:"text",  notRequired:true , type: "TEXT",  value: ""  },
					ALIGN           : { label: "Align"            , 	notRequired:true   , type: "SELECT" , value:"right", values: [ { value: "right",text:"Right" }, { text: "Left",value:"left" }]  },
					BACKGROUND      : { label: "Background Color" , 	notRequired:true   , type: "COLOR" ,value:"", defaultvalue:"#FFF", size:"col-12" , sizesm:"col-sm-12", sizelg:"col-md-6", sizelg:"col-lg-6" },
					COLOR           : { label: "Text Color"       , 	notRequired:true   , type: "COLOR" ,value:"", defaultvalue:"#000", size:"col-12" , sizesm:"col-sm-12", sizelg:"col-md-6", sizelg:"col-lg-6" },
					OPACITY         : { label: "Transparency"     , 	notRequired:true   , type: "SELECT" , value:"", values: [ { text: "NO",value:"1" },{ value: "0.25",text:"75%" },{ value: "0.5",text:"50%" },{ value: "0.75",text:"25%" } ]  },
					FONTSIZE        : { label: "Font Size"         , 	notRequired:true   , validationType:"text"  , type: "TEXT", value:"" },
					FONTWEIGHT      : { label: "Font Weight"      , 	notRequired:true   , type: "SELECT" , value:"", values: [ { text: "Normal",value:"normal" },{ value: "bold",text:"Bold" }]  },
					TEXTDECORATION  : { label: "Text Decoration"  , 	notRequired:true   , type: "SELECT" , value:"", values: [ { text: "none",value:"normal" },{ value: "underline",text:"Underline" } ,{ value: "line-through",text:"Line Through" } ]  },


			};

				this.setState({  ...this.state, formData:formData, completeTag:completeTag })	
		} 

		componentDidMount = async() => {
			var pageId =	this.props.match.params.pageId;
			try{

				await this.getTagObj()	 									
				await preloadForm ( this.state.formData )


			}catch(err){
				console.log(">>>>>>>>>>"+err)
			}finally{
				this.setState({isLoading:false});
			}
		}	
		/*Form Functions*/



	  /*get value fields*/
	  changeHandler = async(event, eventColor) => {

		if (eventColor || !event.target.name){
			var objName=  findObjName (eventColor)
			event["target"] = {name:objName } 
		}
			let resp = await validateForm(this.state.formData, toast,event);
			this.setState({formData:resp.formData});
		} 

		toggleModal = nr => () => {
		  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
	  }

	  //add new
	  set=async (event) => {
		this.execute(event, "SET")
		}
		//edit
		update=async (event) => {
		this.execute(event, "UPDATE")
		}
		//delete
		delete=async (event) => {
		this.execute(event, "DELETE")
		}

		publishTag=async (event) => {
			this.setState({  ...this.state, isLoading:true})
			try{	 
				console.log( this.state.completeTag)

			let data= {"layoutname"  : "tags",
						       "replace"     : true,
						       foldername    : foldername+"private/TAGS/",
							     configuration : this.state.completeTag }
				let resp = await callApi ( "s3", "createUploadFile", data )
				this.setState({...this.state,isLoading:false });
				if (resp.success){
				showMessage(toast,resp.message);
			  }else{
				showError(toast,resp.message);
			  }


			}catch(err){
				console.log(">>>>>>>>>>"+err)
			}finally{
				this.setState({...this.state,isLoading:false });
			}
		}


		/*charge data from a selected Tag*/
		loadFormData=async (event) => {

			this.setState({  ...this.state, isLoading:true})
			try{	  	
			var buttonsModal1
			var modalIcon
			var modalTitle
			var formAction
			var itemId
			var modalClass
			if (event.target.name==="set"){
				buttonsModal1 = [
						{	buttonAction: this.set,
							buttonId    : 1,
							buttonClass : "btn btn-outline-primary",
							buttonName  : "Add"}
					]				
					modalIcon="fas fa-plus"
					modalTitle="New Tag"
					formAction="set"
					itemId = null
					modalClass =  "modal-full-height modal-lg"
					let loadData = await clearformData (this.state.formData)

					this.state.formData.TAGDESCS.value = undefined

			}else if (event.target.name==="update"){
					buttonsModal1 = [
						{	buttonAction: this.update,
							buttonId    : 1,
							buttonClass : "btn btn-outline-info",
							buttonName  : "Edit"}
					]				
					modalIcon="fas fa-edit"
					modalTitle="Edit Tag"
					itemId = event.target.id
					formAction="update"
				modalClass =  "modal-full-height modal-lg"

				let resp = await callApi ( "manager","getTagById",{tagid: itemId} )
				let loadData = await mergeFormData (this.state.formData,resp.TAG)

				var optionNames=[], options=[]
				resp.TAG.TAGDESCS.map(function (item) {
					optionNames.push( {  id: item.LANGUAGEID, name: item.LANGUAGE,  value: item.TAG })
				}); 

				this.state.formData.TAGNAME.value = resp.TAG.TAGDESCS[0].NAME
				this.state.formData.TAGDESCS.value = undefined
				this.state.formData.TAGDESCS.values = optionNames

				var aditionalconfig = JSON.parse( resp.TAG.ADITIONALCONFIG)


				this.state.formData.BACKGROUND.value = aditionalconfig.style.background
				this.state.formData.COLOR.value      = aditionalconfig.style.color
				this.state.formData.OPACITY.value    = aditionalconfig.style.opacity

				this.state.formData.FONTWEIGHT.value     = aditionalconfig.style["font-weight"]
				this.state.formData.FONTSIZE.value       = aditionalconfig.style["font-size"]

				this.state.formData.TEXTDECORATION.value = aditionalconfig.style["text-decoration"]

				this.state.formData.POSITION.value     = aditionalconfig.style["position"]
				this.state.formData.POSITIONB.value     = aditionalconfig.style["positionb"]
				
				
				this.state.formData.ALIGN.value       = aditionalconfig.style["align"]

				this.state.formData.IMAGEPATH.value       = aditionalconfig.style["imagepath"]

			}else if (event.target.name==="delete"){
				buttonsModal1 = [
						{	buttonAction: this.delete,
							buttonId    : 1,
							buttonClass : "btn btn-outline-danger",
							buttonName  : "Delete"}
					]				
					modalIcon = "fas fa-trash"
					modalTitle = "Delete Tag"
					itemId = event.target.id
					formAction="delete"
					modalClass =  ""
			}
		}catch(err){
				console.log(">>>>>>>>>>"+err)
			}finally{
				this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
			}
	  }

		/*ABM manager*/
		execute=async (event,actionType) => {
			try{	  	
			var resp = validateRole(this.props.location.pathname);
			if(resp){
				/*validation*/
				var respValid= true
				if (actionType==="DELETE"){
					respValid= true
				}else{
					resp = await validateForm(this.state.formData, toast);
					respValid = resp.isValid

				}
				if(respValid){
					this.setState({  ...this.state, isLoading:true })
						let _formData =  this.state.formData;
						_formData = parseFormData(_formData)
						_formData["tagid"] = this.state.currentId;

						var aditionalconfig = 
						{ 
							style :  {
								background        : _formData["background"],
								color             : _formData["color"],
								opacity           : _formData["opacity"],
								"font-weight"     : _formData["fontweight"],
								"font-size"       : _formData["fontsize"],
								"text-decoration" : _formData["textdecoration"],
								"position"        : _formData["position"],
								"positionb"       : _formData["positionb"],
								"align"           : _formData["align"],
								"imagepath"       : _formData["imagepath"]
							} 
						}



						_formData["aditionalconfig"] = aditionalconfig
						let resp = await callApi ( "manager",this.state.formAction+"Tags", _formData)

					if (resp.success){
						showMessage(toast,resp.message);
							await this.getTagObj()						
							this.setState({  ...this.state, modal1:false})								
							await preloadForm ( this.state.formData )
					}else{
						showError(toast,resp.message);
					}
				  } 
			  }else{
				showError(toast,"Role not allowed to make changes");
			  }
		}catch(err){
			showError(toast, "Internal Error");
				console.log(">>>>>>>>>>"+err)
			}finally{
				this.setState({...this.state,isLoading:false });
			}
		}


		render(){
	    return(
	    <>
	      <SidebarNav pageId={this.props.match.params.pageId} />
	      <PageLoading isLoading={this.state.isLoading}/>

	       <Modal open={this.state.modal2}
			icon="fas fa-images"
			title="Image Manager"
			zindex="1062"
			message={this.state.imageManager}
			toggle={this.toggleModal (2)} 
			class={this.state.modalLgClass}
		      />

	      <Modal open={this.state.modal1} 
									toggle={this.toggleModal (1)} 
									class={this.state.modalClass}
									icon={this.state.modalIcon}
									title={this.state.modalTitle}
									buttons={this.state.buttonsModal1}
									cancelButton={true}
									message={this.state.formAction==="delete"?
														<h2>{this.state.deleteTag}</h2>
														:
														 <Form 
																formData={this.state.formData} 
																manageImage={this.manageImage}
																changeHandler={this.changeHandler} 
																formConfig={this.state.formConfig}
														 />
													 }
								  />

	      <div className="page-wrapper">
			<div className="content container-fluid">
			<PageHeader title={"Layout Manager"} subtitle={"Tags"}/>

			<div className="row mb-4 justify-content-center text-right">
							<div className="col-md-12 col-lg-12">
									<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
								{	<button type="button" className="btn btn-sm btn-warning" name="set" onClick={this.publishTag}> Publish Tags</button>}
							</div>	
				</div> 

						<div className="row">
							<div className="col-md-12 col-lg-12">
								<div className="card card-table flex-fill">
									<Table tableData={this.state.tableData} />
								</div>
							</div>
						</div>	
			</div> 
		</div>
	    </>         
	    )
	  }
	}
	export default page; 