import React, { Component } from "react";
import { MDBPopover, MDBPopoverBody, MDBBtn, MDBContainer } from "mdbreact";
import Folder from "../../assets/images/folder.png";
import {validateNumber, findValueById,getLanguage, getImagePath,getDefValues,getCoordinatesStore } from '../../common/functions';

const windowHeight = window.innerHeight
		
const addRow = (props,  ini, numcol) => {
	let row=[]
	
	for (let key=ini; key< ini+ numcol ; key++){
		if(props.objImage.items[key]){
			row.push( 
					<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
						<div>
							<img 
								key={"img"+props.objImage.items[key].item} 
								name={"selectItem_"+props.objImage.items[key].item} 
								onClick={props.manageImage} 
								style={{"cursor": "pointer"}}
								/*className="mr-1 mt-2" */
								width="120px" height="120px" 
								src={  props.objImage.items[key].type==="folder"?Folder: getImagePath(props.objImage.items[key].path) } alt={props.objImage.items[key].name} />
							<p className="ml-2">
								<div className="checkbox">
									<label style={{ "word-break": "break-all" }}>
										<input name={"selectToDelete_"+props.objImage.items[key].item}  
													 checked={ props.objImage.items[key].checked?true:false}
													 value = {props.objImage.items[key].name} key={"check_"+props.objImage.items[key].item} 
												onChange={props.manageImage} type="checkbox"/> {props.objImage.items[key].label} </label>
								</div>
							 </p>
						</div>
				</div>
			)
		}
			
	}
	
	return row
}

const loadImageManager = props => {
	
	if (props.objImage.items){	
		const formStructure=[]
		for (let key=0; key < props.objImage.items.length; key = key+6){
			var row = addRow(props, key, 6 )
			formStructure.push(  
								<div key={"row_"+key} className="row ml-3"> 
									{row}
								</div>)
		}
	 	 
	  return (
	  <>
	  	<section className="comp-section">
        <div className="comp-header">
          <span className="h3-responsive ">  </span>
          
        <div className="card">
          <div className="card-body">
           <div className="row">
	          	<div className="col-sm-12">
           			{props.objImage.route}
	          	</div>
						</div>
	          <div className="row">
	          	<div className="col-sm-12 col-lg-9">
	          		<button type="submit" name="getParent"    className="btn-secondary btn btn-sm" onClick={props.manageImage}> <i className="fas fa-level-up-alt" /></button>
	          		<button type="submit" name="refresh"      className="btn-success  btn btn-sm"  onClick={props.manageImage}> <i className="fas fa-sync-alt" /></button>
	          		<button type="submit" name="showUploadFile"   className="btn-info btn btn-sm"      onClick={props.manageImage}> <i className="fas fa-upload" /></button>
	        			{props.objImage.showUploadFile?
		          		 <div className="popover fade show bs-popover-bottom" placement="top" style={{ "top": "26px", "left": "47px" }}>
					          <div>
					            <div className="popover-body">
					            	<div key={"divfolderName"} className={"form-group card-label"} >
													<div className="form-group">
                            <label>Upload File</label>
                            <div className="input-group mb-3">
															 <input  className="form-control" name="inputNewFile" type="file" onChange={props.manageImage} />
															<button disabled={ props.objImage.inputNewFile==null?true:false } type="submit" name="uploadFile"   className="btn-info btn btn-sm" onClick={props.manageImage}> <i className="fas fa-upload" /></button>
														</div>	
                          </div>
													
													
												</div>
												
						           </div>
						         </div>
						       </div>
	          		:""}
	        			<button type="submit" name="showFolderName" className="btn-secondary btn btn-sm" onClick={props.manageImage}> <i className="fas fa-folder-plus" /></button>  		
	          		{props.objImage.showFolderName?
		          		 <div className="popover fade show bs-popover-bottom" placement="top" style={{ "top": "26px", "left": "47px" }}>
					          <div>
					            <div className="popover-body">
					            	<div key={"divfolderName"} className={"form-group card-label"} >
													
													<div className="form-group">
                          	<label>New Folder</label>
                          	<div className="input-group mb-3">
                            	<input  key={"inputfolderName"} type="text" className={"form-control"}
																value={props.objImage.newFolderValue+""}
																name="newFolderValue"
																onChange={props.manageImage} />
															<button disabled={ props.objImage.newFolderValue==""?true:false } type="submit" name="createFolder"   className="btn-secondary btn btn-sm"      onClick={props.manageImage}> <i className="fas fa-folder-plus" /></button>
														</div>
                          </div>
													
												</div>
												
						           </div>
						         </div>
						       </div>
	          		:""}
	          		<button type="submit" name="deleteItems" className="btn-danger btn btn-sm" onClick={props.manageImage}> <i className="fas fa-trash-alt" /></button>
	          		{props.multipleSelect?
	          			<button type="submit" name="selectMultiple" className="btn-info btn btn-sm" onClick={props.manageImage}> <i className="far fa-check-circle" /> Select Files </button>
	          		:""}
	          		
	          		<input key={"filterValue"} type="text" className={"ml-1 search-file-input"}
																value={props.objImage.filterValue+""}
																name="filterValue"
																placeHolder={"Search items..."}
																onChange={props.manageImage} />
																
	          	</div>
	          	
	          	<div className="col-sm-12 col-lg-3 flex items-right">
	          	
	          		<ul className="pagination pagination-sm mb-0">
	                <li className="page-item">
	                  <button disabled={props.objImage.ContinuationToken==""?true:false}    className="btn btn-sm btn-outline-primary " name="ContinuationToken" onClick={props.manageImage}>Previous </button>
	                </li>
	                <li className="page-item">
	                  <button disabled={props.objImage.NextContinuationToken==""?true:false} className=" btn btn-sm btn-outline-primary" name="NextContinuationToken" onClick={props.manageImage}>Next</button>
	                </li>
	              </ul>
	          	
	          	</div>
	         		<div className="line mt-2 ml-3" />
	        		</div>
						</div>
	         
								{formStructure}
				
	          
	          
            <div className="row mb-2 mr-2">
	          	<div className="col-12 flex items-right">	          	
	          		<ul className="pagination pagination-sm mb-0">
	                <li className="page-item">
	                  <button disabled={props.objImage.ContinuationToken==""?true:false}    className="btn btn-sm btn-outline-primary " name="ContinuationToken" onClick={props.manageImage}>Previous </button>
	                </li>
	                <li className="page-item">
	                  <button disabled={props.objImage.NextContinuationToken==""?true:false} className=" btn btn-sm btn-outline-primary" name="NextContinuationToken" onClick={props.manageImage}>Next</button>
	                </li>
	              </ul>
	          	</div>
						</div>
            
            
          </div>
        </div>
      </section>
			
		</>
	  );
	}else{
		return "NO ITEMS DATA"
	}
};

export default loadImageManager;
				 