import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";
const windowHeight = window.innerHeight

const loadCardComp = props => {
	
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" style={{ "maxHeight": (windowHeight-240)+"px" , "overflow":"auto"  } }>
					{props.data.length>0?
		  			<div className="row">
							<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2 ml-1"}><span className="h6-responsive" >Header</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}><span className="h6-responsive" >Card Width</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}><span className="h6-responsive" >Body</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-2 col-xl-2"}><span className="h6-responsive" >Image</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-2 col-xl-2"}><span className="h6-responsive" >Image Alt</span></div>

							<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1"}> </div>
						</div>	
					:""}
				  {props.data.map(itemM => (
				  	<>
							<div className="row mb-1">
						 		<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
					 				<input key={props.container+"_header_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_header?"is-invalid":"")}
										value={itemM.header}
										name={"FIELDVALUE_"+props.container+"_header_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>						  	
						  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
					 				<input key={props.container+"_width_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_width?"is-invalid":"")}
										value={itemM.width}
										name={"FIELDVALUE_"+props.container+"_width_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>
						  	 
						  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
					 				<input key={props.container+"_body_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_body?"is-invalid":"")}
										value={itemM.body}
										name={"FIELDVALUE_"+props.container+"_body_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>		
						  	
						  	
						  	<div className={"col-sm-4 col-sm-4 col-lg-2 col-xl-2"}>
					 				<img 
											key={"img"+itemM.rowId} 
											name={"manageImage_"+itemM.rowId} 
											onClick={props.manageImage} 
											style={{"cursor": "pointer"}}
											width="90px" height="90px" 
											src={  itemM.image===""?defaultImage:itemM.image} />		
						  	</div>			
						  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
					 				<input key={props.container+"_imagealt_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_imagealt?"is-invalid":"")}
										value={itemM.imagealt}
										name={"FIELDVALUE_"+props.container+"_imagealt_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>		
						  	
						  	<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompCard_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-6 col-xl-6"}></div>
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompCard"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO CardComp DATA"
	}
};
export default loadCardComp;
				 