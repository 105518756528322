import React, { Component } from "react";
import { Table, Switch } from "antd";

import { itemRender, onShowSizeChange } from "../../common/functions";

const prepareColumns = (columns) => {
  let columnsHeader = [];
  let keys = Object.keys(columns);
  for (let key = 0; key < columns.length; key++) {
    var field = columns[keys[key]];
    let column = {
      key: field.dataIndex,
      id: field.dataIndex,
      title: field.title,
      dataIndex: field.dataIndex,
      //sorter: (a, b) => a[field.dataIndex].length - b[field.dataIndex].length,
      render: field.render,
    };
    if (field.render) {
      column["render"] = field.render;
    }
    if (field.sorter) {
      column["sorter"] = field.sorter;
    }

    columnsHeader.push(column);
  }
  return columnsHeader;
};

const loadTable = (props) => {
  if (props.tableData) {
    const tableData = props.tableData;

    const pagination = props.noPagination
      ? false
      : {
          total: tableData.rows.length,
          showTotal: (total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          itemRender: itemRender,
        };

    const columnsHeader = prepareColumns(props.tableData.columns);
    return props.filterConfig ? (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <div className="col-12 mt-2">
            <span className="h6-responsive ">
              <i className="fas fa-filter" /> Filter
            </span>
            <span className="or-line" />
          </div>
          {Object.keys(props.filterConfig).map((field) => (
            <div className="col-12">
              <div key={"div_form_" + field} className="form-group card-label">
                <label key={"label_form_" + field}>
                  {props.filterConfig[field].label}{" "}
                </label>
                {props.filterConfig[field].type == "SELECT" ? (
                  <select
                    key={"field_" + field}
                    name={field}
                    defaultValue={props.filterConfig[field].value}
                    onChange={props.filterHandler}
                    className="form-control"
                  >
                    {props.filterConfig[field].values.map((item) => (
                      <option key={"field_" + field + "_i" + item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    key={"field_" + field}
                    type="text"
                    className="form-control"
                    value={props.filterConfig[field].value}
                    name={field}
                    onChange={props.filterHandler}
                  />
                )}
              </div>
            </div>
          ))}
          <div className="col-12 mt-4">
            <button
              className="btn btn-success btn-block"
              name="search"
              onClick={props.filterHandler}
              type="submit"
            >
              Search
            </button>
          </div>
          <div className="col-12 mt-1">
            <button
              className="btn btn-info btn-block"
              name="refresh"
              onClick={props.filterHandler}
              type="submit"
            >
              Refresh
            </button>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columnsHeader}
            align="center"
            // bordered
            dataSource={tableData.rows}
            rowKey={(record) => record.id}
            showSizeChanger={true}
            pagination={pagination}
          />
        </div>
      </div>
    ) : (
      <Table
        className="table-striped"
        style={{ overflowX: "auto" }}
        columns={columnsHeader}
        align="center"
        scroll={props.scroll}
        // bordered
        dataSource={tableData.rows}
        rowKey={(record) => record.id}
        showSizeChanger={true}
        pagination={pagination}
      />
    );
  } else {
    return "NO TABLEDATA";
  }
};
export default loadTable;
