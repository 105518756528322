import React, { Component } from "react";
const windowHeight = window.innerHeight

const loadAttrib = props => {
	
	if (props.attributes && props.objAttributes){
		
		
	  return (
	  <>
	  	<div className="row">
				<div className="col-md-12 col-lg-12"  >		
	  			{props.objAttributes.length>0?
		  			<div className="row">
							<div className={"col-md-12 col-sm-12 col-lg-3 col-xl-3 ml-1"}><span className="h6-responsive" > Attribute</span></div>
							<div className={"col-md-12 col-sm-12 col-lg-6 col-xl-6"}><span className="h6-responsive"> Value</span></div>
							<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 "}> </div>
						</div>	
					:""}
					
				  {props.objAttributes.map(itemM => (
				  	<>
							<div className="row">
						 		<div className={"col-md-12 col-sm-12 col-lg-3 flex middle col-xl-3"}>
						 			<div  className="form-group card-label" >
										<label >{ "Attribute"}  </label>
						 				<select name={props.container	+"_attributeId_"+itemM.rowId} defaultValue={itemM.attributeId} onChange={props.changeHandler} className={"form-control" }>
				        			{props.attributes.map(item => (
				        		 		<option key={"attr_i"+item.value} value={item.value}>{item.text}</option>
				        		 	))
				            	} 
										</select>
				        	</div>
						  	</div>
						 		<div className={"col-md-12 col-sm-12 col-lg-6  col-xl-6 mt-4"}>
							        {itemM.languages.map(item => (
								      	<div className="row">
					              	<div className={"col-md-12 col-sm-12 col-lg-12  col-xl-12"}>
					              		<div  style={{"margin-top": "-2rem"}} className="form-group card-label" >
															<label >{item.languagetitle} </label>
						              		<textarea className={"form-control"+ (item.isInvalid_language?"is-invalid":"")}
																value={item.languagevalue}
																rows="2"
																name={props.container+"_attributevalue_"+itemM.rowId+"_"+item.languageid}
																onChange={props.changeHandler} />
														</div>
													</div>
												</div>
								       ))
							        }
					    	</div>
					    	<div className={"col-md-1 col-sm-1 col-lg-1  col-xl-1 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={props.container+"_"+itemM.rowId} onClick={props.delRow}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>  
				    	
					  </>
					  ))
						} 
						{props.addRow?
							<div className="row">
								<div className={"col-md-12 col-sm-12 col-lg-3  col-xl-3"}></div>
								<div className={"col-md-12 col-sm-12 col-lg-6  col-xl-6"}></div>
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}> <button  className="btn btn-info btn-sm"  name={props.container}
					         onClick={props.addRow} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>  
			
		</>
	  );
	}else{
		return "NO ATTRIB DATA"
	}
};
export default loadAttrib;
				 