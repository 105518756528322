import React from "react";
import Slider from "react-slick";
import ARROW_next1 from "../../assets/images/next.png";
import ARROW_prev1 from "../../assets/images/prev.png";

import ARROW_next2 from "../../assets/images/next2.png";
import ARROW_prev2 from "../../assets/images/prev2.png";

const windowWidthDef = window.innerWidth

function NextArrow1(props) {
  const {className, style, onClick} = props
    return (
        <div
            className="slick-arrow"
            style={{...style, 
            	"display": "block", 
            	"position": "absolute",
            	"top": "50%",            	
            	"marginTop": "-22px",
            	"zIndex": "100",
            	"cursor": "pointer",
            	"left":  (windowWidthDef <776 ?"94%": "98%"),
            	"opacity": "0.6"
            	 }}
            onClick={onClick}
        >
            <img src={ARROW_next1} height="25px" width="25px" alt="arrow_next"/>
        </div>
    );
}

function PrevArrow1(props) {
  const {className, style, onClick} = props
    return (
        <div
            className="slick-arrow"
            style={{...style, 
            	"display": "block", 
            	"position": "absolute",
            	"top": "50%",            	
            	"marginTop": "-22px",
            	"zIndex": "100",
            	"cursor": "pointer",
            	"right": (windowWidthDef <776 ?"94%": "98%"),
            	"opacity": "0.6"
            	 }}
            onClick={onClick}
        >
            <img src={ARROW_prev1} height="25px" width="25px" alt="arrow_prev"/>
        </div>
    );
}

function NextArrow2(props) {
  const {className, style, onClick} = props
   
    return (
        <div
            className="slick-arrow"
            style={{...style, 
            	"display": "block", 
            	"position": "absolute",
            	"top": "50%",            	
            	"marginTop": "-14px",
            	"zIndex": "100",
            	"cursor": "pointer",
            	"left": props.props.sliderConfig.outside?"100%":"94%",       
            	"marginLeft": "-20px"
            	 }}
            onClick={onClick}
        >
            <img src={ARROW_next2} height="35px" width="35px" alt="arrow_next"/>
        </div>
    );
}

function PrevArrow2(props) {
  const {className, style, onClick} = props
    return (
    
        <div
            className="slick-arrow"
            style={{...style, 
            	"display": "block", 
            	"position": "absolute",
            	"top": "50%",            	
            	"marginTop": "-14px",
            	"zIndex": "100",
            	"cursor": "pointer",
            	"right": props.props.sliderConfig.outside? (windowWidthDef <776 ?"94%": "98%") :"94%",         
            	"marginLeft": "-20px"
            	 }}
            onClick={onClick}
        >
            <img src={ARROW_prev2} height="35px" width="35px" alt="arrow_prev"/>
        </div>
    );
}


const loadSlider = props => {
	if (props.sliderConfig){
		const settings = props.sliderConfig
	//	settings["adaptiveHeight"] = true
		
		settings["nextArrow"] =  <NextArrow1 props={props}/>
		settings["prevArrow"] =  <PrevArrow1 props={props}/>
		
		if (settings["specialArrows"]){
			if (settings["specialArrows"] =="1" ){
				settings["nextArrow"] =  <NextArrow1 props={props}/>
				settings["prevArrow"] =  <PrevArrow1 props={props}/>
			}else if (settings["specialArrows"] =="2" ){
				settings["nextArrow"] =  <NextArrow2 props={props}/>
				settings["prevArrow"] =  <PrevArrow2 props={props}/>
			}
		}
		
	
	  return ( 
   		<div className= {(props.sliderConfig.divwidth? props.sliderConfig.divwidth: "card mobile-col col-md-12 col-lg-12 col-xl-12")+ 
   			(props.sliderConfig.dots? " mb-50":"" ) }   style={{ width: "100%", "borderRadius": "5px"}}>
       
        <Slider className={ !props.sliderConfig.arrows?"csliderNoArrows":""} {...settings}>
        
	        {props.sliderData.map((field, index) => (
	        	<div key={"div1"+index} className={"cslider" + (  props.sliderConfig.spaceitems?" space-items-slider":""  )} >
	        		{field.header?
            		<span key={"span1"+index} className="h4-responsive">
	          	 		{field.header}
	          		</span>
             	:""}
             	{field.body?
            		<div key={"div2"+index} className="mt-1">
	          	 		{field.body}
	          		</div>
             	:""}
             	{field.imagen?
            		<div key={"div3"+index} className="mt-1">
	          	 		{field.imagen}
	          		</div>
             	:""}
	        	</div>
  				))
 					} 
        </Slider>
    	</div>
	  );
	}else{
		return "NO CONFIG"
	}
};
export default loadSlider;
				 