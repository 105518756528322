import React from 'react';
import AppContainer from './appcontainer.jsx';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { ToastContainer } from "mdbreact";


const AppRouter = function (props) {
		return(
			<>
       	<Router>
        	<Route render={(props)=> <AppContainer {...props}/>} />
				</Router>
        <ToastContainer
	          closeButton={false}
	          autoClose={3000}
	          hideProgressBar={true}
	          position={"bottom-right"} />
    	</>
    );
}


export default AppRouter;