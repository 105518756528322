/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
import CompCard from '../../components/CompCard';


/*functions*/
import {changeFilter,find, filterHandler,reorder, move, onDragEnd, validateComponent,manageComponent, getUserInfo,getComponentConfiguration,manageImage, listImages, validateRole, showWarning,encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

const foldername= process.env.REACT_APP_FOLDER_LAYOUT+"private/"
const url_front= process.env.REACT_APP_URL_FRONT_CONFIG 
const url_aws= process.env.REACT_APP_PATH_FILES
class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			date_label:date_label,
			modal1: false,
			 
			isLoading: true,
			modalClass: "modal-full-height modal-lg",
			modalLgClass:"modal-lg",
			modalClass3:"modal-sm",
			
			filterConfig: {
				LAYOUTNAME :{ label:"Layout Name" ,  type:"TEXT"   , value:""} 
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"LAYOUTNAME", title:"Layout Name",
						sorter: (a, b) => a.LAYOUTNAME.length - b.LAYOUTNAME.length },
					{ dataIndex:"LAYOUTURL", title:"URL",
						sorter: (a, b) => a.LAYOUTURL.length - b.LAYOUTURL.length,
						render: (text, record) => (<a href={record.LAYOUTURL} target="_blank"> {record.LAYOUTURL}</a>)
		      },
					{ dataIndex:"LAYOUTFILE", title:"Layout Path",
						sorter: (a, b) => a.LAYOUTFILE.length - b.LAYOUTFILE.length },
					 
					{ dataIndex: "LAYOUTID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-sm btn-info" id={record.LAYOUTFILE} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-success" id={record.LAYOUTFILE} name="refresh"
		         onClick={this.loadFormData}
		          type="submit">Refresh</button> 
		          
				  <button className="btn btn-sm btn-secondary" id={record.LAYOUTFILE} name="program"
		         onClick={this.loadFormData}
		          type="submit">Program</button> 
		          

		          
		         <button className="btn btn-sm btn-danger" id={record.LAYOUTNAME} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
	
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"},
				{	buttonAction: this.loadFormData,
						buttonId    : 2,
						buttonClass : "btn btn-success",
						buttonName  : "Program"}
			],
			 
			modalIcon:"fas fa-edit",
			modalTitle:"New Component",
			/*form data*/			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1
			},
			formConfig2:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1
			},
			formData	: { LAYOUTNAME   : { label: "Layout Teplate Name",   type: "TEXT",   value: ""  },
										COMPONENTS   : { label: "Component List" , "type": "CompDND", values: [] , value:[] , filterValue:"",
											               filterValues:[], filterItems: ["category", "type"] } 
									},

			formData2	: { JOBDATE   :{ label: "Job Date"      ,   size:"col-12"        , type: "DATEPICKER",   value: new Date()} ,
								},

			/*support variables*/
			currentName:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			/*images or files*/
			initialFolder:"",
			imageManager:null,
			compManager:null,
			objImage:{
				items:[],
				selected:[]
			},
			 ObjComponentsOrig:[],
			 ObjComponents:[]
		}
	}
	
	
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	
	 	try{
	 		let resp = await callApi ( "s3","listFiles", {foldername:foldername} )
	 		let rows = []
	 		for (let r in resp.files){
	 			if ( resp.files[r].filename!=foldername ){
	 				let data = resp.files[r].filepath.split("/")
	 				let url =  url_front+"/"+data [(data.length-1) ]
	 				rows.push( { LAYOUTNAME: resp.files[r].filename.replace(foldername, "") , LAYOUTURL: url, LAYOUTFILE: resp.files[r].filepath  });		
	 			}
	 		}
	 		
	 		const tableData = this.state.tableData
	 		tableData.rows = rows
	 		this.setState({originalTableData:this.state.tableData});
	 		await preloadForm ( this.state.formData )
	 		
	 		await this.getComponents()
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
  getComponents=async (event) => {
		 
		try{	
	  	var resp = await callApi ( "layaoutManager","getComponents" )
	 		var components = []
	 		for (let c in resp.rows){
	 				components.push ( {
	 					value: resp.rows[c].COMPONENTID,
	 					text : resp.rows[c].COMPONENTNAME,
	 					type:  resp.rows[c].COMPONENTCODE,
	 					data:  JSON.parse( resp.rows[c].DATA),
	 					config:  JSON.parse( resp.rows[c].CONFIG),
	 					category:  resp.rows[c].CATEGORY?resp.rows[c].CATEGORY: "",
	 				})
	 		}
	 		this.setState({ObjComponentsOrig:components});
	 		
	 		this.setState({ObjComponents:components});
	 		const formData = this.state.formData
	 		formData.COMPONENTS.values=components
	 		
	 		var _filterValues =formData.COMPONENTS.filterItems
	 		
	 		var filterValues =[]	 		
			for (let f in _filterValues){
				var label = _filterValues[f]
				var values =["ALL"]
				for (let v in formData.COMPONENTS.values){
					try{
						if (values.indexOf( formData.COMPONENTS.values[v][label] ) <0 )	{
							values.push( formData.COMPONENTS.values[v][label])
						}
					}catch(Exc){
						console.log(">>>> ERROR GETTING FILTER VALUE:",)
					}
				}
				filterValues.push ( {label:label,values:values, value: "ALL"} )
			}
			formData.COMPONENTS.filterValues= filterValues
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
			this.setState({ObjComponentsOrig:[]});
			this.setState({ObjComponents:[]});
	 		const forData = this.state.formData
	 		forData.COMPONENTS.values=[]
		}
	}
	/*charge data from a selected user*/
	loadFormData=async (event) => {
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"},
					{	buttonAction: this.loadFormData,
							buttonId    : 2,
							buttonClass : "btn btn-success",
							buttonName  : "Program"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Layout"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				
				await this.getComponents();
				
				const formData = this.state.formData
	 		 
	 		
				formData.LAYOUTNAME.value=""
				formData.COMPONENTS.values= this.state.ObjComponentsOrig
				formData.COMPONENTS.filterValue= ""
				
				formData.COMPONENTS.value= []
				this.setState({...this.state,modal1:true,isLoading:false, ObjComponents: this.state.ObjComponentsOrig, currentName :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"},
					{	buttonAction: this.loadFormData,
							buttonId    : 2,
							buttonClass : "btn btn-success",
							buttonName  : "Program"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Layout"
				formAction="update"
				modalClass =  "modal-full-height modal-lg"
				let itemId = event.target.id 
				await this.getComponents();
	  		let resp = await callApi ( "layaoutManager","getLayoutTemplateByName",{layoutFile: itemId} )
	  		
	  		let configuration = resp.template;   
	  		const formData = this.state.formData
	 		  formData.COMPONENTS.filterValue= ""
	 		 	let url = itemId.split("/")
	 			let layoutname = url[url.length-1]
	 			
	 			let availComp=[]
	 			let usedComp=[]	 			
	 			this.state.ObjComponentsOrig.map(function (item) {	 				
	 				let isUsed=false;
	 				for (let key in configuration){
	 					if (item.value == configuration[key].componentid && !configuration[key].isChildren ){
	 						isUsed= true;
	 					}
	 				}
	 				if (!isUsed){
	 					availComp.push(item)	
	 				}
	 			})
	 			
	 			for (let c in configuration){
		 			for (let o in  this.state.ObjComponentsOrig){
						if (this.state.ObjComponentsOrig[o].value == configuration[c].componentid && !configuration[c].isChildren ){
						 	usedComp.push(this.state.ObjComponentsOrig[o])
						}
					}
	 			}
	 	 
				formData.LAYOUTNAME.value=layoutname
			  formData.COMPONENTS.values= availComp
			  formData.COMPONENTS.value= usedComp
				 
	  		this.setState({...this.state,modal1:true, ObjComponents:availComp, isLoading:false, currentName :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
	  	}else if (event.target.name==="refresh"){
	  		let itemId = event.target.id 
	  		
	  		await this.getComponents();
		 		
	  		let resp = await callApi ( "layaoutManager","getLayoutTemplateByName",{layoutFile: itemId} )
	  		
	  		let configuration = resp.template;   
	  		const formData = this.state.formData
	 		 
	 		 	let url = itemId.split("/")
	 			let layoutname = url[url.length-1]
	 			
	 			let availComp=[]
	 			let usedComp=[]	 			
	 			this.state.ObjComponentsOrig.map(function (item) {
	 				let isUsed=false;
	 				for (let key in configuration){
	 					if (item.value == configuration[key].componentid && !configuration[key].isChildren ){
	 						isUsed= true;
	 					}
	 				}
	 				if (!isUsed){
	 					availComp.push(item)	
	 				}
	 			})
	 			
	 			for (let c in configuration){
		 			for (let o in  this.state.ObjComponentsOrig){
						if (this.state.ObjComponentsOrig[o].value == configuration[c].componentid && !configuration[c].isChildren ){
						 	usedComp.push(this.state.ObjComponentsOrig[o])
						}
					}
	 			}
	 	 
				formData.LAYOUTNAME.value=layoutname
			  formData.COMPONENTS.values= availComp
			  formData.COMPONENTS.value= usedComp
			  
			  this.setState({...this.state, currentName :itemId });
			  var _formData =  this.state.formData;
			  
			  resp = await validateForm(this.state.formData, toast);  		
			    	
  			_formData = parseFormData(_formData) 
			   
			  configuration=[]				 
			  for (let c in _formData.components ){
			  	configuration.push({
			  		componentid   : _formData.components[c].value,
				  	componentType : _formData.components[c].type,
				  	name          : _formData.components[c].text,
				  	content       : _formData.components[c].data.content,
				  	data          : _formData.components[c].data.data,
				  	config        : _formData.components[c].config.config
			  	})
			  	const _parentData = _formData.components[c] 
			  	if (_parentData.data.data.children){
					
					console.log("tiene hijo", _parentData.data)
			  		for (let c in this.state.ObjComponentsOrig ){ 
				  		if (this.state.ObjComponentsOrig[c].value == _parentData.data.data.children){					  			
				  			_parentData.data.data.children = this.state.ObjComponentsOrig[c].text					  			
					  		configuration.push({
						  		componentid   : this.state.ObjComponentsOrig[c].value,
							  	componentType : this.state.ObjComponentsOrig[c].type,
							  	name          : this.state.ObjComponentsOrig[c].text,
							  	isChildren		: true,
									parent				: _parentData.text,					
							  	content       : this.state.ObjComponentsOrig[c].data.content,
							  	data          : this.state.ObjComponentsOrig[c].data.data,
							  	config        : this.state.ObjComponentsOrig[c].config.config
						  	})
						  }
			  		}
			  	} 
			  	if (_parentData.data.content){				  		
			  			for (let p in _parentData.data.content){
			  				var _parentChild = _parentData.data.content[p]
			  				if(_parentChild.children){
						  		for (let c in this.state.ObjComponentsOrig ){
							  		if (this.state.ObjComponentsOrig[c].text == _parentChild.children){ 
								  		configuration.push({
									  		componentid   : this.state.ObjComponentsOrig[c].value,
										  	componentType : this.state.ObjComponentsOrig[c].type,
										  	name          : this.state.ObjComponentsOrig[c].text,
										  	isChildren		: true,
												parent				: _parentData.text,					
										  	content       : this.state.ObjComponentsOrig[c].data.content,
										  	data          : this.state.ObjComponentsOrig[c].data.data,
										  	config        : this.state.ObjComponentsOrig[c].config.config
									  	})
									  }
						  		}
						  	}
			  			}
			  	}
				}	
			  let data= {"layoutname"   : _formData.layoutname,
					       "replace"     : true,
					       foldername    : foldername,
						     configuration }

							 console.log(JSON.stringify(configuration))
				resp = await callApi ( "s3", "createUploadFile", data )
				
				if (resp.success){
		  		showMessage(toast,resp.message);
	 			/*
		 			resp = await callApi ( "s3","listFiles", {foldername:foldername} )
 					let rows = []
 					for (let r in resp.files){
			 			if ( resp.files[r].filename!=foldername ){
			 				rows.push( { LAYOUTNAME: resp.files[r].filename.replace(foldername, "") , LAYOUTFILE: resp.files[r].filepath });		
			 			}
			 		}
			 		const tableData = this.state.tableData
		 			tableData.rows = rows*/
		  	}else{
		  		showError(toast,resp.message);
		  	}
			  this.setState({...this.state,isLoading:false });			
	  	
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Layout"
				itemId = event.target.id
				formAction="del"
				modalClass =  ""
				this.setState({...this.state,modal1:true,isLoading:false, currentName :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
	  	}else if (event.target.name==="program"){
			buttonsModal1 = [
				  {	buttonAction: this.program,
					  buttonId    : 1,
					  buttonClass : "btn btn-outline-danger",
					  buttonName  : "Program"}
			  ]				
			  modalIcon = "fas fa-timer"
			  modalTitle = "Program Layout"
			  itemId = event.target.id
			  formAction="job"
			  modalClass =  ""

			  var _formData ={} 
			  var components=[]
			  var isValid= true
			  var fileName= ""
			  var logs=[]
			  if (event.target.id ==="1" || event.target.id ==="2" ){
				_formData = this.state.formData;
				let resp = await validateForm(this.state.formData, toast);  				
				_formData = parseFormData(_formData)  				
				
				for (let i in _formData.components){
					components.push (_formData.components[i].value )					
				}
				fileName= this.state.currentName	
				isValid= resp.isValid

  				if (this.state.formAction==="set"){
  					this.state.tableData.rows.map(function (item) {
		 					if (item.LAYOUTNAME == _formData["layoutname"] ){
								isValid=false
  							showError(toast,"NAME ALREADY EXISTS");
		 					}
			 			})
  				}
  				if(_formData.components.length<1){
					isValid=false
  					showError(toast,"CHOOSE AT LEAST ONE ITEM");
  				}  
				let resp2 = await callApi ( "layaoutManager","getLogComponentById",{layoutFile: this.state.currentName} )
				for (let l in resp2.logs){
					logs.push(resp2.logs[l])
				}
			  }else{
				let resp = await callApi ( "layaoutManager","getLayoutTemplateByName",{layoutFile: itemId} )
				
				for (let i in resp.template){
					if (!resp.template[i].isChildren){
						components.push (resp.template[i].componentid )					
					}
					
				}
 
				let resp2 = await callApi ( "layaoutManager","getLogComponentById",{layoutFile: itemId} )
				for (let l in resp2.logs){
					logs.push(resp2.logs[l])
				}
			  }
			  
			  if (isValid){
				this.setState({...this.state,modal1:true, logs:logs, fileName:fileName,components:components, isLoading:false, currentName :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
			  }else{
				this.setState({...this.state, isLoading:false})
			  }
		}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}
  }
  
  //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 

  changeHandler2 = async(event) => {
		let resp = await validateForm(this.state.formData2, toast,event);
		this.setState({formData2:resp.formData});
  } 
  ///get value fields
  changeHandler = async(event) => {
  		let resp = await validateForm(this.state.formData, toast,event);
			this.setState({formData:resp.formData});
	} 
	
	///get filter to components
  changeFilter = async(event) => {
  	
  	
  	var resp = await changeFilter(event, this.state.ObjComponentsOrig , this.state.formData)	
    this.setState({formData:resp});
    
	} 
	//get drag movement
	onDragEnd = result => {
    var resp = onDragEnd(result, this.state.formData)	
  }
  
  //show/hide modal
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	 //program
	program=async (event) => {
		this.execute(event, "PROGRAM")
	}   

	deleteJob=async (componentid, pos) => {
		this.setState({isLoading2:true});
		let resp = await callApi ( "layaoutManager", "deleteComponents", {componentid:componentid } )
		if (resp.success){
			showMessage(toast,resp.message);		
			const logs= this.state.logs
			let pos = find(logs,componentid, "COMPONENTID")
			console.log(logs, pos)
			logs.splice(pos, 1)
			this.setState({isLoading2:false});
		}else{
			showError(toast,resp.message);
			this.setState({isLoading2:false});
		}
	}  

	/*ABM manager*/
	execute=async (event,actionType) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		var _formData =  this.state.formData;
			  	
  		if(resp){
  			/*validation*/
  			if (actionType==="DELETE" || actionType==="PROGRAM" ){  				
  				resp= {isValid:true}
  			}else{
  				resp = await validateForm(this.state.formData, toast);  				
  				_formData = parseFormData(_formData)  				
  				if (actionType==="SET"){
  					this.state.tableData.rows.map(function (item) {
		 					if (item.LAYOUTNAME == _formData["layoutname"] ){
		 					 	resp["isValid"]=false
  							showError(toast,"NAME ALREADY EXISTS");
		 					}
			 			})
  				}
  				if(_formData.components.length<1){
  					resp["isValid"]=false
  					showError(toast,"CHOOSE AT LEAST ONE ITEM");
  				}
  			}
  			if(resp.isValid){
			  	this.setState({  ...this.state, isLoading:true })
				  var configuration=[]			
				  console.log( _formData.components)	 
				  for (let c in _formData.components ){
				  	configuration.push({
				  		componentid   : _formData.components[c].value,
					  	componentType : _formData.components[c].type,
					  	name          : _formData.components[c].text,
					  	content       : _formData.components[c].data.content,
					  	data          : _formData.components[c].data.data,
					  	config        : _formData.components[c].config.config
				  	})
				  	const _parentData = _formData.components[c]
				  	if (_parentData.data.data.children){
				  		for (let c in this.state.ObjComponentsOrig ){
					  		if (this.state.ObjComponentsOrig[c].value == _parentData.data.data.children){					  			
					  			_parentData.data.data.children = this.state.ObjComponentsOrig[c].text					  			
						  		configuration.push({
							  		componentid   : this.state.ObjComponentsOrig[c].value,
								  	componentType : this.state.ObjComponentsOrig[c].type,
								  	name          : this.state.ObjComponentsOrig[c].text,
								  	isChildren		: true,
										parent				: _parentData.text,					
								  	content       : this.state.ObjComponentsOrig[c].data.content,
								  	data          : this.state.ObjComponentsOrig[c].data.data,
								  	config        : this.state.ObjComponentsOrig[c].config.config
							  	})
							  }
				  		}
				  	} 
				  	if (_parentData.data.content){				  		
				  			for (let p in _parentData.data.content){
				  				var _parentChild = _parentData.data.content[p]
				  				if(_parentChild.children){
							  		for (let c in this.state.ObjComponentsOrig ){
								  		if (this.state.ObjComponentsOrig[c].text == _parentChild.children){ 
									  		configuration.push({
										  		componentid   : this.state.ObjComponentsOrig[c].value,
											  	componentType : this.state.ObjComponentsOrig[c].type,
											  	name          : this.state.ObjComponentsOrig[c].text,
											  	isChildren		: true,
													parent				: _parentData.text,					
											  	content       : this.state.ObjComponentsOrig[c].data.content,
											  	data          : this.state.ObjComponentsOrig[c].data.data,
											  	config        : this.state.ObjComponentsOrig[c].config.config
										  	})
										  }
							  		}
							  	}
				  			}
				  	}
				  	
				  }  
				  let data, resp
				  if (actionType==="DELETE"){
				  	data= { "filename"   :  foldername+this.state.currentName }				  	
				  	resp = await callApi ( "s3", "deleteFile", data )
				  }else if (actionType==="PROGRAM"){

					let _formData =  this.state.formData2;
					_formData = parseFormData(_formData)
					_formData["components"] = this.state.components
					//console.log(this.state.components)
					if (this.state.fileName===""){
						_formData["filename"] = this.state.currentName		
					}else{
						_formData["filename"] = this.state.fileName
					}
					resp = await callApi ( "s3", "programFile", _formData )
				  
				  }else{
				  	if (actionType==="UPDATE"){
				  		let dataUpd= { "filename"   :  this.state.currentName.replace(url_aws+"/","") }	
				  		if (this.state.currentName.replace(url_aws+"/","") != foldername+_formData.layoutname ){
				  			let respUpd = await callApi ( "s3", "deleteFile", dataUpd )
				  		}
				  	}
				  	data= {"layoutname"   : _formData.layoutname,
					       "replace"     : actionType==="UPDATE"?true: false,
					       foldername    : foldername,
						     configuration }
						resp = await callApi ( "s3", "createUploadFile", data )
				  }				  
			  	if (resp.success){
			  		showMessage(toast,resp.message);
		 			
			 			resp = await callApi ( "s3","listFiles", {foldername:foldername} )
	 					let rows = []
	 					for (let r in resp.files){
				 			let data = resp.files[r].filepath.split("/")
	 						let url =  url_front+"/"+data [(data.length-1) ]
			 				rows.push( { LAYOUTNAME: resp.files[r].filename.replace(foldername, "") , LAYOUTURL: url, LAYOUTFILE: resp.files[r].filepath  });		
				 		}
				 		const tableData = this.state.tableData
			 			tableData.rows = rows
	 					this.setState({originalTableData:this.state.tableData});
	 					this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
		  	}
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
       
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="del"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													:this.state.formAction==="job"?
													<>
													<Form 
															formData={this.state.formData2} 
															
															changeHandler={this.changeHandler2} 
															formConfig={this.state.formConfig2}
													 />

														<table className="table" >
															<thead>
																	<tr className="main-heading">
																			<th scope="col"> JOB DATE </th>
																			<th scope="col"> JOB EXECUTION DATE</th> 
																			<th scope="col">STATUS</th>
																			<th scope="col">ACTION</th>
																	</tr>
															</thead>
															<tbody> 
															{!this.state.isLoading2 && this.state.logs.map( (item,index) => ( index<5 &&
														 		<tr>
																	<td className=" ">
																		{item.JOB_DATE}
																	</td>
																	<td className=" ">
																		{item.EXE_DATE}
																	</td>
																	<td className=" ">
																		{item.RESP=="-"?"PND":item.RESP}
																	</td> 
																	<td className=" ">
																	{item.RESP=="-" ?
																		<button  className="btn btn-danger btn-sm"  name={"DELROW_"+item.COMPONENTID} onClick={()=>{this.deleteJob(item.COMPONENTID)}}
																		type="button"> <i className="fas fa-trash"  ></i> </button>
																	:
																		<button  className="btn btn-danger btn-sm" disabled type="button"> <i className="fas fa-trash"  ></i> </button>
																		
																	}
																	</td> 
															</tr>
															 ))
															} 	 
															</tbody>
														</table>
													</>
													: <Form 
															formData={this.state.formData} 
															onDragEnd={this.onDragEnd}
															manageComponent={this.manageComponent}
															changeFilter={this.changeFilter}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Layout Manager"} subtitle={"Private Layout Templates"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
							<ExcelFile filename={"export-private-layouts."+this.state.date_label} element={         
						          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
						      }>
						      <ExcelSheet data={this.state.tableData.rows} name="Layouts">
						          		<ExcelColumn label="Layout Name"    value="LAYOUTNAME"/>
						          		<ExcelColumn label="URL"    value="LAYOUTURL"/>
						          		<ExcelColumn label="Layout Path"    value="LAYOUTFILE"/> 
						  		</ExcelSheet>
						  </ExcelFile>
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 