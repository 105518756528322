import React from "react";

import { getImagePath  } from '../../common/functions';
import AccordionItem from '../AccordionItem';
const loadList = props => {
	if (props.listValues){
		return (
		<div className="row">
			<div className="col-12 ">
				<div className="accordion">
					{props.listValues.map(item => (
		 				 <AccordionItem key={1} content={item}   />
						))
				  }  
				</div>
			</div>
		</div>
		/*
			<ul>
				{props.listValues.map(item => (
		    	<li>
		
		    		{item.type==="text"?   <p className="card-text"> {item.text}</p> :""}
		  		 	{item.type==="link"?   <div> <a className="card-link" target={item.target?item.target:"_self" } href={item.url}>{item.text?item.text:item.url}</a> </div>:""}
		  		 	{item.type==="button"? <div> <a className={"btn "+(item.buttonclass?item.buttonclass:"btn-primary")} target={item.target?item.target:"_self"} href={item.url}>{item.text?item.text:"GO" }</a> </div>:""}
		  		 	{item.type==="list"?  
		  		 		<>
		  					{item.values.map(subitem => (
		  						<>
		  							{subitem.type==="text"?    <p className="card-text"> {subitem.text}</p> :""}
						  		 	{subitem.type==="link"?    <div> <a className="card-link" target={subitem.target?subitem.target:"_self" } href={subitem.url}>{subitem.text?subitem.text:subitem.url}</a> </div>:""}
		  		 					{subitem.type==="button"?  <div> <a className={"btn "+(subitem.buttonclass?subitem.buttonclass:"btn-primary")} target={subitem.target?subitem.target:"_self"} href={subitem.url}>{subitem.text?subitem.text:"GO"}</a> </div>:""}
		  		 				</>
		  					))
		  					}
		  		 		</>
		  		 	 :""}
			  	</li>
		  	))
		    }    
		  </ul>   */
		);
			  
	}else{
		return "NO LIST VALUES"
	}
};

export default loadList;
				 