import React  from 'react';
import Api from "../common/api";
import md5 from "react-native-md5";
import Auth from '../services/Auth';

/*
import PageLoading from '../components/PageLoading';
import Table from '../components/Table';
import Modal from '../components/Modal';
import Form from '../components/Form';
import PageHeader from '../components/PageHeader';
import Tab from '../components/Tab';
import Card from '../components/Card';
import List from '../components/List';
import Slider from '../components/Slider';
*/
import Sidebar from "../pages/sidebar";
import CompItem from "../components/CompItem";
import CompCard from '../components/CompCard';


import Modal from '../componentsFront/Modal';
import Form from '../componentsFront/Form';
import Tab from '../componentsFront/Tab';
import Card from '../componentsFront/Card';
import List from '../componentsFront/List';
import BlogContainer from '../componentsFront/BlogContainer';
import Slider from '../componentsFront/Slider';
import SliderImage from '../componentsFront/SliderImage';
import Product from "../componentsFront/Product";
import Text from "../componentsFront/Text";
import Image from "../componentsFront/Image";
import Video from "../componentsFront/Video";
import CategoryBlog from "../componentsFront/CategoryBlog";
import Button from "../componentsFront/Button";

const axios = require('axios');

const folderImages  = process.env.REACT_APP_PATH_IMAGES
const folderFolders = process.env.REACT_APP_PATH_FILES
const privateFolder = process.env.REACT_APP_FOLDER_LAYOUT

const minLng = process.env.REACT_APP_MIN_LNG?process.env.REACT_APP_MIN_LNG: -69
const maxLng = process.env.REACT_APP_MAX_LNG?process.env.REACT_APP_MAX_LNG: -63
const minLat = process.env.REACT_APP_MIN_LAT?process.env.REACT_APP_MIN_LAT: -22
const maxLat = process.env.REACT_APP_MAX_LAT?process.env.REACT_APP_MAX_LAT: -10


								
export function getImagePath(ImagePath){
	try{
		if (ImagePath.indexOf ("http")>=0){
			return ImagePath
		}else{
			return folderImages+""+ImagePath
		}
	}catch(Excep){
		return ""
	}
}
export function setCookie(name, value, hours)
{
	try{
		 var expires;
	  if (hours){
	    var date = new Date();
	    date.setTime(date.getTime() + ( hours * 60 * 60 * 1000 ));
	    expires = "; expires=" + date.toGMTString();
	  } else {
	    expires = "";
	  }

	 // console.log(value)
	  document.cookie = name + "=" + value + expires + "; path=/";
	}catch(err){
		console.log(">>>>>>> ERR SETTING COOKIE",err)

 	}
};

export function getCookie(name)
{
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  var resp
  for (let i = 0; i < ca.length; i++)
  {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
    	c = c.substring(1, c.length);
    	if (c.indexOf(nameEQ) == 0) {
    		return c.substring(nameEQ.length, c.length);
    		console.log("ENC")
    	}
  	}
  }
  return resp;
};


export function getCurrentStore(){
	var tempStores = localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_storesInfoNew')
	var storesInfo ={}
	if (tempStores){
		storesInfo = JSON.parse(localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_storesInfoNew'))
		return storesInfo.selectedStoreId
	}else
		return null
}

export async function getOptions(){

	var resp={
		rows:0,
		success:false
	}
	var rows=[];
	try{
		var temOptions = getCookie(process.env.REACT_APP_ORG_NAME+'_ProductOptions')
			if (temOptions){
				var rows = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_ProductOptions'))
				if (rows.length==0){
					let resp = await callApi ( "customer","getOptionsDB", {languageid: getLanguage() }  )
					rows = resp.rows
					setCookie(process.env.REACT_APP_ORG_NAME+'_ProductOptions',  JSON.stringify(rows), 24 )
				}
			}else{
				let resp = await callApi ( "customer","getOptionsDB", {languageid: getLanguage()} )
				rows = resp.rows
				setCookie(process.env.REACT_APP_ORG_NAME+'_ProductOptions',  JSON.stringify(rows), 24 )
			}
			resp= {
				success:rows.length>1?true:false,
				rows:rows
			}

	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		resp={
			rows:0,
			success:false,
			message:Excep
		}

	}finally{
		return resp
	}
}


export async function getStoresInfo(){
	
	var tempStores = localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_storesInfoNew')
	
	var storesInfo ={}
	if (tempStores){
		//console.log("tempStores",tempStores)
		storesInfo = JSON.parse(localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_storesInfoNew'))
	}else{ 
 		var respStores = await callApi ( "frontend","getStores" ) 
 		var stores=[]
 		respStores.rows.map(function (key) {
				let coords = JSON.parse(key.CLOB_VALUE)
				let store ={storeId: key.DETAILDOMAINID, storeName:key.STRING_VALUE , catalogId: key.NUMBER_VALUE, lat:coords.lat, lng:coords.lng }
				stores.push(store)
			}); 
			var storesInfo = {
					stores : stores,
					selectedStoreId : null,
					selectedCatalogId : null,
			}  
		var updateDate = new Date()
		localStorage.setItem(process.env.REACT_APP_ORG_NAME+'_storesInfoNew',  JSON.stringify(storesInfo))
	}
	if (storesInfo.stores.length==0){
		var respStores = await callApi ( "frontend","getStores" )
		var stores=[]			
		respStores.rows.map(function (key) {
			let coords = JSON.parse(key.CLOB_VALUE)
			let store ={storeId: key.DETAILDOMAINID, storeName:key.STRING_VALUE , catalogId: key.NUMBER_VALUE, lat:coords.lat, lng:coords.lng, add: coords.address }
			stores.push(store)
		}); 
		var newStoresInfo = {
				stores : stores,
				selectedStoreId : storesInfo.selectedStoreId,
				selectedCatalogId : storesInfo.selectedCatalogId
		}
	
		storesInfo = newStoresInfo
		localStorage.setItem(process.env.REACT_APP_ORG_NAME+'_storesInfoNew',  JSON.stringify(newStoresInfo))
		
	}
 
	return storesInfo
}

export async function getTagDefinitions(){

	var resp={
		tags:0,
		success:false
	}
	var tags=[];
	try{
		var temOptions = getCookie(process.env.REACT_APP_ORG_NAME+'_TagDefinitions')
			if (temOptions){
				var tags = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_TagDefinitions'))
				if (tags.length==0){

					var resp = await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "tags", foldername: privateFolder+"private/TAGS/" } )
					var _tags = resp.template;
					var _store = await getStoresInfo()
					var selectedStoreId = _store.selectedStoreId
					var languageId = getLanguage()
					_tags.map(function (key) {
					 if (  key.languageId == languageId  ){
					 	key.tags.map(function (m) {
					 		let from = new Date(m.from)
					 		let to = new Date(m.to)
					 		let sysdate = 	 new Date()
					 		if (  sysdate >= from && sysdate<=to ){

								tags.push ({
									id: m.id,
									storeId: m.storeId,
									name: m.name,
									label: m.label,
									style: m.style
								})

					 		}
						})
					 }
					})
					setCookie(process.env.REACT_APP_ORG_NAME+'_TagDefinitions',  JSON.stringify(tags), 1 )
				}
			}else{
				var resp = await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: "tags", foldername: privateFolder+"private/TAGS/" } )
				var _tags = resp.template;
				var _store = await getStoresInfo()
				var selectedStoreId = _store.selectedStoreId
				var languageId = getLanguage()
				_tags.map(function (key) {
				 if (  key.languageId == languageId  ){
				 	key.tags.map(function (m) {
				 		let from = new Date(m.from)
				 		let to = new Date(m.to)
				 		let sysdate = 	 new Date()
				 		if (  sysdate >= from && sysdate<=to ){

							tags.push ({
								id: m.id,
								storeId: m.storeId,
								name: m.name,
								label: m.label,
								style: m.style
							})

				 		}
					})
				 }
				})
				setCookie(process.env.REACT_APP_ORG_NAME+'_TagDefinitions',  JSON.stringify(tags), 1 )
			}
			resp= {
				success:tags.length>1?true:false,
				tags:tags
			}

	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		resp={
		tags:0,
		success:false,
		message:Excep
	}

	}finally{
		return resp
	}
}

export function getCurrentCatalog(){
	var tempStores = localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_storesInfoNew')
	var storesInfo ={}
	if (tempStores){
		storesInfo = JSON.parse(localStorage.getItem(process.env.REACT_APP_ORG_NAME+'_storesInfoNew'))
		return storesInfo.selectedCatalogId
	}else
		return null
}

export function validateRole(path) {
  let resp=false;
	let token = Auth.loggedIn()
	let menu = token.menu
	for (let key in menu){
		let page = menu[key].PAGE_URL+"/";
		if(path.indexOf( page ) >=0 && menu[key].MODIFY_ACCESS==="YES"	 ){
		//	if( pageId == menu[key].PAGE_ID  && menu[key].MODIFY_ACCESS==="YES"	){
			resp=true
		}
	}

  return resp;
}

export function find(_json, _value, _key){
	var pos = -1
	
	_json.map(function (key, item) {
		if ( key[_key] ==_value ){
				pos= item
			}
		}); 
	return pos
}

export function getUserInfo() {
	let token = Auth.loggedIn()
	 
  return token;
}

export function getLanguage(){
	if (!localStorage.getItem('wfl_def_language') || localStorage.getItem('wfl_def_language') == 'undefined' || localStorage.getItem('wfl_def_language') == null ){
		 	 localStorage.setItem('wfl_def_language',  getUserInfo().languageid );
	}
	return localStorage.getItem('wfl_def_language')
}

export async function getLanguages() {
	  
	let languages = localStorage.getItem('wfl_languages')
	if (!languages){
		let respL = await callApi ( "custom","getDetailDomainsByCode", {domainid:"2"} )	
		let _languages = []
		
		for (let key in respL.rows){
		_languages.push( { id: respL.rows[key].DETAILDOMAINID, name: respL.rows[key].STRING_VALUE})
		}
		languages = JSON.stringify(_languages)	
		localStorage.setItem('wfl_languages', languages);
	}
	
	
  return JSON.parse(languages);
}

export function replaceAll2 ( string, key, keyToReplace ){
	
	var continueReplacing = true
	var newString= string;
	
	if (key != keyToReplace ){
		while (continueReplacing){
			if (string.indexOf (key) >=0){
				string.replace( key, keyToReplace)
			}else{
				continueReplacing= false
			}
		}
	}
	return newString
	
}

export function validLanguageName ( _languages, _allData , _formObj, _fields, _toast ){
	var resp={
		
	} 
	var isValid=true
	var invalidFields=[]
	
	var totalLang = _languages.length
	for (let f in _fields){
		var field= _fields[f]
		var label=""
		var totalValid=0
		for (let key in _languages){
			let formData = _allData[_formObj+_languages[key].id ]
			if ((formData[field].value.toString()).length>0 ){
				totalValid++
			}
			label = formData[field].label
		}
		if (totalValid==0){
			isValid=false
			invalidFields.push(field)
			showError(_toast,"VALIDATION ERROR: "+label+" IS EMPTY IN ALL LANGUAGES OPTIONS");	
		}
	}
	resp={
		isValid,
		invalidFields
	}
	return resp
}
export function findObjName ( _event  ){
	
	var continueSerching = true
	var objName= "";
	 
  var event = _event.target
  
	while (continueSerching){
		if (event.name ||  event.attributes.name ){
		
	//	console.log("encontramos",event.name ,  event.attributes.name )
			continueSerching= false
			objName = event.name ? event.name : event.attributes.name.nodeValue
			
		}else{ 
			event = event.parentNode
			  
		}
	}
 
	return objName
}


const Components= {
 
	  CompCard:{
			header:"",
			width:"",
			body:"",
			imagealt:"",
			image:""
			},
		CompSliderImg:{
			header: "",
			version:"web",
			children:"",
			targetlink:""
		},
		CompList:{
			image: "",
			title:"",
			text:"",
			imageC: ""
		},
		CompCoords:{
			latitude: "",
			longitude:""
		},
		CompForm: {
			name:"",
			label:"",
			type:"INPUT",
			addvalues:"NO",
			value:"",
			includeButton:"NO",
			buttonaction:"",
			size:"col-12",
			sizesm:"col-sm-12",
			sizemd:"col-md-12",
			sizelg:"col-lg-6",
			position:"left",
			validationtype:"NOTREQUIRED"
		},
		CompCardChild: {
		 	header:"",		 
			children:"",
			boxposition:"bottom",
			boxwidth:"full",
			label:"",
			linkbutton:"",
			color:"red",
			version:"web",
			targetlink:""
		},
		CompButton: {
			label:"default",
			action:"",				
			color:"primary"
		},
		CompCategoryBlog: {
			title:"",
			content:"",		
			image: "",
			displayFlex:"NO"
		},
		CompItem: {
			text:"",
			value:"" 
		},
		CompHeaderIconItem:{
			label:"",				
			link:"",
			image:"",
			target:"_self",
			imagewidth:"",
			opacy:1,
			
		},
		CompHeaderItems: {
			width:"",				
			image:"",
			link:"",
			background:"#fff",
			bordercolor:"#fff",
			shadow:"NO",
			items:[]
		},
		CompFooterItems: {
			width:"",				
			image:"",
			link:"",
			imagewidth:"",
			opacy:1,
			subtitle:"",
			fontsize:"12",
			view:"",
			size:"col-12",
			sizesm:"col-sm-12",
			sizemd:"col-md-6",
			sizelg:"col-lg-4",
			items:[]
		},
	}

export function validateFloat( _fieldValue) {
	try{
		
		const numberRegExp = /^\-?[0-9]+\.?[0-9]+$/;
    if (!numberRegExp.test(_fieldValue)) {
        return false
    }else{
    	if( isNaN( parseFloat(_fieldValue)) ) {
		    return false
		  }else{
		  	return true
		  }
    }
		
		
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		return false
	}
}

export function validateNumber( _fieldValue) {
	try{
		if( isNaN( parseInt(_fieldValue)) ) {
	    return false
	  }else{
	  	return true
	  }
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		return false
	}
}
export function getSubcomponents(){
	return Object.keys(Components)
}
export function getSubcomponent(_compName,_rowId) {
	var object
	try{
		
		
		object = { ...Components[_compName] }
		object["rowId"] =  _rowId
		if (object["label"]== undefined){
			object["label"] =  "default"+_rowId	
		}
		
		/*
		if (_compName==="CompCard"){
			object ={
				header:"",
				width:"",
				body:"",
				rowId:_rowId,
				imagealt:"",
				image:""
				}
		}else if (_compName==="CompCardChild"){
			object ={
				header:"",
				rowId:_rowId,
				children:""
				}
		}else if (_compName==="CompOption"){
			object ={
				name:"",
				image:"",
				sortorder:"",
				rowId:_rowId
				}
		}else if (_compName==="CompSliderImg"){
			object ={
				title:"",
				image:"",
				imagealt:"",
				style:"",
				rowId:_rowId,
				link:""
				}
		}else if (_compName==="CompForm"){
			object ={
				name:"",
				label:"",
				type:"INPUT",
				value:"",
				rowId:_rowId
				}
		}else if (_compName==="CompButton"){
			object ={
				label:"default"+_rowId,
				action:"",				
				color:"primary",
				rowId:_rowId
				}
		}else if (_compName==="CompCategoryBlog"){
			object ={
					title:"",
					content:"",		
					image: "",
					displayFlex:"NO",
					rowId:_rowId
			}
		}else{
			object ="NOT FOUND"
		}
			*/
		
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		object= "NOT DEF"
	}finally{
		//console.log(object)
		return object
	}
}

function validateField( _fieldObj,_fieldName) {
	
 
	let isInvalid=false;  
	let resp={isInvalid:isInvalid , message:null }	
	let message = ""
	
  if ( _fieldObj.value ==="" && _fieldObj.notRequired===undefined ){
			isInvalid=true; 
			message ="Field "+_fieldObj.label+" cannot be empty."		
	}else{
		
		if (_fieldObj.validationType==="email" && (_fieldObj.value+"").length  >0 ){
			var emailRegex = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
      if (!emailRegex.test(_fieldObj.value)) {
          message +=  _fieldObj.label+" Incorrect format"+"("+_fieldObj.value+").";
          isInvalid=true;
          
      }
		}else if (_fieldObj.validationType==="date"){
			
			if(_fieldObj.dateFormat==="yyyymmdd"){
				if (isNaN(_fieldObj.value) || _fieldObj.value.length!=8 ) {
	        isInvalid=true;
	        message += _fieldObj.label+" Incorrect format("+_fieldObj.dateFormat +").(Correct Format yyyymmdd).";
		    }else{
		    	let hoy = new Date()
		      let anio = _fieldObj.value.substr(0,4)
	        let mes = _fieldObj.value.substr(4,2)
	        let dia = _fieldObj.value.substr(6,2)
	        let fechaNaci = new Date(anio+"/"+mes+"/"+dia+" 00:00:00")
	        if (fechaNaci.toString() ==="Invalid Date"){
	        	message += _fieldObj.label+" Incorrect format("+_fieldObj.dateFormat +").(Correct Format yyyymmdd).";
	          isInvalid=true;
	        }else{
	        	_fieldObj["returnedValue"] = fechaNaci
	        }
		    }
			}else if(_fieldObj.dateFormat==="yyyymmddhh24miss"){
				if (isNaN(_fieldObj.value) || _fieldObj.value.length!=14 ) {
	        isInvalid=true;
	        message += _fieldObj.label+" Incorrect format("+_fieldObj.dateFormat +").(Correct Format yyyymmddhh24miss).";
		    }else{
		    	let hoy = new Date()
		      let anio = _fieldObj.value.substr(0,4)
	        let mes = _fieldObj.value.substr(4,2)
	        let dia = _fieldObj.value.substr(6,2)

	        let hora = _fieldObj.value.substr(8,2);
          let minuto = _fieldObj.value.substr(10,2);
          let segundo = _fieldObj.value.substr(12,2);
	        let Fecha = new Date(anio+"/"+mes+"/"+dia+" "+hora+":"+minuto+":"+segundo);
	        if (Fecha.toString() ==="Invalid Date"){
	        	message += _fieldObj.label+" Incorrect format("+_fieldObj.dateFormat +").(Correct Format yyyymmddhh24miss).";
	          isInvalid=true;
	        }else{
	        	_fieldObj["returnedValue"] = Fecha
	        }
		    }
			}else if(_fieldObj.dateFormat==="yyyymmdd hh24:mi:ss"){
				if (_fieldObj.value.length!=17 ) {
	        isInvalid=true;
	      	message += _fieldObj.label+" Incorrect format("+_fieldObj.dateFormat +").(Correct Format yyyymmdd hh24:mi:ss).";
		    }else{
		    	let hoy = new Date()
		      let anio = _fieldObj.value.substr(0,4)
	        let mes  = _fieldObj.value.substr(4,2)
	        let dia  = _fieldObj.value.substr(6,2)

	        let hora    = _fieldObj.value.substr(9,2);
          let minuto  = _fieldObj.value.substr(12,2);
          let segundo = _fieldObj.value.substr(15,2);
          
	        let Fecha = new Date(anio+"/"+mes+"/"+dia+" "+hora+":"+minuto+":"+segundo);
	        if (Fecha.toString() ==="Invalid Date"){
	        	message += _fieldObj.label+" Incorrect format("+_fieldObj.dateFormat +").(Correct Format yyyymmdd hh24:mi:ss).";
	          isInvalid=true;
	        }else{
	        	_fieldObj["returnedValue"] = Fecha
	        }
		    }
			}
		}else if (_fieldObj.validationType==="number" && (_fieldObj.value+"").length  >0 ){
			if( isNaN( parseInt(_fieldObj.value)) ) {
				message += _fieldObj.label+" does not a valid number";
        isInvalid=true; 
      }else{
      	
      	if (_fieldObj.maxValue && _fieldObj.value!=""){
					if (parseInt(_fieldObj.value) > _fieldObj.maxValue){
		    		message += _fieldObj.label+" exceeds the maximum value." + "(Current:"+_fieldObj.value+",Max:"+_fieldObj.maxValue +").";
		        isInvalid=true;  
		    	}
				}
				
				if (_fieldObj.minValue && _fieldObj.value!=""){
					if (parseInt(_fieldObj.value) < _fieldObj.minValue){
		    		message += _fieldObj.label+" does not satisfy the minimum value." + "(Current:"+_fieldObj.value+",Min:"+_fieldObj.minValue +").";
		        isInvalid=true;  
		    	}
				}
      	
      }
		}else if (_fieldObj.validationType==="text"){
		
		}else if (_fieldObj.validationType==="array" && _fieldObj.notRequired===undefined ){
			if(!Array.isArray(_fieldObj.value)){
     		isInvalid=true; 
     		message += _fieldObj.label+" does not an array.";
     	}else{
     		if (_fieldObj.value.length<1){
     				isInvalid=true;
     				message += _fieldObj.label+" must have at least one record.";
     		}
     	}
		}else if (_fieldObj.validationType==="phone" && (_fieldObj.value+"").length  >0 ){
			
			if( isNaN(_fieldObj.value) ) {
      	message += _fieldObj.label+" is not a valid number";
        isInvalid=true; 
      }else{
      	if (_fieldObj.value < 1000000 || _fieldObj.value > 80000000){
      		message += _fieldObj.label+" is not a valid phone number";
          isInvalid=true;  
      	}
      }
		}
		if (_fieldObj.maxLength && _fieldObj.value!=""){
			if (_fieldObj.value.length > _fieldObj.maxLength){
    		message += _fieldObj.label+" exceeds the maximum length." + "(Current:"+_fieldObj.value.length+",Max:"+_fieldObj.maxLength +").";
        isInvalid=true;  
    	}
		}
		if (_fieldObj.minLength && _fieldObj.value!=""){
			if (_fieldObj.value.length < _fieldObj.minLength){
				message += _fieldObj.label+" does not satisfy the minimum length." + "(Current:"+_fieldObj.value.length+",Min:"+_fieldObj.minLength +").";
        isInvalid=true;  
      }
		}			 
		if (_fieldObj.allowedValues){
			let contiene =false;
			for(var i in _fieldObj.allowedValues){
				if (_fieldObj.allowedValues[i]== _fieldObj.value ){
					contiene=true;
				}
			} 
			if(!contiene) {
      	message += _fieldObj.label+" value not allowed , it is only allowed : "+_fieldObj.allowedValues+".";
        isInvalid=true; 
      }
		}
	}
	resp={isInvalid:isInvalid,  message:message }
	return resp
}

export async function executeScript (_formData, field, _toast) {
	var formData = _formData
	try{
		var currentField=formData[field];
		await eval( formData[field].script)
	}catch(Excep){
		console.log(">>>>>>>>ERROR EXECUTING CUSTOM SCRIPT",Excep)
	}finally{
		return formData
	}
}						

export function validateSyncForm (formData, _toast, _event) {
	var isValid=true
	var error=""
	var message=""
	var resp={formData:formData, isValid:isValid  }
 

	try{
		if ( _event?_event.hex:null ){
			 
			var field_name  = _event?_event.target.name:undefined 
			 
			for (let i in Object.keys(formData) ){
				let field = Object.keys(formData)[i]
				if (formData[field].type==="COLOR" &&  field===field_name){
					formData[field].value =  _event.hex 
				}
			}
			
		}else{
			
			var field_value = _event? (_event.target.value+"") :undefined
			var field_name  = _event?_event.target.name:undefined 
		 
			if (  field_name && field_name.split("__").length>1){
				
				let subfield = field_name.split("__")
			 	var component = formData[subfield[0]]
				component.values.map(function (subitem) {
					if (subitem.id == subfield[1]   ){
						subitem["value"] = field_value
					 	let isInvalid = field_value==""?true:false 
						subitem["isInvalid"] = isInvalid
					}
				}); 
			}else{
			 
				if (field_name){
					for (let i in Object.keys(formData) ){
						let field = Object.keys(formData)[i]
						if ( formData[field].type==="CHECKBOX" &&  field===field_name){
							let checkedvalues	= formData[field].value 
							if ( validateNumber(field_value) ){
								field_value = parseInt (field_value)
							} 
							if(checkedvalues.indexOf( field_value ) < 0 ){
								checkedvalues.push (field_value)	
							}else{
								let i = checkedvalues.indexOf( field_value )
								checkedvalues.splice (i, 1)
							}
							formData[field].value = checkedvalues
						}else if (formData[field].type==="DATEPICKER" &&  field===field_name ) {							
							formData[field]["value"] = new Date(field_value)						
						}else{
						 
							if(field===field_name){
								formData[field]["value"] = field_value
							}
						}
						
					}
				}
				if (!field_name){
					for (let i in Object.keys(formData) ){
						let field = Object.keys(formData)[i]
						if (formData[field].type!="LABEL" && formData[field].type!="LINEBREAK"){
							if (!field_name || field_name ===field ){
									resp = validateField (formData[field],field )
									formData[field]["isInvalid"] = resp.isInvalid
									message +=" "+resp.message
									if (resp.isInvalid){
										isValid = false
									}
							}
						}
					}
				}
					
				if (!field_name && !isValid){
					_toast.error("VALIDATION ERROR:"+message, {closeButton: true});
				}
			}
	 	}
		resp={formData:formData, isValid:isValid}
	}catch(error) {
		var resp={formData:formData, isValid:false, error:error }
		console.log(">>>>>>>>", error)
		if (_toast){
			_toast.error("Error en la pagina intente nuevamente", {closeButton: true});
		}
	}finally{
		return  resp
	}
}
							
export async function validateForm (formData, _toast, _event) {
	var isValid=true
	var error=""
	var message=""
	var resp={formData:formData, isValid:isValid  }
 

	try{
		if ( _event?_event.hex:null ){
			 
			var field_name  = _event?_event.target.name:undefined 
			 
			for (let i in Object.keys(formData) ){
				let field = Object.keys(formData)[i]
				if (formData[field].type==="COLOR" &&  field===field_name){
					formData[field].value =  _event.hex 
				}
			}
			 
		}else{
			
			var field_value = _event? (_event.target.value+"") :undefined
			var field_name  = _event?_event.target.name:undefined 
		 
			if (  field_name && field_name.split("__").length>1){
				
				let subfield = field_name.split("__")
			 	var component = formData[subfield[0]]
				component.values.map(function (subitem) {
					if (subitem.id == subfield[1]   ){
						subitem["value"] = field_value
					 	let isInvalid = field_value==""?true:false 
						subitem["isInvalid"] = isInvalid
					}
				}); 
			}else{
			 
				if (field_name){
					for (let i in Object.keys(formData) ){
						let field = Object.keys(formData)[i]
						if ( formData[field].type==="CHECKBOX" &&  field===field_name){
							let checkedvalues	= formData[field].value 
							if ( validateNumber(field_value) ){
								field_value = parseInt (field_value)
							} 
							if(checkedvalues.indexOf( field_value ) < 0 ){
								checkedvalues.push (field_value)	
							}else{
								let i = checkedvalues.indexOf( field_value )
								checkedvalues.splice (i, 1)
							}
							formData[field].value = checkedvalues
							
							if (formData[field].value.length == formData[field].values.length){
								console.log( formData[field].value.length )
								console.log( formData[field].values.length )	
								formData[field].checkAll = true
							}else{
								formData[field].checkAll = false
							}
							
							
						}else if (formData[field].type==="DATEPICKER" &&  field===field_name ) {							
							formData[field]["value"] = new Date(field_value)						
						}else{
						 
							if(field===field_name){
								formData[field]["value"] = field_value
							}
						}
						
						if (field_name == field && formData[field].script ){
						 
							var currentField=formData[field];
							try{
								await eval( formData[field].script)
							}catch(Excep){
								console.log(">>>>>>>>ERROR EXECUTING CUSTOM SCRIPT",Excep)
							}
						}
					}
				}
				for (let i in Object.keys(formData) ){
					let field = Object.keys(formData)[i]
					if (formData[field].type!="LABEL" && formData[field].type!="LINEBREAK"){
						if (!field_name || field_name ===field ){
								resp = validateField (formData[field],field )
								formData[field]["isInvalid"] = resp.isInvalid
								message +=" "+resp.message
								if (resp.isInvalid){
									isValid = false
								}
						}
					}
				}
				if (!field_name && !isValid){
					_toast.error("VALIDATION ERROR:"+message, {closeButton: true});
				}
			}
	 	}
		resp={formData:formData, isValid:isValid}
	}catch(error) {
		var resp={formData:formData, isValid:false, error:error }
		console.log(">>>>>>>>", error)
		if (_toast){
			_toast.error("Error en la pagina intente nuevamente", {closeButton: true});
		}
	}finally{
		return  resp
	}
}
export async function getDataDB(_formData, field, _toast) {
	var formData = _formData 
	var Data = new Object()
 	var apiGroup = "custom"
	if (formData[field].getDataDB.apiValues){
		Object.keys(formData).map(function (key) {
			if ( formData[field].getDataDB.apiValues.indexOf(key) >= 0 ){
				Data[key] =  formData[key].value
			}
		}); 
	}
	if (formData[field].getDataDB.aditionalValues){
		for (let key in formData[field].getDataDB.aditionalValues){			
			Data[Object.keys(formData[field].getDataDB.aditionalValues[key])[0] ] =  Object.values(formData[field].getDataDB.aditionalValues[key])[0] 
		}
	} 
	if (formData[field].getDataDB.apiGroup){
		apiGroup = formData[field].getDataDB.apiGroup
	}
	try{
		var values=[]
		var customScript =  " (async () => {  values = await callFormApi ('"+ apiGroup+"', '"+ formData[field].getDataDB.apiMethod+"', "+ `Data`+" ) })();"
		await eval(customScript)		
		if (formData[field].addDefault){
			let defRow = [{value:"", text:formData[field].addDefault}]
			let rows = [...defRow, ...values.rows];
			formData[field]["values"]= rows
			if (formData[field].originalValues){
				//formData[field]["originalValues"]= rows
			}
			
			formData[field]["originalValues"]= rows
			
		}else{
			formData[field]["values"]= values.rows		
			if (formData[field].originalValues){
				//formData[field]["originalValues"]= values.rows		
			}
			
			formData[field]["originalValues"]= values.rows		
		}
		
		
	}catch(error) {
		console.log(">>>>>>>>", error)
		if (_toast){
			_toast.error("Error consultando tabla parametrica", {closeButton: true});
		}
	}finally{
		return  formData
	}
}
export async function callFormApi(apiGroup, apiName, formData, _toast ) {
	var resp={rows:[]}
	try{
		let _resp = await Api[apiGroup][apiName] (formData)
		for (let k in _resp.rows){
			 
			var Row={
				value: _resp.rows[k][Object.keys(_resp.rows[k])[0]],
				text : _resp.rows[k][Object.keys(_resp.rows[k])[1]]
			}
			Object.keys(_resp.rows[k]).map(function (item) {
				Row[item] =  _resp.rows[k][item]
			})
			resp.rows.push( Row )
		}
	}catch(error) {
		console.log(apiGroup, apiName)
		console.log(">>>>>>>>", error)
		if (_toast){
			_toast.error("Error consultando api", {closeButton: true});
		}
	}finally{
		return  resp
	}
}

export async function callApi(apiGroup, apiName, formData, _toast) {
	var resp={rows:[]}
	try{
		resp = await Api[apiGroup][apiName] (formData)
		
	}catch(error) {
		console.log(apiGroup, apiName)
		console.log(">>>>>>>>", error)
		if (_toast){
			_toast.error("Error consultando api", {closeButton: true});
		}
	}finally{
		return  resp
	}
}
export async function preloadForm( _formData ) {
	var formData = _formData
	
	try{
		for (let key in Object.keys(_formData) ){
			let field = Object.keys(_formData)[key]
			if(_formData[field].getDataDB){
				let tmpformData = await getDataDB ( _formData, field )
				
				 
				formData = tmpformData
	 		}
	 	}
	}catch(err){
		console.log(">>>>>>>>>>",err)
	}finally{
		return formData
	}
}

export async function clearformData( _formData, forceClear) {
	var formData = _formData
	
	try{
		for (let key in Object.keys(_formData) ){
			var field = Object.keys(_formData)[key]
			let fieldValue=""
			if(_formData[field].type==="SELECT" || _formData[field].type==="RADIO" || _formData[field].type==="MULTIPLE" ){
				fieldValue = _formData[field].values[0]? _formData[field].values[0].value:""
			}else if (_formData[field].type==="CHECKBOX" ){
				fieldValue=[]
			}else if (_formData[field].type==="DATEPICKER" ){
				fieldValue= new Date()
			}else if (_formData[field].type==="IMAGE" ){
				fieldValue= ""
			}else if (_formData[field].type.startsWith("Comp") ){
				fieldValue= undefined
			}else if (  _formData[field].type==="TEXT" || _formData[field].type==="TEXTAREA" ){
				fieldValue= _formData[field].defvalue?_formData[field].defvalue: ""
			}else{
				fieldValue= _formData[field].value
			} 
			_formData[field].value= _formData[field].defaultvalue?_formData[field].defaultvalue:fieldValue
	 	}
	 	formData = _formData
	}catch(err){
		console.log(">>>>>>>>>>",err,field )
	}finally{
		return formData
	}
}

export async function mergeFormData( _formData, _userData ) {
	var formData = _formData
	try{
		for (let key in Object.keys(_formData) ){
			var field = Object.keys(_formData)[key]
			for (let key2 in Object.keys(_userData) ){
				let field2 = Object.keys(_userData)[key2] 
				if(field.toLowerCase() == field2.toLowerCase()){
					let fieldValue = _userData[field2] ?  _userData[field2] : ( _formData[field].defvalue?_formData[field].defvalue:"" )
					
					if(Array.isArray(fieldValue) &&(_formData[field].type).startsWith("Comp")){
						_formData[field].values= fieldValue===null?"":fieldValue;
					}else{ 
						//console.log(  "_userData[field2]", _userData[field2])
						_formData[field].value= fieldValue===null?"":fieldValue;
					}
					
	 			}
	 		}
	 	}
	 	//console.log (_formData)
	 	formData = _formData
	}catch(err){
		console.log(">>>>>>>>>>",err)
	}finally{
		return formData
	}
}

export function parseFormData(_formData) {
	var parsedFormData={}
	try{
		for (let key in Object.keys(_formData) ){
	  	var field = Object.keys(_formData)[key]
	  	
	  	if (_formData[field].value !== undefined ){
	  		let fieldValue = _formData[field].value
	  		parsedFormData[field.toLowerCase()] = fieldValue	
	  	}else{
	  		parsedFormData[field.toLowerCase()] =  _formData[field].values
	  	}
	  	
		}

	}catch(err){
		console.log(">>>>>>>>>>",err)
	}finally{
		return parsedFormData
	}
	 
}

export function showMessage(toast,message) {
  toast.success(message, {closeButton: true});
}
export function showWarning(toast,message) {
  toast.warning(message, {closeButton: true});
}
export function showError(toast,message) {
  toast.error(message, {closeButton: true});
}
export function encryptText(text) {
  return (md5.hex_md5(text))
}


export function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a href="#0">Previous</a>;
  }
  if (type === "next") {
    return <a href="#0">Next</a>;
  }
  return originalElement;
}

export function onShowSizeChange(obj) {
	
}

export function getChildren(_jsonObjet, _children) {
  let respChildObj
  for (let key in _jsonObjet ){
		if (_jsonObjet[key].isChildren && _jsonObjet[key].name === _children ){
			respChildObj = _jsonObjet[key] 
		}
	}
	return respChildObj
}
export function setObjectToFather (_jsonObjet, _obj, _renderObj ) {
	let objFather
	let objChildren
	for (let key in _jsonObjet ){
		if ( _jsonObjet[key].name === _obj.parent ){
			objFather = _jsonObjet[key] 
		}
	}
	
	if (objFather.componentType ==="Card" || objFather.componentType ==="Slider" ){
		for(var c in objFather.content){
			if (objFather.content[c].children === _obj.name ){
				
				objChildren = { header:  objFather.content[c].header ,
												cardWidth: objFather.content[c].cardWidth ,
												body : _renderObj,
												temporalyName:_obj.parent
											} 
			}
		}
	}
	
	return objChildren;
}

export function setObject(_jsonObjet, _obj, _container, _containerMaster, elementSubType ) {
	var respObj="NO DATA"
	var renderObj
	
	
	if (_obj.componentType ==="Card"){
	 
		
		for(var c in _obj.content){
			
			let newObj = _obj.content[c]
			newObj["temporalyName"] = _obj.name;
			
			if (!_obj.content[c].children ){
				
				_container.push (newObj)
			}else{
			
				var objChildren = getChildren (_jsonObjet, newObj.children   )
				
				setObject (_jsonObjet, objChildren, _container, _containerMaster, true )
			}
		}
		
		var finalContainer = [];
		for(var c in _container){
			if (_container[c].temporalyName === _obj.name ){
				finalContainer.push(_container[c])
			
			}else{
				
			}
		}		
		renderObj= <Card 
								cardData={finalContainer} 
								cardConfig={_obj.config}
							/> 		
  
	}else if (_obj.componentType ==="Slider"){
		
		for(var c in _obj.content){
			
			
			let newObj = _obj.content[c]
			newObj["temporalyName"] = _obj.name;

			if (!_obj.content[c].children ){
				 _container.push (_obj.content[c])
			}else{
				
				var objChildren = getChildren (_jsonObjet, _obj.content[c].children   )
			

				setObject (_jsonObjet, objChildren, _container, _containerMaster, true )
				
			}
		}
		
		
		
		var finalContainer = [];
		for(var c in _container){
			if (_container[c].temporalyName === _obj.name ){
				finalContainer.push(_container[c])
			}
		}		
			
		renderObj= <Slider 
								sliderData={finalContainer}
								sliderConfig={_obj.config}
							/> 		
	}else if (_obj.componentType ==="List"){

		var cardData=[]
		for(var c in _obj.content){
			let newObj = _obj.content[c]
			newObj["temporalyName"] = _obj.name;
			if (!_obj.content[c].children ){
				 _container.push (_obj.content[c])
			}else{
				//console.log("aun no manejado" )
			}
		}		
		var finalContainer = [];
		for(var c in _container){
			if (_container[c].temporalyName === _obj.name ){
				finalContainer.push(_container[c])
				
			}else{
				
			}
		}		
		renderObj= <List 
								listValues={finalContainer} 
							/> 		
  }else if (_obj.componentType ==="Form"){
  	
  	renderObj = <Form 
								formData={_obj.formData} 
								changeHandler={_obj.changeHandler} 
								formConfig={_obj.config}
								/>
	}
	if (!elementSubType){
			
			
			_containerMaster.push(renderObj)
	}else{
		
		let objChildren = setObjectToFather (_jsonObjet, _obj,  renderObj )
		_container.push(objChildren)
	}
}

export function loadPage(jsonObjet) {
  var finalObject=[]
  let containerMaster = []
  for (let key in jsonObjet ){
  	let obj = jsonObjet[key]
  	let container = []
  	
  	if (!obj.isChildren){
  		let respObj = setObject (jsonObjet, obj, container,containerMaster)
  	}else{

  	}
  	
  }

  return  containerMaster
}

export async function manageFolder(event,_objFolder){
	var objFolder 
	try{
		
		let _data =   findObjName(event)
		var data  = _data.split("_")
		let action= data[0]
		if(action==="selectItem"){
			console.log(data[1] , _objFolder.items)
			let item = _objFolder.items [data[1]]
			if (item.type=="folder"){
				//console.log("fol")
				objFolder = await listFolders (item.name, _objFolder.objectName, _objFolder.selected )
				objFolder.selected =[]
			}else{
				objFolder = _objFolder
				objFolder["selected"] = [item.path ]
			}
		}else if(action==="chooseFolder"){
			objFolder = _objFolder
			objFolder["value"] = _objFolder.selected[0]		
		
		}else if(action==="selectFolder"){			
			var objFolder = _objFolder	 	
			objFolder["value"] =  objFolder.items[data[1]].path	 
		}else if (action==="getParent"){
			var routes = _objFolder.route.split("/")
			let newRoute=""
			for (let i =0; i<routes.length-2; i++){
				newRoute = newRoute+routes[i]+"/"
			}
			if(routes.length>1){
				objFolder = await listFolders (newRoute,_objFolder.objectName, _objFolder.selected )	
			}else{
				objFolder=_objFolder
			}
		}else if (action==="refresh"){
			objFolder = await listFolders (_objFolder.route,_objFolder.objectName, _objFolder.selected )	
		}else if (action==="showFolderName"){
			objFolder = _objFolder
			objFolder.showFolderName = objFolder.showFolderName?false:true
			objFolder.showUploadFile = false
		}else if (action==="showUploadFile"){
			objFolder = _objFolder
			objFolder.showUploadFile = objFolder.showUploadFile?false:true
			objFolder.showFolderName = false
		}else if (action==="newFolderValue"){
			objFolder = _objFolder
			const pattern = new RegExp('^[0-9A-Z_-]+$', 'i');
			if(pattern.test(event.target.value)){
				objFolder.newFolderValue = event.target.value
			}
		}else if (action==="createFolder"){
			let folderName= ""
			if (_objFolder.route==="" ){
				folderName= _objFolder.newFolderValue
			}else{
				folderName= _objFolder.route+_objFolder.newFolderValue
			}
			let createFolder = await callApi ( "s3","createFileFolder", { foldername: folderName+"/" } )
			objFolder = await listFolders (_objFolder.route,_objFolder.objectName, _objFolder.selected )	
		 
	 	}else if (action==="inputNewFile"){
			var objFolder = _objFolder
			objFolder.inputNewFile= event.target.files[0]
		}else if (action==="uploadFile"){
			console.log(_objFolder.inputNewFile)
			const formData = new FormData();
      formData.append(
        "filename",
        _objFolder.inputNewFile,
        _objFolder.inputNewFile.name
      );
      formData.append("foldername", _objFolder.route);
      let uploadFile = await callApi ( "s3","uploadFolder", formData  )
			objFolder = await listFolders (_objFolder.route,_objFolder.objectName, _objFolder.selected )	
		}else if (action==="deleteItems"){
			for (let i in _objFolder.items ){
	 			if(_objFolder.items[i].checked){
	 				let resp = await callApi ( "s3","deleteFile", { filename: _objFolder.items[i].name } )
	 			}
	 		}
	 		objFolder = await listFolders (_objFolder.route,_objFolder.objectName, _objFolder.selected )	
		}else if (action==="selectMultiple"){
		/*	var objSelected= []
			for (let i in _objFolder.items ){
	 			if(_objFolder.items[i].checked){
	 				objSelected.push(_objFolder.items[i].name)
	 			}
	 		}	
	 		objFolder = _objFolder
	 		objFolder["selected"] = objSelected*/
	 		console.log(	_objFolder["selected"])
	 		objFolder = _objFolder
		}else{
			console.log(">>>>>>>>","No valid option")
			objFolder =_objFolder
		}

	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		return false
	}finally{
		return objFolder
	}
}

export async function listFolders(_foldername, _objectName, _selected){
	 
	var	respS3 = await callApi ( "s3","listFiles", { foldername: _foldername } )
 		var items =[]
 		var item=0
 		for (let f in respS3.folders ){
 			if (respS3.folders[f]!="/"){
 				
 				let label = respS3.folders[f].split("/")[respS3.folders[f].split("/").length-2]
 				items.push ({ item:item,  name : respS3.folders[f], label: label, type:"folder", path: respS3.folders[f]  } )	
 				item++
 			}
 		}
 		let objFolder={
 				items:items,
				selected:_selected, 
				newFolderValue:"",
				route : _foldername,
				inputNewFile : null,
				objectName: _objectName
 		}	 
		return objFolder;
}

export async function manageImageFolder(event,_objFolder){
	var objFolder 
	try{
		
		let _data =   findObjName(event)
		var data  = _data.split("_")
		let action= data[0]
		if(action==="selectItem"){
			console.log(data[1] , _objFolder.items)
			let item = _objFolder.items [data[1]]
			if (item.type=="folder"){
				//console.log("fol")
				objFolder = await listImageFolders (item.name, _objFolder.objectName, _objFolder.selected )
				objFolder.selected =[]
			}else{
				objFolder = _objFolder
				objFolder["selected"] = [item.path ]
			}
		}else if(action==="chooseFolder"){
			objFolder = _objFolder
			objFolder["value"] = _objFolder.selected[0]		
		
		}else if(action==="selectFolder"){			
			var objFolder = _objFolder	 	
			objFolder["value"] =  objFolder.items[data[1]].path	 
		}else if (action==="getParent"){
			var routes = _objFolder.route.split("/")
			let newRoute=""
			for (let i =0; i<routes.length-2; i++){
				newRoute = newRoute+routes[i]+"/"
			}
			if(routes.length>1){
				objFolder = await listImageFolders (newRoute,_objFolder.objectName, _objFolder.selected )	
			}else{
				objFolder=_objFolder
			}
		}else if (action==="refresh"){
			objFolder = await listImageFolders (_objFolder.route,_objFolder.objectName, _objFolder.selected )	
		}else if (action==="showFolderName"){
			objFolder = _objFolder
			objFolder.showFolderName = objFolder.showFolderName?false:true
			objFolder.showUploadFile = false
		}else if (action==="showUploadFile"){
			objFolder = _objFolder
			objFolder.showUploadFile = objFolder.showUploadFile?false:true
			objFolder.showFolderName = false
		}else if (action==="newFolderValue"){
			objFolder = _objFolder
			const pattern = new RegExp('^[0-9A-Z_-]+$', 'i');
			if(pattern.test(event.target.value)){
				objFolder.newFolderValue = event.target.value
			}
		}else if (action==="createFolder"){
			let folderName= ""
			if (_objFolder.route==="" ){
				folderName= _objFolder.newFolderValue
			}else{
				folderName= _objFolder.route+_objFolder.newFolderValue
			}
			let createFolder = await callApi ( "s3","createFileFolder", { foldername: folderName+"/" } )
			objFolder = await listImageFolders (_objFolder.route,_objFolder.objectName, _objFolder.selected )	
		 
	 	}else if (action==="inputNewFile"){
			var objFolder = _objFolder
			objFolder.inputNewFile= event.target.files[0]
		}else if (action==="uploadFile"){
			console.log(_objFolder.inputNewFile)
			const formData = new FormData();
      formData.append(
        "filename",
        _objFolder.inputNewFile,
        _objFolder.inputNewFile.name
      );
      formData.append("foldername", _objFolder.route);
      let uploadFile = await callApi ( "s3","uploadFolder", formData  )
			objFolder = await listImageFolders (_objFolder.route,_objFolder.objectName, _objFolder.selected )	
		}else if (action==="deleteItems"){
			for (let i in _objFolder.items ){
	 			if(_objFolder.items[i].checked){
	 				let resp = await callApi ( "s3","deleteFile", { filename: _objFolder.items[i].name } )
	 			}
	 		}
	 		objFolder = await listImageFolders (_objFolder.route,_objFolder.objectName, _objFolder.selected )	
		}else if (action==="selectMultiple"){
		/*	var objSelected= []
			for (let i in _objFolder.items ){
	 			if(_objFolder.items[i].checked){
	 				objSelected.push(_objFolder.items[i].name)
	 			}
	 		}	
	 		objFolder = _objFolder
	 		objFolder["selected"] = objSelected*/
	 		console.log(	_objFolder["selected"])
	 		objFolder = _objFolder
		}else{
			console.log(">>>>>>>>","No valid option")
			objFolder =_objFolder
		}

	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		return false
	}finally{
		return objFolder
	}
}

export async function listImageFolders(_foldername, _objectName, _selected){
	 
	var	respS3 = await callApi ( "s3","listImages", { foldername: _foldername } )
 		var items =[]
 		var item=0
 		for (let f in respS3.folders ){
 			if (respS3.folders[f]!="/"){
 				
 				let label = respS3.folders[f].split("/")[respS3.folders[f].split("/").length-2]
 				items.push ({ item:item,  name : respS3.folders[f], label: label, type:"folder", path: respS3.folders[f]  } )	
 				item++
 			}
 		}
 		let objFolder={
 				items:items,
				selected:_selected, 
				newFolderValue:"",
				route : _foldername,
				inputNewFile : null,
				objectName: _objectName
 		}	 
		return objFolder;
}

export async function manageImage(event,_objImage){
	var objImage 
	try{
		var _data = findObjName(event)
		var data = _data.split("_")
			let action= data[0]
			console.log(data)
		if(action==="selectItem"){
			let item = _objImage.originalValues [data[1]]
			if (item.type=="folder"){
				//console.log("fol")
				objImage = await listImages (item.name, _objImage.objectName, _objImage.selected )
			}else{
				objImage = _objImage
				objImage["selected"] = [item.path ]
			}
		}else if (action==="getParent"){
			var routes = _objImage.route.split("/")
			let newRoute=""
			for (let i =0; i<routes.length-2; i++){
				newRoute = newRoute+routes[i]+"/"
			}
			if(routes.length>1){
				objImage = await listImages (newRoute,_objImage.objectName, _objImage.selected )	
			}else{
				objImage=_objImage
			}
		}else if (action==="refresh"){
			
			var ContinuationToken
			if (_objImage.ContinuationToken){
				if (_objImage.ContinuationToken!=""){
					ContinuationToken = _objImage.ContinuationToken
				}
			}
			
			
			objImage = await listImages (_objImage.route,_objImage.objectName, _objImage.selected,ContinuationToken )	
		}else if (action==="showFolderName"){
			objImage = _objImage
			objImage.showFolderName = objImage.showFolderName?false:true
			objImage.showUploadFile = false
		}else if (action==="showUploadFile"){
			objImage = _objImage
			objImage.showUploadFile = objImage.showUploadFile?false:true
			objImage.showFolderName = false
		}else if (action==="newFolderValue"){
			objImage = _objImage
			const pattern = new RegExp('^[0-9A-Z_-]+$', 'i');
			if(pattern.test(event.target.value)){
				objImage.newFolderValue = event.target.value
			}
		}else if (action==="createFolder"){
			let folderName= ""
			if (_objImage.route==="" ){
				folderName= _objImage.newFolderValue
			}else{
				folderName= _objImage.route+_objImage.newFolderValue
			}
			let createFolder = await callApi ( "s3","createImageFolder", { foldername: folderName+"/" } )
			objImage = await listImages (_objImage.route,_objImage.objectName, _objImage.selected )	
		}else if (action==="selectToDelete"){
			var objImage = _objImage
			
			if (objImage.originalValues[data[1]].checked){
				
				let pos = _objImage.selected.indexOf( objImage.originalValues[data[1]].name)
				_objImage.selected.splice( pos, 1)
			}else{
				_objImage.selected.push (objImage.originalValues[data[1]].path )
			}
				console.log(_objImage.selected)
				
			objImage.originalValues[data[1]].checked= objImage.originalValues[data[1]].checked?false:true;
				console.log("after", objImage.originalValues[data[1]].checked)
			
		
	 	}else if (action==="inputNewFile"){
			var objImage = _objImage
			objImage.inputNewFile= event.target.files[0]
		}else if (action==="uploadFile"){
			console.log(_objImage.inputNewFile)
			const formData = new FormData();
      formData.append(
        "filename",
        _objImage.inputNewFile,
        _objImage.inputNewFile.name
      );
      formData.append("foldername", _objImage.route);
      let uploadFile = await callApi ( "s3","uploadImage", formData  )
			objImage = await listImages (_objImage.route,_objImage.objectName, _objImage.selected )	
		}else if (action==="deleteItems"){
			for (let i in _objImage.originalValues ){
	 			if(_objImage.originalValues[i].checked){
	 				let resp = await callApi ( "s3","deleteFile", { filename: _objImage.originalValues[i].name } )
	 			}
	 		}
	 		objImage = await listImages (_objImage.route,_objImage.objectName, _objImage.selected )	
		}else if (action==="selectMultiple"){
		/*	var objSelected= []
			for (let i in _objImage.items ){
	 			if(_objImage.items[i].checked){
	 				objSelected.push(_objImage.items[i].name)
	 			}
	 		}	
	 		objImage = _objImage
	 		objImage["selected"] = objSelected*/
	 		//console.log(	_objImage["selected"])
	 		objImage = _objImage
		}else if (action==="manageInput"){
			objImage =_objImage
			objImage["objectName"] = data[1]
			objImage["selected"] = [ (event.target.value).replace(folderImages, "") ]	
		}else if (action==="ContinuationToken"){
			var PreviousToken
			var currHistory = _objImage.history
			if (currHistory){
				if (currHistory.length){
					let pos = find (currHistory, _objImage.ContinuationToken, "nextToken" )
					if (pos>=0){
						PreviousToken = currHistory[pos].prevToken
					}
				}
			}	
			objImage = await listImages (_objImage.route,_objImage.objectName, _objImage.selected, PreviousToken )
			objImage["history"] = currHistory
			
		}else if (action==="NextContinuationToken"){
			
			let history =_objImage.history?_objImage.history:[]
 			if (history.length==0){
 				history.push({ prevToken:"", nextToken: _objImage.NextContinuationToken })		
 			}
			objImage = await listImages (_objImage.route,_objImage.objectName, _objImage.selected, _objImage.NextContinuationToken )
			var isValid= true
			for (let h in history){
				if (history[h].prevToken == objImage.ContinuationToken && history[h].nextToken == objImage.NextContinuationToken  ){
					isValid= false
				}
			}
			if (isValid){
				history.push({ prevToken:objImage.ContinuationToken, nextToken: objImage.NextContinuationToken })		
			}
			objImage["history"] = history
	  }else if (action==="filterValue"){
	  	objImage =_objImage
	  	objImage["filterValue"] = (event.target.value).toUpperCase()
	  	if (!objImage["originalValues"]){
	  		console.log("creamos")
	  		objImage["originalValues"] = objImage["items"] 
	  	}
	  	
	  	if (event.target.value.length>=3){	  			
	  		let searchValue	= (event.target.value+"").toUpperCase()	  		
	  		let newList =[]
	  		for (let i in objImage["originalValues"] ){
	  			let item = objImage["originalValues"][i].label
	  			if ( item.toUpperCase().indexOf ( searchValue+"" )>=0 ){
	  				newList.push(objImage["originalValues"][i])
	  			}
	  		}
	  		objImage["items"] = newList
	  		
	  	}else{
	  		objImage["items"] = objImage["originalValues"] 

	  	}
	  	
		}else{
			console.log(">>>>>>>>","No valid option", action)
			objImage =_objImage
		}

	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		return false
	}finally{
		return objImage
	}
}

export async function listImages(_foldername, _objectName, _selected , _ContinuationToken){
	 
	var	respS3 = await callApi ( "s3","listImages", { foldername: _foldername, MaxKeys:180, ContinuationToken: _ContinuationToken } )
 		var items =[]
 		var item=0
 		for (let f in respS3.folders ){
 			if (respS3.folders[f]!="/"){
 				
 				let label = respS3.folders[f].split("/")[respS3.folders[f].split("/").length-2]
 				items.push ({ item:item,  name : respS3.folders[f], label: label, type:"folder"} )	
 				item++
 			}
 		}
 		for (let f in respS3.files ){
 			let label = respS3.files[f].filename.split("/")[(respS3.files[f].filename.split("/").length-1)]
 			let isChecked = false
 			if (_selected){
 				if (_selected.indexOf( respS3.files[f].filepath.replace(folderImages, "") )>=0) {
					isChecked = true
				}			
 			}
 			items.push ({ item:item, name : respS3.files[f].filename, type:"file" , label: label, 
 										checked:isChecked,
 										path: respS3.files[f].filepath.replace(folderImages, "") } )
 			item++
 		}
 		let objImage={
 				items                 : items,
 				originalValues        : items,
				selected              : _selected?_selected:[], 
				newFolderValue        : "",
				route                 : _foldername,
				inputNewFile          : null,
				filterValue           : "",
				objectName            : _objectName,
				ContinuationToken     : respS3.ContinuationToken,
				NextContinuationToken : respS3.NextContinuationToken				
 		}	 
 		//console.log(objImage)
		return objImage;
}
 


export async function getComponentConfiguration(componentId){
	
	var configuration = {"NO_DATA":"NO_DATA"}
	var code=""
	try{
		let resp = await callApi ( "custom","getDetailDomainsById",  {detaildomainid:componentId } )
	 	configuration = JSON.parse(resp.rows[0].CLOB_VALUE);   
	 	code          = resp.rows[0].CODE;   
	}catch(error) {
		configuration={"ERROR":"ERROR", "message": error}
	}finally{
		return  {configuration:configuration ,code:code}
	}
}

export function manageComponent(event,_formData){
	var formData={}
	try{
		
		var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
		
			formData= _formData
			var objComp = _formData[data[1]]
			if (data[0]==="FIELDVALUE"){
				for (let key in objComp.values ){
					if (objComp.values[key].rowId == data[3] ){
						 
						
						if ( event?event.hex:null ){
							objComp.values[key][data[2]] =  event.hex 
						}else{
							objComp.values[key][data[2]] = event.target.value
						}
					}
				}
			}else if(data[0]==="ADDROW"){
				let rowId = 0
		 		for (let key in objComp.values){
		 			if (rowId < parseInt(objComp.values[key].rowId) ){
		 				rowId =parseInt(objComp.values[key].rowId)
		 			}
				} 	
				rowId = rowId+1
				

				let objRow= getSubcomponent (data[2],rowId)  
				console.log(objRow, data[2] )
				objComp.values.push(objRow)
				
				
			}else if(data[0]==="DELROW"){
				var tmpObjCompValues=[]
				for (let key in objComp.values){
					if (objComp.values[key].rowId != data[3] ){						
						tmpObjCompValues.push(objComp.values[key])
					}
				}
				objComp["values"] = tmpObjCompValues
			}else if(data[0]==="TOGGLEROW"){
				for (let key in objComp.values ){
					if (objComp.values[key].rowId == data[3] ){
						objComp.values[key]["showMore"] =objComp.values[key]["showMore"]?false:true
					}else{
						objComp.values[key]["showMore"] = false
					}
				}
				
				/*var tmpObjCompValues=[]
				for (let key in objComp.values){
					if (objComp.values[key].rowId != data[3] ){						
						tmpObjCompValues.push(objComp.values[key])
					}
				}
				objComp["values"] = tmpObjCompValues
				*/
			}				
		formData[data[1]] = objComp	
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		return false
	}finally{
		return formData
	}
}

export async function manageSubComponent(event,_formData){
	var resp={}
	var formData={}
	try{
		
			var data
	  	if(event.target.name){
				data = (event.target.name).split("_")
			}else{
				data = (event.target.parentNode.name).split("_")
			}
			
			let action = data[0]
			let name = data[1]
			let subName = data[2]
			let rowId = data[3]
			let type = data[4]
			let subRowId = data[5]
			var formData = _formData
			//console.log("data",data)
			
			let pos_rowId = find ( _formData[name].values, parseInt( data[3]) ,"rowId"  )
			  
			//let subRowId = 0
			
			if (action == "ADDROW"){
				subRowId = 0;
				if (!_formData[name].values[ pos_rowId ][subName] || _formData[name].values[ pos_rowId ][subName].length==0 ){
					formData[name].values[ pos_rowId ][subName] = []
					
				}
				_formData[name].values[ pos_rowId ][subName].map(function (key) {
					if (parseInt(key.rowId) > subRowId){
						subRowId = parseInt(key.rowId)
					}
				}) 
				subRowId++;
				if ( type == "CompItem"){
					formData[name].values[ pos_rowId ][subName].push(
					{ text:"", value:"" , rowId: subRowId }) 
				}else if( type == "CompHeaderIconItem"){
					formData[name].values[ pos_rowId ][subName].push(
					{ label:"", link:"" , rowId: subRowId, image:"" }) 
				}
			}else if ( action == "DELROW"){
				_formData[name].values[ pos_rowId ][subName].map(function (key, item) { 	
					if (key.rowId ==subRowId)	{
						 _formData[name].values[ pos_rowId ][subName].splice(item,1)
					}
				}) 
			}else if ( action == "FIELDVALUE"){
				_formData[name].values[ pos_rowId ][subName].map(function (key) {
					if (key.rowId == subRowId)	{
						let fieldName=data[4]
						key[fieldName] = event.target.value
					}
				}) 
			}
				
			let subcomponentName = name+"_"+subName+"_"+rowId 
			
			resp = {
				formData:formData, 
			  subcomponentName:subcomponentName,
			  data : formData[name].values[ pos_rowId ][subName]
			}
		 
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		resp = {
				formData:null
			}
		return resp
	}finally{
		return resp
	}
}

export async function validateComponent(componentType,_formData, _toast){
	
	var resp={
		isValid:false,
		formData:{}
	}
	try{
		var formData = _formData	  
		var isValid= true
		var component = _formData[componentType];
			if (component.type==="CompCard"){
				component.values.map(function (item) {
					if( item.header==="" ) { item["isInvalid_header"] = true; isValid = false }else{ item["isInvalid_header"] = false; }
					if( item.width==="" )  { item["isInvalid_width"]  = true; isValid = false }else{ item["isInvalid_width"]  = false; }
					if( item.body===""  )  { item["isInvalid_body"]   = true; isValid = false }else{ item["isInvalid_body"]  = false; }
				}); 
			}else if (component.type==="CompOption"){
				component.values.map(function (item) {
					if( item.name==="" ) { item["isInvalid_name"] = true; isValid = false }else{ item["isInvalid_name"] = false; }
				//	if( item.image==="" )  { item["isInvalid_image"]  = true; isValid = false }else{ item["isInvalid_image"]  = false; }
					if( !validateNumber(item.sortorder)  )  { item["isInvalid_sortorder"]   = true; isValid = false }else{ item["isInvalid_sortorder"]  = false; }
				}); 
			}else if (component.type==="CompSliderImg"){
				component.values.map(function (item) {
					if( item.title==="" ) { item["isInvalid_title"] = true; isValid = false }else{ item["isInvalid_title"] = false; }
					if( item.image==="" )  { item["isInvalid_image"]  = true; isValid = false }else{ item["isInvalid_image"]  = false; }
				}); 
			}else if (component.type==="CompList"){
				component.values.map(function (item) {
					if( item.title==="" ) { item["isInvalid_title"] = true; isValid = false }else{ item["isInvalid_title"] = false; }
					if( item.text==="" )  { item["isInvalid_text"]  = true; isValid = false }else{ item["isInvalid_text"]  = false; }
				}); 	 
			}else if (component.type==="CompCardChild"){
				component.values.map(function (item) {
					 
					if( item.children==="" )  { item["isInvalid_children"]  = true; isValid = false }else{ item["isInvalid_children"]  = false; }
				}); 
			}else if (component.type==="CompButton"){
				component.values.map(function (item) {
					if( item.label==="" ) { item["isInvalid_header"] = true; isValid = false }else{ item["isInvalid_label"] = false; }
					if( item.action==="" )  { item["isInvalid_action"]  = true; isValid = false }else{ item["isInvalid_action"]  = false; }
				}); 
			}else if (component.type==="CompCategoryBlog"){
				component.values.map(function (item) {
					if( item.title==="" &&  item.content==="" &&item.image==="" )  { 
						let message = "At least one value need to be filled (title,content or image)"
						_toast.error("VALIDATION ERROR:"+message, {closeButton: true});
						isValid = false 
					}else{
						 
					}
				}); 
			}else if (component.type==="CompCoords"){
				component.values.map(function (item) {
					if( item.latitude==="" ) { 
						item["isInvalid_latitude"] = true; 
						isValid = false 
					}else{
						let latitude = item.latitude.replace(",", ".")
						if (!validateFloat (latitude)){
							item["isInvalid_latitude"] = true; 
							isValid = false 
						}else{
							let latitude= convertNumber(item.latitude)
							if (parseFloat(latitude) < maxLat && parseFloat(latitude) > minLat ) {
								item["isInvalid_latitude"] = false; 		
							}else{
								item["isInvalid_latitude"] = true; 
								isValid = false 
							}
						}
					}
					
					if( item.longitude==="" ) { 
						item["isInvalid_longitude"] = true; 
						isValid = false 
					}else{
						let longitude = item.longitude.replace(",", ".")
						if (!validateFloat (longitude)){
							item["isInvalid_longitude"] = true; 
							isValid = false 
						}else{
							let longitude= convertNumber(item.longitude)
							if (parseFloat(longitude) < maxLng && parseFloat(longitude) > minLng ) {
								item["isInvalid_longitude"] = false; 		
							}else{
								item["isInvalid_longitude"] = true; 
								isValid = false 
							}
						}
					}
				}); 	 
			}
			_formData[componentType] = component
			resp= {
				isValid:isValid,
				formData:formData
			}
			 
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		resp={
		isValid:false,
		formData:{}
		}
 
	}finally{
		return resp
	}
}

export function convertNumber(_string) {
	let _fixString = _string.replace(",", '.')
	if (isNaN(parseFloat(_fixString))){
  	return  null
	}else{
	  return parseFloat(_fixString);
	}
}

export async function getDetailDomainsByCode(_formData){
	
	var resp={
		rows:0,
		success:false
	}
	var rows=[];	
	var data = Object.keys(_formData)
	try{
		var tempDetailDomains = localStorage.getItem('DetailDomains_'+_formData.domainid)
			if (tempDetailDomains){				
				var domains = JSON.parse(localStorage.getItem('DetailDomains_'+_formData.domainid))
				var exists=false;
				if (domains){
					if(domains.length>0){
						rows = domains
						exists=true;
					}
					
				}
				if(!exists){
					console.log("cargaos")
					let resp = await callApi ( "custom","getDetailDomainsByCodeFromDB", _formData )	
					rows = resp.rows
					localStorage.setItem('DetailDomains_'+_formData.domainid,  JSON.stringify(resp.rows))
				}
			 
			}else{
				let resp = await callApi ( "custom","getDetailDomainsByCodeFromDB", _formData )	
				rows = resp.rows
				localStorage.setItem('DetailDomains_'+_formData.domainid,  JSON.stringify(resp.rows))
			}
			resp= {
				success:rows.length>1?true:false,
				rows:rows
			}
			 
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		resp={
		rows:0,
		success:false,
		message:Excep
	}
 
	}finally{
		return resp
	}
}


export async function onDragEnd(result,_formData){
	
	var resp=false
	var listFrom=[]
	var listTo=[]
	var fieldName=""
	try{
		fieldName = result.draggableId.split("__")[0]
		listFrom = _formData[fieldName].values
		listTo   =  _formData[fieldName].value
		const { source, destination } = result;
	  if (!destination) {
	  	_formData[fieldName].values=listFrom
			_formData[fieldName].value=listTo
	      resp=false;
	  }
  	if (source.droppableId === destination.droppableId) {     
      if (source.droppableId === 'droppable') {
      	const items = reorder(
          listFrom,
          source.index,
          destination.index
      ); 
      	_formData[fieldName].values= items
    	}else{
    		const items = reorder(
          listTo,
          source.index,
          destination.index
        
     		);
    		_formData[fieldName].value= items
    	} 
  	} else { 
  	 	if (source.droppableId==="droppable"){
    	 	const result = move(
    	 			listFrom,
    	 			listTo,
            source,
            destination
        );
				_formData[fieldName].values=result.droppable
				_formData[fieldName].value=result.droppable2
        resp=true
  		}else{ 
        const result = move(
            //this.getList(source.droppableId),
            //this.getList(destination.droppableId),
            
	 					listTo,listFrom,
            source,
            destination
        );
				_formData[fieldName].values=result.droppable
				_formData[fieldName].value=result.droppable2
        resp=true
  		}      		
  	}
	}catch(Excep){
		console.log("Excep",Excep)
		_formData[fieldName].values=listFrom
		_formData[fieldName].value=listTo
		resp=true
	 
	}finally{
		return resp
	}
}
export async function filterHandler(event, _filterConfig, _tableData){
	//console.log("hola")
	//console.log(event.target.name)
	
	var filterConfig = _filterConfig
	
	if (_filterConfig[event.target.name]){
		//console.log("existe")
		//console.log("val", event.target.value)
		filterConfig[event.target.name].value= event.target.value
		return {
			filterConfig:_filterConfig,
		}
	}else if(event.target.name=="search") {
		
		//console.log("actu", _tableData.rows.length)
		var tableData = { columns : _tableData.columns, rows:[] }
		var newRows =[]
		
		var fieldsToEval=[]
		Object.keys(_filterConfig).map(function (key) {
			let item = _filterConfig[key]
			if (item.value!=""){
				fieldsToEval.push({ field: key, value: item.value, type: item.type } )
			}
		}); 
 
		if (fieldsToEval.length>0){
			_tableData.rows.map(function (item) {
				let isValid = true
				for (let key in fieldsToEval){
					Object.keys(item).map(function (col) {
						if (isValid){
							if (fieldsToEval[key].field == col){
								
								if ((item[col])){
									if ( fieldsToEval[key].type =="TEXT"){
										if ( (item[col]).toUpperCase().indexOf( (fieldsToEval[key].value).toUpperCase() ) < 0)  {
											isValid=false
										}
									}else if  ( fieldsToEval[key].type =="NUMBER"){
										if ( item[col] != fieldsToEval[key].value )  {
											isValid=false
										}
									}else if  ( fieldsToEval[key].type =="SELECT"){
										if ( item[col] != fieldsToEval[key].value )  {
											isValid=false
										}
									}
								}else{
									isValid=false
								}
								 
							}
						}
					})
				}
				if (isValid){
					newRows.push(item)
				}
			}); 
			tableData["rows"] = newRows
			
		}else{
			 tableData = { columns : _tableData.columns, rows: 	_tableData.rows }	
		}
		return { 
			tableData:tableData
		}
	}else if(event.target.name=="refresh") {
		
		Object.keys(_filterConfig).map(function (key) {
			let item = _filterConfig[key]
			item.value=""
		}); 
		
		return { 
			filterConfig:_filterConfig,
			tableData:_tableData
		}
	}
}

export async function searchHandler(event, _filterConfig,  _showMore, apiName){
	//console.log("hola")
	//console.log(event.target.name)
	 
	var filterConfig = _filterConfig
	var showMore = _showMore
	
	
	
	var option =  findObjName(event)
	 
	if (_filterConfig[option]){
		//console.log("existe")
		//console.log("val", event.target.value)
		filterConfig[event.target.name].value= event.target.value
		return {
			filterConfig:_filterConfig,
			showMore : _showMore
		}
	}else if(option=="showMore") {
		showMore = _showMore?false:true
		return {
			filterConfig:_filterConfig,
			showMore : showMore
		}
	}else if(option=="search") {
		
		
	 
		var formData = parseFormData(_filterConfig)
		 
		let resp = await callApi ( "report",  apiName?apiName:"repOrders", formData )
		
		var reports ={}
			
		Object.keys(resp).map(function (item) {
			
			console.log(item)
			if(Array.isArray(resp[item])){
				console.log("es reporte", resp[item])
				reports[item] = (resp[item])
			}
		}); 
		
	 
		return { 
			tableData:reports
		}
	}else if(event.target.name=="refresh") {
		
		Object.keys(_filterConfig).map(function (key) {
			let item = _filterConfig[key]
			item.value=""
		}); 
		
		return { 
			filterConfig:_filterConfig,
			tableData:{}, 
			showMore:showMore
		}
	}
}

export function findValueById(_json, _value, _key, _param){
	var pos = -1
	var resp
	_json.map(function (key, item) {
		if ( key[_key] ==_value ){
				pos= item
			}
		}); 
	if (pos>=0){
		resp = _json [pos][_param]	
	}else{
		resp="NOT FOUND"
	}
	
		
	return resp
}

export async function changeFilter(event, _originalList, _formData){
	
	var listFrom=[]
	var listTo=[]
	var fieldName=""
	var formData = _formData
	try{
		
		let _data = findObjName(event)
		let data =  _data.split("__")
		
		 
		
		if (data.length>1){
			
			if (data[1]== "showAll" ){
				formData[data[0]].showAll = formData[data[0]].showAll?false: true
			}else if (data[1]== "checkAll" ){
				
				//console.log(data[0], formData[data[0]])
				
				if (formData[data[0]].checkAll){
				//	console.log("estaba todo vaciamos")
					formData[data[0]].value=[]
				}else{
					//console.log("llenamos")
					formData[data[0]].value=[]
					 
					
					console.log( _formData[data[0]]["values"] ) 
					let tmpSelects=[]
					for (let o in _formData[data[0]]["values"]   ){
						tmpSelects.push( _formData[data[0]]["values"][o].value )
					}
					formData[data[0]].showAll=true
					formData[data[0]].value=tmpSelects
					console.log(tmpSelects)
				 
				}
			
				
				formData[data[0]].checkAll = formData[data[0]].checkAll?false: true
				
			}else if (data[1]== "refresh" ){
				 
				let originalList =[]
				let actualList = _formData[data[0]].value;
				 
				if  (actualList.length>0){
					for (let o in _originalList){
						let existField= false;
						for (let a in actualList){
							if (_originalList[o].value == actualList[a].value ){
								existField = true
							}
						}
						if (!existField){
							originalList.push(_originalList[o])
						}
					}
				}else{
					originalList = _originalList
				}
				
				let newValues =[]
				for (let o in originalList){
					let isValid= true
					//console.log("si",formData[data[0]].filterValues)
					
					for (let f in formData[data[0]].filterValues){
						let filter = formData[data[0]].filterValues[f]
						if (filter.value!="ALL"){
							 
							if ( originalList[o][filter.label] != filter.value ){
								isValid=false
							}
						}
					}
					let filterValue = _formData[data[0]]["filterValue"]
					if (filterValue){
						if (originalList[o].text && filterValue.length>=3){
							if ((originalList[o].text).toUpperCase().indexOf(filterValue.toUpperCase())<0){
								isValid=false
							}
						}
					}
						
					
					if(isValid){
						newValues.push(originalList[o])	
					}
				}
				_formData[data[0]]["values"] = newValues
			
			}else{
				let fieldValue =  event.target.value			
				let fieldName=data[0]
				let filterName=data[1]
				let pos = find ( formData[fieldName].filterValues , filterName, "label" )
				formData[data[0]].filterValues[pos].value = fieldValue
				
				let originalList =[]
				let actualList = _formData[data[0]].value;
				if  (actualList.length>0){
					for (let o in _originalList){
						let existField= false;
						for (let a in actualList){
							if (_originalList[o].value == actualList[a].value ){
								existField = true
							}
						}
						if (!existField){
							originalList.push(_originalList[o])
						}
					}
				}else{
					originalList = _originalList
				}
				
				let newValues =[]
				for (let o in originalList){
					let isValid= true
					for (let f in formData[fieldName].filterValues){
						let filter = formData[fieldName].filterValues[f]
						if (filter.value!="ALL"){
							if ( originalList[o][filter.label] != filter.value ){
								isValid=false
							}
						}
					}
					if(isValid){
						newValues.push(originalList[o])	
					}
				}
				_formData[data[0]]["values"] = newValues
			}
		}else{
			let fieldValue =  event.target.value
			_formData[event.target.name]["filterValue"] = fieldValue
			let originalList =[]
			let actualList = _formData[event.target.name].value;
			
			if  (actualList.length>0){
				for (let o in _originalList){
					let existField= false;
					for (let a in actualList){
						if (_originalList[o].value == actualList[a].value ){
							existField = true
						}
					}
					if (!existField){
						originalList.push(_originalList[o])
					}
				}
			}else{
				originalList = _originalList
			}
			if (fieldValue.length >= 3){
				let newValues =[]
				for (let o in originalList){
					if (originalList[o].text){
						if ((originalList[o].text).toUpperCase().indexOf(fieldValue.toUpperCase())>=0){
							newValues.push(originalList[o])
						}
					}
					
				}
				_formData[event.target.name]["values"] = newValues
			}else{
				_formData[event.target.name]["values"] = originalList
			}
		}
	 	
	 	 
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
		formData= _formData
	}finally{
		 
		return formData
	}
	
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
	 
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export function getImgagesExtension(){

	return process.env.REACT_APP_DEF_IMG_EXTENSION;
}

export function searchArray (  _array,_item, _field, _itemVal , _arrValids ){

	var ret = false
	var result=[]
			for (let i in _array ){
				if (_array[i][_item] == _itemVal ){

					if ( _arrValids.length ===0){
						result.push ( _array[i][_field] )
					}else{
						if (_arrValids.indexOf ( _array[i][_field] ) >=0 ){
							result.push ( _array[i][_field] )
						}
					}
				}
			}
	return result;
}
export function getRowsPaginationValues(){

	var rows=[]
	if (localStorage.getItem('def_language') == 3){
		rows =[ {value: 15, text: "Ver 15 Elementos por p\u00E1gina"},
		 				{value: 30, text: "Ver 30 Elementos por p\u00E1gina"},
		 				{value: 45, text: "Ver 45 Elementos por p\u00E1gina"},
		 				{value: 60, text: "Ver 60 Elementos por p\u00E1gina"}
					]
	}

	return rows
}

export function getSortMethods(){

	var rows=[]

	if (localStorage.getItem('def_language') == 3){
		rows =[ {value: "new",  text: "M\u00E1s reciente"},
		 				{value: "minorprice", text: "Menor Precio"},
		 				{value: "majorprice", text: "Mayor Precio"},
		 				{value: "ranking", text: "M\u00E1s vendidos"},
		 				{value: "AZ", text: "A - Z"},
		 				{value: "ZA", text: "Z - A"},
		 				{value: "discount", text: "Mejor Descuento"}
					]
	}

	return rows
}

export function getCurrency(){
	return 'Bs.'
};
export function getDefValues(){
	
	var values={}
	
	values = {
		newLabel: "Nuevo",
		btnaddbutton:"Agregar al carro",
		shortbtnaddbutton:"Agregar",
		proddecriptionlabel: "Descripci\u00F3n del producto",
		prodaditionalinfo:	"Informaci\u00F3n adicional",
		prodShare:	"Compartir",
		PriceLabel: "Precio",
		relatedProductsLabel:"Nuestras sugerencias para ti",
		cartLabel : "Carro de compras",
		cartLabelDesc : "Ac\u00E1 est\u00E1n los productos agregados a tu carro de compra",
		shopCartProductLabel: "Producto",
		shopCartPriceLabel: "Precio",	 	
		shopCartQuantityLabel: "Cantidad",
		shopCartDiscountLabel: "Descuento",
		shopCartSubtotalLabel: "Subtotal",
		showCart :"Ver y Editar Carro",
		completeOrder :"Completar la compra",
		orderResumeLabel : "Resumen del pedido",
		orderLabel : "Pedido",
		paymentDateLabel:"Fecha de compra",
		shippingDateMessageLabel:"Hola, tu pedido fue entregado el",
		orderDetailLabel : "Seguimiento de tu Compra",
		messageCompleteOption:"Complete la opci\u00F3n",
		subtotalOrderLabel : "Subtotal del pedido",
		totalOrderLabel : "Total del pedido",
		buyLabel : "Completar la compra",
		initPaymentLabel : "Iniciar compra",
		continueBuyingLabel : "Agregar m\u00E1s productos al carro",
		shortBuyLabel : "Ir a Pagar",
		shortBuyLabel : "Ir a Pagar",
		startTrackingLabel : "Ir Al Seguimiento", 
		orLabel :"\u00F3",
		loginLabel: "Iniciar sesi\u00F3n",		
		remembermeLabel:"Recordar sesi\u00F3n",
		loginWithSocialNetwork:"Iniciar sesi\u00F3n usando una red social",
		registerLabel: "Registarse",
		emailLabel: "Email",
		passwordLabel: "Constrase\u00F1a",
		forgotPasswordLabel:"Olvid\u00E9 mi contrase\u00F1a",
		createAccountLabel:"Crear cuenta   >",
		continueAsGuest:"Continuar como invitado",
		successfullyAddedLabel:"Agregado Correctamente al carro",
		successfullyRemoveItemLabel: "Producto removido correctamente al carro",
		successfullyChangedItemLabel: "Cantidad cambiada correctamente",
		emtpyCartLabel : "El Carro vac\u00EDo",
		startBuyingLabel : "Continue comprando",
		confirmOrderLabel:"Confirmar Pedido",
		myCartLabel : "Mi carro",
		ShippingLabel : "Entrega",
		sendLabel :"ENVIAR",		
		paymentLabel : "Pago",
		personalDataLabel: "Datos personales",
		shippingInfoLabel: "Entrega / Retiro",
		nextLabel: "Siguiente >",
		emailLabel: "Email",
		firstNameLabel: "Nombres",
		lastNameLabel: "Apellidos",
		nitLabel: "Nit",
		phoneLabel: "Tel\u00E9fono",
		codeLabel: "Cod.",
		streetLabel:"Calle",
		streetnumberLabel:"N\u00FAmero",
		referenceAddressLabel:"Referencias de direcci\u00F3n",
		fastShippingLabel:"Envio el mismo dia",
		apartmentLabel: "Piso o Departamento",
		recipientLabel: "Nombre de quien recibe",
		stateLabel: "Provincia",
		cityLabel: "Ciudad",
		additionalShippingInstructionsLabel: "Indicaciones para la entrega",
		isEmptyLabel:"Est\u00E1 vac\u00EDo",
		productsLabel: "productos",		
		previousStepIncompleteLabel: "A\u00FAn falta completar los datos del cuadro anterior",		
		shippingMethodLabel: "Entrega en domicilio",
		terminalBusMethodLabel: "Entrega en terminal de buses",
		pickupFromStoreMethodLabel: "Retiro en tienda",		
		terminalNameLabel: "Nombre de la Terminal",
		storeNameLabel: "Tienda",
		continueLabel: "Continue navegando",
		resultsLabel:"Resultados",
		searchLabel: "Escribe tu busqueda",
		categoryLabel:"Categoria",
		showMoreLabel: "Mostrar mas...",
		hideLabel: "Ocultar",		
		myAccountLabel:"Mi Cuenta",
		myOrdersLabel:"Mis pedidos",
		myWishListLabel:"Wishlist",
		myAddressLabel:"Mis direcciones",
		mySuscriptionsLabel:"Suscripciones al Newsletter",
		infoAccountLabel:"Informaci\u00F3n de la cuenta",
		orderByLabel: "Ordenar Por",
		publishFacebookLabel:"Publicar en Facebook",
		noRecordsFoundLabel: "Lo sentimos, no encontramos resultados.",
		selecteOptionsLabel : "Por favor selecciona las opciones del producto para agregarlo al carrito",
		selectQuantityLabel : "Por favor selecciona la cantidad",
		userExistsLabel :"El usuario ya existente intente con otro correo",
		gotoStart :"Volver al inicio",		
		userOrPassErrorLabel : "Usuario o Password Incorrecto",
		unknownErrorLabel   : "Error desconocido",
		shippingCostLabel   : "Costo por env\u00EDo",
		itemsOrderLabel :"Art\u00EDculos comprados",
		ordermessageLabel:"El ID de su orden es:",		
		successfullAddedToWishListLabel:"Agregado a su Lista de deseos",
		errorAddedToWishListLabel:"Error agregando producto, intente nuevamente",
		firstLoginLabel:"Debe estar registrado",
		relatedProductsLabel: "TE ENCANTAR\u00C1",
		limitedExceeded :"Cantidad l\u00CDmite de producto excedida",	
		orderInfoLabel: "Informaci\u00F3n del pedido",
		orderResumeLabel: "Detalle de la compra",
		Label: ""
		
	}
	return values
}


export function toFixed( _value ){
	var resp
	try{
		resp = _value.toFixed(2)
	}catch(Excep){
		try{
			resp = _value.toFixed(1)	
		}catch(Excep2){
			resp  = _value
		}
	}
	return resp
}


export async function getFilterProductsElastic(_data){

	var resp={
		rows:0,
		success:false
	}
	var rows=[];
	var finalRows=[]
	var meta={};
	try{
 
		var data= { page: { current:1, size:1000 },
							   query: _data.searchkey  ,
							   filters : {
							            all: [
							               { storeid :  _data.storeid },
							               { productqty :
							               		{from: 1,
							                	 to: 99999
							               	  }
							               }
							            ]
							    },
								"search_fields": {
									"productid": {"weight": 1},
									"genericsku": {"weight": 1},
									"colors": {"weight": 1},
									"sizes": {"weight": 1},
									"productname": {"weight": 10},
									"tags": {"weight": 1},
									"id": {"weight": 1}
								},
								precision: getDefValues().precisionSearch
							  };

		const axios = require('axios');

		await axios
		    .post(
		       	process.env.REACT_APP_ENDPOINTBASE+"/api/as/v1/engines/"+process.env.REACT_APP_ENGINENAME+"/search",
		        data,
		        {headers: {
						'Access-Control-Allow-Origin': '*',
						'origin':'x-requested-with',
              'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+process.env.REACT_APP_SEARCHKEY

					}}
		    )
		    .then(response => response.data)
		    .then(data => {

		      meta = data.meta
		      for ( let k in data.results ){

	  	  	let _options =[]
					let _quantities =[]
					let _categories =[]
					let key = data.results[k]
					for (let o in key["options"].raw){
						_options.push (JSON.parse(  key["options"].raw[o]  ) )
					}
					for (let o in key["categories"].raw){
						_categories.push (JSON.parse(  key["categories"].raw[o]  ) )
					}
					for (let o in key["quantities"].raw){
						_quantities.push (JSON.parse(  key["quantities"].raw[o]  ) )
					}
  	  				let finalRow= {
								 "productid"           : key["productid"].raw,
								 "languageid"          : key["languageid"].raw,
								 "productname"         : key["productname"].raw,
								 "productdescription"  : key["productname"].raw,
								 "productmodel"        : key["productmodel"].raw,
								 "genericsku"          : key["genericsku"].raw,
								 "price"               : key["price"].raw,
								 "quantity"            : 1,
								 "statusid"            : key["statusid"].raw,
								 "dateadded"           : key["dateadded"].raw,
								 "image"               : key["image"].raw,
								 "status"              : key["status"].raw,
								 "newprice"            : key["newprice"].raw,
								 "newpricediscount"    : key["newpricediscount"].raw,
								 "newquantitydiscount" : key["newquantitydiscount"].raw,
								 "datestartdiscount"   : key["datestartdiscount"].raw,
								 "dateenddiscount"     : key["dateenddiscount"].raw,
								 "datestart"           : key["datestart"].raw,
								 "dateend"             : key["dateend"].raw,
								 "age"                 : key["age"].raw,
								 "canonicalpath_id"    : key["canonicalpath_id"].raw,
								 "options"             : _options,
								 "quantities"          : _quantities,
								 "categories"          : _categories,
								 "category"            : key["category"].raw,
								 "tags"                : key["tags"].raw,
								 "minprice"            : key["minprice"].raw,
								 "maxprice"            : key["maxprice"].raw,
								 "isvalid"             : true,
								 "storeid"             : key["storeid"].raw,
								 "path"                : key["path"].raw,
								 "colors"              : key["colors"].raw,
								 "sizes"               : key["sizes"].raw

	   					}
	   					finalRows.push(finalRow)
	  	  	}
		    })
		    .catch(function(error) {
		      console.log(">>>>>>>> Error api",error);
		      resp = {success:false, rows: [], message:"ERR:",error}
		    });
			resp = {success: finalRows.length>0?true:false,
							rows: finalRows ,
							meta: meta
						 }
			return resp
		 
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
			resp={
			rows:0,
			success:false,
			message:Excep
		}
 		return resp
	}
}


export function formatPathName ( string ){


	try{
		var category = (string.toString()).toLowerCase();


			category =  category.split("inicio").join("\/");
			category =  category.split("home").join("\/");
			category =  category.split("\ > ").join("\/");
			category =  category.split("\ ").join("-");

  		category =  category.split("\ ").join("-");
  		category =  category.split("\/").join("-");
  		category =  category.split("\|").join("-");
  		category =  category.split("\u00E1").join("a");
  		category =  category.split("\u00E9").join("e");
  		category =  category.split("\u00ED").join("i");
  		category =  category.split("\u00F3").join("o");
  		category =  category.split("\u00FA").join("u");
  		category =  category.split("\u00F1").join("n");
  		category =  category.split(",").join("-");
  		category =  category.split("--").join("-");

	return category;
	}catch(Ex){
		console.log("Error",Ex,string)
		return string
	}

}


export async function getJSONDefinition(_url, _method, _formData){

	var resp={	template:null,
							success:false,
					    errorcode:"1"
					 }
	try{

		const axios = require('axios');
		await axios({
			headers: {
				'Access-Control-Allow-Origin': '*',
				'origin':'x-requested-with',
            'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
            'Content-Type': 'application/json'
				
				},
	      url: _url,
	      method: 'post'      
	  }).then(response => response.data).then(data => {
    	console.log(data)
    	
    	var configuration = JSON.stringify(data)
	   	let templateData = JSON.parse(configuration);   
  		resp=({
			  	success:true,
			    errorcode:"0",
			    template:templateData
			}); 
    })
    .catch(function(error) {
      console.log(">>>>>>>> Error api",error);
      resp = {success:false, rows: [], message:"ERR:",error}
    });
	 
		return resp
		 
	}catch(Excep){
		console.log(">>>>>>>>",Excep)
			resp={
			template:null,
			success:false,
			message:Excep
		}
 		return resp
	}
}


export async function loadPageV2 (jsonObjet, _managepagination, _manageCart, _changeHandler, _manageButton, _toggleModal, windowWidth) {
  var finalObject=[]
  let containerMaster = []
  for (let key in jsonObjet ){
  	let obj = jsonObjet[key]
  	let container = []

  	if (!obj.isChildren){

  		let renderObj = await getRenderObj ( obj, _managepagination,_manageCart, _changeHandler, _manageButton, _toggleModal  , windowWidth,obj.curdata );

  		if (renderObj.data ==null || !renderObj.data  ){
  			var data=[]  			
  			if (obj.data.children && !obj.content.length){
  				obj["content"] =[ {children:obj.data.children} ]
  			}
				var objectsLoaded=[]
  			for (let i in  obj.content){
  				var cfg2= obj.content[i]
  				for (let i1 in jsonObjet){
  					let c1 = jsonObjet[i1]
  					if (c1.name == obj.content[i].children /*&&  objectsLoaded.indexOf( c1.name||i1 )<0*/ ){
  					 	objectsLoaded.push( c1.name||i1 )
	 						var cfg1= c1["config"]
  						for (let k in Object.keys(cfg2) ){
								let field = Object.keys(cfg2)[k]
				 				cfg1[field] = cfg2[field]
  						}
  						cfg1["fatherconfig"] = obj.config?obj.config:null
  						let renderObjC1 = await getRenderObj ( c1, _managepagination,_manageCart, _changeHandler, _manageButton, _toggleModal , windowWidth,obj.curdata );
   						data.push(renderObjC1)
  					}
  				}
  			}
  			/*
  			for (let i1 in jsonObjet){
  				let c1 = jsonObjet[i1]

  				if (c1.parent == obj.name ){

  					let renderObjC1 = await getRenderObj ( c1, _manageCart, _changeHandler, _manageButton, _toggleModal , windowWidth,obj.curdata );
  					data.push(renderObjC1)

  				}
				}*/

				var renderData =[]
				data.map(  function (child) {
				//
  				 renderData.push({body :child.render})

				});

				renderObj = await getRenderObj ( obj, _managepagination, _manageCart, _changeHandler, _manageButton, _toggleModal,  windowWidth, renderData);
 				containerMaster.push(renderObj.curdata)
  		}else{
  			containerMaster.push(renderObj.curdata)
  		//	console.log("render",renderObj.render)
  		}
  	}else{
  		//console.log("es hijo"+obj.name+"_"+obj.parent)
  	}
  }
  //console.log(containerMaster)
  return  containerMaster
}


export async function getRenderObj ( _obj,_managepagination, _manageCart, _changeHandler,_manageButton, _toggleModal, windowWidth, _curdata){

	try{
		var renderObj;
		if (_obj.componentType === "ABOUT" ){
			renderObj= {curdata:
										{	type   : _obj.componentType,
											data   : _obj.data,
											config : _obj.config
										},
									render:	""
									}
			
		}else if (_obj.componentType === "CARD" || _obj.componentType === "CARDCONTAINER" ){

			var _config =_obj.config
			if (!_config.content){
				_config["content"] =_obj.content
			}/*
			var finalContainer= []
			for(var c in _obj.content){

				let content = _obj.content[c]

			 	console.log("content", content)



			 	//var renderChild = await getRenderObj ( key, null, null,null, null, null, null)

				finalContainer.push(content)
			}
			*/
			renderObj= {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: _curdata,
											config:_obj.config
										},
									render:	<Card
														ObjectName={_obj.name}
														cardData= {_curdata}
														cardConfig={_obj.config}
													/>
									}

		}else if (_obj.componentType ==="SLIDER"  || _obj.componentType === "SLIDERCONTAINER" ){

			var config =   {
				dots           : _obj.config.dots+"" =="false" ?false:true,
			  arrows         : _obj.config.arrows+"" =="false" ?false:true,
	    	slidesToShow   : windowWidth <= 799?2:( _obj.config.slidestoshow  ? parseInt(_obj.config.slidestoshow)  :3 ),
			  slidesToScroll : _obj.config.slidestoscroll? parseInt(_obj.config.slidestoscroll):1,
	    	autoplay       : true,
	    	cssEase        : "linear",
	    	speed          : _obj.config.speed        ? parseInt(_obj.config.speed):500,
	    	autoplaySpeed  : 30000,// _obj.config.autoplayspeed ? parseInt(_obj.config.autoplayspeed):3000,
	    	pauseOnHover: true,
	    	divWidth: "card col-md-12 col-lg-12 col-xl-12"
			}

			renderObj= {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: _curdata,
											config:config
										},
									render:
									 <Slider
									 	ObjectName={_obj.name}
										sliderData={_curdata}
										sliderConfig={config}
									/> 	}

		}else if (_obj.componentType ==="MODAL"){
			var config =  _obj.config;
			config["startopen"] =  _obj.config.startopen+"" =="true"?true:false
			var _renderModal = <Modal
	  								name={_obj.name}
	  								ObjectName={_obj.name}
	  								toggle={_toggleModal}
										modalData={_curdata}
										modalConfig={config}
									/>

			if (config["store"] ) {
				if (config["store"] !=""){
				 	let selectedStoreId =  getCurrentStore();
					if (config["store"] == selectedStoreId ){
					} else{
						_renderModal=null;
						_curdata = null;
					}
				}
			}
	  	renderObj = {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: _curdata,
											config:config
										},
								  render:_renderModal}

		}else if (_obj.componentType ==="SLIDERIMAGE"){

			var config =   {
			      	dots           : _obj.config.dots+"" =="false" ?false:true,
			      	arrows         : _obj.config.arrows+"" =="false" ?false:true,
			      	autoplay       : true,
			      	cssEase        : "linear",
			     		slidesToShow   : windowWidth <= 799?1 :( _obj.config.slidestoshow  ? parseInt(_obj.config.slidestoshow)  :1),
			        slidesToScroll : _obj.config.slidestoscroll? parseInt(_obj.config.slidestoscroll):1,
			      	speed          : _obj.config.speed        ? parseInt(_obj.config.speed):500,
	    				autoplaySpeed  : _obj.config.autoplayspeed ? parseInt(_obj.config.autoplayspeed):3000,
			      	pauseOnHover   : true,
			      	divWidth       : "card col-md-12 col-lg-12 col-xl-12"
			    	}
			var finalContainer = [];

			for(var c in _obj.content){

				let content = _obj.content[c]

				if (content.height && content.height!= "" ){
					content["height"]  = content.height.indexOf("px")>=0 ? content.height : content.height+"px";
				}
				if (content.mheight && content.mheight!= "" ){
					content["mheight"] = content.mheight.indexOf("px")>=0 ? content.mheight : content.mheight+"px";
				}
				if (content.width && content.width!= "" ){
					content["width"]   = content.width.indexOf("px")>=0 ? content.width : content.width+"px";
				}
				if (content.mwidth && content.mwidth!= "" ){
					content["mwidth"]  = content.mwidth.indexOf("px")>=0 ? content.mwidth : content.mwidth+"px";
				}

				finalContainer.push(content)
			}
			renderObj= {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: finalContainer,
											config:config
										},
									render:
									 <SliderImage
									 	ObjectName={_obj.name}
										sliderData={finalContainer}
										sliderConfig={config}
									/> 	}
		}else if (_obj.componentType ==="LIST"){
			var finalContainer= []
			for(var c in _obj.content){
				let content = _obj.content[c]
				finalContainer.push(content)
			}

			renderObj={curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: finalContainer,
										},
								 render: <List
								 						ObjectName={_obj.name}
														listValues={finalContainer}
													/>
								}
								
		}else if (_obj.componentType ==="BLOGCONTAINER"){
		 
			renderObj={curdata:
										{	type:       _obj.componentType,
											ObjectName: _obj.name,
											data:       _obj.data,
											content :   _obj.content
										},
								 render: <BlogContainer
								 						ObjectName={_obj.name}
														blogContainerData={_obj.data}
														blogContainerContent={_obj.content}
													/>
								}						
								
	  }else if (_obj.componentType ==="FORM"){



	  	var content =  _obj.content;

			content= _obj.content.sort(function (a, b){
				return ( convertNumber( a.sortorder) - convertNumber( b.sortorder))
			})


	  	var formData={}
	  	for (let f in content){
	  		formData[_obj.content[f].name ] = _obj.content[f]

	  		if (	_obj.content[f].type == "SELECT" && _obj.content[f].items ){
	  			formData[_obj.content[f].name].values = _obj.content[f].items ?_obj.content[f].items :[{ text:"", value:""} ]
	  			formData[_obj.content[f].name].value =   formData[_obj.content[f].name].values[0].value
	  		}
	  		if (	_obj.content[f].type == "GMAP" ){
	  			formData[_obj.content[f].name].config = { lat:-16.5345619, lng:-68.0900024, zoom: 13}
	  		}

	  		if (	_obj.content[f].type == "CHECKBOX"  && _obj.content[f].items ){
	  			formData[_obj.content[f].name].values = _obj.content[f].items ?_obj.content[f].items :[{ text:"", value:""} ]
	  			formData[_obj.content[f].name].value=[]
	  		}

	  		if (	_obj.content[f].type == "CHECKBOX" && !_obj.content[f].items ){
	  		//	console.log("no tienes items")
	  			formData[_obj.content[f].name].values =  [{ text:_obj.content[f].label , value:"YES"} ]
	  			formData[_obj.content[f].name].value=[]
	  		}

	  		if (	_obj.content[f].type == "ITEMS" && _obj.content[f].items ){
	  			formData[_obj.content[f].name].values = _obj.content[f].items ?_obj.content[f].items :[{ text:"", value:""} ]
	  			formData[_obj.content[f].name].value=[]
	  		}

	  		if (formData[_obj.content[f].name].validationtype == "NOTREQUIRED"){
	  			formData[_obj.content[f].name].notRequired=true
	  			if (formData[_obj.content[f].name].maxlength ){
	  				formData[_obj.content[f].name].validationtype="text"
	  			}else{
	  				formData[_obj.content[f].name].validationtype=""
	  			}
	  		}
	  		if (!formData[_obj.content[f].name].validationtype){
	  			formData[_obj.content[f].name].notRequired=true
	  			if (formData[_obj.content[f].name].maxlength ){
	  				formData[_obj.content[f].name].validationtype="text"
	  			}else{
	  				formData[_obj.content[f].name].validationtype=""
	  			}
	  		}
	  	}
	  	try{
	  		await eval(_obj.config.postprocessing )
	  	}catch(e){
	  		console.log(">>>>>>> ERROR EXECUTING FORM SCRIPT",e)
	  		console.log(_obj.config.postprocessing)
	  	}
 		//console.log(formData)
	  	let finalData ={
	  		formName: _obj.name,
				formLabel: _obj.config.formtitle,
				formOptionID: _obj.config.formid,
				isValid: false,
		  	formData: formData,
		  	formConfig : _obj.config
	  	}
	  	renderObj = {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											finalData: finalData,
											data: formData,
											config: _obj.config
										},
		  						 render: <Form
		  						 					ObjectName={_obj.name}
					  								container={_obj.name}
														formData={formData}
														changeHandler={_changeHandler}
														manageButton={_manageButton}
														formConfig={_obj.config}
														config={_obj.config}
													 />
									}

		}else if (_obj.componentType ==="FORMCONTAINER"){

		
		}else if (_obj.componentType ==="PRODUCT"){
			var productConfig =_obj.config;
			productConfig["quantitySelected"]=  productConfig["quantitySelected"] ? parseFloat(productConfig["quantitySelected"])+ 1 : 1
			productConfig["manageCart"]=_manageCart

			var  data ={
							languageid : getLanguage(),
							categoryid :28,
							storeid: 59
						}

	  	renderObj = {curdata:
										{	type:_obj.componentType,
											name: "product"+ _obj.data.productid,
											ObjectName:_obj.name,
											data: _obj.data,
											config:productConfig
										},
		  						 render:	<Product
		  						 						ObjectName={_obj.name}
		  												name={"product"+ _obj.data.productid}
															productData={_obj.data}
															productConfig={productConfig}
										/>
									}
		}else if (_obj.componentType ==="TEXT"){
	  	renderObj = {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: _obj.data,
											config:_obj.config
										},
	  							 render : <Text
						  								ObjectName={_obj.name}
															textData={_obj.data}
															textConfig={_obj.config}
															config={_obj.config}
														/>
									}
		
		}else if (_obj.componentType ==="BUTTON"){
			
	 		renderObj = {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: _obj.data,
											config:_obj.config
										},
	  							 render : <Button
						  								ObjectName={_obj.name}
															buttonData={_obj.data}
															buttonConfig={_obj.config}
															config={_obj.config}
														/>
									}
									
		}else if (_obj.componentType ==="IMAGE"){
			if (!_obj.parent){
			_obj.config["adjust"] = true
			}
			//console.log(_obj.config)
	  	renderObj = {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: _obj.data,
											config:_obj.config
										},
	  							 render: <Image
					  								ObjectName={_obj.name}
														imageData={_obj.data}
														imageConfig={_obj.config}
														config={_obj.config}
													/>
									}
		}else if (_obj.componentType ==="VIDEO"){
			if (!_obj.parent){
			_obj.config["adjust"] = true
			}
	  	renderObj = {curdata:
										{	type:_obj.componentType,
											ObjectName:_obj.name,
											data: _obj.data,
											config:_obj.config
										},
	  							 render: <Video
					  								ObjectName={_obj.name}
														videoData={_obj.data}
														videoConfig={_obj.config}
														config={_obj.config}
													/>
									}
		}else if (_obj.componentType ==="IFRAME"){
			if (!_obj.parent){
			_obj.config["adjust"] = true
			}
	  	let formData = {
	  		"iframe": {  type: "IFRAME", value: _obj.data.link, height: _obj.data.height, width: _obj.data.width,  size:"col-12" }
	  	}
	  	let formConfig = {colsize :"col-12"}
			let finalData ={
	  		formName: _obj.name,
				formLabel: null,
				formOptionID: 0,
				isValid: false,
		  	formData: formData,
		  	formConfig : formConfig
	  	}

	  	renderObj = {curdata:
										{	type:"FORM",
											ObjectName:_obj.name,
											finalData: finalData,
											data: formData,
											config: _obj.config
										},
		  						 render: <Form
		  						 					ObjectName={_obj.name}
					  								container={_obj.name}
														formData={formData}
														changeHandler={_changeHandler}
														manageButton={_manageButton}
														formConfig={_obj.config}
														config={_obj.config}
													 />
									}

		}else if (_obj.componentType ==="CATEGORYBLOG"){
			renderObj =  {curdata:
										{	type:_obj.componentType,
											ObjectName :_obj.name,
											data: _obj.data,
											content:_obj.content,
											config:_obj.config
										},
										render:  <CategoryBlog
							  								ObjectName={_obj.name}
																categoryBlogData={_obj.data}
																categoryBlogContent={_obj.content}
																categoryBlogConfig={_obj.config}
																config={_obj.config}
															/>
										}
		}else if ( _obj.componentType ==="SLIDERPRODUCTS" || _obj.componentType ==="CARDPRODUCTS"){
			/*****START GETTING DATA******/
			var  data
			var respProd
			var products=[]

			var tempSlider = null; //getCookie(process.env.REACT_APP_ORG_NAME+'SLIDERPRODUCTS'+_obj.name)

			var exists=false;

			if (tempSlider){
				var productsLength = getCookie(process.env.REACT_APP_ORG_NAME+'SLIDERPRODUCTS'+_obj.name)
				for (let k =0; k < parseInt(productsLength); k++){

					products.push ( JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'SLIDERPRODUCTS'+_obj.name+"_"+k  ))	 )
				}

			}else{
				//console.log("GETTING DATA...")
				if (_obj.config.criteria =="CATEGORY"){
					data ={
								languageid : getLanguage(),
								categoryid   : _obj.data.category,
								storeid     : getCurrentCatalog()
							}
				}else if (_obj.config.criteria =="PRODUCT"){

					data ={
								languageid : getLanguage(),
								productids : (_obj.data.products).toString(),
								storeid    : getCurrentCatalog()
							}
				} else if (_obj.config.criteria =="NEWS"){

					data ={
								languageid : getLanguage(),
								name       : _obj.config.criteria,
								storeid    : getCurrentCatalog()
							}
				} else if (_obj.config.criteria =="NEWCATEGORY"){

					data ={
								languageid : getLanguage(),
								name       : _obj.config.criteria,
								categoryid   : _obj.data.category,
								storeid    : getCurrentCatalog()
							}
				}

				respProd = await callApi ( "customer","getFilterProducts", data )

				for (let k in respProd.rows){
					if (parseInt(k)  < parseInt ( _obj.config.maxproducts == 0? respProd.rows.length: _obj.config.maxproducts)   ){
						products.push(respProd.rows[k])
					}
				}
			}
			/*****END GETTING DATA******/
			if ( _obj.componentType ==="SLIDERPRODUCTS"){

				var prodDefConfig =  {pagewidth          : "col-md-12 col-lg-12 col-xl-12",
																colsize            : "col-6",
																colsizelg          : "col-lg-3",
																colsizemd          : "col-md-4",
																colsizesm          : "col-sm-6",
														    pagewidth          : "col-md-12 col-lg-12 col-xl-12"}

				let respCat = await buildProductsSlider (products, null, _manageCart ,  prodDefConfig,_obj.name )
				var config =   {
					dots           : _obj.config.dots+"" =="false" ?false:true,
				  arrows         : _obj.config.arrows+"" =="false" ?false:true,
		    	slidesToShow   : windowWidth <= 799?2:( _obj.config.slidestoshow  ? parseInt(_obj.config.slidestoshow)  :3 ),
				  slidesToScroll : _obj.config.slidestoscroll? parseInt(_obj.config.slidestoscroll):1,
		    	autoplay       : true,
		    	cssEase        : "linear",
		    	speed          : _obj.config.speed        ? parseInt(_obj.config.speed):500,
		    	autoplaySpeed  :  _obj.config.autoplayspeed ? parseInt(_obj.config.autoplayspeed):3000,
		    	pauseOnHover: true,
		    	divWidth: "card col-md-12 col-lg-12 col-xl-12"
				}
				//console.log("config",config)
				renderObj=  {curdata:
											{	type:_obj.componentType,
												ObjectName:_obj.name,
												data: respCat.cardProducts,
												config:config
											},
										 render: <Slider
										 						ObjectName={_obj.name}
																sliderData={respCat.cardProducts}
																sliderConfig={config}
																config={_obj.config}
															/>
										}
			}else{

				var config = _obj.config

				let respCat = await buildProductsCard (products, _managepagination, _manageCart ,  config,_obj.name )
				config["container"] = _obj.name
				renderObj=  {curdata:
											{	type:_obj.componentType,
												ObjectName:_obj.name,
												data: respCat.cardProducts,
												config:config
											},
										 render: <Card
										 						ObjectName={_obj.name}
																cardData={respCat.cardProducts}
																cardConfig={respCat.cardConfig}
																config={_obj.config}
															/>
										}
				}


		}else{
			renderObj= "NO OBJ DEF"
		}

		return renderObj;

	}catch(err){
		console.log(">>>>>>>",err)
		return null
	}

}


export async function buildProductsCard( _products, _managepagination,_manageCart, _prodDefConfig, _container, _categoryRouteObj, _catId, _sugegstedSkus ){

	var resp={
		cardProducts:[],
	  		cardConfig:{}
	}
	try{
		var cardProducts=[]
		var item = 0
		var wishlist = []
		var res =await getOptions()
		var temOptions = res.rows
		//console.log("iniciamos con ",_products)	
			
		var _resp = await getTagDefinitions()
		var tags = _resp.tags 
		var CategoriresLocked=[]
		var _cats = getCookie(process.env.REACT_APP_ORG_NAME+'_CategoriresLockedDiscount')
		
		if (_cats && _catId){
			CategoriresLocked = JSON.parse(getCookie(process.env.REACT_APP_ORG_NAME+'_CategoriresLockedDiscount'))	
		}
		var isLockedCat =false
		if (CategoriresLocked && _catId){
			if (CategoriresLocked.indexOf(_catId)>=0 ){
				isLockedCat=true
			}
		}
		var isLockedClon=false;
		if (_catId==-1){ 
			isLockedClon=true
		}
		//console.log("BUILD",_prodDefConfig)
		/*
		var prods = []
		for (let k in _products){
			if 	(_products[k].productid==21863 || _products[k].productid== 18806 || _products[k].productid== -20885  ){
		 		prods.push(_products[k])
			}
		}
		_products = prods
		*/
		for (let k in _products){			
			//console.log("productid",_products[k].productid)			
			var key  = _products[k]
			
			var isValid=false
			var clonable=false
			var quantities =[]
			var path  = undefined //= key.image;
			
			if (key.packname){
				var productname =  key.productname
				if (key.quantities.length>0 ){
					let _skus =[]
					var _quantity=99999999;
					isValid= true
					let _sku = key.quantities[0]
					key.quantities.map(function (key) {
						if ( key["quantity"] <= 0 ){
							isValid= false
						}else{
							if (key.quantity < _quantity){
							 	_quantity= key.quantity
							}
							if (!path){
								path = key.imagepath?key.imagepath: key.imagespath+key.sku+"_1"+getImgagesExtension();
							}
							let pos = find (_skus,key.sku, "productsku")
						 	if (pos < 0 ){
							 	_skus.push( {productsku: key.sku, price: key.price})
							}
						}
					}); 
					 
					let imgPath = key.image
					var imagesquantity = key.producttotalimages
				  var imagepath      = null
				  var imagespath     = null
				  if (imgPath.indexOf(getImgagesExtension())>=0){
				  	imagepath = imgPath
				  	imagesquantity = 1
				  	path = imgPath
				  }else{
				  	imagespath = imgPath
				  	path = imgPath+key.packname+"_1"+getImgagesExtension();
				  }
					var fixedSku = {									
						imagesquantity    : imagesquantity,
						imagespath        : imagespath,
						imagepath         : imagepath,
						isrequiredid      : _sku.isrequiredid,
						tagid             : _sku.tagid,
						optionid          : 3,
						optionmaster      : "",
						optionvalue       : "",
						optioncustomer    : "PACK",
						newpriced         : null,	  
						newpriced2        : null,	
						newpricec1        : null,
						newpricec2        : null,	
						languageid        : _sku.languageid,
						price             : key.price,
						sku               : key.packname,
						skus              : _skus, 
						specificname      : key.productname,
						quantity          : _quantity,
					}
					quantities.push(fixedSku)
				}
			}else{
				key.quantities.map(function (key) {
					if ( key["quantity"] >0 ){
						quantities.push(key)
						isValid= true
						if (!path){
							path = key.imagepath?key.imagepath: key.imagespath+key.sku+"_1"+getImgagesExtension();
						}
					} 
				});
			}
 
			if(isValid){
				item++
	     	var tag =null
	     	if (key.age < 5){
	     		tag = "new"
	     	}
	     	if (key.newprice!=null && key.newprice!=undefined ){
	     		tag = "-"+key.newprice+"%"
	     	}
	     	if (key.newpricediscount!=null && key.newpricediscount!=undefined ){
	     		tag = key.newpricediscount+ "x"+key.newpricediscount+",99"+getCurrency()
	     	}
	      var optionsselected = null
	      var firstTime = true
	      var showVariants = false;
	      
	      var options =[]
	      if (key.packname){
	      	var fixedOption={
	      		optionid:3,
	      		optionvalues:[
	      		{ filtername: "",
	      			label:"",
	      			price: key.price,
						  sku: key.productid,
	      			value:"",
	      		}
	      		]
	      	}
	      	options.push(fixedOption) 
	      }else{
	      	key.options.map(function (key) {	      	
		      	var optionvalues=[]
						key.optionvalues.map(function (val) {
							
							let pos = find (quantities, val.value , "optionvalue")
							if (pos>=0){
								optionvalues.push(val)	
							}
	 					});
						var opt = { optionid	   : key.optionid,
		      							optionvalues : optionvalues}	      							
		      	if (optionvalues.length>0){
		      		options.push(opt)
		      	}
					});
	      }
	     	for (let o in options){
	  			if ( options[o].optionvalues.length==1 ){
	  				if (firstTime){
	  					optionsselected = []
	  					firstTime=false
	  				}
	  				optionsselected.push ( {optionid: options[o].optionid,  optionvalue: options[o].optionvalues[0].value})

	  			}else{
	  				showVariants=true;
	  			}
	  		}
	  		 
	  		var discount =null
	  		var customTag=null
	  		var priceold=0
	  		var pricenew=0
	  		var firstTime= true
	  		var allSKusD=0;
	  		var allSKusC=0;
	  		for (let o in quantities){
	  			if (firstTime){
	  				priceold = quantities[o].price
		  			if (quantities[o].newpriced2){
	  						discount =  quantities[o].newpriced2
	  						pricenew =  quantities[o].newpriced
		  			}
		  			var arrPrices =[]
						var arrPricesD =[]
						var arrPricesD2=[]		
						var arrQuantities=[]					
					  var arrPrices     = searchArray ( quantities,"optionvalue", "price",  quantities[o].optionvalue, arrPrices )
						var arrPricesD    = searchArray ( quantities,"optionvalue", "newpriced",  quantities[o].optionvalue, arrPricesD )
						var arrPricesD2   = searchArray ( quantities,"optionvalue", "newpriced2", quantities[o].optionvalue, arrPricesD2 )
						var arrQuantities = searchArray ( quantities,"optionvalue", "quantity", quantities[o].optionvalue, arrQuantities )
						priceold = quantities[o].price
						for (let q in arrQuantities){
							if( arrQuantities[q]>0){
								if( arrPrices[q] <= priceold){
									priceold = arrPrices[q]
									if( arrPricesD[q]){
										pricenew = arrPricesD[q]
									}
									if( arrPricesD2[q]){
										discount = arrPricesD2[q]
									}
								}
							}
						}
		  			firstTime=false
	  			}
	  			if (quantities[o].newpriced2){
	  				allSKusD++
	  			}
	  			if (quantities[o].newpricec1){
	  				allSKusC++
	  			}
	  			if (quantities[o].tagid ) {
	  				let pos = find ( tags, quantities[o].tagid+"", "id" )
	  				if (pos>=0){
	  					if (tags[pos].storeId =="" ){
	  						customTag = {label : tags[pos].label,
	  										   sku   : quantities[o].sku,
	  										   style : tags[pos].style };
	  					}else{
	  						if (tags[pos].storeId == getCurrentStore() ){
	  							customTag = {label : tags[pos].label,
	  										   sku   : quantities[o].sku,
	  										   style : tags[pos].style };
	  						}
	  					}
	  				}
	  			}	  		
	  		}
	  		var category    = formatPathName( key.category)
	  		var productpath = formatPathName( key.productname+"-"+key.productid )
				
				
	  		if (_categoryRouteObj){
	  			if (Array.isArray(_categoryRouteObj)){

	  				category=""
	  				for (let k in _categoryRouteObj){
	  					let _category    = formatPathName( _categoryRouteObj[k].label)
	  					category = category+"/"+_category
	  				}
	  				category = category.substr(1, category.length)
	  			}else{
	  				category = _categoryRouteObj
	  			}
	  		} 
	  		var objdata= {
				  							productid	       : key.productid,
												label	  	       : key.productname,
												path	 	 	       : path,
												width	 	 	       : 250,
												height	 	       : 250,
												currency	       : getCurrency(),
												value		         : "",
												customTag        : customTag,
												options          : options,
												quantities       : quantities,
												pricenew         : pricenew,
												priceold	       : priceold,
												allSKusD         : allSKusD,
												allSKusC         : allSKusC,
												discount 	       : discount,
												minprice         : key.minprice,
												maxprice         : key.maxprice,
												tag              : tag,
												genericsku       : quantities[0].sku ,
												category         : category,
												productpath      : productpath} 
	  		var objconfig ={ quantitySelected:1,
	  										 manageCart: _manageCart,
	  										 optionsselected : optionsselected,
	  										 favorite  : wishlist.indexOf (parseInt(key.productid) ) < 0?false:true ,
	  										 showVariants : showVariants,
	  										 fatherConfig : _prodDefConfig,
	  									 }
	  		
	  		if (!showVariants){
					objdata["productsku"] =  quantities[0].sku
					objdata["hideoptions"] = true
					objdata["maxQuantity"] = quantities[0].quantity
				}
				
				if (quantities.length ==1){
				//	
					objconfig["optionsselected"] = [{
							optionid    : quantities[0].optionid,
							optionvalue : quantities[0].optionvalue
					}]
					objdata["productsku"] =  quantities[0].sku
					objdata["hideoptions"] = true
					objdata["maxQuantity"] = quantities[0].quantity
			
					cardProducts.push( {body         : <Product
											  												name={_container}
																								productData={objdata}
																								productConfig={objconfig}
																							/>  ,
															 productid: key.productid,
															 productData:objdata,
														} )
									
				}else{
					var colors=[]
					
					if(!isLockedClon){
						for ( let o in quantities){
							let posF = find(temOptions,  quantities[o].optionid, "optionid" )	
							if ( (temOptions[posF].optionname).toUpperCase() =="COLOR"  ){
								let pos = find (colors,quantities[o].optionvalue, "optionvalue" )
							
								if (pos<0 && quantities[o].ordersku>0){
									colors.push ({ optionvalue : quantities[o].optionvalue, sku: quantities[o].sku, ordersku : quantities[o].ordersku, option: quantities[o].optioncustomer })	
								}
							}
						}
					}else{
						if (_sugegstedSkus){
							for ( let o in quantities){
								let posF = find(temOptions,  quantities[o].optionid, "optionid" )	
								if ( (temOptions[posF].optionname).toUpperCase() =="COLOR"  ){
									let pos = find (colors,quantities[o].optionvalue, "optionvalue" )
									let pos2 = find (_sugegstedSkus,quantities[o].sku, "preferredsku" )
									if (pos<0 && pos2>=0 && quantities[o].ordersku>0){
										colors.push ({ optionvalue : quantities[o].optionvalue, sku: quantities[o].sku, ordersku : quantities[o].ordersku, option: quantities[o].optioncustomer })	
									}
								}
							}
						}
					}
					if (colors.length>0){

						for ( let c=0; c < colors.length; c++ ){
							let newPath = path.replace(colors[0].sku , colors[c].sku )
							
							objdata.quantities.map(function (opt) {
								if ( opt.sku == colors[c].sku ){
									newPath= opt.imagespath+ opt.sku +"_1"+getImgagesExtension();
								}
							});
			 
					 		var arrPrices =[]
							var arrPricesD =[]
							var arrPricesD2=[]
							var arrQuantities=[]
							 
						  var arrPrices     = searchArray ( quantities,"optionvalue", "price",  colors[c].optionvalue, arrPrices )
							var arrPricesD    = searchArray ( quantities,"optionvalue", "newpriced",  colors[c].optionvalue, arrPricesD )
							var arrPricesD2   = searchArray ( quantities,"optionvalue", "newpriced2", colors[c].optionvalue, arrPricesD2 )
					 	  var arrQuantities = searchArray ( quantities,"optionvalue", "quantity", colors[c].optionvalue, arrQuantities )
					 	  
					 		let _priceold = arrPrices[0]
					 		let _discount = arrPricesD2[0]
							let _pricenew = arrPricesD[0]
							
							for (let q in arrQuantities){
								if( arrQuantities[q]>0){
									if( arrPrices[q] <= _priceold){
										_priceold = arrPrices[q]
										if( arrPricesD[q]){
											_pricenew = arrPricesD[q]
										}
										if( arrPricesD2[q]){
											_discount = arrPricesD2[q]
										}
									}
								}
							}    
							let objdataClon  = {
		  							productid	       : key.productid+"_C_"+colors[c].sku,
										label	  	       : key.productname,
										path	 	 	       : newPath,
										width	 	 	       : 250,
										height	 	       : 250,
										currency	       : getCurrency(),
										value		         : "",
										customTag        : customTag,
										options          : options,
										quantities       : quantities,
										pricenew         : _pricenew,
										priceold	       : _priceold,
										allSKusD         : allSKusD,
										allSKusC         : allSKusC,
										discount 	       : _discount,
										minprice         : key.minprice,
										maxprice         : key.maxprice,
										tag              : tag,
										genericsku       : colors[c].sku ,
										category         : category,
										skuCloned        : colors[c].optionvalue,
										productpath      : productpath} 
										
							let objconfigClon = { 
										quantitySelected:1,
										manageCart: _manageCart,
										optionsselected : optionsselected,
										favorite  : wishlist.indexOf (parseInt(key.productid+""+colors[c].sku) ) < 0?false:true ,
										showVariants : showVariants,
										fatherConfig : _prodDefConfig,
										}  
							 
							if (objdataClon.options.length==1){
								objconfigClon["optionsselected"] = [{
									optionid    : objdataClon.options[0].optionid,
									optionvalue : colors[c].optionvalue
								}]
								var arrQtys=[]
								var arrQtys = searchArray ( quantities,"optionvalue", "quantity", colors[c].optionvalue, arrQtys )
								objdataClon["maxQuantity"] = arrQtys[0]
							}else{
								var optionsselected=[]
								objdataClon.options.map(function (optv) {
									if ( optv.optionvalues.length ==1 ){
										optionsselected.push( {
											optionid    : optv.optionid,
											optionvalue :optv.optionvalues[0].value
										})
									}else{
										let pos = find (optv.optionvalues,colors[c].optionvalue, "value" )
										if (pos>=0){
											optionsselected.push( {
												optionid    : optv.optionid,
												optionvalue : colors[c].optionvalue
											})
										}
									}
								});
								if (optionsselected.length>0){
									objconfigClon["optionsselected"] = optionsselected
								}
							}
							var add=true
							if (isLockedCat){
								add=false
								var arrCombos=[]
								var arrCombos = searchArray ( quantities,"optionvalue", "newpricec1", colors[c].optionvalue, arrCombos )
								if (arrCombos[0] || _pricenew > 0){
									add=true
									objdataClon["isLockedCat"] =true
								}
							}
							if(add){
								cardProducts.push(
				  								{body         : <Product
									  												name={_container}
																						productData={objdataClon}
																						productConfig={objconfigClon} 
																						productData={objdataClon}
																				/>  ,
												  productid: key.productid+"_C_"+colors[c].sku,
												  color: colors[c].option,
												  productData:objdataClon,
												  isclonable: true,
											}) 
							}		
						}
					}else{
						
						if (colors.length==1){
							console.log("revisar el color", colors, objdata)
						}
						
						cardProducts.push( {body         : <Product
											  												name={_container}
																								productData={objdata}
																								productConfig={objconfig}
																							/>  ,
															 productid: key.productid,
															 productData: objdata
														} )
					}
				} 
	  		/*var style={}
	  		style["margin-bottom"] = "0.9rem"*/
			}
  	}
  	
  	if (_prodDefConfig.optionsFilter){
  		var colorsFilter= []
			//console.log("tiene filtro", _prodDefConfig.optionsFilter)
			let _optionsselected = optionsselected
			var content =  _prodDefConfig.optionsFilter;
			content= _prodDefConfig.optionsFilter.sort(function (a, b){
				return ( convertNumber( a.optionid) - convertNumber( b.optionid))
			}) 
			content.map(function (key2) {
				//console.log("checkin filter", key2)
				if ( key2.optionname.toUpperCase() =="COLOR"){
					for (let o in key2.selectedoptions){ 
							colorsFilter.push (  key2.selectedoptions[o]	)
							//console.log("key2.selectedoptions[o]",key2.selectedoptions[o])
					}
				}
			});
			if (colorsFilter.length>0){
				//console.log("colorsFilter", colorsFilter)		
				//console.log("cardProducts ORIGINAL", cardProducts)		
				var newCardProducts =[]
				cardProducts.map(function (prod, index) {
					
					if (prod.isclonable){
						//console.log("es clon podriamos borrar", prod.color, index)
						if ( colorsFilter.indexOf( prod.color) >=0){
							//console.log("existe ponemos", index)
							//cardProducts.splice(index, 1)
							newCardProducts.push(prod)
						}
					}else{
						//console.log("no es clon debemos actualizar", index)
						//console.log(prod.productData)
						if ( prod.productData.quantities.length >1){
							
							//console.log("tiene variantes",prod.productData.quantities)
							var productData= prod.productData
							for (let c in colorsFilter){
								let pos = find(productData.quantities, colorsFilter[c], "optioncustomer"	)
								if (pos>=0){
									//console.log("este es el color", productData.quantities[pos].optioncustomer)
									let newPath  = productData.quantities[pos].imagepath
									if (newPath==null){
										newPath = productData.quantities[pos].imagespath+productData.quantities[pos].sku+"_1"+getImgagesExtension()
									} 
									productData["path"] = newPath
									//console.log("newPath", newPath ) 
								}
							}
							newCardProducts.push(prod)
						}
					}
				});
				cardProducts = newCardProducts;
			}
		}
		
  	//console.log("cardProducts",cardProducts)
		var itemsperpage = 15
		var pages = Math.ceil( cardProducts.length / itemsperpage)
		var paginationGroup=[]
		for (let i = 1; i<=pages;i++ ){
			paginationGroup.push(i)
		}
	  var cardConfig = _prodDefConfig

		cardConfig["managepagination"]	= _managepagination
		cardConfig["pagesettings"]	= {
																		itemsperpage       : itemsperpage,
																		paginationGroup    : paginationGroup,
																		currentPage        : 1,
																		pages              : pages,
																		cardProducts       : cardProducts.length
							 										}
  	resp ={
  		cardProducts,
  		cardConfig
  	}
	}catch(Excep){
		console.log(">>>>>>>>",Excep)

	}finally{
		return resp
	}
}



export async function buildProductsSlider( _products, _managepagination,_manageCart, _prodDefConfig,_container, _categoryRouteObj ){
 
	var resp={
		cardProducts:[],
	  		cardConfig:{}
	}
	try{
		var cardProducts=[]
		var item = 0
		var wishlist =  []
	  var _resp = await getTagDefinitions()
	  var tags = _resp.tags
		 
		for (let k in _products){
			var key  = _products[k]
			
			var isValid=false
			var clonable=false
			var quantities =[]
			var path  = undefined
			
			if (key.packname){
				var productname =  key.productname
				if (key.quantities.length>0 ){
					let _skus =[]
					var _quantity=99999999;
					isValid= true
					let _sku = key.quantities[0]
					key.quantities.map(function (key) {
						if ( key["quantity"] <= 0 ){
							isValid= false
						}else{
							if (key.quantity < _quantity){
							 	_quantity= key.quantity
							}
							if (!path){
								path = key.imagepath?key.imagepath: key.imagespath+key.sku+"_1"+getImgagesExtension();
							}
							let pos = find (_skus,key.sku, "productsku")
						 	if (pos < 0 ){
							 	_skus.push( {productsku: key.sku, price: key.price})
							}
						}
					}); 
					 
					let imgPath = key.image
					var imagesquantity = key.producttotalimages
				  var imagepath      = null
				  var imagespath     = null
				  if (imgPath.indexOf(getImgagesExtension())>=0){
				  	imagepath = imgPath
				  	imagesquantity = 1
				  	path = imgPath
				  }else{
				  	imagespath = imgPath
				  	path = imgPath+key.packname+"_1"+getImgagesExtension();
				  }
					var fixedSku = {									
						imagesquantity    : imagesquantity,
						imagespath        : imagespath,
						imagepath         : imagepath,
						isrequiredid      : _sku.isrequiredid,
						tagid             : _sku.tagid,
						optionid          : 3,
						optionmaster      : "",
						optionvalue       : "",
						optioncustomer    : "PACK",
						newpriced         : null,	  
						newpriced2        : null,	
						newpricec1        : null,
						newpricec2        : null,	
						languageid        : _sku.languageid,
						price             : key.price,
						sku               : key.packname,
						skus              : _skus, 
						specificname      : key.productname,
						quantity          : _quantity,
					}
					quantities.push(fixedSku)
				}
			}else{
				key.quantities.map(function (key) {
					if ( key["quantity"] >0 ){
						quantities.push(key)
						isValid= true
						if (!path){
							path = key.imagepath?key.imagepath: key.imagespath+key.sku+"_1"+getImgagesExtension();
						}
					} 
				});
			}
			
			if(isValid){
				item++
	     	var tag =null
	     	if (key.age < 5){
	     		tag = "new"
	     	}
	     	if (key.newprice!=null && key.newprice!=undefined ){
	     		tag = "-"+key.newprice+"%"
	     	}
	     	if (key.newpricediscount!=null && key.newpricediscount!=undefined ){
	     		tag = key.newpricediscount+ "x"+key.newpricediscount+",99"+getCurrency()
	     	}
	      var optionsselected = null
	      var firstTime = true
	      var showVariants = false;

	     	var options =[]
	      if (key.packname){
	      	var fixedOption={
	      		optionid:3,
	      		optionvalues:[
	      		{ filtername: "",
	      			label:"",
	      			price: key.price,
						  sku: key.productid,
	      			value:"",
	      		}
	      		]
	      	}
	      	options.push(fixedOption) 
	      }else{
	      	key.options.map(function (key) {	      	
		      	var optionvalues=[]
						key.optionvalues.map(function (val) {
							
							let pos = find (quantities, val.value , "optionvalue")
							if (pos>=0){
								optionvalues.push(val)	
							}
	 					});
						var opt = { optionid	   : key.optionid,
		      							optionvalues : optionvalues}	      							
		      	if (optionvalues.length>0){
		      		options.push(opt)
		      	}
					});
	      }
	     	for (let o in options){
	  			if ( options[o].optionvalues.length==1 ){
	  				if (firstTime){
	  					optionsselected = []
	  					firstTime=false
	  				}
	  				optionsselected.push ( {optionid: options[o].optionid,  optionvalue: options[o].optionvalues[0].value})

	  			}else{
	  				showVariants=true;
	  			}
	  		}
	  		
	  		var discount =null
	  		var customTag=null
	  		var priceold=0
	  		var pricenew=0
	  		var firstTime= true
	  		var allSKusD=0;
	  		var allSKusC=0;
	  		for (let o in quantities){

	  			if (firstTime){
	  				priceold = quantities[o].price
		  			if (quantities[o].newpriced2){
	  						discount =  quantities[o].newpriced2
	  						pricenew =  quantities[o].newpriced
		  			}
		  			firstTime=false
	  			}
	  			if (quantities[o].newpriced2){
	  				allSKusD++
	  			}
	  				if (quantities[o].newpricec1){
	  				allSKusC++
	  			}

	  			if (quantities[o].tagid ) {
	  				let pos = find ( tags, quantities[o].tagid, "id" )
	  				if (pos>=0){

	  					customTag = {label : tags[pos].label,
	  										   sku   : quantities[o].sku,
	  										   style : tags[pos].style };
	  				}
	  			}

	  		}

	  		var category    = formatPathName( key.category) 
	  		var productpath = formatPathName( key.productname+"-"+key.productid )


	  		if (_categoryRouteObj){
	  			if (Array.isArray(_categoryRouteObj)){

	  				category=""
	  				for (let k in _categoryRouteObj){
	  					let _category    = formatPathName( _categoryRouteObj[k].label)
	  					category = category+"/"+_category
	  				}
	  				category = category.substr(1, category.length)
	  			}else{
	  				category = _categoryRouteObj
	  			}
	  		}

	  		var objdata= {
				  							productid	 : key.productid,
												label	  	 : key.productname,
												//path	 	 	 : key.image,
												path	 	 	 : path,
												width	 	 	 : 250,
												height	 	 : 250,
												pricenew   : pricenew,
												priceold	 : priceold,
												discount   : discount,
												allSKusD   : allSKusD,
												allSKusC   : allSKusC,
												currency	 : getCurrency(),
												value		   : "",
												isnew		 	 : false,
												customTag  : customTag,
												options    : key.options,
												quantities : quantities,
												minprice   : key.minprice,
												maxprice   : key.maxprice,
												tag        : tag,
												genericsku : key.genericsku,
												category   : category,
												productpath: productpath
	  								}

	  		var objconfig ={ quantitySelected:1,
	  										 manageCart: _manageCart,
	  										 optionsselected : optionsselected,
	  										 favorite  : wishlist.indexOf (parseInt(key.productid) ) < 0?false:true ,
	  										 showVariants : showVariants,
	  										 fatherConfig : _prodDefConfig,
	  									 }

				if (quantities.length ==1){
					objconfig["optionsselected"] = [{
							optionid    : quantities[0].optionid,
							optionvalue : quantities[0].optionvalue
					}]
					objdata["productsku"] =  quantities[0].sku
					objdata["hideoptions"] = true
					objdata["maxQuantity"] = quantities[0].quantity
				}

			 	if (!showVariants){
					objdata["productsku"] =  quantities[0].sku
					objdata["hideoptions"] = true
					objdata["maxQuantity"] = quantities[0].quantity
				}

	  		var style={}
	  		style["margin-bottom"] = "0.9rem"
	  		cardProducts.push(
		  								{body         : <Product
							  												name={_container}
																				productData={objdata}
																				productConfig={objconfig}
																			/>  ,
											 productid: key.productid
										} )
									
			}
  	}
		var itemsperpage = 5
		var pages = Math.ceil( cardProducts.length / itemsperpage)
		var paginationGroup=[]
		for (let i = 1; i<=pages;i++ ){
			paginationGroup.push(i)
		}
	  var cardConfig = _prodDefConfig

		cardConfig["managepagination"]	= _managepagination
		cardConfig["pagesettings"]	= {
																		itemsperpage       : itemsperpage,
																		paginationGroup    : paginationGroup,
																		currentPage        : 1,
																		pages              : pages,
																		cardProducts       : cardProducts.length
							 										}
  	resp ={
  		cardProducts,
  		cardConfig
  	}
	}catch(Excep){
		console.log(">>>>>>>>",Excep)

	}finally{
		return resp
	}
}
