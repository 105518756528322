import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";
import {getImagePath } from '../../common/functions';
const windowHeight = window.innerHeight

const loadSliderComp = props => {
	 
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" >
					{props.data.length>0?
		  			<div className="row">
							<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}><span className="h6-responsive" >Image</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Title</span></div> 
							<div className={"col-sm-4 col-sm-4 col-lg-2 col-xl-2"}><span className="h6-responsive" >Image Alt</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}><span className="h6-responsive" >Url Link</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}><span className="h6-responsive" >Target</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}><span className="h6-responsive" >Mobile / Web</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}><span className="h6-responsive" >Height</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}><span className="h6-responsive" >Width</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}><span className="h6-responsive" >Height Mobile</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}><span className="h6-responsive" >Width Mobile</span></div>
							
							<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1"}> </div>
						</div>	
					:""}
				  {props.data.map(itemM => (
				  	<>
							<div className="row mb-1">
								<div className={"col-sm-4 col-sm-4 col-lg-1 col-xl-1"}>
					 				<img 
											key={"img"+itemM.rowId} 
											name={"manageImage_"+itemM.rowId} 
											onClick={props.manageImage} 
											style={{"cursor": "pointer"}}
											width="75px" height="75px" 
											src={  itemM.image===""?defaultImage:getImagePath(itemM.image)} />
						  	</div>		
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_title_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_title?"is-invalid":"")}
										value={itemM.title}
										name={"FIELDVALUE_"+props.container+"_title_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div> 
						  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
					 				<input key={props.container+"_imagealt_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_imagealt?"is-invalid":"")}
										value={itemM.imagealt}
										name={"FIELDVALUE_"+props.container+"_imagealt_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>		
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_link_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_link?"is-invalid":"")}
										value={itemM.link}
										name={"FIELDVALUE_"+props.container+"_link_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>
						  	
						  	<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}>
						 				<select  name={"FIELDVALUE_"+props.container	+"_targetlink_"+itemM.rowId} defaultValue={itemM.targetlink?itemM.targetlink:""} onChange={props.manageComponent} className={"form-control form-control-sm" }>
		        			 		 <option value="">self</option>
		        			 		 <option value="_blank">blank</option>
		        			 		 <option value="_parent">parent</option>
		        			 		 <option value="_top">top</option>
										</select>							 	
						  	</div>	
						  	
						  	<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-1"}>
						 				<select  name={"FIELDVALUE_"+props.container	+"_version_"+itemM.rowId} defaultValue={itemM.version?itemM.version:"web"} onChange={props.manageComponent} className={"form-control form-control-sm" }>
		        			 		 <option value="web">web</option>
		        			 		 <option value="mobile">mobile</option>
		        			 		 <option value="both">both</option>
										</select>							 	
						  	</div>		 
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_height_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_height?"is-invalid":"")}
										value={itemM.height}
										name={"FIELDVALUE_"+props.container+"_height_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_width_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_width?"is-invalid":"")}
										value={itemM.width}
										name={"FIELDVALUE_"+props.container+"_width_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>
						  	
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_mheight_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_mheight?"is-invalid":"")}
										value={itemM.mheight}
										name={"FIELDVALUE_"+props.container+"_mheight_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_mwidth_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_mwidth?"is-invalid":"")}
										value={itemM.mwidth}
										name={"FIELDVALUE_"+props.container+"_mwidth_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>
						  	 
						  	<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompSliderImg_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}></div>
								
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompSliderImg"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO loadSliderComp DATA"
	}
};
export default loadSliderComp;
				 