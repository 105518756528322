/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../componentsFront/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
/*functions*/
import { getLanguages, findObjName, findValueById, listImages, loadPageV2, find,validateComponent, getLanguage, validateSyncForm, changeFilter, filterHandler,validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 
const foldername= process.env.REACT_APP_FOLDER_LAYOUT
var windowWidth  = window.innerWidth
class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			date_label : date_label,
			modal1: false,
			modal2: false,
			lockform:true,
			modalMdClass:"modal-lg",
			modalIamgeClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			eventInfo:{},
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			filterConfig: {
				EVENTTYPE     : { label:"Event Type"        ,  type:"SELECT" , value:"" , values:[] },
			  EVENTNAME     : { label:"Event Name"        ,  type:"TEXT" , value:""  },
			  CUSTOMERNAME  : { label:"Customer Name"        ,  type:"TEXT" , value:""  },
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"EVENTID", title:"Event ID",
						sorter: (a, b) => a.EVENTID.length - b.EVENTID.length },
					{ dataIndex:"STORENAME", title:"Store Name",
						sorter: (a, b) => a.STORENAME.length - b.STORENAME.length },
					{ dataIndex:"EVENTTYPE", title:"Event Type",
						sorter: (a, b) => a.EVENTTYPE.length - b.EVENTTYPE.length },
					{ dataIndex:"EVENTNAME", title:"Event Name",
						sorter: (a, b) => a.EVENTNAME.length - b.EVENTNAME.length },
						
					{ dataIndex:"EVENTDATE", title:"Event Date",
						sorter: (a, b) => a.EVENTDATE.length - b.EVENTDATE.length },
				 
							
					{ dataIndex:"CUSTOMERNAME", title:"Customer Name",
						sorter: (a, b) => a.CUSTOMERNAME.length - b.CUSTOMERNAME.length },
					  
					{	dataIndex:"EMAIL", title:"Email",
						sorter: (a, b) => a.EMAIL.length - b.EMAIL.length },		
						
					{	dataIndex:"PHONE", title:"Phone",
						sorter: (a, b) => a.PHONE.length - b.PHONE.length },
						
					{	dataIndex:"STATUS", title:"Status",
						sorter: (a, b) => a.STATUS.length - b.STATUS.length },
						
					{ dataIndex: "EVENTID", title: "Action",
		        render: (text, record) => (<div className="text-right">
		         <button className="btn btn-sm btn-outline-primary" id={record.EVENTID} name="view"
		         onClick={this.loadFormData}
		          type="submit">View</button>
		           
		         <button className={"btn btn-sm btn-"+ (record.STATUSID=="5"? "danger": "success")}  id={record.EVENTID} name="updatestatus"
		         onClick={this.loadFormData}
		          type="submit"> {record.STATUSID=="5"? "DISABLE": "ENABLE"}  </button>
		          
		           <button className={"btn btn-sm btn-info"}  id={record.EVENTID} name="manage"
		         onClick={this.loadFormData}
		          type="submit"> Manage  </button></div>
		          
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Attribute",
			/*form data*/
			totalRows:[],
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				
				EVENT           : { label: "Event Name"       ,"sizelg":"col-lg-6", type: "TEXT",   value: "" } ,
				CODE            : { label: "Event Code"       ,"sizelg":"col-lg-6", type: "TEXT",   value: "" } ,
				LANGUAGE        : { label: "Language"     ,"sizelg":"col-lg-6"  , type: "SELECT", values:[], value: "" , getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"2"}] } } ,
				SORTORDER       : { label: "SORTORDER"  ,"sizelg":"col-lg-6"  , type: "TEXT", validationType:"number",   value: "" } ,
				IMAGEPATH       : { label: "Ref Image"         , validationType:"text",   type: "IMAGE",  value: ""  },
				IMAGEBUTTON     : { label: "Button Image"           , validationType:"text",   type: "IMAGE",  value: ""  },
				
				
			},
			 
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			updateStatusMessage:"",
			refreshMessage:"Are you sure?",
			initialFolder:"",
			imageManager:null,
			objImage:{
				items:[],
				selected:[]
			}
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getCustomerEvents" )
	 		const tableData = this.state.tableData
		  var rows=[]
			resp.rows.map(function (item) {
				
				let eventinfo    = JSON.parse(item.EVENTINFO)
				let customerinfo = JSON.parse(item.CUSTOMERINFO)
				
				let row={
					EVENTID     : item.EVENTID,
					STOREID     : item.STOREID,
					EVENTTYPE   : item.EVENTTYPE,
					EVENTNAME   : eventinfo.eventname,
					EVENTMESSAGE: eventinfo.message,
					CUSTOMERNAME: item.CUSTOMERNAME, 
					EMAIL       : item.EMAIL, 
					PHONE       : item.PHONE,
					STATUS      : item.STATUS,
					EVENTDATE   : item.EVENTDATE,
					EVENTENDDATE: item.EVENTENDDATE,
					STORENAME   : item.STORENAME,
					KEY         : item.KEY,
					STATUSID    : item.STATUSID,
					EVENTINFO   : JSON.parse(item.EVENTINFO),
					CUSTOMERINFO: JSON.parse(item.CUSTOMERINFO),
					IMAGEPATH   : item.IMAGEPATH,
					
				} 
				rows.push(row)
			}); 

	 		tableData.rows = rows
	 		this.setState({originalTableData:this.state.tableData });
	 	 	
	 		await preloadForm ( this.state.formData )	 		
	 		const filterConfig = this.state.filterConfig
	 		 
		 
			rows.map(function (item) {
				if ( filterConfig.EVENTTYPE.values.indexOf(item.EVENTTYPE )<0){
					filterConfig.EVENTTYPE.values.push (item.EVENTTYPE)	
				}
			}); 
			
		}catch(err){
			console.log(">>>>>>>>>>",err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	 //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	 
  /*get value fields*/
  changeHandler = async(event) => {
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp 
		let isAsync=false
  	if (  this.state.formData[event.target.name]){
  		if ( this.state.formData[event.target.name].script ){
  			isAsync=true
  		}  		
  	}
		if (isAsync){
	  		resp = await validateForm(this.state.formData, toast,event);
	  	}else{
	  		resp = validateSyncForm(this.state.formData, toast,event);	
	  	}
		this.setState({formData:resp.formData});
		 
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	updatestatus=async (event) => {
  	this.execute(event, "UPDATESTATUS")
	}
	//delete
	disable=async (event) => {
  	this.execute(event, "DISABLE")
	}
	
	//delete
	enable=async (event) => {
  	this.execute(event, "ENABLE")
	}
 
	 
	/*charge data from a selected Attribute*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
 			var updateStatusMessage =""
 			
	  	if (event.target.name==="updatestatus"){
	  				
				modalIcon="fas fa-edit"
				modalTitle="Update Status"
				formAction="updatestatus"
				itemId = event.target.id
				//modalClass =  "modal-full-height modal-lg"
				let pos = find ( this.state.originalTableData.rows,itemId, "EVENTID" )
				if(this.state.originalTableData.rows[pos].STATUS=="DISABLED"){
					formAction="enable"
					
					updateStatusMessage =<> Are you sure to <span className="text-success text-underline"> enable</span> this item?</>
					
					buttonsModal1 = [
						{	buttonAction: this.enable,
							buttonId    : 1,
							buttonClass : "btn btn-success",
							buttonName  : "Enable"}
					]
				
				}else{					
					formAction="disable" 
					updateStatusMessage =<> Are you sure to <span className="text-danger text-underline">disable</span> this item?</>
					buttonsModal1 = [
						{	buttonAction: this.disable ,
							buttonId    : 1,
							buttonClass : "btn btn-danger",
							buttonName  : "Disable"}
					]		
				}				
				

	  	}if (event.target.name==="manage"){
	  		
	  		this.setState({isLoading:true})
				modalIcon="fas fa-edit"
				modalTitle="Manage Event"
				formAction="manage"
				itemId = event.target.id
				
				let pos = find ( this.state.originalTableData.rows,itemId, "EVENTID" )
				let data = this.state.originalTableData.rows[pos]
				console.log(itemId, data)
				let formData ={
 						 
						eventid      : itemId,
						storeid      : data.STOREID,
						eventdate    : data.EVENTDATE,
						eventmessage : data.EVENTMESSAGE, 
						eventname    : data.EVENTNAME,
						eventimage   : data.STOREID
 					}
				let resp = await callApi ( "manager","getTokenManageEvent", formData )
				if (resp.success ){
					updateStatusMessage =<> Enter to this <a onClick={ this.toggleModal (1) } href={process.env.REACT_APP_URL_FRONT_CONFIG+"/customer/event/manage/"+ resp.token} target="top" className="text-info text-underline">link</a> to manage event</> 
				}else{
					updateStatusMessage=<span className="text-danger">Error getting link, try again</span> 
				}
				buttonsModal1=[]
				 
	  	}else if (event.target.name==="view"){
	  		 
				modalIcon="fas fa-eye"
				modalTitle="Customer Event Details"
				itemId = event.target.id
				formAction="view"
	  		modalClass =  "modal-full-height modal-lg"
	  		
	  		let pos = find ( this.state.originalTableData.rows,itemId, "EVENTID" )	  		
	  		let data = this.state.originalTableData.rows[pos]
	  		console.log("data",data)
	  		let eventInfo= data;
 				let info = data.EVENTINFO
 				eventInfo["CUSTOMERINFO"] = data.CUSTOMERINFO
 				Object.assign(eventInfo, info);
 				
	  		let formData ={
 						 
						eventid      : itemId,
						storeid      : data.STOREID,
						eventdate    : data.EVENTDATE,
						eventmessage : data.EVENTMESSAGE, 
						eventname    : data.EVENTNAME,
						eventimage   : data.STOREID
 					}
 					
	  		let resp = await callApi ( "manager","getTokenManageEvent", formData )
				var linkUrl="#"
				if (resp.success ){
					 linkUrl= process.env.REACT_APP_URL_FRONT_CONFIG+"/customer/event/manage/"+ resp.token
				}
				
				buttonsModal1 = [	{	buttonAction: this.toggleModal (1),
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit",
						buttonLink  : linkUrl}
						]			
	  		 
	  		var finalFoldername=foldername
				var layoutFile = "createEventForm_"+getLanguage()
		 		resp = await callApi ( "layaoutManager","getLayoutTemplateByName", { layoutFile: layoutFile, foldername: foldername+"private/" } )
				
				for (let key in resp.template ){					
					resp.template[key]["config"]["formclonable"]= 'NO'
					resp.template[key]["config"]["formaction"]= ''
					resp.template[key]["config"]["formactionlabel"]= ''
				}		 		
		 		let _pageData = await loadPageV2	(resp.template, null, null, null, null, null, windowWidth );
		 		this.setState({ ...this.state,   	eventInfo:eventInfo })	
	  		this.renderData(_pageData);
	  		
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			console.log(formAction,updateStatusMessage)
			this.setState({...this.state,modal1:true,isLoading:false,  updateStatusMessage: updateStatusMessage, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
  renderData = (_pageData) => {
		try{ 
			var personalData = this.state.eventInfo.CUSTOMERINFO
			var fieldsPD = Object.keys(personalData[0])
			for (let r  in _pageData){
				let fieldsF = Object.keys(_pageData[r].data)
				if (fieldsF.toString() == fieldsPD.toString()){
					 
					if (personalData.length >0){		
						var arrData = []
						var item=1
						for (let d in personalData){
							let formName = (_pageData[r].config.formsubtitle?_pageData[r].config.formsubtitle:"ITEM")+item
							let newDataNames =[]
							let curFields = _pageData[r].data
							let newData=[]

							for (let i in Object.keys(curFields) ){
								let field = Object.keys(curFields)[i]								
								newDataNames.push(field)								
								let newValues=[]
								let initVal;
								if( curFields[field].values){
									for (let i in curFields[field].values){
										if (i==0){
											initVal = curFields[field].values[i].value
										}
										let row = {
											text  : "1",
											value : "value"
										}
										newValues.push(row)
									}
								}
								let objField={}									
								if (curFields[field].values){
									objField["values"] = newValues
								}								
								for (let f in Object.keys(curFields[field] ) ){
									let fieldName =Object.keys(curFields[field])[f]
									if (fieldName=="value"){										
										if (Array.isArray(curFields[field].values)){
											if (curFields[field].values.length>0){
												objField["value"] = 1
											}else{
												objField["value"] = ""	
											}
										}else{
											objField["value"] = ""	
										}
									}else{
										objField[fieldName] = curFields[field][fieldName]	
									}
								}
								newData.push (objField)
							}							
							let finalData ={}
						  for (let i in newDataNames){
								finalData[ newDataNames[i] ] = newData[i]
							}
							let loadData = mergeFormData (finalData, personalData[d] )
							arrData.push(
								{
									formName     : formName,
									formLabel    : formName ,
									formConfig   : _pageData[r].config,
									formData     : finalData,
									formOptionID : (item-1)
								}
							)
							item++
						}
						_pageData[r]["originaldata"] = _pageData[r].data
						_pageData[r]["data"] = arrData
						_pageData[r]["activeForm"] = 0 
						
					}else{
						let loadData = mergeFormData (_pageData[r].data, personalData[0] )
					}
				}else{
					let loadData = mergeFormData (_pageData[r].data, this.state.eventInfo )	
				}								
				if (_pageData[r].data["directions"]){
					let finalValue = _pageData[r].data["directions"].value
					let finalLabel = ""
					Object.keys(finalValue).map(function (field) {
						finalLabel = finalLabel+ finalValue[field]+", "				
					});	
					let values = [ {text: finalLabel, value: finalValue } ]
					_pageData[r].data["directions"].values= values
				}				
				this.setState({ ["RENDEROBJ"+_pageData[r].ObjectName] : _pageData[r] });
			}
			//console.log("-final-", this.state)
		}catch(err){
			console.log(">>>>>>>>>>>", err)	
		}
  }
  changeForm = async (event) => {
  	this.setState({isLoading:true});
		let object = findObjName(event)
  	let fieldName = object.split("__")
  	let newTemplate = this.state["RENDEROBJ"+fieldName[0]]
  	newTemplate["activeForm"] = event.target.value
	  this.setState({["RENDEROBJ"+fieldName[0]]: newTemplate});			
	  setTimeout(function() {	
			this.setState({["RENDEROBJ"+fieldName[0]]: newTemplate});					  
		  this.setState({isLoading:false});
		}.bind(this),250);
	  
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if(respValid){
			  	this.setState({isLoading:true});
			  	let fixedFormData ={ 						 
						eventid   : this.state.currentId
					}
					console.log(fixedFormData)
			  	let resp = await callApi ( "manager", this.state.formAction+"CustomerEvents", fixedFormData )
			  	if (resp.success){
			  	 	
			  	 	
			  	 	
			  	  resp = await callApi ( "manager","getCustomerEvents" )
				 		const tableData = this.state.tableData
					  var rows=[]
						resp.rows.map(function (item) {
							
							let eventinfo    = JSON.parse(item.EVENTINFO)
							let customerinfo = JSON.parse(item.CUSTOMERINFO)
							
							let row={
								EVENTID     : item.EVENTID,
								STOREID     : item.STOREID,
								EVENTTYPE   : item.EVENTTYPE,
								EVENTNAME   : eventinfo.eventname,
								EVENTMESSAGE: eventinfo.message,
								CUSTOMERNAME: item.CUSTOMERNAME, 
								EMAIL       : item.EMAIL, 
								PHONE       : item.PHONE,
								STATUS      : item.STATUS,
								EVENTDATE   : item.EVENTDATE,
								EVENTENDDATE: item.EVENTENDDATE,
								STORENAME   : item.STORENAME,
								KEY         : item.KEY,
								STATUSID    : item.STATUSID,
								EVENTINFO   : JSON.parse(item.EVENTINFO),
								CUSTOMERINFO: JSON.parse(item.CUSTOMERINFO),
								IMAGEPATH   : item.IMAGEPATH,
								
							} 
							rows.push(row)
						});  
			  		  
			 			tableData.rows = rows
			 			
			 			this.setState({originalTableData:this.state.tableData});
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	} 
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="manage"?
													<h3>{this.state.updateStatusMessage}</h3>
													:this.state.formAction=="disable" || this.state.formAction=="enable"?
														<h3>{this.state.updateStatusMessage}</h3>:
															<div className="card "> 
													 		{ Object.keys(this.state).map(obj=> (
													 			<>
																{ obj.startsWith("RENDEROBJ") && !this.state.isLoading  ?
																	<> 
																		{this.state[obj].type == "FORM"?
																			<Form 
									  						 					name={this.state[obj].ObjectName}
												  								container={this.state[obj].ObjectName}
																					formData={this.state[obj].data}
																					activeForm={this.state[obj].activeForm}
																					changeForm={this.changeForm}
																					changeHandler={this.changeHandler} 
																					manageButton={this.manageButton} 
																					formConfig={this.state[obj].config}
																					lockform={this.state.lockform}
															 						readMode={true}
																				 />
																		:""
																		} 
																	</>
																:""
																}
																</>
											        ))
											        }  
														</div>
												 }
							  />
			<Modal open={this.state.modal2} 
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1060"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (2)} 
      	class={this.state.modalIamgeClass}
      />
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Events"}/>
        	<div className="row mb-4 text-right">
          	 <ExcelFile filename={"export-customer-events."+this.state.date_label} element={         
					          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
					      }>
					          <ExcelSheet data={this.state.tableData.rows} name="Events">
					             
					          		<ExcelColumn label="Event ID" value="EVENTID"/> 
					              <ExcelColumn label="Store Name" value="STORENAME"/> 
					              <ExcelColumn label="Event Type" value="EVENTTYPE"/> 
					              <ExcelColumn label="Event Name" value="EVENTNAME"/> 
					              <ExcelColumn label="Event Date" value="EVENTDATE"/> 
					              <ExcelColumn label="Event End Date" value="EVENTENDDATE"/> 
					              <ExcelColumn label="Customer Name" value="CUSTOMERNAME"/>
					              <ExcelColumn label="Email" value="EMAIL"/>
					              <ExcelColumn label="Phone" value="PHONE"/>
					              <ExcelColumn label="Status" value="STATUS"/>
					              
					          </ExcelSheet>
	 

					    </ExcelFile>
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 