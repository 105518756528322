/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';

/*functions*/
import {getLanguages, find,validateComponent, getLanguage, validateSyncForm, findValueById,changeFilter, filterHandler,validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			modal2: false,
			modalMdClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			filterConfig: {
				STORE        : { label:"Store"        ,  type:"SELECT" , value:"" , values:[] },
				CATALOGNAME  : { label:"Catalog"        ,  type:"SELECT" , value:"" , values:[] },
				
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"STOREID", title:"Store ID",
						sorter: (a, b) => a.STOREID.length - b.STOREID.length },
					{ dataIndex:"STORE", title:"Store",
						sorter: (a, b) => a.STORE.length - b.STORE.length },
					{	dataIndex:"CATALOGNAME", title:"Catalog Name",
						sorter: (a, b) => a.CATALOGNAME.length - b.CATALOGNAME.length },
					{	dataIndex:"SORTORDER", title:"Sort Order",
						sorter: (a, b) => a.SORTORDER.length - b.SORTORDER.length },		
						
					{	dataIndex:"CODE", title:"Code",
						sorter: (a, b) => a.CODE.length - b.CODE.length },
						{	dataIndex:"LATITUDE", title:"Latitude",
						sorter: (a, b) => a.LATITUDE.length - b.LATITUDE.length },
					{	dataIndex:"LONGITUDE", title:"Longitude",
						sorter: (a, b) => a.LONGITUDE.length - b.LONGITUDE.length },
						
					{ dataIndex: "STOREID", title: "Action",
		        render: (text, record) => (<div className="text-right">
		         <button className="btn btn-sm btn-info" id={record.STOREID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Update</button>
		           
		         <button className="btn btn-sm btn-danger" id={record.STOREID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></div>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Attribute",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				
				STORE           : { label: "Store"       ,"sizelg":"col-lg-6", type: "TEXT",   value: "" } ,
				CATALOGNAME     : { label: "Catalog Name","sizelg":"col-lg-6" , notRequired:true , type: "SELECT", value: "", values: [] ,"addDefault":"Same as Store" , getDataDB: {apiGroup: "custom", apiMethod: "getDetailDomainsByCodeFromDB" ,  aditionalValues:[{domainid:"15"} , {subdomainId:"1"}] }} ,
				CODE            : { label: "CODE"     ,"sizelg":"col-lg-6"  , type: "TEXT",   value: "" } ,
				SORTORDER       : { label: "SORTORDER"  ,"sizelg":"col-lg-6"  , type: "TEXT", validationType:"number",   value: "" } ,
				ADDRESS         : { label: "Store Info"       , "sizelg":"col-lg-12", notRequired:true, type: "TEXT",   value: "" } ,
				LATITUDE        : { label: "Latitude"            , "sizelg":"col-lg-6"    , validationType:"number" , type: "TEXT",   value: "" } ,
				LONGITUDE       : { label: "Longitude"           , "sizelg":"col-lg-6"    , validationType:"number" , type: "TEXT",   value: "" } ,
			},
			 
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			refreshMessage:"Are you sure?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getStores" )
	 		const tableData = this.state.tableData
			let rows=[]
			resp.rows.map(function (item) {
				
				var catalogname= item.STRING_VALUE
				
				if (item.NUMBER_VALUE!= item.DETAILDOMAINID){
					catalogname =	 findValueById (resp.rows, item.NUMBER_VALUE, "DETAILDOMAINID", "STRING_VALUE")
				}
				
				let coord = JSON.parse(item.CLOB_VALUE)
				let row={
					STOREID     : item.DETAILDOMAINID,
					STORE       : item.STRING_VALUE,
					CATALOGID   : item.NUMBER_VALUE,
					SORTORDER   : item.SORTORDER,
					CATALOGNAME : catalogname,
					CODE        : item.CODE,
					LATITUDE    : coord.lat,
					LONGITUDE   : coord.lng,
					ADDRESS     : coord.address
				} 
				rows.push(row)
			}); 
				 
	 		tableData.rows = rows
	 		this.setState({originalTableData:this.state.tableData});
	 	 	
	 		await preloadForm ( this.state.formData )	 		
	 		const filterConfig = this.state.filterConfig
	 		filterConfig.CATALOGNAME.values.push("")
	 		this.state.formData.CATALOGNAME.values.map(function (item) {
				 filterConfig.CATALOGNAME.values.push (item.text)
			}); 
		 	filterConfig.STORE.values.push("")
			rows.map(function (item) {
				filterConfig.STORE.values.push (item.STORE)
			}); 
			
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	 //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	 
	  
  /*get value fields*/
  changeHandler = async(event) => {
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp 
		let isAsync=false
  	if (  this.state.formData[event.target.name]){
  		if ( this.state.formData[event.target.name].script ){
  			isAsync=true
  		}  		
  	}
		if (isAsync){
	  		resp = await validateForm(this.state.formData, toast,event);
	  	}else{
	  		resp = validateSyncForm(this.state.formData, toast,event);	
	  	}
		this.setState({formData:resp.formData});
		 
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
 //delete
	refresh=async (event) => {
  	try{	  	
			this.setState({  ...this.state, isLoading:true })
			let resp = await callApi ( "manager","updateProductsQtyByStores", {} )
			/*	
			let languages= await getLanguages()
								  
			for (let l in languages){
				let data= { languageid: languages[l].id,
								  	menutype:"CUSTOMERHEADER"} 
				let resp1 = await callApi ( "manager", "updateCategoriesByStock", data )	
			}
			*/
			let data= { languageid: getLanguage(),
								  	menutype:"CUSTOMERHEADER"} 
			let resp1 = await callApi ( "manager", "updateCategoriesByStock", data )	
			
			if (resp.success){
	  		showMessage(toast,resp.message);  
	 			this.setState({...this.state,modal1:false });
	  	}else{
	  		showError(toast,resp.message);
	  		this.setState({...this.state,modal1:false });
	  	}
			  	
	 	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:false,isLoading:false });
		}
	}
	 
	/*charge data from a selected Attribute*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Product Qty"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				 
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Product Qty"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		 
	  	  let pos = find ( this.state.originalTableData.rows,itemId, "STOREID" )
	  		
	  		let data = this.state.originalTableData.rows[pos]
	  		if (data.CATALOGID == data.STOREID){
	  			console.log("iguales")
	  			data["CATALOGNAME"]=""
	  		}else{
	  			data["CATALOGNAME"]=data["CATALOGID"]
	  		}

	  		let loadData = await mergeFormData (this.state.formData,data)
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Product Qty"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}else if (event.target.name==="refresh"){
	  		buttonsModal1 = [
					{	buttonAction: this.refresh,
						buttonId    : 1,
						buttonClass : "btn btn-outline-warning",
						buttonName  : "Refresh"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Refresh Stores"
				itemId = event.target.id
				formAction="refresh"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
	  			 
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
 					
 					var clobData = { lat: _formData.latitude, lng:_formData.longitude, address: _formData.address }
 					
 					console.log (this.state.tableData.rows)
 					 
 					let fixedFormData ={
 						domainid         : 15, //default 15 for stores
						detaildomainid    : this.state.currentId,
						subdomainid      : _formData.catalogname==""?1:0, 
						subdomain        : _formData.catalogname==""? "CATALOG":"",
						codedetaildomain : _formData.code,
						stringvalue      : _formData.store,
						numbervalue      : _formData.catalogname==""? this.state.currentId:_formData.catalogname,
						datevalue        : null,
						clobvalue        : JSON.stringify(clobData),
						sortorder        : _formData.sortorder

 					}
			  	let resp = await callApi ( "custom",this.state.formAction+"DetailDomains", fixedFormData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getStores" )
			  		
			  		let rows=[]
						resp.rows.map(function (item) {
							
							var catalogname= item.STRING_VALUE
							
							if (item.NUMBER_VALUE!= item.DETAILDOMAINID){
								catalogname =	 findValueById (resp.rows, item.NUMBER_VALUE, "DETAILDOMAINID", "STRING_VALUE")
							}
							let coord = JSON.parse(item.CLOB_VALUE)
							let row={
								STOREID     : item.DETAILDOMAINID,
								STORE       : item.STRING_VALUE,
								CATALOGID   : item.NUMBER_VALUE,
								SORTORDER   : item.SORTORDER,
								CATALOGNAME : catalogname,
								CODE        : item.CODE,
								LATITUDE    : coord.lat,
								LONGITUDE   : coord.lng,
								ADDRESS     : coord.address
							} 
							rows.push(row)
						});
			 			const tableData = this.state.tableData
			 			tableData.rows = rows
			 			
			 			this.setState({originalTableData:this.state.tableData});
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	} 
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
													:this.state.formAction==="refresh"?
														<h2>{this.state.refreshMessage}</h2>:
													 <Form 
															formData={this.state.formData} 
															 
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />
			 
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Stores"}/>
        	<div className="row mb-4 justify-content-center text-right">
						<div className="col-md-12 col-lg-12">
							<ExcelFile element={         
					          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
					      }>
				          <ExcelSheet data={this.state.tableData.rows} name="SKUS">
					              <ExcelColumn label="SKU Name" value="SPECIFICNAME"/>
					              <ExcelColumn label="SKU" value="SKU"/> 
					              <ExcelColumn label="Store" value="STORE"/> 
					              <ExcelColumn label="Stock" value="QUANTITY"/>
					              <ExcelColumn label="Price" value="PRICE"/>

				          </ExcelSheet>
						    </ExcelFile>
							<button className="btn btn-sm btn-warning" name="refresh"  onClick={this.loadFormData} type="submit">Refresh Stores</button>
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 