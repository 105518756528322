import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
const windowHeight = window.innerHeight
const loadCompItem = props => {
	
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" >
					{props.data.length>0?
		  			<div className="row">
							<div className={"col-sm-12 col-sm-12 col-lg-5 col-xl-5"}><span className="h6-responsive" >Item Text </span></div>							
							<div className={"col-sm-12 col-sm-12 col-lg-5 col-xl-5"}><span className="h6-responsive" >Item Value</span></div>
						</div>	
					:""}
				  {props.data.map(itemM => (
				  	 
							<div className="row mb-1">
						 		<div className={"col-sm-12 col-sm-12 col-lg-5 col-xl-5"}>
					 				<input key={props.container+"_text_"+itemM.rowId}  type="text" 
					 					className={"form-control form-control-sm "+ (itemM.isInvalid_text?"is-invalid":"")}
										value={itemM.text}
										name={"FIELDVALUE_"+props.container+"_text_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>	 
						  	<div className={"col-sm-12 col-sm-12 col-lg-5 col-xl-5"}>
					 					<input key={props.container+"_value_"+itemM.rowId}  type="text" 
					 					className={"form-control form-control-sm "+ (itemM.isInvalid_value?"is-invalid":"")}
										value={itemM.value}
										name={"FIELDVALUE_"+props.container+"_value_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>	 
							  <div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right middle"}>
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompForm_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
							</div> 	
					  )) 
					} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-sm-12 col-sm-12 col-lg-5 col-xl-5"}></div>
								<div className={"col-sm-12 col-sm-12 col-lg-5 col-xl-5"}></div>
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right middle"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompItem"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO Item DATA"
	}
};
export default loadCompItem;
				 