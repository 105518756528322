import React from "react"; 
import { Scrollbars } from "react-custom-scrollbars";

const loadModal = props => {


	return (
		<>
			{props.open?
				<>
					<div onClick={props.toggle?props.toggle:()=>console.log("nodef") }  className="modal-backdrop fade show"></div>
					
					<div className="modal fade top show" style={{"display": "block", "z-index": props.zindex?props.zindex:"1050" }} tabIndex="-1" role="dialog" aria-hidden="true">
						
						<Scrollbars
	            style={{
	              width: "100%",
	              height: "100%"
	            }} >
          	
							<div role="document" className={"modal-dialog "+ ( props.class?props.class:"modal-dialog-centered") }>
								<div className="modal-content">
									<div className="modal-header">
										<span  className="h3-responsive modal-title"> <i className={props.icon} /> {props.title}</span>
										{props.toggle?
											<button type="button" id={"1"} onClick={props.toggle} className="close" aria-label="Close"><span aria-hidden="true">x</span></button>
										:""}
									</div>
									<div className="modal-body">
										 {props.message}
									</div>
									{props.buttons || props.cancelButton?
										<div className="modal-footer justify-content-center text-center">
											<>
											{props.buttons?props.buttons.map(item => (item.buttonLink?
												
													<a type="submit" href={item.buttonLink} target="top" onClick={item.buttonAction} key={item.buttonId} className={item.buttonClass}>{item.buttonName} </a>
													:
													<button type="submit" onClick={item.buttonAction} key={item.buttonId} id={item.buttonId} name={item.buttonName.toLowerCase()} className={item.buttonClass}>{item.buttonName} </button>
												 ))
		                   :""}  
		                   	
		                   	{props.cancelButton?
		                   		<button type="submit" onClick={props.toggle?props.toggle:console.log("nodef")} key={"cancel"} className={"btn btn-secondary "+(props.cancelButtonClass?props.cancelButtonClass:"") }>Back </button>
		                   	:""}
	                   	</>
										</div>
								  	:""
								  }
								</div>
							</div>
						</Scrollbars>
		      </div>        
				</>
			:""}
		</>
	);

	};

export default loadModal;
				 