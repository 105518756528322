/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';

/*functions*/
import {getLanguages, validateComponent, changeFilter, filterHandler,validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 
var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			modal2: false,
			modalMdClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			date_label:date_label,
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			filterConfig: {
				REL_TYPE       : { label:"Relation Type" , type:"SELECT" , value:"" , values:["","CATEGORY","SKU" ] },
				SKU_A          : { label:"SKU A" ,      type:"TEXT"   , value:""} ,
				SKU_B          : { label:"SKU B" ,      type:"TEXT"   , value:""} ,
				CATEGORYNAME_A : { label:"Category A" , type:"SELECT" , value:"" , values:[""] },
				CATEGORYNAME_B : { label:"Category B" , type:"SELECT" , value:"" , values:[""] } 
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"REL_TYPE", title:"Relation Type",
						sorter: (a, b) => a.REL_TYPE.length - b.REL_TYPE.length },
					{ dataIndex:"SKU_A", title:"SKU A",
						sorter: (a, b) => a.SKU_A?a.SKU_A:0 - b.SKU_A?b.SKU_A:0 },
					{ dataIndex:"SKU_B", title:"SKU B",
						sorter: (a, b) => a.SKU_B?a.SKU_B:0 - b.SKU_B?b.SKU_B:0 },
					{ dataIndex:"CATEGORYNAME_A", title:"Category A",
						sorter: (a, b) => a.CATEGORYNAME_A?a.CATEGORYNAME_A.length:0 - b.CATEGORYNAME_A?b.CATEGORYNAME_A.length:0 },
					{ dataIndex:"CATEGORYNAME_B", title:"Category B",
						sorter: (a, b) => a.CATEGORYNAME_B?a.CATEGORYNAME_B.length:0 - b.CATEGORYNAME_B?b.CATEGORYNAME_B.length:0 },
						
					{ dataIndex:"CATEGORYROUTE_A", title:"Route A",
						sorter: (a, b) => a.CATEGORYROUTE_A?a.CATEGORYROUTE_A.length:0 - b.CATEGORYROUTE_A?b.CATEGORYROUTE_A.length:0 },
					{ dataIndex:"CATEGORYROUTE_B", title:"Route B",
						sorter: (a, b) => a.CATEGORYROUTE_B?a.CATEGORYROUTE_B.length:0 - b.CATEGORYROUTE_B?b.CATEGORYROUTE_B.length:0 },
				  
					{ dataIndex: "REL_INDEX", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-sm btn-danger" id={record.REL_TYPE+"__"+record.REL_INDEX} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Relation",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				REL_TYPE       : { label:"Category A"                     , type: "SELECT", value: "CATEGORY", values: [{text:"CATEGORY", value:"CATEGORY"},{text:"SKU", value:"SKU"}] } ,
				SKU_A          : { label: "SKU A"       ,notRequired:true , type: "TEXT",   value: "" } ,
				SKU_B          : { label: "SKU B"       ,notRequired:true , type: "TEXT",   value: "" } ,
				
				CATEGORYID_A   : { label:"Category A"   ,notRequired:true , type: "SELECT", value: "", values: [] , getDataDB: {apiMethod: "getCategories", apiGroup:"manager" } } ,
				CATEGORYID_B   : { label:"Category B"   ,notRequired:true , type: "SELECT", value: "", values: [] /*, getDataDB: {apiMethod: "getCategories", apiGroup:"manager" }*/ } ,
				
			},
						
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getRelatedProducts" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		this.setState({originalTableData:this.state.tableData});
	 		await preloadForm ( this.state.formData )
			
			const formData = this.state.formData
			this.state.formData.CATEGORYID_A.values.map(function (item) {
				 formData.CATEGORYID_B.values.push (item)
			}); 

	 		const filterConfig = this.state.filterConfig
	 		
	 		
	 	  
	 		resp.rows.map(function (item) {
	 			if (item.CATEGORYNAME_A){
	 				if (filterConfig.CATEGORYNAME_A.values.indexOf(item.CATEGORYNAME_A) <0){
	 					filterConfig.CATEGORYNAME_A.values.push(item.CATEGORYNAME_A)
	 				}
	 			}
	 			if (item.CATEGORYNAME_B){
	 				if (filterConfig.CATEGORYNAME_B.values.indexOf(item.CATEGORYNAME_B) <0){
	 					filterConfig.CATEGORYNAME_B.values.push(item.CATEGORYNAME_B)
	 				}
	 			}
			}); 
			 
			
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	 //filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	} 
	 
	  
  /*get value fields*/
  changeHandler = async(event) => {
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
		 
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
 
	 
	/*charge data from a selected Relation*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Relation"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				 
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Relation"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		var data = itemId.split("__")
	  		var rel_type= data[0]
	  		var pvs= data[1].split("_")
	  		let resp = await callApi ( "manager","getRelatedProductsBySKUCat",{rel_type: rel_type, pv_a: pvs[0], pv_b:pvs[1]} )
	  		let loadData = await mergeFormData (this.state.formData,resp.relatedProducts)
	  			  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Relation"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			var _formData =  this.state.formData;
  			if (actionType==="DELETE"){
					respValid= true			
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
					  _formData = parseFormData(_formData)
					  _formData["priority"] = 1
		  		if (_formData.rel_type=="CATEGORY"){
						if (_formData.categoryid_a ==0 || _formData.categoryid_b ==0 ){
							showError(toast,"COMPLETE CATEGORY A AND B");
							respValid=false
						}else{
							if (_formData.categoryid_a == _formData.categoryid_b  ){
								showError(toast,"THE CATEGORY A AND B ARE EQUALS");
								respValid=false
							}else{
								_formData["pv_a"] = _formData.categoryid_a
								_formData["pv_b"] = _formData.categoryid_b
							}
						}
					}else{
						if (_formData.sku_a =="" || _formData.sku_b =="" ){
							showError(toast,"COMPLETE SKU A AND B");
							respValid=false
						}else{
							if (_formData.sku_a == _formData.sku_b ){
								showError(toast,"THE CATEGORY A AND B ARE EQUALS");
								respValid=false
							}else{
								_formData["pv_a"] = _formData.sku_a
								_formData["pv_b"] = _formData.sku_b
							}
						}
					}
	  		}
	  		
	  		
					
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					
					if (actionType==="DELETE"){
						_formData={}
						var data = this.state.currentId.split("__")
	  				var rel_type= data[0]
		  			var pvs= data[1].split("_")
	  				_formData["rel_type"] =rel_type
	  				_formData["pv_a"] = pvs[0]
						_formData["pv_b"] = pvs[1]

					}
					
			  	let resp = await callApi ( "manager",this.state.formAction+"RelatedProducts", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getRelatedProducts" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({originalTableData:this.state.tableData});
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	
	
  
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
													:
													 <Form 
															formData={this.state.formData} 
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />
			 
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Related Products"}/>
        	<div className="row mb-4 justify-content-center text-right">
						<div className="col-md-12 col-lg-12">
							<ExcelFile filename={"export-relatedProducts."+this.state.date_label} element={         
					          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
					      }>
					          <ExcelSheet data={this.state.tableData.rows} name="SKUS">
				              <ExcelColumn label="Relation Type" value="REL_TYPE"/>
				              <ExcelColumn label="SKU A" value="SKU_A"/> 
				              <ExcelColumn label="SKU B" value="SKU_B"/> 
				              <ExcelColumn label="Category A" value="CATEGORYNAME_A"/> 
				              <ExcelColumn label="Category B" value="CATEGORYNAME_B"/>
											<ExcelColumn label="Route A" value="CATEGORYROUTE_A"/>
											<ExcelColumn label="Route B" value="CATEGORYROUTE_B"/>
					          </ExcelSheet>

					    </ExcelFile>
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 