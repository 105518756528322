import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { MDBIcon,MDBFormInline } from "mdbreact";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {getLanguages} from '../../common/functions';
import marker from "../../assets/images/marker.png";
import { SketchPicker, SwatchesPicker  } from 'react-color';
import {validateNumber, findValueById,getLanguage, getImagePath,getDefValues,getCoordinatesStore } from '../../common/functions';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Folder from "../../assets/images/folder.png";
const windowWidthDef = window.innerWidth

const grid = 7;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 6,
    margin: "0px 0px 8px 0px",
    fontSize: "14px",
		
		wordBreak: "break-all",
    // change background colour if dragging
    background: isDragging ? '#07d55a' : '#b1f7a6',
		border: "1px solid #5ce147",
		borderRadius: "0.4rem",
		minHeight:"0.75rem",
    // styles we need to apply on draggables
    ...draggableStyle
});

const getItemStyle2 = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 15,
    margin: 12,

    // change background colour if dragging
    background: isDragging ? '#07d55a' : '#d2dde3',
    border: "1px solid #0668a2",    
		borderRadius: "0.4rem",
		minHeight:"1rem",
    // styles we need to apply on draggables
    ...draggableStyle
});


const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#d6efff' : '#FFF',
    padding: grid,
    width: "25%",
    boxShadow : "0 1px 3px 0 rgba(0, 0, 0, .05)",
    border: "1px solid #dbdbdb",
    borderRadius: "4px",
    minHeight:"8rem",
    height: "100%"
});
const getListStyle2	 = isDraggingOver => ({
    background: isDraggingOver ? '#d6efff' : '#FFF',    
    boxShadow : "0 1px 3px 0 rgba(0, 0, 0, .05)",
		width: "73%",
		marginLeft: "2%",
		border: "1px solid #dbdbdb",
		borderRadius: "4px",
		minHeight:"8rem",
		height: "100%"
    
});

const AnyReactComponent = ({ top, left }) => <div 
style={{ 
	 	
	"z-index": "100",
	"position":"absolute",
	"top": top,
	"left": left,	
	 }}> 	<img style={{"width": "20px"}}	src={marker} alt=""	/> </div>;


/*"left": "calc(-18% + "+x+"px)",
	"top": "calc(-29% + "+y+"px)"	*/
	
const addRow = (props,  ini, numcol) => {
	  
	let row=[]
	let keys= Object.keys(props.formData)
	
	
	 
	for (let key=ini;key< ini+ numcol ; key++){
		
		if(key < Object.keys(props.formData).length ){
			 
			var field = props.formData[keys[key]];
			
			var label= field.label
			let input =""
			
			var filtersDND=[]
			if( field.filterValues){
				filtersDND = field.filterValues
			}
		
			if ( Array.isArray(label)){
				var label = findValueById(label, getLanguage(), "languageid", "value")
			} 
			if (field.type ==="TEXT"){
				if(field.includeButton=="YES" ){
					input =  
						<div key={"div_cont_"+keys[key]} className={ (field.size?field.size:"col-12") + " "+ 
		     												(field.sizesm?field.sizesm:" ") + " "+
		     												(field.sizemd?field.sizemd:" ") + " "+
		     												(field.sizelg?field.sizelg:" ") + " " } >
		     												
		     			<div className="row">
			     			<div key={"div_cont2_"+keys[key]} className="col-form-left">
									<div key={"div_form_"+keys[key]} className="form-group card-label ">
										<label key={"label_form_"+keys[key]}>{ label  }  </label>									
										<input id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
														
														placeholder={ field.placeholder?field.placeholder:"" }
														style ={ field.placeholder? {"height": "49px"}:{} }
									  				type="text" className={" left-border form-control" + (field.isInvalid?" is-invalid":"") }
														value={field.value}
														disabled={  props.lockform?true:field.disabled?field.disabled: false}
														name={(props.container?props.container+"__":"")+ keys[key]} 
														onChange={props.changeHandler} />
									</div>
								</div>
								<div className="col-form-right flex top" >
		            	<button className={"btn right-border btn-block btn-"+(field.actionLabel?field.actionLabel:"primary") }  
		            	style={{ 
		            		"box-shadow": "0 1px 3px 0 rgb(171, 79, 101)"
		            		}}type="button" name={props.container+"__"+field.buttonaction} onClick={props.manageButton}>{field.actionLabel?field.actionLabel: getDefValues().sendLabel }</button>
		          	</div>	
								
							</div>
							
						</div> 	
				}else{
					input = 
				 		<input id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
				 							disabled={  props.lockform?true:field.disabled?field.disabled: false}
				 							placeholder={ field.placeholder?field.placeholder:"" }
											style ={ field.placeholder? {"height": "49px"}:{} }
				 							type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={field.value}
											name={(props.container?props.container+"__":"")+ keys[key]} onChange={props.changeHandler} />
				}
			}else if (field.type ==="PASSWORD"){
					input = <input id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
										disabled={  props.lockform?true:field.disabled?field.disabled: false} 
										type="password" className={"form-control "+ (field.isInvalid?"is-invalid":"")}
										value={field.value+""}
										name={(props.container?props.container+"__":"")+ keys[key]}
										onChange={props.changeHandler} />
			}else if (field.type ==="TEXTAREA"){
				input = <textarea id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
											disabled={  props.lockform?true:field.disabled?field.disabled: false}
											type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={field.value+""}
											style= {{ height: field.height?field.height: 'auto' }}
											rows={field.rows?field.rows:"5"}
											name={(props.container?props.container+"__":"")+ keys[key]}
									onChange={props.changeHandler} />
			}else if (field.type ==="SELECT"){
				
				var textSelected=field.values[0]?field.values[0].text:""
				field.values.map(function (item) {
					if (item.value==field.value){
						textSelected = item.text
					}
	    	}); 
	           	
				input =props.lockform || field.disabled?
								<input id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
				 							disabled={  true}
				 							type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={textSelected}
											name={(props.container?props.container+"__":"")+ keys[key]} onChange={props.changeHandler} />
											
	      			:  <select id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] } key={keys[key]} 
								 			name={(props.container?props.container+"__":"")+ keys[key]} defaultValue={field.value} onChange={props.changeHandler} className={"form-control"+ (field.isInvalid?" is-invalid":"") }>
	               			disabled={field.disabled?field.disabled: false}
	              			{field.values.map(item => (
	              		 			<option key={keys[key] +"_i"+item.value} value={item.value}>{item.text}</option>
	              		 	))
	                  	} 
	      				</select>
	      			
	    }else if (field.type ==="MULTIPLE"){
				input = <select id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] } key={keys[key]} 
								 			name={(props.container?props.container+"__":"")+ keys[key]} multiple={true}  onChange={props.changeHandler} size={ field.rows?field.rows: 5 } className={"form-control"+ (field.isInvalid?" is-invalid":"") }>
	              			{field.values.map(item => (
	              		 		<option key={keys[key] +"_i"+item.value} value={item.value}>{item.text}</option>
	              		 	))
	                  	} 
	      				</select>
			}else if (field.type ==="DATEPICKER"){
				
				var options={
					locale: {
		        firstDayOfWeek: 1,
		        weekdays: {
		          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
		          longhand: ['Domingo', 'Lunes', 'Martes', 'Mi&eacute;rcoles', 'Jueves', 'Viernes', 'S&aacute;bado'],         
		        }, 
		        months: {
		          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
		          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		        },
			    }
				}
				input = field.isUpdatable===false?"":
								<DateTimePicker 
									key={keys[key]}
									date={field.value} 
									name={(props.container?props.container+"__":"")+ keys[key]}
									label={label} 
									options={options} 
									isUpdatable= {field.isUpdatable===undefined?true: field.isUpdatable }
									updateDate={props.changeHandler} 
								/>
			}else if (field.type ==="LINEBREAK"){
				input = <div key={keys[key]} className=""  />				
			}else if (field.type ==="CHECKBOX"){
				
				var checkValues=[]
	  		var originalValues = field.originalValues?field.originalValues:[]
				if (Array.isArray(field.value)){
					
					checkValues= field.values.sort(function (a, b){
							return ( (field.value.indexOf( (b.value) )) - (field.value.indexOf( (a.value) )))
					})
				
				}	else{
					checkValues=field.values
				}
				 
				
				input = <div   className="dep-checkbox-container">  
									{originalValues.length>8?
										<>
											<div key={"div_form_"+keys[key]} className={"form-group card-label" } style={{'width': '245px', "marginTop": "-6px"}} >
												<i className="fas fa-search"
												style={{
													"position" : "relative",
													"top"      : "19px",										 
													"left"     : "87%",
													"opacity"  : "0.4"
												}}
												></i>
												<input id={keys[key]+"filter_component" }  key={keys[key]+"filter_component" } 
													type="text" 
													style={{ height: "40px", paddingTop: "0px" }}
													value={field.filterValue+""}
													name={keys[key]}
													placeHolder={"Search items..."}
													onChange={props.changeFilter} />
											</div>
											
											<div key={"div_form_"+keys[key]} className={"form-group card-label flex items-left" } 
												style={{width: '100%', 
															 marginTop: '-0.5rem',
															 marginBottom: '1rem'}} >
												
												<button type="button" 
																className="text-right no-button" 
																style={{paddingRight: 0}} 
																name={keys[key]+"__"+"checkAll"} onClick={props.changeFilter} > 
													<span style={{ color:"#909090", fontSize:14}}> 
														<i className= { "mr-1 "+ (field.checkAll?"far fa-check-square":"far fa-square") }   /> 
														<span style={{textDecoration:"underline" }}>{!field.checkAll?"Check All":"Uncheck All"}</span> 
													</span> 
												</button>
											</div>
											<div key={"div_form_"+keys[key]} className={"form-group card-label flex items-right" } 
												style={{width: '100%', 
															 marginTop: '-2.5rem',
															 marginBottom: '0.75rem'}} >	
												<button type="button" 
																className="text-right no-button" 
																style={{paddingRight: 0}} 
																name={keys[key]+"__"+"showAll"} onClick={props.changeFilter} > 
													<span style={{ color:"#909090", fontSize:14}}> 
														<i className= {"fas fa-"+ (field.showAll?"plus":"minus") }   />
													</span> 
												</button>
											</div>
										</>
									:""}
									{!field.showAll?
										<>
											{checkValues.map(item => (
													<div className="checkbox">
														<label>
															<input 
															name={(props.container?props.container+"__":"")+ keys[key]} 
															checked={ Array.isArray(field.value)? (field.value.indexOf( (item.value) ) >= 0?true:false) :false  } 
															value = { item.value} key={keys[key] +"_"+item.value} 
															id= {keys[key] +"_"+item.value} 
															onChange={props.changeHandler} type="checkbox"/> {item.text} </label>
													</div>
				              		))
				              }
				            </>
			            : 
			          	  <>
											{checkValues.map((item, index) => ( index>8?"":
													<div className="checkbox">
														<label>
															<input 
															name={(props.container?props.container+"__":"")+ keys[key]} 
															checked={ Array.isArray(field.value)? (field.value.indexOf( (item.value) ) >= 0?true:false) :false  } 
															value = { item.value} key={keys[key] +"_"+item.value} 
															id= {keys[key] +"_"+item.value} 
															onChange={props.changeHandler} type="checkbox"/> {item.text} </label>
													</div>
				              		))
				              }
				            </>
			          	}
			          	
			          	{originalValues.length>8?
										<>
											  
											<div key={"div_form_"+keys[key]} className={"form-group card-label" } style={{'width': '100%', "marginTop": "-6px"}} >
												<button type="button" 
																className="col-12 text-right no-button" 
																style={{paddingRight: 0}} 
																name={keys[key]+"__"+"showAll"} onClick={props.changeFilter} > 
													<span style={{ color:"#909090", fontSize:14 }}> 
														 <i className= {"mr-1 fas fa-"+ (field.showAll?"plus":"minus") } />
														 <span style={{textDecoration:"underline" }}>{!field.showAll?"Minimize":"Show all"}</span> 
													</span> 
												</button>
											</div>
										</>
									:""}
								</div>
	    }else if (field.type ==="HIDDEN"){
				input = <input id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} disabled={true} type="hidden"  className={"form-control"+ (field.isInvalid?" is-invalid":"") }
									value={field.value+""}
									name={(props.container?props.container+"__":"")+ keys[key]}
									onChange={props.changeHandler} />
			
			}else if (field.type ==="LABEL"){
			 	
				input = 	<div key={"div_cont_"+keys[key]} className={ "mt-1 mb-1 "+ (field.size?field.size:"col-12") + " col-form "+ 
		     												(field.sizesm?field.sizesm:" ") + " "+
		     												(field.sizemd?field.sizemd:" ") + " "+
		     												(field.sizelg?field.sizelg:" ") + " " } >
									 <span className={ field.class?field.class:"form-span" } >	<b>{ label  }</b>   </span>
									</div>
			
			}else if (field.type ==="RADIO"){
				 	input = 					
									<div className="form-group card-label">
										<MDBFormInline>
										 
											{field.values.map(item => (
		              		 		 		
	              		 		<label className="ml-2">
													<input 
														key={keys[key] +"_i"+item.value}
														value={item.value}
														type="radio" name={(props.container?props.container+"__":"")+ keys[key]}
														checked={item.value===field.value?true:false} onClick={props.changeHandler} />
													<span className="checkmark ml-1">{item.text}</span>
												</label>  
		              		 )) 
											}
										</MDBFormInline>	
									</div>
			}else if (field.type==="GMAP"){
				var mapWidth =  600
				var left = "calc(-18% + "+field.value.x+"px)" 
				var top = "calc(-29% + "+field.value.y+"px)"	
				
				var windowWidth = props.windowWidth?props.windowWidth: windowWidthDef
				
				if (windowWidth <768){
					mapWidth = 376;
					var left = "calc(-11.4% + "+field.value.x+"px)" 
					var top = "calc(-28.8% + "+field.value.y+"px)"	
				}
				
				if (windowWidth <400){
					mapWidth = 290;
					var left = "calc(-41.2% + "+field.value.x+"px)" 
					var top = "calc(-34% + "+field.value.y+"px)"	
				}
				
				input = 					
					<div className="col-12 flex items-center">
			    	<div style={{ height: '500px', width: mapWidth+'px' }}>
			    	{windowWidth+  navigator.userAgent }
			        <GoogleMapReact
			          bootstrapURLKeys={{ key: 'AIzaSyDFVrNaijSzvgP7ffGmEESjDplb1JNrTL8' }}
			          defaultCenter={ { lat:field.config.lat ,lng:field.config.lng }}
			          defaultZoom={field.config.zoom}
			          language= {'es'}
	    					region={'es'}
	    					onClick={props.changeHandler}
			        >
			        	{field.value.x? 
			        		 
				          <AnyReactComponent  
				          	top={top}		
				            left={left}     
				          />
				        :""}
			        </GoogleMapReact>
			      </div>
			    </div>
			     
			}else if (field.type ==="ITEMS"){
				var textSelected=field.values[0].text
				field.values.map(function (item) {
					if (item.value==field.value){
						textSelected = item.text
					}
	    	}); 
	           	
				input =props.lockform?
								<input id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  key={keys[key]} 
				 							disabled={  true}
				 							type="text" className={"form-control"+ (field.isInvalid?" is-invalid":"") }
											value={textSelected}
											name={(props.container?props.container+"__":"")+ keys[key]} onChange={props.changeHandler} />
											
	      			: 	<div className="card itemsCard " style={field.isInvalid?{ "borderColor": "#dc3545"  }:{} } >
	      				 <div className="row ml-0">
	      				
	      					{field.values.map(item => (
	      						<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2 nomargin">
	      							<button 
	      								key={keys[key] +"_i"+item.value}
	      								className={"formitem"+ (item.value==field.value?" formitem-active":"") }
	      								type="button" value={item.value} 
	      								name={(props.container?props.container+"__":"")+ keys[key]}
	      							 	onClick={props.changeHandler}>
	      								 {item.text}
	      							</button>
	      							
	      						</div>
	      					))
	                } 	
	                </div>
	      				</div>
	      			
	    }else if (field.type ==="IFRAME"){
				input = <div key={"div_cont_"+keys[key]} className={ (field.size?field.size:"col-12") + " col-form "+ 
		     												(field.sizesm?field.sizesm:" ") + " "+
		     												(field.sizemd?field.sizemd:" ") + " "+
		     												(field.sizelg?field.sizelg:" ") + " " } >
		     					<iframe src={field.value} style={{"height": (field.height?field.height:"500px") , "width": (field.width?field.width:"100%"), "border": "0px " }} ></iframe>
		     			 </div> 
			}else if (field.type ==="API"){
				input = ""
			}else if (field.type ==="IMAGE"){
				let style= {/*"margin-left": "-12px",*/ "margin-top": "1.3rem"}
				
				let styleButton = { width: field.width?field.width:"100%" , wordBreak:'break-all'  }
				let styleInput  = { width: field.width?field.width:"100%" , "margin": "-1px 0px 2px 2px", wordBreak:'break-all'  }
											    
				if (field.flex ){
					style["display"] = "flex"
					style["margin-top"] = "20px"
					styleButton["margin"] = "0px"
					styleButton["height"] = field.height
					styleInput["width"]  = "auto"
					styleInput["marginRight"]  = 2
					styleButton["width"]  = "auto"
					styleButton["marginLeft"]  = 2
				}
											    
				
				input = <div className={ "col-form " +(field.size?field.size:"col-sm-12 col-md-6 col-lg-2 col-xl-2")} >
										<div style={style}>
											<input key={"manageInput_"+(props.container?props.container+"__":"")+keys[key]} 
														value={field.value}
														 className="form-control"
														style={styleInput}
														name={ "manageInput_"+(props.container?props.container+"__":"")+ keys[key]} 
														onChange={props.manageImage} />
											<img 
												 key={"image"+keys[key]}
												
												name={"selectItem_"+(props.container?props.container+"__":"")+keys[key]} 
												width= { field.width? field.width:  "100%" }
												height={ field.height?field.height: "100%" }
												style={{ "margin": "1px 0px 1px 2px"}}
												src={  field.value===""?defaultImage:getImagePath(field.value) } alt={field.value} 
											/> 
											
											<p>
												<button 
													key={ "manageImage_"+(props.container?props.container+"__":"")+ keys[key]} 
													type="button" style={styleButton } 
													className={"btn btn-primary btn-sm"+ (field.flex?"mt-1":"" ) }
													name={ "manageImage_"+(props.container?props.container+"__":"")+ keys[key]} 
													onClick={props.manageImage}> Change {field.label} </button>
											</p>
											 
										</div>
								</div>
			
			}else if (field.type ==="FOLDER"){
				let style= {"margin-left": "-12px"}
				
				let styleButton = { width: field.width?field.width:"120px"  }
											    
				if (field.flex ){
					style["display"] = "flex"
					style["margin-top"] = "15px"
					styleButton["margin"] = "0px"
					styleButton["height"] = field.height
				}
								 
				input = <div className="col-sm-12 col-md-9 col-lg-3 col-xl-3">
										<div style={style}>
											<img 
												 key={"image"+keys[key]}
												
												name={ "manageFolder_"+(props.container?props.container+"__":"")+ keys[key]} 
												onClick={props.manageFolder}
												width= { field.width? field.width:  "65px" }
												height={ field.height?field.height: "65px" }
												style={{ "margin": "0 2px", cursor:"pointer"}}
												src={ Folder } alt={field.value} 
											/> 
											<p className="ml-1 text-bold">
												{field.value?field.value: "NO SELECTED" }
											</p>
											 
										</div>
								</div>
								
			}else if (field.type ==="CompLanguage"){
				 	input = <div key={"div_cont_"+keys[key]} 
				 							className={ (field.size?field.size:"col-12") + " col-form "+ 
		     												(field.sizesm?field.sizesm:" ") + " "+
		     												(field.sizemd?field.sizemd:" ") + " "+
		     												(field.sizelg?field.sizelg:" ") + " " } 
		     												>
										<div key={"div_form_"+keys[key]} className={"form-group "+( field.type ==="TEXT"?"card-label":"card-label" ) } >
											<label 
												style={{"margin-bottom": "1rem", "margin-left":"-0.25rem"}}
												key={"label_form_"+keys[key]}> <b> {  field.label} </b> </label>
											 	
											 	{!field.values?"": field.values.map(item => (
															<div key={"div_form_"+keys[key]+item.id} 
															
															style={{"margin-top": "-1.25rem"}}
															className={"form-group card-label"} >
																<label key={"label_form_"+keys[key]+item.id}>{  item.name}  </label>
																	 
																	{field.subType=== "TEXTAREA"?
																		<textarea 
																	 		id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  
																	 		key={keys[key]} disabled={field.disabled?field.disabled: false}  
																			className={"form-control "+ (item.isInvalid?"is-invalid":"") }
																			value={item.value?item.value+"":""}
																			name={(props.container?props.container+"__":"")+ keys[key]+"__"+item.id }
																			onChange={props.changeHandler} />	
																	:
																	 	<input 
																	 		id={props.formConfig.formId? "form_"+props.formConfig.formId+"_"+keys[key]:"form"+keys[key] }  
																	 		key={keys[key]} disabled={field.disabled?field.disabled: false} 
																	 		type="text" 
																	 		className={"form-control "+ (item.isInvalid?"is-invalid":"") }
																			value={item.value?item.value+"":""}
																			name={(props.container?props.container+"__":"")+ keys[key]+"__"+item.id }
																			onChange={props.changeHandler} />
																	}
															</div>
							        	))}
							        	
        						</div>
									</div>

			}else if (field.type ==="CompCardChild"){
					
					input = <div >
										<div className="row ml-1 CompCardChild-header">
											<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Items </span></div>
										</div>
											
										{field.values.map(subitem => (
		              		<div className="row ml-1 CompCardChild-item">
												<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
													<span >{subitem.children}
													</span>
												</div>
												 
											</div>	 		
	              		 ))
	                  } 
		                <p className={"text-right"}>
											<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
										</p>
									</div>
			}else if (field.type ==="CompCard"){
				
				input = <div >
									<div className="row">
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Type </span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Item </span></div>
									</div>
										
									{field.values.map(subitem => (
	              		<div className="row">
											<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<span >{subitem.children?"Container":"Single"}
												</span>
											</div>
											<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<span >{ subitem.children?subitem.children:subitem.header===""?"NO HEADER": (subitem.header).substr(0,15)+ (subitem.header.length>15?"...":"") } 
												</span>
											</div>
										</div>	 		
              		 ))
                  } 
	                <p className={"text-right"}>
										<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
									</p>
								</div>
			}else if (field.type ==="CompCategoryBlog"){
				
				input = <div >
									<div className="row ml-1 CompCardChild-header">
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Items </span></div>
									</div>
										
									{field.values.map(subitem => (
	              		<div className="row ml-1 CompCardChild-item"  >
	              		{subitem.title || subitem.content?
											<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<>
													{subitem.title?<span >{subitem.title}</span>:""}
													{subitem.content? <p>{(subitem.content).substr(0,200)+ (subitem.content.length>200?"...":"")}</p>:""}
												</>
											</div>
										:""}
										{subitem.image?
											<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
										
												<img 
													 key={"image"+keys[key]}
													name={"selectItem_"+keys[key]} 
													width="120px" height="120px" 
													style={{ "margin": "1px 0px 9px 0px"}}
													src={  subitem.image===""?defaultImage:getImagePath(subitem.image) } alt={subitem.image} 
												/>
										
											</div>
										:""}	
										</div>	 		
              		 ))
                  } 
	                <p className={"text-right"}>
										<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
									</p>
								</div>
			}else if (field.type ==="CompForm"){
				
				input = <div >
									 
									<div className="row ml-1 CompCardChild-header">
										<div className={"col-sm-6 col-sm-6 col-lg-2 col-xl-2"}><span className="h6-responsive" >Type </span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-3 col-xl-3"}><span className="h6-responsive" >Field Name </span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-3 col-xl-3"}><span className="h6-responsive" >Label</span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-3 col-xl-3"}><span className="h6-responsive" >Default Value </span></div>
									</div>
									
										
									{field.values.map(subitem => (
	              		<div className="row ml-1 CompCardChild-item">
											<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-2 col-xl-2"}>
												<span >{subitem.type}
												</span>
											</div>
											<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-3 col-xl-3"}>
												<span >{ subitem.name } 
												</span>
											</div>
											<div key={keys[key] +"_i3"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-3 col-xl-3"}>
												<span >{ subitem.label } 
												</span>
											</div>
											<div key={keys[key] +"_i4"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-3 col-xl-3"}>
												<span >{ subitem.value } 
												</span>
											</div>
										</div>	 		
              		 ))
                  } 
	                <p className={"text-right"}>
										<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
									</p>
								</div>
			
			}else if (field.type ==="CompCoords"){
				
				input = <div >
									<div className="row ml-1 CompCardChild-header">
										<div className={"col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2"}><span className="h6-responsive" >Latitude </span></div>
										<div className={"col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2"}><span className="h6-responsive" >Longitude</span></div>
									</div>
										
									{field.values.map(subitem => (
	              		<div className="row ml-1 CompCardChild-item">
											<div key={keys[key] +"_i1"+subitem.value} className={"col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2"}>
												<span >{subitem.latitude}
												</span>
											</div>
											<div key={keys[key] +"_i2"+subitem.value} className={"col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2"}>
												<span >{ subitem.longitude } 
												</span>
											</div>
										</div>	 		
              		 ))
                  } 
	                <p className={"text-right"}>
										<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
									</p>
								</div>
			}else if (field.type ==="CompSliderImg"){
				
				input = <div >
									<div className="row ml-1 CompCardChild-header">
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Alt Image </span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Image </span></div>
									</div>
										
									{field.values.map(subitem => (
	              		<div className="row ml-1 CompCardChild-item">
											<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<span >{subitem.title}
												</span>
											</div>
											<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<img 
													 key={"image"+keys[key]}
													name={"selectItem_"+keys[key]} 
													width="120px" height="120px" 
													style={{ "margin": "1px 0px 9px 0px"}}
													src={  subitem.image===""?defaultImage:getImagePath(subitem.image) } alt={subitem.itemimage} 
												/>
											</div>
										</div>	 		
              		 ))
                  } 
	                <p className={"text-right"}>
										<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
									</p>
								</div>
			
			}else if (field.type ==="CompHeaderItems" ){
				
				input = <div >
									<div className="row ml-1 CompHeaderItems-header">
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Icon Image  </span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Link Url </span></div>
									</div>
										
									{field.values.map( (subitem, i) => (
	              		<div className="row ml-1 CompHeaderItems-item">
											<div key={keys[key] +"_i2"+i} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<img 
													 key={"image"+keys[key]+i}
													name={"selectItem_"+keys[key]+i} 
													width="75px" height="75px" 
													style={{ "margin": "1px 0px 9px 0px"}}
													src={  subitem.image===""?defaultImage:getImagePath(subitem.image) } alt={subitem.link} 
												/>
											</div>
											<div key={keys[key] +"_i1"+i} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<span >{subitem.link}
												</span>
											</div>
										</div>	 		
              		 ))
                  } 
	                <p className={"text-right"}>
										<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
									</p>
								</div>
			}else if (field.type ==="CompFooterItems"){
				
				input = <div >
									<div className="row ml-1 CompFooterItems-header">
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" > Image  </span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Title</span></div>
									</div>
										
									{field.values.map( (subitem, i) => (
	              		<div className="row ml-1 CompFooterItems-item">
											<div key={keys[key] +"_i2"+i} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<img 
													 key={"image"+keys[key]+i}
													name={"selectItem_"+keys[key]+i} 
													width="75px" height="75px" 
													style={{ "margin": "1px 0px 9px 0px"}}
													src={  subitem.image===""?defaultImage:getImagePath(subitem.image) } alt={subitem.title} 
												/>
											</div>
											<div key={keys[key] +"_i1"+i} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
												<span >{subitem.title}
												</span>
											</div>
										</div>	 		
              		 ))
                  } 
	                <p className={"text-right"}>
										<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
									</p>
								</div>
			}else if (field.type ==="CompList"){
				
				input = <div >
									<div className="row ml-1 CompCardChild-header">
										<div className={"col-sm-6 col-sm-6 col-lg-2 col-xl-2"}><span className="h6-responsive" >Image Title</span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-4 col-xl-4"}><span className="h6-responsive" >Text</span></div>
										<div className={"col-sm-6 col-sm-6 col-lg-4 col-xl-4"}><span className="h6-responsive" >Value </span></div>
									</div>
										
									{field.values.map(subitem => (
	              		<div className="row ml-1 CompCardChild-item">
											<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-2 col-xl-2"}>
												<img 
													 key={"image"+keys[key]}
													name={"selectItem_"+keys[key]} 
													width="70px" height="70px" 
													style={{ "margin": "1px 0px 9px 0px"}}
													src={  subitem.image===""?defaultImage:getImagePath(subitem.image) } alt={subitem.itemimage} 
												/>
											</div>
											<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-4 col-xl-4"}>
												<span >{subitem.title}
												</span>
											</div>
											<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-4 col-xl-4"}>
												<span >{subitem.text}
												</span>
											</div>
										</div>	 		
              		 ))
                  } 
	                <p className={"text-right"}>
										<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
									</p>
								</div>
			}else if (field.type ==="CompButton"){
				
				input = <div >
								<div className="row ml-1 CompCardChild-header">
									<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Button Name </span></div>
									<div className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}><span className="h6-responsive" >Action </span></div>
								</div>
									
								{field.values.map(subitem => (
              		<div className="row ml-1 CompCardChild-item">
										<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
											<span >{subitem.label}
											</span>
										</div>
										<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-6 col-sm-6 col-lg-6 col-xl-6"}>
											<span >{ subitem.action } 
											</span>
										</div>
									</div>	 		
            		 ))
                } 
                <p className={"text-right"}>
									<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
								</p>
							</div>
			}else if (field.type ==="CompOption"){
			
				input = <div >
								<div className="row ml-1 CompCardChild-header">
									<div className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}><span className="h6-responsive" >Option Value Name </span></div>
									<div className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}><span className="h6-responsive" >Image </span></div>
									<div className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}><span className="h6-responsive" >Sort Order </span></div>
								</div>
									
								{field.values.map(subitem => (
              		<div className="row ml-1 CompCardChild-item">
										<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}>
											<span >{subitem.name}
											</span>
										</div>
										<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}>
											<img 
												 key={"image"+keys[key]}
												name={"selectItem_"+keys[key]} 
												width="120px" height="120px" 
												style={{ "margin": "1px 0px 9px 0px"}}
												src={  subitem.image===""?defaultImage:getImagePath(subitem.image) } alt={subitem.image} 
											/>
										</div>
										<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}>
											<span >{ subitem.sortorder} 
											</span>
										</div>
									</div>	 		
            		 ))
                } 
                <p className={"text-right"}>
									<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
								</p>
							</div>
			
			}else if (field.type ==="CompItem"){
			
				input = <div >
								<div className="row">
									<div className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}><span className="h6-responsive" >Item Text </span></div>								
									<div className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}><span className="h6-responsive" >Item Value </span></div>
								</div>
									
								{field.values.map(subitem => (
              		<div className="row">
										<div key={keys[key] +"_i1"+subitem.value} className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}>
											<span >{subitem.text}
											</span>
										</div> 
										<div key={keys[key] +"_i2"+subitem.value} className={"col-sm-4 col-sm-4 col-lg-4 col-xl-4"}>
											<span >{ subitem.text} 
											</span>
										</div>
									</div>	 		
            		 ))
                } 
                <p className={"text-right"}>
									<button type="button" style={{ "width":"120px"}} className="btn btn-primary mt-1 btn-sm" name={ field.type +"_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageComponent}>{field.buttonLabel?field.buttonLabel:"Manage"}</button>
								</p>
							</div>
							
			}else if (field.type ==="IMAGEOLD"){
				input = <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
							<div > 
										<img 
											 key={"image"+keys[key]}
											name={"selectItem_"+keys[key]} 
											width="120px" height="120px" 
											style={{ "margin": "1px 0px 9px 0px"}}
											src={  field.value===""?defaultImage:getImagePath(field.value) } alt={field.value} 
										/>
										<p>
											<button type="button" style={{ "width":"120px"}} className="btn btn-primary btn-sm mt-1" name={ "manageImage_"+(props.container?props.container+"__":"")+ keys[key]} onClick={props.manageImage}> Change</button>
										</p>
									</div>
							</div>
			}else if (field.type ==="COLOR"){
				input = <div  name={keys[key]} 
				className={ (field.size?field.size:"col-12") + " col-form "+ 
		     												(field.sizesm?field.sizesm:" ") + " "+
		     												(field.sizemd?field.sizemd:" ") + " "+
		     												(field.sizelg?field.sizelg:" ") + " " }
		     												 key={keys[key]}>
 									
 									
 									<SketchPicker  
 										className={keys[key]}
 										name={keys[key]}
 										id={keys[key]}
						        color={ field.value?field.value:"#FFFFF" }
						        onChange={ props.changeHandler }
						      />
      
      
							  </div>
			}else if (field.type ==="CompDND"){
				var DropName =(props.container?props.container+"__":"")+ keys[key]
				input=
					<div className="dndContainer">
						<>
							 		
								<div key={"div__cont_dnd_"+keys[key]} style={ {width: "25%"}} >
									<span className="h5-responsive" >{label} </span>
									
									{filtersDND.map((filter, index) => (
										<div key={"div_form_filter"+index} className={"form-group card-label" } 
											style={{'width': '100%', "marginTop": "-6px"}} >
												
											<label key={"label_form_filter"+index}>{ filter.label.toUpperCase() }  </label>										
											<select  key={"select_form_filter"+index} 
									 			name={keys[key]+"__"+filter.label } 
									 			defaultValue={filter.value}
									 			onChange={props.changeFilter} className={"form-control"}>
		              			{filter.values.map(item => (
		              		 		<option key={"option_form_filter"+index+"_i"+item} value={item}>{item}</option>
		              		 	))
		                  	} 
		      						</select>
										</div>
									))
									}
							
								
									<div key={"div_form_"+keys[key]} className={"form-group card-label" } style={{'width': '100%', "marginTop": "-6px"}} >
										<i className="fas fa-search"
										style={{
											"position" : "relative",
											"top"      : "19px",										 
											"left"     : "87%",
											"opacity"  : "0.4"
										}}
										></i>
										<input id={keys[key]+"filter_component" }  key={keys[key]+"filter_component" } 
											type="text" 
											style={{ height: "40px", paddingTop: "0px" }}
											value={field.filterValue+""}
											name={keys[key]}
											placeHolder={"Search items..."}
											onChange={props.changeFilter} />
									</div>
								
								<div key={"div_form_"+keys[key]} className={"form-group card-label" } style={{'width': '100%', "marginTop": "-6px"}} >
									<button type="button" 
													className="col-12 text-right no-button" 
													style={{paddingRight: 0}} 
													name={keys[key]+"__"+"showAll"} onClick={props.changeFilter} > 
										<span style={{ color:"#909090", fontSize:14, textDecoration:"underline" }}> 
											{!field.showAll?"Collapse ":"Expand "}  <i className= {"fas fa-"+ (!field.showAll?"compress":"expand")+ "-arrows-alt" }   />
										</span> 
									</button>
									 
								</div>
								
								
							</div>
							 
								<div key={"div_cont_dnd_"+keys[key]}  style={ {width: "70%" , "marginLeft":"2%"  }}>
									<div className="title-bottom"> 
										<span className="h5-responsive">Drag Items inside box </span>
									</div>
								</div>
						 
						</>
						<div style={{ width: "100%" , 
													alignItems: 'flex-start',
													height: !field.showAll? 'auto': '14rem',
													overflow: !field.showAll?'initial':'hidden'
													
													 }} className={"form-inline"} >
							<DragDropContext onDragEnd={props.onDragEnd}>
			                <Droppable droppableId={"droppable"} label="hola2">
			                    {(provided, snapshot) => (
			                        <div
			                            ref={provided.innerRef}
			                            style={getListStyle(snapshot.isDraggingOver)}> 
			                            {field.values.map((item, index) => (
			                                <Draggable
			                                    key={item.value}
			                                    lable="hola"
			                                    draggableId={DropName+"__"+item.text+"__"+item.value}
			                                    index={index}>
			                                    {(provided, snapshot) => ( field.actionButton?
			                                    	
			                                        <div
			                                       		ref={provided.innerRef}
			                                          {...provided.draggableProps}
			                                          {...provided.dragHandleProps}
			                                          style={getItemStyle(
			                                              snapshot.isDragging,
			                                              provided.draggableProps.style
			                                          )}>
			                                          <span >  {item.text} </span>
			                                          <div style={{marginTop: -2, textAlign:"right"}}>
			                                           	<div id={item.value} className="btn btn-sm btn-info" onClick={()=> {field.action(item.value)}}> {field.actionLabel} </div>
			                                           </div>
			                                        </div>
			                                       :
			                                      	<div
			                                            ref={provided.innerRef}
			                                            {...provided.draggableProps}
			                                            {...provided.dragHandleProps}
			                                            style={getItemStyle(
			                                                snapshot.isDragging,
			                                                provided.draggableProps.style
			                                            )}>
			                                           <span >  {item.text} </span>
			                                        </div>
			                                    )}
			                                </Draggable>
			                            ))}
			                            {provided.placeholder}
			                        </div>
			                    )}
			                </Droppable> 
			                <Droppable droppableId="droppable2">
			                    {(provided, snapshot) => (
			                        <div
			                            ref={provided.innerRef}
			                            style={getListStyle2(snapshot.isDraggingOver)}>
			                            {field.value.map((item, index) => (
			                                <Draggable
			                                    key={item.value}
			                                    draggableId={DropName+"__"+item.text+"__"+item.value}
			                                    index={index}>
			                                    {(provided, snapshot) => ( field.actionButton?
			                                    	 <div
			                                            ref={provided.innerRef}
			                                            {...provided.draggableProps}
			                                            {...provided.dragHandleProps}
			                                            style={getItemStyle2(
			                                                snapshot.isDragging,
			                                                provided.draggableProps.style
			                                            )}>
			                                            <span className="h5-responsive">  {item.text} </span>
			                                          <div style={{marginTop: -2, textAlign:"right"}}>
			                                           	<div id={item.value} className="btn btn-sm btn-info" onClick={()=> {field.action(item.value)}}> {field.actionLabel} </div>
			                                           </div>
			                                        </div>
			                                    	:
			                                        <div
			                                            ref={provided.innerRef}
			                                            {...provided.draggableProps}
			                                            {...provided.dragHandleProps}
			                                            style={getItemStyle2(
			                                                snapshot.isDragging,
			                                                provided.draggableProps.style
			                                            )}>
			                                            <span className="h5-responsive">  {item.text} </span>
			                                        </div>
			                                    )}
			                                </Draggable>
			                            ))}
			                            {provided.placeholder}
			                        </div>
			                    )}
			                </Droppable>
			            </DragDropContext>
								 </div>
								</div>
			
			}else if (field.type ==="EDITOR"){
				input = <div  name={keys[key]} 
				className={ (field.size?field.size:"col-12") + " col-form "+ 
		     												(field.sizesm?field.sizesm:" ") + " "+
		     												(field.sizemd?field.sizemd:" ") + " "+
		     												(field.sizelg?field.sizelg:" ") + " " }
		     												 key={keys[key]}>
 									
 									 <Editor
						          editorState={props.editorState}
						          wrapperClassName="demo-wrapper"
						          editorClassName="demo-editor"
						          onEditorStateChange={props.onEditorStateChange}
						        />
						        
							  </div>
			}
	
	  	row.push( field.type ==="IMAGE" || field.type ==="API" || field.type ==="IFRAME" || field.type ==="HIDDEN" || 
	  						field.type ==="LABEL" || field.type ==="GMAP" || field.includeButton=="YES" ||
	  						field.type === "CompLanguage"  || field.type === "CompDND" || field.type === "NODEF" || field.type === "LINEBREAK" 
	  						 ? input :
	  	
		     				<div key={"div_cont_"+keys[key]} className={ (field.size?field.size:"col-12") + " col-form "+ 
		     												(field.sizesm?field.sizesm:" ") + " "+
		     												(field.sizemd?field.sizemd:" ") + " "+
		     												(field.sizelg?field.sizelg:" ") + " " } 
		     												>

									<div 
								style={{width: "100%"}}
									key={"div_form_"+keys[key]} className={"form-group "+( field.type ==="TEXT"?"card-label":"card-label" ) } >
										<label key={"label_form_"+keys[key]}>{ label  }  </label>
										{input}
									</div>
								</div> 
							)
			
			if (props.formData[keys[key+1]] ){
				if (props.formData[keys[key+1]].type==="LINEBREAK" ){
				  
					var curfield = props.formData[keys[key]];
					var curCol   = parseInt ((curfield.size?curfield.size:"col-12").split("-")[1])
					var curColsm = parseInt ((curfield.size?curfield.size:"col-12").split("-")[1])
					var curColmd = parseInt ((curfield.size?curfield.size:"col-12").split("-")[1])
					var curCollg = parseInt ((curfield.size?curfield.size:"col-12").split("-")[1])
					
					var LBcol = 12-curCol
					/*console.log("----")
					console.log(LBcol)
					console.log("----")*/
					
				}
			}
			
						 
		}
  }
	return row;
}

const loadForm = props => {
	
	const defaultProps = {
    center: {
      lat: props.gmpasConfig,
      lng: props.gmpasConfig
    },
    zoom: 8
  };
  
	if (props.formData){
		var groupingrows = validateNumber(props.formConfig.groupingrows)?parseInt( props.formConfig.groupingrows):2
		const formStructure=[]
		var formData =[]
		if (Array.isArray(props.formData)){
			
			for (let k in props.formData){
				
				if (k == props.activeForm ){
					
					let tmpProps = {formData: props.formData[k].formData, formConfig: props.formData[k].formConfig, changeHandler: props.changeHandler, lockform: props.lockform }
					formData = addRow(tmpProps, 0,Object.keys(props.formData[k].formData ).length )
				}
			}
			
		}else{
			 formData = addRow(props, 0,Object.keys(props.formData).length )
		}
		 
			
			formStructure.push(  
												<div key={"row_"+0} className={"row" + " flex top " + (props.formConfig.formalign?props.formConfig.formalign:" items-left") }> 
													{formData}
												</div>	
												)
		 
	  return ( 
	 		<div className= {"card-form "+(props.formConfig.formalign?props.formConfig.formalign:" items-left") } >
	 		 
	 			<div className= { props.formConfig.colsize+" "+props.formConfig.colsizelg+" "+props.formConfig.colsizemd+" "+props.formConfig.colsizesm } >
							{props.formConfig.formtitle?
								<>
									<div className="row">
										<div className={"col-" +( props.lockform?"10":"12"  ) + " flex top " + (props.formConfig.aligntitle?props.formConfig.aligntitle:" items-left") }>	
				 							{props.formConfig.formtittleicon?<img className={props.formConfig.formsubtitle?"formIcon":"formIcon2"} src={getImagePath(props.formConfig.formtittleicon)} alt={props.formConfig.formtittleicon} /> :""}
				 							<span className={"h4-responsive flex " + ( props.formConfig.formtittleicon?" ml-40 ":"" ) +(props.formConfig.formalign?props.formConfig.formalign:" items-left")} >{props.formConfig.formtitle}  </span>
				 							
				 						</div>
				 						{props.formConfig.formsubtitle?
					 						<div className={"col-" +( props.lockform?"10":"12"  ) + " flex top " + (props.formConfig.formalign?props.formConfig.formalign:" items-left") }>	
					 							{props.formConfig.formtittleicon?<div className="ml-40"/> :""}
					 							
					 							<span className={"flex " +(props.formConfig.formalign?props.formConfig.formalign:" items-left")} >{props.formConfig.formsubtitle}  </span>
					 						</div>
				 						:""}
				 						{props.lockform?
							   			<div className="col-2 flex items-right">	
												<button className="btn btn-edit-orderdata" type="button" onClick={props.editForm}>
													<i className="fas fa-edit" />
												</button>
											</div>
										:""}
				 					</div>
				 					{props.formConfig.formlinetitle? props.formConfig.formlinetitle.length>0 || props.formConfig.formlinetitle=="YES" ? 
				 						<span className="or-line mt-1 mb-30"></span> :<div className="mt-1 mb-30"></div>
				 					:<div className="mt-1 mb-30"></div>}
				 				</>
				 			:""}
				 			
				 			{Array.isArray(props.formData)?
				 				<>
				 					<div  className="mt-30 mb-3"> 
										 <div className="btn-group shipping-group" role="group" aria-label="shipping-types">
										 	{props.formData.map( (item, key) => (
										 		
										 		<button type="button" onClick={props.changeForm} 
										 						name={key}
											 					value={key}
											 					disabled= { props.lockform?true:false }
											 					
											 					className={"btn btn-primary "+ ( key==props.activeForm? " button-active ":
											 						
											 							("zIndex"+(110-key) )
											 						 ) +
											 						   
											 							 ( key==0?" rigth-button ":" rigth-button " )
											 							
											 					} 
										 		>
										 			{item.formLabel }
										 		</button>
	            		 		))
	                		} 
										</div>
									</div>
								
				 				</>
				 			:""}
						
				   		<form className={"customform"+ (props.customClass?" userForm":"") }>  
				   			{formStructure}  
							</form>
						</div>
					 
			</div> 			

	  );
	}else{
		console.log("NO FORM DATA>>>>>" , props)
		return "NO FORM DATA"
	}
};
export default loadForm;
