import React, { Component } from 'react';

import SidebarNav from "../sidebar";

import Table from '../../components/Table';

import { Switch } from "antd";

import IMG01 from "../../assets/images/doctors/doctor-thumb-01.jpg";
import IMG02 from "../../assets/images/doctors/doctor-thumb-02.jpg";
import IMG03 from "../../assets/images/doctors/doctor-thumb-03.jpg";
import IMG04 from "../../assets/images/doctors/doctor-thumb-04.jpg";
import IMG05 from "../../assets/images/doctors/doctor-thumb-01.jpg";
import IMG06 from "../../assets/images/doctors/doctor-thumb-02.jpg";
import IMG07 from "../../assets/images/doctors/doctor-thumb-03.jpg";
import IMG08 from "../../assets/images/doctors/doctor-thumb-04.jpg";

/*custom components*/
import PageHeader from '../../components/PageHeader';
import Auth from '../../services/Auth';

class Dashboard extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			isLoading: false,
			username : "",
			tableData: {
				columns : [
		      {
		        title: "Customer",
		        dataIndex: "Name",
		        render: (text, record) => (
		          <h2 className="table-avatar">
		            
		            <a href="/admin/profile">{text}</a>
		          </h2>
		        ),
		        sorter: (a, b) => a.Name.length - b.Name.length,
		      },
		      
		      {
		        title: "Category",
		        dataIndex: "PatientName",
		        render: (text, record) => (
		          <h2 className="table-avatar">
		            <a
		              href="/admin/profile"
		              className="avatar avatar-sm mr-2"
		            >
		              <img alt="" src={record.PatientImg} />
		            </a>
		            <a href="/admin/profile">{record.PatientName}</a>
		          </h2>
		        ),
		        sorter: (a, b) => a.PatientName.length - b.PatientName.length,
		      },
		      {
		        title: "Date Of Purchase",
		        dataIndex: "AppointmentTime",
		        render: (text, record) => (
		          <>
		            <span>{record.AppointmentDate}</span>
		            <span className="text-primary d-block">{text}</span>
		          </>
		        ),
		        sorter: (a, b) => a.AppointmentTime.length - b.AppointmentTime.length,
		      },
		      {
		        title: "Status",
		        dataIndex: "status",
		       
		        render: (e) => (
		          <Switch />
		        ),
		        sorter: (a, b) => a.status.length - b.status.length,
		      },
		      {
		        title: "Amount",
		        dataIndex: "Amount",
		        sorter: (a, b) => a.Amount.length - b.Amount.length,
		      },
		    ],
		    rows: [
	        {
	          id: 1,
	          image: IMG01,
	          Name: "Ruby Perrin",
	          Speciality: "Dental",
	          PatientImg: IMG06,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 2,
	          image: IMG02,
	          Name: "Darren Elder",
	          Speciality: "Cardiology",
	          PatientImg: IMG07,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 3,
	          image: IMG03,
	          Name: "Deborah Angel",
	          Speciality: "Orthopaedics",
	          PatientImg: IMG08,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$100.00",
	        },
	        {
	          id: 4,
	          image: IMG04,
	          Name: "Ruby Perrin",
	          Speciality: "Dermatology",
	          PatientImg: IMG06,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$2200.00",
	        },
	        {
	          id: 5,
	          image: IMG05,
	          Name: "John Gibbs",
	          Speciality: "Orthopaedics",
	          PatientImg: IMG07,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$13200.00",
	        },
	        {
	          id: 6,
	          image: IMG01,
	          Name: "Katharine Berthold",
	          Speciality: "Dental",
	          PatientImg: IMG08,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 7,
	          image: IMG02,
	          Name: "Linda Tobin",
	          Speciality: "Dermatology",
	          PatientImg: IMG05,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 8,
	          image: IMG03,
	          Name: "Marvin Campbell",
	          Speciality: "Cardiology",
	          PatientImg: IMG06,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 9,
	          image: IMG04,
	          Name: "Ruby Perrin",
	          Speciality: "Dental",
	          PatientImg: IMG07,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 10,
	          image: IMG05,
	          Name: "Ruby Perrin",
	          Speciality: "Neurology",
	          PatientImg: IMG08,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 11,
	          image: IMG01,
	          Name: "Ruby Perrin",
	          Speciality: "Urology",
	          PatientImg: IMG06,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 12,
	          image: IMG02,
	          Name: "Ruby Perrin",
	          Speciality: "Neurology",
	          PatientImg: IMG07,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	        {
	          id: 13,
	          image: IMG03,
	          Name: "Ruby Perrin",
	          Speciality: "Urology",
	          PatientImg: IMG08,
	          PatientName: "Category 1",
	          AppointmentDate: "9 Nov 2019",
	          AppointmentTime: "11.00 AM - 11.15 AM",
	          status: true,
	          Amount: "$3200.00",
	        },
	      	]
			}
		}
	}
	componentDidMount() {
	 	var token = Auth.loggedIn()
	 	this.setState({ ...this.state, username: token.username });
 	}
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <div className="page-wrapper">
	    <div className="content container-fluid">
		 
			<PageHeader title={"Welcome "+this.state.username+"!"} subtitle={"Dashboard"}/>
         
               {/* breadcrumb */}

             <div className="row">
				<div className="col-xl-3 col-sm-6 col-12">
					<div className="card">
						<div className="card-body">
							<div className="dash-widget-header">
								<span className="dash-widget-icon text-primary border-primary">
									<i className="fe fe-users"></i>
								</span>
								<div className="dash-count">
									<h3>168</h3>
								</div>
							</div>
							<div className="dash-widget-info">
								<h6 className="text-muted">Products</h6>
								<div className="progress progress-sm">
									<div className="progress-bar bg-primary w-50"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-sm-6 col-12">
					<div className="card">
						<div className="card-body">
							<div className="dash-widget-header">
								<span className="dash-widget-icon text-success">
									<i className="fe fe-credit-card"></i>
								</span>
								<div className="dash-count">
									<h3>487</h3>
								</div>
							</div>
							<div className="dash-widget-info">
								
								<h6 className="text-muted">Orders</h6>
								<div className="progress progress-sm">
									<div className="progress-bar bg-success w-50"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-sm-6 col-12">
					<div className="card">
						<div className="card-body">
							<div className="dash-widget-header">
								<span className="dash-widget-icon text-danger border-danger">
									<i className="fe fe-money"></i>
								</span>
								<div className="dash-count">
									<h3>485</h3>
								</div>
							</div>
							<div className="dash-widget-info">
								
								<h6 className="text-muted">Sales</h6>
								<div className="progress progress-sm">
									<div className="progress-bar bg-danger w-50"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-sm-6 col-12">
					<div className="card">
						<div className="card-body">
							<div className="dash-widget-header">
								<span className="dash-widget-icon text-warning border-warning">
									<i className="fe fe-folder"></i>
								</span>
								<div className="dash-count">
									<h3>$62523</h3>
								</div>
							</div>
							<div className="dash-widget-info">
								
								<h6 className="text-muted">Revenue</h6>
								<div className="progress progress-sm">
									<div className="progress-bar bg-warning w-50"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 col-lg-6">
					 
				   
				</div>
				<div className="col-md-12 col-lg-6">
					  
				</div>
				</div>
			{/*  row */}
			
			<div className="row">
				<div className="col-md-12 col-lg-12">
					<div className="card card-table flex-fill">
							<Table tableData={this.state.tableData} />
					</div>
				</div>
			</div>	
	     </div> 
         </div>
    </>         
    )
  }
}
export default Dashboard; 