import React, { Component } from 'react';
import { Link,Redirect } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoicon from "../../assets/images/logo-small.png";
import avatar from "../../assets/images/doctors/doctor-thumb-01.jpg";
import Dropdown from "react-bootstrap/Dropdown";
//import $ from "jquery";
import IMG01 from "../../assets/images/doctors/doctor-thumb-01.jpg";
import IMG02 from "../../assets/images/doctors/doctor-thumb-02.jpg";
import IMG03 from "../../assets/images/doctors/doctor-thumb-03.jpg";


/*custom components*/
import Auth from '../../services/Auth';
import SidebarNav from "../sidebar";


import {findObjName} from '../../common/functions';

const exclusionArray = [
      
      "register",
      "forgotPassword",
      "lockscreen",
      "404",
      "500" 
    ];
    
class Header extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
			isLogged   : false,
			username   : "",
			firstname  : "",
			lastname   : "",
			usergroup  : "",
			menuTemplate : [],
			isToggled	: false,
		}
  }
	

  componentDidMount() {
  	
  	var token = Auth.loggedIn()
  	var menuTemplate=[]
  	if(token.menu){
  		token.menu.map(function (key) {
			
				let mlevel= 2
				if (key.PAGE_URL=="#" || key.PAGE_DOMAIN ==null ){
					mlevel=1
				}
				
				let PARENTMENUID
				
				if (key.PAGE_URL!="#" ){
					PARENTMENUID = key.SUBDOMAINID
				}
				
				let MENUID       = key.PAGE_ID
				let ISPARENT     = key.PAGE_URL=="#"?true:false
				let MENUURL      = key.PAGE_URL+"/"+ key.PAGE_ID
				let OPEN         = false
				let MENULEVEL    = mlevel
				let MENULABEL    = key.PAGE_NAME
				 
				menuTemplate.push 
				({
					MENUID       : MENUID,
					ISPARENT     : ISPARENT,
					MENUURL      : MENUURL,
					OPEN         : OPEN,
					MENULEVEL    : MENULEVEL,
					MENULABEL    : MENULABEL,
					PARENTMENUID : PARENTMENUID
				})
			
			});
  	}
  	
	 	this.setState({ ...this.state, menuTemplate:menuTemplate, firstname: token.firstname, lastname: token.lastname, usergroup: token.usergroup });
	 	 
  }
	toggleClick = () => {
		this.setState({
			isToggled: !this.state.isToggled
		});
	}
	
	toggleMenuTemplate = ( event) => {
		var menuId = findObjName(event)
		var menuTemplate = this.state.menuTemplate
		menuTemplate.map(function (key) {
			if (key.MENUID == menuId){
				key["OPEN"] = key["OPEN"] =="true"?"false":"true"
			}
		});
		this.setState({menuTemplate: menuTemplate});
  }
  
  render() {
  	  
    if (exclusionArray.indexOf(this.props.location.pathname) >= 0) {
      return "";
    } 
    if (!Auth.loggedIn()) {
    	return (
      	<Redirect to="/login" />
      )
    }//<SidebarNav firstTimeTrue = {this.props.firstTimeTrue} />
   
    return (
    <>
    	
      <div className="header">
        {/* Logo */}
        <div className="header-left">
          <a href="/admin" className="logo">
            <img src={logo} alt="Logo" />
          </a>
          <a href="/admin" className="logo logo-small">
            <img src={logoicon} alt="Logo" width="30" height="30" />
          </a>
        </div>
        {/* /Logo */}
        <a href="#0" id="toggle_btn" onClick={this.handlesidebar}>
          <i className="fe fe-text-align-left" />
        </a>
        <div className="top-nav-search">
          <form>
            <input type="text" className="form-control" placeholder="Search here" />
            <button className="btn" type="submit"><i className="fa fa-search" /></button>
          </form>
        </div>
        {/* Mobile Menu Toggle */}
        <a href="#" onClick={this.toggleClick} className="mobile_btn" id="mobile_btn">
          <i className="fa fa-bars" />
        </a>
        {/* /Mobile Menu Toggle */}

        <ul className="nav user-menu">
          <li className="nav-item dropdown noti-dropdown">
            <Dropdown className="notify">
              <Dropdown.Toggle
                className="dropdown-toggle nav-link"
                id="dropdown-basic"
              >
                <i className="fe fe-bell"></i>{" "}
                
              </Dropdown.Toggle>

              <Dropdown.Menu className="notification-list">


                <Dropdown.Item
                  href="#/action-1"
                  className="notification-message"
                >
                  <span>No Notifications</span>
                </Dropdown.Item>
	             </Dropdown.Menu>
            </Dropdown>
          </li>

          <li className="nav-item dropdown has-arrow">
            <Dropdown className="user-dropdown">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span className="user-img">
                  <img
                    className="rounded-circle"
                    src={avatar}
                    width="31"
                    alt={this.state.firstname+" "+this.state.lastname}
                  />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" className="no-padding">
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      <img
                        src={avatar}
                        alt="User"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                    <div className="user-text">
                      <h6>{this.state.firstname+this.state.firstname+" "+this.state.lastname}</h6>
                      <p className="text-muted mb-0">{this.state.usergroup}</p>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item href="/profile"> My Profile</Dropdown.Item>
                <Dropdown.Item href="/settings">Settings</Dropdown.Item>
                <Dropdown.Item href="/logout">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        
        <div className={this.state.isToggled ? "mobile-header-active mobile-header-wrapper-style sidebar-visible" : "mobile-header-active mobile-header-wrapper-style"}>
          <div className="mobile-header-wrapper-inner">
              <div className="mobile-header-top">
                  <div className="mobile-header-logo">
                      <Link to="/">
                          <span>
                              <img src={logo} alt="logo"/>
                          </span>
                      </Link>
                  </div>
                  <div
                      className="mobile-menu-close close-style-wrap close-style-position-inherit">
                      <button className="close-style search-close"
                              onClick={this.toggleClick}>
                          <i className="icon-top"></i>
                          <i className="icon-bottom"></i>
                      </button>
                  </div>
              </div>
              <div className="mobile-header-content-area">
                  <div className="mobile-social-menu">
                      <nav>
                          <ul className="mobile-menu">
                               
                              {this.state.menuTemplate.map(l1 => (l1.MENULEVEL == "1" ?
                                      <li className="category-mobile-menu">
                                          <div className="no-link" name={l1.MENUID} onClick={ l1.ISPARENT? this.toggleMenuTemplate: this.emtpyFunction }>
                                              <a name={l1.MENUID} 
                                                 href={l1.MENUURL ? l1.ISPARENT? "#":l1.MENUURL : "#"}>

                                                  <span
                                                      className={l1.OPEN == "true" ? "text-bold" : ""}>{l1.MENULABEL}</span>
                                              </a>
                                              {l1.ISPARENT ? <i name={l1.MENUID}

                                                                className={"fa fa-angle-" + (l1.OPEN == "true" ? "up" : "down")}></i> : ""}
                                          </div>
                                          
                                           
                                          
                                          {/*level 2*/}
                                          {l1.ISPARENT && l1.OPEN == "true" ?
                                              <ul className="subcategory-mobile-menu2">
                                              	 
                                                {this.state.menuTemplate.map(l2 => (l2.MENULEVEL == "2" && l1.MENUID == l2.PARENTMENUID ?
                                                    <li>
                                                      <div className="no-link" name={l2.MENUID} onClick={ l2.ISPARENT? this.toggleMenuTemplate:this.emtpyFunction }>
                                                        <a name={l2.MENUID}  
                                                           href={l2.MENUURL ? l2.ISPARENT? "#":l2.MENUURL : "#"}>
																														<span className={l2.OPEN == "true" ? "text-bold" : ""}>{l2.MENULABEL}</span>
                                                        </a>
                                                      </div>
                                                    </li>
                                                    : ""
                                                ))
                                                }
                                              </ul>
                                              : ""
                                          }
                                      </li>
                                      : ""
                              ))
                              } 
                          </ul>

                      </nav>
                  </div>
              </div>
          </div>
      </div>
                                        
      </div>
    </>
    );
	}  
}

export default Header;
