/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
/*functions*/
import {manageImage, listImages, validateRole, filterHandler,encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date()
var fromDate = new Date()
fromDate.setDate( f.getDate() - 30) 

var mes = (f.getUTCMonth()+1 )<10? "0"+(f.getUTCMonth()+1 ): (f.getUTCMonth()+1 );
var dia = (f.getUTCDate() )<10? "0"+(f.getUTCDate()): (f.getUTCDate() ); 
var date_label = f.getUTCFullYear()+"."+mes+"."+dia; 

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			date_label:date_label,
			modal1: false,
			modal2: false,
			isLoading: true,
			modalClass: "modal-full-height modal-lg",
			modalIamgeClass:"modal-lg",
			filterConfig: {
				CUSTOMERID    : { label:"Customer ID"    , type:"NUMBER" , value:""} ,
				CUSTOMERNAME  : { label:"User Name"      , type:"TEXT"   , value:""} ,
				CUSTOMERGROUP : { label:"Customer Group" , type:"SELECT" , value:"" , values:[] },
			},
			originalTableData:{},
			/*table data*/
			tableData: {
				columns : [
				{ dataIndex:"CUSTOMERID", title:"Customer ID",
						sorter: (a, b) => a.CUSTOMERID - b.CUSTOMERID },
					{ dataIndex:"CUSTOMERNAME", title:"Customer username",
						sorter: (a, b) => a.CUSTOMERNAME.length - b.CUSTOMERNAME.length },
					{ dataIndex:"STATUS", title:"Status",
						sorter: (a, b) => a.STATUS.length - b.STATUS.length },
					{ dataIndex:"CUSTOMERGROUP", title:"Customer Group",
						sorter: (a, b) => a.CUSTOMERGROUP.length - b.CUSTOMERGROUP.length },	
						
					{ dataIndex:"DATE_ADDED", title:"Date Added",
						sorter: (a, b) => a.DATE_ADDED.length - b.DATE_ADDED.length },
					{ dataIndex: "CUSTOMERID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-info" id={record.CUSTOMERID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-danger" id={record.CUSTOMERID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Customer",
			/*form data*/			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 2,
				
			},
			formData	: {
				CUSTOMERNAME     : { label: "Customer Name"        , validationType:"text",     type: "TEXT",   value: ""  },
				CUSTOMERGROUPID  : { label: "Customer Group"       , addDefault:"CHOOSE ONE",			type: "SELECT", value: "", values: [] , getDataDB: {apiMethod: "getCustomerGroups", apiGroup:"manager"} } ,
				FIRSTNAME    : { label: "Firstname"        , validationType:"text",     type: "TEXT",   value: ""  },
				LASTNAME     : { label: "Lastname"         , validationType:"text",     type: "TEXT",   value: ""  },
				LASTNAME2     : { label: "Apellido Materno"         , validationType:"text",     type: "TEXT",   value: ""  },
				EMAIL        : { label: "Email"            , validationType:"email", notRequired:true , type: "TEXT",   value: ""  },
				
				PHONECODE     : { label: "phonecode"       , validationType:"text",     type: "TEXT",   value: ""  },
				PHONE         : { label: "phone"           , validationType:"number"  , notRequired:true,     type: "TEXT",   value: ""  },
				BIRTHDAY        : { label: "Birthday"             , type: "DATEPICKER",   value: new Date()} ,
				STATUSID     : { label: "Status"           ,                       			type: "SELECT", value: "", values: [] , getDataDB: {apiMethod: "getDetailDomainsByCode", aditionalValues:[{domainid:"5"}] } } ,
				
			},
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?",
			/*images or files*/
			initialFolder:"",
			imageManager:null,
			objImage:{
				items:[],
				selected:[]
			}
			 
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getCustomers" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		this.setState({originalTableData:this.state.tableData}); 
	 		await preloadForm ( this.state.formData )
	 		
	 		const filterConfig = this.state.filterConfig
	 		// console.log(this.state.formData.CUSTOMERGROUPID.values)
	 		filterConfig.CUSTOMERGROUP.values.push ("")
	 		this.state.formData.CUSTOMERGROUPID.values.map(function (item) {
	 			if (item.value!=""){
	 				filterConfig.CUSTOMERGROUP.values.push (item.text)
	 			}	
				 
			}); 
			
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	//filter table
  filterHandler = async(event) => {
		let resp = await filterHandler(event , this.state.filterConfig, this.state.originalTableData );
		if (resp.filterConfig){
			this.setState({ filterConfig:resp.filterConfig});
		}
		if (resp.tableData){
			this.setState({ tableData:resp.tableData});
		}
	}  
	
	/*charge data from a selected customer*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Customer"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Customer"
				itemId = event.target.id
	  		let resp = await callApi ( "manager","getCustomerById",{customerid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData,resp.USER)
	  		formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Customer"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  ///get value fields
  changeHandler = async(event) => {
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
	} 
  //show/hide modal
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	//manage image
	manageImage= async(event) => {
		try{
			this.setState({isLoading:true});
			var data = (event.target.name).split("_")
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages("",objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal2: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
						let field = objImage.objectName
						var formData = this.state.formData
						formData[field].value = objImage.selected[0]
						this.setState({modal2:false});
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	} 

	/*ABM manager*/
	execute=async (event,actionType) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			if (actionType==="DELETE"){
  				resp= {isValid:true}
  			}else{
  				resp = await validateForm(this.state.formData, toast);
  			}
  			if(resp.isValid){
			  	this.setState({  ...this.state, isLoading:true })
			  	var _formData =  this.state.formData;
			  	_formData = parseFormData(_formData)
					_formData["customerid"] = this.state.currentId;
				  
				 
					let resp = await callApi ( "manager",this.state.formAction+"Customers", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getCustomers" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({originalTableData:this.state.tableData});
			 			this.filterHandler({target:{name:"search"}})
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
					 
		  	}
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      
      
      <Modal open={this.state.modal2} 
      
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1060"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (2)} 
      	class={this.state.modalIamgeClass}
      />
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													:
													 <Form 
															formData={this.state.formData} 
															manageImage={this.manageImage}
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />

      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"System"} subtitle={"Customers"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
							<ExcelFile filename={"export-customers."+this.state.date_label} element={         
					          	<button type="button" className="btn btn-sm btn-success" name="set" > Export</button>
					      }>
			          <ExcelSheet data={this.state.tableData.rows} name="Customers">
			          		<ExcelColumn label="Customer ID"    value="CUSTOMERID"/>
			          		<ExcelColumn label="Customer username"    value="CUSTOMERNAME"/>
			          		<ExcelColumn label="Status"     value="STATUS"/> 
			              <ExcelColumn label="Customer Group"  value="CUSTOMERGROUP"/>
										<ExcelColumn label="Date Added" value="DATE_ADDED"/>
			          </ExcelSheet>
				   		</ExcelFile>
							<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} filterConfig={this.state.filterConfig} filterHandler={this.filterHandler}  />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 