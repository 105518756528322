/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';

/*functions*/
import {validateRole, changeFilter, findObjName,encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			isLoading: true,
			modalClass: "modal-full-height modal-md",
			/*table data*/
			tableData: {
				columns : [
					{ dataIndex:"USERGROUPNAME", title:"User Group Name",
						sorter: (a, b) => a.USERGROUPNAME.length - b.USERGROUPNAME.length },
					{ dataIndex: "USERGROUPID", title: "Action",
		        render: (text, record) => (<div className="text-right">
		         <button className="btn btn-info" id={record.USERGROUPID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-danger" id={record.USERGROUPID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></div>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New User Group",
			/*form data*/			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				
			},
			formData	: {
				USERGROUPNAME      : { label: "User Group Name"    ,validationType:"text"  , type: "TEXT",   value: ""  },
				ACCESSPERMISSION   : { label: "Access Permission"  ,filterValue:"", showAll:true, validationType:"array" , type: "CHECKBOX", value: [],  values: [] , getDataDB: {apiMethod: "getAdminMenus"} } ,
				MODIFYPERMISSION   : { label: "Modify Permission"  ,filterValue:"", showAll:true, type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getAdminMenus" } } ,
			},
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
		
	 	try{
	 		let resp = await callApi ( "manager","getUserGroups" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		await preloadForm ( this.state.formData )
	 		
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	/*charge data from a selected user*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New User  Group"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-md"
				let loadData = await clearformData (this.state.formData)
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit User Group"
				itemId = event.target.id
	  		let resp = await callApi ( "manager","getUserGroupById",{usergroupid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData,resp.USERGROUP)
	  		formAction="update"
	  		modalClass =  "modal-full-height modal-md"
	  		
	  		console.log (loadData)
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete User Group"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  ///get value fields
  changeHandler = async(event) => {
  	//console.log(event.target.value)
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
	} 
	
	changeFilter = async(event) => {

		let _data = findObjName(event)
		let data =  _data.split("__")
		var originalValues=[]
		if (data.length==1){
			originalValues = this.state.formData[data].originalValues
		}
    
		 
  	var resp = await changeFilter(event, originalValues , this.state.formData)	
    this.setState({formData:resp});
 
	} 
	
  //show/hide modal
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
	/*ABM manager*/
	execute=async (event,actionType) => {
  	try{
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			if (actionType==="DELETE"){
  				resp= {isValid:true}
  			}else{
  				resp = await validateForm(this.state.formData, toast);
  			}
  			if(resp.isValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
					_formData["usergroupid"] = this.state.currentId;
			  	resp = await callApi ( "manager",this.state.formAction+"UserGroups", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getUserGroups" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  }
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log("..>>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													:
													 <Form 
															formData={this.state.formData} 
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
															changeFilter={this.changeFilter}
													 />
												 }
							  />

      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"System"} subtitle={"User Groups"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
								<button type="button" className="btn btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 