import React from "react"; 

const addRow = (props,  ini, numCols) => {
	let row=[]
	let keys= Object.keys(props.modalData)
	
	
	
	for (let key=ini;key< ini+ numCols ; key++){
	 
		if(key < Object.keys(props.modalData).length ){
			var field = props.modalData[keys[key]];
			
			var col = "col-12"
			
			var style={}
			if (field.style){
				style = field.style
			}
	  	row.push(   
     				
     				<div className={ col + " mobile-col d-flex" } style={style}  >
              <div className={ (field.cardclass?field.cardclass:"")+ " card flex-fill"} >
             		{field.body}
              </div>
            </div>
                  
			)
		}
  }
	return row;
}

const loadModal = props => {

	if (props.modalData && props.modalData.length>0 ){
 		const modalStructure=[]
		var from = 0
		var numCols =  Object.keys(props.modalData).length
		var row = addRow(props,  from, numCols )
		modalStructure.push(  
												<div key={"row_"+0} className="row"> 
													{row}
												</div>	
												)
		 				
	  return ( 
			<>
				{props.modalConfig.startopen?
					<>
						<div onClick={props.toggle} name={props.name} className="closable modal-backdrop fade show"></div>
							<div onClick={props.toggle} name={props.name} style={{"display": "flex"}} className={"modal fade show "+(props.modalConfig.align?props.modalConfig.align:"middle")+" " +(props.modalConfig.justify?props.modalConfig.justify:"center") }  tabIndex="-1" role="dialog" aria-hidden="true">
								<div role="document" className={"modal-dialog "+ ( props.modalConfig.size ?props.modalConfig.size :"modal-dialog-centered") }>
								 
									<div className="modal-content">
									
									 
										{props.modalConfig.title!=""?
											<div className="modal-header">
												<span  className="h3-responsive modal-title"> <i className={props.modalConfig.icon?props.modalConfig.icon:""} /> {props.modalConfig.title}</span>
												{props.toggle?
													<button type="button" name={props.name} onClick={props.toggle} className="close" aria-label="Close"><span name={props.name} aria-hidden="true">x</span></button>
												:""}
											</div>
										:<div className="primary-cont">
												
												{props.toggle?
													<button type="button" name={props.name} onClick={props.toggle} className="closeModal" aria-label="Close">
													 <i name={props.name} className="fas fa-times"></i>
													</button>
												:""}
											</div>}
										<div className="modal-body">
											  
											<div className="" >
										 		<div className="col-md-12 col-lg-12 col-xl-12" >
										   			{modalStructure}  
												</div> 			
											</div> 	
											 
										</div>
										{props.modalConfig.buttons || props.modalConfig.cancelbutton?
											<div className="modal-footer justify-content-center text-center">
												<>
												{props.modalConfig.buttons?props.modalConfig.buttons.map(item => (
														<button type="submit" onClick={item.buttonaction} key={"btn_"+item.rowId} className={"btn btn-"+item.color}>{item.label} </button>
													 ))
			                   :""}  
			                   	
			                   	{props.modalConfig.cancelbutton?
			                   		<button type="submit" name={props.name} onClick={props.toggle?props.toggle:console.log("nodef")} key={"cancel"} className={"btn btn-secondary "+(props.cancelbuttonclass?props.cancelbuttonclass:"") }>Back </button>
			                   	:""}
		                   	</>
											</div>
									  	:""
									  }
									</div>
								</div>
				      </div>        
					</>
				:""}
			</>
		);
	}else{
		return ""
	}
};

export default loadModal;
				 