import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";

const windowHeight = window.innerHeight

const loadCompButton = props => {
	
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" style={{ "maxHeight": (windowHeight-240)+"px" , "overflow":"auto"  } }>
					{props.data.length>0?
		  			<div className="row">
							<div className={"col-md-6 col-sm-6 col-lg-4 col-xl-4 ml-1"}><span className="h6-responsive" >Button Name</span></div>
							 
							<div className={"col-md-6 col-sm-6 col-lg-4 col-xl-4"}><span className="h6-responsive" >Button Action</span></div>
						 	<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}><span className="h6-responsive" >Button Color</span></div>
						 
							<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1"}> </div>
						</div>	
					:""}
				  {props.data.map(itemM => (
				  	<>
							<div className="row mb-1">
						 		<div className={"col-md-6 col-sm-6 col-lg-4 col-xl-4"}>
					 				<input key={props.container+"_label_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_label?"is-invalid":"")}
										value={itemM.label}
										name={"FIELDVALUE_"+props.container+"_label_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>						  	
						  	 
						  	
						  	<div className={"col-md-6 col-sm-6 col-lg-4 col-xl-4"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_action_"+itemM.rowId} defaultValue={itemM.action} onChange={props.manageComponent} className={"form-control form-control-sm"+ (itemM.isInvalid_label?"is-invalid":"") }>
			        			<option key={props.container+"attr_i"+0} value={""}>CHOOSE ONE OPTION</option>
			        			<option key={props.container+"attr_i"+1} value={"submitform"}> Submit Form </option>
			        			<option key={props.container+"attr_i"+2} value={"closeform"}> Close Modal </option>
									</select>
						  	</div>			
						  	<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}>
					 				<select  name={"FIELDVALUE_"+props.container	+"_color_"+itemM.rowId} defaultValue={itemM.color} onChange={props.manageComponent} className={"form-control form-control-sm" }>
			        			<option key={props.container+"attr_i"+1} value={"primary"}> Primary </option>
			        			<option key={props.container+"attr_i"+2} value={"secondary"}> Secondary </option>
			        			<option key={props.container+"attr_i"+3} value={"danger"}> Danger </option>
									</select>
						  	</div>		
						  	
						  	<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompButton_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-md-6 col-sm-6 col-lg-4 col-xl-4"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-4 col-xl-4"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-3 col-xl-3"}></div>
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompButton"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO CompButton DATA"
	}
};
export default loadCompButton;
				 