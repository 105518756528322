import React, { Component } from "react";
import { Table, Switch } from "antd";

import {
  itemRender,
  onShowSizeChange,
} from "../../common/functions";


const loadTab = props => {
	
	if (props.tabData){
		
	  return (
		 
      	<div className="pt-0 ml-1">
        	<ul role="tablist" className="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
		        {props.tabData.tabs.map(item => (
			      	<li key={"#tab_li_"+ item.id} className="nav-item">
              <a onClick ={ props.tabData.onChangeTab } id={item.id} key={"#tab_a_"+ item.id} href={"#tab_"+ item.id} data-toggle="tab" className={"nav-link"+( props.tabData.activeTab=== item.id? " active" :"") } >{item.title }</a>
            	</li>  
			       ))
		        }
        	</ul> 
          <div className="tab-content ">
          	{props.tabData.tabs.map(item => (
          		<div role="tabpanel" id="tab-1" className={"tab-pane fade"+( props.tabData.activeTab=== item.id? " show active" :"") }>
              	<p className="card-text">{item.content}</p>
            	</div>
			       ))
		        }
          </div>
        </div>
    	  
	  );
	}else{
		return "NO TAB DATA"
	}
};
export default loadTab;
				 