import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
import defaultImage from "../../assets/images/default.jpg";
import {getImagePath } from '../../common/functions';
const windowHeight = window.innerHeight

const loadComp = props => {
	 
	if (props.data){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12" >
					{props.data.length>0?
		  			<div className="row">
							<div className={"col-sm-4 col-sm-4 col-lg-2 col-xl-2"}><span className="h6-responsive" >Image Title</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}><span className="h6-responsive" >Text</span></div> 
							<div className={"col-md-6 col-sm-6 col-lg-4 col-xl-4"}><span className="h6-responsive" >Value</span></div>
							<div className={"col-sm-4 col-sm-4 col-lg-2 col-xl-2"}><span className="h6-responsive" >Image Content</span></div>
							  
							<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1"}> </div>
						</div>	
					:""}
				  {props.data.map(itemM => (
				  	<>
							<div className="row mb-1">
								<div className={"col-sm-4 col-sm-4 col-lg-2 col-xl-2"}>
					 				<img 
											key={"img"+itemM.rowId} 
											name={"manageImage_"+itemM.rowId+"_image"} 
											onClick={props.manageImage} 
											style={{"cursor": "pointer"}}
											width="90px" height="90px" 
											src={  itemM.image==""?defaultImage:getImagePath(itemM.image)} />
						  	</div>		
						  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
					 				<input key={props.container+"_title_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_title?"is-invalid":"")}
										value={itemM.title}
										name={"FIELDVALUE_"+props.container+"_title_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div> 
						  	<div className={"col-md-6 col-sm-6 col-lg-4 col-xl-4"}>
					 				<input key={props.container+"_text_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_text?"is-invalid":"")}
										value={itemM.text}
										name={"FIELDVALUE_"+props.container+"_text_"+itemM.rowId}
										onChange={props.manageComponent} />
						  	</div>	
						  	
						  	<div className={"col-sm-4 col-sm-4 col-lg-2 col-xl-2"}>
					 				<img 
											key={"img"+itemM.rowId} 
											name={"manageImage_"+itemM.rowId+"_imageC"} 
											onClick={props.manageImage} 
											style={{"cursor": "pointer"}}
											width="90px" height="90px" 
											src={  itemM.imageC==""?defaultImage:getImagePath(itemM.imageC)} />
						  	</div>			
						  	  
						  	<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={"DELROW_"+props.container+"_CompList_"+itemM.rowId} onClick={props.manageComponent}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>
					  </>
					  ))
						} 
						{props.manageComponent?
							<div className="row">
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-6 col-xl-6"}></div>
								 
								
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}> <button  className="btn btn-info btn-sm"  name={"ADDROW_"+props.container+"_CompSliderImg"}
					         onClick={props.manageComponent} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO loadListComp DATA"
	}
};
export default loadComp;
				 