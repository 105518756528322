/*****components***/
import React, { Component } from "react";
import { Switch } from "antd";
import { toast, ToastContainer } from "mdbreact";
/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from "../../components/PageLoading";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import Form from "../../components/Form";
import PageHeader from "../../components/PageHeader";
import Tab from "../../components/Tab";
import Attributes from "../../components/Attributes";
import Discounts from "../../components/Discounts";
import Specials from "../../components/Specials";
import Options from "../../components/NewOptions";
import Images from "../../components/ImagePath";
import ImageManager from "../../components/ImageManager";
import FolderManager from "../../components/FolderManager";
import defaultImage from "../../assets/images/default.jpg";

/*functions*/
import {
  manageImage,
  findObjName,
  getLanguage,
  listImages,
  manageImageFolder,
  listImageFolders,
  find,
  toFixed,
  changeFilter,
  validLanguageName,
  filterHandler,
  getImagePath,
  findValueById,
  validateNumber,
  getLanguages,
  validateRole,
  encryptText,
  mergeFormData,
  clearformData,
  callApi,
  validateForm,
  showError,
  showMessage,
  showWarning,
  preloadForm,
  parseFormData,
} from "../../common/functions";
import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var f = new Date();
var fromDate = new Date();
fromDate.setDate(f.getDate() - 30);

var mes =
  f.getUTCMonth() + 1 < 10 ? "0" + (f.getUTCMonth() + 1) : f.getUTCMonth() + 1;
var dia = f.getUTCDate() < 10 ? "0" + f.getUTCDate() : f.getUTCDate();
var date_label = f.getUTCFullYear() + "." + mes + "." + dia;

var windowHeight = window.innerHeight;
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_label: date_label,
      modal1: false,
      modal2: false,
      modal2B: false,
      modal3: false,
      modal30: false,
      modal31: false,
      folderManager: null,
      imageManager: null,
      objFolder: {
        items: [],
        selected: [],
      },
      allData: [],
      allDataCombo: [],
      imageManager2: null,
      originalTableData: {},
      isLoading: true,
      modalClass: "modal-full-height modal-lg",
      modalSMClass: "modal-sm",
      modalIamgeClass: "modal-lg",

      formData30: {
        CATEGORIES: {
          label: "Category",
          type: "SELECT",
          value: "",
          values: [],
          addDefault: "NO DEFINED",
        },
      },
      formConfig30: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
      },

      formData31: {
        foldername: {
          label: "Category Folder",
          type: "SELECT",
          value: "",
          values: [
            {
              value: "catalog/product/ACCESORIOS PERSONALES/",
              text: "catalog/product/ACCESORIOS PERSONALES/",
            },
            { value: "catalog/product/BANO/", text: "catalog/product/BANO/" },
            { value: "catalog/product/BANO/TOALLA/", text: "catalog/product/BANO/TOALLA/" },
            { value: "catalog/product/BEBE/", text: "catalog/product/BEBE/" },
            {
              value: "catalog/product/COCINA/",
              text: "catalog/product/COCINA/",
            },
            {
              value: "catalog/product/MUEBLES/MUEBLES COMEDOR/",
              text: "catalog/product/MUEBLES/MUEBLES COMEDOR/",
            }, 
            {
              value: "catalog/product/MUEBLES/PREVENTA/",
              text: "catalog/product/MUEBLES/PREVENTA/",
            }, 
            {
              value: "catalog/product/COMBOSPACKS/",
              text: "catalog/product/COMBOSPACKS/",
            },
            {
              value: "catalog/product/COMEDOR/",
              text: "catalog/product/COMEDOR/",
            },
            {
              value: "catalog/product/DORMITORIO/",
              text: "catalog/product/DORMITORIO/",
            },
            {
              value: "catalog/product/ESCRITORIO Y PAPELERIA/",
              text: "catalog/product/ESCRITORIO Y PAPELERIA/",
            },
            {
              value: "catalog/product/GIFT CARDS/",
              text: "catalog/product/GIFT CARDS/",
            },
            {
              value: "catalog/product/INVERTIDOS/",
              text: "catalog/product/INVERTIDOS/",
            },
            {
              value: "catalog/product/JARDIN/",
              text: "catalog/product/JARDIN/",
            },
            {
              value: "catalog/product/LAVANDERIA/",
              text: "catalog/product/LAVANDERIA/",
            },
            {
              value: "catalog/product/LIVING Y SALA DE ESTAR/",
              text: "catalog/product/LIVING Y SALA DE ESTAR/",
            },
            {
              value: "catalog/product/LIVING Y SALA DE ESTAR/CORTINAS/",
              text: "catalog/product/LIVING Y SALA DE ESTAR/CORTINAS/",
            },
            {
              value: "catalog/product/LIVING Y SALA DE ESTAR/MARCOS/",
              text: "catalog/product/LIVING Y SALA DE ESTAR/MARCOS/",
            },
            {
              value: "catalog/product/LIVING Y SALA DE ESTAR/ALFOMBRASFICT/",
              text: "catalog/product/LIVING Y SALA DE ESTAR/ALFOMBRASFICT/",
            },

            {
              value: "catalog/product/MARKETING/",
              text: "catalog/product/MARKETING/",
            },
            {
              value: "catalog/product/MASCOTAS/",
              text: "catalog/product/MASCOTAS/",
            },
            {
              value: "catalog/product/MUEBLES/",
              text: "catalog/product/MUEBLES/",
            },
            {
              value: "catalog/product/MUEBLES/FICTICIOS/",
              text: "catalog/product/MUEBLES/FICTICIOS/",
            },
            {
              value: "catalog/product/MUEBLES/MUEBLES COMEDOR/",
              text: "catalog/product/MUEBLES/MUEBLES COMEDOR/",
            },
            {
              value: "catalog/product/NAVIDAD/",
              text: "catalog/product/NAVIDAD/",
            },
            { value: "catalog/product/NEW/", text: "catalog/product/NEW/" },
            {
              value: "catalog/product/NINO ORG/",
              text: "catalog/product/NINO ORG/",
            },

            {
              value: "catalog/product/NINO ORG/Dormitorio/",
              text: "catalog/product/NINO ORG/Dormitorio/",
            },

            {
              value: "catalog/product/NINO ORG/Dormitorio/Peru/",
              text: "catalog/product/NINO ORG/Dormitorio/Peru/",
            },

            { value: "catalog/product/NINO/", text: "catalog/product/NINO/" },
            {
              value: "catalog/product/OCIO Y TIEMPO LIBRE/",
              text: "catalog/product/OCIO Y TIEMPO LIBRE/",
            },
            {
              value: "catalog/product/ORGANIZADORES/",
              text: "catalog/product/ORGANIZADORES/",
            },
            { value: "catalog/product/PACK/", text: "catalog/product/PACK/" },
            { value: "catalog/product/PACKS/", text: "catalog/product/PACKS/" },
            {
              value: "catalog/product/PROMOCIONALES/",
              text: "catalog/product/PROMOCIONALES/",
            },
            {
              value: "catalog/product/SOLUCIONES DEL HOGAR/",
              text: "catalog/product/SOLUCIONES DEL HOGAR/",
            },
            {
              value: "catalog/product/TERRAZA/",
              text: "catalog/product/TERRAZA/",
            },

            {
              value: "catalog/product/PACK/PACKK/",
              text: "catalog/product/PACK/PACKK/",
            },
            {
              value: "catalog/product/TERRAZA/ACAPULCOYPAPASAN/",
              text: "catalog/product/TERRAZA/ACAPULCOYPAPASAN/",
            },
            {
              value: "catalog/product/TERRAZA/PAPASAN/",
              text: "catalog/product/TERRAZA/PAPASAN/",
            },
            {
              value: "catalog/product/TERRAZA/PAPASANFICT/",
              text: "catalog/product/TERRAZA/PAPASANFICT/",
            },
            {
              value: "catalog/product/TERRAZA/ACAPULCOYPAPASAN/ACAPGRIS/",
              text: "catalog/product/TERRAZA/ACAPULCOYPAPASAN/ACAPGRIS/",
            },
          ],
          addDefault: "NO DEFINED",
        },
      },
      formConfig31: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
      },

      /*table filter*/
      filterConfig: {
        PRODUCTNAME: { label: "Product Name", type: "TEXT", value: "" },
        PRODUCTID: { label: "Product Id", type: "NUMBER", value: "" },
        TAXCLASS_ID: { label: "Key Variant", type: "NUMBER", value: "" },
        ISPACK: {
          label: "IS PACK",
          type: "SELECT",
          value: "",
          values: ["", "YES", "NO"],
        },
        PACKNAME: { label: "Pack Name", type: "TEXT", value: "" },
      },
      /*table data*/
      tabData1: {
        tabs: [
          { id: "tab1", title: "General", content: "" },
          { id: "tab2", title: "Data", content: "" },
          { id: "tab3", title: "Links", content: "" },
          { id: "tab4", title: "Attribute", content: "" },
          { id: "tab5", title: "Variants", content: "" },
          { id: "tab6", title: "Image", content: "" },
          { id: "tab7", title: "Reward Points", content: "" },
          //  { id: "tab10" , title: "Design" , content:""},
        ],
        onChangeTab: this.changeTab1,
        activeTab: "tab1",
      },
      formData5: {
        languageid: {
          label: "Language",
          type: "SELECT",
          value: "",
          values: [],
          getDataDB: {
            apiMethod: "getDetailDomainsByCode2",
            aditionalValues: [{ domainid: "2" }],
          },
        },
      },
      tabData1_1: {
        tabs: [],
        onChangeTab: this.changeTab1_1,
        activeTab: "",
      },
      defaultData: {},
      tableData: {
        columns: [
          { dataIndex: "", title: "" },
          {
            dataIndex: "PRODUCTID",
            title: "Product Id",
            sorter: (a, b) => a.PRODUCTID.length - b.PRODUCTID.length,
          },
          {
            title: "Image",
            dataIndex: "IMAGE",
            render: (text, record) => (
              <div className="mt-2">
                <img
                  width="40px"
                  height="40px"
                  src={record.IMAGE ? getImagePath(record.IMAGE) : defaultImage}
                />
              </div>
            ),
          },
          {
            dataIndex: "PRODUCTNAME",
            title: "Product Name",
            sorter: (a, b) => a.PRODUCTNAME.length - b.PRODUCTNAME.length,
          },
          {
            dataIndex: "PRODUCTMODEL",
            title: "Default SKU",
            sorter: (a, b) => a.PRODUCTMODEL.length - b.PRODUCTMODEL.length,
          },
          {
            dataIndex: "PRICE",
            title: "Price",
            sorter: (a, b) => (a.PRICE ? a.PRICE : 0 - b.PRICE ? b.PRICE : 0),
            render: (text, record) => <span> {toFixed(record.PRICE)} </span>,
          },
          {
            dataIndex: "TAXCLASS_ID",
            title: "Key Variant",
            sorter: (a, b) => a.TAXCLASS_ID.length - b.TAXCLASS_ID.length,
          },

          {
            dataIndex: "SORTORDER",
            title: "Sort Order",
            sorter: (a, b) => a.SORTORDER.length - b.SORTORDER.length,
          },

          {
            dataIndex: "ISPACK",
            title: "IS PACK",
            sorter: (a, b) => a.ISPACK.length - b.ISPACK.length,
          },

          {
            dataIndex: "PACKNAME",
            title: "Pack Name",
            sorter: (a, b) => a.PACKNAME.length - b.PACKNAME.length,
          },

          {
            dataIndex: "STATUS",
            title: "Status",
            sorter: (a, b) => a.STATUS.length - b.STATUS.length,
          },
          {
            dataIndex: "PRODUCTID",
            title: "Action",
            render: (text, record) => (
              <>
                <button
                  className="btn btn-sm btn-info"
                  id={record.PRODUCTID}
                  name="update"
                  onClick={this.loadFormData}
                  type="submit"
                >
                  Edit
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  id={record.PRODUCTID}
                  name="delete"
                  onClick={this.loadFormData}
                  type="submit"
                >
                  Delete
                </button>
              </>
            ),
          },
        ],
        rows: [],
      },
      //change when the customerGroups have been created
      customerGroups: [
        { text: "test1", value: 1 },
        { text: "test2", value: 2 },
      ], //for discounts fields

      attributes: [], //for attribute fields
      options: [], //for options fields
      optionsByoptions: [], //for options fields
      objAttributes: [], //for object Attributes fields
      objOptions: [], //for object Options fields
      objImages: [], //for object images
      /*modal info*/
      buttonsModal1: [
        {
          buttonAction: this.set,
          buttonId: 1,
          buttonClass: "btn btn-outline-primary",
          buttonName: "Add",
        },
      ],
      buttonsModal30: [
        {
          buttonAction: this.setElastic,
          buttonId: 1,
          buttonClass: "btn btn-outline-primary",
          buttonName: "Add",
        },
      ],

      buttonsModal31: [
        {
          buttonAction: this.setImages,
          buttonId: 1,
          buttonClass: "btn btn-outline-primary",
          buttonName: "Add",
        },
      ],

      formConfig5: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
      },
      modalClass5: "modal-sm",
      Modal5: false,
      buttonsModal5: [
        {
          buttonAction: this.publishMenu,
          buttonId: 1,
          buttonClass: "btn btn-outline-primary",
          buttonName: "Publish Products",
        },
      ],
      modalIcon: "fas fa-edit",
      modalTitle: "New Product",

      /*form data*/
      formData1: {
        SORTORDER: {
          label: "Sort Order",
          sizelg: "col-lg-4",
          notRequired: true,
          validationType: "number",
          type: "TEXT",
          value: "",
        },
        TAXCLASS_ID: {
          label: "Key Variant",
          sizelg: "col-lg-4",
          type: "TEXT",
          value: "",
        },
        STATUSID: {
          label: "Status",
          sizelg: "col-lg-4",
          type: "SELECT",
          value: "",
          values: [],
          getDataDB: {
            apiMethod: "getDetailDomainsByCode",
            aditionalValues: [{ domainid: "5" }],
          },
        },

        PACKNAME: {
          label: "Pack Name",
          notRequired: true,
          type: "TEXT",
          value: "",
        },

        PRODUCTMODEL: {
          label: "Default SKU",
          sizelg: "col-lg-6",
          notRequired: true,
          type: "TEXT",
          value: "",
        },
        PRICE: {
          label: "Price",
          sizelg: "col-lg-6",
          validationType: "number",
          type: "TEXT",
          value: "",
        },
        UPC: {
          label: "Upc",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "TEXT",
          value: "",
        },
        EAN: {
          label: "Ean",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "TEXT",
          value: "",
        },
        JAN: {
          label: "Jan",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "TEXT",
          value: "",
        },
        ISBN: {
          label: "Isbn",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "TEXT",
          value: "",
        },
        MPN: {
          label: "GA",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "TEXT",
          value: "",
        },
        PRODUCTLOCATION: {
          label: "Product location",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "TEXT",
          value: "",
        },

        //QUANTITY             : { label: "Quantity"            , validationType:"number" , type: "TEXT",   value: "" } ,
        MINIMUMQUANTITY: {
          label: "Minimum Quantity",
          sizelg: "col-lg-3",
          notRequired: true,
          validationType: "number",
          type: "TEXT",
          value: "",
          defaultvalue: "1",
        },
        DECIMALSTEP: {
          label: "Decimalstep",
          sizelg: "col-lg-3",
          validationType: "number",
          type: "TEXT",
          value: "",
          defaultvalue: "1",
        },
        SUBTRACTSTOCKID: {
          label: "Subtract Stock",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "SELECT",
          value: "15",
          values: [
            { text: "Yes", value: "15" },
            { text: "No", value: "16" },
          ],
        },
        OUTOFSTOCKSTATUSID: {
          label: "Out Of Stock Status",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "SELECT",
          value: "",
          values: [],
          getDataDB: {
            apiMethod: "getDetailDomainsByCode",
            aditionalValues: [{ domainid: "9" }],
          },
        },
        REQUIRESSHIPPING: {
          label: "Requires Shipping",
          sizelg: "col-lg-3",
          notRequired: true,
          type: "RADIO",
          value: [],
          values: [
            { text: "Yes", value: "15" },
            { text: "No", value: "16" },
          ],
        },
        DATEAVAILABLE: {
          label: "Date Available",
          sizelg: "col-lg-3",
          type: "DATEPICKER",
          value: new Date(),
          options: { enableTime: false },
        },
        DIMENSIONL: {
          label: "dimension_l",
          sizelg: "col-lg-3",
          notRequired: true,
          validationType: "number",
          type: "TEXT",
          value: "",
        },
        DIMENSIONW: {
          label: "dimension_w",
          sizelg: "col-lg-3",
          notRequired: true,
          validationType: "number",
          type: "TEXT",
          value: "",
        },
        DIMENSIONH: {
          label: "dimension_h",
          sizelg: "col-lg-3",
          notRequired: true,
          validationType: "number",
          type: "TEXT",
          value: "",
        },
        LENGTHCLASSID: {
          label: "Length Class",
          sizelg: "col-lg-3",
          notRequired: true,
          addDefault: "CHOOSE ONE",
          type: "SELECT",
          value: "",
          values: [],
          getDataDB: {
            apiMethod: "getDetailDomainsByCode",
            aditionalValues: [{ domainid: "10" }],
          },
        },
        WEIGHT: {
          label: "Weight",
          sizelg: "col-lg-3",
          notRequired: true,
          validationType: "number",
          type: "TEXT",
          value: "",
        },
        WEIGHTCLASSID: {
          label: "Weight Class",
          sizelg: "col-lg-3",
          notRequired: true,
          addDefault: "CHOOSE ONE",
          type: "SELECT",
          value: "",
          values: [],
          getDataDB: {
            apiMethod: "getDetailDomainsByCode",
            aditionalValues: [{ domainid: "10" }],
          },
        },
      },
      formConfig1: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
        SecondaryForm: true,
      },

      formData3: {
        MANUFACTURERID: {
          label: "Manufacturer",
          sizelg: "col-lg-6",
          notRequired: true,
          addDefault: "CHOOSE ONE",
          type: "SELECT",
          value: "",
          values: [],
          getDataDB: { apiMethod: "getManufacturers", apiGroup: "manager" },
        },
        CANONICALPATHID: {
          label: "Canonical Path",
          sizelg: "col-lg-6",
          type: "SELECT",
          value: "",
          values: [],
        },
        CATEGORIES: {
          label: "Categories",
          showAll: true,
          filterValue: "",
          validationType: "array",
          type: "CHECKBOX",
          value: [],
          values: [],
          getDataDB: { apiMethod: "getCategories", apiGroup: "manager" },
        },

        //		FILTERS             : { label: "Filters"          , notRequired:true        , type: "CHECKBOX", value: [], values: [] , /*getDataDB: {apiMethod: "getMenus" }*/ } ,
        //		STORES              : { label: "Stores"           , notRequired:true        , type: "CHECKBOX", value: [], values: [] , /*getDataDB: {apiMethod: "getMenus" }*/ } ,
        //		DOWNLOADS           : { label: "Downloads"                                  , type: "CHECKBOX", value: [], values: [] , /*getDataDB: {apiMethod: "getMenus" }*/ } ,
        //RELATEDPRODUCTS     : { label: "Related Products" , notRequired:true        , type: "SEARCHER", value: [], values: [] , getDataDB: {apiMethod: "getProducts", apiGroup:"manager" } } ,
      },

      formConfig3: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
        SecondaryForm: true,
      },

      formData4: {
        POINTS: {
          label: "Points",
          notRequired: true,
          sizelg: "col-lg-6",
          validationType: "number",
          type: "TEXT",
          value: "",
        },
      },
      formConfig4: {
        pageWidth: "card col-md-12 col-lg-12 col-xl-12",
        groupingRows: 1,
      },

      /*support variables*/
      currentId: null,
      formAction: "set",
      deleteMessage: "Are you sure to delete this item?",
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    var pageId = this.props.match.params.pageId;
    try {
      let resp;
      resp = await callApi("manager", "getProducts");
      //resp = {rows:[]}
      const tableData = this.state.tableData;
      tableData.rows = resp.rows;
      this.setState({ originalTableData: this.state.tableData });

      await preloadForm(this.state.formData1);
      await preloadForm(this.state.formData3);

      await preloadForm(this.state.formData4);
      await preloadForm(this.state.formData5);

      await preloadForm(this.state.formData30);


       
      let objImageFolder = await listImages("catalog/product/", "");

      const fvalues = this.state.formData31.foldername.values
 
      if (objImageFolder.items){
        for (let i in objImageFolder.items){
          let item = objImageFolder.items[i]
          if (item.type==="folder"){

            let pos = find(fvalues, item.name, "value");
            if (pos<0){
            console.log("item:",item.type, item.name)
              console.log("no existe!!!")
              fvalues.push({text:item.name, value:item.name })
            }

          }
        }
      }

      const formData3 = this.state.formData3;
      const formData30 = this.state.formData30;
      formData3.CANONICALPATHID.values = formData3.CATEGORIES.values;
      formData3.CATEGORIES.originalValues = formData3.CATEGORIES.values;

      formData30["CATEGORIES"]["values"].push({
        text: "CHOOSE ONE",
        value: "",
      });
      for (let c in formData3.CATEGORIES.values) {
        let key = formData3.CATEGORIES.values[c];
        if (key.CATEGORYLEVEL === 1 || key.CATEGORYLEVEL === 2) {
          formData30["CATEGORIES"]["values"].push(key);
        }
      }

      formData30["CATEGORIES"]["originalValues"] = formData30.CATEGORIES.values;

      //	formData3.CATEGORIES.filterValue = ""
      console.log(formData3);
      resp = await callApi("manager", "getAttributes");
      let attributes = [];
      for (let key in resp.rows) {
        attributes.push({
          value: resp.rows[key].ATTRIBUTEID,
          text: resp.rows[key].NAME,
        });
      }
      this.setState({ attributes: attributes });

      resp = await callApi("manager", "getOptions");
      let respOpt = await callApi("manager", "getOptionsAndOptions");
      let options = [];

      let optionsByoptions = [];

      for (let op in respOpt.options) {
        optionsByoptions.push({
          optionid: respOpt.options[op].OPTIONID,
          value: respOpt.options[op].OPTIONVALUE,
          image: respOpt.options[op].IMAGEPATH,
        });
      }
      for (let o in resp.rows) {
        var haveImage = true;
        var values = [
          {
            label: "NONE",
            value: "",
            image: "",
          },
        ];
        for (let op in respOpt.options) {
          if (respOpt.options[op].OPTIONID == resp.rows[o].OPTIONID) {
            values.push({
              label: respOpt.options[op].OPTIONVALUE,
              value: respOpt.options[op].OPTIONVALUE,
              image: getImagePath(respOpt.options[op].IMAGEPATH),
            });
          }
          if (haveImage) {
            if (respOpt.options[op].OPTIONID == resp.rows[o].OPTIONID) {
              if (respOpt.options[op].IMAGEPATH) {
                haveImage = true;
              } else {
                haveImage = false;
              }
            }
          }
        }
        options.push({
          value: resp.rows[o].OPTIONID,
          values: values,
          text: resp.rows[o].OPTIONNAME,
          type: resp.rows[o].OPTIONTYPE,
          subdomain: resp.rows[o].SUBDOMAIN,
          haveImage,
        });
      }

      var optionIds = [];
      options.map(function (item) {
        if (item.subdomain == "CHOOSE") {
          optionIds.push({ optionId: item.value, value: "" });
        }
      });

      let languages = await getLanguages();

      var optionnames = [];
      languages.map(function (lng) {
        optionnames.push({ id: lng.id, name: lng.name, value: "" });
      });

      let defaultData = {
        optionnames: optionnames,
        optionprice: "",
        optionquantity: "0",
        optionimages: "",
        imagesquantity: "",
      };

      this.setState({
        ...this.state,
        languages: languages,
        defaultData: defaultData,
        optionsByoptions: optionsByoptions,
        optionIds: optionIds,
        options: options,
      });

      for (let key in languages) {
        let formData = {
          LANGUAGEID: {
            label: "Language id",
            type: "HIDDEN",
            value: languages[key].id,
            disabled: true,
          },
          NAME: {
            label: "Product Name",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          DESCRIPTION: {
            label: "Product Description",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          KEYWORD: {
            label: "Keyword",
            sizelg: "col-lg-3",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          SEOH1: {
            label: "Seo H1",
            sizelg: "col-lg-3",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          SEOH2: {
            label: "Seo H2",
            sizelg: "col-lg-3",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          SEOH3: {
            label: "Seo H3",
            sizelg: "col-lg-3",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          METATAGTITLE: {
            label: "Meta Tag Title",
            sizelg: "col-lg-4",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          METATAGDESCRIPTION: {
            label: "Meta Tag Description",
            sizelg: "col-lg-4",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          METATAGKEYWORD: {
            label: "Meta Tag Keyword",
            sizelg: "col-lg-4",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
          PRODUCTTAGS: {
            label: "Product TAgs",
            notRequired: true,
            type: "TEXT",
            value: "",
          },
        };
        let formConfig2 = {
          pageWidth: "card col-md-12 col-lg-12 col-xl-12",
          groupingRows: 1,
          SecondaryForm: true,
          formId: languages[key].id,
        };
        this.setState({ ["formData2" + languages[key].id]: formData });
        this.setState({ ["formConfig2" + languages[key].id]: formConfig2 });
      }

      this.refreshFormData();
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ isLoading: false });
    }
  };
  /*Form Functions*/

  /*show form to select language to publish*/
  showpublishMenu = async (event) => {
    this.setState({ ...this.state, modal5: true });
    let loadData5 = await clearformData(this.state.formData5);
  };

  changeFilter3 = async (event) => {
    let _data = findObjName(event);
    let data = _data.split("__");
    var originalValues = [];
    if (data.length == 1) {
      originalValues = this.state.formData3[data].originalValues;
    }

    var resp = await changeFilter(event, originalValues, this.state.formData3);
    this.setState({ formData3: resp });
    this.refreshFormData(event);
  };

  /*publish json products list*/
  generateEntireData = async (event) => {
    this.setState({ ...this.state, isLoading: true });
    try {
      let resp = await callApi("manager", "getCountProductsToExport");

      let parts = Math.ceil(resp.total / 3);
      var rows = [];
      for (let i = 1; i <= resp.total; i = i + parts) {
        let data = { from: i, to: i + parts - 1 };
        console.log("data", data);
        let resp = await callApi("manager", "getPartProductsToExport", data);
        rows = rows.concat(resp.rows);
      }

      this.setState({ ...this.state, allData: rows });

      //let resp = await callApi ( "manager","getAllProductsToExport" )
      /*
			if (resp.success){
		  	this.setState({  ...this.state, allData:resp.rows})
		  }else{
		  	this.setState({  ...this.state, allData:[]})
		  }*/
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ ...this.state, isLoading: false, modal5: false });
    }
  };

  /*publish json products list*/
  generateEntireComboData = async (event) => {
    this.setState({ ...this.state, isLoading: true });
    try {
      let resp = await callApi("manager", "getAllCombosToExport");

      if (resp.success) {
        this.setState({ ...this.state, allDataCombo: resp.rows });
      } else {
        this.setState({ ...this.state, allDataCombo: [] });
      }
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  /*charge data from a selected user*/
  loadFormData = async (event) => {
    this.setState({ ...this.state, isLoading: true });
    try {
      var buttonsModal1;
      var modalIcon;
      var modalTitle;
      var formAction;
      var itemId;
      var modalClass = "";
      if (event.target.name === "set") {
        buttonsModal1 = [
          {
            buttonAction: this.set,
            buttonId: 1,
            buttonClass: "btn btn-outline-primary",
            buttonName: "Add",
          },
        ];
        modalIcon = "fas fa-plus";
        modalTitle = "New Product";
        formAction = "set";
        itemId = null;
        modalClass = "modal-full-height modal-lg";
        let loadData1 = await clearformData(this.state.formData1);
        let loadData3 = await clearformData(this.state.formData3);
        let loadData4 = await clearformData(this.state.formData4);

        this.setState({ objAttributes: [] });
        this.setState({ objOptions: [] });
        this.setState({ objImages: [] });

        for (let key in this.state.languages) {
          let langId = this.state.languages[key].id;
          let langName = this.state.languages[key].name;
          let formLanguage = this.state["formData2" + langId];
          let loadData2 = await clearformData(formLanguage);
          loadData2.LANGUAGEID.value = langId;
          this.setState({ ["formData2" + langId]: loadData2 });
        }
        const tabData1 = this.state.tabData1;
        tabData1["activeTab"] = "tab1";
        this.setState({ tabData1: tabData1 });

        this.refreshFormData(event);
      } else if (event.target.name === "update") {
        buttonsModal1 = [
          {
            buttonAction: this.update,
            buttonId: 1,
            buttonClass: "btn btn-outline-info",
            buttonName: "Edit",
          },
        ];
        modalIcon = "fas fa-edit";
        modalTitle = "Edit Product";
        itemId = event.target.id;
        modalClass = "modal-full-height modal-lg";
        let resp = await callApi("manager", "getProductById", {
          productid: itemId,
        });
        let loadData = await mergeFormData(this.state.formData1, resp.PRODUCT);
        loadData = await mergeFormData(this.state.formData3, resp.PRODUCT);
        loadData = await mergeFormData(this.state.formData4, resp.PRODUCT);

        let rows = [];
        let _tmprows = [];

        var _tmpAttIds = [];

        let firstLang1 = -1;
        for (let l in resp.PRODUCT.ATTRIBUTES) {
          if (firstLang1 < 0) {
            firstLang1 = resp.PRODUCT.ATTRIBUTES[l].LANGUAGEID;
          }
        }

        for (let _key in resp.PRODUCT.ATTRIBUTES) {
          let _obj = resp.PRODUCT.ATTRIBUTES[_key];
          if (firstLang1 == _obj.LANGUAGEID) {
            let defLangs = await getLanguages();
            let _languages = [];
            defLangs.map(function (lng) {
              _languages.push({
                languageid: lng.id,
                languagetitle: lng.name,
                languagevalue: "",
              });
            });

            let newobj = {
              id: parseInt(_key),
              attributeId: parseInt(_obj.ID),
              languages: _languages,
              rowId: parseInt(_key) + 1,
            };
            _tmpAttIds.push(newobj);
          }
        }

        for (let key in resp.PRODUCT.ATTRIBUTES) {
          let _obj = resp.PRODUCT.ATTRIBUTES[key];

          let finded = false;
          for (let pos in _tmpAttIds) {
            if (!finded) {
              if (_tmpAttIds[pos].attributeId == _obj.ID) {
                for (let lan in _tmpAttIds[pos].languages) {
                  if (
                    _tmpAttIds[pos].languages[lan].languageid ==
                      _obj.LANGUAGEID &&
                    _tmpAttIds[pos].languages[lan].languagevalue == ""
                  ) {
                    _tmpAttIds[pos].languages[lan].languagevalue =
                      _obj.ATRIBUTEVALUE;
                    finded = true;
                  } else {
                    //	console.log("no va")
                  }
                }
              }
            }
          }
        }

        this.setState({ objAttributes: _tmpAttIds });

        /*
	  		for (let key in resp.PRODUCT.ATTRIBUTES ){
	  			let _obj =resp.PRODUCT.ATTRIBUTES[key]
	  			if (_tmprows.indexOf(_obj.ID ) < 10){
	  				_tmprows.push(_obj.ID)
						let _languages=[]
						
						let defLangs= await getLanguages()
			 
				 
						defLangs.map(function (lng) {
							_languages.push({ languageid:lng.id , languagetitle:lng.name, languagevalue: ""})
						}); 
			
						for (let key2 in resp.PRODUCT.ATTRIBUTES){
							
							if (_obj.ID === resp.PRODUCT.ATTRIBUTES[key2].ID ){
								
								let pos = find (_languages, resp.PRODUCT.ATTRIBUTES[key2].LANGUAGEID, "languageid"  )
								if (pos >=0){
									_languages[pos].languagevalue = resp.PRODUCT.ATTRIBUTES[key2].ATRIBUTEVALUE
								} 
							} 
						}
						let obj= {
							attributeId : parseInt(_obj.ID),
							languages		: _languages,
							rowId 			: parseInt(key)+1
						}
						rows.push(obj)
					}
				}
				this.setState({ objAttributes  :rows });
				*/

        rows = [];
        for (let key in resp.PRODUCT.IMAGES) {
          let _obj = resp.PRODUCT.IMAGES[key];
          let obj = {
            rowId: parseInt(key) + 1,
            path: _obj.PATH,
            sortorder: _obj.SORTORDER,
          };
          rows.push(obj);
        }

        this.setState({ objImages: rows });
        rows = [];

        var firstLang = 0;
        var row = 0;

        var optionIds = [];
        this.state.options.map(function (item) {
          if (item.subdomain == "CHOOSE") {
            optionIds.push({ optionId: item.value, value: "" });
          }
        });
        var originalLanguages = this.state.languages;
        for (let key in resp.PRODUCT.PRODUCTOPTIONS) {
          let _obj = resp.PRODUCT.PRODUCTOPTIONS[key];
          let pos = find(rows, _obj.SKU, "optionsku");
          if (pos < 0) {
            var langNames = [];
            let obj = {
              rowId: row,
              optionrequiredid: _obj.ISREQUIREDID,
              optionquantity: _obj.QUANTITY + "",
              optionprice: _obj.PRICE,
              imagesquantity: _obj.IMAGESQUANTITY,
              optionimages: _obj.IMAGESPATH,
              optionsku: _obj.SKU,
            };
            resp.PRODUCT.PRODUCTOPTIONS.map(function (op) {
              let pos = find(originalLanguages, op.LANGUAGEID, "id", "name");
              if (op.SKU == _obj.SKU) {
                for (let o in optionIds) {
                  if (op.OPTIONID == optionIds[o].optionId) {
                    optionIds[o].value = op.OPTIONVALUE;
                  }
                }
                let langName = findValueById(
                  originalLanguages,
                  op.LANGUAGEID,
                  "id",
                  "name"
                );
                if (langName != "NOT FOUND") {
                  let pos = find(langNames, op.LANGUAGEID, "id");
                  if (pos < 0) {
                    langNames.push({
                      id: op.LANGUAGEID,
                      name: langName,
                      value: op.SPECIFICNAME,
                    });
                  }
                }
              }
            });
            originalLanguages.map(function (lng) {
              let pos = find(langNames, lng.id, "id");
              if (pos < 0) {
                langNames.push({ id: lng.id, name: lng.name, value: "" });
              }
            });
            obj["optionnames"] = langNames;

            for (let o in optionIds) {
              obj["optionvalue_" + optionIds[o].optionId] = optionIds[o].value;
            }
            this.state.options.map(function (option) {
              if (option.subdomain == "CHOOSE") {
                let values = [];
                option.values.map(function (_item) {
                  let item = _item;
                  item["rowId"] = row;
                  item["optionId"] = option.value;
                  item["optionLabel"] = option.text;
                  item["haveImage"] = option.haveImage;
                  item["target"] = {
                    name:
                      "objOptions_optionoptionid_" + row + "_" + option.value,
                    value: _item.value,
                  };
                  values.push(item);
                });
                if (values.length > 0) {
                  obj["optionvalues_" + option.value] = values;
                }
              }
            });
            row++;
            rows.push(obj);
          }
        }
        if (rows.length > 0) {
          let defaultData = {
            optionnames: rows[0].optionnames,
            optionprice: rows[0].optionprice,
            optionquantity: rows[0].optionquantity,
            optionimages: rows[0].optionimages,
            imagesquantity: rows[0].imagesquantity,
          };
          this.setState({ objOptions: rows, defaultData: defaultData });
        }

        for (let key in resp.PRODUCT.PRODUCTDESCS) {
          let field = resp.PRODUCT.PRODUCTDESCS[key];
          let formLanguage = this.state["formData2" + field.LANGUAGEID];
          if (formLanguage) {
            let fields = Object.keys(formLanguage);
            for (let key in fields) {
              formLanguage[fields[key]].value =
                field[fields[key]] === null ? "" : field[fields[key]];
            }
          } else {
            console.log(">>>>language not founded");
          }
        }

        formAction = "update";
        modalClass = "modal-full-height modal-lg";

        this.refreshFormData();
      } else if (event.target.name === "delete") {
        buttonsModal1 = [
          {
            buttonAction: this.delete,
            buttonId: 1,
            buttonClass: "btn btn-outline-danger",
            buttonName: "Delete",
          },
        ];
        modalIcon = "fas fa-trash";
        modalTitle = "Delete Product";
        itemId = event.target.id;
        formAction = "delete";
        modalClass = "";
        this.refreshFormData(event);
      }

      this.setState({
        ...this.state,
        modal1: true,
        isLoading: false,
        currentId: itemId,
        modalClass: modalClass,
        formAction: formAction,
        buttonsModal1: buttonsModal1,
        modalIcon: modalIcon,
        modalTitle: modalTitle,
      });
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
    }
  };
  //refresh form
  refreshFormData = (event) => {
    var tabData1 = this.state.tabData1;
    var tabData1_1 = this.state.tabData1_1;
    var tabs = [];
    var activeTab;

    for (let key in this.state.languages) {
      let langId = this.state.languages[key].id;
      let langName = this.state.languages[key].name;
      let formLanguage = this.state["formData2" + langId];

      if (key == 0) {
        activeTab = "tab1_" + langId;
      }

      let tab = {
        id: "tab1_" + langId,
        title: langName,
        content: (
          <Form
            formData={this.state["formData2" + langId]}
            changeHandler={this.changeHandler2}
            formConfig={this.state["formConfig2" + langId]}
          />
        ),
      };
      tabs.push(tab);
    }
    tabData1_1["tabs"] = tabs;
    if (tabData1_1["activeTab"] == "") {
      tabData1_1["activeTab"] = activeTab;
    }

    tabData1.tabs[0]["content"] = <Tab tabData={this.state.tabData1_1} />;
    tabData1.tabs[1]["content"] = (
      <Form
        formData={this.state.formData1}
        container="formData1"
        changeHandler={this.changeHandler}
        formConfig={this.state.formConfig1}
      />
    );
    tabData1.tabs[2]["content"] = (
      <Form
        formData={this.state.formData3}
        container="formData3"
        changeHandler={this.changeHandler}
        changeFilter={this.changeFilter3}
        formConfig={this.state.formConfig3}
      />
    );
    tabData1.tabs[3]["content"] = (
      <Attributes
        attributes={this.state.attributes}
        objAttributes={this.state.objAttributes}
        changeHandler={this.changeHandler3}
        container="objAttributes"
        addRow={this.addRow}
        delRow={this.delRow}
      />
    );

    tabData1.tabs[4]["content"] = (
      <Options
        options={this.state.options}
        optionsByoptions={this.state.optionsByoptions}
        objOptions={this.state.objOptions}
        defaultData={this.state.defaultData}
        changeHandler={this.changeHandler3}
        manageImage={this.manageImageOption}
        container="objOptions"
        addRow={this.addRow}
        delRow={this.delRow}
      />
    );
    tabData1.tabs[5]["content"] = (
      <Images
        objImages={this.state.objImages}
        changeHandler={this.changeHandler3}
        manageImage={this.manageImage}
        manageImageFolder={this.manageImageFolder}
        container="objImages"
        addRow={this.addRow}
        delRow={this.delRow}
      />
    );

    tabData1.tabs[6]["content"] = (
      <Form
        formData={this.state.formData4}
        container="formData4"
        changeHandler={this.changeHandler}
        formConfig={this.state.formConfig4}
      />
    );

    this.setState({ tabData1_1: tabData1_1 });
    this.setState({ tabData1: tabData1 });
  };
  //add new attribute row

  addRow = (event) => {
    if (event) {
      var container;
      if (event.target.name) {
        container = event.target.name.split("_")[0];
      } else {
        container = event.target.parentNode.name.split("_")[0];
      }
      var languages = [];
      let objContainer = this.state[container];

      let rowId = 0;
      for (let key in objContainer) {
        if (rowId < objContainer[key].rowId) {
          rowId = objContainer[key].rowId;
        }
      }
      rowId = rowId + 1;

      let objRow = {};
      if (container === "objAttributes") {
        for (let key in this.state.languages) {
          languages.push({
            languageid: this.state.languages[key].id,
            languagetitle: this.state.languages[key].name,
            languagevalue: "",
          });
        }
        objRow = {
          attributeId: this.state.attributes[0].value,
          languages: languages,
          rowId: rowId,
        };
      } else if (container === "objImages") {
        objRow = {
          rowId: rowId,
          path: "",
          sortorder: "",
        };
      } else if (container === "objOptions") {
        var languages = [];

        this.state.languages.map(function (item) {
          languages.push({ id: item.id, name: item.name, value: "" });
        });

        objRow = {
          rowId: rowId,
          optionquantity: "0",
          optionprice: "",
          imagesquantity: "",
          optionimages: "",
          optionsku: "",
          optionnames: languages,
        };

        this.state.options.map(function (option) {
          if (option.subdomain == "CHOOSE") {
            let values = [];
            option.values.map(function (_item) {
              let item = _item;
              item["rowId"] = rowId;
              item["optionId"] = option.value;
              item["optionLabel"] = option.text;
              item["haveImage"] = option.haveImage;
              item["target"] = {
                name: "objOptions_optionoptionid_" + rowId + "_" + option.value,
                value: _item.value,
              };
              values.push(item);
            });
            if (values.length > 0) {
              objRow["optionvalue_" + option.value] = "";
              objRow["optionvalues_" + option.value] = values;
            }
          }
        });
      }

      objContainer.push(objRow);
      this.setState({ objContainer: objContainer });
      this.refreshFormData(event);
    }
  };

  delRow = (event) => {
    if (event) {
      var rowId;
      var container;
      if (event.target.name) {
        container = event.target.name.split("_")[0];
        rowId = event.target.name.split("_")[1];
      } else {
        container = event.target.parentNode.name.split("_")[0];
        rowId = event.target.parentNode.name.split("_")[1];
      }
      var objContainer = this.state[container];
      var pos = 0;
      for (let key in objContainer) {
        if (objContainer[key].rowId == rowId) {
          pos = key;
        }
      }
      objContainer.splice(pos, 1);
      this.setState({ [container]: objContainer });
      this.refreshFormData(event);
    }
  };

  ///filter table
  filterHandler = async (event) => {
    let resp = await filterHandler(
      event,
      this.state.filterConfig,
      this.state.originalTableData
    );

    if (resp.filterConfig) {
      this.setState({ filterConfig: resp.filterConfig });
    }
    if (resp.tableData) {
      this.setState({ tableData: resp.tableData });
    }
  };

  //get change tabs
  changeTab1 = (event) => {
    const tabData1 = this.state.tabData1;
    tabData1["activeTab"] = event.target.id;
    this.setState({ tabData1: tabData1 });
  };
  //get change tabs for general tab
  changeTab1_1 = (event) => {
    const tabData1_1 = this.state.tabData1_1;
    tabData1_1["activeTab"] = event.target.id;
    this.setState({ tabData1_1: tabData1_1 });
    var tabData1 = this.state.tabData1;
    tabData1.tabs[0]["content"] = <Tab tabData={this.state.tabData1_1} />;
    this.setState({ tabData1: tabData1 });
    this.refreshFormData(event);
  };
  //get value fields static form
  changeHandler = async (event) => {
    let data = event.target.name.split("__");

    var container = data[0];
    var field = data[1];
    var _event = {
      target: { name: field, id: field, value: event.target.value },
    };
    let resp = await validateForm(this.state[container], toast, _event);
    this.setState({ [container]: resp.formData });
    this.refreshFormData(event);
  };

  //get dinamyc fields form
  changeHandler2 = async (event) => {
    var id = event.target.id.split("_")[1];
    var _form = this.state["formData2" + id];
    let resp = await validateForm(_form, toast, event);
    this.setState({ ["formData2" + id]: resp.formData });
    this.refreshFormData(event);
  };

  changeHandler5 = async (event) => {
    let resp = await validateForm(this.state.formData5, toast, event);
    this.setState({ formData5: resp.formData });
  };

  changeHandler30 = async (event) => {
    let resp = await validateForm(this.state.formData30, toast, event);
    this.setState({ formData30: resp.formData });
  };

  changeHandler31 = async (event) => {
    let resp = await validateForm(this.state.formData31, toast, event);
    this.setState({ formData31: resp.formData });
  };

  //get change Attrib / Discount / Specials / Options
  changeHandler3 = (event) => {
    try {
      var data = event.target.name.split("__");
      var isMaster = false;
      var container;
      var field;
      var rowId;
      var objContainer;
      var langOpId = 0;
      if (data.length > 1) {
        let _data = data[0].split("_");
        container = _data[0];
        field = data[1];
        rowId = _data[1];

        if (data.length > 2) {
          langOpId = data[2];
        }
        objContainer = this.state[container];
      } else {
        data = event.target.name.split("_");
        container = data[0];
        field = data[1];
        rowId = data[2];
        objContainer = this.state[container];
      }

      if (field === "attributevalue") {
        var _languageId = data[3];
        for (let key in objContainer) {
          if (objContainer[key].rowId == rowId) {
            let _obj = objContainer[key];
            let _languages = _obj.languages;
            for (let key2 in _languages) {
              if (_languageId == _languages[key2].languageid) {
                _languages[key2]["languagevalue"] = event.target.value;
              }
            }
            _obj["languages"] = _languages;
            objContainer[key] = _obj;
          }
        }
      } else {
        if (rowId == "master") {
          isMaster = true;
        }
        for (let key in objContainer) {
          if (objContainer[key].rowId == rowId || isMaster) {
            let obj = objContainer[key];

            if (Array.isArray(objContainer[key][field])) {
              for (let l in objContainer[key][field]) {
                if (objContainer[key][field][l].id == langOpId) {
                  objContainer[key][field][l]["value"] = event.target.value;
                }
              }
            } else {
              objContainer[key][field] = event.target.value;
            }
          }
        }
      }
      if (field === "optionoptionid") {
        let show = false;
        let optionId = data[3];
        for (let key in objContainer) {
          if (objContainer[key].rowId == rowId) {
            let obj = objContainer[key];
            obj["optionvalue_" + optionId] = event.target.value;
          }
        }
      }

      if (isMaster) {
        var defaultData = this.state.defaultData;
        Object.keys(defaultData).map(function (item) {
          if (item == field) {
            if (Array.isArray(defaultData[field])) {
              for (let l in defaultData[field]) {
                if (defaultData[field][l].id == langOpId) {
                  defaultData[field][l]["value"] = event.target.value;
                }
              }
            } else {
              defaultData[field] = event.target.value;
            }
          }
        });
        this.setState({ defaultData: defaultData, [container]: objContainer });
      } else {
        this.setState({ [container]: objContainer });
      }

      this.customvalidatation(event);
      this.refreshFormData(event);
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    }
  };

  //show/hide modal
  toggleModal = (nr) => () => {
    console.log("n");
    this.setState({ ["modal" + nr]: !this.state["modal" + nr] });
  };

  customvalidatation = (event) => {
    //var isValidAttributes  = true
    var isValidDiscounts = true;
    var isValidSpecials = true;
    var isValidOptions = true;
    var isValid = true;
    var isValidImages = true;
    var isValidProductName = true;
    if (!event) {
      var resp = validLanguageName(
        this.state.languages,
        this.state,
        "formData2",
        ["NAME", "DESCRIPTION"],
        toast
      );
      if (!resp.isValid) {
        for (let key in this.state.languages) {
          let formData = this.state["formData2" + this.state.languages[key].id];
          for (let f in resp.invalidFields) {
            formData[resp.invalidFields[f]].isInvalid = true;
          }
          this.setState({
            ["formData2" + this.state.languages[key].id]: formData,
          });
        }
      }
      isValidProductName = resp.isValid;
    }

    /*
		this.state.objAttributes.map(function (item) {
			
			for (let key in item.languages){
				if  (item.languages[key].languagevalue=="" ){
					isValidAttributes = false
					item.languages[key].isInvalid_language=true
				}else{
					item.languages[key].isInvalid_language=false
				}
			}
		}); 
		if (!isValidAttributes && !event ){
			showError(toast,"Check Attributes");	
		}
		*/
    let messages = "VARIANT ERRORS: ";

    if (!event) {
      var _objOptions = this.state.objOptions;

      if (_objOptions.length == 0) {
        showError(toast, messages + " CHOOSE AT LEAST ONE VARIANT");
      } else {
        var optionIds = this.state.optionIds;
        _objOptions.map(function (item, i) {
          var minOption = false;

          for (let o in optionIds) {
            if (item["optionvalue_" + optionIds[o].optionId] !== "") {
              minOption = true;
            }
          }

          if (!minOption) {
            isValidOptions = false;
            messages += "Chose one option at least option" + ",row:" + i;
            for (let o in optionIds) {
              item["isInvalid_optionoptionid_" + optionIds[o].optionId] = true;
            }
          } else {
            for (let o in optionIds) {
              item["isInvalid_optionoptionid_" + optionIds[o].optionId] = false;
            }
          }

          if (!validateNumber(item.optionquantity)) {
            isValidOptions = false;
            item.isInvalid_optionquantity = true;
            messages += " Error in quantity" + ",row:" + i;
          } else {
            item.isInvalid_optionquantity = false;
          }
          if (!validateNumber(item.optionprice)) {
            isValidOptions = false;
            item.isInvalid_optionprice = true;
            messages += " Error in price" + ",row:" + i;
          } else {
            item.isInvalid_optionprice = false;
          }

          if (item.optionsku == "") {
            isValidOptions = false;
            item.isInvalid_optionsku = true;
            messages += " Error in sku" + ",row:" + i;
          } else {
            item.isInvalid_optionsku = false;
          }
        });
      }
      this.setState({ objOptions: _objOptions });
      this.refreshFormData();
    }

    if (!isValidOptions && !event) {
      showError(toast, messages);
    }

    this.state.objImages.map(function (item) {
      if (!validateNumber(item.sortorder)) {
        item.isInvalid_sortorder = true;
        isValidImages = false;
      } else {
        item.isInvalid_sortorder = false;
      }
      if (item.path == "") {
        item.isInvalid_path = true;
        isValidImages = false;
      } else {
        item.isInvalid_path = false;
      }
    });
    if (!isValidImages && !event) {
      showError(toast, "Check Images");
    }

    //if (!isValidDiscounts || !isValidSpecials || !isValidOptions || !isValidAttributes || !isValidImages || !isValidProductName){
    if (
      !isValidDiscounts ||
      !isValidSpecials ||
      !isValidOptions ||
      !isValidImages ||
      !isValidProductName
    ) {
      isValid = false;
    }
    return isValid;
  };

  //add new
  set = async (event) => {
    this.execute(event, "SET");
  };
  //edit
  update = async (event) => {
    this.execute(event, "UPDATE");
  };
  //delete
  delete = async (event) => {
    this.execute(event, "DELETE");
  };
  //manage image
  manageImage = async (event) => {
    try {
      this.setState({ isLoading: true });
      var data = findObjName(event).split("_");
      let action = data[0];
      if (action === "manageImage") {
        let objectName = data[1];
        let objImage = await listImages("", objectName);
        this.setState({ objImage: objImage });
        let imageManager = (
          <ImageManager
            objImage={this.state.objImage}
            manageImage={this.manageImage}
          />
        );
        this.setState({ imageManager: imageManager, modal2: true });

        this.refreshFormData(event);
      } else {
        var objImage = await manageImage(event, this.state.objImage);
        this.setState({ objImage: objImage });
        let imageManager = (
          <ImageManager
            objImage={this.state.objImage}
            manageImage={this.manageImage}
          />
        );
        this.setState({ imageManager: imageManager });

        if (objImage.selected.length === 1) {
          let field = objImage.objectName;
          var objImages = this.state.objImages;
          for (let i in objImages) {
            if (objImages[i].rowId == field) {
              objImages[i].path = objImage.selected[0];
            }
          }
          this.setState({ modal2: false });
        }
        this.refreshFormData(event);
      }
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  manageImageFolder = async (event) => {
    try {
      this.setState({ isLoading: true });
      var _data = findObjName(event);
      var data = _data.split("_");
      let action = data[0];
      if (action === "manageImageFolder") {
        let objectName = data[1];
        console.log(this.state.objImages, objectName);
        let pos = find(this.state.objImages, objectName, "rowId");
        console.log(pos);

        let currFolder = this.state.objImages[pos].path;

        let objFolder = await listImageFolders("", objectName, []);
        this.setState({ objFolder: objFolder });
        let folderManager = (
          <FolderManager
            objFolder={this.state.objFolder}
            manageFolder={this.manageImageFolder}
          />
        );
        this.setState({ folderManager: folderManager, modal2B: true });
      } else {
        var objFolder = await manageImageFolder(event, this.state.objFolder);
        this.setState({ objFolder: objFolder });
        let folderManager = (
          <FolderManager
            objFolder={this.state.objFolder}
            manageFolder={this.manageImageFolder}
          />
        );
        this.setState({ folderManager: folderManager });

        if (objFolder.value) {
          let field = objFolder.objectName;

          var objImages = this.state.objImages;
          for (let i in objImages) {
            if (objImages[i].rowId == field) {
              objImages[i].path = "/" + objFolder.value;
            }
          }

          //	var formData = this.state.formData
          //	formData[field].value = objFolder.value
          this.setState({ modal2B: false });
        }
        this.refreshFormData(event);
      }
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  //manage image
  manageImageOption = async (event) => {
    try {
      this.setState({ isLoading: true });
      var data = findObjName(event).split("_");
      let action = data[0];

      if (action === "manageImage") {
        let objectName = data[1];
        let objImage = await listImages("", objectName);
        this.setState({ objImage: objImage });
        let imageManager2 = (
          <ImageManager
            objImage={this.state.objImage}
            manageImage={this.manageImageOption}
          />
        );
        this.setState({ imageManager2: imageManager2, modal3: true });

        this.refreshFormData(event);
      } else {
        var objImage = await manageImage(event, this.state.objImage);
        this.setState({ objImage: objImage });
        let imageManager2 = (
          <ImageManager
            objImage={this.state.objImage}
            manageImage={this.manageImageOption}
          />
        );
        this.setState({ imageManager2: imageManager2 });

        if (objImage.selected.length === 1) {
          let field = objImage.objectName;
          var objOptions = this.state.objOptions;
          for (let i in objOptions) {
            if (objOptions[i].rowId == field) {
              objOptions[i].optionimage = objImage.selected[0];
            }
          }
          this.setState({ modal3: false });
        }
        this.refreshFormData(event);
      }
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  /*Elastic manager*/
  setElastic = async (event, actionType) => {
    try {
      var resp = validateRole(this.props.location.pathname);
      if (resp) {
        let resp1 = await validateForm(this.state.formData30, toast);
        var respValid = resp1.isValid;
        if (respValid) {
          let _formData30 = this.state.formData30;
          _formData30 = parseFormData(_formData30);

          console.log(_formData30);

          let resp = callApi("manager", "setFilterProducts", _formData30);

          showMessage(toast, "PROCESS LAUNCHED!");
          this.setState({ ...this.state, modal30: false });
          /*
					if (resp.success){
						showMessage(toast,resp.message);
					 		
						   this.setState({...this.state,modal30:false });
						   
					}else{
						showError(toast,resp.message);
					}*/
        }
      } else {
        showError(toast, "Role not allowed to make changes");
      }
    } catch (err) {
      showError(toast, "Internal Error");
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  /*images folder*/
  setImages = async (event, actionType) => {
    try {
      var resp = validateRole(this.props.location.pathname);
      if (resp) {
        let resp1 = await validateForm(this.state.formData31, toast);
        var respValid = resp1.isValid;
        if (respValid) {
          let _formData31 = this.state.formData31;
          _formData31 = parseFormData(_formData31);

          console.log(_formData31);

          let resp = callApi("manager", "setImages", _formData31);

          showMessage(toast, "PROCESS LAUNCHED!");
          this.setState({ ...this.state, modal31: false });
          /*
					if (resp.success){
						showMessage(toast,resp.message);
					 		
						   this.setState({...this.state,modal30:false });
						   
					}else{
						showError(toast,resp.message);
					}*/
        }
      } else {
        showError(toast, "Role not allowed to make changes");
      }
    } catch (err) {
      showError(toast, "Internal Error");
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  /*ABM manager*/
  execute = async (event, actionType) => {
    try {
      var resp = validateRole(this.props.location.pathname);
      if (resp) {
        var respValid = true;

        if (actionType === "DELETE") {
          respValid = true;
        } else {
          let resp1 = await validateForm(this.state.formData1, toast);
          resp1 = resp1.isValid;
          let resp3 = await validateForm(this.state.formData3, toast);
          resp3 = resp3.isValid;
          let resp4 = await validateForm(this.state.formData4, toast);
          resp4 = resp4.isValid;
          for (let key in this.state.languages) {
            let langId = this.state.languages[key].id;
            let resp = await validateForm(
              this.state["formData2" + langId],
              toast
            );
            if (!resp.isValid) {
              respValid = false;
            }
          }
          let resp2 = this.customvalidatation();
          if (!resp1 || !resp2 || !resp3 || !resp4) {
            respValid = false;
          }
        }
        //respValid = true
        if (respValid) {
          this.setState({ ...this.state, isLoading: true });
          let _formData1 = this.state.formData1;
          let _formData3 = this.state.formData3;
          let _formData4 = this.state.formData4;
          _formData1 = parseFormData(_formData1);
          _formData3 = parseFormData(_formData3);
          _formData4 = parseFormData(_formData4);

          _formData1["productid"] = this.state.currentId;

          var productdescs = [];
          let objOptions = [];

          var optionIds = this.state.optionIds;
          for (let key in this.state.languages) {
            let langId = this.state.languages[key].id;
            let _subformData = this.state["formData2" + langId];
            _subformData = parseFormData(_subformData);
            productdescs.push(_subformData);

            this.state.objOptions.map(function (op) {
              for (let o in optionIds) {
                if (op["optionvalue_" + optionIds[o].optionId] !== "") {
                  let optionname = findValueById(
                    op.optionnames,
                    langId,
                    "id",
                    "value"
                  );
                  objOptions.push({
                    optionoptionid: optionIds[o].optionId,
                    optionrequiredid: 0,
                    optionvalue: op["optionvalue_" + optionIds[o].optionId],
                    optionquantity: op.optionquantity,
                    subtractstock: 0,
                    optionprice: op.optionprice,
                    optionpoints: 0,
                    optionweight: 0,
                    imagesquantity: op.imagesquantity,
                    optionimage: "",
                    optionimages: op.optionimages,
                    optionsku: op.optionsku,
                    languageid: langId,
                    optionname: optionname ? optionname : "",
                  });
                }
              }
            });
          }

          var objAttributes = [];
          for (let key in this.state.objAttributes) {
            let _obj = this.state.objAttributes[key];
            let atribId = _obj.attributeId;
            for (let key2 in _obj.languages) {
              let finalobj = {
                id: atribId,
                languageid: _obj.languages[key2].languageid,
                languagevalue: _obj.languages[key2].languagevalue,
              };
              objAttributes.push(finalobj);
            }
          }

          let finalForm = _formData1;
          Object.assign(finalForm, _formData3);
          Object.assign(finalForm, _formData4);
          finalForm["productdescs"] = productdescs;

          finalForm["attributes"] = objAttributes;
          finalForm["productoptions"] = objOptions;

          finalForm["images"] = this.state.objImages;

          //let resp = {success:false, message:"message"}
          let resp = await callApi(
            "manager",
            this.state.formAction + "Products",
            finalForm
          );
          if (resp.success) {
            showMessage(toast, resp.message);
            resp = await callApi("manager", "getProducts");
            const tableData = this.state.tableData;
            tableData.rows = resp.rows;
            this.setState({ originalTableData: this.state.tableData });
            this.filterHandler({ target: { name: "search" } });
            this.setState({ ...this.state, modal1: false });
          } else {
            showError(toast, resp.message);
          }
        } else {
          this.refreshFormData(event);
        }
      } else {
        showError(toast, "Role not allowed to make changes");
      }
    } catch (err) {
      showError(toast, "Internal Error");
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  render() {
    return (
      <>
        <SidebarNav pageId={this.props.match.params.pageId} />
        <PageLoading isLoading={this.state.isLoading} />

        <Modal
          open={this.state.modal5}
          toggle={this.toggleModal(5)}
          icon=""
          title={"Choose Language"}
          buttons={this.state.buttonsModal5}
          cancelButton={true}
          class={this.state.modalClass5}
          message=<Form
            formData={this.state.formData5}
            changeHandler={this.changeHandler5}
            formConfig={this.state.formConfig5}
          />
        />

        <Modal
          open={this.state.modal2}
          icon="fas fa-images"
          title="Image Manager"
          zindex="1060"
          message={this.state.imageManager}
          toggle={this.toggleModal(2)}
          class={this.state.modalIamgeClass}
        />

        <Modal
          open={this.state.modal2B}
          icon="fas fa-folder"
          title="Image Folder"
          zindex="1063"
          message={this.state.folderManager}
          toggle={this.toggleModal("2B")}
          class={this.state.modalIamgeClass}
        />

        <Modal
          open={this.state.modal30}
          icon="fas fa-folder"
          title="Category List"
          zindex="1067"
          buttons={this.state.buttonsModal30}
          message=<Form
            formData={this.state.formData30}
            changeHandler={this.changeHandler30}
            formConfig={this.state.formConfig30}
          />
          toggle={this.toggleModal(30)}
          class={this.state.modalSMClass}
        />
        <Modal
          open={this.state.modal31}
          icon="fas fa-folder"
          title="Category List"
          zindex="1067"
          buttons={this.state.buttonsModal31}
          message=<Form
            formData={this.state.formData31}
            changeHandler={this.changeHandler31}
            formConfig={this.state.formConfig31}
          />
          toggle={this.toggleModal(31)}
          class={this.state.modalSMClass}
        />
        <Modal
          open={this.state.modal3}
          icon="fas fa-images"
          title="Image Manager"
          zindex="1060"
          message={this.state.imageManager2}
          toggle={this.toggleModal(3)}
          class={this.state.modalIamgeClass}
        />
        <Modal
          open={this.state.modal1}
          toggle={this.toggleModal(1)}
          class={this.state.modalClass}
          icon={this.state.modalIcon}
          title={this.state.modalTitle}
          buttons={this.state.buttonsModal1}
          cancelButton={true}
          message={
            this.state.formAction === "delete" ? (
              <span className="h4-responsive">{this.state.deleteMessage}</span>
            ) : (
              <Tab tabData={this.state.tabData1} />
            )
          }
        />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <PageHeader title={"Catalog"} subtitle={"Products"} />

            <div className="row mb-4 text-right">
              <div className="col-md-12 col-lg-12">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  name="set"
                  onClick={this.loadFormData}
                >
                  {" "}
                  Add New
                </button>
                {this.state.allData.length == 0 ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-warning"
                    name="set"
                    onClick={this.generateEntireData}
                  >
                    {" "}
                    Generate All Products Data
                  </button>
                ) : (
                  <ExcelFile
                    filename={"all-products." + this.state.date_label}
                    element={
                      <button
                        type="button"
                        className="btn btn-sm   btn-success"
                        name="set"
                      >
                        {" "}
                        Export All Data
                      </button>
                    }
                  >
                    <ExcelSheet data={this.state.allData} name="PRODUCTS">
                      <ExcelColumn label="Name" value="PRODUCTNAME" />
                      <ExcelColumn label="SKU" value="SKU" />
                      <ExcelColumn
                        label="Description"
                        value="PRODUCTDESCRIPTION"
                      />
                      <ExcelColumn label="Price" value="PRICE" />
                      <ExcelColumn label="Cat 1" value="CAT_N1" />
                      <ExcelColumn label="Cat 2" value="CAT_N2" />
                      <ExcelColumn label="Cat 3" value="CAT_N3" />
                      <ExcelColumn label="Cat 4" value="CAT_N4" />
                      <ExcelColumn label="Cat 5" value="CAT_N5" />
                      <ExcelColumn label="Materials" value="ATTRIB_1" />
                      <ExcelColumn label="Sizes" value="ATTRIB_2" />
                      <ExcelColumn label="Cares" value="ATTRIB_3" />
                      <ExcelColumn label="Certifications" value="ATTRIB_4" />
                      <ExcelColumn label="Age" value="ATTRIB_5" />
                      <ExcelColumn label="TAG1" value="TAG1" />
                      <ExcelColumn label="TAG2" value="TAG2" />
                      <ExcelColumn label="TAG3" value="TAG3" />
                      <ExcelColumn label="TAG4" value="TAG4" />
                      <ExcelColumn label="TAG5" value="TAG5" />
                      <ExcelColumn label="TAG6" value="TAG6" />
                      <ExcelColumn label="Size" value="SIZEVALUE" />
                      <ExcelColumn label="Color" value="COLORVALUE" />
                      <ExcelColumn label="Key" value="TAXCLASS_ID" />
                      <ExcelColumn label="Specific Name" value="SPECIFICNAME" />
                      <ExcelColumn label="Images Path" value="IMAGESPATH" />
                      <ExcelColumn label="Images Qty" value="IMAGESQUANTITY" />
                      <ExcelColumn label="Clonable" value="ORDERSKU" />

                      <ExcelColumn label="Sort Order" value="SORTORDER" />

                      <ExcelColumn label="H1" value="SEOH1" />
                      <ExcelColumn label="H2" value="SEOH2" />
                      <ExcelColumn label="H3" value="SEOH3" />
                      <ExcelColumn label="Metatag Title" value="METATAGTITLE" />
                      <ExcelColumn
                        label="Meta Tag Description"
                        value="METATAGDESCRIPTION"
                      />

                      <ExcelColumn label="Instruccions" value="ATTRIB_6" />
                      <ExcelColumn label="Origin" value="ATTRIB_7" />
                      <ExcelColumn label="Packaging sizes" value="ATTRIB_8" />
                      <ExcelColumn label="Warnings" value="ATTRIB_9" />
                      <ExcelColumn label="Color Info" value="ATTRIB_10" />
                      <ExcelColumn label="GA" value="GA" />

                      <ExcelColumn label="Product Id" value="PRODUCTID" />
                    </ExcelSheet>
                  </ExcelFile>
                )}

                {this.state.allDataCombo.length == 0 ? (
                  <button
                    type="button"
                    className="btn btn-sm btn-info"
                    name="set"
                    onClick={this.generateEntireComboData}
                  >
                    {" "}
                    Generate All Packs
                  </button>
                ) : (
                  <ExcelFile
                    filename={"all-packs." + this.state.date_label}
                    element={
                      <button
                        type="button"
                        className="btn btn-sm  btn-success"
                        name="set"
                      >
                        {" "}
                        Export All Packs
                      </button>
                    }
                  >
                    <ExcelSheet data={this.state.allDataCombo} name="PACKS">
                      <ExcelColumn label="Name" value="PRODUCTNAME" />
                      <ExcelColumn label="SKU" value="SKU" />
                      <ExcelColumn
                        label="Description"
                        value="PRODUCTDESCRIPTION"
                      />
                      <ExcelColumn label="Price" value="PRICE" />
                      <ExcelColumn label="Cat 1" value="CAT_N1" />
                      <ExcelColumn label="Cat 2" value="CAT_N2" />
                      <ExcelColumn label="Cat 3" value="CAT_N3" />
                      <ExcelColumn label="Cat 4" value="CAT_N4" />
                      <ExcelColumn label="Cat 5" value="CAT_N5" />
                      <ExcelColumn label="Materials" value="ATTRIB_1" />
                      <ExcelColumn label="Sizes" value="ATTRIB_2" />
                      <ExcelColumn label="Cares" value="ATTRIB_3" />
                      <ExcelColumn label="Certifications" value="ATTRIB_4" />
                      <ExcelColumn label="Age" value="ATTRIB_5" />
                      <ExcelColumn label="TAG1" value="TAG1" />
                      <ExcelColumn label="TAG2" value="TAG2" />
                      <ExcelColumn label="TAG3" value="TAG3" />
                      <ExcelColumn label="TAG4" value="TAG4" />
                      <ExcelColumn label="TAG5" value="TAG5" />
                      <ExcelColumn label="TAG6" value="TAG6" />
                      <ExcelColumn label="Size" value="SIZEVALUE" />
                      <ExcelColumn label="Color" value="COLORVALUE" />
                      <ExcelColumn label="Key" value="TAXCLASS_ID" />
                      <ExcelColumn label="Specific Name" value="SPECIFICNAME" />
                      <ExcelColumn label="Images Path" value="IMAGESPATH" />
                      <ExcelColumn label="Images Qty" value="IMAGESQUANTITY" />
                      <ExcelColumn label="Pack Name" value="PACKNAME" />
                      <ExcelColumn label="Sort Order" value="SORTORDER" />

                      <ExcelColumn label="H1" value="SEOH1" />
                      <ExcelColumn label="H2" value="SEOH2" />
                      <ExcelColumn label="H3" value="SEOH3" />
                      <ExcelColumn label="Metatag Title" value="METATAGTITLE" />
                      <ExcelColumn
                        label="Meta Tag Description"
                        value="METATAGDESCRIPTION"
                      />

                      <ExcelColumn label="Instruccions" value="ATTRIB_6" />
                      <ExcelColumn label="Origin" value="ATTRIB_7" />
                      <ExcelColumn label="Packaging sizes" value="ATTRIB_8" />
                      <ExcelColumn label="Warnings" value="ATTRIB_9" />
                      <ExcelColumn label="Color Info" value="ATTRIB_10" />
                      <ExcelColumn label="GA" value="GA" />

                      <ExcelColumn label="Product Id" value="PRODUCTID" />
                    </ExcelSheet>
                  </ExcelFile>
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  name="elastic"
                  onClick={() => {
                    this.setState({ modal30: true });
                  }}
                >
                  {" "}
                  Update Elastic
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-info"
                  name="elastic"
                  onClick={() => {
                    this.setState({ modal31: true });
                  }}
                >
                  {" "}
                  Update Images
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="card card-table flex-fill">
                  <Table
                    tableData={this.state.tableData}
                    filterConfig={this.state.filterConfig}
                    filterHandler={this.filterHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
