/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';
import ImageManager from '../../components/ImageManager';
import Tab from '../../components/Tab';

/*functions*/
import {manageImage, listImages, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';

class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			//show  : null,
			modal1: false,
			modal2: false,
			isLoading: true,
			modalClass: "modal-full-height modal-lg",
			modalIamgeClass:"modal-lg",
			/*table data*/
			tabData1: {
				tabs: [{ id: "tab1" , title: "General", content:""},
				 			 { id: "tab2" , title: "SEO"   , content:""},
				 		//	 { id: "tab3" , title: "Design" , content:"hola3"},
							],
				onChangeTab: this.changeTab1,
				activeTab: "tab1",
			}	,
			
			tabData1_1: {
				tabs: [
							],
				onChangeTab: this.changeTab1_1,
				activeTab: "",
			}	,
			
			tableData: {
				columns : [
					{ dataIndex:"NAME", title:"Manufacturer Name",
						sorter: (a, b) => a.NAME.length - b.NAME.length },
					{ dataIndex:"SORTORDER", title:"Sort Order",
						sorter: (a, b) => a.SORTORDER.length - b.SORTORDER.length },
					{ dataIndex: "MANUFACTURERID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-sm btn-info" id={record.MANUFACTURERID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-danger" id={record.MANUFACTURERID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Manufacturer",
			
			/*form data*/			
			
			formData1: {
				
				NAME          : { label: "Manufacturer Name" , type: "TEXT",   value: "" } ,
				STORES        : { label: "Sections"          , validationType:"array"  , type: "CHECKBOX", value: [], values: [] , getDataDB: {apiMethod: "getMenus" } } ,
				IMAGEPATH     : { label: "Image"             , notRequired:true        , type: "IMAGE",  value: "" }, 
				SORTORDER     : { label: "Sort Order"        , validationType:"number" , type: "TEXT",   value: ""  }		
			},
			formConfig1:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				SecondaryForm: true
			},
			
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getManufacturers" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		await preloadForm ( this.state.formData1 )
			resp = await callApi ( "custom","getDetailDomainsByCode", {domainid:"2"} )
			this.setState({"languages":resp.rows});
			
			for (let key in resp.rows){
				let formData	= {
					LANGUAGEID         : { label: "Language id"          , type: "HIDDEN", value: resp.rows[key].DETAILDOMAINID, disabled: true } ,
					NAME               : { label: "Manufacturer Name"        , type: "TEXT",   value:"" } ,
					DESCRIPTION        : { label: "Description"          , type: "TEXT",   value: ""} ,
					KEYWORD            : { label: "Keyword"             ,notRequired:true , type: "TEXT",   value: ""} ,
					SEOH1              : { label: "Seo H1"              ,notRequired:true , type: "TEXT",   value: "" },
					SEOH2              : { label: "Seo H2"              ,notRequired:true , type: "TEXT",   value: "" } ,
					SEOH3              : { label: "Seo H3"              ,notRequired:true , type: "TEXT",   value: ""  },
					METATAGTITLE       : { label: "Meta Tag Title"       , type: "TEXT",   value: ""  },
					METATAGDESCRIPTION : { label: "Meta Tag Description" , type: "TEXT",   value: ""  },
					METATAGKEYWORD     : { label: "Meta Tag Keyword"     , type: "TEXT",   value: ""  },
			}
			let formConfig2 = {
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				SecondaryForm: true,
				formId : resp.rows[key].DETAILDOMAINID
			}
			this.setState({["formData2"   + resp.rows[key].DETAILDOMAINID ]:formData});
			this.setState({["formConfig2" + resp.rows[key].DETAILDOMAINID ]:formConfig2});
			}
			this.refreshFormData();
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	/*charge data from a selected user*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Manufacturer"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData1)
				
				for (let key in this.state.languages ){
					let langId = this.state.languages[key].DETAILDOMAINID
					let langName = this.state.languages[key].STRING_VALUE
					let formLanguage =this.state["formData2"+langId]
					loadData = await clearformData (formLanguage)
					loadData.LANGUAGEID.value= langId
					this.setState({["formData2"+langId]:loadData});
				}
				this.refreshFormData(event);
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Manufacturer"
				itemId = event.target.id
				modalClass =  "modal-full-height modal-lg"
	  		let resp = await callApi ( "manager","getManufacturerById",{manufacturerid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData1,resp.MANUFACTURER)
	  		/*special load for dinamyc fields (1 form by language)*/
	  		 
	  		/*change if manage stores*/
	  		let stores=[]
	  		console.log(resp.MANUFACTURER)
	  		for (let key in resp.MANUFACTURER.MANUFACTURERSTORES ){
	  			stores.push(resp.MANUFACTURER.MANUFACTURERSTORES[key].STOREID)
	  		}
	  		this.state.formData1.STORES.value = stores
	  		for (let key in resp.MANUFACTURER.MANUFACTURERDESCS ){
	  			let field= resp.MANUFACTURER.MANUFACTURERDESCS[key]
	  			let formLanguage =this.state["formData2"+field.LANGUAGEID]
	  			if (formLanguage){
	  				let fields = Object.keys(formLanguage)
	  				for (let key in fields ){
	  					formLanguage[fields[key]].value = field[fields[key]] ===null?"":field[fields[key]];
	  				}
	  			}else{
	  				console.log("language not founded")
	  			}
	  		}
	  		formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		this.refreshFormData(event);
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Manufacturer"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
				this.refreshFormData(event);
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  //refresh form
  refreshFormData= (event) => {
  	
		var tabData1 = this.state.tabData1
		var tabData1_1 = this.state.tabData1_1
		var tabs = []
		var activeTab
		
		for (let key in this.state.languages ){
			let langId = this.state.languages[key].DETAILDOMAINID
			let langName = this.state.languages[key].STRING_VALUE
			let formLanguage =this.state["formData2"+langId]
			
			if (key==0){
				activeTab = "tab1_"+langId
			}
			
			let tab = { id: "tab1_"+langId , title: langName , content:
					<Form 
						formData={this.state["formData2"+langId]} 
						changeHandler={this.changeHandler2} 
						formConfig={this.state["formConfig2"+langId]}/>
			}
			tabs.push(tab)
	  }
		tabData1_1["tabs"] = tabs
		if (tabData1_1["activeTab"]==""){
			tabData1_1["activeTab"] = activeTab
		}
		
	 	tabData1.tabs[1]["content"] = 	<Tab tabData={this.state.tabData1_1}  /> 
	 	tabData1.tabs[0]["content"] = 	<Form 
																					formData={this.state.formData1} 
																					manageImage={this.manageImage}
																					changeHandler={this.changeHandler1} 
																					formConfig={this.state.formConfig1}/>
		this.setState({tabData1_1:tabData1_1});            																					
	 	this.setState({tabData1:tabData1});
	}
	
  //get change tabs
  changeTab1= (event) => {
  	
  	const tabData1= this.state.tabData1
  	tabData1["activeTab"] = event.target.id;
		this.setState({ tabData1:tabData1 })
	}
	//get change tabs for general tab
	changeTab1_1= (event) => {
  	const tabData1_1= this.state.tabData1_1
  	tabData1_1["activeTab"] = event.target.id;
		this.setState({ tabData1_1:tabData1_1 })
		var tabData1 = this.state.tabData1
	 	tabData1.tabs[0]["content"] = 	<Tab tabData={this.state.tabData1_1}  /> 
	 	this.setState({tabData1:tabData1});
	 	this.refreshFormData(event);
	} 
  
  //get value fields static form
  changeHandler1 = async(event) => {
		let resp = await validateForm(this.state.formData1, toast,event);
		this.setState({formData1:resp.formData});
		this.refreshFormData(event);
	} 
	//get dinamyc fields 
	changeHandler2 = async(event) => {
  	
  	var id = (event.target.id).split("_") [1]
  	var _form = this.state["formData2"+id]
		let resp = await validateForm(_form, toast,event);
		console.log(resp)
		this.setState({["formData2"+id]:resp.formData});
		this.refreshFormData(event);
	} 
  //show/hide modal
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	//manage image
	manageImage= async(event) => {
		try{
			this.setState({isLoading:true});
			var data = (event.target.name).split("_")
			let action =  data[0]
			if (action==="manageImage"){
				let objectName = data[1]
				let objImage = await listImages("",objectName);
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
	 		 	this.setState({ imageManager:imageManager, modal2: true});
			}else{
				var objImage = await manageImage( event, this.state.objImage)
				this.setState({objImage:objImage});
				let imageManager = <ImageManager objImage={this.state.objImage} manageImage={this.manageImage} />
				this.setState({imageManager:imageManager});
				if (objImage.selected.length===1){
					let field = objImage.objectName
					var formData1 = this.state.formData1
					formData1[field].value = objImage.selected[0]
					this.setState({modal2:false});
					this.refreshFormData(event);
				}
			}
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	} 
	/*ABM manager*/
	execute=async (event,actionType) => {
  	try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData1, toast);
	  			respValid = resp.isValid
					for (let key in this.state.languages ){
						let langId = this.state.languages[key].DETAILDOMAINID
						let	resp = await validateForm(this.state["formData2"+langId], toast);
						if (!resp.isValid){
							respValid= false
						}
					}
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData1;
					_formData = parseFormData(_formData)
					_formData["manufacturerid"] = this.state.currentId;
					var manufacturerdescs =[]					
					for (let key in this.state.languages ){
						let langId = this.state.languages[key].DETAILDOMAINID
						let _subformData=this.state["formData2"+langId]
						_subformData = parseFormData(_subformData)
						manufacturerdescs.push(_subformData)
					}
			  	let finalForm = _formData
			  	finalForm ["manufacturerdescs"] = manufacturerdescs
			  	let resp = await callApi ( "manager",this.state.formAction+"Manufacturers", finalForm )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getManufacturers" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  }else{
			  	this.refreshFormData(event);
			  }
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal2} 
      	icon="fas fa-images"
      	title="Image Manager"
      	zindex="1060"
      	message={this.state.imageManager}
      	toggle={this.toggleModal (2)} 
      	class={this.state.modalIamgeClass}
      />
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<span className="h4-responsive">{this.state.deleteMessage}</span>
													:
													 <Tab tabData={this.state.tabData1}  />
												 }
							  />
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Manufacturers"}/>
        	
        	<div className="row mb-4 text-right">
						<div className="col-md-12 col-lg-12">
								<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} />
								
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 