import React, { Component } from 'react';
import { Link,Redirect } from 'react-router-dom';
import Logo from '../../assets/images/logo-white.png';
import Auth from '../../services/Auth';
import {validateForm,showError} from '../../common/functions';
import PageLoading from '../../components/PageLoading';
import {toast,ToastContainer} from 'mdbreact';

class Logout extends Component{
	//define variable
	constructor(props) {
		super(props); 
	}
	//loading page
	componentDidMount() {
		
		var token = Auth.logout()
	}
	//rendering page
	render(){ 
		 
    return (
      <Redirect to="/login" />
    )
	}
}
export default Logout;
	 	