import React from "react";
import image from "react-slick";
import {validateNumber, findValueById,getLanguage,getImagePath, getDefValues,getCoordinatesStore } from '../../common/functions';


const windowWidthDef = window.innerWidth
const loadimage = props => {
	if (props.imageConfig){
		var style ={}
		var show= false;
		var itemsAlign="items-center"
		
		
		if (props.imageConfig.version){
			if (windowWidthDef<=799){
		 	//mobile
	 		if (props.imageConfig.version=="mobile" || props.imageConfig.version=="both"){
	 			show= true
	 		} 
	 		itemsAlign= "items-left"
			}else{
				show= true
				if (props.imageConfig.version=="mobile"){
			 		show= false
				} 
			}
		}else {
			var show= true;	
		}
		
		if (props.imageConfig.fatherconfig){
			var classContainer="box-bottom-container"
			if (props.imageConfig.fatherconfig && windowWidthDef<799){
				if (props.imageConfig.fatherconfig.colsize=="col-6"){
					classContainer="box-bottom-container-2"
				}
			}
			if(props.imageConfig.linkbutton){
				if (props.imageConfig.linkbutton.length>5 && windowWidthDef<799){
					classContainer="box-bottom-container-2"
				}
			} 
		}
			
		if(show){
			if(props.imageConfig.boxwidth){
				if(props.imageConfig.boxwidth=="2_3"){
					style["width"] = "50%" 
					itemsAlign="items-left"
				}
				if(props.imageConfig.boxwidth=="1_3"){
					itemsAlign="items-left"
				}
			} 
			var pathImage = props.imageData.image
			
			if (windowWidthDef<799){
				if (props.imageData.imagemobile){
					if (props.imageData.imagemobile!=""){
						pathImage = props.imageData.imagemobile
				} 
				} 
			}
			
			
			// console.log(props.imageConfig.targetlink)
			return (  
	   		<div className="flex items-center">
   				<div className= { props.imageConfig.adjust? "adjust-image":"" }>
   					<div className= { props.imageConfig.adjust? "col-12":"" }>
							{ props.imageConfig.link?
				    			<a href={props.imageConfig.link} 
				    			target={ (props.imageConfig.targetlink?props.imageConfig.targetlink:"_self") }
				    			>
				    			<img alt={props.imageData.imagealt} 
				    			width={props.imageConfig.fontwidth=="0"?"100%":props.imageConfig.fontwidth} 
				    			height={props.imageConfig.fontheight=="0"?"100%":props.imageConfig.fontheight} 
				    			src={getImagePath(pathImage)}  />
				    			</a>:
				    			<img alt={props.imageData.imagealt} 
				    			width={props.imageConfig.fontwidth=="0"?"100%":props.imageConfig.fontwidth} 
				    			height={props.imageConfig.fontheight=="0"?"100%":props.imageConfig.fontheight} 
				    			src={getImagePath(pathImage)}   />
			  	 		}
			  	 		{props.imageConfig.label || props.imageConfig.linkbutton?
			  	 			<> 
						  	 		<div className={classContainer} >  
					  	 				
							  	 			<div className="label" style={style} > 
							  	 				<div className="content" > 
								  	 				{props.imageConfig.label?
								  	 					<div className={ (windowWidthDef<799 && classContainer!="box-bottom-container-2"?"col-9 col-lg-12 col-sm-9 col-md-9":"col-12")+" flex middle "+itemsAlign }>
								  	 					 <span>{props.imageConfig.label} </span>
								  	 					</div>
								  	 			  :""}
							  	 					{props.imageConfig.linkbutton? 
							  	 						<div className={ (windowWidthDef<799 && classContainer!="box-bottom-container-2"?"col-3":"col-12" ) +" flex middle "+itemsAlign }>
							  	 						
							  	 						
							  	 							<a href={props.imageConfig.link} 
							  	 							target={ (props.imageConfig.targetlink?props.imageConfig.targetlink:"_self") }
							  	 							>
							  	 							<button className={ props.imageConfig.color?  ("btn btn-img-"+ props.imageConfig.color) : "btn-primary" }>
							  	 								{ props.imageConfig.linkbutton }</button>
							  	 							</a>
							  	 						</div> :"" }
							  	 				</div>
							  	 			</div>
						  	 			
						  	 		</div>
					  	 		 
									</>
			  	 		:""}
		  	 		</div>
		  		</div>
	    	</div>
	  	);
		}else{
			return ""
		}
		  
	  	
	  
	}else{
		return "NO CONFIG"
	}
};
export default loadimage;
				 