import React, { Component } from "react";
import DateTimePicker from '../DateTimePicker';
const windowHeight = window.innerHeight
const loadDiscount = props => {
	
	if (props.customerGroups && props.objDiscounts){
	  return (
	  	<div className="row">
				<div className="col-md-12 col-lg-12"  >
					{props.objDiscounts.length>0?
		  			<div className="row">
							<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2 ml-1"}><span className="h6-responsive" >Customer Group</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Quantity</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Priority</span></div>
							<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}><span className="h6-responsive" >Price</span></div>
							<div className={"col-md-6 col-sm-5 col-lg-2 col-xl-2"}><span className="h6-responsive" >Date Start</span></div>
							<div className={"col-md-6 col-sm-5 col-lg-2 col-xl-2"}><span className="h6-responsive" >Date End</span></div>
							<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1"}> </div>
						</div>	
					:""}
				  {props.objDiscounts.map(itemM => (
				  	<>
							<div className="row mb-1">
						 		<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}>
					 				<select  name={props.container	+"_customergroupid_"+itemM.rowId} defaultValue={itemM.customergroupid} onChange={props.changeHandler} className={"form-control form-control-sm" }>
			        			{props.customerGroups.map(item => (
			        		 		<option key={props.container+"attr_i"+item.value} value={item.value}>{item.text}</option>
			        		 	))
			            	} 
									</select>
						  	</div>						  	
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_quantity_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_quantity?"is-invalid":"")}
										value={itemM.quantity}
										name={props.container+"_quantity_"+itemM.rowId}
										onChange={props.changeHandler} />
						  	</div>
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_priorityorder_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_priorityorder?"is-invalid":"")}
										value={itemM.priorityorder}
										name={props.container+"_priorityorder_"+itemM.rowId}
										onChange={props.changeHandler} />
						  	</div>			
						  	<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}>
					 				<input key={props.container+"_price_"+itemM.rowId}  type="text" className={"form-control form-control-sm "+ (itemM.isInvalid_price?"is-invalid":"")}
										value={itemM.price}
										name={props.container+"_price_"+itemM.rowId}
										onChange={props.changeHandler} />
						  	</div>			
						  	<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2 date-form-control date-form-control-sm"}>						 			 
				 					<DateTimePicker 
										key={props.container+"_datestart_"+itemM.rowId}
										date={itemM.date_start}  
										options = {{ altInputClass: "form-control", enableTime: false}}
										date={itemM.datestart} 
										name={props.container+"_datestart_"+itemM.rowId}
										isUpdatable= {itemM.isUpdatable===undefined?true: itemM.isUpdatable }
										updateDate={props.changeHandler} 
									/>			        	 
						  	</div>		
						  	<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-2 date-form-control date-form-control-sm"}>						 			 
					 					<DateTimePicker 
											key={props.container+"_dateend_"+itemM.rowId}
											date={itemM.date_end} 
											options = {{ altInputClass: "form-control", enableTime: false}}
											name={props.container+"_dateend_"+itemM.rowId}
											date={itemM.dateend} 
											label={" "}  
											isUpdatable= {itemM.isUpdatable===undefined?true: itemM.isUpdatable }
											updateDate={props.changeHandler} 
										/>			        	 
						  	</div>						  	
						  	<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}>						 		 
									<button  className="btn btn-danger btn-sm"  name={props.container+"_"+itemM.rowId} onClick={props.delRow}
							       type="button"> <i className="fas fa-trash"  ></i> </button>
							 	</div>
				    	</div>
					  </>
					  ))
						} 
						{props.addRow?
							<div className="row">
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-1 col-xl-1"}></div>
								<div className={"col-md-6 col-sm-6 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-4 col-sm-4 col-lg-2 col-xl-2"}></div>
								<div className={"col-md-1 col-sm-1 col-lg-1 col-xl-1 text-right"}> <button  className="btn btn-info btn-sm"  name={props.container}
					         onClick={props.addRow} type="button"> <i className="fas fa-plus"></i> </button>
					    	</div>
							</div>	
			    	:""}
				</div>
			</div>   
	  );
	}else{
		return "NO Discount DATA"
	}
};
export default loadDiscount;
				 