/*****components***/
import React, { Component } from 'react';
import { Switch } from "antd";
import {toast,ToastContainer} from 'mdbreact';

/*menu*/
import SidebarNav from "../sidebar";

/*custom components*/
import PageLoading from '../../components/PageLoading';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import PageHeader from '../../components/PageHeader';

/*functions*/
import {getLanguages, validateComponent, validateRole, encryptText,mergeFormData,clearformData, callApi, validateForm, showError,showMessage, preloadForm,parseFormData} from '../../common/functions';
class page extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
			show : null,
			modal1: false,
			modal2: false,
			modalMdClass:"modal-lg",
			isLoading: true,
			componentType:"",
			modalClass: "modal-full-height modal-lg",
			
			/*modal info*/
			buttonsModal2 : [
				{	buttonAction: this.validateComponent,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Save"},
				{	buttonAction: this.cancelComponent,
					buttonId    : 2,
					buttonClass : "btn btn-danger",
					buttonName  : "Cancel "}	
					
			],
			
			/*table data*/
			tableData: {
				columns : [
				  { dataIndex:"ATTRIBUTEID", title:"Attribute Id",
						sorter: (a, b) => a.ATTRIBUTEID - b.ATTRIBUTEID },
					{ dataIndex:"NAME", title:"Attribute Name",
						sorter: (a, b) => a.NAME.length - b.NAME.length },
					{ dataIndex:"ATTRIBUTECATEGORY", title:"Attribute Group",
						sorter: (a, b) => a.ATTRIBUTECATEGORY.length - b.ATTRIBUTECATEGORY.length },
					{ dataIndex:"SORTORDER", title:"Sort Order",
						sorter: (a, b) => a.SORTORDER - b.SORTORDER  },
					{ dataIndex: "ATTRIBUTEID", title: "Action",
		        render: (text, record) => (<>
		         <button className="btn btn-sm btn-info" id={record.ATTRIBUTEID} name="update"
		         onClick={this.loadFormData}
		          type="submit">Edit</button>
		         <button className="btn btn-sm btn-danger" id={record.ATTRIBUTEID} name="delete"
		         onClick={this.loadFormData}
		          type="submit">Delete</button></>
		        )
		      }
				],
		    rows: [], 
			},
			/*modal info*/
			buttonsModal1 : [
				{	buttonAction: this.set,
					buttonId    : 1,
					buttonClass : "btn btn-outline-primary",
					buttonName  : "Add"}
			],
			
			modalIcon:"fas fa-edit",
			modalTitle:"New Attribute",
			/*form data*/
			
			formConfig:{
				pageWidth: "card col-md-12 col-lg-12 col-xl-12",
				groupingRows: 1,
				languages:[]
				
			},
			formData	: {
				ATTRIBUTENAMES      : { label: "Attribute Name"      , type: "CompLanguage",   values: [] },
				ATTRIBUTEVALUES     : { label: "Attribute Value"      , type: "CompLanguage",   values: [] },
				ATTRIBUTECATEGORYID : { label: "Attribute Group"             , type: "SELECT", value: "", values: [] , getDataDB: {apiGroup: "manager", apiMethod: "getAttributeCategories" }} ,
				SORTORDER           : { label: "Sort Order"       , type: "TEXT",   value: "" } ,
				
			},
						
			/*support variables*/
			currentId:null,
			formAction: "set",
			deleteMessage:"Are you sure to delete this item?"
		}
	}
	/*loading data*/
	componentDidMount = async() => {
	 	var pageId =	this.props.match.params.pageId;
	 	try{
	 		let resp = await callApi ( "manager","getAttributes" )
	 		const tableData = this.state.tableData
	 		tableData.rows = resp.rows
	 		
	 	let languages= await getLanguages()
	 	let languages2= await getLanguages()
	 	let formData	= {
	 			
	 			ATTRIBUTENAMES      : { label: "Attribute Name"   , type: "CompLanguage",   values: languages },
	 			ATTRIBUTEVALUES     : { label: "Attribute Value"      , type: "CompLanguage",   values: languages2 },
				ATTRIBUTECATEGORYID : { label: "Type"             , type: "SELECT", value: "", values: [] , getDataDB: {apiGroup: "manager", apiMethod: "getAttributeCategories"}  } ,
				SORTORDER           : { label: "Sort Order"       , type: "TEXT",   value: "" } ,
				
			};
			
			this.setState({  ...this.state, formData:formData})								
	 		await preloadForm ( this.state.formData )
	 		
	 		
		}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({isLoading:false});
		}
	}	
	/*Form Functions*/
	
	 
	  
  /*get value fields*/
  changeHandler = async(event) => {
  	var field_value = event? (event.target.value) :undefined
		var field_name  = event?event.target.name:undefined 
		let resp = await validateForm(this.state.formData, toast,event);
		this.setState({formData:resp.formData});
		 
	} 
  
	toggleModal = nr => () => {
	  this.setState({ ['modal' + nr]: !this.state['modal' + nr]});
  }
  
  //add new
  set=async (event) => {
  	this.execute(event, "SET")
	}
	//edit
	update=async (event) => {
  	this.execute(event, "UPDATE")
	}
	//delete
	delete=async (event) => {
  	this.execute(event, "DELETE")
	}
	
 
	 
	/*charge data from a selected Attribute*/
	loadFormData=async (event) => {
				
		this.setState({  ...this.state, isLoading:true})
		try{	  	
	  	var buttonsModal1
	  	var modalIcon
	  	var modalTitle
	  	var formAction
	  	var itemId
	  	var modalClass
	  	if (event.target.name==="set"){
	  		buttonsModal1 = [
					{	buttonAction: this.set,
						buttonId    : 1,
						buttonClass : "btn btn-outline-primary",
						buttonName  : "Add"}
				]				
				modalIcon="fas fa-plus"
				modalTitle="New Attribute"
				formAction="set"
				itemId = null
				modalClass =  "modal-full-height modal-lg"
				let loadData = await clearformData (this.state.formData)
				this.state.formData.ATTRIBUTENAMES.value = undefined
				this.state.formData.ATTRIBUTEVALUES.value = undefined
				
	  	}else if (event.target.name==="update"){
	  			buttonsModal1 = [
					{	buttonAction: this.update,
						buttonId    : 1,
						buttonClass : "btn btn-outline-info",
						buttonName  : "Edit"}
				]				
				modalIcon="fas fa-edit"
				modalTitle="Edit Attribute"
				itemId = event.target.id
				formAction="update"
	  		modalClass =  "modal-full-height modal-lg"
	  		
	  		let resp = await callApi ( "manager","getAttributeById",{attributeid: itemId} )
	  		let loadData = await mergeFormData (this.state.formData,resp.ATTRIBUTE)
	  		
	  		var optionNames=[], optionValues=[],options=[]
	  		resp.ATTRIBUTE.ATTRIBUTENAMES.map(function (item) {
	  			optionNames.push( {  id: item.LANGUAGEID, name: item.LANGUAGE,  value: item.NAME })
	  			optionValues.push( {  id: item.LANGUAGEID, name: item.LANGUAGE,  value: item.DESCRIPTION })
	  		}); 
	  		 
	  		this.state.formData.ATTRIBUTENAMES.value = undefined
	  		this.state.formData.ATTRIBUTENAMES.values = optionNames
	  	 
	  	  this.state.formData.ATTRIBUTEVALUES.value = undefined
	  		this.state.formData.ATTRIBUTEVALUES.values = optionValues
	  		
	  	}else if (event.target.name==="delete"){
	  		buttonsModal1 = [
					{	buttonAction: this.delete,
						buttonId    : 1,
						buttonClass : "btn btn-outline-danger",
						buttonName  : "Delete"}
				]				
				modalIcon = "fas fa-trash"
				modalTitle = "Delete Attribute"
				itemId = event.target.id
				formAction="delete"
				modalClass =  ""
	  	}
  	}catch(err){
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,modal1:true,isLoading:false, currentId :itemId, modalClass, formAction:formAction, buttonsModal1: buttonsModal1, modalIcon:modalIcon, modalTitle:modalTitle  });
		}
  }
  
	/*ABM manager*/
	execute=async (event,actionType) => {
		try{	  	
  		var resp = validateRole(this.props.location.pathname);
  		if(resp){
  			/*validation*/
  			var respValid= true
  			if (actionType==="DELETE"){
  				respValid= true
	  		}else{
	  			resp = await validateForm(this.state.formData, toast);
	  			respValid = resp.isValid
					 
	  		}
  			if(respValid){
			  	this.setState({  ...this.state, isLoading:true })
					let _formData =  this.state.formData;
					_formData = parseFormData(_formData)
					_formData["attributeid"] = this.state.currentId;
					
			  	let resp = await callApi ( "manager",this.state.formAction+"Attributes", _formData )
			  	if (resp.success){
			  		showMessage(toast,resp.message);
			  		resp = await callApi ( "manager","getAttributes" )
			 			const tableData = this.state.tableData
			 			tableData.rows = resp.rows
			 			this.setState({...this.state,modal1:false });
			  	}else{
			  		showError(toast,resp.message);
			  	}
			  } 
		  }else{
		  	showError(toast,"Role not allowed to make changes");
		  }
  	}catch(err){
  		showError(toast, "Internal Error");
			console.log(">>>>>>>>>>"+err)
		}finally{
			this.setState({...this.state,isLoading:false });
		}
	}
	
	
	
  
	
	render(){
    return(
    <>
      <SidebarNav pageId={this.props.match.params.pageId} />
      <PageLoading isLoading={this.state.isLoading}/>
      <Modal open={this.state.modal1} 
								toggle={this.toggleModal (1)} 
								class={this.state.modalClass}
								icon={this.state.modalIcon}
								title={this.state.modalTitle}
								buttons={this.state.buttonsModal1}
								cancelButton={true}
								message={this.state.formAction==="delete"?
													<h2>{this.state.deleteMessage}</h2>
													:
													 <Form 
															formData={this.state.formData} 
															 
															changeHandler={this.changeHandler} 
															formConfig={this.state.formConfig}
													 />
												 }
							  />
			<Modal open={this.state.modal2}
      	icon="fas fa-images"
      	title={this.state.titlemodal2}
      	zindex="1060"
      	message={this.state.compManager}
      	buttons={this.state.buttonsModal2}
      	class={this.state.modalMdClass}
      />
      <div className="page-wrapper">
	    	<div className="content container-fluid">
        	<PageHeader title={"Catalog"} subtitle={"Attributes"}/>
        	
        	<div className="row mb-4 justify-content-center text-right">
						<div className="col-md-12 col-lg-12">
								<button type="button" className="btn btn-sm btn-primary" name="set" onClick={this.loadFormData}> Add New</button>
						</div>	
	    		</div> 
	    		
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
								<Table tableData={this.state.tableData} />
							</div>
						</div>
					</div>	
	    	</div> 
    	</div>
    </>         
    )
  }
}
export default page; 