import axios from "axios";
import {getDetailDomainsByCode} from '../common/functions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_CONFIG
  //baseURL:'http://localhost:8083'
  //baseURL: 'https://cidev-back.casaideas.com.bo/'
  // timeout: 1000,
});


const parse_res = api => api.then(data => data.data);


const Api = {
  auth: {
    login: (username, password) => api.post(`/api/login`, { username, password }).then(data => data.data)
  },
  menu: {
    userMenu: () => parse_res(api.get(`/api/getMenu`)),
    adminMenu: () => parse_res(api.get(`/api/getuserMenu`)),
  },
  
  custom: {
  	/*miscellaneous*/
     
    
    getDetailDomainsByCode : (data) => getDetailDomainsByCode(data) ,
    getDetailDomainsByCodeFromDB : (data) => parse_res(api.post(`/api/admin/getDetailDomainsByCode`,  data )),
    
    getDetailDomainsByCode2 : (data) => getDetailDomainsByCode(data) ,
    
    getDetailDomainsById   : (data) => parse_res(api.post(`/api/admin/getDetailDomainsById`,  data )),
	  setDetailDomains       : (data) => parse_res(api.put(`/api/admin/setDetailDomains`,  data )),
    updateDetailDomains    : (data) => parse_res(api.put(`/api/admin/updateDetailDomains`,  data )),
    deleteDetailDomains    : (data) => parse_res(api.put(`/api/admin/deleteDetailDomains`,  data )),
    
    getAdminMenus               : (data) => parse_res(api.post(`/api/admin/getAdminMenus`,  data )),
    
    getPaymentTypes          : (data) => parse_res(api.post(`/api/admin/getPaymentTypes`,  data )),
    getShippingTypes         : (data) => parse_res(api.post(`/api/admin/getShippingTypes`,  data )),
  },
  
  frontend:{
 
    getEventTypes          : (data) => parse_res(api.post(`/api/frontend/getEventTypes`      ,  data )),
    getShippingTypes       : (data) => parse_res(api.post(`/api/frontend/getShippingTypes`      ,  data )),
  },
  
  manager: {
  	/*users*/
    getUsers               : (data) => parse_res(api.post(`/api/admin/getUsers`,  data )),
    getUserById            : (data) => parse_res(api.post(`/api/admin/getUserById`,  data )),
    setUsers               : (data) => parse_res(api.put(`/api/admin/setUsers`,  data )),
    updateUsers            : (data) => parse_res(api.put(`/api/admin/updateUsers`,  data )),
    deleteUsers            : (data) => parse_res(api.put(`/api/admin/deleteUsers`,  data )),
		/*usergroups*/
		getUserGroups          : (data) => parse_res(api.post(`/api/admin/getUserGroups`,  data )),
    getUserGroupById       : (data) => parse_res(api.post(`/api/admin/getUserGroupById`,  data )),
    setUserGroups          : (data) => parse_res(api.put(`/api/admin/setUserGroups`,  data )),
    updateUserGroups       : (data) => parse_res(api.put(`/api/admin/updateUserGroups`,  data )),
    deleteUserGroups       : (data) => parse_res(api.put(`/api/admin/deleteUserGroups`,  data )),
   	/*categories*/
		getCategories          : (data) => parse_res(api.post(`/api/admin/getCategories`,  data )),
    getCategoryById        : (data) => parse_res(api.post(`/api/admin/getCategoryById`,  data )),
    setCategories          : (data) => parse_res(api.put(`/api/admin/setCategories`,  data )),
    updateCategories       : (data) => parse_res(api.put(`/api/admin/updateCategories`,  data )),
    deleteCategories       : (data) => parse_res(api.put(`/api/admin/deleteCategories`,  data )),
    updateCategoriesByStock: (data) => parse_res(api.put(`/api/admin/updateCategoriesByStock`,  data )),
    
    
    /*massive loads*/
    loadMassiveData        : (data) => parse_res(api.post(`/api/admin/loadMassiveData`,  data )),
		getLoadsById           : (data) => parse_res(api.post(`/api/admin/getLoadsById`,  data )),
    /*products*/
		getProducts            : (data) => parse_res(api.post(`/api/admin/getProducts`,  data )),
    getProductById         : (data) => parse_res(api.post(`/api/admin/getProductById`,  data )),
    setProducts            : (data) => parse_res(api.put(`/api/admin/setProducts`,  data )),
    updateProducts         : (data) => parse_res(api.put(`/api/admin/updateProducts`,  data )),
    deleteProducts         : (data) => parse_res(api.put(`/api/admin/deleteProducts`,  data )),
    getFilterProducts      : (data) => parse_res(api.post(`/api/admin/getFilterProducts`,  data )),
    getAllProductsToExport : (data) => parse_res(api.post(`/api/admin/getAllProductsToExport`,  data )),
    getAllCombosToExport   : (data) => parse_res(api.post(`/api/admin/getAllCombosToExport`,  data )),
    setFilterProducts      : (data) => parse_res(api.post(`/api/admin/setFilterProducts`,  data )),
    setImages              : (data) => parse_res(api.post(`/api/admin/setImages`,  data )),
    
    
    getPartProductsToExport  : (data) => parse_res(api.post(`/api/admin/getPartProductsToExport`,  data )),
    getCountProductsToExport : (data) => parse_res(api.post(`/api/admin/getCountProductsToExport`,  data )),
    
     /*Manufacturers*/
		getManufacturers       : (data) => parse_res(api.post(`/api/admin/getManufacturers`,  data )),
    getManufacturerById    : (data) => parse_res(api.post(`/api/admin/getManufacturerById`,  data )),
    setManufacturers       : (data) => parse_res(api.put(`/api/admin/setManufacturers`,  data )),
    updateManufacturers    : (data) => parse_res(api.put(`/api/admin/updateManufacturers`,  data )),
    deleteManufacturers    : (data) => parse_res(api.put(`/api/admin/deleteManufacturers`,  data )),
    
     /*Attributes*/
		getAttributes          : (data) => parse_res(api.post(`/api/admin/getAttributes`,  data )),
    getAttributeById       : (data) => parse_res(api.post(`/api/admin/getAttributeById`,  data )),
    setAttributes          : (data) => parse_res(api.put(`/api/admin/setAttributes`,  data )),
    updateAttributes       : (data) => parse_res(api.put(`/api/admin/updateAttributes`,  data )),
    deleteAttributes       : (data) => parse_res(api.put(`/api/admin/deleteAttributes`,  data )),
		
		 /*Coupons*/
		getCoupons          : (data) => parse_res(api.post(`/api/admin/getCoupons`,  data )),
    getCouponById       : (data) => parse_res(api.post(`/api/admin/getCouponById`,  data )),
    setCoupons          : (data) => parse_res(api.put(`/api/admin/setCoupons`,  data )),
    updateCoupons       : (data) => parse_res(api.put(`/api/admin/updateCoupons`,  data )),
    deleteCoupons       : (data) => parse_res(api.put(`/api/admin/deleteCoupons`,  data )),
    
    
     /*Coupons usercodes*/
		getCoupons_usercodes          : (data) => parse_res(api.post(`/api/admin/getCoupons_usercodes`,  data )),
    getCoupons_usercodeById       : (data) => parse_res(api.post(`/api/admin/getCoupons_usercodeById`,  data )),
    setCoupons_usercodes          : (data) => parse_res(api.put(`/api/admin/setCoupons_usercodes`,  data )),
    updateCoupons_usercodes       : (data) => parse_res(api.put(`/api/admin/updateCoupons_usercodes`,  data )),
    deleteCoupons_usercodes      : (data) => parse_res(api.put(`/api/admin/deleteCoupons_usercodes`,  data )),
    
		/*Attributes groups*/
		getAttributeCategories     : (data) => parse_res(api.post(`/api/admin/getAttributeCategories`,  data )),
		getAttributeCategoryById   : (data) => parse_res(api.post(`/api/admin/getAttributeCategoryById`,  data )),
    setAttributeCategories     : (data) => parse_res(api.put(`/api/admin/setAttributeCategories`,  data )),
    updateAttributeCategories  : (data) => parse_res(api.put(`/api/admin/updateAttributeCategories`,  data )),
    deleteAttributeCategories  : (data) => parse_res(api.put(`/api/admin/deleteAttributeCategories`,  data )),
		
		 /*Options*/
		getOptions             : (data) => parse_res(api.post(`/api/admin/getOptions`,  data )),
		setOptions             : (data) => parse_res(api.put(`/api/admin/setOptions`,  data )),
		updateOptions          : (data) => parse_res(api.put(`/api/admin/updateOptions`,  data )),
		deleteOptions          : (data) => parse_res(api.put(`/api/admin/deleteOptions`,  data )),
    getOptionById          : (data) => parse_res(api.post(`/api/admin/getOptionById`,  data )),
		getOptionsAndOptions   : (data) => parse_res(api.post(`/api/admin/getOptionsAndOptions`,  data )),
		
		/*Customer groups*/
		getCustomerGroups     : (data) => parse_res(api.post(`/api/admin/getCustomerGroups`,  data )),
		getCustomerGroupById   : (data) => parse_res(api.post(`/api/admin/getCustomerGroupById`,  data )),
    setCustomerGroups     : (data) => parse_res(api.put(`/api/admin/setCustomerGroups`,  data )),
    updateCustomerGroups  : (data) => parse_res(api.put(`/api/admin/updateCustomerGroups`,  data )),
    deleteCustomerGroups  : (data) => parse_res(api.put(`/api/admin/deleteCustomerGroups`,  data )),

		setCustomerGroupsToCustomers: (data) => parse_res(api.put(`/api/admin/setCustomerGroupsToCustomers`,  data )),


	  /*Menus*/
		getMenus          : (data) => parse_res(api.post(`/api/admin/getMenus`,  data )),
    getMenuById       : (data) => parse_res(api.post(`/api/admin/getMenuById`,  data )),
    setMenus          : (data) => parse_res(api.put(`/api/admin/setMenus`,  data )),
    updateMenus       : (data) => parse_res(api.put(`/api/admin/updateMenus`,  data )),
    deleteMenus       : (data) => parse_res(api.put(`/api/admin/deleteMenus`,  data )),
    
    /*Orders*/
    getOrders               : (data) => parse_res(api.post(`/api/admin/getOrders`,  data )),
    getOrderById            : (data) => parse_res(api.post(`/api/admin/getOrderById`,  data )),
    setOrders               : (data) => parse_res(api.put(`/api/admin/setOrders`,  data )),
    updateOrders            : (data) => parse_res(api.put(`/api/admin/updateOrders`,  data )),
    deleteOrders            : (data) => parse_res(api.put(`/api/admin/deleteOrders`,  data )),
    changeStatusOrders      : (data) => parse_res(api.post(`/externalapi/execute/changeStatusOrders/key/3452356rtshfghsdfgy456fh`,  data )),
    
    
    /*Status Orders*/
    getStatusOrders               : (data) => parse_res(api.post(`/api/admin/getStatusOrders`,  data )),
    
    /*Messages*/
		getMessages          : (data) => parse_res(api.post(`/api/admin/getMessages`,  data )),
    getMessageById       : (data) => parse_res(api.post(`/api/admin/getMessageById`,  data )),
    setMessages          : (data) => parse_res(api.put(`/api/admin/setMessages`,  data )),
    updateMessages       : (data) => parse_res(api.put(`/api/admin/updateMessages`,  data )),
    deleteMessages       : (data) => parse_res(api.put(`/api/admin/deleteMessages`,  data )),
    
     /*Tags*/
		getTags          : (data) => parse_res(api.post(`/api/admin/getTags`,  data )),
    getTagById       : (data) => parse_res(api.post(`/api/admin/getTagById`,  data )),
    setTags          : (data) => parse_res(api.put(`/api/admin/setTags`,  data )),
    updateTags       : (data) => parse_res(api.put(`/api/admin/updateTags`,  data )),
    deleteTags       : (data) => parse_res(api.put(`/api/admin/deleteTags`,  data )),
    
    /*product quantities*/
    getProductsQty          : (data) => parse_res(api.post(`/api/admin/getProductsQty`,  data )),
    getProductQtyById       : (data) => parse_res(api.post(`/api/admin/getProductQtyById`,  data )),
    setProductsQty          : (data) => parse_res(api.put(`/api/admin/setProductsQty`,  data )),
    updateProductsQty       : (data) => parse_res(api.put(`/api/admin/updateProductsQty`,  data )),
    updateProductsQtyByStores: (data) => parse_res(api.put(`/api/admin/updateProductsQtyByStores`,  data )),
    deleteProductsQty       : (data) => parse_res(api.put(`/api/admin/deleteProductsQty`,  data )),
    
     /*Discounts*/
		getDiscounts       : (data) => parse_res(api.post(`/api/admin/getDiscounts`,  data )),
    getDiscountById    : (data) => parse_res(api.post(`/api/admin/getDiscountById`,  data )),
    setDiscounts       : (data) => parse_res(api.put(`/api/admin/setDiscounts`,  data )),
    updateDiscounts    : (data) => parse_res(api.put(`/api/admin/updateDiscounts`,  data )),
    deleteDiscounts    : (data) => parse_res(api.put(`/api/admin/deleteDiscounts`,  data )),
    
    /*customers*/
    getCustomers               : (data) => parse_res(api.post(`/api/admin/getCustomers`,  data )),
    getCustomerById            : (data) => parse_res(api.post(`/api/admin/getCustomerById`,  data )),
    setCustomers               : (data) => parse_res(api.put(`/api/admin/setCustomers`,  data )),
    updateCustomers            : (data) => parse_res(api.put(`/api/admin/updateCustomers`,  data )),
    deleteCustomers            : (data) => parse_res(api.put(`/api/admin/deleteCustomers`,  data )),
    
    /*Polygons*/
    getPolygons               : (data) => parse_res(api.post(`/api/admin/getPolygons`,  data )),
    
    getAllPolygons            : (data) => parse_res(api.post(`/api/admin/getAllPolygons`,  data )),
    getAllPolygonsAndCoords   : (data) => parse_res(api.post(`/api/admin/getAllPolygonsAndCoords`,  data )),
    getPolygonById            : (data) => parse_res(api.post(`/api/admin/getPolygonById`,  data )),
    setPolygons               : (data) => parse_res(api.put(`/api/admin/setPolygons`,  data )),
    updatePolygons            : (data) => parse_res(api.put(`/api/admin/updatePolygons`,  data )),
    deletePolygons            : (data) => parse_res(api.put(`/api/admin/deletePolygons`,  data )),
    
    
     /*RelatedProducts*/
		getRelatedProducts          : (data) => parse_res(api.post(`/api/admin/getRelatedProducts`,  data )),
    getRelatedProductsBySKUCat  : (data) => parse_res(api.post(`/api/admin/getRelatedProductsBySKUCat`,  data )),
    setRelatedProducts          : (data) => parse_res(api.put(`/api/admin/setRelatedProducts`,  data )),
    updateRelatedProducts       : (data) => parse_res(api.put(`/api/admin/updateRelatedProducts`,  data )),
    deleteRelatedProducts       : (data) => parse_res(api.put(`/api/admin/deleteRelatedProducts`,  data )),	
    
    /*Stores*/
    getStores                   : (data) => parse_res(api.post(`/api/frontend/getStores`,  data )),
    getFormShippingTypes        : (data) => parse_res(api.post(`/api/admin/getFormShippingTypes`,  data )),
    
    /*Events*/
    getEvents                   : (data) => parse_res(api.post(`/api/admin/getDetailDomainsByCode`,  { domainid: 21} )),
    /*customer events*/
    getCustomerEvents           : (data) => parse_res(api.post(`/api/frontend/getCustomerEvents`,  data )),
		updateCustomerEventsStatus  : (data) => parse_res(api.put(`/api/frontend/updateCustomerEventsStatus`,  data )),
		getTokenManageEvent           : (data) => parse_res(api.post(`/api/frontend/getTokenManageEvent`,  data )),
		
		disableCustomerEvents           : (data) => parse_res(api.put(`/api/frontend/disableCustomerEvents`,  data )),
		enableCustomerEvents           : (data) => parse_res(api.put(`/api/frontend/enableCustomerEvents`,  data )),
		
  },
  s3: {
  	/*AWS*/
		uploadImage            : (data) => parse_res(api.post(`/api/s3/uploadImage`,  data )),
		listImages             : (data) => parse_res(api.post(`/api/s3/listImages`,  data )),
		createImageFolder      : (data) => parse_res(api.post(`/api/s3/createImageFolder`,  data )),
		deleteFile             : (data) => parse_res(api.post(`/api/s3/deleteFile`,  data )),
    programFile            : (data) => parse_res(api.post(`/api/s3/programFile`,  data )),
    
		deleteImage            : (data) => parse_res(api.post(`/api/s3/deleteImage`,  data )),
		listFiles              : (data) => parse_res(api.post(`/api/s3/listFiles`,  data )),
		createUploadFile       : (data) => parse_res(api.put(`/api/s3/createUploadFile`,  data )),
		createFileFolder       : (data) => parse_res(api.post(`/api/s3/createFileFolder`,  data )),
		
  },
  /*reports*/
  report: {
  	/*AWS*/
		repOrders              : (data) => parse_res(api.post(`/api/reports/repOrders`,  data )),
    repCarts               : (data) => parse_res(api.post(`/api/reports/repCarts`,  data )),
	},
  /*layaoutManager*/
  layaoutManager:{
  	/*templates*/
  	getLayoutTemplates         : (data) => parse_res(api.post(`/api/layout/getLayoutTemplates`,  data )),
  	getLayoutTemplateByName    : (data) => parse_res(api.post(`/api/layout/getLayoutTemplateByName`,  data )),
  //	setLayoutTemplate          : (data) => parse_res(api.put(`/api/layout/setLayoutTemplate`,  data )),
  	delLayoutTemplate          : (data) => parse_res(api.put(`/api/layout/delLayoutTemplate`,  data )),
  	
		/*components*/
		getComponents          : (data) => parse_res(api.post(`/api/admin/getComponents`,  data )),
		getCategoryComponents  : (data) => parse_res(api.post(`/api/admin/getCategoryComponents`,  data )),		
    getComponentById       : (data) => parse_res(api.post(`/api/admin/getComponentById`,  data )),
    getLogComponentById       : (data) => parse_res(api.post(`/api/admin/getLogComponentById`,  data )),
    setComponents          : (data) => parse_res(api.put(`/api/admin/setComponents`,  data )),
    updateCategoryComponent: (data) => parse_res(api.put(`/api/admin/updateCategoryComponent`,  data )),
    
    updateComponents       : (data) => parse_res(api.put(`/api/admin/updateComponents`,  data )),
    deleteComponents       : (data) => parse_res(api.put(`/api/admin/deleteComponents`,  data )),	
    
    /*BLogs*/
		setBlog                : (data) => parse_res(api.put(`/api/frontend/setBlog`,  data )),
		updateBlog             : (data) => parse_res(api.put(`/api/frontend/setBlog`,  data )),
    delBlog                : (data) => parse_res(api.put(`/api/frontend/delBlog`,  data )),
    getBlog                : (data) => parse_res(api.post(`/api/frontend/getBlog` ,  data )),
    getBlogs               : (data) => parse_res(api.post(`/api/frontend/getBlogs`,  data )),
    getBlogsByUserId       : (data) => parse_res(api.post(`/api/frontend/getBlogsByUserId`,  data )),
    
    
    
  },
   
	
};

export default Api;

export const setApiToken = (token) => {
  api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}